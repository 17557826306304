import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import CustomSpinner from "components/Common/CustomSpinner/index"
import Breadcrumbs from "components/Common/Breadcrumb"
import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

function mapStateToProps(state) {
  const { token } = state.Login
  const { id } = state.Company.company
  return { token, company_id: id }
}

const MarketingPlace = props => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [setMarketingPlaces, setSetMarketingPlaces] = useState([])
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/set-marketing-places",
        },
        false
      )
      const setMarketingPlaces = response?.dbResult?.map(i => ({
        ...i,
        isChecked: false,
      }))

      setSetMarketingPlaces(setMarketingPlaces)
      return setMarketingPlaces
    }
    const fetchCompany_MarketingPlaces = async setMarketingPlaces => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )
      if (response?.dbResult) {
        const company_marketingPlaces = response?.dbResult

        const updatedMarketingPlaces = [...setMarketingPlaces]

        company_marketingPlaces?.forEach(i => {
          for (let j = 0; j < updatedMarketingPlaces.length; j++) {
            const marketingPlace = updatedMarketingPlaces[j]
            if (i.setMarketingPlace_id === marketingPlace.id) {
              updatedMarketingPlaces[j] = {
                ...marketingPlace,
                isChecked: true,
              }
              break
            }
          }
        })

        setSetMarketingPlaces(updatedMarketingPlaces)
      }
    }

    ;(async () => {
      setLoading(true)
      const setMarketingPlaces = await fetchSetMarketingPlaces()
      await fetchCompany_MarketingPlaces(setMarketingPlaces)
      setLoading(false)
    })()
  }, [])

  // const checkHandler = id => {
  //   const updatedMarketingPlaces = [...setMarketingPlaces]
  //   const index = updatedMarketingPlaces?.findIndex(item => item.id === id)
  //   const type = updatedMarketingPlaces[index]
  //   updatedMarketingPlaces[index] = { ...type, isChecked: !type.isChecked }
  //   setSetMarketingPlaces(updatedMarketingPlaces)
  // }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Setup")} breadcrumbItem={t("Marketing Place")} />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <p className={styles.subtitle}>
              {t(
                "Your facility is active in the following sales channels. Please contact our Vihobook customer services to use inactive sales channels and applications."
              )}
            </p>
            <Row className="mx-0 mt-4">
              {setMarketingPlaces.map(item => (
                <FormGroup key={item.id} className={styles.checkboxContainer}>
                  <div onClick={() => {}} className={styles.checkboxWrapper}>
                    <Input
                      type="checkbox"
                      className={styles.checkbox}
                      name={item.name}
                      id={item.id}
                      onChange={() => {}}
                      checked={item.isChecked}
                      disabled={true}
                    />
                    <span className="ml-2">{item.name}</span>
                  </div>
                </FormGroup>
              ))}
            </Row>
          </CardBody>
        </Card>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </Container>
    </main>
  )
}

export default connect(mapStateToProps)(MarketingPlace)
