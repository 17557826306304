import {
  USER_API_ERROR,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCEED,
  ADD_NEW_USER_START,
  ADD_NEW_USER_SUCCEED,
  GET_CURRENT_COMPANY_USERS_START,
  GET_CURRENT_COMPANY_USERS_SUCCEED,
  UPDATE_USER_START,
  UPDATE_USER_SUCCEED,
  DELETE_USER_START,
  DELETE_USER_SUCCEED,
} from "./actionTypes"

const initialState = {
  currentUser: {
    name: "User",
  },
  error: false,
  errorMessage: "",
  loading: false,
}

const startMode = state => {
  return {
    ...state,
    loading: true,
    error: false,
    errorMessage: "",
  }
}

const successMode = state => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: "",
  }
}

const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_CURRENT_USER_START:
      return startMode(state)

    case GET_CURRENT_USER_SUCCEED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        currentUser: actions.user,
      }

    case ADD_NEW_USER_START:
      return startMode(state)

    case ADD_NEW_USER_SUCCEED:
      return successMode(state)

    case GET_CURRENT_COMPANY_USERS_START:
      return startMode(state)

    case GET_CURRENT_COMPANY_USERS_SUCCEED:
      return successMode(state)

    case UPDATE_USER_START:
      return startMode(state)

    case UPDATE_USER_SUCCEED:
      return successMode(state)

    case DELETE_USER_START:
      return startMode(state)

    case DELETE_USER_SUCCEED:
      return successMode(state)

    case USER_API_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: actions.errorMessage,
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
