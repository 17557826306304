import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Col, Row, Container, Card } from "reactstrap"

import TopItem from "./TopItem"
import BottomItem from "./BottomItem"
import useRequest from "hooks/useRequest"
import { useSocket } from "contexts/socket-context"
// images/icons
import img1 from "assets/images/hp-icons/1.png"
import img2 from "assets/images/hp-icons/2.png"
import img3 from "assets/images/hp-icons/3.png"
import img4 from "assets/images/hp-icons/4.png"
import img5 from "assets/images/hp-icons/5.png"

import styles from "./dashboard.module.scss"

const TicketHomePage = () => {
  const [, setErrorMessage] = useState("")
  const [, setLoading] = useState(false)
  const [reservationCount, setReservationCount] = useState(0)
  const userName = useSelector(
    state => state.User.currentUser.name + " " + state.User.currentUser.surname
  )
  const { request } = useRequest(setLoading, setErrorMessage)
  const { unreadMessageCount } = useSocket()
  const { t } = useTranslation()

  useEffect(() => {
    const fetchReservations = async () => {
      const response = await request(
        {
          url: "/ticket-reservations/fetch-by-company-id",
        },
        true
      )
      if (response?.dbResult) {
        setReservationCount(response?.dbResult?.length)
      }
    }

    ;(async () => {
      await fetchReservations()
    })()
  }, [])

  return (
    <Container className={styles.container}>
      {/* TOP ITEMS */}
      <Row>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/setup-user"}
            bgColor={"#40189d"}
            imgSrc={img1}
            label={t("View Profile")}
            content={userName}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/ticket-reservations"}
            bgColor={"#72ace5"}
            imgSrc={img1}
            label={t("Reservations")}
            content={reservationCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/reservation-messages"}
            bgColor={"#9ac46d"}
            imgSrc={img1}
            label={t("Messages")}
            content={unreadMessageCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/#"}
            bgColor={"#3b8965"}
            imgSrc={img1}
            label={t("Comments")}
            content={0}
          />
        </Col>
      </Row>
      <Card className={styles.middleCard}>{t("Operations")}</Card>
      {/* BOTTOM ITEMS */}
      <Row>
        {bottomItems.map(item => (
          <Col xs={6} xl={3} key={item.label}>
            <BottomItem
              link={item.link}
              imgSrc={item.imgSrc}
              label={t(item.label)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default TicketHomePage

const bottomItems = [
  { label: "Pricing", imgSrc: img1, link: "/ticket-pricing" },
  {
    label: "Ticket Promotions",
    imgSrc: img2,
    link: "/ticket-promotions",
  },
  {
    label: "Availability",
    imgSrc: img3,
    link: "/ticket-availability",
  },
  { label: "Reservation", imgSrc: img2, link: "/ticket-reservations" },
  {
    label: "Ticket Registration",
    imgSrc: img4,
    link: "/ticket",
  },
  { label: "Ticket Photos", imgSrc: img1, link: "/ticket-photos" },
  { label: "Settings", imgSrc: img5, link: "/setup-company-settings" },

  { label: "User", imgSrc: img4, link: "/setup-user" },
]
