import { Fragment } from "react"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { useTranslation } from "react-i18next"

import { useReservationContext } from "../context"
import styles from "./styles.module.scss"

export default function CustomTable() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { values } = useReservationContext()
  const { resDetails } = values
  if (!resDetails.reservation) {
    return null
  }

  const {
    freeCancellable,
    freeCancelDayCountBeforeArrival,
    unusedChargePercent,
    cancelChargePercent,
    hasPrepayment,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    returnDayCount,
  } = resDetails.reservation

  const data = [
    {
      title: "Prepayment Policy",
      desc: [],
    },
    {
      title: "Cancellation Policy",
      desc: [],
    },
    {
      title: "No-show Policy",
      desc: [],
    },
  ]

  let text = ""
  if (locale === "tr") {
    if (!hasPrepayment) {
      text +=
        "Rezervasyonun cout tarihi sonunda faturası vihobook tarafına iletildiği zaman 5 iş gününde ödenecektir."
      data[0].desc.push(text)
    } else {
      text += `Toplam ücretin %${prepaymentPercent}'si ücretsiz iptal  ${
        prepaymentBeforeFreeCancellation
          ? "sona ermeden önce"
          : "sona erdikten sonra"
      } ön ödeme olarak ödenir`

      text +=
        prepaymentPercent < 100
          ? `, Kalanı %${
              100 - prepaymentPercent
            } si rezervasyonun cout tarihi sonunda faturası vihobook tarafına iletildiği zaman 5 iş gününde ödenecektir. `
          : ". "
      data[0].desc.push(text)
      text = `Rezervasyon İptal edilmesi durumunda yapılmış olan ön ödeme tesis tarafından ${returnDayCount} iş günü içerisinde vihobook iade edilecektir.`
      data[0].desc.push(text)
    }
  } else {
    if (!hasPrepayment) {
      text +=
        "The payment will be made within 5 working days after the invoice is sent to Vihobook at the end of the reservation's cout date."
      data[0].desc.push(text)
    } else {
      text += `${prepaymentPercent}% of the total fee is paid as a prepayment ${
        prepaymentBeforeFreeCancellation ? "before" : "after"
      }  the free cancellation ends`
      text +=
        prepaymentPercent < 100
          ? `, the remaining ${
              100 - prepaymentPercent
            }% will be paid in 5 working days when the invoice is sent to Vihobook at the end of the cout date of the reservation. `
          : ". "
      data[0].desc.push(text)
      text = `In case of Cancellation of the reservation, the prepayment will be refunded by the facility within ${returnDayCount} working days.`
      data[0].desc.push(text)
    }
  }

  if (freeCancellable) {
    text =
      locale === "tr"
        ? `Misafir, varıştan ${freeCancelDayCountBeforeArrival} gün öncesine kadar ücretsiz olarak iptal edebilir.`
        : `The guest can cancel free of charge until ${freeCancelDayCountBeforeArrival} days before arrival.`
    data[1].desc.push(text)
    text =
      locale === "tr"
        ? `Varıştan önceki ${freeCancelDayCountBeforeArrival} gün içinde iptal eden konuklardan toplam ücretin %${cancelChargePercent} tahsil edilecektir.`
        : `The guest will be charged ${cancelChargePercent}% of the total price if they cancel in the ${freeCancelDayCountBeforeArrival} days before arrival.`
    data[1].desc.push(text)
  } else {
    text =
      locale === "tr"
        ? `Rezervasyondan sonra iptal edilirse konuktan toplam ücretin %${cancelChargePercent} tahsil edilecektir.`
        : `The guest will be charged ${cancelChargePercent}% of the total price if they cancel after reservation.`
    data[1].desc.push(text)
  }

  if (unusedChargePercent > 0) {
    text =
      locale === "tr"
        ? `Misafir gelmezse, toplam rezervasyon ücretinin %${unusedChargePercent} ücretlendirilir.`
        : `If the guest doesn't show up, they will be charged ${unusedChargePercent}% of the total price of the reservation.`
    data[2].desc.push(text)
  } else {
    text =
      locale === "tr"
        ? `Misafir gelmezse ücret alınmayacaktır.`
        : `If the guest doesn't show up, they will not be charged.`
    data[2].desc.push(text)
  }

  return (
    <TableContainer component={Paper} id="hotelConditions">
      <Table>
        <TableBody>
          {data.map(item => (
            <Fragment key={item.title}>
              <TableRow className={styles.noSideBorder}>
                <TableCell
                  className={styles.headingCell}
                  rowSpan={item.desc.length}
                >
                  {t(item.title)}
                </TableCell>
                <TableCell className={styles.descCell}>
                  {item.desc[0]}
                </TableCell>
              </TableRow>
              {item.desc.length > 1 &&
                item.desc.slice(1).map(desc => (
                  <TableRow className={styles.noSideBorder} key={desc}>
                    <TableCell className={styles.descCell}>{desc}</TableCell>
                  </TableRow>
                ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
