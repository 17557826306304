import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  Modal,
  ModalBody,
  Card,
  CardBody,
  Row,
  Input,
  Spinner,
  Button,
  Alert,
} from "reactstrap"

import RatePlans from "./QuestionsCards/RatePlans"
import WhichRooms from "./QuestionsCards/WhichRooms"
import MarketingPlace from "./QuestionsCards/MarketingPlace"
import StayDates from "./QuestionsCards/StayDates"
import PromotionDetails from "./PromotionDetails"
import BookablePeriodDate from "./QuestionsCards/BookablePeriodDate"
import PromotionName from "./QuestionsCards/PromotionName"
import Currencies from "./QuestionsCards/Currencies"

import SuccessAlert from "components/Common/SuccessAlert"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"
import { addDays, getTodayDate, formatDate } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

let initialOptions = []
for (let i = 0; i <= 50; i++) {
  initialOptions.push({ label: i, value: i })
}
const FreeNightsDealForm = props => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))
  const { isOpen, close, modalTitle, name, setComponentIndex } = props
  const company_id = useSelector(state => state.Company.company.id)
  const [hotel_id, setHotel_id] = useState(null)

  const [res_startDate, setRes_startDate] = useState(getTodayDate())
  const [res_endDate, setRes_endDate] = useState(addDays(getTodayDate(), 7))
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])

  const [booking_startDate, setBooking_startDate] = useState(getTodayDate())
  const [booking_endDate, setBooking_endDate] = useState(
    addDays(getTodayDate(), 7)
  )
  const [members, setMembers] = useState(false)

  const [roomNames, setRoomNames] = useState([])
  const [boardTypes, setBoardTypes] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [promotionsName, setPromotionsName] = useState(
    name + "  " + formatDate(getTodayDate())
  )
  const [overnightStay, setOvernightStay] = useState(3)
  const [, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")

  const { request } = useRequest(setLoading, setError)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )

      if (response?.dbResult) {
        const hotel_id = response?.dbResult?.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        ...i,
        id: i.id[1],
        isChecked: false,
      }))
      marketingPlaces.unshift({ id: 0, name: t("All"), isChecked: false })
      setMarketingPlaces(marketingPlaces)
    }
    const fetchRoomNames = async hotel_id => {
      const response = await request({
        url: "/hotel-room-names",
        params: { hotel_id },
      })
      setRoomNames(
        response?.dbResult?.map(i => ({
          ...i,
          name: getLabelByLanguage(i, locale),
          isChecked: true,
        }))
      )
      if (roomNames.length === 0) {
        setRoomRegError(true)
      }
    }
    const fetchBoardTypes = async hotel_id => {
      const response = await request({
        url: "/hotel-board-types/fetch-names-by-hotel-id",
        params: { hotel_id },
      })
      setBoardTypes(
        response?.dbResult?.map(i => ({
          ...i,
          name: getLabelByLanguage(i, locale),
          isChecked: true,
        }))
      )
    }
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      await Promise.allSettled([
        fetchRoomNames(hotel_id),
        fetchBoardTypes(hotel_id),
        fetchSetMarketingPlaces(),
      ])
      setLoading(false)
    })()
  }, [])

  // CHECK HANDLERS
  const boardTypesCheckHandler = id => {
    setBoardTypes(
      boardTypes.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        }
        return i
      })
    )
  }

  const roomNamesCheckHandler = id => {
    setRoomNames(
      roomNames.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        }
        return i
      })
    )
  }

  const currencyCheckHandler = id => {
    setCurrencies(
      currencies.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        }
        return i
      })
    )
  }

  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const validSubmitHandler = () => {
    const submit = async newData => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "hotel-promotions",
          method: "post",
          data: newData,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }
    setError("")

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)
    const setBoardType_ids = boardTypes
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    const currency_ids = currencies.filter(i => i.isChecked).map(i => i.id)

    const room_ids = roomNames
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.room_id)

    if (currency_ids.length < 1) {
      return setError(t("Please select a currency!"))
    }

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }
    let newData = {
      promotionType_id: 4,
      members,
      setBoardType_ids,
      room_ids,
      discountRate: 0,
      minNight: false,
      minNightDay: 0,
      freeDays: true,
      overnightStay,
      res_startDate,
      res_endDate,
      booking_startDate,
      currency_ids,
      booking_endDate,
      setMarketingPlace_ids,
      status: 1,
      hotel_id,
      company_id,
      promotionsName,
    }
    submit(newData)
  }
  return (
    <div>
      <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
        <div className="modal-header">
          <h3 className={styles.modalTitle}>{t(modalTitle)}</h3>
          <button
            type="button"
            onClick={close}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className={styles.modalBody}>
          <p className={styles.modalSubtitle}>
            {t(
              "Customize this promotion to suit your needs. Select your own dates, discount amount, audience, and more."
            )}
          </p>

          <PromotionDetails members={members} setMembers={setMembers} />

          <RatePlans
            boardTypes={boardTypes}
            setBoardTypes={setBoardTypes}
            boardTypesCheckHandler={boardTypesCheckHandler}
          />

          {country_id === 107 && (
            <Currencies
              currencies={currencies}
              currencyCheckHandler={currencyCheckHandler}
            />
          )}

          <WhichRooms
            roomNames={roomNames}
            setRoomNames={setRoomNames}
            roomNamesCheckHandler={roomNamesCheckHandler}
          />
          {marketingPlaces.length > 2 && (
            <MarketingPlace
              marketingPlaces={marketingPlaces}
              marketingPlaceCheckHandler={marketingPlaceCheckHandler}
            />
          )}

          <Card className={styles.card}>
            <CardBody className={styles.cardBody}>
              <h4 className={styles.cardTitle}>{t("Length of Stay")}</h4>
              <h5 className={styles.cardSubtitle}>
                {t(
                  "How many paid night reservations are required for customers to earn free accommodation?"
                )}
              </h5>

              <Row className={styles.lengthOfStayRow}>
                <Input
                  value={overnightStay}
                  onChange={e => setOvernightStay(e.target.value)}
                />
                <p>{t("days or more (before check-in)")}</p>
              </Row>
            </CardBody>
          </Card>

          <BookablePeriodDate
            setRes_startDate={setRes_startDate}
            setRes_endDate={setRes_endDate}
            res_startDate={res_startDate}
            res_endDate={res_endDate}
          />

          <StayDates
            booking_startDate={booking_startDate}
            booking_endDate={booking_endDate}
            setBooking_startDate={setBooking_startDate}
            setBooking_endDate={setBooking_endDate}
          />

          <PromotionName
            promotionsName={promotionsName}
            setPromotionsName={setPromotionsName}
          />
          {error && <Alert color="danger"> {t(error)}</Alert>}
          {HotelNotRegisteredComp}

          {showSuccessAlert && (
            <SuccessAlert
              hideSuccessAlert={() => {
                setShowSuccessAlert(false)
                setComponentIndex("")
              }}
            />
          )}
          <div className="modal-footer">
            <Button type="button" className="btn btn-secondary" onClick={close}>
              {t("Close")}
            </Button>

            <Button size="md" color="primary" onClick={validSubmitHandler}>
              {loading ? (
                <>
                  <Spinner size="sm" /> {t("Loading...")}{" "}
                </>
              ) : (
                t("Save")
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
export default FreeNightsDealForm
