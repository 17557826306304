import { takeEvery, put, select } from "redux-saga/effects"
import axios from "axios"

import { GET_COMPANY, UPDATE_COMPANY } from "./actionTypes"
import {
  apiError,
  getCompanyStart,
  getCompanySucceed,
  updateCompanyStart,
  updateCompanySucceed,
} from "./actions"

function* getCompanySaga(action) {
  try {
    yield put(getCompanyStart())
    let url = "/company"

    const config = {
      headers: {
        Authorization: "Bearer " + action.token,
      },
    }
    const response = yield axios.get(url, config)
    if (response.data.result == true) {
      yield put(getCompanySucceed(response.data.company))
    } else {
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    yield put(apiError("Something went wrong!!"))
  }
}

function* updateCompanySaga({ company, callback }) {
  try {
    yield put(updateCompanyStart())
    const state = yield select()
    const token = state.Login.token

    let url = "/company"

    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    }
    const formData = new FormData()
    formData.append("name", company.name)
    formData.append("title", company.title)
    formData.append("telephoneNumber", company.telephoneNumber)
    formData.append("email", company.email)
    formData.append("address1", company.address1)
    formData.append("address2", company.address2)
    formData.append("countryId", company.country_id)
    formData.append("provinceId", company.province_id)
    formData.append("districtId", company.district_id)
    formData.append("taxAdministration", company.taxAdministration)
    formData.append("taxNumber", company.taxNumber)
    formData.append("logoRef", company.logoRef)

    const response = yield axios.put(url, formData, config)

    if (response.data.result == true) {
      yield put(updateCompanySucceed(response.data.updatedCompany))
      callback({ result: true })
    } else {
      const message = response.data.message
      yield put(apiError(message))
      callback({
        result: false,
        message,
      })
    }
  } catch (error) {
    const message = "Something went wrong!!"
    yield put(apiError(message))
    callback({ result: false, message })
  }
}

function* companySaga() {
  yield takeEvery(GET_COMPANY, getCompanySaga)
  yield takeEvery(UPDATE_COMPANY, updateCompanySaga)
}

export default companySaga
