import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"
import { getTranslatedOption, getCurrencySignById } from "utils/other"

class VehiclesTable extends React.Component {
  state = {
    selectedRowId: "",
  }
  render() {
    const {
      t,
      onSelectTableRow,
      extraServicesPrices,
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      onPageChange,
      hasPagination,
    } = this.props
    let ExtraServicesPrices = extraServicesPrices.map(item => {
      const locale = this.props.i18n.language

      return (
        <Tr
          key={item.id}
          onClick={() => {
            onSelectTableRow(item.id)
            this.setState({ selectedRowId: item.id })
          }}
          className={this.state.selectedRowId === item.id ? "selectedRow" : ""}
        >
          <Td className="align-middle">
            {getTranslatedOption(item, "vehicleType", locale)}
          </Td>
          <Td className="align-middle">
            {getTranslatedOption(item, "label", locale)}
          </Td>
          <Td className="align-middle">
            {getTranslatedOption(item, "subLabel", locale)}
          </Td>
          <Td className="align-middle">{item.isFree ? t("Yes") : t("No")}</Td>
          <Td className="align-middle">
            {getCurrencySignById(2)}
            {item.price}
          </Td>
        </Tr>
      )
    })

    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div className="table-responsive mb-0 data-table-container">
                  <Table
                    id="main-table"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th className="table-data">{t("Vehicle Type")}</Th>
                        <Th className="table-data">{t("Service Label")}</Th>
                        <Th className="table-data">{t("Service Sublabel")}</Th>
                        <Th className="table-data">{t("Is Free")}</Th>
                        <Th className="table-data">{t("Price")}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>{ExtraServicesPrices}</Tbody>
                  </Table>
                </div>
                {hasPagination && (
                  <WidgetPagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(VehiclesTable)
