import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"

class VehiclesTable extends React.Component {
  state = {
    selectedRowId: "",
  }
  render() {
    const {
      vehicleTypes,
      t,
      onSelectTableRow,
      vehicles,
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      onPageChange,
      hasPagination,
    } = this.props

    let vehicleType

    let Vehicles = vehicles.map(vehicle => {
      vehicleType = vehicleTypes.find(vehicleType => {
        return vehicle.vehicleType_id == vehicleType.value
      })
      const vehicleTypeName = vehicleType ? vehicleType.label : ""
      return (
        <Tr
          key={vehicle.id}
          onClick={() => {
            onSelectTableRow(vehicle.id)
            this.setState({ selectedRowId: vehicle.id })
          }}
          className={
            this.state.selectedRowId === vehicle.id ? "selectedRow" : ""
          }
        >
          <Td className="align-middle">{vehicleTypeName}</Td>
          <Td className="align-middle">
            {vehicle.status === true ? t("Active") : t("Inactive")}
          </Td>
          <Td className="align-middle">{vehicle.count}</Td>
          <Td className="align-middle">{vehicle.capacity}</Td>
          <Td className="align-middle">{vehicle.suitcaseCount}</Td>
          <Td className="align-middle">{vehicle.note}</Td>
        </Tr>
      )
    })

    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div className="table-responsive mb-0 data-table-container">
                  <Table
                    id="main-table"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th className="table-data">{t("Vehicle Type")}</Th>
                        <Th className="table-data">{t("Vehicle State")}</Th>
                        <Th className="table-data">{t("Vehicle Count")}</Th>
                        <Th className="table-data">{t("Capacity")}</Th>
                        <Th className="table-data">{t("Suitcase Count")}</Th>
                        <Th className="table-data">{t("Note")}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>{Vehicles}</Tbody>
                  </Table>
                </div>
                {hasPagination && (
                  <WidgetPagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(VehiclesTable)
