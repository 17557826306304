import React from "react"
import { useTranslation } from "react-i18next"

import { usePrepaymentContext } from "./context"
import styles from "./styles.module.scss"

const PolicySummary = () => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { values } = usePrepaymentContext()
  const {
    hasPrepayment,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    returnDayCount,
  } = values

  if (hasPrepayment && (!prepaymentPercent || !returnDayCount)) {
    return (
      <div className={styles.policySummary}>
        <p className={styles.validationalert}>
          {t("Please enter the conditions in the fields mentioned above.")}
        </p>
      </div>
    )
  }

  let summary = ""
  let summary2 = ""
  let summary3 = ""
  let summary4 = ""

  if (locale === "tr") {
    if (!hasPrepayment) {
      summary +=
        "Rezervasyonun cout tarihi sonunda faturası vihobook tarafına iletildiği zaman 5 iş gününde ödenecektir."
    } else {
      summary += `Toplam ücretin %${prepaymentPercent}'si ücretsiz iptal  ${
        prepaymentBeforeFreeCancellation
          ? "sona ermeden önce"
          : "sona erdikten sonra"
      } ödenir`
      summary +=
        prepaymentPercent < 100
          ? `, Kalanı %${
              100 - prepaymentPercent
            } si misafirin otelden ayrılış (cout) tarihi sonunda Vihobook tarafından 5 iş gününde ödenecektir.`
          : ". "
      summary2 += `Rezervasyon iptal edilmesi (olması) durumunda ödemesi yapılmış olan rezervasyonların tesis tarafından maksimum ${returnDayCount} iş günü içerisinde Vihobook'a geri ödemesini gerçekleştirecektir.`
      summary3 +=
        "Tesisin Vihobook'tan alacağı olması durumunda tesis iade ödemesini gerçekleştirmek zorunda değildir. Vihobook alacak bakiyesinden düşülecektir."
      summary4 +=
        "Tesis Vihobook'tan ödeme alabilmesi için rezervasyonların numarası (voucher), geliş ayrılış tarihleri, rezervasyon misafir adını gösteren konaklama faturalarını vihobook ilettikten sonra koşullar belirtilmiş olan koşullar doğrultusunda ödemesini tahsil edebilecektir."
    }
  } else {
    if (!hasPrepayment) {
      summary +=
        "The payment will be made within 5 working days after the invoice is sent to Vihobook at the end of the reservation's cout date."
    } else {
      summary += `${prepaymentPercent}% of the total fee is paid  ${
        prepaymentBeforeFreeCancellation ? "before" : "after"
      }  the free cancellation ends`
      summary +=
        prepaymentPercent < 100
          ? `, the remaining ${
              100 - prepaymentPercent
            }% will be paid by Vihobook within 5 working days at the end of the guest's check-out date. `
          : ". "
      summary2 += `In case of cancellation of the reservation, the property will refund the paid reservations to Vihobook within a maximum of ${returnDayCount} working days.`
      summary3 +=
        "If the facility has a receivable from Vihobook, the facility is not obliged to make the refund payment. It will be deducted from the balance receivable from Vihobook."
      summary4 +=
        "In order to receive payment from Vihobook, the facility will be able to collect the payment in accordance with the conditions specified after submitting the accommodation invoices to Vihobook showing the reservation number (voucher), arrival and departure dates, and the name of the reservation guest."
    }
  }

  return (
    <div className={styles.policySummary}>
      <h6>{t("Policy Summary")}</h6>
      <p>{summary}</p>
      <p>{summary2}</p>
      <p>{summary3}</p>
      <p>{summary4}</p>
    </div>
  )
}

export default PolicySummary
