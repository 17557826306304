import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"

import styles from "./styles.module.scss"

class Breadcrumbs extends Component {
  render() {
    const { title, breadcrumbItem } = this.props
    return (
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h5 className={styles.title}>{breadcrumbItem}</h5>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0" className={styles.breadcrumb}>
                <BreadcrumbItem>
                  <Link to="#">{title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumbs
