import {
  REGISTER_USER_START,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  result: false,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_START:
      state = {
        ...state,
        error: false,
        errorMessage: "",
        loading: true,
        result: false,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        error: false,
        errorMessage: "",
        loading: false,
        result: true,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        loading: false,
        result: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
