import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { Label, Card, Col, Row, Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from "axios"

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const CustomDropZone = ({ fileRef, setFileRef }) => {
  const companyId = useSelector(state => state.Company.company.id)
  const [isRejected, setIsRejected] = useState(false)

  const { t } = useTranslation()

  const handleAcceptedFiles = files => {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })

    setFileRef(files[0])
    setIsRejected(false)
  }

  const onDropRejected = obj => {
    setIsRejected(true)
  }

  return (
    <>
      <Label for="logo">{t("Documents")}</Label>
      <Dropzone
        maxFiles={1}
        accept="image/jpg, image/jpeg, image/png, application/pdf"
        onDropRejected={onDropRejected}
        onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h5>{t("Drop files here or click to upload.")}</h5>
              <h6>{t("(Only *.jpeg, *.png and .pdf files will be accepted)")}</h6>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {isRejected && (
          <Alert color="danger">{t("Please upload a valid file!")}</Alert>
        )}
        {fileRef && (
          <Card className="mt-1 mb-1 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2 ">
              <Row className="align-items-center">
                <Col className="col-auto">
                  {fileRef.name ? (
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={fileRef.name}
                      src={fileRef.preview}
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={fileRef}
                      src={axios.defaults.baseURL + "/companyDocuments/" + companyId + "/" + fileRef}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        )}
      </div>
    </>
  )
}

export default CustomDropZone
