import Select from "react-select"
import { useTranslation } from "react-i18next"
import { TabContent, TabPane, Input } from "reactstrap"

import CustomCheckbox from "components/Common/CustomCheckbox/index"
import styles from "./styles.module.scss"

const NavContents = props => {
  const { t } = useTranslation()

  return (
    <TabContent activeTab={props.activeLink} className={styles.tabContent}>
      <TabPane tabId={0} className={styles.tabPane}>
        {props?.features[props.activeLink]?.featureItems?.map(item => (
          <div className={styles.checkboxContainer} key={item.id}>
            <CustomCheckbox
              id={item.id}
              title={item.itemTitle}
              isChecked={item.isChecked}
              onClick={props.onCheckboxClicked}
            />
            {item.isPaid && (
              <div className={styles.inputsContainer}>
                <Input
                  value={item.price}
                  placeholder={t("Price")}
                  onChange={e => props.onPriceChange(e.target.value, item.id)}
                />
                <Select
                  options={props.currencies}
                  value={props.currencies.find(
                    i => i.value === item.currency_id
                  )}
                  onChange={v => props.onCurrencyChange(v.value, item.id)}
                  placeholder={t("Currency")}
                  noOptionsMessage={() => t("No Options")}
                  className={styles.currencySelect}
                  styles={customStyles}
                />
              </div>
            )}
          </div>
        ))}
      </TabPane>
    </TabContent>
  )
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "28px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "28px",
    padding: "0px 8px",
    lineHeight: "none",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "28px",

    "& > div": {
      padding: "6px 8px",
    },
  }),
}

export default NavContents
