import React from "react"
import { Card, CardBody } from "reactstrap"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import styles from "./styles.module.scss"

const RadioButtons = props => {
  const { title, name, dataList, dataCheckHandler } = props
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{title}</h5>
        {Array.isArray(dataList) && dataList.length > 0 && (
          <FormControl component="fieldset" className={styles.formControl}>
            <RadioGroup
              name={name}
              onChange={dataCheckHandler}
              value={dataList.find(i => i.isChecked)?.value}
            >
              {dataList.map(item => {
                return (
                  <FormControlLabel
                    key={item.value}
                    control={<Radio className={styles.radio} />}
                    className={styles.radioLabel}
                    label={item.label}
                    value={item.value}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        )}
      </CardBody>
    </Card>
  )
}

export default RadioButtons
