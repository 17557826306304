import React, { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Container, Row, Button, Spinner } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation-safe"

import CustomWarning from "components/Common/CustomWarning/index"
import Breadcrumbs from "components/Common/Breadcrumb"
import TopInputs from "./TopInputs"
import BottomInputs from "./BottomInputs"
import styles from "./styles.module.scss"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner"

const HotelInformation = () => {
  const topInputsRef = useRef()
  const bottomInputsRef = useRef()

  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [hotel_id, setHotel_id] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchHotelData = async () => {
      setLoading(true)
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )
      await Promise.all([
        topInputsRef?.current?.fetchData(),
        bottomInputsRef?.current?.fetchData(),
      ])
      if (response?.dbResult) {
        topInputsRef?.current?.setState(response.dbResult)
        bottomInputsRef?.current?.setState(response.dbResult)
        setHotel_id(response.dbResult.id)
      }
      setLoading(false)
    }

    fetchHotelData()
  }, [hotel_id])

  const validSubmitHandler = async (e, inputs) => {
    const { selectedDistrict, selectedNeighborhood, phoneNumber, googleName, googleAddress, googlePhoneNumber } =
      topInputsRef?.current?.getState()
    const { selectedType, selectedCategory } =
      bottomInputsRef?.current?.getState()

    if (
      !selectedDistrict?.value ||
      !selectedType?.value ||
      !selectedCategory?.value ||
      !phoneNumber
    ) {
      setShowBlankFieldAlert(true)
      return
    }

    let data = {
      ...inputs,
      name: inputs.facilityName,
      district_id: selectedDistrict.value,
      setType_id: selectedType.value,
      setCategory_id: selectedCategory.value,
      phoneNumber,
      googleName,
      googleAddress,
      googlePhoneNumber,
      ...(selectedNeighborhood && {
        neighborhood_id: selectedNeighborhood.value,
      }),
    }

    let method = ""

    if (hotel_id) {
      data = { ...data, id: hotel_id }
      method = "put"
    } else {
      method = "post"
    }
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-registration",
        method,
        data,
      },
      false
    )
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
      setHotel_id(response?.dbResult?.id)
    } else {
      console.log(errorMessage)
    }
  }
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Information")}
        />
        {loading && <CustomSpinner color="primary" />}
        <AvForm
          onValidSubmit={validSubmitHandler}
          className={loading ? "d-none" : ""}
        >
          <TopInputs ref={topInputsRef} />
          <BottomInputs ref={bottomInputsRef} />
          <Row className={styles.submitBtnContainer}>
            <Button color="primary">
              {submitLoading ? (
                <Spinner color="white" size="sm" />
              ) : (
                t("Submit")
              )}
            </Button>
          </Row>
        </AvForm>
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default HotelInformation
