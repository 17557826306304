import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Col, Label } from "reactstrap"
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation-safe"

import styles from "./styles.module.scss"

export default props => {
  const [maxAdult, setMaxAdult] = useState("")
  const [maxChild, setMaxChild] = useState("")
  const [maxCapacity, setMaxCapacity] = useState("")
  const { t } = useTranslation()
  const Feedback = <AvFeedback>{t("Please fill in the blank!")}</AvFeedback>

  useEffect(() => {
    if (!props.addOperation) {
      const { maxAdult, maxChild } = props.defaultValues
      setMaxAdult(maxAdult)
      setMaxChild(maxChild)
    }
  }, [])
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Room Capacity")}</CardTitle>
        <hr />
        <Row form>
          <Col md={4}>
            <AvGroup>
              <Label>* {t("Max. adult")}</Label>
              <AvInput
                name="maxAdult"
                type="number"
                step={1}
                min={1}
                required
                onChange={(e, maxAdult) => setMaxAdult(maxAdult)}
              />
              {Feedback}
            </AvGroup>
          </Col>
          <Col md={4}>
            <AvGroup>
              <Label>* {t("Max. child")}</Label>
              <AvInput
                name="maxChild"
                type="number"
                step={1}
                min={0}
                required
                onChange={(e, maxChild) => setMaxChild(maxChild)}
              />
              {Feedback}
            </AvGroup>
          </Col>
          <Col md={4}>
            <AvGroup>
              <Label>* {t("Max. capacity")}</Label>
              <AvInput
                name="maxCapacity"
                type="number"
                step={1}
                min={1}
                onChange={(e, maxCapacity) => setMaxCapacity(maxCapacity)}
              />
              <span className={styles.inputAltNote}>
                {t("Max. number of guests (adults and children)")}
              </span>
            </AvGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
