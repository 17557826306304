import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

// Import menuDropdown
import LanguageDropdown from "./TopbarDropdown/LanguageDropdown"
import ProfileMenu from "./TopbarDropdown/ProfileMenu"

import styles from "./styles.module.scss"

class Header extends Component {
  toggleMenu = () => {
    this.props.toggleMenuCallback()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  render() {
    const { company } = this.props
    return (
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={this.toggleMenu}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            <h4 className={styles.companyName}>{company?.code} / {company?.name}</h4>
          </div>
          <div className="d-flex">
            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={this.toggleFullscreen}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = state => {
  const { layoutType } = state.Layout
  const { company } = state.Company
  return { layoutType, company }
}

export default connect(mapStateToProps, {})(withTranslation()(Header))
