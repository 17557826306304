import React, { useEffect } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from "reactstrap"
import { Controller } from "react-hook-form"

import CustomSwitch from "components/Common/CustomSwitch/index"
import { useTicketContext } from "./context"
import { selectError } from "utils/other"
import styles from "./styles.module.scss"
import TicketTimeInfo from "./TicketTimeInfo"

export default ({ useFormResult, isAddOperation, ticket }) => {
  const { register, errors, setValue, control } = useFormResult
  const { values, functions, setters } = useTicketContext()
  const { fetchTicketTypeNames } = functions
  const { types, ticketTypeNames, selectedTimes } = values
  const { setSelectedTimes } = setters

  useEffect(() => {
    if (!isAddOperation) {
      const { ticketType_id, ticketTypeName_id, name, maxCapacity, status } =
        ticket

      Promise.allSettled([fetchTicketTypeNames(ticketType_id)])

      setValue("ticketType_id", ticketType_id)
      setValue("ticketTypeName_id", ticketTypeName_id)
      setValue("name", name)
      setValue("maxCapacity", maxCapacity)
      setValue("status", status)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Ticket Info")}</CardTitle>
        <hr />
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.ticketType_id && styles.error}`}>
            <Label>* {t("Type")}</Label>
            <Controller
              control={control}
              name="ticketType_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={types}
                    value={types?.find(i => i?.value === value)}
                    onChange={obj => {
                      const ticketType_id = obj.value
                      onChange(ticketType_id)
                      setValue("ticketTypeName_id", "")
                      fetchTicketTypeNames(ticketType_id)
                    }}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.ticketType_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.ticketType_id && (
              <p>{t(errors?.ticketType_id?.message)}</p>
            )}
          </Col>
          <Col
            md={6}
            className={` ${errors?.ticketTypeName_id && styles.error}`}
          >
            <Label>* {t("Ticket Name")}</Label>
            <Controller
              control={control}
              name="ticketTypeName_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={ticketTypeNames}
                    value={ticketTypeNames?.find(i => i?.value === value) || ""}
                    onChange={obj => {
                      const ticketTypeName_id = obj.value
                      onChange(ticketTypeName_id)
                    }}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.ticketTypeName_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.ticketTypeName_id && (
              <p>{t(errors?.ticketTypeName_id?.message)}</p>
            )}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.name && styles.error}`}>
            <Label>* {t("Special Name")}</Label>
            <Input name="name" innerRef={register} invalid={!!errors?.name} />
            {errors?.name && <p>{t(errors?.name?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.maxCapacity && styles.error}`}>
            <Label>* {t("Capacity")}</Label>
            <Input
              name="maxCapacity"
              innerRef={register}
              invalid={!!errors?.maxCapacity}
              type="number"
              step={1}
              min={0}
            />
            {errors?.maxCapacity && <p>{t(errors?.maxCapacity?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={2} className={styles.center}>
            <Controller
              control={control}
              name="status"
              defaultValue={true}
              render={({ onChange, value }) => {
                return (
                  <CustomSwitch
                    label={t("Status")}
                    onClick={() => onChange(!value)}
                    value={value}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <TicketTimeInfo
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          isAddOperation={isAddOperation}
          ticket={ticket}
        />
      </CardBody>
    </Card>
  )
}
