import React, { useState } from "react"
import { Card, Media, Input } from "reactstrap"
import { BiSearchAlt } from "react-icons/bi"
import { useTranslation } from "react-i18next"

import NewConversationModal from "./NewConversationModal"
import Contacts from "./Contacts"
import { useChatContext } from "../context"
import styles from "./sidebar.module.scss"

export default function Sidebar() {
  const [searchInput, setSearchInput] = useState("")

  const { t } = useTranslation()
  const { values, setters, functions } = useChatContext()
  const { currentUser, contacts, currentContact, subjectTypes, showNewCModal } =
    values
  const { setCurrentContact, setShowNewCModal } = setters
  const { setMessagesToRead, newConversationHandler } = functions
  const { name, surname } = currentUser

  const filteredContacts = contacts.filter(i =>
    i?.title?.toLowerCase().includes(searchInput.toLowerCase())
  )
  return (
    <Card className={styles.container}>
      <header className="py-3 border-bottom">
        <Media className="d-flex justify-content-center align-items-center">
          <div className={styles.avatar}>
            <span>{`${name[0].toUpperCase()}${
              surname && surname[0].toUpperCase()
            }`}</span>
          </div>
          <Media body>
            <h5 className="font-size-15 mt-0 mb-1 font-weight-bold">{`${name} ${surname}`}</h5>
            <p className="text-muted mb-0 font-size-14">
              <span className={styles.online}></span>
              {t("Online")}
            </p>
          </Media>
        </Media>
      </header>
      <div className={styles.searchBox}>
        <BiSearchAlt size={15} />
        <Input
          type="search"
          placeholder={t("Search...")}
          onChange={e => setSearchInput(e.target.value)}
        />
      </div>
      <div className={styles.action}>
        <h5>{t("Recent")}</h5>
        <button onClick={setShowNewCModal.bind(null, true)}>
          {t("Create new conversation")}
        </button>
        {showNewCModal && (
          <NewConversationModal
            toggle={() => setShowNewCModal(prev => !prev)}
            subjectTypes={subjectTypes}
            submitHandler={newConversationHandler}
          />
        )}
      </div>

      <Contacts
        contacts={filteredContacts}
        currentContact={currentContact}
        setCurrentContact={setCurrentContact}
        setMessagesToRead={setMessagesToRead}
      />
    </Card>
  )
}
