import React, { useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"
import { formatDate, formatDateTime } from "utils/dateTimeFunctions"
import styles from "./style.module.scss"

const PromotionsTable = props => {
  const [selectedRowId, setSelectedRowId] = useState("")
  const { t } = useTranslation()
  const {
    promotions,
    promotionTypes,
    onSelectTableRow,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const PromotionRows = promotions?.map(item => {
    const promotionName = promotionTypes?.find(
      i => i.id === item.promotionType_id
    )?.name
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
          setSelectedRowId(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{item?.promotionName}</Td>
        <Td className={styles.dataCell}>{item?.propertyName}</Td>
        <Td className={styles.dataCell}>
          {formatDate(item?.bookingStartDate)} -{" "}
          {formatDate(item?.bookingEndDate)}
        </Td>
        <Td className={styles.dataCell}>
          {formatDate(item?.resStartDate)} - {formatDate(item?.resEndDate)}
        </Td>
        <Td className={styles.dataCell}>{promotionName}</Td>
        <Td className={styles.dataCell}>{item?.discountRate}</Td>
        <Td className={styles.dataCell}>{item?.setMarketingPlaceName}</Td>
        <Td className={styles.dataCell}>{formatDateTime(item?.createdAt)}</Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className={`table-responsive ${styles.tContainer}`}>
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.th}>{t("Name")}</Th>
              <Th className={styles.th}>{t("Villa Name")}</Th>
              <Th className={styles.th}>{t("Bookable period")}</Th>
              <Th className={styles.th}>{t("Stay dates")}</Th>
              <Th className={styles.th}>{t("Discount type")}</Th>
              <Th className={styles.th}>{t("Discount rate")} %</Th>
              <Th className={styles.th}>{t("Marketing Place")}</Th>
              <Th className={styles.th}>{t("Registration date")}</Th>
            </Tr>
          </Thead>

          <Tbody>{PromotionRows}</Tbody>
        </Table>
      </div>
      {hasPagination && (
        <WidgetPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default PromotionsTable
