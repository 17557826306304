import React, { Component } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import { getCurrentUser } from "store/actions"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    this.props.getCurrentUser(this.props.token)
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className={'d-inline-block'}
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <i className="bx bx-user" />
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.props.userName}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/setup-user">
              <DropdownItem>
                <i className="bx bx-user font-size-16 align-middle mr-1" />
                {t("Profile")}
              </DropdownItem>
            </Link>

            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
              <span>{t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.User.currentUser,
    token: state.Login.token,
    userName:
      state.User.currentUser.name + " " + state.User.currentUser.surname,
  }
}

export default connect(mapStateToProps, { getCurrentUser })(
  withRouter(withTranslation()(ProfileMenu))
)
