import React, { useState, useEffect } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import useRequest from "hooks/useRequest"

import Inputs from "./Inputs"
import Calendar from "./Calendar"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getTranslatedOption } from "utils/other"

const TransferPricingReport = () => {
  const [years, setYears] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [selectedMarketingPlace, setSelectedMarketingPlace] = useState("")
  const [prices, setPrices] = useState([])

  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [modifiers, setModifiers] = useState({})
  const [formState, setFormState] = useState()

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    const generateYears = () => {
      const startYear = 2023
      const currentYear = new Date().getFullYear()
      const loopNumber = currentYear - startYear + 5
      const years = []
      let year = startYear
      for (let i = 0; i < loopNumber; i++) {
        years.push({ label: year, value: year })
        year += 1
      }

      setYears(years)
    }

    const fetchVehicles = async () => {
      const response = await request(
        {
          url: `/trans-vehicles/names`,
        },
        false
      )

      if (response?.result && Array.isArray(response.dbResult)) {
        const vehicles = response.dbResult.map(i => ({
          ...i,
          label: getTranslatedOption(i, "type", locale),
          value: i.id,
        }))
        setVehicles(vehicles)
      }
    }

    const fetchMarketingPlaces = async () => {
      const response = await request(
        {
          url: `/company-marketing-place`,
        },
        false
      )

      if (response?.result && response?.dbResult?.length > 0) {
        const marketingPlaces = response.dbResult.map(i => ({
          ...i,
          label: i.setMarketingPlaceName,
          value: i.setMarketingPlace_id,
        }))

        setMarketingPlaces(marketingPlaces)
        if (marketingPlaces.length === 1) {
          setSelectedMarketingPlace(marketingPlaces[0])
        }
      }
    }

    ;(async () => {
      setLoading(true)
      generateYears()
      await Promise.allSettled([fetchVehicles(), fetchMarketingPlaces()])
      setLoading(false)
    })()
  }, [])

  const submitHandler = async data => {
    setFormState(data)
    setSearching(true)
    const payload = {
      onlyMembers: data.onlyMembers.value,
      vehicle_id: data.vehicle.value,
      setMarketingPlace_id: data.marketingPlace.value,
      location_id_start: data.locationStart.value,
      searchTableStart_id: data.locationStart.searchTable_id,
      location_id_end: data.locationEnd.value,
      searchTableEnd_id: data.locationEnd.searchTable_id,
      year: data.year.value,
      currency_id: 2,
    }

    const response = await request(
      {
        url: "/transfer-price-daily/fetch-by-conditions",
        params: payload,
      },
      false
    )
    if (response?.result && Array.isArray(response?.dbResult)) {
      prepareData(response.dbResult)
    }
    setSearching(false)
  }

  const prepareData = prices => {
    const pricedDates = prices.map(i => new Date(i.date))
    setModifiers({ pricedDate: pricedDates })
    setPrices(prices)
  }

  let Content = null
  if (loading) {
    Content = <Spinner size="lg" color="primary" className="spinner" />
  } else if (errorMessage) {
    Content = <Alert color="danger"> {t(errorMessage)}</Alert>
  } else {
    Content = (
      <>
        <Inputs
          vehicles={vehicles}
          years={years}
          marketingPlaces={marketingPlaces}
          selectedMarketingPlace={selectedMarketingPlace}
          submitHandler={submitHandler}
          loading={searching}
        />
        {searching ? (
          <Spinner size="lg" color="primary" className="spinner" />
        ) : (
          <Calendar
            modifiers={modifiers}
            selectedYear={formState?.year?.value}
            prices={prices}
          />
        )}
      </>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Transfer")}
          breadcrumbItem={t("Pricing Report")}
        />
        {Content}
      </Container>
    </div>
  )
}

export default TransferPricingReport
