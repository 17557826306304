import Select from "react-select"
import { IoMdClose } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useCallback, useState, useRef } from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import vLogo from "assets/images/vDarkLogo.png"
import styles from "./customMap.module.scss"

const libraries = ["places"]

const CustomMap = props => {
  const { lat, lng, isModalOpen } = props
  const isCoordinateProvided = !!lat && !!lng

  const center = {
    lat: lat || 38.9637,
    lng: lng || 35.2433,
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={libraries}
    >
      {isCoordinateProvided ? (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: 4,
            opacity: 0.6,
          }}
          center={center}
          zoom={10}
          options={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: false,
            clickableIcons: false,
          }}
        >
          <Marker position={center} />
        </GoogleMap>
      ) : (
        <img src={vLogo} className={styles.img} />
      )}
      {isModalOpen && <MapModal {...props} />}
    </LoadScript>
  )
}

const MapModal = props => {
  const { lat, lng, isModalOpen, setIsModalOpen, setCoordinate, setGoogleName, setGoogleAddress, setGooglePhoneNumber } = props
  const mapRef = useRef(null)
  const { t } = useTranslation()
  const defaultCoordinate = {
    lat: lat || 38.9637,
    lng: lng || 35.2433,
  }
  const [newCenter, setNewCenter] = useState(defaultCoordinate)
  const [marker, setMarker] = useState(defaultCoordinate)

  const isCoordinateProvided = !!lat && !!lng

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  })

  const onLoad = useCallback(
    map => {
      if (!isCoordinateProvided) {
        map.fitBounds({
          south: 35.80859185933293,
          west: 25.44081416441787,
          north: 42.36669994962167,
          east: 44.81784494793906,
        })
        mapRef.current = map
      }
    },
    [isCoordinateProvided]
  )

  const selectChangeHandler = useCallback(
    async item => {
      placesService.getDetails({ placeId: item.value }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat = place.geometry.location.lat()
          const lng = place.geometry.location.lng()
          const newCoordinate = { lat, lng }
          setNewCenter(newCoordinate)
          setMarker(newCoordinate)
          setGoogleName(place?.name)
          setGoogleAddress(place?.formatted_address)
          setGooglePhoneNumber(place?.international_phone_number)
          mapRef?.current?.fitBounds(place.geometry.viewport)
        }
      })
    },
    [placesService]
  )


  const saveHandler = useCallback(() => {
    setCoordinate(marker)
    setIsModalOpen(false)
  }, [marker])

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={setIsModalOpen.bind(null, false)}
      size="xl"
    >
      <ModalBody className={styles.modalBody}>
        <div className={styles.selectContainer}>
          <Select
            options={placePredictions.map(i => ({
              ...i,
              label: i.description,
              value: i.place_id,
            }))}
            isLoading={isPlacePredictionsLoading}
            onInputChange={v => getPlacePredictions({ input: v })}
            onChange={selectChangeHandler}
            placeholder={t("Search a place...")}
            noOptionsMessage={() => t("No Options")}
          />
        </div>
        <GoogleMap
          mapContainerStyle={{
            height: "calc(100vh - 140px)",
            width: "100%",
            borderRadius: 4,
          }}
          center={newCenter}
          zoom={10}
          options={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: false,
            clickableIcons: true,
            draggableCursor: "pointer",
            mapId: "922ce486e8d5e261",
          }}
          onLoad={onLoad}
        >
          <Marker position={marker} draggable={false} />
        </GoogleMap>
        <div
          className={styles.closeContainer}
          onClick={setIsModalOpen.bind(null, false)}
        >
          <IoMdClose size={26} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={setIsModalOpen.bind(null, false)}>
          {t("Close")}
        </Button>
        <Button color="primary" onClick={saveHandler}>
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CustomMap
