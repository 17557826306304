import React, { Component } from "react"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import TimePicker from "@mui/lab/TimePicker"
import TextField from "@mui/material/TextField"
import { withTranslation } from "react-i18next"

import styles from "./styles.module.scss"

class CustomTimePicker extends Component {
  state = {
    isOpen: false,
  }

  setOpen = () => {
    this.setState({ isOpen: true })
  }

  setClose = () => {
    this.setState({ isOpen: false })
  }

  formatTime = date => {
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let ampm = hours >= 12 ? "pm" : "am"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes
    var strTime = hours + ":" + minutes + " " + ampm
    return strTime
  }

  render() {
    const {
      label,
      value,
      onChange,
      placeholder,
      textFieldClassName,
      inputClassName,
      t,
    } = this.props
    const { isOpen } = this.state

    const formattedValue = value ? this.formatTime(value) : ""

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          open={isOpen}
          onOpen={this.setOpen}
          onClose={this.setClose}
          value={value}
          label={t(label)}
          onChange={onChange}
          renderInput={params => {
            return (
              <TextField
                {...params}
                className={textFieldClassName || styles.customTextFiled}
                inputProps={{
                  className: inputClassName || styles.input,
                  placeholder: t(placeholder || "hh:mm (a/p)m"),
                  readOnly: true,
                }}
                onClick={this.setOpen}
                value={formattedValue}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }
}

export default withTranslation()(CustomTimePicker)
