import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import EditModal from "./EditModal"
import RoomTable from "components/Hotel/RoomTable/index"

const CancelToken = axios.CancelToken
let cancel

class RoomPhotos extends Component {
  state = {
    isModalOpen: false,
    hotel_id: "",
    rooms: [],
    roomTypes: [],
    roomNames: [],
    selectedRowId: "",
    selectedRowData: {},
    showWarningMessage: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    roomsLoading: false,
    errorMessage: "",
    error: false,
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const hotel_id = await this.fetchHotelData()
    Promise.allSettled([
      this.fetchRooms(hotel_id),
      this.fetchRoomTypes(),
      this.fetchRoomNames(),
    ])
    this.setState({ loading: false })
  }

  fetchHotelData = async () => {
    try {
      const url = `/hotel-registration`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {},
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        const hotel_id = response.data.dbResult.id
        this.setState({ hotel_id })
        return hotel_id
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRoomTypes = async () => {
    try {
      const url = `/hotel-set-room-types`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)
      if (response.data.result) {
        this.setState({ roomTypes: response.data.dbResult })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRoomNames = async () => {
    try {
      const url = `/hotel-set-room-names`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        this.setState({ roomNames: response.data.dbResult })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRooms = async param_hotel_id => {
    try {
      this.setState({ roomsLoading: true })
      const { activePage, itemsCountPerPage, searchText, hotel_id } = this.state
      const url = `/hotel-rooms/fetch-by-page-number`

      if (cancel !== undefined) {
        cancel()
      }

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          page: activePage,
          limit: itemsCountPerPage,
          searchText,
          hotel_id: hotel_id || param_hotel_id,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: {},
          rooms: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ errorMessage: "Something went wrong!!" })
    }
    this.setState({ roomsLoading: false })
  }

  onSelectTableRow = selectedRowId => {
    let selectedRowData = this.state.rooms.find(item => {
      return item.id == selectedRowId
    })
    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchRooms)
  }

  refreshTable = () => {
    this.setState(
      {
        activePage: 1,
        searchText: "",
        totalItemsCount: 0,
      },
      this.fetchRooms
    )
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      loading,
      roomsLoading,
      error,
      errorMessage,
      selectedRowId,
      rooms,
      isModalOpen,
      selectedRowData,
      showWarningMessage,
      roomTypes,
      roomNames,
      hotel_id,
    } = this.state
    const { t } = this.props

    let Content = null
    if (loading || roomsLoading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <RoomTable
          rooms={rooms}
          roomTypes={roomTypes}
          roomNames={roomNames}
          onSelectTableRow={this.onSelectTableRow}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={t("Rooms")} breadcrumbItem={t("Room Photos")} />
            <ControlButtons
              displayAddBtn={true}
              addTitle="Add/Remove Photo"
              displayEditBtn={false}
              displayDeleteBtn={false}
              onAddBtn={() => {
                if (selectedRowId) {
                  this.setState({ isModalOpen: true })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState({ searchText, activePage: 1 }, this.fetchRooms)
              }}
            />
            {Content}
          </Container>
        </div>
        {isModalOpen && (
          <EditModal
            isOpen={true}
            close={() => this.setState({ isModalOpen: false })}
            room={selectedRowData}
            hotel_id={hotel_id}
            refreshTable={this.refreshTable}
          />
        )}

        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(connect(mapStateToProps, {})(RoomPhotos))
