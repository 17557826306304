import React from "react"
import { Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import CustomCheckbox from "../../../Common/CustomCheckbox/index"
import styles from "./styles.module.scss"

export default function Checkboxes({ label, items, onCheck, className }) {
  const { t } = useTranslation()
  return (
    <div className={className || styles.container}>
      <Label className={styles.label}>{t(label)}</Label>
      <div className={styles.checkboxes}>
        {items?.map(i => (
          <CustomCheckbox
            key={i.id}
            id={i.id}
            title={i.name}
            isChecked={i.isChecked}
            onClick={onCheck}
          />
        ))}
      </div>
    </div>
  )
}
