import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { changeSidebarType } from "store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import styles from "./styles.module.scss"

class Layout extends Component {
  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0)
    this.resizeHandler()
    window.addEventListener("resize", this.resizeHandler)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeHandler)
  }

  resizeHandler = () => {
    const innerWidth = window.innerWidth
    const { leftSideBarType, changeSidebarType } = this.props
    if (innerWidth > 992 && leftSideBarType !== "default") {
      changeSidebarType("default")
    } else if (innerWidth <= 992 && leftSideBarType === "default") {
      changeSidebarType("condensed")
    }
  }

  toggleMenuCallback = () => {
    const { leftSideBarType, changeSidebarType } = this.props
    if (leftSideBarType === "default") {
      changeSidebarType("condensed")
    } else if (leftSideBarType === "condensed") {
      changeSidebarType("default")
    }
  }

  render() {
    const { leftSideBarType, changeSidebarType, children } = this.props
    return (
      <div id="layout-wrapper" className={styles.layout}>
        <Sidebar type={leftSideBarType} setType={changeSidebarType} />
        <div className={styles.rightSide}>
          <Header
            toggleMenuCallback={this.toggleMenuCallback}
            leftSideBarType={leftSideBarType}
          />
          <div className="main-content">{children}</div>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStateToProps, {
  changeSidebarType,
})(withRouter(Layout))
