import React from "react"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { FormControl, InputLabel, Box, MenuItem, Select } from "@mui/material"

import styles from "./styles.module.scss"

const NightsPlanForm = props => {
  const { minNightDay, setMinNightDay } = props

  const { t } = useTranslation()
  const handleChange = event => {
    setMinNightDay(event.target.value)
  }
  return (
    <div className={styles.nightPlanForm}>
      <h6>{t("Select a number between 1 and 7")}</h6>

      <div className={styles.row}>
        <FormControl>
          <InputLabel type="text" className={styles.label}>
            {t("Nights")}
          </InputLabel>

          <Select
            className={styles.select}
            value={minNightDay}
            label={t("Nights")}
            onChange={handleChange}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
          </Select>
        </FormControl>
        <p> {t("night or more")} </p>
      </div>
    </div>
  )
}

export default NightsPlanForm
