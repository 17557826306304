import React from "react"
import { Card, CardBody, Input, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

export default function Checkboxes({
  dataList,
  checkHandler,
  label,
  children,
}) {
  const { t } = useTranslation()
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{t(label)}</h5>
        <Row className={styles.checkboxes}>
          {dataList?.map(item => (
            <Col
              key={item.id + "id"}
              onClick={() => checkHandler(item.id)}
              className={styles.singleCheckbox}
            >
              <Input
                type="checkbox"
                className={styles.input}
                name={item.name}
                id={item.id}
                onChange={() => {}}
                checked={item.isChecked || false}
              />
              <span>{item.name}</span>
            </Col>
          ))}
        </Row>
        {children}
      </CardBody>
    </Card>
  )
}
