import { components } from "react-select"
import {
  MdOutlineAirplanemodeActive,
  MdOutlineVilla,
  MdHotel,
  MdLocationPin,
} from "react-icons/md"
import { FaHospital } from "react-icons/fa"
import { GiBattleship } from "react-icons/gi"

import styles from "./inputs.module.scss"

const SelectOption = props => {
  const { locationType_id, searchTable_id, label } = props.data

  let icon = null
  if (searchTable_id === 1) {
    switch (locationType_id) {
      case 1:
        icon = <MdOutlineAirplanemodeActive size={24} />
        break
      case 2:
        icon = <FaHospital size={24} />
        break
      case 3:
        icon = <GiBattleship size={24} />
        break
    }
  } else if (searchTable_id === 2) {
    icon = <MdLocationPin size={24} />
  } else if (searchTable_id === 3) {
    icon = <MdLocationPin size={24} />
  } else if (searchTable_id === 4) {
    icon = <MdHotel size={24} />
  } else if (searchTable_id === 5) {
    icon = <MdOutlineVilla size={24} />
  }

  return (
    <components.Option {...props}>
      <div className={styles.selectOption}>
        <span>{icon}</span>
        <span>{label}</span>
      </div>
    </components.Option>
  )
}

export default SelectOption
