import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import { createContext, useContext, useState, useEffect, useMemo } from "react"

import { schema } from "./schema"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

const TicketContext = createContext()
export const useTicketContext = () => {
  const context = useContext(TicketContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const TicketContextProvider = props => {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")

  const [selectedTimes, setSelectedTimes] = useState([])
  const [types, setTypes] = useState([])
  const [ticketTypeNames, setTicketTypeNames] = useState([])
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const { request } = useRequest(setLoading, setErrorMessage)
  const locale = useTranslation().i18n.language

  const { register, errors, setValue, watch, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const fetchTicketTypes = async () => {
      const response = await request(
        {
          url: "/ticket-types",
        },
        false
      )

      if (response && response.result && Array.isArray(response.dbResult)) {
        setTypes(
          response.dbResult.map(i => ({
            label: getLabelByLanguage(i, locale),
            value: i.id,
          }))
        )
      }
    }

    const fetchCountries = async () => {
      const response = await request(
        {
          url: "/countries",
        },
        false
      )

      setCountries(
        response.countries.map(i => ({
          label: getLabelByLanguage(i, locale),
          value: i.id,
        }))
      )
    }

    const main = async () => {
      await Promise.allSettled([fetchTicketTypes(), fetchCountries()])
    }
    main()
  }, [])

  const fetchTicketTypeNames = async type_id => {
    const response = await request(
      {
        url: "/ticket-names/fetch-by-type",
        params: { type_id },
      },
      false
    )

    setTicketTypeNames(
      response.dbResult.map(i => ({
        label: getLabelByLanguage(i, locale),
        value: i.id,
      }))
    )
  }

  const fetchProvinces = async country_id => {
    const response = await request(
      {
        url: "/provinces",
        params: { country_id },
      },
      false
    )

    setProvinces(
      response.provinces.map(i => ({
        label: getLabelByLanguage(i, locale),
        value: i.id,
      }))
    )
  }

  const fetchDistricts = async province_id => {
    const response = await request(
      {
        url: "/districts",
        params: { province_id },
      },
      false
    )

    setDistricts(
      response.districts.map(i => ({
        label: getLabelByLanguage(i, locale),
        value: i.id,
      }))
    )
  }

  const submitTicket = async (method, payload) => {
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/ticket",
        method,
        data: payload,
      },
      false
    )

    setSubmitLoading(false)

    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  const value = useMemo(() => {
    const useFormResult = {
      register,
      errors,
      watch,
      setValue,
      handleSubmit,
      control,
    }

    const functions = {
      fetchTicketTypeNames,
      fetchProvinces,
      fetchDistricts,
      submitTicket,
    }

    const setters = {
      setLoading,
      setErrorMessage,
      setShowSuccessAlert,
      // info
      setTypes,
      setTicketTypeNames,
      setCountries,
      setProvinces,
      setDistricts,
      setSelectedTimes,
    }
    const values = {
      loading,
      submitLoading,
      errorMessage,
      showSuccessAlert,
      // info
      types,
      ticketTypeNames,
      countries,
      provinces,
      districts,
      selectedTimes,
    }
    return {
      setters,
      values,
      functions,
      useFormResult,
    }
  }, [
    loading,
    submitLoading,
    errorMessage,
    showSuccessAlert,
    // info
    types,
    ticketTypeNames,
    countries,
    provinces,
    selectedTimes,
    districts,
    // use form result
    watch,
    register,
    errors,
    setValue,
    handleSubmit,
    control,
  ])
  return <TicketContext.Provider value={value} {...props} />
}
