import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Button,
  Spinner,
  Label,
  Alert,
} from "reactstrap"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { FaRegCalendarCheck } from "react-icons/fa"
import { MdShoppingCart } from "react-icons/md"
import { BiTrendingUp } from "react-icons/bi"
import { Box, Slider } from "@mui/material"
import SweetAlert from "react-bootstrap-sweetalert"

import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import Breadcrumbs from "components/Common/Breadcrumb"
import SuccessAlert from "components/Common/SuccessAlert"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import { addDays, getTodayDate } from "utils/dateTimeFunctions"
import { getLabelByLanguage, all } from "utils/other"

import styles from "./styles.module.scss"

export default function index({ title, opportunityType_id }) {
  const company_id = useSelector(state => state.Company.company.id)
  const [hotel_id, setHotel_id] = useState("")
  const [hotel, setHotel] = useState()
  const [startDate, setStartDate] = useState(getTodayDate())
  const [endDate, setEndDate] = useState(addDays(getTodayDate(), 7))
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [defaultCommission, setDefaultCommission] = useState("")
  const [commission, setCommission] = useState(10)
  const [showCantSaveModal, setShowCantSaveModal] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )

      if (response?.dbResult) {
        const hotel_id = response?.dbResult?.id
        setHotel_id(hotel_id)
        setHotel(response?.dbResult)
        return hotel_id
      }
    }

    const fetchDefaultCommission = async company_id => {
      const response = await request(
        {
          url: "/company-types/fetch-by-companyId",
          params: { company_id },
        },
        false
      )
      if (response?.dbResult) {
        const defaultCommission = response?.dbResult[0]?.defaultCommission
        setDefaultCommission(defaultCommission)
        return defaultCommission
      }
    }

    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        ...i,
        id: i.id[1],
        isChecked: false,
      }))

      marketingPlaces.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })

      setMarketingPlaces(marketingPlaces)
    }

    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      await Promise.allSettled([
        fetchSetMarketingPlaces(),
        fetchDefaultCommission(company_id),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (marketingPlaces.length > 0) {
      const newMP = [...marketingPlaces]
      newMP[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newMP)
    }
  }, [locale])

  // CHECK HANDLERS
  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const validSubmitHandler = () => {
    const submit = async newData => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/hotel-opportunities",
          method: "post",
          data: newData,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")

    if (opportunityType_id === 1 && !hotel?.isConfirmed) {
      return setShowCantSaveModal(true)
    }

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    let newData = {
      startDate,
      endDate,
      setMarketingPlace_ids,
      setOpportunityType_id: opportunityType_id,
      defaultCommission,
      commission,
      status: 1,
      hotel_id,
      company_id,
    }
    submit(newData)
  }

  const resetState = () => {
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setCommission(10)
    setError(null)
  }

  const resetDatesHandler = () => {
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setError(null)
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Influencer Marketing")}
        />
        {loading && <CustomSpinner color="primary" />}
        {!loading && (
          <>
            <Card>
              <CardBody>
                <header className={styles.header}>
                  <h4>{t(title)}</h4>
                  <p>
                    {t(
                      "You can fill your empty rooms and attract the guests you want to target."
                    )}
                  </p>
                </header>
                <form>
                  <FormGroup className="mb-1">
                    <Label className={styles.label}>
                      <FaRegCalendarCheck size={20} />
                      {t("Select the dates on which it will be active.")}
                    </Label>
                    <Row className={styles.row1}>
                      {/* DATE INPUTS */}
                      <FormGroup className={styles.singleDateContainer}>
                        <Label>{t("From")}</Label>
                        <CustomDayPicker
                          onChange={date => {
                            setStartDate(date)
                            if (date.getTime() > endDate.getTime()) {
                              setEndDate(date)
                            }
                          }}
                          value={startDate}
                          disabledDays={[]}
                        />
                      </FormGroup>
                      <FormGroup className={styles.singleDateContainer}>
                        <Label>{t("To")}</Label>
                        <CustomDayPicker
                          onChange={setEndDate}
                          value={endDate}
                          disabledDays={[{ before: startDate }]}
                        />
                      </FormGroup>

                      <Button
                        color="primary"
                        onClick={resetDatesHandler}
                        className={styles.resetBtn}
                      >
                        {submitLoading && <Spinner size="sm" color="white" />}{" "}
                        {t("Reset Dates")}
                      </Button>
                    </Row>
                  </FormGroup>
                </form>
              </CardBody>
            </Card>

            {marketingPlaces.length > 2 && (
              <Card>
                <CardBody>
                  <Label className={styles.label}>
                    <MdShoppingCart size={20} />
                    {t("Sales Channels")}
                  </Label>
                  <Row className={styles.checkboxRow}>
                    {marketingPlaces.map(item => (
                      <div
                        key={item.id}
                        className={styles.checkboxWrapper}
                        onClick={() => marketingPlaceCheckHandler(item.id)}
                      >
                        <Input
                          type="checkbox"
                          className={styles.checkboxInput}
                          name={item.name}
                          id={item.id}
                          onChange={() => {}}
                          checked={item.isChecked || false}
                        />
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            )}

            <Card>
              <CardBody>
                <Label className={styles.label}>
                  <BiTrendingUp size={20} />
                  {t("What would you like your new commission rate to be?")}
                </Label>
                <p>
                  {t(
                    "The final step! Move the blue dot to increase your commission. The higher you raise, the more visible you will be on selected dates."
                  )}
                </p>
                <p className={styles.commission}>
                  {t("Current Commission")} {defaultCommission}%
                </p>
                <p>
                  {t(
                    "Move the circle icon along the line to increase your new commission rate."
                  )}
                </p>
                <Box className={styles.sliderWrapper}>
                  <Slider
                    defaultValue={+defaultCommission}
                    valueLabelDisplay="auto"
                    marks
                    min={10}
                    max={50}
                    step={5}
                    value={commission}
                    onChange={e => setCommission(e.target.value)}
                  />
                </Box>
                <p className={styles.commission}>
                  {t("Total Commission")} {defaultCommission + commission}%
                </p>
              </CardBody>
            </Card>
            {error && (
              <Alert color="danger" className="mt-n3">
                {" "}
                {t(error)}
              </Alert>
            )}
            <Button
              color="primary"
              onClick={validSubmitHandler}
              className={styles.submit}
            >
              {t("Apply new commission rate")}{" "}
              {submitLoading && <Spinner size="sm" color="white" />}
            </Button>
          </>
        )}
      </Container>

      {HotelNotRegisteredComp}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            resetState()
          }}
        />
      )}
      {showCantSaveModal && (
        <SweetAlert
          warning
          title={t("Warning!")}
          confirmBtnBsStyle="warning"
          onConfirm={setShowCantSaveModal.bind(null, false)}
          confirmBtnText={t("OK")}
        >
          <h4>
            {t(
              "To use this feature, please contact Vihobook Customer Service."
            )}
          </h4>
        </SweetAlert>
      )}
    </main>
  )
}
