import * as yup from "yup"

export const schema = yup.object({
  ticketType_id: yup.string().required("This field is required!"), // input un name i
  ticketTypeName_id: yup.string().required("This field is required!"),
  name: yup
    .string()
    .required("This field is required!")
    .min(3, "Too short!")
    .max(50, "Too long!"),
  maxCapacity: yup
    .number()
    .required("This field is required!")
    .typeError("Must be a number!"),
  status: yup.boolean(),
  ageMin: yup
    .number()
    .required("This field is required!")
    .typeError("Must be a number!"),
  ageMax: yup
    .number()
    .required("This field is required!")
    .typeError("Must be a number!"),
  isChildAllowed: yup.boolean(),
  chd1: yup.number().when("isChildAllowed", {
    is: true,
    then: yup
      .number()
      .required("This field is required!")
      .typeError("Must be a number!"),
    otherwise: yup.number().nullable(),
  }),
  chd2: yup.number().when("isChildAllowed", {
    is: true,
    then: yup
      .number()
      .required("This field is required!")
      .typeError("Must be a number!")
      .test("chd2", "Must be greater than chd1!", function (value) {
        return value > this.parent.chd1
      }),
    otherwise: yup.number().nullable(),
  }),

  country_id: yup.string().required("This field is required!"),
  province_id: yup.string().required("This field is required!"),
  district_id: yup.string().required("This field is required!"),
  address: yup
    .string()
    .required("This field is required!")
    .min(5, "Too short!")
    .max(50, "Too long!"),
  latitude: yup.string().required("Please add coordinate!"),
  longitude: yup.string().required("Please add coordinate!"),
})
