import React, { useState } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit"
import { AvInput } from "availity-reactstrap-validation-safe"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

const InformationInputs = props => {
  const [activeTab, setActiveTab] = useState("Turkish")
  const { t } = useTranslation()

  const handleLinkClick = value => {
    if (value === activeTab) {
      return
    }
    setActiveTab(value)
  }
  const TabLink = title => (
    <MDBTabsItem>
      <MDBTabsLink
        onClick={() => handleLinkClick(title)}
        active={activeTab === title}
      >
        {t(title)}
      </MDBTabsLink>
    </MDBTabsItem>
  )

  const TabsPane = (input, name) => (
    <MDBTabsPane show={activeTab === input}>
      <AvInput name={name} type="textarea" className={styles.tabInput} />
    </MDBTabsPane>
  )
  return (
    <Card className={styles.card}>
      <CardBody>
        <CardTitle>{t("Room Description")}</CardTitle>
        <hr />
        <MDBTabs pills className="mb-3">
          {TabLink("Turkish")}
          {TabLink("English")}
          {TabLink("French")}
          {TabLink("German")}
          {TabLink("Russian")}
          {TabLink("Arabic")}
        </MDBTabs>

        <MDBTabsContent>
          {TabsPane("Turkish", "description_tr")}
          {TabsPane("English", "description_en")}
          {TabsPane("French", "description_fr")}
          {TabsPane("German", "description_de")}
          {TabsPane("Russian", "description_ru")}
          {TabsPane("Arabic", "description_ar")}
        </MDBTabsContent>
      </CardBody>
    </Card>
  )
}

export default InformationInputs
