import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react"

import useRequest from "hooks/useRequest"

const ReservationContext = createContext()
export const useReservationContext = () => {
  const context = useContext(ReservationContext)
  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }
  return context
}

export const ReservationProvider = props => {
  const [resDetails, setResDetails] = useState({})
  const [promotionNames, setPromotionNames] = useState([])
  const [resStatuses, setResStatuses] = useState([])
  const [resCancelLoading, setResCancelLoading] = useState(false)
  const [resCancelSucceed, setResCancelSucceed] = useState(false)
  const [resNotUsedLoading, setResNotUsedLoading] = useState(false)
  const [resNotUsedSucceed, setResNotUsedSucceed] = useState(false)
  const [isDailySectionOpen, setIsDailySectionOpen] = useState(false)
  const [isPolicySectionOpen, setIsPolicySectionOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)
  const resDetails_id = props.children.props.match.params.id

  useEffect(() => {
    const fetchReservationById = async () => {
      const response = await request(
        {
          url: `/transfer-reservations/${resDetails_id}`,
        },
        false
      )

      setResDetails(response?.dbResult)
      return response?.dbResult
    }

    const fetchPromotionNamesByIds = async ids => {
      const response = await request(
        {
          url: `/transfer-promotions/fetch-names-by-ids`,
          method: "post",
          data: { ids },
        },
        false
      )
      setPromotionNames(response?.dbResult)
    }

    const fetchResStatuses = async ids => {
      const response = await request(
        {
          url: `/reservation-statuses`,
        },
        false
      )
      setResStatuses(response?.resStatuses)
    }

    ;(async () => {
      setLoading(true)
      const details = await fetchReservationById()
      let promotionIds = new Set()
      const ids = details.reservation.promotion_ids?.split(", ").map(id => +id)
      ids?.forEach(id => promotionIds.add(id))
      promotionIds = Array.from(promotionIds) // convert set to array
      if (promotionIds.length > 0) {
        fetchPromotionNamesByIds(promotionIds)
      }
      await fetchResStatuses()
      setLoading(false)
    })()
  }, [resDetails_id])

  const updateResStatus = useCallback(
    async (newStatus_id, resStatusDescription) => {
      const response = await request(
        {
          url: "/transfer-reservations/update-status",
          method: "put",
          data: {
            id: resDetails_id,
            res_id: +resDetails?.reservation.res_id,
            setStatus_id: newStatus_id,
            resStatusDescription,
          },
        },
        false
      )
      if (response?.result) {
        const newResDetails = { ...resDetails }
        newResDetails.reservation.resStatus_id = newStatus_id
        setResDetails(newResDetails)
      }

      return response?.result
    },
    [resDetails_id, resDetails?.reservation, request]
  )

  const cancelResHandler = useCallback(async () => {
    setResCancelLoading(true)
    const succeed = await updateResStatus(6)
    setResCancelLoading(false)
    if (succeed) {
      setResCancelSucceed(true)
      setTimeout(() => setResCancelSucceed(false), 6000)
    }
  }, [updateResStatus])

  const notUsedResHandler = useCallback(async () => {
    setResNotUsedLoading(true)
    const succeed = await updateResStatus(4)
    setResNotUsedLoading(false)
    if (succeed) {
      setResNotUsedSucceed(true)
      setTimeout(() => setResNotUsedSucceed(false), 6000)
    }
  }, [updateResStatus])

  const printHandler = useCallback(() => {
    const print = () => {
      window.print()
    }
    setIsDailySectionOpen(true)
    setIsPolicySectionOpen(true)
    setTimeout(print, 1000)
  }, [])

  const value = useMemo(() => {
    const setters = {
      setLoading,
      setError,
      setResDetails,
      setIsDailySectionOpen,
      setIsPolicySectionOpen,
    }
    const values = {
      loading,
      error,
      resDetails,
      promotionNames,
      resStatuses,
      resCancelLoading,
      resCancelSucceed,
      resNotUsedSucceed,
      resNotUsedLoading,
      isPolicySectionOpen,
      isDailySectionOpen,
    }
    const functions = {
      cancelResHandler,
      printHandler,
      updateResStatus,
      notUsedResHandler,
    }
    return { setters, values, functions }
  }, [
    // SETTERS
    setIsDailySectionOpen,
    setIsPolicySectionOpen,
    setLoading,
    // VALUES
    error,
    resDetails,
    promotionNames,
    resStatuses,
    resCancelLoading,
    resNotUsedLoading,
    resCancelSucceed,
    isPolicySectionOpen,
    isDailySectionOpen,
    // FUNCTIONS
    cancelResHandler,
    printHandler,
    updateResStatus,
    notUsedResHandler,
  ])

  return <ReservationContext.Provider value={value} {...props} />
}
