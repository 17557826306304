import { capitalize } from "lodash"

export const getCurrencySignById = currency_id => {
  let sign
  switch (currency_id) {
    case 1:
      // dollar
      sign = "$"
      break
    case 2:
      // EURO
      sign = "€"
      break
    case 3:
      // tl
      sign = "₺"
      break
    case 4:
      // pound
      sign = "£"
      break
  }
  return sign
}

export const turkishToEnglish = text => {
  return text
    .replace(/Ğ/g, "g")
    .replace(/Ü/g, "u")
    .replace(/Ş/g, "s")
    .replace(/I/g, "i")
    .replace(/İ/g, "i")
    .replace(/Ö/g, "o")
    .replace(/Ç/g, "c")
    .replace(/ğ/g, "g")
    .replace(/ü/g, "u")
    .replace(/ş/g, "s")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ç/g, "c")
}

export const encodeForUrl = (name = "") => {
  return turkishToEnglish(
    name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[?=]/g, "")
      .replace(/[)]/g, "")
      .replace(/[(]/g, "")
  )
}

export const getLabelByLanguage = (i, locale) => {
  let label = i.name_en || i.name
  switch (locale) {
    case "tr":
      label = i.name_tr
      break
    case "fr":
      label = i.name_fr
      break
    case "de":
      label = i.name_de
      break
    case "ar":
      label = i.name_ar
      break
    case "ru":
      label = i.name_ru
      break
    default:
      label = i.name_en || i.name
      break
  }

  return label
}

export const all = {
  name_en: "All",
  name_tr: "Hepsi",
  name_fr: "All",
  name_de: "All",
  name_ru: "All",
  name_ar: "All",
}

export const initialApplicationDays_fullName = [
  { id: 0, name_en: "Sunday", isChecked: true },
  { id: 1, name_en: "Monday", isChecked: true },
  { id: 2, name_en: "Tuesday", isChecked: true },
  { id: 3, name_en: "Wednesday", isChecked: true },
  { id: 4, name_en: "Thursday", isChecked: true },
  { id: 5, name_en: "Friday", isChecked: true },
  { id: 6, name_en: "Saturday", isChecked: true },
]

export const initialApplicationDays_shortName = [
  { id: 0, name_en: "Sun", isChecked: true },
  { id: 1, name_en: "Mon", isChecked: true },
  { id: 2, name_en: "Tue", isChecked: true },
  { id: 3, name_en: "Wed", isChecked: true },
  { id: 4, name_en: "Thu", isChecked: true },
  { id: 5, name_en: "Fri", isChecked: true },
  { id: 6, name_en: "Sat", isChecked: true },
]

export const selectError = {
  control: provided => ({
    ...provided,
    borderColor: "#f46a6a",
    boxShadow: "none",
    "&:hover": { borderColor: "#f46a6a" },
  }),
}

export const getTranslatedOption = (data, prefix, locale) => {
  const fields = Object.entries(data)?.filter(([key]) =>
    key?.startsWith(prefix)
  )
  const result = fields?.find(item => item[0]?.split("_")[1] === locale)
  return result[1] || ""
}

export const formatMoney = (value = 0, decimalLength = 0) => {
  const x = 3 // length of whole part
  const s = "," // section delimiter
  const c = "." // decimal delimiter

  const re =
      "\\d(?=(\\d{" +
      (x || 3) +
      "})+" +
      (decimalLength > 0 ? "\\D" : "$") +
      ")",
    num = value.toFixed(Math.max(0, ~~decimalLength))

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  )
}

export const getFormattedDistance = distance => {
  if (distance < 1000) {
    return distance + " mt"
  } else {
    return distance / 1000 + " Km"
  }
}

export const snakeToCamelCase = variable => {
  return variable.split("_").reduce((prev, curr, idx) => {
    return prev + (idx === 0 ? curr.toLowerCase() : capitalize(curr))
  }, "")
}
