import React from "react"
import { Card, CardContent } from "@mui/material"
import { useTranslation } from "react-i18next"

import { useChildPolicyContext } from "../../context"
import styles from "./priceTable.module.scss"
import TableRow from "./TableRow"

export default function PriceTable({ data }) {
  const { selectedCurrency, isPercent } = useChildPolicyContext()
  const { t } = useTranslation()

  const colLabel = isPercent
    ? t("Percent(%)")
    : `${t("Price")} (${selectedCurrency?.label || ""})`

  return (
    <Card className={styles.card} variant="outlined">
      <CardContent className={styles.cardContent}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th colSpan={4}>
                {data[0][0]?.adultCount}.{" "}
                {t(`Person ${isPercent ? "Percent" : "Price"} Table`)}
              </th>
            </tr>
            <tr>
              <th>{t("First Child")}</th>
              <th>{colLabel}</th>
              <th>{t("Second Child & Beyond")}</th>
              <th>{colLabel}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <TableRow key={idx} groupNumber={idx + 1} data={item} />
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  )
}
