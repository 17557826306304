import React, { useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"
import WidgetPagination from "components/Common/WidgetPagination/index"
import styles from "./styles.module.scss"

const PartnersTable = props => {
  const [selectedRowId, setSelectedRowId] = useState("")

  const { t } = useTranslation()
  const {
    partners,
    onSelectTableRow,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const PartnersRows = partners?.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
          setSelectedRowId(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{item.CompanyName}</Td>
        <Td className={styles.dataCell}>{item.email}</Td>
        <Td className={styles.dataCell}>
          {item.Statu === true && item.SendStatu === false
            ? t("Pending Approval")
            : "" || (item.Statu === true && item.SendStatu === true)
            ? t("Approved")
            : t("Denied")}
        </Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className={`table-responsive ${styles.tContainer}`}>
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.th}>{t("Company Name")}</Th>
              <Th className={styles.th}>{t("Email")}</Th>
              <Th className={styles.th}>{t("Status")}</Th>
            </Tr>
          </Thead>

          <Tbody>{PartnersRows}</Tbody>
        </Table>
      </div>
      {hasPagination && (
        <WidgetPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default PartnersTable
