import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import styles from "./childDetails.module.scss"
import {
  formatMoney,
  getLabelByLanguage,
  getCurrencySignById,
} from "utils/other"

export default function ChildDetailsModal({
  isOpen,
  close,
  adultPrices,
  hotel_id,
  currency_id,
  childPolicy,
  roomData,
}) {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const [tablesData, setTablesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const currencySign = getCurrencySignById(currency_id)

  useEffect(() => {
    const fetchChildNames = async () => {
      const response = await request(
        {
          url: "/hotel-set-child-names",
        },
        false
      )

      const childNames = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
      }))
      return childNames
    }

    const fetchChildPolicyAge = async () => {
      const response = await request(
        {
          url: "/hotel-child-policy-age",
          params: { hotel_id },
        },
        false
      )

      const childPolicyAge = response?.dbResult?.sort(
        (a, b) => a.startAge - b.startAge
      )

      return childPolicyAge
    }

    const fetchChildPolicyDetail = async () => {
      const response = await request({
        url: "/hotel-child-policy-detail",
        params: { childPolicy_id: childPolicy.id },
      })
      if (response?.dbResult) {
        return response.dbResult
      }
    }

    ;(async () => {
      setLoading(true)
      const responses = await Promise.allSettled([
        fetchChildNames(),
        fetchChildPolicyAge(),
        fetchChildPolicyDetail(),
      ])
      setLoading(false)
      const childNames = responses[0].value
      const childPolicyAge = responses[1].value
      const childPolicyDetail = responses[2].value
      prepareTablesData(childNames, childPolicyAge, childPolicyDetail)
    })()
  }, [])

  const prepareTablesData = (childNames, childPolicyAge, childPolicyDetail) => {
    const isPercent = childPolicy.isPercent
    const newTablesData = Array(roomData.maxAdult)
      .fill(0)
      .map((_, idx) => {
        // prepare row by row, first and second child at the same time
        const adultCount = idx + 1
        return childPolicyAge.map(policyAge => {
          // find name
          let name = childNames.find(
            i => i.id === policyAge.setChildName_id
          ).name
          name = `${name} (${policyAge.startAge}-${policyAge.endAge})`
          // calculate price for first child
          let percentOrPrice1 = +childPolicyDetail.find(
            detail =>
              detail.childPolicyAge_id === policyAge.id &&
              detail.adultCount === adultCount &&
              detail.childCount === 1
          )?.percentOrPrice
          const adultPrice = +adultPrices[idx].price
          const coefficient = +adultPrices[idx].coefficient
          const childPrice1 = isPercent
            ? (percentOrPrice1 * coefficient) / 100
            : percentOrPrice1
          const ratio1 = isPercent
            ? percentOrPrice1
            : (percentOrPrice1 * 100) / coefficient
          // calculate price for second child
          let percentOrPrice2 = +childPolicyDetail.find(
            detail =>
              detail.childPolicyAge_id === policyAge.id &&
              detail.adultCount === adultCount &&
              detail.childCount === 2
          )?.percentOrPrice
          const childPrice2 = isPercent
            ? (percentOrPrice2 * coefficient) / 100
            : percentOrPrice2
          const ratio2 = isPercent
            ? percentOrPrice2
            : (percentOrPrice2 * 100) / coefficient

          return [
            {
              name,
              childPrice: childPrice1,
              ratio: formatMoney(+ratio1, 1),
              totalPrice: childPrice1 + adultPrice,
            },
            {
              name,
              childPrice: childPrice2,
              ratio: formatMoney(+ratio2, 1),
              totalPrice: childPrice1 + childPrice2 + adultPrice,
            },
          ]
        })
      })

    setTablesData(newTablesData)
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Child Pricing Details")}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body" style={{ minHeight: 200 }}>
        {loading && <Spinner size="lg" color="primary" className="spinner" />}
        {!loading &&
          tablesData.map((tableData, idx) => (
            <div className={styles.card} key={idx}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th colSpan={8}>
                      {idx + 1}. {t("Person")}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4}>{t("First Child")}</th>
                    <th colSpan={4}>{t("Second Child & Beyond")}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>{t("Ratio")}</th>
                    <th>{t("Child Price")}</th>
                    <th>
                      {idx + 1}. {t("Person 1 Child Total Price")}
                    </th>
                    <th></th>
                    <th>{t("Ratio")}</th>
                    <th>{t("Child Price")}</th>
                    <th>
                      {idx + 1}. {t("Person 2 Child Total Price")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, idx) => (
                    <tr key={idx}>
                      <td className="text-left">{row[0].name}</td>
                      <td>{row[0].ratio}%</td>
                      <td>
                        {currencySign}
                        {formatMoney(+row[0].childPrice, 2)}
                      </td>
                      <td>
                        {currencySign}
                        {formatMoney(+row[0].totalPrice, 2)}
                      </td>
                      <td className="text-left">{row[1].name}</td>
                      <td>{row[1].ratio}%</td>
                      <td>
                        {currencySign}
                        {formatMoney(+row[1].childPrice, 2)}
                      </td>
                      <td>
                        {currencySign}
                        {formatMoney(+row[1].totalPrice, 2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={close}>
          {t("Close")}
        </button>
      </div>
    </Modal>
  )
}
