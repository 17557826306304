import axios from "axios"
import React, { useState, useEffect } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import CustomModal from "./CustomModal"
import PropertiesTable from "components/Villa/PropertiesTable/index"
import useRequest from "hooks/useRequest"

const CancelToken = axios.CancelToken
let cancel
const itemsCountPerPage = 13

const PropertyPlaceDistances = () => {
  // default
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState("")
  const [selectedRowData, setSelectedRowData] = useState({})
  const [showWarningMessage, setShowWarningMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  //
  const [properties, setProperties] = useState([])
  const { t } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    const main = async () => {
      await fetchCompanyProperties(1, "")
    }
    main()
  }, [])

  const fetchCompanyProperties = async (page, searchText) => {
    const response = await request({
      url: "/property/fetch-by-page-number",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c
      }),
      params: {
        page,
        limit: itemsCountPerPage,
        searchText,
      },
    })

    setProperties(response?.dbResult[0])
    setTotalItemsCount(response?.dbResult[1][0]?.totalItemsCount)
  }

  const onSelectTableRow = selectedRowId => {
    const selectedRowData = properties.find(item => item.id == selectedRowId)
    setSelectedRowId(selectedRowId)
    setSelectedRowData(selectedRowData)
  }

  const onPageChange = activePage => {
    setActivePage(activePage)
    fetchCompanyProperties(activePage, "")
  }

  let Content = null
  if (loading) {
    Content = <Spinner size="lg" color="primary" className="spinner" />
  } else if (errorMessage) {
    Content = (
      <Alert color="danger"> {t(errorMessage.replace("Error: ", ""))}</Alert>
    )
  } else {
    Content = (
      <PropertiesTable
        properties={properties}
        onSelectTableRow={onSelectTableRow}
        selectedRowId={selectedRowId}
        activePage={activePage}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={itemsCountPerPage}
        onPageChange={onPageChange}
        hasPagination={true}
      />
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Property")}
            breadcrumbItem={t("What's nearby?")}
          />
          <ControlButtons
            displayAddBtn={true}
            addTitle="Add/Remove Category"
            displayEditBtn={false}
            displayDeleteBtn={false}
            onAddBtn={() => {
              if (selectedRowId) {
                setIsModalOpen(true)
              } else {
                setShowWarningMessage(true)
              }
            }}
            onPrintBtn={() => {}}
            onPdfBtn={() => {}}
            onExcelBtn={() => {}}
            isApiSearch={true}
            searchAPI={searchText => fetchCompanyProperties(1, searchText)}
          />
          {Content}
        </Container>
      </div>

      {isModalOpen && (
        <CustomModal
          isOpen={true}
          close={() => {
            setIsModalOpen(false)
            setSelectedRowData({})
            setSelectedRowId("")
          }}
          property={selectedRowData}
        />
      )}

      <SweetAlert
        show={showWarningMessage}
        warning
        title={t("Please select a row!")}
        onConfirm={() => {
          setShowWarningMessage(false)
        }}
        confirmBtnText={t("OK")}
      ></SweetAlert>
    </React.Fragment>
  )
}

export default PropertyPlaceDistances
