import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import NightsPlanForm from "../NightsPlanForm"
import styles from "./styles.module.scss"

export default function MinNights(props) {
  const { minNight, setMinNight, minNightDay, setMinNightDay } = props
  const [isNightsPlanFormOpen, setIsNightsPlanFormOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("How long do guests need to stay to use this promotion?")}
        </h5>

        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={minNight}
            onChange={(e, v) => setMinNight(v)}
          >
            <FormControlLabel
              value={false}
              control={<Radio className={styles.radio} />}
              label={t("Match to selected rate plan(s)")}
              className={styles.radioLabel}
              onClick={() => {
                setIsNightsPlanFormOpen(false)
              }}
            />

            <FormControlLabel
              value={true}
              control={<Radio className={styles.radio} />}
              label={t("Choose a minimum number of nights")}
              className={styles.radioLabel}
              onClick={() => {
                setIsNightsPlanFormOpen(true)
              }}
            />
          </RadioGroup>
        </FormControl>
        {isNightsPlanFormOpen && (
          <NightsPlanForm
            isOpen={true}
            minNightDay={minNightDay}
            setMinNightDay={setMinNightDay}
            close={() => {
              setIsNightsPlanFormOpen(false)
            }}
          />
        )}
      </CardBody>
    </Card>
  )
}
