import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

// company
import CompanySaga from "./company/saga"

//
import UserSaga from "./user/saga"

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(CompanySaga),
    fork(UserSaga),
  ])
}
