import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

import { useVillaOverallViewContext } from "../context"

const CustomTable = props => {
  const { values } = useVillaOverallViewContext()
  const { t, i18n } = useTranslation()
  const { villas, currencies } = values

  const dailyData = props.data
  const { currency_id, property_id } = dailyData[0]
  const villaName = villas?.find(i => i.id === property_id)?.name
  const currencyName = currencies?.find(i => i.id === currency_id)?.name

  return (
    <div className={styles.container}>
      <legend className={styles.legend}>
        <span>{villaName} </span>
      </legend>
      <div className={styles.row}>
        {/* TITLES*/}
        <div className={`${styles.col} ${styles.titlesCol}`}>
          <span className={styles.center}>{t("Month")}</span>
          <span className={styles.center}>{t("Name")}</span>
          <span className={styles.center}>{t("Day")}</span>
          <br />
          <span>{t("Status")}</span>
          <span>{t("Sold out")}</span>
          <span>{t("Reservation")}</span>
          <br />
          <span className={styles.rowsHeader}>{t("Price")}</span>
          <span>
            {t("Price")} ({currencyName})
          </span>
        </div>
        {/* SHOULD BE SCROLLABLE */}
        <div className={`${styles.col} ${styles.scrollable}`}>
          <div className={styles.row}>
            {dailyData?.map(item => {
              let { date, status, price, hasSold, renter } = item
              date = new Date(date)
              return (
                <div key={date} className={styles.col}>
                  <span className={styles.bold}>
                    {date?.toLocaleString(i18n.language, {
                      month: "short",
                    })}
                  </span>
                  <span className={styles.bold}>
                    {date?.toLocaleDateString(i18n.language, {
                      weekday: "short",
                    })}
                  </span>
                  <span className={styles.bold}>{date.getDate()}</span>
                  <br />
                  <span className={status ? styles.open : styles.closed}>
                    {status ? t("Open") : t("Closed")}
                  </span>
                  <span>
                    {hasSold ? t("Sold") : status ? t("On sale") : t("Closed")}
                  </span>
                  <span>{renter}</span>
                  <br />
                  <span className={styles.empty}></span>
                  <span className={price ? "" : styles.redBg}>{price} </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomTable
