import React, { useState, useEffect } from "react"
import { Col, Row, Container, Card, Alert } from "reactstrap"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import TopItem from "./TopItem"
import BottomItem from "./BottomItem"
import useRequest from "hooks/useRequest"
import { useSocket } from "contexts/socket-context"
import { useMasterSocket } from "contexts/master-socket-context"
// images/icons
import img1 from "assets/images/hp-icons/1.png"
import img2 from "assets/images/hp-icons/2.png"
import img3 from "assets/images/hp-icons/3.png"
import img4 from "assets/images/hp-icons/4.png"
import img5 from "assets/images/hp-icons/5.png"

import styles from "./dashboard.module.scss"

const VillaHomePage = () => {
  const [, setErrorMessage] = useState("")
  const [, setLoading] = useState(false)
  const [reservationCount, setReservationCount] = useState(0)
  const [showWarning, setShowWarning] = useState(false)
  const [cStatuses, setCStatuses] = useState({})
  const userName = useSelector(
    state => state.User.currentUser.name + " " + state.User.currentUser.surname
  )
  const { request } = useRequest(setLoading, setErrorMessage)
  const { unreadMessageCount: resUnreadMessageCount } = useSocket()
  const { unreadMessageCount: masterUnreadMessageCount } = useMasterSocket()
  const totalUnreadMessageCount =
    (resUnreadMessageCount || 0) + (masterUnreadMessageCount || 0)

  const { t } = useTranslation()

  useEffect(() => {
    const fetchReservations = async () => {
      const response = await request(
        {
          url: "/property-reservations/get-pending-count-by-company-id",
        },
        <i class="fas fa-bullseye    "></i>
      )
      if (response?.dbResult) {
        setReservationCount(response?.dbResult?.pendingReservationsCount)
      }
    }

    const fetchCompulsoryStatuses = async () => {
      const response = await request(
        {
          url: "/property/fetch-compulsory-statuses",
        },
        false
      )
      if (response?.result) {
        setCStatuses(response)
        const { generalStatus, propertiesStatus } = response
        const hasPhoto = propertiesStatus.every(i => i.photoCount > 0)

        if (
          Object.values(generalStatus).includes(0) ||
          propertiesStatus.length === 0 ||
          !hasPhoto
        ) {
          setShowWarning(true)
        }
      }
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchReservations(), fetchCompulsoryStatuses()])
      setLoading(false)
    })()
  }, [])

  return (
    <Container className={styles.container}>
      <Alert
        color="danger"
        className={styles.warning}
        isOpen={showWarning}
        toggle={setShowWarning.bind(null, false)}
      >
        <p>{t("Warning!")}</p>
        <p>
          {t(
            "The account is not currently active. Please fill out the following pages carefully."
          )}
        </p>
        <ul>
          {!cStatuses?.generalStatus?.hasCancellationPolicy && (
            <li>
              <Link to="/property-cancellation-policy" className="alert-link">
                {t("Cancellation Policy")}
              </Link>
            </li>
          )}
          {!cStatuses?.generalStatus?.hasPrepaymentPolicy && (
            <li>
              <Link to="/property-prepayment-policy" className="alert-link">
                {t("Payment Policy")}
              </Link>
            </li>
          )}
          {!cStatuses?.generalStatus?.hasGuestInfoPolicy && (
            <li>
              <Link to="/property-guest-info-policy" className="alert-link">
                {t("Guest Info Policy")}
              </Link>
            </li>
          )}
          {!cStatuses?.generalStatus?.hasTaxPolicy && (
            <li>
              <Link to="/property-kdv-tax-fees" className="alert-link">
                {t("Tax Policy")}
              </Link>
            </li>
          )}
          {cStatuses?.propertiesStatus?.length === 0 ? (
            <>
              <li>
                <Link to="/property-registration" className="alert-link">
                  {t("Property Registration")}
                </Link>
              </li>
              <li>
                <Link to="/property-photos" className="alert-link">
                  {t("Photos")}
                </Link>
              </li>
            </>
          ) : (
            <>
              {cStatuses?.propertiesStatus?.map(p =>
                p.photoCount === 0 ? (
                  <li key={p.name}>
                    <Link to="/property-photos" className="alert-link">
                      {p.name} - {t("Photos")}
                    </Link>
                  </li>
                ) : null
              )}
            </>
          )}
        </ul>
      </Alert>
      {/* TOP ITEMS */}
      <Row>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/setup-user"}
            bgColor={"#40189d"}
            imgSrc={img1}
            label={t("View Profile")}
            content={userName}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/property-reservations"}
            bgColor={"#72ace5"}
            imgSrc={img1}
            label={t("Pending Reservations")}
            content={reservationCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/reservation-messages"}
            bgColor={"#9ac46d"}
            imgSrc={img1}
            label={t("Messages")}
            content={totalUnreadMessageCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/#"}
            bgColor={"#3b8965"}
            imgSrc={img1}
            label={t("Comments")}
            content={0}
          />
        </Col>
      </Row>
      <Card className={styles.middleCard}>{t("Operations")}</Card>
      {/* BOTTOM ITEMS */}
      <Row>
        {bottomItems.map(item => (
          <Col xs={6} xl={3} key={item.label}>
            <BottomItem
              link={item.link}
              imgSrc={item.imgSrc}
              label={t(item.label)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default VillaHomePage

const bottomItems = [
  { label: "Overall View", imgSrc: img1, link: "/property-overall-view" },
  {
    label: "Influencer Marketing",
    imgSrc: img2,
    link: "/property-influencer-marketing",
  },
  {
    label: "Search Engine Marketing",
    imgSrc: img3,
    link: "/property-search-engine-marketing",
  },
  { label: "Promotions", imgSrc: img4, link: "/property-promotions" },
  { label: "Availability", imgSrc: img2, link: "/property-availability" },
  {
    label: "Property Information",
    imgSrc: img3,
    link: "/property-registration",
  },
  { label: "Pricing", imgSrc: img4, link: "/property-pricing" },
  { label: "Settings", imgSrc: img5, link: "/setup-company-settings" },
]
