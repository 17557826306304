import React from "react"
import { Card, CardBody } from "reactstrap"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import styles from "./styles.module.scss"
import { useTranslation } from "react-i18next"

const Members = props => {
  const { onlyMembers, setOnlyMembers } = props
  const { t } = useTranslation()

  const handleChange = event => {
    setOnlyMembers(event.target.value)
  }
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Availability Details")}</h4>
        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={onlyMembers}
            onChange={handleChange}
          >
            <FormControlLabel
              value={false}
              control={<Radio className={styles.radio} />}
              label={t("Everyone")}
              className={styles.radioLabel}
            />

            <FormControlLabel
              value={true}
              control={<Radio className={styles.radio} />}
              label={t("Only Members (members registered in the Vihobook system)")}
              className={styles.radioLabel}
            />
          </RadioGroup>
        </FormControl>
      </CardBody>
    </Card>
  )
}
export default Members
