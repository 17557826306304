import { takeEvery, put } from "redux-saga/effects"
import axios from "axios"

import { REGISTER_USER } from "./actionTypes"
import {
  registerUserStart,
  registerUserFailed,
  registerUserSuccessful,
} from "./actions"

function* registerUserSaga({ user: authData, callback }) {
  yield put(registerUserStart())

  let url = "/auth/signup"
  try {
    const response = yield axios.put(url, authData)
    if (response.data.result == true) {
      yield put(registerUserSuccessful())
      callback(response.data)
    } else {
      yield put(registerUserFailed(response.data.message))
    }
  } catch (error) {
    yield put(registerUserFailed("Something went wrong!!"))
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUserSaga)
}

export default accountSaga
