export const USER_API_ERROR = "USER_API_ERROR"

export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_CURRENT_USER_START = "GET_CURRENT_USER_START"
export const GET_CURRENT_USER_SUCCEED = "GET_CURRENT_USER_SUCCEED"

export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_NEW_USER_START = "ADD_USER_START"
export const ADD_NEW_USER_SUCCEED = "ADD_USER_SUCCEED"

export const GET_CURRENT_COMPANY_USERS = "GET_CURRENT_COMPANY_USERS"
export const GET_CURRENT_COMPANY_USERS_START = "GET_CURRENT_COMPANY_USERS_START"
export const GET_CURRENT_COMPANY_USERS_SUCCEED =
  "GET_CURRENT_COMPANY_USERS_SUCCEED"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_START = "UPDATE_USER_START"
export const UPDATE_USER_SUCCEED = "UPDATE_USER_SUCCEED"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_START = "DELETE_USER_START"
export const DELETE_USER_SUCCEED = "DELETE_USER_SUCCEED"
