import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Spinner, Button, Alert, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Prices from "./components/Prices"
import Dates from "./components/Dates"
import Members from "./components/Members"
import Checkboxes from "./components/Checkboxes"

import SuccessAlert from "components/Common/SuccessAlert"
import { getLabelByLanguage, all, getTranslatedOption } from "utils/other"
import { addDays, getTodayDate } from "utils/dateTimeFunctions"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner"

const TicketPricing = () => {
  const [onlyMembers, setOnlyMembers] = useState(false)
  const [tickets, setTickets] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [startDate, setStartDate] = useState(getTodayDate())
  const [endDate, setEndDate] = useState(addDays(getTodayDate(), 7))
  const [prices, setPrices] = useState({
    adultPrice: "",
    babyPrice: "",
    childPrice: "",
  })

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")

  const { request } = useRequest(setLoading, setError)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const selectedTicket = tickets.find(i => i.isChecked)

  useEffect(() => {
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      if (response?.result && Array.isArray(response?.dbResult)) {
        const marketingPlaces = response?.dbResult?.map(i => ({
          ...i,
          id: i.id[1],
          isChecked: false,
        }))

        marketingPlaces.unshift({
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })
        setMarketingPlaces(marketingPlaces)
      }
    }

    const fetchTickets = async () => {
      const response = await request(
        {
          url: "/ticket/names",
        },
        false
      )

      const activeTickets = response?.tickets?.filter(
        ticket => ticket.status === true
      )

      const tickets = activeTickets.map(i => ({
        ...i,
        name: `${getTranslatedOption(i, "name", locale)} (${i.specialName})`,
        isChecked: false,
      }))

      setTickets(tickets)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchSetMarketingPlaces(), fetchTickets()])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setMarketingPlaces(list => [
      { ...list[0], label: getLabelByLanguage(all, locale) },
      ...list.slice(1),
    ])

    setTickets(list =>
      list.map(i => ({
        ...i,
        name: `${getTranslatedOption(i, "name", locale)} (${i.specialName})`,
      }))
    )
  }, [locale])

  // CHECK HANDLERS
  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const ticketCheckHandler = id => {
    setTickets(list =>
      list.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : false,
      }))
    )
  }

  const reset = () => {
    setTickets(tickets =>
      tickets.map(i => ({
        ...i,
        isChecked: false,
      }))
    )

    setMarketingPlaces(marketingPlaces =>
      marketingPlaces.map(i => ({
        ...i,
        isChecked: false,
      }))
    )
    setOnlyMembers(false)
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setPrices({
      adultPrice: "",
      babyPrice: "",
      childPrice: "",
    })
  }

  const submitHandler = () => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/ticket-price-daily",
          method: "put",
          data: payload,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")

    const ticket_ids = tickets.filter(i => i.isChecked).map(i => i.id)

    if (ticket_ids.length === 0) {
      return setError("Please select a ticket!")
    }

    const currency_id = 2

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    let arePricesValid = true

    if (isNaN(prices.adultPrice) || prices.adultPrice <= 0) {
      arePricesValid = false
    }

    if (selectedTicket.isChildAllowed) {
      if (isNaN(prices.childPrice) || prices.childPrice < 0) {
        arePricesValid = false
      }
      if (isNaN(prices.babyPrice) || prices.babyPrice < 0) {
        arePricesValid = false
      }
    }

    if (!arePricesValid) {
      return setError(t("Please enter a valid price!"))
    }

    const newData = {
      onlyMembers,
      ticket_ids,
      startDate,
      endDate,
      setMarketingPlace_ids,
      price: +prices.adultPrice,
      chd1: prices.babyPrice || 0,
      chd2: prices.childPrice || 0,
      currency_id,
    }

    submit(newData)
    reset()
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Ticket")} breadcrumbItem={t("Pricing")} />
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <Members
              onlyMembers={onlyMembers}
              setOnlyMembers={setOnlyMembers}
            />

            <Checkboxes
              dataList={tickets}
              checkHandler={ticketCheckHandler}
              label="Which Ticket?"
            />

            {marketingPlaces.length > 2 && (
              <Checkboxes
                dataList={marketingPlaces}
                checkHandler={marketingPlaceCheckHandler}
                label="Marketing Places"
              />
            )}

            <Dates
              title="Price Date"
              subtitle="When can guests use this price?"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />

            {selectedTicket && (
              <Prices
                prices={prices}
                setPrices={setPrices}
                isChildAllowed={selectedTicket.isChildAllowed}
                currency="EUR"
              />
            )}

            {error && <Alert color="danger"> {t(error)}</Alert>}
            {showSuccessAlert && (
              <SuccessAlert
                hideSuccessAlert={() => {
                  setShowSuccessAlert(false)
                  setStartDate(endDate)
                  setEndDate(addDays(endDate, 7))      
                }}
              />
            )}

            <div className="row justify-content-end mx-1">
              <Button
                className="align-self-end"
                size="md"
                color="primary"
                onClick={submitHandler}
              >
                {submitLoading ? (
                  <>
                    <Spinner size="sm" /> {t("Loading...")}{" "}
                  </>
                ) : (
                  t("Save")
                )}
              </Button>
            </div>
          </>
        )}
      </Container>
    </main>
  )
}
export default TicketPricing
