import { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  Button,
  Spinner,
  Alert,
  Input,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"

import styles from "./styles.module.scss"

export default function index() {
  const { company } = useSelector(state => state.Company)
  const hasSignedContract = company.isAgreementConfirmed || false

  const [hasTax, setHasTax] = useState(false)

  const [hasVatTax, setHasVatTax] = useState(false)
  const [hasStateTax, setHasStateTax] = useState(false)
  const [hasOrderTax, setHasOrderTax] = useState(false)

  const [vatTaxCommission, setVatTaxCommission] = useState("0")
  const [stateTaxCommission, setStateTaxCommission] = useState("0")
  const [orderTaxCommission, setOrderTaxCommission] = useState("0")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await fetchPrepaymentPolicy()
      setLoading(false)
    })()
  }, [])

  const fetchPrepaymentPolicy = async () => {
    const response = await request(
      {
        url: "/hotel-tax-policy",
      },
      false
    )

    if (response?.dbResult?.id) {
      const {
        hasTax,
        hasVatTax,
        hasStateTax,
        hasOrderTax,
        vatTaxCommission,
        stateTaxCommission,
        orderTaxCommission,
      } = response.dbResult

      setHasTax(hasTax)
      setHasVatTax(hasVatTax)
      setHasStateTax(hasStateTax)
      setHasOrderTax(hasOrderTax)

      setVatTaxCommission(vatTaxCommission || "0")
      setStateTaxCommission(stateTaxCommission || "0")
      setOrderTaxCommission(orderTaxCommission || "0")
    }
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    if ((vatTaxCommission || stateTaxCommission || orderTaxCommission) > 100) {
      return setErrorMessage(
        "Invalid value. The numbers can't be greater than 100%!"
      )
    }
    // end of validation

    // submit data
    let payload = {}
    if (hasTax) {
      payload = {
        hasTax,
        hasVatTax,
        hasStateTax,
        hasOrderTax,
        vatTaxCommission,
        stateTaxCommission,
        orderTaxCommission,
      }
    } else {
      payload = {
        hasTax,
        hasVatTax: false,
        hasStateTax: false,
        hasOrderTax: false,
        vatTaxCommission: 0,
        stateTaxCommission: 0,
        orderTaxCommission: 0,
      }
    }
    setSubmitLoading(true)
    const response = await request({
      url: "/hotel-tax-policy",
      method: "post",
      data: payload,
    })
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
      fetchPrepaymentPolicy()
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("VAT/Taxes/Fees Policy")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h4 className={styles.pageTitle}>
              {t("Tax Policy")}.{" "}
              {t(
                "You cannot change this information after your Agreement has been approved by Vihobook."
              )}
            </h4>

            {/* FIRST Q */}
            <div className={styles.questionContainer}>
              <label className={styles.question}>
                {t(
                  "Do you add extra VAT/Tourism Tax/Other Tax Fees to your prices?"
                )}
              </label>
              <div className={styles.buttons}>
                <label htmlFor="hasTaxNo">
                  <input
                    id="hasTaxNo"
                    disabled={hasSignedContract}
                    type="radio"
                    name="hasTax"
                    value={hasTax}
                    checked={hasTax === false}
                    onChange={() => setHasTax(false)}
                  />
                  {t("No")}
                </label>
                <label htmlFor="hasTaxYes">
                  <input
                    id="hasTaxYes"
                    type="radio"
                    disabled={hasSignedContract}
                    name="hasTax"
                    checked={hasTax === true}
                    value={hasTax}
                    onChange={() => setHasTax(true)}
                  />
                  {t("Yes")}
                </label>
              </div>
            </div>

            {hasTax && (
              <>
                <QuestionComp
                  questionText="Do you add VAT to your prices?"
                  input1Id="hasVatTaxNo"
                  input2Id="hasVatTaxYes"
                  name="hasVatTax"
                  radioValue={hasVatTax}
                  radioSetter={setHasVatTax}
                  inputValue={vatTaxCommission}
                  inputSetter={setVatTaxCommission}
                  disabled={hasSignedContract}
                />

                <QuestionComp
                  questionText="Do you add tourism tax to your prices?"
                  input1Id="hasStateTaxNo"
                  input2Id="hasStateTaxYes"
                  name="hasStateTax"
                  radioValue={hasStateTax}
                  radioSetter={setHasStateTax}
                  inputValue={stateTaxCommission}
                  inputSetter={setStateTaxCommission}
                  disabled={hasSignedContract}
                />

                <QuestionComp
                  questionText="Do you add other fees to your prices?"
                  input1Id="hasOrderTaxNo"
                  input2Id="hasOrderTaxYes"
                  name="hasOrderTax"
                  radioValue={hasOrderTax}
                  radioSetter={setHasOrderTax}
                  inputValue={orderTaxCommission}
                  inputSetter={setOrderTaxCommission}
                  disabled={hasSignedContract}
                />
              </>
            )}
          </CardBody>
        </Card>
        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <Button
          color="primary"
          className={styles.submitBtn}
          onClick={submitHandler}
          disabled={hasSignedContract}
          style={{ cursor: hasSignedContract ? "not-allowed" : "pointer" }}
        >
          {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
        </Button>
      </Container>
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

const QuestionComp = ({
  questionText,
  input1Id,
  input2Id,
  name,
  radioValue,
  radioSetter,
  inputValue,
  inputSetter,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.questionContainer}>
      <label className={styles.question}>{t(questionText)}</label>
      <div className={styles.row}>
        <div className={styles.buttons}>
          <label htmlFor={input1Id}>
            <input
              id={input1Id}
              type="radio"
              name={name}
              value={radioValue}
              disabled={disabled}
              checked={radioValue === false}
              onChange={() => radioSetter(false)}
            />
            {t("No")}
          </label>
          <label htmlFor={input2Id}>
            <input
              id={input2Id}
              type="radio"
              name={name}
              disabled={disabled}
              checked={radioValue === true}
              value={radioValue}
              onChange={() => radioSetter(true)}
            />
            {t("Yes")}
          </label>
        </div>

        {radioValue && (
          <div
            className={`${styles.inputWrapper} ${
              disabled && styles.disabled
            }`}
          >
            <Input
              disabled={disabled}
              type="number"
              value={inputValue}
              onChange={e => inputSetter(e.target.value)}
            />
            <span>% {t("applied")}</span>
          </div>
        )}
      </div>
    </div>
  )
}
