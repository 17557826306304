import React, { Component } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap"

import classnames from "classnames"

class TextEditor extends Component {
  state = {
    activeTab: "1",
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const { activeTab } = this.state
    const { t } = this.props
    return (
      <React.Fragment>
        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                this.toggleTab("1")
              }}
            >
              {t("Turkish")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                this.toggleTab("2")
              }}
            >
              {t("English")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                this.toggleTab("3")
              }}
            >
              {t("French")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                this.toggleTab("4")
              }}
            >
              {t("German")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "5",
              })}
              onClick={() => {
                this.toggleTab("5")
              }}
            >
              {t("Russian")}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === "6",
              })}
              onClick={() => {
                this.toggleTab("6")
              }}
            >
              {t("Arabic")}
            </NavLink>
          </NavItem>
        </ul>

        <TabContent className="pt-2" activeTab={activeTab}>
          <TabPane tabId="1">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.turkishEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "tr")}
            />
          </TabPane>
          <TabPane tabId="2">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.englishEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "en")}
            />
          </TabPane>
          <TabPane tabId="3">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.frenchEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "fr")}
            />
          </TabPane>
          <TabPane tabId="4">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.germanEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "de")}
            />
          </TabPane>
          <TabPane tabId="5">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.russianEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "ru")}
            />
          </TabPane>
          <TabPane tabId="6">
            <Editor
              editorClassName="pl-2 z-index-0"
              editorState={this.props.arabicEditorState}
              onEditorStateChange={v => this.props.onEditorStateChange(v, "ar")}
            />
          </TabPane>
        </TabContent>
      </React.Fragment>
    )
  }
}

export default TextEditor
