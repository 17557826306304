import React, { useState, useEffect } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import useRequest from "hooks/useRequest"

import Inputs from "./Inputs"
import Calendar from "./Calendar"
import Breadcrumbs from "components/Common/Breadcrumb"
import { addDays } from "utils/dateTimeFunctions"

const AvailabilityReport = () => {
  const [years, setYears] = useState([])
  const [properties, setProperties] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [selectedMarketingPlace, setSelectedMarketingPlace] = useState("")
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [modifiers, setModifiers] = useState({})
  const [formState, setFormState] = useState()

  const { t } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    const generateYears = () => {
      const startYear = 2022
      const currentYear = new Date().getFullYear()
      const loopNumber = currentYear - startYear + 5
      const years = []
      let year = startYear
      for (let i = 0; i < loopNumber; i++) {
        years.push({ label: year, value: year })
        year += 1
      }

      setYears(years)
    }

    const fetchProperties = async () => {
      const response = await request(
        {
          url: `/property/names`,
        },
        false
      )

      if (response?.result && response?.properties?.length > 0) {
        const properties = response.properties.map(i => ({
          ...i,
          label: i.name,
          value: i.id,
        }))
        setProperties(properties)
      }
    }

    const fetchMarketingPlaces = async () => {
      const response = await request(
        {
          url: `/company-marketing-place`,
        },
        false
      )

      if (response?.result && response?.dbResult?.length > 0) {
        const marketingPlaces = response.dbResult.map(i => ({
          ...i,
          label: i.setMarketingPlaceName,
          value: i.setMarketingPlace_id,
        }))

        setMarketingPlaces(marketingPlaces)
        if (marketingPlaces.length === 1) {
          setSelectedMarketingPlace(marketingPlaces[0])
        }
      }
    }

    ;(async () => {
      setLoading(true)
      generateYears()
      await Promise.allSettled([fetchProperties(), fetchMarketingPlaces()])
      setLoading(false)
    })()
  }, [])

  const submitHandler = async data => {
    setFormState(data)
    setSearching(true)
    const payload = {
      property_id: data.property.id,
      year: data.year.value,
      setMarketingPlace_id: data.marketingPlace.value,
    }

    const response = await request(
      {
        url: "/property-availability/fetch-by-year",
        params: payload,
      },
      false
    )
    if (response?.result && response?.dbResult?.length > 0) {
      prepareData(response.dbResult)
    }
    setSearching(false)
  }

  const prepareData = availabilities => {
    const resIds = []
    //
    const resStartDates = []
    const reservedDates = []
    const resEndDates = []
    //
    const avbStartDates = []
    const avbDates = []
    const avbEndDates = []

    availabilities.forEach((item, idx) => {
      if (!item.res_id && !!item.status) {
        avbStartDates.push(new Date(item.date))
        avbEndDates.push(addDays(item.date, 1))
      } else if (item.res_id) {
        if (!resIds.includes(item.res_id)) {
          resStartDates.push(new Date(item.date))
          resIds.push(item.res_id)
          const nextItem = availabilities[idx + 1]
          if (!nextItem || nextItem.res_id !== item.res_id) {
            resEndDates.push(addDays(item.date, 1))
          }
        } else {
          const nextItem = availabilities[idx + 1]
          if (!nextItem) {
            resEndDates.push(new Date(item.date))
          } else if (!!nextItem && nextItem.res_id !== item.res_id) {
            resEndDates.push(new Date(nextItem.date))
            reservedDates.push(new Date(item.date))
          } else {
            reservedDates.push(new Date(item.date))
          }
        }
      }
    })

    setModifiers({
      resStartDate: resStartDates,
      reservedDate: reservedDates,
      resEndDate: resEndDates,
      // avb
      avbStartDate: avbStartDates,
      avbEndDate: avbEndDates,
    })
  }

  let Content = null
  if (loading) {
    Content = <Spinner size="lg" color="primary" className="spinner" />
  } else if (errorMessage) {
    Content = <Alert color="danger"> {t(errorMessage)}</Alert>
  } else {
    Content = (
      <>
        <Inputs
          properties={properties}
          years={years}
          marketingPlaces={marketingPlaces}
          selectedMarketingPlace={selectedMarketingPlace}
          submitHandler={submitHandler}
          loading={searching}
        />
        {searching ? (
          <Spinner size="lg" color="primary" className="spinner" />
        ) : (
          <Calendar
            modifiers={modifiers}
            selectedYear={formState?.year?.value}
          />
        )}
      </>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Reports")}
          breadcrumbItem={t("Property Availability")}
        />
        {Content}
      </Container>
    </div>
  )
}

export default AvailabilityReport
