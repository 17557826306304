import React, { useState, useEffect } from "react"
import { Modal, Alert, Spinner } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation-safe"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import SuccessAlert from "components/Common/SuccessAlert/index"
import { useChildPolicyContext, ChildPolicyProvider } from "./context"
import FormBody from "./FormBody/index"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

const Add_EditModal = props => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  // local states
  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")

  // hooks
  const {
    setCurrencies,
    currencies,
    setHotelRoomNames,
    setHotelBoardTypes,
    setChildNames,
    setChildPolicyAge,
    tablesData,
    isPercent,
    selectedCurrency,
    selectedRoomName,
    selectedBoardType,
    policyName,
  } = useChildPolicyContext()

  const { request } = useRequest(setLoading, setErrorMessage)
  const { isOpen, close, modalTitle, isAddOperation, hotel_id, policy } = props
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchChildNames = async () => {
      const response = await request(
        {
          url: "/hotel-set-child-names",
        },
        false
      )

      const childNames = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, i18n.language),
      }))
      setChildNames(childNames)
    }

    const fetchChildPolicyAge = async () => {
      const response = await request(
        {
          url: "/hotel-child-policy-age",
          params: { hotel_id },
        },
        false
      )

      const childPolicyAge = response?.dbResult?.sort(
        (a, b) => a.startAge - b.startAge
      )

      setChildPolicyAge(childPolicyAge)
    }

    ;(async () => {
      if (country_id === 107 && !currencies.find(i => i.id === 3)) {
        setCurrencies(prevCurrencies => [
          ...prevCurrencies,
          { id: 3, value: 3, label: "TRY", name: "TRY" },
        ])
      }
      const hotelRoomNames = props.hotelRoomNames.map(i => ({
        ...i,
        value: i.id,
        label: getLabelByLanguage(i, locale),
      }))
      const hotelBoardTypes = props.hotelBoardTypes.map(i => ({
        ...i,
        value: i.id,
        label: getLabelByLanguage(i, locale),
      }))
      setHotelRoomNames(hotelRoomNames)
      setHotelBoardTypes(hotelBoardTypes)
      setLoading(true)
      await Promise.allSettled([fetchChildNames(), fetchChildPolicyAge()])
      setLoading(false)
    })()
  }, [])

  const onValidSubmit = (event, data) => {
    // validate
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    setErrorMessage("")

    if (
      !selectedRoomName ||
      !selectedBoardType ||
      (!isPercent && !selectedCurrency)
    ) {
      return setErrorMessage("Please fill in the blanks!")
    }

    const getDetailList = () => {
      const detailList = []
      tablesData.forEach(i => {
        i.forEach(j => detailList.push(...j))
      })
      return detailList
    }

    const submitData = async data => {
      setSaveLoading(true)
      const response = await request(
        {
          url: "/hotel-child-policies",
          method: isAddOperation ? "post" : "put",
          data,
        },
        false
      )
      setSaveLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    const payload = {
      id: isAddOperation ? "" : policy.id,
      name: policyName,
      isPercent,
      currency_id: selectedCurrency?.value || null,
      room_id: selectedRoomName.room_id,
      setBoardType_id: selectedBoardType.value,
      hotel_id,
      detailList: getDetailList(),
    }

    submitData(payload)
  }

  return (
    <>
      <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
          <button
            type="button"
            onClick={close}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body" style={{ minHeight: 200 }}>
          {!loading && (
            <AvForm onValidSubmit={onValidSubmit}>
              <FormBody isAddOperation={isAddOperation} policy={policy} />
              {errorMessage && <Alert color="danger"> {t(errorMessage)}</Alert>}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={close}
                >
                  {t("Close")}
                </button>

                <button type="submit" className="btn btn-primary">
                  {saveLoading ? (
                    <>
                      <Spinner size="sm" /> {t("Loading...")}{" "}
                    </>
                  ) : (
                    t("Save")
                  )}
                </button>
              </div>
            </AvForm>
          )}

          {loading && <Spinner size="lg" color="primary" className="spinner" />}
        </div>
      </Modal>
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            props.close()
          }}
        />
      )}
    </>
  )
}

const WithProvider = props => {
  return (
    <ChildPolicyProvider>
      <Add_EditModal {...props} />
    </ChildPolicyProvider>
  )
}

export default WithProvider
