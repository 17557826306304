import React, { useState, useEffect } from "react"
import { Modal, Button, Spinner, Row, FormGroup, Input } from "reactstrap"
import { useTranslation } from "react-i18next"
import CustomWarning from "components/Common/CustomWarning/index"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"
import styles from "./styles.module.scss"

const CustomModal = ({ isOpen, close, property }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [categories, setCategories] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const categories = await fetchCategories()
      await fetchPropertyCategories(categories)
      setLoading(false)
    })()
  }, [])

  const fetchCategories = async () => {
    const response = await request(
      {
        url: "/property-set-categories",
      },
      false
    )
    const categories = response?.categories?.map(i => ({
      ...i,
      title: getLabelByLanguage(i, locale),
      isChecked: false,
    }))
    return categories
  }

  const fetchPropertyCategories = async categories => {
    const response = await request(
      {
        url: "/property-categories",
        params: { propertyId: property.id },
      },
      false
    )
    const property_categories = response?.property_categories
    const updatedCategories = [...categories]
    property_categories?.forEach(i => {
      for (let j = 0; j < updatedCategories.length; j++) {
        const category = updatedCategories[j]
        if (i.categoryId === category.id) {
          updatedCategories[j] = { ...category, isChecked: true }
          break
        }
      }
    })

    setCategories(updatedCategories)
  }

  const checkHandler = id => {
    const updatedCategories = [...categories]
    const index = updatedCategories.findIndex(item => item.id === id)
    const category = updatedCategories[index]
    updatedCategories[index] = { ...category, isChecked: !category.isChecked }
    setCategories(updatedCategories)
  }

  const submitHandler = async () => {
    const dataList = []
    categories.forEach(i => {
      if (i.isChecked) {
        dataList.push({
          categoryId: i.id,
          propertyId: property.id,
        })
      }
    })

    setSaveLoading(true)
    const response = await request(
      {
        url: "/property-categories",
        method: "put",
        data: { propertyCategories: dataList, propertyId: property.id },
      },
      false
    )

    setSaveLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response?.message)
    }
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Property Categories")}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {loading && (
          <div className="spinner-background">
            <Spinner size="lg" color="primary" className="spinner" />
          </div>
        )}
        <Row className="mx-0 mt-4">
          {categories.map(item => (
            <FormGroup key={item.id} className={styles.checkboxContainer}>
              <div
                onClick={() => checkHandler(item.id)}
                className={styles.checkboxWrapper}
              >
                <Input
                  type="checkbox"
                  className={styles.checkbox}
                  name={item.title}
                  id={item.id}
                  onChange={() => {}}
                  checked={item.isChecked || false}
                />
                <span className="ml-2">{item.title}</span>
              </div>
            </FormGroup>
          ))}
        </Row>
        {showSuccessAlert && (
          <CustomWarning
            title="Congratulations!"
            success={true}
            show={showSuccessAlert}
            onConfirm={() => {
              setShowSuccessAlert(false)
              close()
            }}
            message="The data is saved successfully!"
          />
        )}
      </div>
      <div className="modal-footer">
        <Button type="button" className="btn btn-secondary" onClick={close}>
          {t("Close")}
        </Button>

        <Button
          size="md"
          color="primary"
          onClick={submitHandler}
          disabled={saveLoading}
        >
          {saveLoading ? (
            <>
              <Spinner size="sm" /> {t("Loading...")}{" "}
            </>
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default CustomModal
