import { useState, useEffect } from "react"
import Select from "react-select"
import { IoMdBed } from "react-icons/io"
import { HiPlus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Label } from "reactstrap"

import { usePropertyContext } from "../context"
import BedTypeItem from "./BedTypeItem"
import styles from "./styles.module.scss"
import useRequest from "hooks/useRequest"
import { getTranslatedOption } from "utils/other"

const countOptions = Array(5)
  .fill(null)
  .map((_, i) => ({ label: i + 1, value: i + 1 }))

export default ({ isAddOperation, property }) => {
  const { values, setters, functions, useFormResult } = usePropertyContext()
  const { watch } = useFormResult
  const { bedroomCount } = watch()
  const { setPropertyBedTypes, setLoading, setErrorMessage } = setters
  const { bedTypes, propertyBedTypes } = values
  const { fetchBedTypes } = functions

  const [roomNumbers, setRoomNumbers] = useState([]) // 1. Room, 2. Room
  const [selectedRoomNumber, setSelectedRoomNumber] = useState("")

  const [bedTypeOptions, setBedTypeOptions] = useState([])
  const [selectedBedType, setSelectedBedType] = useState("")
  const [selectedBedTypeCount, setSelectedBedTypeCount] = useState(
    countOptions[0]
  )

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const roomNumberOptions = Array(+bedroomCount || 0)
      .fill(null)
      .map((_, i) => ({ label: i + 1 + t(". Room"), value: i + 1 }))
    setRoomNumbers(roomNumberOptions)
    setPropertyBedTypes([])
  }, [bedroomCount])

  useEffect(() => {
    const selectedRoomBeds = propertyBedTypes.filter(
      i => i.selectedRoomNumber.value === selectedRoomNumber.value
    )

    // filter bedOptions for each bedRoomNumber
    let newBedOptions = []
    for (const bedType of bedTypes) {
      const index = selectedRoomBeds.findIndex(
        i => i.selectedBedType.value === bedType.id
      )
      if (index === -1) {
        newBedOptions.push(bedType)
      }
    }

    newBedOptions = newBedOptions.map(i => ({
      ...i,
      label: getTranslatedOption(i, "name", locale),
      value: i.id,
    }))
    setBedTypeOptions(newBedOptions)
  }, [selectedRoomNumber.value])

  useEffect(() => {
    const fetchPropertyBedTypes = async bedTypes => {
      const response = await request({
        url: "/property-bed-types/fetch-by-property-id",
        params: { property_id: property.id },
      })

      const propertyBedTypes = response?.dbResult?.map(i => {
        const bedType = bedTypes.find(j => j.id === i.setBedType_id)
        return {
          selectedRoomNumber: {
            label: i.bedroomNumber + t(". Room"),
            value: i.bedroomNumber,
          },
          selectedBedType: {
            ...bedType,
            label: getTranslatedOption(bedType, "name", locale),
            value: i.setBedType_id,
          },
          selectedBedTypeCount: {
            label: i.count,
            value: i.count,
          },
        }
      })

      setPropertyBedTypes(propertyBedTypes)
    }
    ;(async () => {
      const bedTypes = await fetchBedTypes()
      if (!isAddOperation) {
        await fetchPropertyBedTypes(bedTypes)
      }
    })()
  }, [])

  const addBedType = () => {
    if (
      !selectedRoomNumber.value ||
      !selectedBedType?.value ||
      !selectedBedTypeCount?.value
    )
      return

    setPropertyBedTypes(prev => [
      ...prev,
      { selectedRoomNumber, selectedBedType, selectedBedTypeCount },
    ])
    setSelectedRoomNumber("")
    setSelectedBedType("")
    setSelectedBedTypeCount(countOptions[0])
  }

  const deleteHandler = (roomNumber_id, bedType_id) => {
    setPropertyBedTypes(prev =>
      prev.filter(
        i =>
          !(
            i.selectedRoomNumber.value === roomNumber_id &&
            i.selectedBedType.value === bedType_id
          )
      )
    )
  }

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Number of beds and accommodation")}</CardTitle>
        <hr />
        <Row className={styles.inputsRow}>
          <div className={styles.bedroomWrapper}>
            <Label>* {t("Bedroom")}</Label>
            <Select
              value={selectedRoomNumber}
              onChange={setSelectedRoomNumber}
              options={roomNumbers}
              noOptionsMessage={() => t("No Options")}
              placeholder={t("Select...")}
            />
          </div>
          <div className={styles.bedTypeWrapper}>
            <Label>* {t("Bed type")}</Label>
            <Select
              value={selectedBedType}
              onChange={setSelectedBedType}
              options={bedTypeOptions}
              noOptionsMessage={() => t("No Options")}
              placeholder={t("Select...")}
            />
          </div>
          <div className={styles.counterWrapper}>
            <div className="w-100">
              <Label>* {t("Count")}</Label>
              <Select
                value={selectedBedTypeCount}
                onChange={setSelectedBedTypeCount}
                options={countOptions}
                className={styles.select}
              />
            </div>
            <span
              className={styles.icons}
              onClick={addBedType}
              title={t("Add")}
            >
              <HiPlus size={22} />
              <IoMdBed size={35} className={styles.lastIcon} />
            </span>
          </div>
        </Row>

        {propertyBedTypes.map((item, idx) => (
          <BedTypeItem key={idx} data={item} onDelete={deleteHandler} />
        ))}
      </CardBody>
    </Card>
  )
}
