import { useState } from "react"
import { Modal, ModalBody, ModalFooter, Button, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import { CgClose } from "react-icons/cg"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"

import SuccessAlert from "components/Common/SuccessAlert"
import { useReservationContext } from "../context"
import styles from "./styles.module.scss"

const ResStatusModal = ({ close }) => {
  const { values, functions } = useReservationContext()
  const { updateResStatus } = functions
  const { resStatuses, resDetails } = values
  const { resStatus_id, resStatusDescription } = resDetails.reservation
  const [loading, setLoading] = useState(false)
  const [selectedStatus_id, setSelectedStatus_id] = useState(resStatus_id)
  const [description, setDescription] = useState(resStatusDescription || "")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { t } = useTranslation()

  const saveHandler = async () => {
    setLoading(true)
    const result = await updateResStatus(selectedStatus_id, description)
    setLoading(false)

    if (result) {
      setShowSuccessAlert(true)
    }
  }

  const hideSuccessAlert = async () => {
    setShowSuccessAlert(false)
    close()
  }

  return (
    <Modal isOpen={true} toggle={close} size="lg">
      <header className={styles.header}>
        <h5>{t("Reservation Status")}</h5>
        <span onClick={close}>
          <CgClose />
        </span>
      </header>
      <ModalBody className={styles.modalBody}>
        <FormControl className={styles.paymentStatusFormControl}>
          <RadioGroup
            name="radio-buttons-group"
            onChange={(_, selected) => setSelectedStatus_id(+selected)}
            value={selectedStatus_id}
          >
            {resStatuses.map((i, idx) =>
              idx > 2 ? null : (
                <div key={i.id}>
                  <FormControlLabel
                    value={i.id}
                    control={<Radio />}
                    label={t(i.name)}
                    disabled={i.id === 1}
                  />
                  {selectedStatus_id === 3 && i.id === 3 && (
                    <TextField
                      label={t("Description")}
                      variant="outlined"
                      onChange={e => setDescription(e.target.value)}
                      value={description}
                      size="small"
                      fullWidth
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  )}
                </div>
              )
            )}
          </RadioGroup>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          {t("Close")}
        </Button>
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={saveHandler}
        >
          {t("Save")}
          {loading && <Spinner size="sm" className="ml-1" />}
        </Button>
      </ModalFooter>
      {showSuccessAlert && <SuccessAlert hideSuccessAlert={hideSuccessAlert} />}
    </Modal>
  )
}

export default ResStatusModal
