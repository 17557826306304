import React from "react"
import { Card, CardBody, Input, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

export default function Currencies({ currencies, currencyCheckHandler }) {
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("In which currencies is your promotion valid?")}
        </h5>
        <Row className={styles.currencies}>
          {currencies?.map(item => (
            <Col className={styles.column}>
              <div
                key={item.id}
                onClick={() => currencyCheckHandler(item.id)}
                className={styles.singleCheckbox}
              >
                <Input
                  type="checkbox"
                  className={styles.input}
                  name={item.name}
                  id={item.id}
                  onChange={() => {}}
                  checked={item.isChecked || false}
                />
                <span>{item.name}</span>
              </div>
              <div className="d-flex">
                <div className={styles.placeholder}></div>
                <p className={styles.description}>{item.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}
