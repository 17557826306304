import { useState } from "react"
import { useHistory } from "react-router-dom"

import CustomWarning from "../components/Common/CustomWarning"

export default () => {
  const [showHotelNotRegWarning, setShowHotelNotRegWarning] = useState(false)
  const history = useHistory()

  const HotelNotRegisteredComp = showHotelNotRegWarning ? (
    <CustomWarning
      title="Warning!"
      warning={true}
      show={showHotelNotRegWarning}
      onConfirm={() => {
        setShowHotelNotRegWarning(false)
        history.push("/hotel-information")
      }}
      message="Please Register Your Hotel Information First!"
    />
  ) : null

  return { setShowHotelNotRegWarning, HotelNotRegisteredComp }
}
