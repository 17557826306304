import {
  Container,
  Card,
  CardBody,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import GuestInfoSection from "./GuestInfoSection"
import AgeSection from "./AgeSection"
import CurfewSection from "./CurfewSelection"
import { GuestInfoProvider, useGuestInfoContext } from "./context"
import styles from "./styles.module.scss"

const HotelGuestInfoPolicy = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { values, setters, functions } = useGuestInfoContext()
  const {
    loading,
    submitLoading,
    errorMessage,
    showSuccessAlert,
    hotelNotReg,
  } = values
  const { setShowSuccessAlert, setHotelNotReg } = setters
  const { submitHandler } = functions
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Guest Info Policy")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <GuestInfoSection />
            <AgeSection />
            <CurfewSection />
          </CardBody>
        </Card>

        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <CustomWarning
          title="Warning!"
          warning={true}
          show={hotelNotReg}
          onConfirm={() => {
            setHotelNotReg(false)
            history.push("/hotel-information")
          }}
          message="Please Register Your Hotel Information First!"
        />

        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

const WithProvider = props => {
  return (
    <GuestInfoProvider>
      <HotelGuestInfoPolicy {...props} />
    </GuestInfoProvider>
  )
}
export default WithProvider
