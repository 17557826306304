import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useSocket } from "contexts/socket-context"

const MessagesContext = createContext()
export const useMessagesContext = () => {
  const context = useContext(MessagesContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const MessagesProvider = props => {
  const { socket } = useSocket()
  const currentUser = useSelector(state => state.User.currentUser)

  const [conversations, setConversations] = useState([])
  const [messages, setMessages] = useState([])
  const [currentConversation_id, setCurrentConversation_id] = useState("")
  const [currentMessage, setCurrentMessage] = useState("")

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    setLoading(true)
    let currentConversation_id
    // EMIT EVENTS
    socket.emit("fetch-conversations", response => {
      if (response?.result) {
        let conversations = response?.dbResult

        conversations = conversations.map(i => ({
          ...i,
          name: `${i.customerName} (${i.resNo})`,
        }))
        setConversations(conversations)
        if (conversations?.length > 0) {
          currentConversation_id = conversations[0].id
          setCurrentConversation_id(currentConversation_id)
        }
      } else {
        setErrorMessage(response.message)
      }

      setLoading(false)
    })

    // SET LISTENERS
    socket.on("new-conversation-created", newConversation => {
      setConversations(prev => [
        {
          ...newConversation,
          name: `${newConversation.customerName} (${newConversation.resNo})`,
        },
        ...prev,
      ])

      if (!currentConversation_id) {
        setCurrentConversation_id(newConversation.id)
      }
    })
    // listening for new messages to come
    socket.on("receive-message", newMsg => {
      // update conversations lastMessage and time
      setConversations(prev => {
        let newConversations = prev.map(i => {
          if (i.id === newMsg.conversation_id) {
            return {
              ...i,
              lastMessage: newMsg.message,
              time: new Date(newMsg.createdAt) - 2000,
              unreadMessageCount: (i.unreadMessageCount || 0) + 1,
            }
          } else {
            return i
          }
        })
        newConversations.sort(
          (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
        )

        return newConversations
      })

      // update messages if currentConversation_id is same as newMessageConversation_id
      setCurrentConversation_id(id => {
        if (id === newMsg.conversation_id) {
          setMessages(prev => [...prev, newMsg])
        }
        return id
      })

      setScroll(true)
    })

    socket.on("customer-status-changed", data => {
      setConversations(prevCons =>
        prevCons.map(con =>
          con.id === data.id
            ? { ...con, customerStatus: data.customerStatus }
            : con
        )
      )
    })

    socket.on("messages-read", conversation_id => {
      setMessages(messages =>
        messages.map(i =>
          i.conversation_id === conversation_id ? { ...i, readStatus: true } : i
        )
      )
    })

    // REMOVE LISTENER
    return () => {
      if (socket) {
        socket.off("receive-message")
        socket.off("new-conversation-created")
        socket.off("customer-status-changed")
        socket.off("message-read")
      }
    }
  }, [])

  useEffect(() => {
    if (currentConversation_id) {
      const payload = { conversation_id: currentConversation_id }
      socket.emit("fetch-messages", payload, response => {
        if (response?.result) {
          setMessages(response?.dbResult)
          setScroll(true)
        } else {
          setErrorMessage(response?.message)
        }
      })
    }
  }, [currentConversation_id])

  const addMessage = (conversation_id, message) => {
    // find customer id
    const customer_id = conversations.find(
      i => i.id === conversation_id
    )?.customer_id

    const payload = {
      conversation_id,
      message,
      customer_id,
    }

    const cb = response => {
      if (response?.result) {
        const newMsg = response.dbResult
        setCurrentMessage("")
        setConversations(prev => {
          let newCons = prev.map(con =>
            con.id === newMsg.conversation_id
              ? {
                  ...con,
                  lastMessage: newMsg.message,
                  time: new Date(newMsg.createdAt).getTime() - 5000,
                }
              : con
          )
          newCons.sort(
            (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
          )
          return newCons
        })
        setMessages(prev => [...prev, newMsg])
        setScroll(true)
      }
    }

    socket.emit("send-message", payload, cb)
  }

  const setMessagesToRead = conversation => {
    if (conversation.unreadMessageCount > 0) {
      const cb = response => {
        if (response?.result) {
          setConversations(prev => {
            return prev.map(i =>
              i.id === conversation.id ? { ...i, unreadMessageCount: 0 } : i
            )
          })
          setMessages(prevMsg => prevMsg.map(i => ({ ...i, readStatus: true })))
        }
      }
      socket.emit(
        "set-customer-messages-to-read",
        {
          conversation_id: conversation.id,
          customer_id: conversation.customer_id,
        },
        cb
      )
    }
  }

  const deleteMessage = message_id => {
    socket.emit("delete-message", { message_id }, response => {
      if (response?.result) {
        setMessages(prev => prev.filter(i => i.id !== message_id))
      }
    })
  }

  const value = useMemo(() => {
    const setters = {
      setLoading,
      setSubmitLoading,
      setErrorMessage,
      //
      setConversations,
      setMessages,
      setCurrentConversation_id,
      setCurrentMessage,
      setScroll,
    }
    const values = {
      submitLoading,
      loading,
      errorMessage,
      //
      currentUser,
      conversations,
      messages,
      currentConversation_id,
      currentMessage,
      scroll,
    }
    const functions = { addMessage, setMessagesToRead, deleteMessage }
    return { setters, values, functions }
  }, [
    submitLoading,
    loading,
    errorMessage,
    //
    currentUser,
    conversations,
    messages,
    currentConversation_id,
    currentMessage,
    scroll,
  ])

  return <MessagesContext.Provider value={value} {...props} />
}
