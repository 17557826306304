import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import { getTranslatedOption } from "utils/other"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import PromotionTypeItem from "./PromotionTypeItem"
import BasicDealPromotionForm from "./PromotionForms/BasicDealPromotionForm"
import LastMinDealProForm from "./PromotionForms/LastMinDealProForm"
import EarlyBookerDealForm from "./PromotionForms/EarlyBookerDealForm"
import FreeNightsDealForm from "./PromotionForms/FreeNightsDealForm"
import LongStayDealForm from "./PromotionForms/LongStayDealForm"
import styles from "./styles.module.scss"

const HotelPromotion = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [componentIndex, setComponentIndex] = useState("")
  const [, setHotel_id] = useState("")
  const [promotions, setPromotions] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )
      if (response?.dbResult) {
        const hotel_id = response.dbResult.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }

    const fetchPromotions = async () => {
      const response = await request(
        {
          url: "/hotel-set-promotion-types",
        },
        false
      )

      if (response?.dbResult) {
        const dbResult = response.dbResult
        setPromotions(response.dbResult)
        return dbResult
      }
    }
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      await fetchPromotions()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (promotions.length > 0) {
      const newPromotions = promotions?.map(item => {
        return {
          ...item,
          name: getTranslatedOption(item, "name", locale),
          info: getTranslatedOption(item, "info", locale),
          subInfo: getTranslatedOption(item, "subInfo", locale),
        }
      })
      setPromotions(newPromotions)
    }
  }, [locale, promotions.length])

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Promotions")}
        />
        {loading && <CustomSpinner color="primary" />}
        {!loading && (
          <>
            <Card>
              <CardBody>
                <h4 className={styles.title}>{t("Promotions")}</h4>
                <p className={styles.subtitle}>
                  {t(
                    "Increase your occupancy with a variety of opportunity types that can be customized and tailored to your needs."
                  )}
                </p>
              </CardBody>
            </Card>

            {promotions.length > 0 &&
              promotions?.map((item, idx) => (
                <PromotionTypeItem
                  key={item.id}
                  item={item}
                  index={idx}
                  setComponentIndex={setComponentIndex}
                />
              ))}

            {componentIndex === 0 && (
              <BasicDealPromotionForm
                modalTitle={t("Create a basic deal")}
                isOpen={true}
                name={t("Basic Deal")}
                close={() => {
                  setComponentIndex("")
                }}
                setComponentIndex={setComponentIndex}
              />
            )}

            {componentIndex === 1 && (
              <LastMinDealProForm
                modalTitle={t("Create a last-minute deal")}
                isOpen={true}
                name={t("Last Minute Deal")}
                close={() => {
                  setComponentIndex("")
                }}
                setComponentIndex={setComponentIndex}
              />
            )}

            {componentIndex === 2 && (
              <EarlyBookerDealForm
                modalTitle={t("Create a early booker deal")}
                isOpen={true}
                name={t("Early Booker Deal")}
                close={() => {
                  setComponentIndex("")
                }}
                setComponentIndex={setComponentIndex}
              />
            )}

            {componentIndex === 3 && (
              <FreeNightsDealForm
                modalTitle={t("Create a free nights deal")}
                isOpen={true}
                name={t("Free Nights Deal")}
                close={() => {
                  setComponentIndex("")
                }}
                setComponentIndex={setComponentIndex}
              />
            )}

            {componentIndex === 4 && (
              <LongStayDealForm
                modalTitle={t("Create a long-stay deal")}
                isOpen={true}
                name={t("Long Stay Deal")}
                close={() => {
                  setComponentIndex("")
                }}
                setComponentIndex={setComponentIndex}
              />
            )}
          </>
        )}
      </Container>
      {HotelNotRegisteredComp}
    </main>
  )
}
export default HotelPromotion
