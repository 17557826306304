import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  userId: "",
  token: "",
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        userId: action.userId,
        token: action.token,
        errorMessage: "",
      }
      break
    case LOGOUT_USER:
      state = { ...state, loading: true, error: false, errorMessage: "" }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, userId: "", loading: false, token: "" }
      break
    case API_ERROR:
      state = {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
