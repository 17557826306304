import React, { useState, useEffect } from "react"
import { Modal, Button, Spinner, Row, Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"
import { TiLocation } from "react-icons/ti"
import { HiMinus, HiPlus } from "react-icons/hi"
import Select from "react-select"

import CustomWarning from "components/Common/CustomWarning/index"
import { getFormattedDistance, getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

const CustomModal = ({ isOpen, close, property }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [distance, setDistance] = useState("")

  const [placeName, setPlaceName] = useState("")

  const [places, setPlaces] = useState([])
  const [placeDistances, setPlaceDistances] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchPlaces(), fetchPropertyPlaceDistances()])
      setLoading(false)
    })()
  }, [])

  const fetchPlaces = async () => {
    const response = await request(
      {
        url: "/property-set-places",
      },
      false
    )
    const places = response?.dbResult?.map(i => ({
      ...i,
      label: getLabelByLanguage(i, locale),
      value: i.id,
    }))
    setPlaces(places)
  }

  const fetchPropertyPlaceDistances = async () => {
    const response = await request(
      {
        url: "/property-place-distances/fetch-by-property",
        params: { property_id: property.id },
      },
      false
    )

    setPlaceDistances(response.dbResult)
  }

  const addItemHandler = () => {
    if (placeName.trim().length === 0 || !selectedPlace || !distance) {
      return setShowBlankFieldAlert(true)
    }

    // add to property places
    setPlaceDistances([
      ...placeDistances,
      {
        id: uuid(),
        name: placeName,
        setPlace_id: selectedPlace.value,
        distance,
        property_id: property.id,
      },
    ])

    // reset
    setPlaceName("")
    setSelectedPlace("")
    setDistance("")
  }

  const deleteItemHandler = id => {
    setPlaceDistances(places => places.filter(i => i.id !== id))
  }

  const submitHandler = async () => {
    setSaveLoading(true)
    const response = await request(
      {
        url: "/property-place-distances",
        method: "patch",
        data: { placeDistances, property_id: property.id },
      },
      false
    )

    setSaveLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response?.message)
    }
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("What's nearby?")}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body min-height-500">
        {loading && (
          <div className="spinner-background">
            <Spinner size="lg" color="primary" className="spinner" />
          </div>
        )}
        {/* CONTENT */}
        <h4 className={styles.title}>{t("Tell us what's nearby")}</h4>
        <p className={`${styles.subtitle} mb-0`}>
          {t(
            "When deciding where to stay, one of the most essential considerations for visitors is location; they want to know if there are restaurants or grocery stores nearby."
          )}
        </p>
        <p className={styles.subtitle}>
          {t(
            "What are your top three suggestions for restaurants, cafes, bars, marketplaces and grocery stores?"
          )}
        </p>

        <Row className={`${styles.row} mt-3 mb-2`}>
          <div className={styles.col}>
            <Label>{t("Place name")}</Label>
            <Input
              type="text"
              value={placeName}
              onChange={e => setPlaceName(e.target.value)}
            />
          </div>
          <div className={styles.col}>
            <Label>{t("Place type")}</Label>
            <Select
              placeholder=""
              value={selectedPlace}
              noOptionsMessage={() => t("No Options")}
              options={places}
              onChange={setSelectedPlace}
            />
          </div>

          <div className={styles.col}>
            <Label>{t("Distance in meter")}</Label>
            <Input
              type="number"
              value={distance}
              onChange={e =>
                setDistance(e.target.value.replace(/[^0-9\.]+/g, ""))
              }
            />
          </div>
          <div
            className={styles.addIcon}
            onClick={addItemHandler}
            title={t("Add")}
          >
            <HiPlus size={22} className="mr-n2" />
            <TiLocation size={25} />
          </div>
        </Row>

        <div className={styles.childPolicyList}>
          {placeDistances?.map((item, idx) => {
            const placeName = places.find(
              place => place.value === item.setPlace_id
            )?.label
            return (
              <Row className={styles.row} key={item.id}>
                <div className={`${styles.col} pl-2`}>{item?.name}</div>
                <div className={styles.col}>{placeName}</div>
                <div className={styles.col}>
                  {getFormattedDistance(item?.distance)}
                </div>

                <div
                  className={styles.deleteIcons}
                  onClick={deleteItemHandler.bind("", item.id)}
                  title={t("Delete")}
                >
                  <HiMinus size={22} className="mr-n2" />
                  <TiLocation size={25} />
                </div>
              </Row>
            )
          })}
        </div>
        {showSuccessAlert && (
          <CustomWarning
            title="Congratulations!"
            success={true}
            show={showSuccessAlert}
            onConfirm={() => {
              setShowSuccessAlert(false)
              close()
            }}
            message="The data is saved successfully!"
          />
        )}
      </div>
      <div className="modal-footer">
        <Button type="button" className="btn btn-secondary" onClick={close}>
          {t("Close")}
        </Button>

        <Button
          size="md"
          color="primary"
          onClick={submitHandler}
          disabled={saveLoading}
        >
          {saveLoading ? (
            <>
              <Spinner size="sm" /> {t("Loading...")}{" "}
            </>
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default CustomModal
