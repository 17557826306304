import React from "react"
import { Card, CardBody, FormGroup, Input, Label, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

export default function Prices(props) {
  const { prices, setPrices, isChildAllowed, currency } = props
  const { t } = useTranslation()

  const handlePriceChange = (key, value) => {
    setPrices(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Prices")}</h4>
        <Row form>
          <Col md={7}>
            <FormGroup row>
              <Label for="price" md={3}>
                {t("Adult")}
              </Label>
              <Col md={4}>
                <Input
                  type="number"
                  min={0}
                  value={prices.adultPrice}
                  onChange={e =>
                    handlePriceChange("adultPrice", e.target.value)
                  }
                />
              </Col>
              <Col md={1} className="d-flex align-items-center">
                <h4 className={styles.currency}>{currency}</h4>
              </Col>
            </FormGroup>
          </Col>
        </Row>

        {isChildAllowed && (
          <>
            <Row form>
              <Col md={7}>
                <FormGroup row>
                  <Label for="price" md={3}>
                    {t("Child")}
                  </Label>
                  <Col md={4}>
                    <Input
                      type="number"
                      min={0}
                      value={prices.childPrice}
                      onChange={e =>
                        handlePriceChange("childPrice", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={1} className="d-flex align-items-center">
                    <h4 className={styles.currency}>{currency}</h4>
                  </Col>
                  <Col md={2} className="ml-4 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className={styles.input}
                      name={"free"}
                      onChange={() => {
                        handlePriceChange("childPrice", 0)}}
                      checked={prices?.childPrice === 0}
                    />
                    <span className="mt-1">{t("Free")}</span>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={7}>
                <FormGroup row>
                  <Label for="price" md={3}>
                    {t("Baby")}
                  </Label>
                  <Col md={4}>
                    <Input
                      type="number"
                      min={0}
                      value={prices.babyPrice}
                      onChange={e =>
                        handlePriceChange("babyPrice", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={1} className="d-flex align-items-center">
                    <h4 className={styles.currency}>{currency}</h4>
                  </Col>
                  <Col md={2} className="ml-4 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className={styles.input}
                      name={"free"}
                      onChange={() => {handlePriceChange("babyPrice", 0)}}
                      checked={prices?.babyPrice === 0}
                    />
                    <span className="mt-1">{t("Free")}</span>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  )
}
