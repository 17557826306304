import React, { useEffect } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from "reactstrap"
import { Controller } from "react-hook-form"

import CustomSwitch from "components/Common/CustomSwitch/index"
import { usePropertyContext } from "./context"
import { selectError } from "utils/other"
import styles from "./styles.module.scss"

export default ({ useFormResult, isAddOperation, property }) => {
  const { register, errors, setValue, control } = useFormResult
  const { values } = usePropertyContext()
  const { types } = values
  useEffect(() => {
    if (!isAddOperation) {
      const {
        propertyType_id,
        name,
        checkInTime,
        checkOutTime,
        bedroomCount,
        livingRoomCount,
        bathroomCount,
        status,
        hasSwimmingPool,
      } = property

      setValue("type_id", propertyType_id)
      setValue("propertyName", name)
      setValue("checkInTime", checkInTime)
      setValue("checkOutTime", checkOutTime)
      setValue("bedroomCount", bedroomCount)
      setValue("livingRoomCount", livingRoomCount)
      setValue("bathroomCount", bathroomCount)
      setValue("status", status)
      setValue("hasSwimmingPool", hasSwimmingPool)
    }
  }, [])
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Property Info")}</CardTitle>
        <hr />
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.type_id && styles.error}`}>
            <Label>* {t("Type")}</Label>
            <Controller
              control={control}
              name="type_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={types}
                    value={types?.find(i => i?.value === value)}
                    onChange={selectedType => onChange(selectedType?.value)}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.type_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.type_id && <p>{t(errors?.type_id?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.propertyName && styles.error}`}>
            <Label>* {t("Property Name")}</Label>
            <Input
              name="propertyName"
              innerRef={register}
              invalid={!!errors?.propertyName}
            />

            {errors?.propertyName && <p>{t(errors?.propertyName?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.checkInTime && styles.error}`}>
            <Label>* {t("Check-in Time")}</Label>
            <Input
              name="checkInTime"
              type="time"
              defaultValue={"16:00:00"}
              innerRef={register}
              invalid={!!errors?.checkInTime}
            />

            {errors?.checkInTime && <p>{t(errors?.checkInTime?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.checkOutTime && styles.error}`}>
            <Label>* {t("Check-out Time")}</Label>
            <Input
              name="checkOutTime"
              type="time"
              defaultValue={"10:00:00"}
              innerRef={register}
              invalid={!!errors?.checkOutTime}
            />

            {errors?.checkOutTime && <p>{t(errors?.checkOutTime?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.bedroomCount && styles.error}`}>
            <Label>* {t("Bedroom Count")}</Label>
            <Input
              name="bedroomCount"
              innerRef={register}
              invalid={!!errors?.bedroomCount}
              type="number"
              step={1}
              min={0}
            />
            {errors?.bedroomCount && <p>{t(errors?.bedroomCount?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.livingRoomCount && styles.error}`}>
            <Label>* {t("Living Room Count")}</Label>
            <Input
              name="livingRoomCount"
              innerRef={register}
              invalid={!!errors?.livingRoomCount}
              type="number"
              step={1}
              min={0}
            />
            {errors?.livingRoomCount && (
              <p>{t(errors?.livingRoomCount?.message)}</p>
            )}
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.bathroomCount && styles.error}`}>
            <Label>* {t("Bathroom Count")}</Label>
            <Input
              name="bathroomCount"
              innerRef={register}
              invalid={!!errors?.bathroomCount}
              type="number"
              step={1}
              min={0}
            />
            {errors?.bathroomCount && (
              <p>{t(errors?.bathroomCount?.message)}</p>
            )}
          </Col>
          <Col md={2} className={styles.center}>
            <Controller
              control={control}
              name="status"
              defaultValue={true}
              render={({ onChange, value }) => {
                return (
                  <CustomSwitch
                    label={t("Status")}
                    onClick={() => onChange(!value)}
                    value={value}
                  />
                )
              }}
            />
          </Col>
          <Col md={4} className={styles.center}>
            <Controller
              control={control}
              name="hasSwimmingPool"
              defaultValue={false}
              render={({ onChange, value }) => {
                return (
                  <CustomSwitch
                    label={t("Swimming pool")}
                    onClick={() => onChange(!value)}
                    value={value}
                  />
                )
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
