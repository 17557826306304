import React from "react"
import { Modal } from "reactstrap"
import { withTranslation } from "react-i18next"
import Uploader from "./Uploader"

class EditModal extends React.Component {
  closeModel = () => {
    // if photos are uploading, don't allow closing the modal
    if (this.childRef.isLoading()) {
      this.childRef.showAlert()
      return
    } else {
      this.props.close()
    }
  }
  render() {
    const { room, hotel_id, t } = this.props
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          scrollable={true}
          size="xl"
          toggle={this.closeModel}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{t("Add/Remove Photo")}</h5>
            <button
              type="button"
              onClick={this.closeModel}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body min-height-240">
            <h6 className="mb-2">{t('You should add at least 4 photos.')}</h6>
            <Uploader
              room_id={room.id}
              hotel_id={hotel_id}
              onRef={childRef => {
                this.childRef = childRef
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.closeModel}
            >
              {t("Close")}
            </button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withTranslation()(EditModal)
