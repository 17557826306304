import React, { Component } from "react"
import { Switch, withRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes/"
import {
  autoLogin,
  logoutUser,
  loginSuccess,
  autoLogout,
  getCompany,
} from "./store/actions"
import { SocketProvider } from "./contexts/socket-context"
import { MasterSocketProvider } from "./contexts/master-socket-context"

// layouts
import VerticalLayout from "./components/Common/Layouts/index"

// Import scss
import "./assets/scss/theme.scss"
import "./App.scss"
import "./pages/Villa/Reports/AvailabilityReport/calendar.scss"

class App extends Component {
  state = { shouldRender: false }

  componentDidMount = () => {
    const history = this.props?.history
    const isPublic = !!publicRoutes.some(
      route => route.path === history?.location?.pathname
    )
    if (isPublic && history) {
      this.props.history.push(
        history.location.pathname + history.location.search
      )
      this.setState({ shouldRender: true })
      return
    }

    const token = localStorage.getItem("token")
    if (!token) {
      this.props.logoutUser(this.props.history)
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"))
      if (expirationDate <= new Date()) {
        this.props.logoutUser(this.props.history)
      } else {
        const userId = localStorage.getItem("userId")
        const updatedExpirationDate =
          (expirationDate.getTime() - new Date().getTime()) / 1000
        this.props.loginSuccess(userId, token)
        this.props.getCompany(token)
        this.props.autoLogout(updatedExpirationDate, this.props.history)
      }
    }
    this.setState({ shouldRender: true })
  }

  render() {
    const { shouldRender } = this.state
    // company type
    let [isVilla, isTransfer, isHotel, isTicket] = [false, false, false, false]
    this.props?.companyTypes?.forEach(type => {
      if (type.companyType_id === 1) {
        isHotel = true
      }
      if (type.companyType_id === 2) {
        isVilla = true
      }
      if (type.companyType_id === 3) {
        isTransfer = true
      }
      if (type.companyType_id === 4) {
        isTicket = true
      }
    })

    const protectedRoutesList = []
    for (const groupRoutes in authProtectedRoutes) {
      authProtectedRoutes[groupRoutes].forEach(route => {
        protectedRoutesList.push(route.path)
      })
    }

    return (
      <Switch>
        {shouldRender && (
          <SocketProvider>
            <MasterSocketProvider>
              <Route path={publicRoutes.map(route => route.path)}>
                {publicRoutes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    component={route.component}
                    exact
                  />
                ))}
              </Route>

              <Route path={protectedRoutesList} exact>
                <VerticalLayout>
                  {authProtectedRoutes.commonPages.map((route, idx) => (
                    <Route
                      key={idx}
                      path={route.path}
                      component={route.component}
                      exact
                    />
                  ))}
                  {isVilla &&
                    authProtectedRoutes.villaPages.map((route, idx) => (
                      <Route
                        key={idx}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    ))}
                  {isHotel &&
                    authProtectedRoutes.hotelPages.map((route, idx) => (
                      <Route
                        key={idx}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    ))}
                  {isTransfer &&
                    authProtectedRoutes.transferPages.map((route, idx) => (
                      <Route
                        key={idx}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    ))}
                  {isTicket &&
                    authProtectedRoutes.ticketPages.map((route, idx) => (
                      <Route
                        key={idx}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    ))}
                </VerticalLayout>
              </Route>
            </MasterSocketProvider>
          </SocketProvider>
        )}
      </Switch>
    )
  }
}

const mapStateToProps = state => {
  return {
    companyTypes: state.Company.company.types,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    autoLogin,
    logoutUser,
    loginSuccess,
    autoLogout,
    getCompany,
  })(App)
)
