import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useTranslation } from "react-i18next"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import WidgetPagination from "components/Common/WidgetPagination/index"
import styles from "./styles.module.scss"
import { formatDate, formatDateTime } from "utils/dateTimeFunctions"
import { getTranslatedOption } from "utils/other"
const PromotionsData = props => {
  const [selectedRowId, setSelectedRowId] = useState("")
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const {
    onSelectTableRow,
    promotions,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const PromotionRows = promotions?.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
          setSelectedRowId(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "vehicleType", locale)}
        </Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "locationStart", locale)}
        </Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "locationEnd", locale)}
        </Td>
        <Td className={styles.dataCell}>
          {formatDate(item?.bookingStartDate)} -{" "}
          {formatDate(item?.bookingEndDate)}
        </Td>
        <Td className={styles.dataCell}>
          {formatDate(item?.resStartDate)} - {formatDate(item?.resEndDate)}
        </Td>
        <Td className={styles.dataCell}>
          {item.onlyMembers ? t("Yes") : t("No")}
        </Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "promotionType", locale)}
        </Td>
        <Td className={styles.dataCell}>{item.discountRate}</Td>
        <Td className={styles.dataCell}>{item?.marketingPlaceName}</Td>
        <Td className={styles.dataCell}>{formatDateTime(item?.createdAt)}</Td>
      </Tr>
    )
  })

  return (
    <Card className={styles.card}>
      <CardBody style={{ padding: "0px" }}>
        <div className="table-rep-plugin">
          <div className={`table-responsive ${styles.tContainer}`}>
            <Table className="table table-striped table-bordered">
              <Thead>
                <Tr>
                  <Th className={styles.th}>{t("Vehicle Type")}</Th>
                  <Th className={styles.th}>{t("Location Start")}</Th>
                  <Th className={styles.th}>{t("Location End")}</Th>
                  <Th className={styles.th}>{t("Bookable period")}</Th>
                  <Th className={styles.th}>{t("Transfer Date")}</Th>
                  <Th className={styles.th}>{t("Only Members")}</Th>
                  <Th className={styles.th}>{t("Promotion type")}</Th>
                  <Th className={styles.th}>{t("Discount rate")}</Th>
                  <Th className={styles.th}>{t("Marketing Place")}</Th>
                  <Th className={styles.th}>{t("Registration date")}</Th>
                </Tr>
              </Thead>

              <Tbody>{PromotionRows}</Tbody>
            </Table>
          </div>
          {hasPagination && (
            <WidgetPagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default PromotionsData
