import moment from "moment"
import { useState } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"

import { getTranslatedOption, formatMoney } from "utils/other"
import { useReservationContext } from "../context"
import styles from "./styles.module.scss"

export default function Children() {
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  moment.locale(locale)
  const {
    values: { resDetails },
  } = useReservationContext()
  const { reservation, children } = resDetails

  if (!reservation) {
    return null
  }

  const el = document.getElementById("children")

  return (
    <section>
      <Card>
        <CardBody>
          <header className={styles.header}>
            <div>
              <h5>{t("Children")}</h5>
              <div
                className={styles.control}
                onClick={() => setIsOpen(prev => !prev)}
              >
                {isOpen ? (
                  <RiArrowUpSLine size={23} />
                ) : (
                  <RiArrowDownSLine size={23} />
                )}
              </div>
            </div>
          </header>
          <article
            className={styles.article}
            style={isOpen && el ? { maxHeight: el.clientHeight + 200 } : {}}
          >
            <TableContainer component={Paper} id="children">
              <Table>
                <TableHead>
                  <TableRow className={styles.headRow}>
                    <TableCell>{t("Age")}</TableCell>
                    <TableCell>{t("Baby Seat Requested")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {children?.map(item => (
                    <TableRow className={styles.bodyRow} key={item.id}>
                      <TableCell>{item.childAge}</TableCell>
                      <TableCell>
                        {item.childAge <= 3
                          ? item.babySeat
                            ? t("Yes")
                            : t("No")
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </article>
        </CardBody>
      </Card>
    </section>
  )
}
