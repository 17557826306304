import { Container, Row, Col, Spinner, Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { MdArrowBackIosNew } from "react-icons/md"
import { Link } from "react-router-dom"

import { ReservationProvider, useReservationContext } from "./context"
import InfoSection from "./InfoSection.js"
import ButtonsSection from "./ButtonsSection/ButtonsSection.js"
import DailyDetailSection from "./DailyDetailSection/index"
import ExtraServicesSection from "./ExtraServicesSection/index"
import FeaturesAndConditions from "./FeaturesAndCondition"
import Children from "./Children"

import styles from "./styles.module.scss"

const ReservationDetails = () => {
  const { t } = useTranslation()
  const {
    values: { loading, error, resNotUsedSucceed, resCancelSucceed },
  } = useReservationContext()
  return (
    <main className={`page-content ${styles.main}`}>
      <Container fluid>
        <Link to="/transfer-reservations" className={styles.backToPage}>
          <MdArrowBackIosNew /> {t("Back to reservation overview")}
        </Link>
        <h4 className="mt-3">{t("Reservation Info")}</h4>
        {(resNotUsedSucceed || resCancelSucceed) && (
          <Alert color="success">
            {t("Reservation status updated successfully!")}
          </Alert>
        )}
        {error && <Alert color="danger">{t(error)}</Alert>}
        {loading ? (
          <Spinner className="absolute-spinner" color="primary" />
        ) : (
          <Row>
            <Col md={8} id="section-to-print">
              <InfoSection />
              <DailyDetailSection />
              <ExtraServicesSection />
              <Children />
              <FeaturesAndConditions />
            </Col>
            <Col md={4}>
              <ButtonsSection />
            </Col>
          </Row>
        )}
      </Container>
    </main>
  )
}

const WithContext = props => {
  return (
    <ReservationProvider>
      <ReservationDetails {...props} />
    </ReservationProvider>
  )
}

export default WithContext
