import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import TicketsTable from "components/Ticket/TicketsTable"
import EditModal from "./EditModal"

const CancelToken = axios.CancelToken
let cancel

class TicketPhotos extends Component {
  state = {
    isEditModalOpen: false,
    tickets: [],
    selectedRowId: "",
    selectedRowData: null,
    showWarningAlert: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    errorMessage: "",
    error: false,
    locale: "",
  }

  componentDidMount = () => {
    this.fetchTickets()
    this.setState({ locale: this.props.i18n.language })
  }

  fetchTickets = async () => {
    try {
      this.setState({ loading: true })
      const { activePage, itemsCountPerPage, searchText } = this.state
      const url = `/ticket/fetch-by-page-number?page=${activePage}&limit=${itemsCountPerPage}&searchText=${searchText}`

      if (cancel !== undefined) {
        cancel()
      }

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }
      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: {},
          tickets: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      } else {
        console.log(error)
      }

      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedRowId => {
    const { tickets } = this.state
    let selectedRowData = tickets.find(item => {
      return item.id == selectedRowId
    })

    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchTickets)
  }

  refreshTable = () => {
    this.setState(
      { activePage: 1, searchText: "", totalItemsCount: 0 },
      this.fetchTickets
    )
  }

  render() {
    const { t } = this.props
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      tickets,
      loading,
      errorMessage,
      error,
      selectedRowId,
      isEditModalOpen,
      selectedRowData,
      showWarningAlert,
    } = this.state

    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <TicketsTable
          tickets={tickets}
          onSelectTableRow={this.onSelectTableRow}
          selectedRowId={this.state.selectedRowId}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Ticket")}
              breadcrumbItem={t("Ticket Photos")}
            />
            <ControlButtons
              displayAddBtn={true}
              addTitle="Add/Remove Photo"
              displayEditBtn={false}
              displayDeleteBtn={false}
              onAddBtn={() => {
                if (selectedRowId) {
                  this.setState({ isEditModalOpen: true })
                } else {
                  this.setState({ showWarningAlert: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState({ searchText, activePage: 1 }, this.fetchTickets)
              }}
            />
            {Content}
          </Container>
        </div>
        {isEditModalOpen && (
          <EditModal
            isOpen={true}
            close={() => {
              this.setState({ isEditModalOpen: false })
            }}
            ticket={selectedRowData}
            refreshTable={this.refreshTable}
          />
        )}

        {showWarningAlert ? (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningAlert: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        ) : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return { token }
}

export default withTranslation()(connect(mapStateToProps, {})(TicketPhotos))
