import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FaPhoneSquareAlt } from "react-icons/fa"

import LanguageDropdown from "components/Common/Layouts/TopbarDropdown/LanguageDropdown"
import vLogo from "assets/images/vihobook-4.png"
import altLogo from "assets/images/alt-logo.png"

import styles from "./layout.module.scss"
import { Button } from "reactstrap"

const Layout = props => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <header>
        <div className="container">
          <Link to="/register">
            <img src={vLogo} height="48px" />
          </Link>
          <div className={styles.rightSide}>
            <LanguageDropdown color="white" />
            <p>{t("Already a partner?")}</p>
            <Link to="/login">
              <Button className={styles.button} color="secondary" outline>
                {t("Login")}
              </Button>
            </Link>
          </div>
        </div>
      </header>
      {props.children}
      <footer>
        <div className="container">
          <div className={styles.copyright}>
            <span>
              {t("Copyright ©️ ")}
              {new Date().getFullYear()}.
            </span>
            <img src={altLogo} alt="alt logo" height="30" />
            <span>{t("All rights reserved.")}</span>
          </div>
          <a
            href="tel:00908508887339"
            className="font-size-13 d-flex align-items-center"
          >
            <FaPhoneSquareAlt size={20} />{" "}
            <span className="ml-1">+90 850 888 7339</span>
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
