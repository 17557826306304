import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AvField, AvForm } from "availity-reactstrap-validation-safe"
import { Alert, Card, CardBody, Container, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import Layout from "./Layout"
import useRequest from "hooks/useRequest"
import styles from "./forgot-password.module.scss"

const ForgetPasswordPage = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)

  const handleValidSubmit = async (event, values) => {
    const email = values.email
    const response = await request({
      method: "PUT",
      url: "/auth/send-reset-link",
      data: { email },
    })
    if (response && response.result) {
      setSuccessModalVisible(true)
    }
  }

  return (
    <Layout>
      <Container>
        <main className={styles.wrapper}>
          <Card className={styles.card}>
            <CardBody>
              <h4>{t("Reset Your Password")}</h4>
              <p>
                {t(
                  "We need your Extranet registered email address to reset your password."
                )}
              </p>
              <AvForm onValidSubmit={handleValidSubmit}>
                <AvField
                  name="email"
                  label={t("Email")}
                  placeholder={t("Enter email")}
                  type="email"
                  required
                  errorMessage={t("This field is required!")}
                />

                {errorMessage && (
                  <Alert color="danger" className="font-size-13 px-3 py-2">
                    {t(errorMessage)}
                  </Alert>
                )}
                <button
                  className="btn btn-primary waves-effect waves-light"
                  disabled={loading}
                >
                  {t("Send")}{" "}
                  {loading && (
                    <Spinner size="sm" className="ml-1 align-self-center" />
                  )}
                </button>
              </AvForm>
            </CardBody>
          </Card>
          <div className="mt-5 text-center">
            {locale !== "tr" && (
              <p>
                Go back to{" "}
                <Link to="login" className="font-weight-medium text-primary">
                  Login
                </Link>{" "}
              </p>
            )}
            {locale === "tr" && (
              <p>
                <Link to="login" className="font-weight-medium text-primary">
                  Girişe
                </Link>{" "}
                geri dön
              </p>
            )}
            <p>
              © {new Date().getFullYear()} Vihobook.{" "}
              {locale !== "tr" && (
                <>
                  Crafted with <i className="mdi mdi-heart text-danger" />
                </>
              )}
              {locale === "tr" && (
                <>
                  <i className="mdi mdi-heart text-danger" />
                  {"  "} ile hazırlanmış
                </>
              )}
            </p>
          </div>
        </main>
      </Container>
      {successModalVisible && (
        <SweetAlert
          title={t("Check your inbox")}
          success
          confirmBtnBsStyle="success"
          onConfirm={setSuccessModalVisible.bind(null, false)}
          confirmBtnText={t("OK")}
        >
          <h4>
            {t("We've sent the password reset link to your email address.")}
          </h4>
        </SweetAlert>
      )}
    </Layout>
  )
}

export default ForgetPasswordPage
