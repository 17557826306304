import React from "react"
import { Tooltip } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import { withStyles } from "@material-ui/core/styles"

const DarkTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: "6px 12px",
  },
}))(Tooltip)

function RenderDay(day, modifiers, t) {
  const dayNumber = day.getDate()

  let myClass = ""
  if (modifiers?.startDate) {
    myClass = "render-day__start"
  } else if (modifiers?.endDate) {
    myClass = "render-day__end"
  }

  let title = "Closed"
  if (modifiers.resStartDate && modifiers.resEndDate) {
    title = "Check-in/Check-out date"
  } else if (modifiers.resStartDate) {
    title = "Check-in date"
  } else if (modifiers.resEndDate) {
    title = "Check-out date"
  } else if (modifiers.reservedDate) {
    title = "Reserved"
  } else if (modifiers.avbStartDate) {
    title = "Available"
  }

  return (
    <DarkTooltip
      TransitionComponent={Zoom}
      arrow
      placement="top"
      title={t(title)}
    >
      <div className={"render-day__container "}>
        <div className={myClass + "-text"}>{dayNumber}</div>
      </div>
    </DarkTooltip>
  )
}

export default RenderDay
