import React from "react"
import { useTranslation } from "react-i18next"

import { useCancellationContext } from "./context"
import styles from "./styles.module.scss"

const PolicySummary = () => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { values } = useCancellationContext()
  const {
    freeCancellable,
    freeCancelDayCountBeforeArrival,
    cancelChargePercent,
    unusedChargePercent,
  } = values

  if (
    (freeCancellable && !freeCancelDayCountBeforeArrival) ||
    !cancelChargePercent ||
    !unusedChargePercent
  ) {
    return (
      <div className={styles.policySummary}>
        <p className={styles.validationalert}>
          {t("Please enter the conditions in the fields mentioned above.")}
        </p>
      </div>
    )
  }

  if ((!freeCancellable && !cancelChargePercent) || !unusedChargePercent) {
    return null
  }

  let summary = ""
  if (locale === "en") {
    if (freeCancellable) {
      summary = `The guest can cancel free of charge up to ${freeCancelDayCountBeforeArrival} days before arrival (check-in) date at the facility (hotel).
      Reservations canceled within ${freeCancelDayCountBeforeArrival} days before arrival will be charged ${cancelChargePercent}% of the total price.`
    } else {
      summary = `Reservations beyond the free cancellation date will be charged ${cancelChargePercent}% of the total price.`
    }

    if (!!unusedChargePercent) {
      summary += ` If the guest doesn't show up, despite making a reservation (no-show), ${unusedChargePercent}% of the total price will be charged.`
    }
  }
  if (locale === "tr") {
    if (freeCancellable) {
      summary = `Misafir, tesise (otel) varış (check-in) tarihinin ${freeCancelDayCountBeforeArrival} gün öncesine kadar ücretsiz olarak iptal edebilir. Varıştan önceki ${freeCancelDayCountBeforeArrival} gün içinde iptal edilen rezervasyonların toplam ücretinin %${cancelChargePercent}'i tahsil edilecektir.`
    } else {
      summary = `Ücretsiz iptal tarihi geçen rezervasyonların toplam ücretin %${cancelChargePercent}'u tahsil edilecektir.`
    }

    if (!!unusedChargePercent) {
      summary += `Konuğun (Misafir) rezervasyon yaptırdığı halde otele gelmemesi (No-Show) durumunda toplam rezervasyon ücretinin %${unusedChargePercent}'si kadar tahsil edilecektir.`
    }
  }

  return (
    <div className={styles.policySummary}>
      <h6>{t("Policy Summary")}</h6>
      <p>{summary}</p>
    </div>
  )
}

export default PolicySummary
