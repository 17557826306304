import { Modal, ModalBody, ModalFooter, Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { CgClose } from "react-icons/cg"
import styles from "./tac.module.scss"

const TermsAndConditions = ({ toggle }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  let Content = null
  switch (locale) {
    case "en":
      Content = Content_en
      break
    case "tr":
      Content = Content_tr
      break
    default:
      Content = Content_en
      break
  }
  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <header className={styles.header}>
        <h5>{t("Vihobook Business Partner Account Terms of Use")}</h5>
        <span onClick={toggle}>
          <CgClose />
        </span>
      </header>
      <ModalBody className={styles.modalBody}>
        <Content />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {t("Close")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TermsAndConditions

const Content_en = () => {
  return (
    <>
      <p>
        Vihobook may offer business partners access to online user accounts,
        including Connect (Extranet) and the Affiliate Partner Center (hereafter
        the “Vihobook Services”). Individuals with access to these user
        accounts, either due to an agreement directly with Vihobook or on behalf
        of a business partner of Vihobook (hereafter the “Master Agreement”),
        must adhere to the following Terms of Use.
      </p>
      <p>
        You agree not to use, and not to allow third parties to use, the
        Vihobook Services for other purposes than agreed to in the Master
        Agreement, and not:
      </p>
      <ul>
        <li>To send unsolicited commercial emails to guests</li>
        <li>To make misrepresentations, including hiding your identity</li>
        <li>To upload viruses, malware, or other malicious code</li>
        <li>
          To alter, disable, or circumvent any processes built into the Vihobook
          systems
        </li>
        <li>
          To damage, disable, impair, and/or overburden the Vihobook systems
        </li>
        <li>To reverse engineer any of the features offered by Vihobook</li>
        <li>
          To copy and further use the Vihobook trademarks unless explicitly
          agreed to by Vihobook
        </li>
        <li>To disclose, share or resell any login details</li>
        <li>
          To grant access to the Vihobook systems to third parties for
          commercial or any other reason without Vihobook’s explicit written
          agreement
        </li>
        <li>
          To use the Vihobook Services in violation of applicable law, including
          the legal rights of others – this includes uploading content that
          violates third-party intellectual property rights
        </li>
      </ul>
      <p>
        Any loss or misuse of login credentials must immediately be reported to
        <a href="mailto:customer@vihobook.com"> customer@vihobook.com</a>
      </p>
      <p>
        Failure to comply with these Terms of Use may lead to suspension of your
        access to the Vihobook Services. Vihobook retains the right to take
        further legal steps, including termination, in accordance with the
        respective Master Agreement.
      </p>
      <p>Vihobook</p>
    </>
  )
}

const Content_tr = () => {
  return (
    <>
      <p>
        Vihobook, iş ortakları için Connect (extranet) ve İştirak Ortağı Merkezi
        (bundan böyle "Vihobook Hizmetleri") dahil olmak üzere online kullanıcı
        hesaplarına erişme olanağı sağlayabilir. Doğrudan Vihobook'la olan veya
        Vihobook'un bir iş ortağı adına olan sözleşme sayesinde (bundan böyle
        "Ana Sözleşme") bu kullanıcı hesaplarına erişimi bulunan kişilerin
        aşağıdaki Kullanım Şartlarına uymaları gerekmektedir.
      </p>
      <p>
        Vihobook Hizmetlerini Ana Sözleşmede üzerinde anlaşmaya varılanlardan
        farklı amaçlar için kullanmamayı ve üçüncü tarafların kullanmasına izin
        vermemeyi kabul edersiniz. Ayrıca aşağıdakileri de yapmamayı kabul
        edersiniz:
      </p>
      <ul>
        <li>Konuklara istenmeyen ticari e-postalar göndermek</li>
        <li>
          Kimliğinizi saklamak dahil olmak üzere yalan beyanlarda bulunmak
        </li>
        <li>
          Virüsler, kötü amaçlı yazılımlar veya diğer zararlı kodlar yüklemek
        </li>
        <li>
          Vihobook sistemlerinde bulunan herhangi bir işlemi değiştirmek, devre
          dışı bırakmak veya gerçekleşmesini engellemek
        </li>
        <li>
          Vihobook sistemlerine zarar vermek, bu sistemleri devre dışı bırakmak,
          zayıflatmak ve/veya bu sistemlerin kapasitesini zorlamak
        </li>
        <li>
          Vihobook tarafından sunulan özelliklerden herhangi biri için ters
          mühendislik yapmak
        </li>
        <li>
          Vihobook'la açık bir şekilde anlaşmaya varılmadığı sürece Vihobook
          ticari markalarını kopyalamak ve farklı şekilde kullanmak
        </li>
        <li>
          Herhangi bir kullanıcı bilgisini ifşa etmek, paylaşmak veya yeniden
          satmak
        </li>
        <li>
          Vihobook'un açık ve yazılı anlaşması olmaksızın ticari veya başka bir
          sebeple üçüncü kişilerin Vihobook sistemlerine girmesine izin vermek
        </li>
        <li>
          Vihobook Hizmetlerini yürürlükteki yasaları ve başkalarının hukuki
          haklarını ihlal edecek şekilde kullanmak. Buna üçüncü taraf fikri
          mülkiyet haklarını ihlal eden içerik yüklemek de dahildir.
        </li>
      </ul>
      <p>
        Kullanıcı bilgilerinin herhangi bir şekilde kaybolması veya kötüye
        kullanılması durumunda derhal{" "}
        <a href="mailto:customer@vihobook.com">customer@vihobook.com</a> adresine
        bildirilmelidir.
      </p>
      <p>
        Bu Kullanım Şartlarına uymamak, Vihobook Hizmetlerine olan erişim
        hakkınızın durdurulmasına yol açabilir. Vihobook, ilgili Ana Sözleşme
        gereğince fesih dahil olmak üzere diğer yasal adımları atma hakkını
        saklı tutar.
      </p>
      <p>Vihobook</p>
    </>
  )
}
