import { Link } from "react-router-dom"
import { Card } from "reactstrap"

import styles from "./dashboard.module.scss"

const TopItem = ({ link, bgColor, imgSrc, label, content }) => {
  return (
    <Link to={link}>
      <Card className={styles.topCardItem} style={{ backgroundColor: bgColor }}>
        <img src={imgSrc} alt={label} />
        <div>
          <label>{label}</label>
          <span>{content}</span>
        </div>
      </Card>
    </Link>
  )
}

export default TopItem
