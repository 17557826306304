import React from "react"
import { Row } from "reactstrap"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

export default function RadioButtons(props) {
  const { label, availabilityStatus, setAvailabilityStatus } = props
  const { t } = useTranslation()
  return (
    <div className={styles.radioContainer}>
      <h5 className={styles.cardSubtitle}>{t(label)}</h5>
      <Row className={styles.radioButtons}>
        <RadioGroup
          row
          onChange={(e, v) => setAvailabilityStatus(v)}
          value={availabilityStatus}
        >
          <FormControlLabel
            className={styles.aRadioLabel}
            control={<Radio className={styles.radio} />}
            label={t("Open")}
            value={true}
          />
          <FormControlLabel
            className={styles.aRadioLabel}
            control={<Radio className={styles.radio} />}
            label={t("Closed")}
            value={false}
          />
        </RadioGroup>
      </Row>
    </div>
  )
}
