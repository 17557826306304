import axios from "axios"
import { useSelector } from "react-redux"

const useRequest = (setLoading, setErrorMessage) => {
  const token = useSelector(state => state.Login.token)

  const request = async (config, shouldLoad = true) => {
    try {
      setErrorMessage("")
      if (shouldLoad) setLoading(true)
      const response = await axios.request({
        ...config,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      if (shouldLoad) setLoading(false)
      if (response.data.result) {
        return response.data
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      if (shouldLoad) setLoading(false)
      setErrorMessage(error.message)
    }
  }
  return { request }
}

export default useRequest
