import React from "react"
import { Modal, Row, Col, Alert, Spinner, Label } from "reactstrap"
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation-safe"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import { addNewUser, updateUser } from "store/actions"
import PhoneInput from "components/Common/PhoneInput/index"
import SuccessAlert from "components/Common/SuccessAlert/index"

class CustomModal extends React.Component {
  state = {
    phoneNumber: "",
    showSuccessAlert: false,
    readOnly: true,
  }

  componentDidMount = () => {
    const { isAddOperation, user } = this.props
    if (!isAddOperation) {
      this.setState({ phoneNumber: user?.phoneNumber })
    }
  }
  onSave = (event, values) => {
    const { phoneNumber } = this.state
    const { user } = this.props
    values = { ...values, phoneNumber }

    if (this.props.isAddOperation) {
      this.props.addNewUser(values, this.callback)
    } else {
      values = { ...values, id: user?.id }
      this.props.updateUser(values, this.callback)
    }
  }

  callback = obj => {
    if (obj.result) {
      this.setState({ showSuccessAlert: true })
    }
  }

  hideSuccessAlert = () => {
    this.setState({ showSuccessAlert: false })
    this.props.refreshUsersTable()
    this.props.close()
  }

  render() {
    const { close, isAddOperation, user, error, errorMessage, loading, t } =
      this.props
    const { showSuccessAlert, readOnly } = this.state
    return (
      <React.Fragment>
        <Modal isOpen={true} scrollable={true} size="lg" toggle={close}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {t(isAddOperation ? "Add User" : "Edit User")}
            </h5>
            <button
              type="button"
              onClick={close}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              onValidSubmit={this.onSave}
              autoComplete="off"
              model={
                user
                  ? {
                      status: user?.status ? "1" : "0",
                      isSuperUser: user?.isSuperUser ? "1" : "0",
                    }
                  : { status: "1", isSuperUser: "1" }
              }
            >
              <Row form>
                <Col md={6}>
                  <AvGroup>
                    <Label for="title">{t("Name")}</Label>
                    <AvInput
                      name="name"
                      id="name"
                      value={isAddOperation ? "" : user?.name}
                      required
                      autoComplete="off"
                    />
                    <AvFeedback>{t("Please enter a name!")}</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <Label for="surname">{t("Surname")}</Label>
                    <AvInput
                      name="surname"
                      id="surname"
                      value={isAddOperation ? "" : user?.surname}
                      autoComplete="off"
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <AvGroup>
                    <Label for="email">{t("Email")}</Label>
                    <AvInput
                      name="email"
                      id="email"
                      required
                      type="email"
                      value={isAddOperation ? "" : user?.email}
                      autoComplete="new-password"
                      readOnly={readOnly}
                      onFocus={() => this.setState({ readOnly: false })}
                      onBlur={() => this.setState({ readOnly: true })}
                    />
                    <AvFeedback>{t("Please enter an email!")}</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <Label for="password">{t("Password")}</Label>
                    <AvInput
                      name="password"
                      id="password"
                      type="password"
                      minLength={6}
                      autoComplete="off"
                      required={isAddOperation ? true : false}
                    />
                    <AvFeedback>
                      {t("Please enter a password! min. 6 character")}
                    </AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <PhoneInput
                    phoneNumber={isAddOperation ? "" : user?.phoneNumber}
                    onChange={phoneNumber => {
                      this.setState({ phoneNumber })
                    }}
                    label={t("Phone Number")}
                  />
                </Col>
                <Col md={3}>
                  <AvRadioGroup name="status" required>
                    <Label for="status" className="d-block">
                      {t("Status")}
                    </Label>
                    <div className="AvRadioGroup__buttons">
                      <AvRadio label={t("Active")} value="1" />
                      <AvRadio label={t("Inactive")} value="0" />
                    </div>
                  </AvRadioGroup>
                </Col>
                <Col md={3}>
                  <AvRadioGroup name="isSuperUser" required>
                    <Label for="isSuperUser" className="d-block">
                      {t("User Type")}
                    </Label>
                    <div className="AvRadioGroup__buttons">
                      <AvRadio label={t("Superuser")} value="1" />
                      <AvRadio label={t("Regular user")} value="0" />
                    </div>
                  </AvRadioGroup>
                </Col>
              </Row>

              {error && <Alert color="danger">{errorMessage}</Alert>}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={close}
                >
                  {t("Close")}
                </button>

                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <>
                      <Spinner size="sm" /> {t("Loading...")}{" "}
                    </>
                  ) : (
                    t("Save")
                  )}
                </button>
              </div>
            </AvForm>
          </div>
        </Modal>
        {showSuccessAlert && (
          <SuccessAlert hideSuccessAlert={this.hideSuccessAlert} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, error, errorMessage } = state.User
  return {
    loading,
    error,
    errorMessage,
  }
}
export default connect(mapStateToProps, { addNewUser, updateUser })(
  withTranslation()(CustomModal)
)
