import React, { Fragment, Component } from "react"
import RUG, { DropArea, DragArea, Card } from "react-upload-gallery"
import "react-upload-gallery/dist/style.css"
import axios from "axios"
import { connect } from "react-redux"
import { withTranslation, Trans } from "react-i18next"
import { Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

class Uploader extends Component {
  state = {
    loading: false,
    errorMessage: "",
    photos: [],
    photosLoaded: false,
    showAlert: false,
  }

  componentDidMount = () => {
    this.getPhotos()
    this.props.onRef(this)
  }

  componentWillUnmount = () => {
    this.props.onRef(undefined)
  }

  getPhotos = async () => {
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/trans-vehicle-photos?vehicleId=" + this.props.vehicleId
      const response = await axios.get(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
        const updatedPhotos = response.data.photos.map(p => {
          return {
            ...p,
            selected: p.sortIndex === 0,
            source: axios.defaults.baseURL + "/" + p.photoRef,
          }
        })

        updatedPhotos.sort((a, b) => a.sortIndex - b.sortIndex)

        this.setState({ photos: updatedPhotos, photosLoaded: true })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  postPhoto = ({ uid, file, action, onProgress, onSuccess, onError }) => {
    this.setState({ loading: true })
    const form = new FormData()
    form.append("vehicleId", this.props.vehicleId)
    form.append("vehiclePhoto", file)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    axios
      .post(action, form, {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(uid, Math.round((loaded / total) * 100))
        },
        cancelToken: source.token,
      })
      .then(response => {
        this.setState({ loading: false })
        if (response.data.result) {
          let source =
            axios.defaults.baseURL + "/" + response.data.photo.photoRef
          let data = { ...response.data.photo, source, uid }

          let updatedPhotos = [...this.state.photos]
          updatedPhotos.push(data)
          this.setState({ photos: updatedPhotos })

          onSuccess(uid, data)
        } else {
          onError(uid, {
            action,
            status: error.request,
            response: error.response,
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          loading: false,
          errorMessage: "Something went wrong!!",
        })
      })

    return {
      abort() {
        source.cancel()
      },
    }
  }

  deletePhoto = async photoId => {
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        data: {
          photoId,
        },
      }
      const url = "/trans-vehicle-photos"
      const response = await axios.delete(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  DropAreaComponent = (isDrag, openDialogue) => {
    const { t } = this.props
    return (
      <div
        className="dropzone py-4 mb-2"
        onClick={openDialogue}
        style={{ background: isDrag ? "#556ee642" : "" }}
      >
        <div className="dz-message needsclick">
          <div className="mb-3">
            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
          </div>
          <h5>{t("Drop files here or click to upload.")}</h5>
          <h6>
            (
            {t(
              "Only 720 * 480 or greater png and jpeg images will be accepted"
            )}
            )
          </h6>
        </div>
      </div>
    )
  }

  onSortEnd = async images => {
    // if photos are uploading
    if (this.state.loading) {
      this.setState({ showAlert: true })
      return
    }

    // if images don't have imageId
    if (!images[0].id) {
      images = this.insertDB_id(images)
    }

    // get only image Id and its index
    images = images.map((image, index) => {
      return { imageId: image.id, index, primary: !!image.selected }
    })

    try {
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/trans-vehicle-photos/update-sort-index"
      const response = await axios.post(url, { data: images }, config)
      if (response.data.result) {
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectClick = async img => {
    let { photos } = this.state
    const selectedImageIndex = photos.findIndex(image => image.id === img.id)

    photos = photos.map((image, index) => {
      return { imageId: image.id, index, primary: index === selectedImageIndex }
    })

    try {
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/trans-vehicle-photos/update-sort-index"
      const response = await axios.post(url, { data: photos }, config)
      if (response.data.result) {
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  insertDB_id = images => {
    const { photos } = this.state
    images = images.map(image => {
      photos.forEach(photo => {
        if (photo.uid === image.uid) {
          image = { ...image, id: photo.id }
        }
      })
      return image
    })
    return images
  }

  isLoading = () => {
    return this.state.loading
  }

  showAlert = () => {
    this.setState({ showAlert: true })
  }

  render() {
    const { photos, photosLoaded, showAlert } = this.state
    const { t } = this.props

    return (
      <Fragment>
        {photosLoaded ? (
          <>
            <RUG
              header={({ openDialogue }) => (
                <>
                  <DropArea>
                    {isDrag => this.DropAreaComponent(isDrag, openDialogue)}
                  </DropArea>
                  <h5 className="my-1">
                    <Trans
                      i18nKey="To sort, <0>long hold</0> and drag. Click to set an primary image."
                      components={[<strong />]}
                    />
                  </h5>
                </>
              )}
              action="/trans-vehicle-photos"
              accept={["jpg", "jpeg", "png"]}
              initialState={photos}
              sorting={true}
              inOrder={true}
              onSortEnd={this.onSortEnd}
              customRequest={this.postPhoto}
              onDeleted={image => {
                if (!image.hasOwnProperty("id")) {
                  image = this.state.photos.find(p => {
                    return p.uid == image.uid
                  })
                }
                this.deletePhoto(image.id)
              }}
            >
              <DragArea className="rug-items __card">
                {image => (
                  <div>
                    <Card image={image} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className={`btn ${
                          image.selected
                            ? "btn-outline-success"
                            : "btn-outline-secondary"
                        } btn-sm`}
                        onClick={() => {
                          if(image.selected) return
                          image.select()
                          this.onSelectClick(image)
                        }}
                      >
                        {image.selected ? t("Primary Image") : t("Set as Primary")}
                      </button>
                    </div>
                  </div>
                )}
              </DragArea>
            </RUG>
          </>
        ) : (
          <Spinner size="lg" color="primary" className="spinner" />
        )}
        {showAlert && (
          <SweetAlert
            title={t("Warning!")}
            warning
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.setState({ showAlert: false })
            }}
            confirmBtnText={t("OK")}
          >
            <h4>{t("Please wait till all the photos are uploaded.")}</h4>
          </SweetAlert>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return { token }
}

export default withTranslation()(connect(mapStateToProps, null)(Uploader))
