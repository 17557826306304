import {
  GET_COMPANY,
  API_ERROR,
  GET_COMPANY_START,
  GET_COMPANY_SUCCEED,
  UPDATE_COMPANY,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCEED,
} from "./actionTypes"

export const getCompany = token => {
  return {
    type: GET_COMPANY,
    token,
  }
}

export const getCompanyStart = () => {
  return {
    type: GET_COMPANY_START,
  }
}

export const getCompanySucceed = company => {
  return {
    type: GET_COMPANY_SUCCEED,
    company,
  }
}

export const apiError = errorMessage => {
  return {
    type: API_ERROR,
    errorMessage: errorMessage,
  }
}

export const updateCompany = (company, callback) => {
  return {
    type: UPDATE_COMPANY,
    company,
    callback,
  }
}

export const updateCompanyStart = () => {
  return {
    type: UPDATE_COMPANY_START,
  }
}

export const updateCompanySucceed = company => {
  return {
    type: UPDATE_COMPANY_SUCCEED,
    company,
  }
}
