import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Controller } from "react-hook-form"

import { useTicketContext } from "./context"
import { selectError } from "utils/other"
import styles from "./styles.module.scss"
import MapModal from "./CustomMap"

export default ({ useFormResult, isAddOperation, ticket }) => {
  const { register, errors, setValue, control, watch } = useFormResult
  const { values, setters, functions } = useTicketContext()
  const { fetchProvinces, fetchDistricts } = functions
  const { countries, provinces, districts } = values
  const { setLoading } = setters
  const { t } = useTranslation()
  const [mapModalVisible, setMapModelVisible] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!isAddOperation) {
        const {
          country_id,
          province_id,
          district_id,
          address,
          latitude,
          longitude,
        } = ticket
        setLoading(true)
        await Promise.allSettled([
          fetchProvinces(country_id),
          fetchDistricts(province_id),
        ])
        setLoading(false)

        if (latitude && longitude) {
          setValue("latitude", latitude)
          setValue("longitude", longitude)
        }
        setValue("country_id", country_id)
        setValue("province_id", province_id)
        setValue("district_id", district_id)
        setValue("address", address)
      }
    })()
  }, [])

  const setCoordinateHandler = ({ lat, lng }) => {
    setValue("latitude", lat)
    setValue("longitude", lng)
  }

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Address")}</CardTitle>
        <hr />
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.country_id && styles.error}`}>
            <Label>* {t("Country")}</Label>
            <Controller
              control={control}
              name="country_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={countries}
                    value={countries?.find(i => i?.value === value)}
                    onChange={obj => {
                      const country_id = obj.value
                      onChange(country_id)
                      setValue("province_id", "")
                      setValue("district_id", "")
                      fetchProvinces(country_id)
                    }}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.country_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.country_id && <p>{t(errors?.country_id?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.province_id && styles.error}`}>
            <Label>* {t("Province")}</Label>
            <Controller
              control={control}
              name="province_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={provinces}
                    value={provinces?.find(i => i?.value === value) || ""}
                    onChange={obj => {
                      const province_id = obj?.value
                      onChange(province_id)
                      fetchDistricts(province_id)
                    }}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.province_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.province_id && <p>{t(errors?.province_id?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.district_id && styles.error}`}>
            <Label>* {t("District")}</Label>
            <Controller
              control={control}
              name="district_id"
              defaultValue={""}
              render={({ onChange, value, ref }) => {
                return (
                  <Select
                    inputRef={ref}
                    options={districts}
                    value={districts?.find(i => i?.value === value) || ""}
                    onChange={obj => onChange(obj?.value)}
                    noOptionsMessage={() => t("No Options")}
                    placeholder={t("Select...")}
                    styles={errors?.district_id ? selectError : ""}
                  />
                )
              }}
            />
            {errors?.district_id && <p>{t(errors?.district_id?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.address && styles.error}`}>
            <Label>* {t("Address")}</Label>
            <Input
              name="address"
              innerRef={register}
              invalid={!!errors?.address}
              type="text"
            />
            {errors?.address && <p>{t(errors?.address?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6} className={` ${errors?.latitude && styles.error}`}>
            <Label>* {t("Latitude")}</Label>
            <Input
              name="latitude"
              innerRef={register}
              invalid={!!errors?.latitude}
              type="number"
              readOnly
              className="disabled-bg"
            />
            {errors?.latitude && <p>{t(errors?.latitude?.message)}</p>}
          </Col>
          <Col md={6} className={` ${errors?.longitude && styles.error}`}>
            <Label>* {t("Longitude")}</Label>
            <Input
              name="longitude"
              innerRef={register}
              invalid={!!errors?.longitude}
              type="number"
              readOnly
              className="disabled-bg"
            />
            {errors?.longitude && <p>{t(errors?.longitude?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col className="d-flex justify-content-end">
            <Button type="button" onClick={setMapModelVisible.bind(null, true)}>
              {t(watch("latitude") ? "Update Coordinate" : "Add Coordinate")}
            </Button>
          </Col>
        </Row>
        {mapModalVisible && (
          <MapModal
            lat={watch("latitude") || null}
            lng={watch("longitude") || null}
            isModalOpen={mapModalVisible}
            setIsModalOpen={setMapModelVisible}
            setCoordinate={setCoordinateHandler}
          />
        )}
      </CardBody>
    </Card>
  )
}
