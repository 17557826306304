import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Container, Card, CardBody, Alert } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SearchInputs from "./SearchInputs"
import CustomTable from "./Table/index"
import styles from "./styles.module.scss"

// custom hooks
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import { useOverallViewContext, HotelOverallViewProvider } from "./context"

const OverallView = () => {
  const { t } = useTranslation()
  const { functions, setters, values } = useOverallViewContext()
  const {
    fetchHotelData,
    fetchRoomNames,
    fetchBoardTypes,
    fetchCompanyMarketingPlaces,
    searchHandler,
  } = functions
  const { setLoading } = setters
  const { loading, error, tablesData, currencies } = values
  const { HotelNotRegisteredComp, setShowHotelNotRegWarning } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setLoading(false)
        return setShowHotelNotRegWarning(true)
      }

      const result = await Promise.allSettled([
        fetchRoomNames(hotel_id),
        fetchBoardTypes(hotel_id),
        fetchCompanyMarketingPlaces(),
      ])
      await searchHandler(
        result[0].value,
        result[1].value,
        result[2].value,
        currencies,
        hotel_id
      )
      setLoading(false)
    })()
  }, [])

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Marketing")}
          breadcrumbItem={t("Overall View")}
        />

        {loading ? (
          <CustomSpinner color="primary" />
        ) : (
          <>
            {/*SEARCH INPUTS*/}
            <SearchInputs />
            {tablesData?.length > 0 && (
              <Card>
                <CardBody className={styles.cardBody}>
                  {/* TABLE*/}
                  {tablesData?.map(tData => (
                    <CustomTable
                      key={tData?.summaryData?.room_id}
                      data={tData}
                    />
                  ))}
                </CardBody>
              </Card>
            )}
          </>
        )}
        {error && (
          <Alert color="danger" className={styles.alert}>
            {error}
          </Alert>
        )}
      </Container>
      {HotelNotRegisteredComp}
    </main>
  )
}

const WrappedComponent = () => (
  <HotelOverallViewProvider>
    <OverallView />
  </HotelOverallViewProvider>
)

export default WrappedComponent
