import { Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"

import { usePrepaymentContext } from "./context"
import styles from "./styles.module.scss"

const ConditionInputs = () => {
  const { t } = useTranslation()
  const { values, setters } = usePrepaymentContext()
  const {
    prepaymentPercent,
    paymentMethods,
    selectedPaymentMethod,
    returnDayCount,
    hasSignedContract,
  } = values
  const { setPrepaymentPercent, setSelectedPaymentMethod, setReturnDayCount } =
    setters
  return (
    <div className={styles.conditionsContainer}>
      {/* FIRST Q */}{" "}
      <div className={`${styles.thirdQuestion} ${hasSignedContract && styles.disabled}`}>
        <Label>
          {t(
            "What percentage of the total fee would you like to receive as a payment?"
          )}
        </Label>
        <Input
          type="number"
          disabled={hasSignedContract}
          value={prepaymentPercent}
          onChange={e => {
            const inputValue = e.target.value;
            if (inputValue >= 0 && inputValue <= 100) {
              setPrepaymentPercent(inputValue);
            }            
          }}
          max={100}
          min={0}
        />
        %
      </div>
      <div className={styles.lastRow}>
        <div className={styles.col1}>
          <Label>{t("How will you receive the payment?")}</Label>
          <Select
            placeholder=""
            isDisabled={hasSignedContract}
            value={selectedPaymentMethod}
            noOptionsMessage={() => t("No Options")}
            options={paymentMethods}
            onChange={setSelectedPaymentMethod}
          />
        </div>
      </div>
      <div className={styles.lastRow}>
        <div className={styles.col2}>
          <Label>
            {t(
              "In case of cancellation of paid reservations, how long will it take for the payment to be refunded?"
            )}
          </Label>
          <div className={`${styles.row} ${hasSignedContract && styles.disabled}`}>
            {" "}
            <Input
              type="number"
              value={returnDayCount}
              disabled={hasSignedContract}
              onChange={e => {
                const newValue = e.target.value?.replace(/[^0-9]/g, "")
                if (newValue === "" || (newValue >= 0 && newValue <= 10)) {
                  setReturnDayCount(newValue)
                }
              }}
              min={0}
              max={10}
              step={1}
            />
            {t("days_")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConditionInputs
