import { BsCalendar2CheckFill, BsCardChecklist } from "react-icons/bs"
import { FaCarSide, FaDollarSign } from "react-icons/fa"
import { IoIosListBox } from "react-icons/io"

const transferContent = [
  {
    id: 3001,
    label: "Transfer",
    icon: FaCarSide,
    link: "/#",
    items: [
      { label: "Vehicle Registration", link: "/vehicle-registration" },
      { label: "Vehicle Photos", link: "/vehicle-photos" },
      {
        label: "Vehicle Languages",
        link: "/transfer-languages",
      },
    ],
  },
  {
    id: 3004,
    label: "Policies",
    icon: IoIosListBox,
    link: "/#",
    items: [
      {
        label: "Cancellation Policy",
        link: "/transfer-cancellation-policy",
      },
      {
        label: "Payment Policy",
        link: "/transfer-prepayment-policy",
      },
      {
        label: "Country Policy",
        link: "/country-policy",
      },
      {
        label: "Tax Policy",
        link: "transfer-kdv-tax-fees",
      },
    ],
  },
  {
    id: 3002,
    label: "Marketing",
    icon: FaDollarSign,
    link: "/#",
    items: [
      { label: "Pricing", link: "/transfer-pricing" },
      { label: "Extra Services Pricing", link: "/extra-services-pricing" },
      { label: "Promotions", link: "/transfer-promotions" },
      { label: "Your Promotions", link: "/transfer-your-promotions" },
      { label: "Pricing Report", link: "/transfer-pricing-report" },
    ],
  },
  {
    id: 3003,
    label: "Reservations",
    icon: BsCalendar2CheckFill,
    link: "/#",
    items: [{ label: "Reservations", link: "/transfer-reservations" }],
  },
]

export default transferContent
