import React, { useState, useEffect } from "react"
import { Col, Row, Container, Card, Alert } from "reactstrap"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import TopItem from "./TopItem"
import BottomItem from "./BottomItem"
import useRequest from "hooks/useRequest"
import { useSocket } from "contexts/socket-context"
import { useMasterSocket } from "contexts/master-socket-context"
// images/icons
import img1 from "assets/images/hp-icons/1.png"
import img2 from "assets/images/hp-icons/2.png"
import img3 from "assets/images/hp-icons/3.png"
import img4 from "assets/images/hp-icons/4.png"
import img5 from "assets/images/hp-icons/5.png"

import styles from "./dashboard.module.scss"
import { getTranslatedOption } from "utils/other"

const HotelHomePage = () => {
  const [, setErrorMessage] = useState("")
  const [, setLoading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [cStatuses, setCStatuses] = useState({})
  const [reservationCount, setReservationCount] = useState(0)
  const userName = useSelector(
    state => state.User.currentUser.name + " " + state.User.currentUser.surname
  )
  const { request } = useRequest(setLoading, setErrorMessage)
  const { unreadMessageCount: resUnreadMessageCount } = useSocket()
  const { unreadMessageCount: masterUnreadMessageCount } = useMasterSocket()
  const totalUnreadMessageCount =
    (resUnreadMessageCount || 0) + (masterUnreadMessageCount || 0)
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    const fetchPendingReservationCount = async () => {
      const response = await request(
        {
          url: "/hotel-room-reservations/get-count-by-company-id",
        },
        false
      )
      if (response?.dbResult) {
        setReservationCount(response?.dbResult?.pendingReservationsCount)
      }
    }

    const fetchCompulsoryStatuses = async () => {
      const response = await request(
        {
          url: "/hotel-registration/compulsory-tables-statuses",
        },
        false
      )
      if (response?.dbResult) {
        const statuses = response.dbResult
        setCStatuses(statuses)
        for (const [_, value] of Object.entries(statuses)) {
          if (value === 0) {
            setShowWarning(true)
            break
          }
        }
      }
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchPendingReservationCount(),
        fetchCompulsoryStatuses(),
      ])
      setLoading(false)
    })()
  }, [])

  return (
    <Container className={`${styles.container} ${showWarning ? "mt-4" : ""}`}>
      <Alert
        color="danger"
        className={styles.warning}
        isOpen={showWarning}
        toggle={setShowWarning.bind(null, false)}
      >
        <p>{t("Warning!")}</p>
        <p>
          {t(
            "The hotel is not currently active. Please fill out the following pages carefully."
          )}
        </p>
        <ul>
          {!cStatuses.hasCompanyAddress && (
            <li>
              <Link to="/setup-company-settings" className="alert-link">
                {t("Company Settings")}
              </Link>
            </li>
          )}
          {!cStatuses.isHotelRegistered && (
            <li>
              <Link to="/hotel-information" className="alert-link">
                {t("Hotel Information")}
              </Link>
            </li>
          )}
          {!cStatuses.hasBoardType && (
            <li>
              <Link to="/hotel-board-type" className="alert-link">
                {t("Hotel Board Type")}
              </Link>
            </li>
          )}
          {!cStatuses.hasFeature && (
            <li>
              <Link to="/hotel-features" className="alert-link">
                {t("Hotel Features")}
              </Link>
            </li>
          )}
          {!cStatuses.hasPhoto && (
            <li>
              <Link to="/hotel-photos" className="alert-link">
                {t("Hotel Photos")}
              </Link>
            </li>
          )}
          {cStatuses?.photoCountPerRoom?.length > 0 && (
            <>
              <li>
                <Link to="/hotel-room-photos" className="alert-link">
                  {t("Room Photos")}
                </Link>
                <ul className="mt-1 mb-2">
                  {cStatuses.photoCountPerRoom.map((item, index) => (
                    <li key={index}>
                      <Link to="/hotel-room-photos" className="alert-link">
                        {getTranslatedOption(item, "name", locale)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </>
          )}
          {!cStatuses.hasChildPolicy && (
            <li>
              <Link to="/hotel-child-policy" className="alert-link">
                {t("Child Policy")}
              </Link>
            </li>
          )}
          {!cStatuses.hasCancellationPolicy && (
            <li>
              <Link to="/hotel-cancellation-policy" className="alert-link">
                {t("Cancellation Policy")}
              </Link>
            </li>
          )}
          {!cStatuses.hasPrepaymentPolicy && (
            <li>
              <Link to="/hotel-prepayment-policy" className="alert-link">
                {t("Payment Policy")}
              </Link>
            </li>
          )}
          {!cStatuses.hasGuestPolicy && (
            <li>
              <Link to="/hotel-guest-info-policy" className="alert-link">
                {t("Guest Info Policy")}
              </Link>
            </li>
          )}

          {!cStatuses.hasTaxPolicy && (
            <li>
              <Link to="/hotel-kdv-tax-fees" className="alert-link">
                {t("Tax Policy")}
              </Link>
            </li>
          )}
        </ul>
      </Alert>

      {/* TOP ITEMS */}
      <Row>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/setup-user"}
            bgColor={"#40189d"}
            imgSrc={img1}
            label={t("View Profile")}
            content={userName}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/hotel-room-reservations"}
            bgColor={"#72ace5"}
            imgSrc={img1}
            label={t("Pending Reservations")}
            content={reservationCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/reservation-messages"}
            bgColor={"#9ac46d"}
            imgSrc={img1}
            label={t("Messages")}
            content={totalUnreadMessageCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/#"}
            bgColor={"#3b8965"}
            imgSrc={img1}
            label={t("Comments")}
            content={0}
          />
        </Col>
      </Row>
      <Card className={styles.middleCard}>{t("Operations")}</Card>
      {/* BOTTOM ITEMS */}
      <Row>
        {bottomItems.map(item => (
          <Col xs={6} xl={3} key={item.label}>
            <BottomItem
              link={item.link}
              imgSrc={item.imgSrc}
              label={t(item.label)}
            />
          </Col>
        ))}
      </Row>
      <Row className={styles.textAnimateWrapper}>
        <h6 className={styles.textAnimate}>
         Vihobook tesisler ile hesaplarını Türkiye Pazarı için Türk lirası ile, diğer ülke pazarlarında EURO para birimi ile çalışır.
        </h6>
      </Row>
    </Container>
  )
}

export default HotelHomePage

const bottomItems = [
  { label: "Overall View", imgSrc: img1, link: "/hotel-room-overall-view" },
  {
    label: "Influencer Marketing",
    imgSrc: img2,
    link: "/hotel-influencer-marketing",
  },
  {
    label: "Search Engine Marketing",
    imgSrc: img3,
    link: "/hotel-search-engine-marketing",
  },
  { label: "Promotions", imgSrc: img4, link: "/hotel-promotions" },
  { label: "Availability", imgSrc: img2, link: "/hotel-room-availability" },
  {
    label: "Hotel Information",
    imgSrc: img3,
    link: "/hotel-information",
  },
  { label: "Pricing", imgSrc: img4, link: "/hotel-room-pricing" },
  { label: "Settings", imgSrc: img5, link: "/setup-company-settings" },
]
