import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import Add_EditModal from "./Add_EditModal/index"
import CustomTable from "./CustomTable"
import SearchInputs from "./SearchInputs"
import { all, getTranslatedOption } from "utils/other"

const CancelToken = axios.CancelToken
let cancel

class ChildPricingPolicy extends Component {
  state = {
    isModalOpen: false,
    hotel_id: "",
    policies: [],
    roomNames: [],
    boardTypes: [],
    isChildAllowed: true,
    selectedRowId: "",
    selectedRowData: {},
    showWarningMessage: false,
    showWarnDeleteMsg: false,
    showSuccessDeleteMsg: false,
    isAddOperation: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    errorMessage: "",
    error: false,
    showHotelNotRegAlert: false,
    showChildNotAllowedWarn: false,
    locale: "en",
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const hotel_id = await this.fetchHotelData()
    const isChildAllowed = await this.fetchHotelChild(hotel_id)
    if (!hotel_id) {
      this.setState({ loading: false, showHotelNotRegAlert: true })
      return
    } else if (!isChildAllowed) {
      // create this warning
      this.setState({
        loading: false,
        showChildNotAllowedWarn: true,
      })
      return
    }
    Promise.allSettled([
      this.fetchHotelRoomNames(hotel_id),
      this.fetchHotelBoardTypes(hotel_id),
    ])
    this.setState({ loading: false, locale: this.props.i18n.language })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.i18n.language !== prevState.locale) {
      const newLocale = this.props.i18n.language
      const newRoomNames = this.state.roomNames.map(item => ({
        ...item,
        name: getTranslatedOption(item, "name", newLocale),
      }))
      const newBoardTypes = this.state.boardTypes.map(item => ({
        ...item,
        name: getTranslatedOption(item, "name", newLocale),
      }))
      this.setState({
        roomNames: newRoomNames,
        boardTypes: newBoardTypes,
        locale: newLocale,
      })
    }
  }

  fetchHotelData = async () => {
    try {
      const url = `/hotel-registration`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {},
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        const hotel_id = response.data.dbResult.id
        this.setState({ hotel_id })
        return hotel_id
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchHotelChild = async hotel_id => {
    try {
      const url = `/hotel-child`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: { hotel_id },
      }
      const response = await axios.get(url, config)

      if (response?.data?.result) {
        const isChildAllowed = response?.data?.dbResult?.isChildAllowed || false
        this.setState({ isChildAllowed })
        return isChildAllowed
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchHotelRoomNames = async hotel_id => {
    try {
      const url = `/hotel-room-names`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          hotel_id,
        },
      }
      const response = await axios.get(url, config)
      if (response.data.result) {
        const locale = this.props.i18n.language
        const data = response.data.dbResult.map(i => ({
          ...i,
          isChecked: false,
          name: getTranslatedOption(i, "name", locale),
        }))

        const roomNames = data.filter(room => room.maxChild > 0)
        this.setState({ roomNames })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchHotelBoardTypes = async hotel_id => {
    try {
      const url = `/hotel-board-types/fetch-names-by-hotel-id`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          hotel_id,
        },
      }
      const response = await axios.get(url, config)
      if (response.data.result) {
        const locale = this.props.i18n.language
        const boardTypes = response.data.dbResult.map(i => ({
          ...i,
          isChecked: false,
          name: getTranslatedOption(i, "name", locale),
        }))
        this.setState({ boardTypes })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchPolicies = async () => {
    try {
      const {
        activePage,
        itemsCountPerPage,
        searchText,
        hotel_id,
        roomNames,
        boardTypes,
      } = this.state
      const selectedRoomName = roomNames.find(i => i.isChecked)
      const selectedBoardType = boardTypes.find(i => i.isChecked)
      const roomName_id = selectedRoomName?.id || null
      const boardType_id = selectedBoardType?.id || null

      const url = `/hotel-child-policies/fetch-by-page-number`

      if (cancel !== undefined) {
        cancel()
      }

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          page: activePage,
          limit: itemsCountPerPage,
          searchText,
          hotel_id: hotel_id,
          roomName_id,
          boardType_id,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }
      this.setState({ loading: true })
      const response = await axios.get(url, config)
      this.setState({ loading: false })

      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: {},
          policies: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  deletePolicy = async () => {
    this.setState({ showWarnDeleteMsg: false })
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        data: {
          childPolicy_id: this.state.selectedRowId,
          hotel_id: this.state.hotel_id,
        },
      }
      const url = "/hotel-child-policies"
      const response = await axios.delete(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
        this.setState({ showSuccessDeleteMsg: true })
      } else {
        this.setState({
          error: true,
          errorMessage: response.data.message,
        })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedRowId => {
    let selectedRowData = this.state.policies.find(item => {
      return item.id == selectedRowId
    })
    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchPolicies)
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      loading,
      error,
      errorMessage,
      selectedRowId,
      isModalOpen,
      isAddOperation,
      selectedRowData,
      showWarnDeleteMsg,
      showSuccessDeleteMsg,
      showWarningMessage,
      hotel_id,
      showHotelNotRegAlert,
      policies,
      roomNames,
      boardTypes,
      showChildNotAllowedWarn,
    } = this.state
    const { t } = this.props

    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <CustomTable
          policies={policies}
          hotelRoomNames={roomNames}
          hotelBoardTypes={boardTypes}
          onSelectTableRow={this.onSelectTableRow}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Marketing")}
              breadcrumbItem={t("Child Pricing Policy")}
            />
            <p className="font-size-14" style={{ marginTop: -16 }}>
              {t(
                "If you accept children in your facility, you need to define the price. If the children are free of charge, define the price as zero (0)."
              )}
            </p>
            <ControlButtons
              displayAddBtn={true}
              displayEditBtn={true}
              displayDeleteBtn={true}
              onAddBtn={() => {
                this.setState({ isModalOpen: true, isAddOperation: true })
              }}
              onEditBtn={() => {
                if (selectedRowId) {
                  this.setState({ isModalOpen: true, isAddOperation: false })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onDeleteBtn={() => {
                if (selectedRowId) {
                  this.setState({ showWarnDeleteMsg: true })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState({ searchText, activePage: 1 }, this.fetchPolicies)
              }}
            />
            <SearchInputs
              roomNames={roomNames}
              boardTypes={boardTypes}
              setRoomNames={roomNames => this.setState({ roomNames })}
              setBoardTypes={boardTypes => this.setState({ boardTypes })}
              onSearch={this.fetchPolicies}
            />
            {Content}
          </Container>
        </div>
        {isModalOpen && (
          <Add_EditModal
            modalTitle={
              isAddOperation ? "Add Child Policy" : "Edit Child Policy"
            }
            isOpen={true}
            close={() => {
              this.setState({ isModalOpen: false, isAddOperation: false })
            }}
            policy={isAddOperation ? {} : selectedRowData}
            hotel_id={hotel_id}
            isAddOperation={isAddOperation}
            hotelRoomNames={roomNames}
            hotelBoardTypes={boardTypes}
          />
        )}
        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}
        <SweetAlert
          show={showWarnDeleteMsg}
          warning
          showCancel
          confirmBtnText={t("Yes, delete it!")}
          cancelBtnText={t("Cancel")}
          confirmBtnBsStyle="danger"
          title={t("Are you sure?")}
          onConfirm={this.deletePolicy}
          onCancel={() => {
            this.setState({ showWarnDeleteMsg: false })
          }}
          focusCancelBtn
        >
          {t("You will not be able to recover it!")}
        </SweetAlert>

        <SweetAlert
          show={showSuccessDeleteMsg}
          success
          confirmBtnText={t("OK")}
          confirmBtnBsStyle="primary"
          title={t("Deleted!")}
          onConfirm={() => {
            this.setState({ showSuccessDeleteMsg: false })
          }}
        >
          {t("The row is deleted successfully!")}
        </SweetAlert>

        <SweetAlert
          show={showHotelNotRegAlert}
          warning
          confirmBtnText={t("OK")}
          confirmBtnBsStyle="primary"
          title={t("Warning!")}
          onConfirm={() => {
            this.setState({ showHotelNotRegAlert: false }, () => {
              this.props.history.push("/hotel-information")
            })
          }}
        >
          {t("Please Register Your Hotel Information First!")}
        </SweetAlert>

        <SweetAlert
          show={showChildNotAllowedWarn}
          warning
          confirmBtnText={t("OK")}
          confirmBtnBsStyle="primary"
          title={t("Warning!")}
          onConfirm={() => {
            this.setState({ showChildNotAllowedWarn: false }, () => {
              this.props.history.push("/hotel-child-policy")
            })
          }}
        >
          {t("Please create Child Age Policy first!")}
        </SweetAlert>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(
  connect(mapStateToProps, {})(ChildPricingPolicy)
)
