import {
  REGISTER_USER,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, callback) => {
  return {
    type: REGISTER_USER,
    user: user,
    callback,
  }
}

export const registerUserStart = () => {
  return {
    type: REGISTER_USER_START,
  }
}

export const registerUserSuccessful = () => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
  }
}

export const registerUserFailed = errorMessage => {
  return {
    type: REGISTER_USER_FAILED,
    errorMessage,
  }
}
