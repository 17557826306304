import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Col, Label } from "reactstrap"
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation-safe"

import CustomSwitch from "components/Common/CustomSwitch/index"
import { getLabelByLanguage } from "utils/other"
import styles from "./styles.module.scss"

export default forwardRef((props, ref) => {
  const [selectedRoomType, setSelectedRoomType] = useState("")
  const [roomStatus, setRoomStatus] = useState(true)
  const [roomNames, setRoomNames] = useState([])
  const [selectedRoomName, setSelectedRoomName] = useState("")
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const Feedback = <AvFeedback>{t("Please fill in the blank!")}</AvFeedback>

  const roomTypes = props.roomTypes.map(i => ({
    ...i,
    label: getLabelByLanguage(i, locale),
    value: i.id,
  }))

  // PROPAGATE DATA IN EDIT MODE
  useEffect(() => {
    // IF EDIT MODE
    if (!props.isAddOperation) {
      // SET STATUS
      setRoomStatus(props.room.status)
      // SET SELECTED ROOM TYPE
      const selectedRoomType = roomTypes.find(
        type => type.id === props.room.setRoomType_id
      )
      setSelectedRoomType(selectedRoomType)
      // SET SELECTED ROOM NAME
      const selectedRoomName = props.roomNames.find(
        i => +i.id === +props.room.setRoomName_id
      )
      selectedRoomName.label = getLabelByLanguage(selectedRoomName, locale)
      selectedRoomName.value = selectedRoomName.id
      setSelectedRoomName(selectedRoomName)
    }
  }, [roomTypes.length])

  const selectRoomTypeHandler = selectedRoomType => {
    setSelectedRoomName("")
    setSelectedRoomType(selectedRoomType)

    // Prepare Room Names
    let roomNames = props.roomNames.filter(
      i => +i.setRoomType_id === +selectedRoomType.value
    )

    // only roomNames that are not selected already
    roomNames = roomNames.reduce((prev, roomName) => {
      let isRoomNameAlreadySelected = false
      for (const room of props.rooms) {
        if (+room.setRoomName_id === +roomName.id) {
          isRoomNameAlreadySelected = true
          break
        }
      }

      if (!isRoomNameAlreadySelected) {
        prev.push({
          ...roomName,
          label: getLabelByLanguage(roomName, locale),
          value: roomName.id,
        })
      }

      return prev
    }, [])

    // IF EDIT MODE THEN ADD ROOM NAME BACK
    if (
      !props.isAddOperation &&
      +selectedRoomType.value === +props.room.setRoomType_id
    ) {
      const setRoomName_id = props.room.setRoomName_id
      const roomName = props.roomNames.find(i => +i.id === setRoomName_id)
      roomName.label = getLabelByLanguage(roomName, locale)
      roomName.value = roomName.id
      roomNames.push(roomName)
    }

    setRoomNames(roomNames)
  }

  const getState = () => ({ selectedRoomType, roomStatus, selectedRoomName })
  useImperativeHandle(ref, () => ({ getState }))

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Room Info")}</CardTitle>
        <hr />
        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label>* {t("Room type")}</Label>
              <Select
                value={selectedRoomType}
                onChange={selectRoomTypeHandler}
                options={roomTypes}
                noOptionsMessage={() => t("No Options")}
                placeholder={t("Select...")}
              />
            </AvGroup>
          </Col>
          <Col md={6} className={styles.roomStatusContainer}>
            <CustomSwitch
              isRequired={true}
              label="Room status"
              onClick={() => setRoomStatus(prevState => !prevState)}
              className={styles.roomStatus}
              value={roomStatus}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <AvGroup className={styles.altNoteContainer}>
              <Label>* {t("Room name")}</Label>
              <Select
                value={selectedRoomName}
                onChange={setSelectedRoomName}
                options={roomNames}
                noOptionsMessage={() => t("No Options")}
                placeholder={t("Select...")}
              />
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup className={styles.altNoteContainer}>
              <Label>{t("Special name (optional)")}</Label>
              <AvInput name="specialName" />
              <span className={styles.inputAltNote}>
                {t("You can optionally create a custom name for your own use")}
              </span>
            </AvGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label>* {t("(Same type) Room count")}</Label>
              <AvInput
                name="roomCount"
                type="number"
                step={1}
                min={1}
                required
              />
              {Feedback}
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label>
                {t("Room area")} (m<sup>2</sup>)
              </Label>
              <AvInput
                name="roomArea"
                type="number"
                min={1}
                placeholder={t("Meter square")}
              />
            </AvGroup>
          </Col>
        </Row>{" "}
      </CardBody>
    </Card>
  )
})
