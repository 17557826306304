import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Spinner, Button, Alert, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Prices from "./components/Prices"
import Dates from "./components/Dates"
import Members from "./components/Members"
import Checkboxes from "./components/Checkboxes"
import Locations from "./components/Locations"

import SuccessAlert from "components/Common/SuccessAlert"
import { getLabelByLanguage, all, getTranslatedOption } from "utils/other"
import { addDays, getTodayDate } from "utils/dateTimeFunctions"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner"

const TransferPricing = () => {
  const [onlyMembers, setOnlyMembers] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [selectedStartLocation, setSelectedStartLocation] = useState("")
  const [selectedEndLocation, setSelectedEndLocation] = useState("")
  const [estimatedTime, setEstimatedTime] = useState("")
  const [startDate, setStartDate] = useState(getTodayDate())
  const [endDate, setEndDate] = useState(addDays(getTodayDate(), 7))
  const [price, setPrice] = useState("")
  const [returnPrice, setReturnPrice] = useState("")

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")

  const { request } = useRequest(setLoading, setError)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      if (response?.result && Array.isArray(response?.dbResult)) {
        const marketingPlaces = response?.dbResult?.map(i => ({
          ...i,
          id: i.id[1],
          isChecked: false,
        }))

        marketingPlaces.unshift({
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })
        setMarketingPlaces(marketingPlaces)
      }
    }

    const fetchVehicles = async () => {
      const response = await request({
        url: "/trans-vehicles/names",
      })

      if (response?.result && Array.isArray(response?.dbResult)) {
        const vehicles = response.dbResult?.map(i => ({
          ...i,
          name: getTranslatedOption(i, "type", locale),
          isChecked: false,
        }))
        if (vehicles.length > 0) {
          vehicles[0].isChecked = true
        }

        vehicles.unshift({
          ...all,
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })

        setVehicles(vehicles)
      }
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchSetMarketingPlaces(), fetchVehicles()])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setMarketingPlaces(list => [
      { ...list[0], label: getLabelByLanguage(all, locale) },
      ...list.slice(1),
    ])

    setVehicles(list =>
      list.map(item =>
        item.id == 0
          ? { ...item, name: getTranslatedOption(item, "name", locale) }
          : {
              ...item,
              name: getTranslatedOption(item, "type", locale),
            }
      )
    )
  }, [locale])

  // CHECK HANDLERS
  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const vehicleCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = vehicles.map(i => ({
        ...i,
        isChecked: !vehicles[0].isChecked,
      }))
    } else {
      updatedList = vehicles.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedList[0].isChecked = true
    } else {
      updatedList[0].isChecked = false
    }

    setVehicles(updatedList)
  }

  const reset = () => {
    const nonCheckedVehicles = vehicles.map(i => ({
      ...i,
      isChecked: false,
    }))

    setVehicles(nonCheckedVehicles)

    const updatedMarketingPlaces = marketingPlaces.map(i => ({
      ...i,
      isChecked: false,
    }))

    setMarketingPlaces(updatedMarketingPlaces)
    setOnlyMembers(false)
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setPrice("")
    setReturnPrice("")
    setEstimatedTime("")
    setSelectedStartLocation("")
    setSelectedEndLocation("")
  }

  const submitHandler = () => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/transfer-price-daily",
          method: "put",
          data: payload,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")

    const vehicle_ids = vehicles
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (vehicle_ids.length === 0) {
      return setError("Please select a vehicle!")
    }

    if (!selectedStartLocation || !selectedEndLocation) {
      return setError("Please select location!")
    }

    if (!estimatedTime || estimatedTime < 0) {
      return setError("Please enter a valid estimated time!")
    }

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    if (!price || !returnPrice || price < 0 || returnPrice < 0) {
      return setError("Please enter valid prices!")
    }

    const newData = {
      onlyMembers,
      vehicle_ids,
      location_id_start: selectedStartLocation.id,
      searchTableStart_id: selectedStartLocation.searchTable_id,
      location_id_end: selectedEndLocation.id,
      searchTableEnd_id: selectedEndLocation.searchTable_id,
      estimatedTime: +estimatedTime,
      startDate,
      endDate,
      currency_id: 2,
      setMarketingPlace_ids,
      price: +price,
      returnPrice: +returnPrice,
    }

    submit(newData)
    reset()
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Transfer")} breadcrumbItem={t("Pricing")} />
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <Members
              onlyMembers={onlyMembers}
              setOnlyMembers={setOnlyMembers}
            />

            <Checkboxes
              dataList={vehicles}
              checkHandler={vehicleCheckHandler}
              label="Which vehicles?"
            />

            {marketingPlaces.length > 2 && (
              <Checkboxes
                dataList={marketingPlaces}
                checkHandler={marketingPlaceCheckHandler}
                label="Marketing Places"
              />
            )}

            <Locations
              setSelectedStartLocation={setSelectedStartLocation}
              setSelectedEndLocation={setSelectedEndLocation}
              estimatedTime={estimatedTime}
              setEstimatedTime={setEstimatedTime}
            />

            <Dates
              title="Price Date"
              subtitle="When can guests transfer using this price?"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />

            <Prices
              price={price}
              setPrice={setPrice}
              returnPrice={returnPrice}
              setReturnPrice={setReturnPrice}
              currency={"EUR"}
            />

            {error && <Alert color="danger"> {t(error)}</Alert>}
            {showSuccessAlert && (
              <SuccessAlert
                hideSuccessAlert={() => {
                  setShowSuccessAlert(false)
                }}
              />
            )}

            <div className="row justify-content-end">
              <Button
                className="align-self-end"
                size="md"
                color="primary"
                onClick={submitHandler}
              >
                {submitLoading ? (
                  <>
                    <Spinner size="sm" /> {t("Loading...")}{" "}
                  </>
                ) : (
                  t("Save")
                )}
              </Button>
            </div>
          </>
        )}
      </Container>
    </main>
  )
}
export default TransferPricing
