import React, { Component } from "react"
import { Button, ButtonGroup, Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next"

class ControlButtons extends Component {
  state = {
    typingTimer: null,
  }

  searchHandler = () => {
    if (this.props.isApiSearch) {
      const input = document.getElementById("table-search-input").value.trim()

      const { typingTimer } = this.state
      clearTimeout(typingTimer)
      let newTimer = setTimeout(() => {
        this.props.searchAPI(input)
      }, 500)
      this.setState({ typingTimer: newTimer })
    } else {
      this.search()
    }
  }
  search = () => {
    let input, filter, table, i
    input = document.getElementById("table-search-input")
    filter = input.value.toUpperCase()
    table = document.getElementById("main-table")
    let rows = table.getElementsByTagName("tr")

    for (i = 1; i < rows.length; i++) {
      let cells = rows[i].getElementsByTagName("td")

      let j
      let rowContainsFilter = false
      for (j = 0; j < cells.length; j++) {
        if (cells[j]) {
          if (
            cells[j].innerHTML
              .split("</div>")[1]
              .toUpperCase()
              .indexOf(filter) > -1
          ) {
            rowContainsFilter = true
            continue
          }
        }
      }

      if (!rowContainsFilter) {
        rows[i].style.display = "none"
      } else {
        rows[i].style.display = ""
      }
    }
  }

  render() {
    const {
      className,
      displayAddBtn,
      onAddBtn,
      addTitle,
      displayEditBtn,
      onEditBtn,
      editTitle,
      displayDeleteBtn,
      onDeleteBtn,
      deleteTitle,
      onPrintBtn,
      onExcelBtn,
      onPdfBtn,
      t,
    } = this.props
    return (
      <Row className={`mb-2 ${className}`}>
        <Col>
          {displayAddBtn && (
            <Button
              color="primary"
              onClick={onAddBtn}
              className="d-inline-flex mb-1"
            >
              <i className="mdi mdi-plus " />
              <span className="pr-1">{t(addTitle ? addTitle : "Add")}</span>
            </Button>
          )}{" "}
          {displayEditBtn && (
            <Button
              color="primary"
              onClick={onEditBtn}
              className="d-inline-flex mb-1"
            >
              <i className="bx bx-edit-alt icon" />
              <span className="pr-1">{t(editTitle ? editTitle : "Edit")}</span>
            </Button>
          )}{" "}
          {displayDeleteBtn && (
            <Button
              color="danger"
              className="py-1 d-inline-flex mb-1 mr-1"
              onClick={onDeleteBtn}
            >
              <i className="mdi mdi-delete-outline icon" />
              <span className="pr-1 align-self-center">
                {t(deleteTitle ? deleteTitle : "Delete")}
              </span>
            </Button>
          )}
          <ButtonGroup className="mb-1">
            <Button
              onClick={onPrintBtn}
              className="py-1 d-inline-flex align-items-center"
            >
              <i className="bx bx-printer icon" />
              <span className="pr-1">{t("Print")}</span>
            </Button>{" "}
            <Button
              className="py-1 d-inline-flex align-items-center"
              onClick={onPdfBtn}
            >
              <i className="mdi mdi-file-pdf-outline icon" />
              <span className="pr-1">Pdf</span>
            </Button>{" "}
            <Button
              className="py-1 d-inline-flex align-items-center"
              onClick={onExcelBtn}
            >
              <i className="mdi mdi-microsoft-excel icon" />
              <span className="pr-1">Excel</span>
            </Button>{" "}
          </ButtonGroup>
        </Col>
        <Col className="table-search-input">
          <i className="bx bx-search-alt" />
          <input
            placeholder={t("Search...")}
            id="table-search-input"
            onChange={this.searchHandler}
          />
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(ControlButtons)
