import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import PhoneInputComp from "react-phone-input-2"
import Select from "react-select"
import { Alert, Input } from "reactstrap"

import AuthButton from "./AuthButton"
import PasswordInput from "./PasswordInput"
import TACModal from "./TAC"
import PrivacyPolicyModal from "./PrivacyPolicy"
import { registerUser } from "store/actions"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage, getTranslatedOption } from "utils/other"
import styles from "./auth-section.module.scss"

const selectStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    borderRadius: "0.25rem",
    borderColor: "#74788d",
    padding: "0.4rem",
    cursor: "pointer",
  }),
}

const schema = yup.object({
  name: yup
    .string()
    .required("This field is required!")
    .min(0, "Too short!")
    .max(50, "Too long!"),
  surname: yup
    .string()
    .required("This field is required!")
    .min(0, "Too short!")
    .max(50, "Too long!"),
  phoneNumber: yup
    .string()
    .required("This field is required!")
    .min(5, "Invalid input!")
    .max(15, "Invalid input!"),
  country: yup
    .mixed()
    .test(
      "isSelected",
      "This field is required!",
      selectedItem => !!selectedItem?.value
    ),
  email: yup.string().required("This field is required!"),
  password: yup
    .string()
    .required("This field is required!")
    .min(6, "Please enter a password! min. 6 character"),
})

function Page2({ page1Data, setPage }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const account = useSelector(state => state.Account)
  const [countries, setCountries] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [membershipCode, setMembershipCode] = useState("")
  const [showTAC, setShowTAC] = useState(false)
  const [showPP, setShowPP] = useState(false)
  const { t, i18n } = useTranslation()
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const locale = i18n.language

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({ resolver: yupResolver(schema) })

  const [, setLoading] = useState(false)
  const [, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)

  const TACHandler = e => {
    e.preventDefault()
    setShowTAC(true)
  }

  const privacyPolicyHandler = e => {
    e.preventDefault()
    setShowPP(true)
  }

  let TAC = null
  switch (locale) {
    case "en":
      TAC = (
        <span>
          By signing in or creating an account, you agree with our Privacy
          Statement
          <Link to="#" className="text-primary" onClick={TACHandler}>
            {" "}
            Terms & Conditions{" "}
          </Link>
          and
          <Link to="#" className="text-primary" onClick={privacyPolicyHandler}>
            {" "}
            Privacy Statement
          </Link>
        </span>
      )
      break
    case "tr":
      TAC = (
        <span>
          Bir hesaba giriş yaparak ya da hesap oluşturarak ile
          <Link to="#" className="text-primary" onClick={TACHandler}>
            {" "}
            Şartlar ve Koşullarımız{" "}
          </Link>
          ve
          <Link to="#" className="text-primary" onClick={privacyPolicyHandler}>
            {" "}
            Gizlilik Bildirimimizi{" "}
          </Link>
          kabul etmiş olursunuz
        </span>
      )
      break
    default:
      TAC = (
        <span>
          By signing in or creating an account, you agree with our Privacy
          Statement
          <Link to="#" className="text-primary" onClick={TACHandler}>
            {" "}
            Terms & Conditions{" "}
          </Link>
          and
          <Link to="#" className="text-primary" onClick={privacyPolicyHandler}>
            {" "}
            Privacy Statement
          </Link>
        </span>
      )
      break
  }

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await request(
        {
          url: "/countries",
        },
        false
      )
      if (response?.countries) {
        const countries = response?.countries.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
        }))
        setCountries(countries)
        return countries
      }
    }
    fetchCountries()
  }, [])

  useEffect(() => {
    if (countries.length > 0) {
      setCountries(prev =>
        prev.map(i => ({ ...i, label: getTranslatedOption(i, "name", locale) }))
      )
    }

    const selectedCountry = watch("country")
    if (selectedCountry?.value) {
      setValue("country", {
        ...selectedCountry,
        label: getTranslatedOption(selectedCountry, "name", locale),
      })
    }
  }, [locale])

  const callback = obj => {
    if (obj.result) {
      setMembershipCode(obj.companyCode)
      setShowSuccessAlert(true)
    }
  }

  const signUp = data => {
    const payload = {
      ...page1Data,
      ...data,
      currency_id: 2,
      setMarketingPlace_id: 1,
      country_id: parseInt(data.country.id),
    }
    dispatch(registerUser(payload, callback))
  }

  const handleCheck = event => {
    const isActive = event.target.checked
    setIsActive(isActive)
  }

  return (
    <div>
      {showTAC && <TACModal toggle={setShowTAC.bind(null, false)} />}
      {showPP && <PrivacyPolicyModal toggle={setShowPP.bind(null, false)} />}

      <div className="row mb-3">
        <div className={`col-sm-6`}>
          <Controller
            control={control}
            name="name"
            defaultValue={""}
            render={({ onChange, value }) => {
              return (
                <input
                  className={`w-100 py-3 px-3 border border-secondary rounded ${
                    errors.name && styles.error
                  }`}
                  type="text"
                  id="name"
                  value={value}
                  onChange={onChange}
                  name="name"
                  placeholder={t("Name")}
                  required
                />
              )
            }}
          />
          {errors?.name && (
            <p className={styles.errorTxt}>
              {t(errors.name?.message || "defaultError")}
            </p>
          )}
        </div>
        <div className={`col-sm-6`}>
          <Controller
            control={control}
            name="surname"
            defaultValue={""}
            render={({ onChange, value }) => {
              return (
                <input
                  className={`w-100 py-3 px-3 border border-secondary rounded ${
                    errors.surname && styles.error
                  }`}
                  type="text"
                  id="surname"
                  name="surname"
                  value={value}
                  onChange={onChange}
                  placeholder={t("Surname")}
                  required
                />
              )
            }}
          />

          {errors.surname && (
            <p className={styles.errorTxt}>
              {t(errors.surname?.message || "defaultError")}
            </p>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <div className={`col-sm-6`}>
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue={""}
            render={({ onChange, value }) => {
              return (
                <PhoneInputComp
                  country={"tr"}
                  value={value}
                  onChange={onChange}
                  inputProps={{
                    required: true,
                  }}
                  containerClass={`w-100 border border-secondary rounded`}
                  inputClass={`w-100 h-100 py-3 border-0`}
                />
              )
            }}
          />

          {errors.phoneNumber && (
            <p className={styles.errorTxt}>
              {t(errors.phoneNumber?.message || "defaultError")}
            </p>
          )}
        </div>
        <div className={`col-sm-6`}>
          <Controller
            control={control}
            name="country"
            defaultValue={""}
            render={({ onChange, value }) => {
              return (
                <Select
                  styles={selectStyles}
                  value={value}
                  onChange={onChange}
                  options={countries}
                  noOptionsMessage={() => t("No Options")}
                  classNamePrefix="select2-selection"
                  placeholder={t("Select Country")}
                />
              )
            }}
          />
          {errors.country && (
            <p className={styles.errorTxt}>{t(errors.country?.message)}</p>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-6">
          <Controller
            control={control}
            name="email"
            defaultValue={""}
            render={({ onChange, value }) => {
              return (
                <input
                  className={`w-100 py-3 px-3 border border-secondary rounded ${
                    errors.email && styles.error
                  }`}
                  type="email"
                  id="email"
                  value={value}
                  onChange={onChange}
                  name="email"
                  placeholder="Email"
                  required
                />
              )
            }}
          />
          {errors.email && (
            <p className={styles.errorTxt}>
              {t(errors.email?.message || "defaultError")}
            </p>
          )}
        </div>
        <div className="col-sm-6">
          <Controller
            control={control}
            name="password"
            defaultValue={""}
            render={({ onChange, value }) => {
              return <PasswordInput value={value} onChange={onChange} />
            }}
          />
          {errors.password && (
            <p className={styles.errorTxt}>
              {t(errors.password?.message || "defaultError")}
            </p>
          )}
        </div>
      </div>
      <label htmlFor="tac" className={styles.tac}>
        <Input type="checkbox" onClick={handleCheck} id="tac" />
        {TAC}
      </label>

      {account.error && <Alert color="danger">{t(account.errorMessage)}</Alert>}

      <AuthButton
        className={`w-100 rounded mt-2 ${
          isActive ? "signBtn" : "authBtnDisabled"
        }`}
        onClick={handleSubmit(signUp)}
        disabled={!isActive}
        submitLoading={account.loading}
        submitText={t("Registering...")}
      >
        {t("Register")}
      </AuthButton>

      {showSuccessAlert && (
        <SweetAlert
          success
          title={t("Congratulations!")}
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowSuccessAlert(false)
            history.push("/login")
          }}
          confirmBtnText={t("OK")}
        >
          <h5>
            {t(
              "Your account is successfully created. Note your membership code or check your email."
            )}
          </h5>
          <p>{t("Membership Code")} {membershipCode}</p>
        </SweetAlert>
      )}
    </div>
  )
}

export default Page2
