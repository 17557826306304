import React, { useState } from "react"
import { Media } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { momentLocale } from "utils/dateTimeFunctions"
import styles from "./contacts.module.scss"

const Contacts = ({
  contacts,
  currentContact,
  setCurrentContact,
  setMessagesToRead,
}) => {
  const {
    i18n: { language: locale },
  } = useTranslation()

  return (
    <div className={styles.container}>
      <PerfectScrollbar className="h-100">
        <ul className={styles.contactList}>
          {contacts.map(conversation => {
            return (
              <li
                key={conversation.id}
                className={classnames({
                  [styles.active]: currentContact?.id == conversation.id,
                })}
              >
                <Link
                  to="/"
                  onClick={e => {
                    e.preventDefault()
                    setCurrentContact(prev => {
                      const prevConversation = contacts.find(
                        i => i.id === prev.id
                      )
                      setMessagesToRead(prevConversation)
                      setMessagesToRead(conversation)
                      return conversation
                    })
                  }}
                >
                  <Media className={styles.contactItem}>
                    <div className={styles.avatar}>
                      <span>{conversation.title[0]}</span>
                    </div>

                    <Media
                      className={classnames({
                        "overflow-hidden": true,
                        [styles.bold]: conversation?.unreadMessageCount > 0,
                      })}
                      body
                    >
                      <h5>{conversation.title}</h5>
                      <p>{conversation.lastMessage}</p>
                    </Media>
                    <div
                      className={classnames({
                        "d-flex flex-column": true,
                        [styles.unreadCount]:
                          conversation?.unreadMessageCount > 0,
                      })}
                    >
                      <p className="font-size-11 mb-0">
                        {conversation.time
                          ? momentLocale(locale)(conversation.time).fromNow()
                          : ""}
                      </p>
                      <span className="d-none">
                        {conversation?.unreadMessageCount || 0}
                      </span>
                    </div>
                  </Media>
                </Link>
              </li>
            )
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  )
}

export default Contacts
