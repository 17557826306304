import {
  Container,
  Card,
  CardBody,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConditionInputs from "./ConditionInputs"
import { PrepaymentProvider, usePrepaymentContext } from "./context"
import PolicySummary from "./PolicySummary"
import styles from "./styles.module.scss"

const HotelPrepaymentPolicy = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { values, setters, functions } = usePrepaymentContext()
  const {
    loading,
    submitLoading,
    errorMessage,
    showSuccessAlert,
    hotelNotReg,
    hasSignedContract,
  } = values
  const { setShowSuccessAlert, setHotelNotReg } = setters
  const { submitHandler } = functions
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Hotels")} breadcrumbItem={t("Payment Policy")} />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h4 className={styles.title}>
              {t(
                "Reservation fees are collected by Vihobook. Specify the conditions under which you want to receive payment by Vihobook."
              )}{" "}
              {t(
                "You cannot change this information after your Agreement has been approved by Vihobook."
              )}
            </h4>
            <ConditionInputs />
            <PolicySummary />
          </CardBody>
        </Card>

        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <CustomWarning
          title="Warning!"
          warning={true}
          show={hotelNotReg}
          onConfirm={() => {
            setHotelNotReg(false)
            history.push("/hotel-information")
          }}
          message="Please Register Your Hotel Information First!"
        />

        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button
            style={{ cursor: hasSignedContract ? "not-allowed" : "pointer" }}
            color="primary"
            onClick={submitHandler}
            disabled={hasSignedContract}
          >
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

const WithProvider = props => {
  return (
    <PrepaymentProvider>
      <HotelPrepaymentPolicy {...props} />
    </PrepaymentProvider>
  )
}
export default WithProvider
