import React, { useState } from "react"
import { GoSearch } from "react-icons/go"
import AsyncSelect from "react-select/async"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Label, Col, FormGroup, Row, Input } from "reactstrap"
import useRequest from "hooks/useRequest"

import SelectOption from "./SelectOption"
import styles from "./styles.module.scss"
let typingTimer

const Locations = props => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const {
    selectedStartLocation,
    selectedEndLocation,
    setSelectedStartLocation,
    setSelectedEndLocation,
    estimatedTime,
    setEstimatedTime,
  } = props
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  const onLocationInput = (input, callback) => {
    if (input.trim().length < 2) {
      return
    }

    clearTimeout(typingTimer)
    let newTimer = setTimeout(() => {
      if (input) {
        searchOnAPI(input, callback)
      }
    }, 250)

    typingTimer = newTimer
  }

  const searchOnAPI = async (input, callback) => {
    try {
      let options = []
      input = input.replace(/İ/g, "I")

      const response = await request(
        {
          url: "/trans-locations/search",
          params: {
            locale,
            searchText: input,
          },
        },
        false
      )
      options = response.dbResult.map(i => ({
        ...i,
        label: i.name,
        value: i.id,
      }))
      callback(options)
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("")}</h4>
        <h5 className={styles.cardSubtitle}>{t("")}</h5>
        <Row className="m-0 align-items-end flex-wrap" style={{ gap: 8 }}>
          <Col md={3} className="p-0">
            <FormGroup className="mb-0">
              <Label>{t("Start Location")}</Label>
              <AsyncSelect
                placeholder={t("Search...")}
                menuPortalTarget={document.querySelector("body")}
                components={{
                  DropdownIndicator: () => (
                    <span className="pr-3">
                      <GoSearch size={20} />
                    </span>
                  ),
                  IndicatorSeparator: () => null,
                  Option: SelectOption,
                }}
                noOptionsMessage={() => null}
                loadOptions={onLocationInput}
                loadingMessage={() => t("Loading...")}
                onChange={item => setSelectedStartLocation(item)}
                value={selectedStartLocation}
                className={styles.selectContainer}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={3} className="p-0">
            <FormGroup className="mb-0">
              <Label>{t("End Location")}</Label>
              <AsyncSelect
                placeholder={t("Search...")}
                menuPortalTarget={document.querySelector("body")}
                components={{
                  DropdownIndicator: () => (
                    <span className="pr-3">
                      <GoSearch size={20} />
                    </span>
                  ),
                  IndicatorSeparator: () => null,
                  Option: SelectOption,
                }}
                noOptionsMessage={() => null}
                loadOptions={onLocationInput}
                loadingMessage={() => t("Loading...")}
                onChange={item => setSelectedEndLocation(item)}
                value={selectedEndLocation}
                className={styles.selectContainer}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={3} className="p-0">
            <FormGroup className="mb-0">
              <Label for="estimatedTime">{t("Estimated Time/Minute")}</Label>
              <Input
                type="number"
                min={0}
                step={1}
                value={estimatedTime}
                onChange={e => setEstimatedTime(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Locations
