import { createContext, useContext, useMemo, useState, useEffect } from "react"

import useRequest from "hooks/useRequest"

const CancellationContext = createContext()
export const useCancellationContext = () => {
  const context = useContext(CancellationContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const CancellationProvider = props => {
  const [freeCancellable, setFreeCancellable] = useState(true)
  const [freeCancelDayCountBeforeArrival, setFreeCancelDayCountBeforeArrival] =
    useState("")
  const [cancelChargePercent, setCancelChargePercent] = useState("")
  const [unusedChargePercent, setUnusedChargePercent] = useState("")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await fetchPolicy()
      setLoading(false)
    })()
  }, [])

  const fetchPolicy = async () => {
    const response = await request(
      {
        url: "/ticket-cancellation-policy",
      },
      false
    )
    if (response?.dbResult?.id) {
      const {
        freeCancellable,
        freeCancelDayCountBeforeArrival,
        cancelChargePercent,
        unusedChargePercent,
      } = response.dbResult

      setFreeCancellable(freeCancellable)
      setFreeCancelDayCountBeforeArrival(freeCancelDayCountBeforeArrival || "")
      setCancelChargePercent(cancelChargePercent)
      setUnusedChargePercent(unusedChargePercent)
    }
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    if (
      (freeCancellable && !freeCancelDayCountBeforeArrival) ||
      !cancelChargePercent ||
      !unusedChargePercent
    ) {
      return setErrorMessage("Please fill in the blanks!")
    } else if (
      (!freeCancellable && !cancelChargePercent) ||
      !unusedChargePercent
    ) {
      return setErrorMessage("Please fill in the blanks!")
    }

    if (freeCancellable && freeCancelDayCountBeforeArrival % 1 !== 0) {
      return setErrorMessage(`Invalid value. The day can't be decimal!`)
    }

    if (cancelChargePercent > 100 || unusedChargePercent > 100) {
      return setErrorMessage(
        "Invalid value. The numbers can't be greater than 100%!"
      )
    }

    let newDayCount = freeCancelDayCountBeforeArrival
    if (!freeCancellable) {
      newDayCount = 0
    }

    // submit data
    const payload = {
      freeCancellable,
      freeCancelDayCountBeforeArrival: newDayCount,
      cancelChargePercent,
      unusedChargePercent,
    }

    setSubmitLoading(true)
    const response = await request({
      url: "/ticket-cancellation-policy",
      method: "patch",
      data: payload,
    })
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
      fetchPolicy()
    }
  }

  const value = useMemo(() => {
    const setters = {
      setFreeCancellable,
      setFreeCancelDayCountBeforeArrival,
      setShowSuccessAlert,
      setLoading,
      setSubmitLoading,
      setErrorMessage,
      setCancelChargePercent,
      setUnusedChargePercent,
    }
    const values = {
      freeCancellable,
      freeCancelDayCountBeforeArrival,
      showSuccessAlert,
      submitLoading,
      loading,
      errorMessage,
      cancelChargePercent,
      unusedChargePercent,
    }
    const functions = { submitHandler }
    return { setters, values, functions }
  }, [
    freeCancellable,
    freeCancelDayCountBeforeArrival,
    showSuccessAlert,
    submitLoading,
    loading,
    errorMessage,
    cancelChargePercent,
    unusedChargePercent,
  ])

  return <CancellationContext.Provider value={value} {...props} />
}
