import React from "react"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { MdArrowForwardIos } from "react-icons/md"

import AuthButton from "./AuthButton"
import styles from "./auth-section.module.scss"

const COMPANY_TYPES = [
  {
    id: 1,
    label: "Hotel",
    title: "hotelTitle",
    types: "hotelTypes",
  },
  {
    id: 2,
    label: "Villa",
    title: "propertyTitle",
    types: "propertyTypes",
  },
  {
    id: 3,
    label: "Transfer",
    title: "transferTitle",
    types: "transferTypes",
  },
  {
    id: 4,
    label: "Tour",
    title: "eventTitle",
    types: "eventTypes",
  },
]

const schema = yup.object({
  companyName: yup
    .string()
    .required("This field is required!")
    .min(0, "Too short!")
    .max(50, "Too long!"),
  companyType_id: yup.number().required("This field is required!"),
})

function Page1({ setPage, setPage1Data }) {
  const { t } = useTranslation()

  const {
    setValue,
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyType_id: 1,
    },
  })

  const selectedCompanyType = watch("companyType_id")

  const handleCardClick = id => {
    if (id === selectedCompanyType) return
    setValue("companyType_id", id)
  }

  const handleNextPage = data => {
    setPage(2)
    setPage1Data(data)
  }

  return (
    <div>
      <div className="row">
        <div className={`col-sm-12`}>
          <div
            className={`${styles.companyNameInput} ${
              errors.companyName && styles.errorLabel
            }`}
          >
            <label>{t("Company Name")}</label>
            <Controller
              control={control}
              name="companyName"
              defaultValue={""}
              render={({ onChange, value }) => {
                return (
                  <input
                    className={`w-100 py-3 px-3 rounded ${
                      errors.companyName
                        ? styles.error
                        : "border border-secondary"
                    }`}
                    type="text"
                    id="companyName"
                    value={value}
                    onChange={onChange}
                    name="companyName"
                    placeholder={t("eg. Google")}
                    required
                  />
                )
              }}
            />
            {errors.companyName && (
              <p className={styles.errorTxt}>
                {t(errors.companyName?.message || "defaultError")}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.companyNameInput}>
        <hr className="mb-3" />
      </div>

      <div className="row">
        <div className={`col-sm-12`}>
          <div className={styles.companyNameInput}>
            <label>
              {t(
                "Become a free member by choosing according to your company / facility type!"
              )}
            </label>
          </div>
        </div>
      </div>
      {COMPANY_TYPES.map(i => (
        <div className="row">
          <div className={`col-sm-12`}>
            <div
              className={`mb-3 py-3 px-3 border border-secondary rounded d-flex ${styles.card}`}
              onClick={() => handleCardClick(i.id)}
            >
              <Controller
                control={control}
                name="companyType_id"
                defaultValue={""}
                render={({ onChange, value }) => {
                  return (
                    <input
                      className="mr-2"
                      type="radio"
                      id={i.id}
                      name={i.label}
                      value={i.id}
                      checked={i.id === selectedCompanyType}
                      onChange={onChange}
                    />
                  )
                }}
              />
              <div className="d-flex flex-column align-items-start">
                <p className="font-weight-bold">{t(i.title)}</p>
                <p>{t(i.types)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="row">
        <div className={`col-sm-12`}>
          <div className={styles.companyNameInput}>
            <AuthButton
              className={`signBtn w-100 rounded mt-2`}
              onClick={handleSubmit(handleNextPage)}
            >
              {t("Next")} <MdArrowForwardIos />
            </AuthButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page1
