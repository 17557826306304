import React from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation-safe"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert"
import Checkboxes from "components/Hotel/Marketing/Checkboxes/Checkboxes"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

import { addDays } from "utils/dateTimeFunctions"
// fns
import {
  usePropertyPricingContext,
  PropertyPricingProvider,
} from "./pricing-context"

const Pricing = () => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  const { t } = useTranslation()
  const history = useHistory()
  const { values, setters, functions } = usePropertyPricingContext()
  const {
    submitHandler,
    cancelHandler,
    villaCheckHandler,
    currencyCheckHandler,
    marketingPlaceCheckHandler,
  } = functions
  const {
    fromDate,
    toDate,
    villas,
    marketingPlaces,
    currencies,
    villaRegError,
    showSuccessAlert,
    submitLoading,
    loading,
    error,
  } = values
  const { setFromDate, setToDate, setVillaRegError, setShowSuccessAlert } =
    setters

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Marketing")} breadcrumbItem={t("Pricing")} />
        <AvForm onValidSubmit={submitHandler}>
          <Card>
            <CardBody className={styles.cardBody}>
              {loading ? (
                <CustomSpinner color="primary" />
              ) : (
                <>
                  <div className={styles.row}>
                    <h4 className={styles.title}>
                      {t(
                        "Enter your prices that will be valid for the date periods you have specified. Enter a date with a maximum interval of 45 days"
                      )}
                    </h4>
                  </div>
                  <div className={styles.row}>
                    {/* DATE INPUTS */}
                    <div className={styles.datesContainer}>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("From")}</Label>
                        <CustomDayPicker
                          onChange={date => {
                            setFromDate(date)
                            if (date.getTime() > toDate.getTime()) {
                              setToDate(date)
                            }
                          }}
                          value={fromDate}
                          disabledDays={[]}
                        />
                      </div>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("To")}</Label>
                        <CustomDayPicker
                          onChange={setToDate}
                          value={toDate}
                          disabledDays={[{ before: fromDate }]}
                        />
                      </div>
                    </div>

                    <Checkboxes
                      label="Villa"
                      items={villas}
                      onCheck={villaCheckHandler}
                    />
                    {marketingPlaces?.length > 2 && (
                      <Checkboxes
                        label="Marketing Places"
                        items={marketingPlaces}
                        onCheck={marketingPlaceCheckHandler}
                      />
                    )}
                    {country_id === 107 && (
                      <div className={styles.row}>
                        <Checkboxes
                          label="Currency"
                          items={currencies}
                          onCheck={currencyCheckHandler}
                        />
                      </div>
                    )}
                  </div>
                  {/* PRICING */}
                  <div className={styles.row}>
                    {/*  PRICING BOX */}
                    <div className={styles.pricingBox}>
                      <Label className={styles.pricingBoxLabel}>
                        {t("Price")}
                      </Label>
                      <AvGroup className={styles.avGroup}>
                        <div>
                          <AvInput
                            name="price"
                            className={styles.priceInput}
                            type="number"
                            min={1}
                            required
                          />
                          <AvFeedback>
                            {t("Please fill in the blanks!")}
                          </AvFeedback>
                        </div>
                        <span className={styles.priceCurrency}>
                          {currencies.find(i => i.isChecked)?.name}
                        </span>
                      </AvGroup>
                    </div>
                  </div>
                </>
              )}
              {error && (
                <Alert color="danger" className={styles.alert}>
                  {error}
                </Alert>
              )}
            </CardBody>
          </Card>

          <Row className={styles.buttons}>
            <Button size="md" type="button" onClick={cancelHandler}>
              {t("Cancel")}
            </Button>
            <Button size="md" color="primary">
              {t("Submit")} {submitLoading && <Spinner size="sm" />}
            </Button>
          </Row>
        </AvForm>
      </Container>
      {villaRegError && (
        <SweetAlert
          warning
          title={t("Warning!")}
          confirmBtnText={t("OK")}
          onConfirm={() => {
            setVillaRegError(false)
            history.push("/property-registration")
          }}
        >
          {t("Please first register a villa!")}
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            setFromDate(toDate)
            setToDate(addDays(toDate, 7))
            cancelHandler()
          }}
        />
      )}
    </main>
  )
}

const PricingWithProvider = () => {
  return (
    <PropertyPricingProvider>
      <Pricing />
    </PropertyPricingProvider>
  )
}

export default PricingWithProvider
