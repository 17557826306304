import React from "react"
import { Button, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Select from "react-select"

import styles from "./inputs.module.scss"

const isSelected = selectedItem => !!selectedItem?.value
const schema = yup.object({
  property: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
  year: yup.mixed().test("isSelected", "This field is required!", isSelected),
  marketingPlace: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
})

const Inputs = props => {
  const {
    properties,
    years,
    marketingPlaces,
    selectedMarketingPlace,
    submitHandler,
    loading,
  } = props
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <div className={styles.formControl}>
        <label>{t("Property")}</label>
        <Controller
          control={control}
          name="property"
          defaultValue={""}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={properties}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.property && <p>{t(errors.property.message)}</p>}
      </div>
      <div className={styles.formControl}>
        <label>{t("Year")}</label>
        <Controller
          name="year"
          control={control}
          defaultValue={""}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={years}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.year && <p>{t(errors.year.message)}</p>}
      </div>
      <div
        className={styles.formControl}
        // style={{ display: marketingPlaces.length === 1 ? "none" : "flex" }}
      >
        <label>{t("MarketingPlace")}</label>
        <Controller
          name="marketingPlace"
          defaultValue={selectedMarketingPlace}
          control={control}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={marketingPlaces}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.marketingPlace && <p>{t(errors.marketingPlace.message)}</p>}
      </div>
      <Button type="submit" color="primary" disabled={loading}>
        {t("Search")} {loading && <Spinner size="sm" />}
      </Button>
    </form>
  )
}

export default Inputs
