import React from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"
import { Input } from "reactstrap"

import styles from "./styles.module.scss"

export default function MinNights(props) {
  const {
    hasMinAccommodation,
    setHasMinAccommodation,
    minAccommodation,
    setMinAccommodation,
  } = props
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("How long do guests need to stay to use this promotion?")}
        </h5>

        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            name="minimumAccommodation"
            onChange={(e, v) => setHasMinAccommodation(v == 1)}
            value={hasMinAccommodation ? 1 : 0}
          >
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              className={styles.radioLabel}
              label={t("Match to selected rate plan(s)")}
              value={0}
            />

            <FormControlLabel
              control={<Radio className={styles.radio} />}
              className={styles.radioLabel}
              label={t("Choose a minimum number of nights")}
              value={1}
            />
          </RadioGroup>
        </FormControl>
        {hasMinAccommodation && (
          <div className="mt-3">
            <h6>{t("Write a number")}</h6>
            <div className="row mx-0 gap-1 align-items-center">
              <Input
                className="w-auto mr-2"
                type="number"
                min={1}
                max={366}
                value={minAccommodation}
                onChange={e => setMinAccommodation(e.target.value)}
              />
              <p className="mb-0 w-auto"> {t("night or more")} </p>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
