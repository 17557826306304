import React, { useState, useRef, useEffect } from "react"
import {
  Button,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { BsSearch } from "react-icons/bs"
import { ImSearch } from "react-icons/im"
import { MdSend } from "react-icons/md"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useTranslation } from "react-i18next"

import MessageList from "./MessageList"
import { useChatContext } from "../context"
import styles from "./chatBox.module.scss"
import { getTranslatedOption } from "utils/other"

const ChatBox = () => {
  let messagesEl = useRef()
  let searchInputRef = useRef()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const { values, setters, functions } = useChatContext()
  const { currentContact, contacts, messages, currentMessage, scroll } = values
  const { setCurrentMessage, setScroll } = setters
  const { addMessage, setMessagesToRead, deleteMessage } = functions

  useEffect(() => {
    if (scroll) {
      messagesEl?.scroll(0, messagesEl?.scrollHeight)

      setScroll(false)
    }
  }, [scroll, setScroll])

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  const onKeyPress = e => {
    const { key } = e
    if (key === "Enter") {
      sendHandler()
    }
  }

  const sendHandler = () => {
    if (currentMessage) {
      addMessage(currentContact, currentMessage)
    }
  }

  const userInteractHandler = () => {
    setMessagesToRead(currentContact)
  }

  const searchHandler = e => {
    e.preventDefault()
    const value = searchInputRef?.current?.value
    console.log(window?.find(value))
  }

  return (
    <Card className={styles.card} onClick={userInteractHandler}>
      <header className={styles.header}>
        <div>
          <h5 className="mb-2">
            {currentContact &&
              `${currentContact?.title} (${getTranslatedOption(
                currentContact,
                "subjectType",
                locale
              )})`}
          </h5>
        </div>
        <Dropdown isOpen={isSearchOpen} toggle={toggleSearch}>
          <DropdownToggle className={styles.toggleBtn} tag="i">
            <ImSearch />
          </DropdownToggle>
          <DropdownMenu className={styles.dropdownMenu} right>
            <InputGroup>
              <Input
                type="search"
                className="form-control"
                placeholder={t("Search...")}
                aria-label="Recipient's username"
                innerRef={searchInputRef}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" type="submit" onClick={searchHandler}>
                  <BsSearch size={14} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </DropdownMenu>
        </Dropdown>
      </header>

      <PerfectScrollbar
        className={styles.scrollbar}
        ref={x => (messagesEl = x?._ps?.element)}
      >
        <MessageList
          messages={messages}
          deleteMessage={deleteMessage}
          facilityName={currentContact?.companyName}
        />
      </PerfectScrollbar>

      <div className={styles.inputBox}>
        <input
          type="text"
          value={currentMessage}
          onKeyPress={onKeyPress}
          onChange={e => setCurrentMessage(e.target.value)}
          className="form-control chat-input"
          placeholder={t("Enter message...")}
        />
        <Button
          type="button"
          color="primary"
          onClick={sendHandler}
          className="btn-rounded chat-send w-md waves-effect waves-light"
          disabled={contacts.length === 0}
        >
          <span>{t("Send")}</span> <MdSend size={15} />
        </Button>
      </div>
    </Card>
  )
}

export default ChatBox
