import { Link } from "react-router-dom"
import { Card } from "reactstrap"

import styles from "./dashboard.module.scss"

const BottomItem = ({ link, imgSrc, label }) => {
  return (
    <Link to={link}>
      <Card className={styles.bottomCardItem}>
        <img src={imgSrc} alt={label} />
        <label>{label}</label>
      </Card>
    </Link>
  )
}

export default BottomItem
