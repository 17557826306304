import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import styles from "./newConversation.module.scss"

const schema = yup.object({
  title: yup.string().required("This field is required!"),
  subjectType: yup
    .mixed()
    .test(
      "isSelected",
      "This field is required!",
      selectedItem => !!selectedItem?.value
    ),
  message: yup
    .string()
    .required("This field is required!")
    .min(5, "Too short!"),
})

export default function NewConversationModal({
  toggle,
  loading,
  subjectTypes,
  submitHandler,
}) {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("New Conversation")}</ModalHeader>
      <ModalBody>
        <Form className={styles.form}>
          <div className={styles.middleRow}>
            <FormGroup>
              <Label>{t("Subject")}</Label>
              <Input name="title" innerRef={register} />
              {errors.title && (
                <p className={styles.formError}>{t(errors.title?.message)}</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label>{t("Subject type")}</Label>
              <Controller
                control={control}
                name="subjectType"
                defaultValue=""
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={subjectTypes}
                    placeholder={t("Select...")}
                    noOptionsMessage={() => t("No Options")}
                  />
                )}
              />
              {errors.subjectType && (
                <p className={styles.formError}>
                  {t(errors.subjectType?.message)}
                </p>
              )}
            </FormGroup>
          </div>
          <div className={styles.row}>
            <FormGroup>
              <Label>{t("First Message")}</Label>
              <Input type="textarea" name="message" innerRef={register} />
              {errors.message && (
                <p className={styles.formError}>{t(errors.message?.message)}</p>
              )}
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={toggle}>
          {t("Close")}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit(submitHandler)}
        >
          {loading ? (
            <>
              <Spinner size="sm" /> {t("Loading...")}{" "}
            </>
          ) : (
            t("Save")
          )}
        </button>
      </ModalFooter>
    </Modal>
  )
}
