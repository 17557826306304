import React, { useState } from "react"
import { Button, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import { GoSearch } from "react-icons/go"

import useRequest from "hooks/useRequest"
import SelectOption from "./SelectOption"
import styles from "./inputs.module.scss"

const isSelected = selectedItem => !!selectedItem?.value
const schema = yup.object({
  vehicle: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
  locationStart: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
  locationEnd: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
  onlyMembers: yup
    .mixed()
    .test(
      "isSelected",
      "This field is required!",
      selectedItem => !!selectedItem.label
    ),
  year: yup.mixed().test("isSelected", "This field is required!", isSelected),
  marketingPlace: yup
    .mixed()
    .test("isSelected", "This field is required!", isSelected),
})

let typingTimer
const Inputs = props => {
  const {
    vehicles,
    years,
    marketingPlaces,
    selectedMarketingPlace,
    submitHandler,
    loading,
  } = props

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const [, setLoading] = useState(false)
  const [, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const onLocationInput = (input, callback) => {
    if (input.trim().length < 2) {
      return
    }

    clearTimeout(typingTimer)
    let newTimer = setTimeout(() => {
      if (input) {
        searchOnAPI(input, callback)
      }
    }, 250)

    typingTimer = newTimer
  }

  const searchOnAPI = async (input, callback) => {
    try {
      let options = []
      input = input.replace(/İ/g, "I")

      const response = await request(
        {
          url: "/trans-locations/search",
          params: {
            locale,
            searchText: input,
          },
        },
        false
      )
      options = response.dbResult.map(i => ({
        ...i,
        label: i.name,
        value: i.id,
      }))
      callback(options)
    } catch (error) {
      console.log(error.message)
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      {/* VEHICLE */}
      <div className={styles.formControl}>
        <label>{t("Vehicle")}</label>
        <Controller
          control={control}
          name="vehicle"
          defaultValue={""}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={vehicles}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.vehicle && <p>{t(errors.vehicle.message)}</p>}
      </div>
      {/* START LOCATION */}
      <div className={styles.formControl}>
        <label>{t("Start Location")}</label>
        <Controller
          control={control}
          name="locationStart"
          defaultValue={""}
          render={({ onChange, value }) => (
            <AsyncSelect
              placeholder={t("Search...")}
              menuPortalTarget={document.querySelector("body")}
              components={{
                DropdownIndicator: () => (
                  <span className="pr-3">
                    <GoSearch size={20} />
                  </span>
                ),
                IndicatorSeparator: () => null,
                Option: SelectOption,
              }}
              noOptionsMessage={() => null}
              loadOptions={onLocationInput}
              loadingMessage={() => t("Loading...")}
              onChange={onChange}
              value={value}
              className={styles.selectContainer}
              isClearable
            />
          )}
        />
        {errors.locationStart && <p>{t(errors.locationStart.message)}</p>}
      </div>
      {/* End LOCATION */}
      <div className={styles.formControl}>
        <label>{t("End Location")}</label>
        <Controller
          control={control}
          name="locationEnd"
          defaultValue={""}
          render={({ onChange, value }) => (
            <AsyncSelect
              placeholder={t("Search...")}
              menuPortalTarget={document.querySelector("body")}
              components={{
                DropdownIndicator: () => (
                  <span className="pr-3">
                    <GoSearch size={20} />
                  </span>
                ),
                IndicatorSeparator: () => null,
                Option: SelectOption,
              }}
              noOptionsMessage={() => null}
              loadOptions={onLocationInput}
              loadingMessage={() => t("Loading...")}
              onChange={onChange}
              value={value}
              className={styles.selectContainer}
              isClearable
            />
          )}
        />
        {errors.locationEnd && <p>{t(errors.locationEnd.message)}</p>}
      </div>
      {/* YEAR */}
      <div className={styles.formControl} style={{ width: 120 }}>
        <label>{t("Year")}</label>
        <Controller
          name="year"
          control={control}
          defaultValue={""}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={years}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.year && <p>{t(errors.year.message)}</p>}
      </div>
      {/* ONLY MEMBERS */}
      <div className={styles.formControl} style={{ width: 190 }}>
        <label>{t("Who will be able to see this price?")}</label>
        <Controller
          name="onlyMembers"
          control={control}
          defaultValue={""}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={[
                { label: t("Everyone"), value: false },
                { label: t("Only Members"), value: true },
              ]}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.onlyMembers && <p>{t(errors.onlyMembers.message)}</p>}
      </div>
      {/* MARKETING PLACE */}
      <div
        className={styles.formControl}
        style={{ display: marketingPlaces.length === 1 ? "none" : "flex" }}
      >
        <label>{t("MarketingPlace")}</label>
        <Controller
          name="marketingPlace"
          defaultValue={selectedMarketingPlace}
          control={control}
          render={({ onChange, value }) => (
            <Select
              value={value}
              onChange={onChange}
              options={marketingPlaces}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          )}
        />
        {errors.marketingPlace && <p>{t(errors.marketingPlace.message)}</p>}
      </div>
      <Button type="submit" color="primary" disabled={loading}>
        {t("Search")} {loading && <Spinner size="sm" />}
      </Button>
    </form>
  )
}

export default Inputs
