import React from "react"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { Label, Card, Col, Row, Alert } from "reactstrap"
import { withTranslation } from "react-i18next"
import axios from "axios"

class CustomDropZone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoRef: null,
      isRejected: false,
      rejectionMessage: "",
    }

    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.props.setLogoRef(files[0])
    this.setState({ logoRef: files[0], isRejected: false })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  onDropRejected = obj => {
    this.setState({ isRejected: true })
  }

  componentDidMount = () => {
    this.setState({ logoRef: this.props.logoRef })
  }

  render() {
    const { t } = this.props
    const { isRejected, logoRef } = this.state
    return (
      <React.Fragment>
        <Label for="logo">{t("Logo")}</Label>
        <Dropzone
          maxFiles={1}
          accept="image/jpg, image/jpeg, image/png"
          onDropRejected={this.onDropRejected}
          onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h5>{t("Drop files here or click to upload.")}</h5>
                <h6>{t("(Only *.jpeg and *.png images will be accepted)")}</h6>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {isRejected && (
            <Alert color="danger">{t("Please upload a valid logo!")}</Alert>
          )}
          {logoRef && (
            <Card className="mt-1 mb-1 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
              <div className="p-2 ">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    {logoRef.name ? (
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={logoRef.name}
                        src={logoRef.preview}
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt="Logo image"
                        src={axios.defaults.baseURL + "/" + logoRef}
                        style={{ objectFit: "contain" }}
                      />
                    )}
                  </Col>
                  <Col>
                    {logoRef.name && (
                      <>
                        <Link to="#" className="text-muted font-weight-bold">
                          {logoRef.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{logoRef.formattedSize}</strong>
                        </p>{" "}
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CustomDropZone)
