import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"
import WidgetPagination from "components/Common/WidgetPagination/index"
import styles from "./styles.module.scss"

const DocumentsTable = props => {
  const { t } = useTranslation()
  const {
    onSelectTableRow,
    selectedRowId,
    documents,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  let Documents = documents.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
        }}
        className={`${selectedRowId === item.id ? styles.selectedRow : ""} ${
          styles.tableRow
        }`}
      >
        <Td className={styles.dataCell}>{item.fileTitle}</Td>
        <Td className={styles.dataCell}>{item.fileRef}</Td>
        <Td className={styles.dataCell}>
          {t(item.isConfirmed ? "Yes" : "No")}
        </Td>
      </Tr>
    )
  })

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0 data-table-container">
                <Table
                  id="main-table"
                  className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th className={styles.tableData}>
                        {t("Document Title")}
                      </Th>
                      <Th className={styles.tableData}>{t("Document")}</Th>
                      <Th className={styles.tableData}>{t("Is Confirmed")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{Documents}</Tbody>
                </Table>
              </div>
              {hasPagination && (
                <WidgetPagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default DocumentsTable
