import { useTranslation } from "react-i18next"
import AuthButton from "./AuthButton"

const signUp = {
  title: "Vihobook Extranet",
  description: "Already have an account?",
  buttonText: "Login",
}

const signIn = {
  title: "Welcome to the Vihobook Extranet!",
  description: "Don't have an account?",
  buttonText: "Register",
}

const AuthSection = ({ currentPage, handleClick }) => {
  const { t } = useTranslation()

  const { title, description, buttonText } =
    currentPage === "/login" ? signIn : signUp

  const directionClass = currentPage === "/login" ? "slideLeft" : "slideRight"

  return (
    <div
      className={`authSection ${directionClass} d-flex position-relative justify-content-center align-items-center h-100 overflow-hidden`}
    >
      <div className={`circle first position-absolute rounded-circle`}></div>
      <div className={`circle second position-absolute rounded-circle`}></div>
      <div className={`circle third position-absolute rounded-circle`}></div>
      <div className={`circle fourth position-absolute rounded-circle`}></div>
      <div
        className={`container flex-col align-items b-center text-center justify-content-center text-white mb-20`}
      >
        <h2 className="mb-4">{t(title)}</h2>
        <p className="mb-4">{t(description)}</p>
        <AuthButton className="rounded-lg" onClick={handleClick}>
          {t(buttonText)}
        </AuthButton>
      </div>
    </div>
  )
}

export default AuthSection
