import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Card, CardBody, Col, Row } from "reactstrap"

import styles from "./styles.module.scss"

export default function PromotionTypeItem({ item, index, setComponentIndex }) {
  const { t } = useTranslation()

  const newPromotionHandler = () => {
    setComponentIndex(index)
  }
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <Row className={styles.row}>
          <Col className={styles.col} xs={12} md={6}>
            <Row className={styles.itemLeftSide}>
              <img
                src={process.env.REACT_APP_API_URL + "/" + item.icon}
                alt={item.name}
              />
              <div>
                <h4>{item.name}</h4>
                <h6>{item.info}</h6>
              </div>
            </Row>
          </Col>
          <Col className={styles.col} xs={12} md={6}>
            <div className={styles.itemRightSide}>
              <p>{item.subInfo}</p>
              <Button color="primary" onClick={newPromotionHandler}>
                {t("Add Promotion")}
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
