import { addDays } from "date-fns"
import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage, all } from "utils/other"

const HotelOverallViewContext = createContext()

export const useOverallViewContext = () => {
  const context = useContext(HotelOverallViewContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const HotelOverallViewProvider = props => {
  const [hotel_id, setHotel_id] = useState(null)
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(addDays(new Date(), 15))
  const [roomNames, setRoomNames] = useState([])
  const [boardTypes, setBoardTypes] = useState([])
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])

  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [tablesData, setTablesData] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)

  const { i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    setRoomNames(
      roomNames.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )

    setBoardTypes(
      boardTypes.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )
  }, [locale])

  // FETCH FUNCTIONS
  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )

    if (response?.dbResult) {
      const hotel_id = response?.dbResult?.id
      setHotel_id(hotel_id)
      return hotel_id
    }
  }

  const fetchRoomNames = async hotel_id => {
    const response = await request(
      {
        url: "/hotel-room-names",
        params: { hotel_id },
      },
      false
    )

    const roomNames = response?.dbResult?.map(i => ({
      ...i,
      name: getLabelByLanguage(i, locale),
      isChecked: true,
    }))

    roomNames.unshift({
      ...all,
      id: 0,
      name: getLabelByLanguage(all, locale),
      isChecked: true,
    })

    setRoomNames(roomNames)
    return roomNames
  }

  const fetchBoardTypes = async hotel_id => {
    const response = await request(
      {
        url: "/hotel-board-types/fetch-names-by-hotel-id",
        params: { hotel_id },
      },
      false
    )
    const boardTypes = response?.dbResult?.map(i => ({
      ...i,
      name: getLabelByLanguage(i, locale),
      isChecked: false,
    }))
    boardTypes[0].isChecked = true
    setBoardTypes(boardTypes)
    return boardTypes
  }

  const fetchCompanyMarketingPlaces = async () => {
    const response = await request(
      {
        url: "/company-marketing-place",
      },
      false
    )

    const marketingPlaces = response?.dbResult?.map(i => ({
      id: i.setMarketingPlace_id,
      name: i.setMarketingPlaceName,
      isChecked: false,
    }))
    marketingPlaces[0].isChecked = true
    setMarketingPlaces(marketingPlaces)
    return marketingPlaces
  }

  const searchHandler = async (
    roomNames,
    boardTypes,
    marketingPlaces,
    currencies,
    hotel_id
  ) => {
    setError("")
    const room_ids = roomNames
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.room_id)
    const boardType_id = boardTypes?.find(i => i.isChecked)?.id

    const currency_id = currencies?.find(i => i.isChecked)?.id

    const setMarketingPlace_id = marketingPlaces?.find(i => i.isChecked)?.id

    // validation
    if (room_ids?.length === 0 || !boardType_id || !currency_id) {
      return
    }

    // search on api
    const data = {
      fromDate,
      toDate,
      room_ids,
      setBoardType_id: boardType_id,
      currency_id,
      setMarketingPlace_id,
      hotel_id,
    }

    const response = await request({
      url: "/hotel-room-prices/overall-view",
      params: data,
    })

    setTablesData(response?.dbResult)
  }

  const value = useMemo(() => {
    const functions = {
      fetchHotelData,
      fetchRoomNames,
      fetchBoardTypes,
      fetchCompanyMarketingPlaces,
      searchHandler,
    }
    const setters = {
      setHotel_id,
      setSubmitLoading,
      setLoading,
      setError,
      setCurrencies,
      setFromDate,
      setToDate,
      setRoomNames,
      setBoardTypes,
      setMarketingPlaces,
      setTablesData,
    }
    const values = {
      hotel_id,
      submitLoading,
      loading,
      error,
      fromDate,
      toDate,
      roomNames,
      currencies,
      boardTypes,
      marketingPlaces,
      tablesData,
    }
    return { functions, setters, values }
  }, [
    hotel_id,
    submitLoading,
    loading,
    currencies,
    error,
    fromDate,
    toDate,
    roomNames,
    boardTypes,
    marketingPlaces,
    tablesData,
  ])

  return <HotelOverallViewContext.Provider value={value} {...props} />
}
