import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { withTranslation } from "react-i18next"

const status = {
  false: "Inactive",
  true: "Active",
}

const isSuperUser = {
  false: "Regular user",
  true: "Superuser",
}

class UsersTable extends React.Component {
  state = {
    selectedRowId: "",
  }
  render() {
    const { t, users } = this.props

    let Users = users.map(user => {
      return (
        <Tr
          key={user.id}
          onClick={() => {
            this.props.onSelectTableRow(user.id)
            this.setState({ selectedRowId: user.id })
          }}
          className={this.state.selectedRowId === user.id ? "selectedRow" : ""}
        >
          <Td className="align-middle text-center">{user.name}</Td>
          <Td className="align-middle text-center">{user.surname}</Td>
          <Td className="align-middle text-center">{user.email}</Td>
          <Td className="align-middle text-center">{user.phoneNumber}</Td>
          <Td className="align-middle text-center">{t(status[user.status])}</Td>
          <Td className="align-middle">{t(isSuperUser[user.isSuperUser])}</Td>
        </Tr>
      )
    })
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div className="table-responsive mb-0">
                  <Table
                    id="main-table"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th className="table-data">{t("Name")}</Th>
                        <Th className="table-data">{t("Surname")}</Th>
                        <Th className="table-data">{t("Email")}</Th>
                        <Th className="table-data">{t("Phone Number")}</Th>
                        <Th className="table-data">{t("Status")}</Th>
                        <Th className="table-data">{t("User Type")}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>{Users}</Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(UsersTable)
