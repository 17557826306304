const AuthButton = ({
  children,
  onClick,
  className,
  disabled = false,
  submitLoading,
  submitText,
}) => {
  return (
    <button
      className={`py-3 ${className} `}
      onClick={onClick}
      disabled={disabled || submitLoading}
    >
      {submitLoading ? (
        <>
          <span className="mr-2">{submitText}</span>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </>
      ) : (
        children
      )}
    </button>
  )
}

export default AuthButton
