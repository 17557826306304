import React from "react"
import Layout from "./Layout"
import styles from "./register.module.scss"
import { useTranslation } from "react-i18next"
import { Col, Card, CardBody, Button } from "reactstrap"
import { TiTick } from "react-icons/ti"
import { Link } from "react-router-dom"

import registrationIcon from "../../../assets/images/registration.png"
import digitalMarketingIcon from "../../../assets/images/shopping-online.png"
import partnerIcon from "../../../assets/images/partner.png"
import formIcon from "../../../assets/images/form.png"
import customerServiceIcon from "../../../assets/images/customer-service.png"
import profitIcon from "../../../assets/images/financial-profit.png"

export default function Register() {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  return (
    <Layout>
      <div className={styles.wrapper}>
        <main className="container">
          <div className={styles.hero}>
            <Col lg={7} md={12}>
              <div className={styles.textSlide}>
                <div className={locale === "tr" ? "d-none" : "d-block"}>
                  List your
                </div>
                <div className={styles.textWrap}>
                  <div className={styles.text}>
                    <span>{t("your Hotel")}</span>
                    <span>{t("your Villa")}</span>
                    <span>{t("your Holiday House")}</span>
                    <span>{t("your Apartment")}</span>
                    <span>{t("your Bungalow")}</span>
                    <span>{t("your Anything")}</span>
                  </div>
                </div>
                <div>{t("on Vihobook.com")}</div>
              </div>
              <p>
                {t(
                  "Register to reach travelers and tourists around the world"
                )}
              </p>
            </Col>
            <Col lg={5} md={12} className="d-flex flex-column align-items-end">
              <Card className={styles.card}>
                <CardBody className={styles.cardBody}>
                  <h3 className="mb-4">
                    {t("Become a free member now and earn more!")}
                  </h3>
                  <ul>
                    <li>
                      <TiTick className="me-3" size={24} />
                      {t(
                        "Get more interactions with affiliate marketing!"
                      )}
                    </li>
                    <li>
                      <TiTick className="me-3" size={24} />
                      {t(
                        "Tourism Digital Marketing that appeals to the entire Tourism Sector"
                      )}
                    </li>
                    <li>
                      <TiTick className="me-3" size={24} />
                      {t("Ease of receiving payments from Vihobook")}
                    </li>
                    <li>
                      <TiTick className="me-3" size={24} />
                      {t("Registration is free and takes 15 minutes")}
                    </li>
                  </ul>
                  <hr />
                  <Link to="/register-form">
                    <Button color="primary" className="w-100">
                      {t("Get started")}
                    </Button>
                  </Link>
                  <hr />
                  <p className={styles.loginLink}>
                    {t("Already have an account ?")}
                    <Link
                      to="/login"
                      className="ml-2 font-weight-medium text-primary"
                    >
                      {t("Login")}
                    </Link>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </div>
        </main>
      </div>
      <main className="container">
        <div className={styles.features}>
          <h2>{t("Make more profit thanks to your cooperation with Vihobook Digital Marketing")}</h2>
          <div className={styles.gridWrapper}>
            <div className={styles.one}>
              <img src={registrationIcon} alt="registration" />
              <h3>{t("Quick Registration")}</h3>
              <p>
                {t("Set up your facility page in 15 minutes")}
              </p>
            </div>
            <div className={styles.two}>
              <img src={customerServiceIcon} alt="registration" />
              <h3>{t("Customer Service Support")}</h3>
              <p>{t("We offer customer support 7/24")}</p>
            </div>
            <div className={styles.three}>
              <img src={digitalMarketingIcon} alt="registration" />
              <h3>{t("Affiliate Marketing")}</h3>
              <p>{t("Chance to reach more guests")}</p>
            </div>
            <div className={styles.four}>
              <img src={profitIcon} alt="registration" />
              <h3>{t("Secure Payment")}</h3>
              <p>
                {t(
                  "Get guaranteed payouts and fraud protection by Vihobook.com"
                )}
              </p>
            </div>
            <div className={styles.five}>
              <img src={formIcon} alt="registration" />
              <h3>{t("Transparent Commission")}</h3>
              <p>
                {t(
                  "Understand how much you pay for what with transparent commission"
                )}
              </p>
            </div>
            <div className={styles.six}>
              <img src={partnerIcon} alt="registration" />
              <h3>{t("Collaboration Model")}</h3>
              <p>{t("Helping you to provide a high occupancy rate for your business with your cooperation with Vihobook.com")}</p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
