import { Row, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { AiOutlineSearch } from "react-icons/ai"

import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import CustomMultiSelect from "./CustomMultiSelect"
import CustomSingleSelect from "./CustomSingleSelect"
import styles from "./styles.module.scss"

import { useReservationContext } from "../context"

export default function SearchInputs() {
  const { t } = useTranslation()
  const { functions, setters, values } = useReservationContext()
  const { searchHandler } = functions
  const { setStartDate, setEndDate, setStatuses } = setters
  const { startDate, endDate, statuses } = values

  const multiCheckHandler = (clickedItem, items, setItems) => {
    const id = clickedItem.id
    let updatedList = []
    if (id === 0) {
      updatedList = items.map(i => ({
        ...i,
        isChecked: !items[0].isChecked,
      }))
    } else {
      updatedList = items.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked
    setItems(updatedList)
  }

  const multiRenderValueHandler = items => {
    if (items?.length > 0) {
      if (items[0].isChecked) {
        return t("All Checked")
      } else {
        const checkedItemCount = items
          .slice(1)
          .reduce(
            (prev, currentValue) => (currentValue.isChecked ? prev + 1 : prev),
            0
          )
        return checkedItemCount === 0
          ? t("Select...")
          : `${checkedItemCount} ${t("Checked")}`
      }
    } else {
      return ""
    }
  }

  return (
    <Row className={styles.row}>
      {/* DATE INPUTS */}
      <div className={styles.datesContainer}>
        <div className={styles.dateInputContainer}>
          <Label>{t("From")}</Label>
          <CustomDayPicker
            onChange={date => {
              if (date.getTime() > endDate.getTime()) {
                setEndDate(date)
              }
              setStartDate(date)
            }}
            value={startDate}
            disabledDays={[]}
          />
        </div>
        <div className={styles.dateInputContainer}>
          <Label>{t("To")}</Label>
          <CustomDayPicker
            onChange={setEndDate}
            value={endDate}
            disabledDays={[{ before: startDate }]}
          />
        </div>
      </div>
      {/* Status */}
      <CustomMultiSelect
        label="Status"
        items={statuses}
        onChange={clickedItem =>
          multiCheckHandler(clickedItem, statuses, setStatuses)
        }
        renderValue={() => multiRenderValueHandler(statuses)}
        multiple={true}
      />

      {/* SEARCH BTN */}
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          startIcon={<AiOutlineSearch />}
          onClick={() => searchHandler(statuses)}
          className={styles.button}
        >
          {t("Search")}
        </Button>
      </Stack>
    </Row>
  )
}
