import { useState } from "react"
import { useHistory } from "react-router-dom"
import AuthSection from "./AuthSection"
import Login from "./Login"
import RegisterForm from "./RegisterForm"

const Auth = () => {
  const history = useHistory()
  const pathname = history.location.pathname
  const [currentPage, setCurrentPage] = useState(pathname)

  const handlePageChange = () => {
    const nextPage = currentPage === "/login" ? "register" : "login"
    setCurrentPage(nextPage)
    history.push(`/${nextPage}`)
  }

  const flexDirection = currentPage === "/login" ? "row-reverse" : "row"

  return (
    <div
      className={`authPage ${flexDirection} vw-100 vh-100 d-flex `}
    >
      <AuthSection currentPage={currentPage} handleClick={handlePageChange} />
      <div className={`formContainer h-100`}>
        {currentPage === "/login" ? (
          <Login handlePageChange={handlePageChange} />
        ) : (
          <RegisterForm handlePageChange={handlePageChange} />
        )}
      </div>
    </div>
  )
}

export default Auth
