import { BiBuildingHouse } from "react-icons/bi"
import { FaDollarSign } from "react-icons/fa"
import { IoIosListBox } from "react-icons/io"
import { BsCalendar2CheckFill } from "react-icons/bs"

const tourContent = [
  {
    id: 4001,
    label: "Ticket",
    icon: BiBuildingHouse,
    link: "/#",
    items: [
      {
        label: "Ticket Registration",
        link: "/ticket",
      },
      {
        label: "Ticket Photos",
        link: "/ticket-photos",
      },
      {
        label: "Ticket Features",
        link: "/ticket-features",
      },
      {
        label: "Ticket Languages",
        link: "/ticket-languages",
      },
    ],
  },
  {
    id: 4002,
    label: "Policies",
    icon: IoIosListBox,
    link: "/#",
    items: [
      {
        label: "Cancellation Policy",
        link: "/ticket-cancellation-policy",
      },
      {
        label: "Payment Policy",
        link: "/ticket-prepayment-policy",
      },
      {
        label: "Country Policy",
        link: "/country-policy",
      },
      {
        label: "Tax Policy",
        link: "/ticket-kdv-tax-fees",
      },
    ],
  },
  {
    id: 4003,
    label: "Marketing",
    icon: FaDollarSign,
    link: "/#",
    items: [
      {
        label: "Pricing",
        link: "/ticket-pricing",
      },
      {
        label: "Availability Status",
        link: "/ticket-availability",
      },
      {
        label: "Promotions",
        link: "/ticket-promotions",
      },
      {
        label: "Your Promotions",
        link: "/ticket-your-promotions",
      },
    ],
  },
  {
    id: 4004,
    label: "Reservations",
    icon: BsCalendar2CheckFill,
    link: "/#",
    items: [{ label: "Reservations", link: "/ticket-reservations" }],
  },
]

export default tourContent
