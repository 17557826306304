import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"
import styles from "./style.module.scss"

const PropertiesTable = props => {
  const { t } = useTranslation()
  const {
    onSelectTableRow,
    selectedRowId,
    properties,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  let PropertiesComp = properties.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{item.name}</Td>
        <Td className={styles.dataCell}>{item.checkInTime}</Td>
        <Td className={styles.dataCell}>{item.checkOutTime}</Td>
        <Td className={styles.dataCell}>{item.bedroomCount}</Td>
        <Td className={styles.dataCell}>{item.livingRoomCount}</Td>
        <Td className={styles.dataCell}>{item.bathroomCount}</Td>
        <Td className={styles.dataCell}>
          {t(item.hasSwimmingPool ? "Yes" : "No")}
        </Td>
        <Td className={styles.dataCell}>
          {t(item.status ? "Active" : "Inactive")}
        </Td>
        <Td className={styles.dataCell}>{item.address}</Td>
        <Td className={styles.dataCell}>{item.maxAdult}</Td>
        <Td className={styles.dataCell}>{item.maxChild}</Td>
        <Td className={styles.dataCell}>{item.maxCapacity}</Td>
      </Tr>
    )
  })

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0 data-table-container">
                <Table
                  id="main-table"
                  className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th className={styles.tableData}>{t("Name")}</Th>
                      <Th className={styles.tableData}>{t("Check-in Time")}</Th>
                      <Th className={styles.tableData}>
                        {t("Check-out Time")}
                      </Th>
                      <Th className={styles.tableData}>{t("Bedroom Count")}</Th>
                      <Th className={styles.tableData}>
                        {t("Living Room Count")}
                      </Th>
                      <Th className={styles.tableData}>
                        {t("Bathroom Count")}
                      </Th>
                      <Th className={styles.tableData}>
                        {t("Has Swimming Pool")}
                      </Th>
                      <Th className={styles.tableData}>{t("Status")}</Th>
                      <Th className={styles.tableData}>{t("Address")}</Th>
                      <Th className={styles.tableData}>{t("Max. Adult")}</Th>
                      <Th className={styles.tableData}>{t("Max. Child")}</Th>
                      <Th className={styles.tableData}>{t("Max. Capacity")}</Th>
                    </Tr>
                  </Thead>

                  <Tbody>{PropertiesComp}</Tbody>
                </Table>
              </div>
              {hasPagination && (
                <WidgetPagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PropertiesTable
