import React, { useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"
import { formatDate } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

const ExtraServicesTable = props => {
  const [selectedRowId, setSelectedRowId] = useState("")
  const { t } = useTranslation()
  const {
    extraServices,
    onSelectTableRow,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const ExtraServicesRows = extraServices?.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
          setSelectedRowId(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{item.propertyName}</Td>
        <Td className={styles.dataCell}>{item.extraServiceTypeName}</Td>

        <Td className={styles.dataCell}>{formatDate(item?.startDate)}</Td>
        <Td className={styles.dataCell}>{formatDate(item?.endDate)}</Td>

        <Td className={styles.dataCell}>{item?.currencyName}</Td>
        <Td className={styles.dataCell}>{item?.price}</Td>
        <Td className={styles.dataCell}>{item.minAccommodation}</Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className={`table-responsive ${styles.tContainer}`}>
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.th}>{t("Property Name")}</Th>
              <Th className={styles.th}>{t("Extra Service Name")}</Th>
              <Th className={styles.th}>{t("Start Date")}</Th>
              <Th className={styles.th}>{t("End Date")}</Th>
              <Th className={styles.th}>{t("Currency")}</Th>
              <Th className={styles.th}>{t("Amount")}</Th>
              <Th className={styles.th}>{t("Minimum Day")}</Th>
            </Tr>
          </Thead>

          <Tbody>{ExtraServicesRows}</Tbody>
        </Table>
      </div>
      {hasPagination && (
        <WidgetPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default ExtraServicesTable
