import React from "react"
import { Input } from "reactstrap"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

const CheckBoxes = props => {
  const { title, items, itemCheckHandler } = props
  const { t } = useTranslation()

  return (
    <div>
      <h6 className={styles.ratePlanCheckboxTitle}>{t(title)}</h6>
      {items?.map(item => {
        return (
          <div
            key={item.id}
            onClick={() => {
              itemCheckHandler(item.id)
            }}
            className={styles.checkboxWrapper}
          >
            <Input
              type="checkbox"
              className={styles.checkbox}
              name={item.name}
              onChange={() => {}}
              checked={item.isChecked}
            />
            <span>{t(item.name)}</span>
          </div>
        )
      })}
    </div>
  )
}

export default CheckBoxes
