import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from "reactstrap"

import styles from "./styles.module.scss"

export default ({ useFormResult, isAddOperation, property }) => {
  const { register, errors, setValue, watch } = useFormResult
  const { maxAdult, maxChild } = watch()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isAddOperation) {
      const { maxAdult, maxChild, maxCapacity } = property

      setValue("maxAdult", maxAdult)
      setValue("maxChild", maxChild)
      setValue("maxCapacity", maxCapacity)
    }
  }, [])

  useEffect(() => {
    const newMaxAdult = maxAdult || 0
    const newMaxChild = maxChild || 0

    setValue("maxCapacity", +newMaxAdult + +newMaxChild)
  }, [maxAdult, maxChild])
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Capacity")}</CardTitle>
        <hr />
        <Row form>
          <Col md={4} className={` ${errors?.maxAdult && styles.error} mb-3`}>
            <Label>* {t("Max. adult")}</Label>
            <Input
              name="maxAdult"
              innerRef={register}
              invalid={!!errors?.maxAdult}
              type="number"
              step={1}
              min={0}
            />
            {errors?.maxAdult && <p>{t(errors?.maxAdult?.message)}</p>}
          </Col>

          <Col md={4} className={` ${errors?.maxChild && styles.error} mb-3`}>
            <Label>* {t("Max. child")}</Label>
            <Input
              name="maxChild"
              innerRef={register}
              invalid={!!errors?.maxChild}
              type="number"
              step={1}
              min={0}
            />
            {errors?.maxChild && <p>{t(errors?.maxChild?.message)}</p>}
          </Col>
          <Col md={4} className="mb-3">
            <Label>* {t("Max. capacity")}</Label>
            <Input
              name="maxCapacity"
              innerRef={register}
              type="number"
              step={1}
              min={0}
              className="disabled-bg"
              readOnly
            />
            <span className={styles.inputAltNote}>
              {t("Max. number of guests (adults and children)")}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
