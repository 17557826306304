import {
  GET_COMPANY_START,
  GET_COMPANY_SUCCEED,
  API_ERROR,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCEED,
} from "./actionTypes"

const initialState = {
  company: {
    name: "CompanyName",
  },
  error: false,
  errorMessage: "",
  loading: false,
}

const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_COMPANY_START:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case GET_COMPANY_SUCCEED:
      return {
        ...state,
        loading: false,
        error: false,
        company: actions.company,
      }

    case API_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: actions.errorMessage,
        loading: false,
      }

    case UPDATE_COMPANY_START:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case UPDATE_COMPANY_SUCCEED:
      return {
        ...state,
        loading: false,
        error: false,
        company: actions.company,
      }

    default:
      return state
  }
}

export default reducer
