import { Link } from "react-router-dom"
import { map } from "lodash"
import { Media } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useTranslation } from "react-i18next"
import moment from "moment"
import classnames from "classnames"

import customerAvatar from "assets/images/users/customerAvatar.png"
import { useMessagesContext } from "./context"
import styles from "./styles.module.scss"

const Conversations = ({ filteredConversations }) => {
  const { values, setters, functions } = useMessagesContext()
  const { currentConversation_id } = values
  const { setCurrentConversation_id } = setters
  const { setMessagesToRead } = functions
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  return (
    <div className="chat-leftsidebar-nav">
      <div>
        <h5 className="font-size-14 mb-3">{t("Recent")}</h5>
        <PerfectScrollbar className={styles.conversationHeight}>
          <ul className="list-unstyled chat-list">
            {map(filteredConversations, conversation => (
              <li
                key={conversation.id}
                className={classnames({
                  [styles.active]: currentConversation_id == conversation.id,
                })}
              >
                <Link
                  to="#"
                  onClick={() => {
                    setCurrentConversation_id(prev_id => {
                      const prevConversation = filteredConversations.find(
                        i => i.id === prev_id
                      )
                      setMessagesToRead(prevConversation)
                      setMessagesToRead(conversation)

                      return conversation.id
                    })
                  }}
                >
                  <Media>
                    <div className="align-self-center mr-3">
                      <i
                        className={
                          conversation.customerStatus
                            ? "mdi mdi-circle text-success font-size-10"
                            : "mdi mdi-circle font-size-10"
                        }
                      />
                    </div>
                    <div className="align-self-center mr-3">
                      <img
                        src={customerAvatar}
                        className="rounded-circle avatar-xs"
                        alt="Customer avatar"
                      />
                    </div>

                    <Media
                      className={classnames({
                        "overflow-hidden": true,
                        [styles.bold]: conversation?.unreadMessageCount > 0,
                      })}
                      body
                    >
                      <h5 className="text-truncate font-size-14 mb-1">
                        {conversation.name}
                      </h5>
                      <p className="text-truncate mb-0">
                        {conversation.lastMessage}
                      </p>
                    </Media>
                    <div
                      className={classnames({
                        "d-flex flex-column": true,
                        [styles.unreadCount]:
                          conversation?.unreadMessageCount > 0,
                      })}
                    >
                      <p className="font-size-11 mb-0">
                        {conversation.time
                          ? moment(conversation.time).locale(locale).fromNow()
                          : ""}
                      </p>
                      <span className="d-none">
                        {conversation?.unreadMessageCount
                          ? conversation?.unreadMessageCount
                          : ""}
                      </span>
                    </div>
                  </Media>
                </Link>
              </li>
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default Conversations
