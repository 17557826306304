import { useState } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import { Card, CardBody } from "reactstrap"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { getTranslatedOption, formatMoney } from "utils/other"
import { useReservationContext } from "../context"
import styles from "./styles.module.scss"

export default function ExtraServicesSection() {
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  moment.locale(locale)
  const {
    values: { resDetails },
  } = useReservationContext()
  const { reservation, extraServices } = resDetails

  const currencySign = "€"

  if (!reservation) {
    return null
  }

  const el = document.getElementById("extraServices")

  return (
    <section>
      <Card>
        <CardBody>
          <header className={styles.header}>
            <div>
              <h5>{t("Extra Services")}</h5>
              <div>
                <h5>
                  {currencySign}{" "}
                  {formatMoney(reservation?.agentExtraServicePrice, 2)}
                </h5>
                <div
                  className={styles.control}
                  onClick={() => setIsOpen(prev => !prev)}
                >
                  {isOpen ? (
                    <RiArrowUpSLine size={23} />
                  ) : (
                    <RiArrowDownSLine size={23} />
                  )}
                </div>
              </div>
            </div>
          </header>
          <article
            className={styles.article}
            style={isOpen && el ? { maxHeight: el.clientHeight + 200 } : {}}
          >
            <TableContainer component={Paper} id="extraServices">
              <Table>
                <TableHead>
                  <TableRow className={styles.headRow}>
                    <TableCell>{t("Service Name")}</TableCell>
                    <TableCell>{t("Price")}</TableCell>
                    <TableCell>{t("Quantity")}</TableCell>
                    <TableCell>{t("Subtotal")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraServices?.map(item => (
                    <TableRow className={styles.bodyRow} key={item.id}>
                      <TableCell>
                        {getTranslatedOption(item, "label", locale)}
                      </TableCell>

                      <TableCell>
                        {currencySign}
                        {formatMoney(item.price, 2)}
                      </TableCell>

                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {currencySign}
                        {formatMoney(item.price * item.quantity, 2)}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow
                    className={styles.lightColor}
                    sx={{ "& > td": { paddingTop: "15px !important" } }}
                  >
                    <TableCell></TableCell>
                    <TableCell colSpan={2}>{t("Total")}</TableCell>
                    <TableCell align="center">
                      {currencySign}
                      {formatMoney(reservation?.agentExtraServicePrice, 2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </article>
        </CardBody>
      </Card>
    </section>
  )
}
