import { useState, useRef, useEffect } from "react"
import { IoIosArrowForward } from "react-icons/io"
import { NavLink, useHistory } from "react-router-dom"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import styles from "./sidebar.module.scss"

const SidebarItem = ({
  id,
  Icon,
  link,
  label,
  unreadMessageCount,
  items,
  level1ItemClickHandler,
  level1ClickedItemId,
}) => {
  const { t } = useTranslation()
  const level2MenuRef = useRef(null)
  const history = useHistory()
  const pathname = history.location.pathname
  const [level2Height, setLevel2Height] = useState(0)

  const menuItemClickHandler = e => {
    const hasLevel2Menu = items && items.length > 0
    if (hasLevel2Menu) {
      e.preventDefault()
    }
    level1ItemClickHandler(id)
  }

  useEffect(() => {
    const height = level2MenuRef.current?.scrollHeight
    if (height > 0 && level2Height === 0) {
      setLevel2Height(height)
    }
  }, [level2Height])

  const hasLevel2Menu = items && items.length > 0
  const openLevel2 = id === level1ClickedItemId
  const isChildActive = items?.some(i => i.link === pathname)

  const totalUnreadMessageCount =
    (unreadMessageCount?.masterUnreadMessageCount || 0) +
    (unreadMessageCount?.resUnreadMessageCount || 0)

  return (
    <li className={styles.menuItem}>
      <NavLink
        to={link}
        onClick={menuItemClickHandler}
        className={classNames({
          [styles.activeMenuItem]:
            pathname === link || openLevel2 || isChildActive,
        })}
      >
        <div>
          <span className={styles.icon}>
            <Icon size={18} />
          </span>
          <label>
            {t(label)}{" "}
            {totalUnreadMessageCount > 0 && (
              <span className={styles.unreadMessageCount}>
                <span>{totalUnreadMessageCount}</span>
              </span>
            )}
          </label>
        </div>
        {hasLevel2Menu && (
          <span
            className={classNames({
              [styles.arrow]: true,
              [styles.rotateArrow]: openLevel2,
            })}
          >
            <IoIosArrowForward />
          </span>
        )}
      </NavLink>
      {hasLevel2Menu && (
        <ul
          className={styles.subMenu}
          ref={level2MenuRef}
          style={{ height: openLevel2 ? level2Height : 0 }}
        >
          {items.map((item, index) => (
            <SubItem
              key={item.label}
              item={item}
              isMandatory={item?.isMandatory}
              badge={
                index === 0 && unreadMessageCount?.resUnreadMessageCount > 0
                  ? unreadMessageCount.resUnreadMessageCount
                  : index === 1 &&
                    unreadMessageCount?.masterUnreadMessageCount > 0
                  ? unreadMessageCount.masterUnreadMessageCount
                  : ""
              }
            />
          ))}
        </ul>
      )}
    </li>
  )
}

export default SidebarItem

const SubItem = ({ item, badge, isMandatory }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pathname = history.location.pathname
  return (
    <li
      className={classNames({
        [styles.subMenuItem]: true,
        [styles.subMenuItemActive]: pathname === item.link,
      })}
    >
      <NavLink to={item.link}>
        {badge > 0 && (
          <span className={styles.badge}>
            <span>{badge}</span>
          </span>
        )}
        {isMandatory && <span className={styles.mandatory}></span>}
        {t(item.label)}
      </NavLink>
    </li>
  )
}
