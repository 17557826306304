import { MdVilla } from "react-icons/md"
import { FaDollarSign } from "react-icons/fa"
import { IoIosListBox } from "react-icons/io"
import {
  BsCalendar2CheckFill,
  BsGraphUp,
  BsCardChecklist,
} from "react-icons/bs"
import { AiOutlineApartment } from "react-icons/ai"

const villaContent = [
  {
    id: 2001,
    label: "Property",
    icon: MdVilla,
    link: "/#",
    items: [
      { label: "Property Registration", link: "/property-registration" },
      { label: "Property Categories", link: "/property-categories" },
      { label: "Property Features", link: "/property-features" },
      { label: "Photos", link: "/property-photos" },
      { label: "Property Languages", link: "/property-languages" },
      { label: "What's nearby?", link: "/property-place-distances" },
    ],
  },
  {
    id: 2002,
    label: "Policies",
    icon: IoIosListBox,
    link: "/#",
    items: [
      { label: "Cancellation Policy", link: "/property-cancellation-policy" },
      { label: "Payment Policy", link: "/property-prepayment-policy" },
      { label: "Country Policy", link: "/country-policy" },
      { label: "Guest Info Policy", link: "/property-guest-info-policy" },
      { label: "Tax Policy", link: "/property-kdv-tax-fees" },
    ],
  },
  {
    id: 2003,
    label: "Marketing",
    icon: FaDollarSign,
    link: "/#",
    items: [
      { label: "Overall View", link: "/property-overall-view" },
      { label: "Pricing", link: "/property-pricing" },
      { label: "Promotions", link: "/property-promotions" },
      { label: "Your Promotions", link: "/property-your-promotions" },
      { label: "Property Availability", link: "/property-availability" },
      { label: "Damage Deposit", link: "/property-damage-deposit" },
      { label: "Extra Services", link: "/property-extra-services" },
      { label: "Your Extra Services", link: "/property-your-extra-services" },
    ],
  },
  {
    id: 2004,
    label: "Opportunities",
    icon: BsGraphUp,
    link: "/#",
    items: [
      { label: "Influencer Marketing", link: "/property-influencer-marketing" },
      {
        label: "Search Engine Marketing",
        link: "/property-search-engine-marketing",
      },
      {
        label: "Social Network Marketing",
        link: "/property-social-network-marketing",
      },
      { label: "Your Opportunities", link: "/property-your-opportunities" },
    ],
  },
  {
    id: 2005,
    label: "Reservations",
    icon: BsCalendar2CheckFill,
    link: "/#",
    items: [{ label: "Reservations", link: "/property-reservations" }],
  },
  {
    id: 2006,
    label: "Partners",
    icon: AiOutlineApartment,
    link: "/#",
    items: [
      { label: "Partners", link: "/property-partners" },
      { label: "Your Partners", link: "/property-partners-list" },
    ],
  },
  {
    id: 2007,
    label: "Reports",
    icon: BsCardChecklist,
    link: "/#",
    items: [
      { label: "Property Availability", link: "/reports-availability" },
      { label: "Property Availability 2", link: "/reports-availability2" },
    ],
  },
]

export default villaContent
