import React, { useEffect, useState } from "react"
import { Label, Row, Col } from "reactstrap"
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation-safe"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material"

import { useChildPolicyContext } from "../context"
import styles from "./formBody.module.scss"
import PriceTable from "./PriceTable/index"
import useRequest from "hooks/useRequest"

export default ({ isAddOperation, policy }) => {
  const [, setLoading] = useState(false)
  const [, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const {
    isPercent,
    setIsPercent,
    hotelRoomNames,
    hotelBoardTypes,
    selectedRoomName,
    setSelectedRoomName,
    selectedBoardType,
    setSelectedBoardType,
    currencies,
    selectedCurrency,
    setSelectedCurrency,
    tablesData,
    setTablesData,
    policyName,
    setPolicyName,
    childPolicyAge,
  } = useChildPolicyContext()
  const { t } = useTranslation()

  const prepareTablesData = selectedRoomName => {
    const maxAdult = selectedRoomName.maxAdult

    const newTablesData = Array(maxAdult)
      .fill(0)
      .map((_, idx) => {
        // prepare row by row, first and second child at the same time
        return childPolicyAge.map(i => [
          {
            adultCount: idx + 1,
            childCount: 1,
            childPolicyAge_id: i.id,
            percentOrPrice: 0,
          },
          {
            adultCount: idx + 1,
            childCount: 2,
            childPolicyAge_id: i.id,
            percentOrPrice: 0,
          },
        ])
      })

    setTablesData(newTablesData)
    return newTablesData
  }

  useEffect(() => {
    const fetchChildPolicyDetail = async childPolicy_id => {
      const response = await request({
        url: "/hotel-child-policy-detail",
        params: { childPolicy_id },
      })
      if (response?.dbResult) {
        return response?.dbResult
      }
    }

    // propagate data in edit mode
    ;(async () => {
      if (!isAddOperation) {
        const { id, name, isPercent, room_id, setBoardType_id, currency_id } =
          policy
        const roomName = hotelRoomNames?.find(i => i.room_id === room_id)
        const boardType = hotelBoardTypes?.find(i => i.id === setBoardType_id)
        const selectedCurrency = currencies?.find(i => i.value === currency_id)
        setPolicyName(name)
        setIsPercent(isPercent)
        setSelectedRoomName(roomName)
        setSelectedBoardType(boardType)
        setSelectedCurrency(selectedCurrency)
        // prepare childPolicyDetail
        const details = await fetchChildPolicyDetail(id)
        const newTablesData = [...prepareTablesData(roomName)]

        details.forEach(i => {
          const tblData = newTablesData[i.adultCount - 1]
          const rowIndex = tblData?.findIndex(
            row => row[0]?.childPolicyAge_id === i.childPolicyAge_id
          )
          if (rowIndex === undefined) {
            return
          }
          newTablesData[i.adultCount - 1][rowIndex][
            i?.childCount - 1
          ].percentOrPrice = i.percentOrPrice
        })

        setTablesData(newTablesData)
      }
    })()
  }, [])

  useEffect(() => {
    if (selectedRoomName) {
      // reset tables to 0 when inputType is changed
      prepareTablesData(selectedRoomName)
    }
  }, [isPercent])

  useEffect(() => {
    if (selectedRoomName && selectedBoardType) {
      if(selectedCurrency && !isPercent){
        setPolicyName(`${selectedCurrency.label} - ${selectedRoomName?.label} - ${selectedBoardType?.label}`)
      } else {
        setPolicyName(`${selectedRoomName?.label} - ${selectedBoardType?.label}`)
      }
    }
  }, [selectedRoomName, selectedBoardType, selectedCurrency, isPercent])

  const lg = isPercent ? 4 : 3
  return (
    <div className={styles.container}>
      <Row>
        <Col>
          <Label>{t("Input Type")}</Label>
          <RadioGroup
            row
            name="isPercent"
            value={isPercent}
            onChange={e => setIsPercent(e.target.value === "true")}
          >
            <FormControlLabel
              control={<Radio size="small" />}
              label={<Typography variant="body2">{t("Percent")}</Typography>}
              value={true}
              disabled={!isAddOperation}
            />
            <FormControlLabel
              control={<Radio size="small" />}
              label={<Typography variant="body2"> {t("Price")}</Typography>}
              value={false}
              disabled={!isAddOperation}
            />
          </RadioGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={lg}>
          <Label>{t("Room Name")}</Label>
          <Select
            options={hotelRoomNames}
            value={selectedRoomName}
            onChange={selectedRoomName => {
              setSelectedRoomName(selectedRoomName)
              prepareTablesData(selectedRoomName)
            }}
            placeholder={t("Select...")}
            noOptionsMessage={() => t("No Options")}
            className="mb-3"
          />
        </Col>
        <Col lg={lg}>
          <Label>{t("Board Type")}</Label>
          <Select
            options={hotelBoardTypes}
            value={selectedBoardType}
            onChange={selectedBoardType => {
              setSelectedBoardType(selectedBoardType)
            }}
            placeholder={t("Select...")}
            noOptionsMessage={() => t("No Options")}
            className="mb-3"
          />
        </Col>
        <Col lg={lg}>
          <AvGroup className={styles.disabledInput}>
            <Label>{t("Policy Name")}</Label>
            <AvInput
              name="policyName"
              required
              type="text"
              autoComplete="off"
              onChange={e => setPolicyName(e.target.value)}
              value={policyName}
              disabled
            />
            <AvFeedback>{t("Please fill in the blanks!")}</AvFeedback>
          </AvGroup>
        </Col>
        {!isPercent && (
          <Col lg={lg}>
            <Label>{t("Currency")}</Label>
            <Select
              options={currencies}
              value={selectedCurrency}
              onChange={setSelectedCurrency}
              placeholder={t("Select...")}
              noOptionsMessage={() => t("No Options")}
            />
          </Col>
        )}
      </Row>

      {selectedRoomName.value && (
        <>
          <ul className={styles.maxList}>
            <li>
              {t("Max. adult")}: {selectedRoomName.maxAdult}
            </li>
            <li>
              {t("Max. child")}: {selectedRoomName.maxChild}
            </li>
            <li>
              {t("Max. capacity")}: {selectedRoomName.maxCapacity}
            </li>
          </ul>
          {tablesData.map((item, idx) => (
            <Row key={idx}>
              <PriceTable data={item} />
            </Row>
          ))}
        </>
      )}
    </div>
  )
}
