import React from "react"
import { Card, CardBody, Input, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

export default function MarketingPlace(props) {
  const { marketingPlaces, marketingPlaceCheckHandler } = props
  const { t } = useTranslation()
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{t("Marketing Place")}</h5>
        <Row className={styles.marketingPlaces}>
          {marketingPlaces?.map(item => (
            <div
              key={item.id + "id"}
              onClick={() => marketingPlaceCheckHandler(item.id)}
              className={styles.singleCheckbox}
            >
              <Input
                type="checkbox"
                className={styles.input}
                name={item.name}
                id={item.id}
                onChange={() => {}}
                checked={item.isChecked || false}
              />
              <span>{item.name}</span>
            </div>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}
