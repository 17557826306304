import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning/index"
import CustomSwitch from "components/Common/CustomSwitch/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

const HotelPetPolicy = () => {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [policies_id, setPolicies_id] = useState("")
  const [hotel_id, setHotel_id] = useState("")
  const [arePetsAllowed, setArePetsAllowed] = useState(false)
  const [arePetsPaid, setArePetsPaid] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      const hotel_id = response.dbResult.id
      setHotel_id(hotel_id)
      return hotel_id
    }
  }
  const fetchHotel_PetPolicy = async hotel_id => {
    const response = await request(
      {
        url: "/hotel-pet-policies",
        params: { hotel_id },
      },
      false
    )

    if (response.dbResult) {
      const { id, arePetsAllowed, arePetsPaid } = response.dbResult
      setPolicies_id(id)
      setArePetsAllowed(arePetsAllowed)
      setArePetsPaid(arePetsPaid)
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setLoading(false)
        setShowHotelNotRegWarning(true)
        return
      }
      await fetchHotel_PetPolicy(hotel_id)
      setLoading(false)
    })()
  }, [])

  const submitHandler = async () => {
    let data = {
      arePetsAllowed,
      arePetsPaid,
      hotel_id,
    }

    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }

    setSubmitLoading(true)
    let method = ""
    if (policies_id) {
      data = { ...data, hotel_id, id: policies_id }
      method = "put"
    } else {
      method = "post"
    }

    const response = await request(
      {
        url: "/hotel-pet-policies",
        method,
        data,
      },
      false
    )
    setSubmitLoading(false)

    if (response?.result) {
      setPolicies_id(response.dbResult.id)
      setShowSuccessAlert(true)
    } else {
      console.log(errorMessage)
    }
  }
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Pet Policy")}
        />
        {loading && <CustomSpinner color="primary" />}
        <div className={loading ? "d-none" : ""}>
          <Card>
            <CardBody>
              <h4 className={styles.title}>{t("Pet Policy")}</h4>
              <p className={styles.subtitle}>
                {t(
                  "You can define the general rules that you have determined in your facility, such as pet acceptance, in the system in line with the information requested in this field."
                )}
              </p>
              <CustomSwitch
                label="Do you accept pets?"
                className={styles.customSwitch}
                onClick={() => {
                  setArePetsAllowed(prevState => !prevState)
                  {
                    arePetsAllowed && setArePetsPaid(false)
                  }
                }}
                value={arePetsAllowed}
              />
              <CustomSwitch
                label="Do you want an extra fee for the pet?"
                className={styles.customSwitch}
                onClick={() => {
                  setArePetsPaid(prevState => !prevState)
                }}
                value={arePetsPaid}
              />
            </CardBody>
          </Card>
          {HotelNotRegisteredComp}
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Row className={styles.btnContainer}>
            <Button color="primary" onClick={submitHandler}>
              {submitLoading ? (
                <Spinner color="white" size="sm" />
              ) : (
                t("Submit")
              )}
            </Button>
          </Row>
        </div>
      </Container>
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default HotelPetPolicy
