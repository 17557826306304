import React from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation-safe"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert"
import Checkboxes from "components/Hotel/Marketing/Checkboxes/Checkboxes"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

// fns
import {
  useExtraServicesContext,
  ExtraServicesProvider,
} from "./extraServices-context"

const ExtraServices = () => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))
  const { t } = useTranslation()
  const history = useHistory()
  const { values, setters, functions } = useExtraServicesContext()

  const {
    submitHandler,
    cancelHandler,
    villaCheckHandler,
    currencyCheckHandler,
    extraServicesCheckHandler,
  } = functions

  const {
    fromDate,
    toDate,
    villas,
    currencies,
    extraServices,
    villaRegError,
    showSuccessAlert,
    submitLoading,
    loading,
    error,
  } = values
  const { setFromDate, setToDate, setVillaRegError, setShowSuccessAlert } =
    setters

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Marketing")}
          breadcrumbItem={t("Extra Services")}
        />
        <AvForm onValidSubmit={submitHandler}>
          <Card>
            <CardBody className={styles.cardBody}>
              {loading ? (
                <CustomSpinner color="primary" />
              ) : (
                <>
                  <h4 className={styles.title}>
                    {t(
                      "Indicate your valid extra services on the dates you have specified."
                    )}
                  </h4>
                  <div className={styles.row}>
                    {/* DATE INPUTS */}
                    <div className={styles.datesContainer}>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("From")}</Label>
                        <CustomDayPicker
                          onChange={date => {
                            setFromDate(date)
                            if (date.getTime() > toDate.getTime()) {
                              setToDate(date)
                            }
                          }}
                          value={fromDate}
                          disabledDays={[]}
                        />
                      </div>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("To")}</Label>
                        <CustomDayPicker
                          onChange={setToDate}
                          value={toDate}
                          disabledDays={[{ before: fromDate }]}
                        />
                      </div>
                    </div>

                    <Checkboxes
                      label="Villa"
                      items={villas}
                      onCheck={villaCheckHandler}
                    />
                    <Checkboxes
                      label="Extra Services"
                      items={extraServices}
                      onCheck={extraServicesCheckHandler}
                    />
                    {country_id === 107 && (
                      <Checkboxes
                        label="Currency"
                        items={currencies}
                        onCheck={currencyCheckHandler}
                      />
                    )}
                  </div>
                  {/* PRICING */}
                  <div className={styles.row}>
                    {/*  PRICING BOX */}
                    <div className={styles.pricingBox}>
                      <Label className={styles.pricingBoxLabel}>
                        {t("Price")}
                      </Label>
                      <AvGroup className={styles.avGroup}>
                        <div>
                          <AvInput
                            name="price"
                            className={styles.priceInput}
                            type="number"
                            min={1}
                            required
                          />
                          <AvFeedback>
                            {t("Please fill in the blanks!")}
                          </AvFeedback>
                        </div>
                        <span className={styles.priceCurrency}>{currencies.find(i => i.isChecked)?.name}</span>
                      </AvGroup>
                    </div>
                  </div>

                  {/* MIN DAY */}
                  <div className={styles.minDayWrapper}>
                    <Label className={styles.minDayLabel}>
                      {t("Minimum Day")}
                    </Label>
                    <p className="mb-0">
                      {t(
                        "If the guests stay under the minimum number of days, extra services are activated."
                      )}
                    </p>
                    <div className={styles.minDayRow}>
                      {/* input */}
                      <div className="d-flex">
                        <AvGroup className="mb-0">
                          <AvInput
                            name="minAccommodation"
                            className={styles.priceInput}
                            type="number"
                            min={1}
                            required
                          />
                          <AvFeedback>
                            {t("Please fill in the blanks!")}
                          </AvFeedback>
                        </AvGroup>
                        <span className="mt-2 ml-1">{t("day")}</span>
                      </div>
                      {/* /input */}

                      <div className={styles.checkboxWrapper}>
                        <AvInput
                          id="isCompulsory"
                          name="isCompulsory"
                          type="checkbox"
                        />
                        <Label for="isCompulsory">
                          {t("Is that compulsory?")}{" "}
                        </Label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {error && (
                <Alert color="danger" className={styles.alert}>
                  {error}
                </Alert>
              )}
            </CardBody>
          </Card>

          <Row className={styles.buttons}>
            <Button size="md" type="button" onClick={cancelHandler}>
              {t("Cancel")}
            </Button>
            <Button size="md" color="primary">
              {t("Submit")} {submitLoading && <Spinner size="sm" />}
            </Button>
          </Row>
        </AvForm>
      </Container>
      {villaRegError && (
        <SweetAlert
          warning
          title={t("Warning!")}
          confirmBtnText={t("OK")}
          onConfirm={() => {
            setVillaRegError(false)
            history.push("/property-registration")
          }}
        >
          {t("Please first register a villa!")}
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            cancelHandler()
          }}
        />
      )}
    </main>
  )
}

const ExtraServicesWithProvider = () => {
  return (
    <ExtraServicesProvider>
      <ExtraServices />
    </ExtraServicesProvider>
  )
}

export default ExtraServicesWithProvider
