import React from "react"
import { Row, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { AiOutlineSearch } from "react-icons/ai"
import { useSelector } from "react-redux"

import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import { useVillaOverallViewContext } from "../context"
import CustomMultiSelect from "./CustomMultiSelect"
import CustomSingleSelect from "./CustomSingleSelect"
import styles from "./styles.module.scss"

export default function SearchInputs() {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  const { t } = useTranslation()
  const { setters, values, functions } = useVillaOverallViewContext()
  const { searchHandler } = functions
  const {
    setFromDate,
    setToDate,
    setVillas,
    setCurrencies,
    setMarketingPlaces,
  } = setters
  const { fromDate, toDate, villas, currencies, marketingPlaces } = values

  const multiCheckHandler = (clickedItem, items, setItems) => {
    const id = clickedItem.id
    let updatedList = []
    if (id === 0) {
      updatedList = items?.map(i => ({
        ...i,
        isChecked: !items[0].isChecked,
      }))
    } else {
      updatedList = items?.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked
    setItems(updatedList)
  }

  const multiRenderValueHandler = items => {
    if (items.length > 0) {
      if (items[0].isChecked) {
        return t("All Checked")
      } else {
        const checkedItemCount = items
          .slice(1)
          .reduce(
            (prev, currentValue) => (currentValue.isChecked ? prev + 1 : prev),
            0
          )
        return checkedItemCount === 0
          ? t("Select...")
          : `${checkedItemCount} ${t("Checked")}`
      }
    } else {
      return ""
    }
  }

  const singleCheckHandler = (clickedItem, items, setItems) => {
    setItems(
      items?.map(i =>
        i.id === clickedItem.id
          ? { ...i, isChecked: true }
          : { ...i, isChecked: false }
      )
    )
  }

  const singleRenderValueHandler = items => {
    const checkedItem = items.find(i => i.isChecked)
    return checkedItem ? checkedItem.name : t("Select...")
  }

  return (
    <Row className={styles.row}>
      {/* DATE INPUTS */}
      <div className={styles.datesContainer}>
        <div className={styles.dateInputContainer}>
          <Label>{t("From")}</Label>
          <CustomDayPicker
            onChange={date => {
              if (date.getTime() > toDate.getTime()) {
                setToDate(date)
              }
              setFromDate(date)
            }}
            value={fromDate}
            disabledDays={[]}
          />
        </div>
        <div className={styles.dateInputContainer}>
          <Label>{t("To")}</Label>
          <CustomDayPicker
            onChange={setToDate}
            value={toDate}
            disabledDays={[{ before: fromDate }]}
          />
        </div>
      </div>
      {/* ROOMS */}
      <CustomMultiSelect
        label="Properties"
        items={villas}
        onChange={clickedItem =>
          multiCheckHandler(clickedItem, villas, setVillas)
        }
        renderValue={() => multiRenderValueHandler(villas)}
        multiple={true}
      />
      {/* CURRENCIES */}
      {country_id === 107 && (
        <CustomSingleSelect
          label="Currency"
          items={currencies}
          onChange={clickedItem =>
            singleCheckHandler(clickedItem, currencies, setCurrencies)
          }
          renderValue={() => singleRenderValueHandler(currencies)}
        />
      )}
      {/* MarketingPlace */}
      {marketingPlaces.length > 1 && (
        <CustomSingleSelect
          label="Marketing Place"
          items={marketingPlaces}
          onChange={clickedItem =>
            singleCheckHandler(clickedItem, marketingPlaces, setMarketingPlaces)
          }
          renderValue={() => singleRenderValueHandler(marketingPlaces)}
        />
      )}
      {/* SEARCH BTN */}
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          startIcon={<AiOutlineSearch />}
          onClick={() => searchHandler(villas, currencies, marketingPlaces)}
        >
          {t("Search")}
        </Button>
      </Stack>
    </Row>
  )
}
