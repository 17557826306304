import React, { useState, useRef, useEffect } from "react"
import { Modal, Alert, Spinner } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation-safe"
import { useTranslation } from "react-i18next"

import RoomInfo from "./RoomInfo"
import RoomCapacity from "./RoomCapacity"
import RoomBedInfo from "./RoomBedInfo/index"
import RoomDescription from "./RoomDescription"
import SuccessAlert from "components/Common/SuccessAlert/index"

import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import styles from "./styles.module.scss"

export default props => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [defaultValues, setDefaultValues] = useState({})
  const { request } = useRequest(setLoading, setErrorMessage)
  const childRoomInfo = useRef()
  const childRoomBedInfo = useRef()
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()
  const { isOpen, close, modalTitle, isAddOperation, hotel_id } = props
  const { t } = useTranslation()

  useEffect(() => {
    // propagate default values
    if (isAddOperation) {
      setDefaultValues({
        extraBedGiveable: false,
        extraChildBedGiveable: false,
      })
    } else {
      const room = props.room
      setDefaultValues({
        ...room,
        extraBedGiveable: room.extraBed,
        extraChildBedGiveable: room.extraChildBed,
      })
    }
  }, [])

  const onValidSubmit = (event, data) => {
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    setErrorMessage("")
    const { selectedRoomName, selectedRoomType, roomStatus } =
      childRoomInfo?.current?.getState()
    const { selectedBedTypes } = childRoomBedInfo?.current?.getState()

    if (
      !selectedRoomName?.value ||
      !selectedRoomType?.value ||
      !selectedBedTypes.length > 0
    ) {
      setErrorMessage(t("Please fill in the blanks!"))
      return
    }

    const selectedRoomBedTypes = selectedBedTypes.map(i => ({
      setRoomBedType_id: i.value,
      count: i.count,
      hotel_id,
    }))

    data = {
      ...data,
      extraBed: data.extraBedGiveable,
      extraChildBed: data.extraChildBedGiveable,
      setRoomName_id: selectedRoomName.value,
      setRoomType_id: selectedRoomType.value,
      status: roomStatus,
      selectedRoomBedTypes,
      hotel_id,
      id: isAddOperation ? "" : props.room.id,
    }
    submitData(data)
  }

  const submitData = async data => {
    const response = await request({
      url: "/hotel-rooms",
      method: isAddOperation ? "post" : "put",
      data,
    })
    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {loading && (
          <div className="spinner-background">
            <Spinner size="lg" color="primary" className="spinner" />
          </div>
        )}
        <AvForm onValidSubmit={onValidSubmit} model={defaultValues}>
          <div className={styles.rowsContainer}>
            <RoomInfo ref={childRoomInfo} {...props} />
            <RoomCapacity
              defaultValues={defaultValues}
              isAddOperation={isAddOperation}
            />
            <RoomBedInfo ref={childRoomBedInfo} {...props} />
            <RoomDescription />
          </div>
          {errorMessage && <Alert color="danger"> {t(errorMessage)}</Alert>}
          {HotelNotRegisteredComp}

          {showSuccessAlert && (
            <SuccessAlert
              hideSuccessAlert={() => {
                setShowSuccessAlert(false)
                props.refreshTable()
                props.close()
              }}
            />
          )}

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={close}>
              {t("Close")}
            </button>

            <button type="submit" className="btn btn-primary">
              {loading ? (
                <>
                  <Spinner size="sm" /> {t("Loading...")}{" "}
                </>
              ) : (
                t("Save")
              )}
            </button>
          </div>
        </AvForm>
      </div>
    </Modal>
  )
}
