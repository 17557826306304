import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"
import Select from "react-select"

import { useGuestInfoContext } from "./context"
import styles from "./styles.module.scss"

const AgeSection = () => {
  const { t } = useTranslation()
  const { values, setters } = useGuestInfoContext()
  const { hasAgeLimit, ages, selectedMinAge, selectedMaxAge } = values
  const { setHasAgeLimit, setSelectedMinAge, setSelectedMaxAge } = setters
  return (
    <section className={styles.section}>
      <h4>{t("Age Limit")}</h4>
      {/* FIRST Q */}
      <div className={styles.questionContainer}>
        <p>{t("Is there an age limit to check in?")}</p>
        <div className={styles.buttons}>
          <label htmlFor="ageLimitYes">
            <input
              id="ageLimitYes"
              type="radio"
              name="hasAgeLimit"
              checked={hasAgeLimit === true}
              value={hasAgeLimit}
              onChange={() => setHasAgeLimit(true)}
            />
            {t("Yes")}
          </label>
          <label htmlFor="ageLimitNo">
            <input
              id="ageLimitNo"
              type="radio"
              name="hasAgeLimit"
              value={hasAgeLimit}
              checked={hasAgeLimit === false}
              onChange={() => setHasAgeLimit(false)}
            />
            {t("No")}
          </label>
        </div>
      </div>
      {hasAgeLimit && (
        <div className={styles.row}>
          <div className={styles.col}>
            <Label className="mb-1">{t("Minimum Age")}</Label>
            <Select
              placeholder={t("Select...")}
              value={selectedMinAge}
              noOptionsMessage={() => t("No Options")}
              options={ages}
              onChange={selectedMinAge => {
                setSelectedMinAge(selectedMinAge)
                if (selectedMinAge.value > selectedMaxAge?.value) {
                  setSelectedMaxAge(ages[ages.length - 1])
                }
              }}
            />
          </div>
          <div className={styles.col}>
            <Label className="mb-1">{t("Maximum Age")}</Label>
            <Select
              placeholder={t("Select...")}
              value={selectedMaxAge}
              noOptionsMessage={() => t("No Options")}
              options={ages.filter(i => i.value > selectedMinAge.value)}
              onChange={setSelectedMaxAge}
            />
          </div>
        </div>
      )}
    </section>
  )
}

export default AgeSection
