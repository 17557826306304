import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert"
import Checkboxes from "components/Hotel/Marketing/Checkboxes/Checkboxes"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

// custom hooks
import useRequest from "hooks/useRequest"
import useVillaNotRegWarn from "hooks/useVillaNotRegisteredWarn"
import { addDays, getTodayDate } from "utils/dateTimeFunctions"
import { getLabelByLanguage, all } from "utils/other"

const PropertyPromotions = () => {
  const [startDate, setStartDate] = useState(getTodayDate())
  const [endDate, setEndDate] = useState(addDays(getTodayDate(), 7))
  const [villas, setVillas] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [availabilityStatus, setAvailabilityStatus] = useState(true)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)
  const { VillaNotRegisteredComp, setShowVillaNotRegWarning } =
    useVillaNotRegWarn()
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchCompanyVillas = async () => {
      const response = await request(
        {
          url: "/property/names",
        },
        false
      )

      const villas = response?.properties?.map(i => ({
        ...i,
        isChecked: false,
      }))
      setVillas(villas)

      if (villas.length === 0) {
        setShowVillaNotRegWarning(true)
      }
    }
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        id: i.setMarketingPlace_id,
        name: i.setMarketingPlaceName,
        isChecked: false,
      }))

      marketingPlaces.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setMarketingPlaces(marketingPlaces)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchCompanyVillas(),
        fetchSetMarketingPlaces(),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (marketingPlaces?.length > 0) {
      // translate 'all'
      const newMarketingPlaces = [...marketingPlaces]
      newMarketingPlaces[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newMarketingPlaces)
    }
  }, [locale])

  // CHECK HANDLERS
  const villasCheckHandler = id => {
    const updatedVillas = villas.map(i => {
      if (id === i.id) {
        return { ...i, isChecked: !i.isChecked }
      } else {
        return { ...i, isChecked: false }
      }
    })
    setVillas(updatedVillas)
  }

  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const submitHandler = e => {
    e.preventDefault()
    setError("")

    const submitData = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/property-availability",
          method: "put",
          data: payload,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    const property_id = villas.find(i => i.isChecked)?.id
    const setMarketingPlace_ids = marketingPlaces
      ?.filter(i => i.isChecked && i.id !== 0)
      ?.map(i => i.id)

    if (!property_id) {
      return setError(t("Please select a villa!"))
    }
    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    const payload = {
      property_id,
      startDate,
      endDate,
      status:
        typeof availabilityStatus === "string"
          ? availabilityStatus === "true"
          : availabilityStatus,
      setMarketingPlace_ids,
    }

    submitData(payload)
  }
  const cancelHandler = () => {
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setVillas(villas.map(i => ({ ...i, isChecked: false })))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setAvailabilityStatus(true)
    setError(null)
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Property")}
          breadcrumbItem={t("Property Availability")}
        />
        <Card>
          <CardBody className={styles.cardBody}>
            {loading ? (
              <CustomSpinner color="primary" />
            ) : (
              <>
                <div className={styles.row}>
                  <h4 className={styles.title}>
                    {t(
                      "You can turn the availability on and off on Vihobook channels on the dates you have determined to sell your facility."
                    )}
                  </h4>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.datesContainer}>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("From")}</Label>
                        <CustomDayPicker
                          onChange={date => {
                            setStartDate(date)
                            if (date.getTime() > endDate.getTime()) {
                              setEndDate(date)
                            }
                          }}
                          value={startDate}
                          disabledDays={[]}
                        />
                      </div>
                      <div className={styles.dateInputContainer}>
                        <Label>{t("To")}</Label>
                        <CustomDayPicker
                          onChange={setEndDate}
                          value={endDate}
                          disabledDays={[{ before: startDate }]}
                        />
                      </div>
                    </div>
                    {/* OPEN CLOSE RADIO BUTTONS */}
                    <div className={styles.box}>
                      <Label className={styles.boxLabel}>
                        {t("Identify selected villas as")}:
                      </Label>
                      <RadioGroup
                        row
                        onChange={(e, v) => setAvailabilityStatus(v)}
                        value={availabilityStatus}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <Typography className={styles.radioLabel}>
                              {t("Open")}
                            </Typography>
                          }
                          value={true}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <Typography className={styles.radioLabel}>
                              {t("Closed")}
                            </Typography>
                          }
                          value={false}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <Checkboxes
                    label={t("Villas")}
                    items={villas}
                    onCheck={villasCheckHandler}
                  />
                  {marketingPlaces.length > 2 && (
                    <Checkboxes
                      label={t("Marketing Places")}
                      items={marketingPlaces}
                      onCheck={marketingPlaceCheckHandler}
                    />
                  )}
                </div>
              </>
            )}
            {error && (
              <Alert color="danger" className={styles.alert}>
                {error}
              </Alert>
            )}
          </CardBody>
        </Card>

        <Row className={styles.buttons}>
          <Button size="md" type="button" onClick={cancelHandler}>
            {t("Cancel")}
          </Button>
          <Button size="md" color="primary" onClick={submitHandler}>
            {t("Submit")} {submitLoading && <Spinner size="sm" />}
          </Button>
        </Row>
      </Container>
      {VillaNotRegisteredComp}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            cancelHandler()
          }}
        />
      )}
    </main>
  )
}

export default PropertyPromotions
