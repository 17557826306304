import { Nav, NavItem, NavLink, Progress } from "reactstrap"
import styles from "./styles.module.scss"

const NavButtons = props => {
  const progressValue = (100 / props.features.length) * (props.activeLink + 1)
  return (
    <section className={styles.container}>
      <Nav className={styles.nav}>
        {props.features.map((item, idx) => {
          const num = (parseInt(idx, 10) + 101).toString().substr(1)
          const active = props.activeLink === idx ? styles.active : ""
          return (
            <NavItem
              key={item.id}
              className={[styles.navItem, active].join(" ")}
              onClick={() => props.onLinkClick(idx)}
            >
              <NavLink className={styles.navLink}>
                <span className={styles.num}>{num}</span>
                <span>{item.featureTitle}</span>
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <Progress
        value={progressValue}
        color="success"
        striped
        className="my-3"
      />
    </section>
  )
}

export default NavButtons
