import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import vihobookLogo from "assets/images/vBlueLogo.png"

import Page1 from "./Page1"
import Page2 from "./Page2"

function RegisterForm({ handlePageChange }) {
  const [page1Data, setPage1Data] = useState({})
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
      <form
        className={`registerForm d-flex flex-column justify-content-center align-items-center`}
      >
        <img src={vihobookLogo} alt="Vihobook Logo" className={`logo`} />
        {page === 1 ? (
          <Page1 setPage={setPage} setPage1Data={setPage1Data} />
        ) : (
          <Page2 page1Data={page1Data} setPage={setPage} />
        )}
      </form>
      <div className={`already mt-2`}>
        <p className="text-center" onClick={handlePageChange}>
          {t("Already have an account?")}
        </p>
      </div>
    </div>
  )
}
export default RegisterForm
