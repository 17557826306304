import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Alert,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import CustomSpinner from "components/Common/CustomSpinner/index"
import Table from "./Table/index"
import styles from "./styles.module.scss"
import { useSelector } from "react-redux"

import useRequest from "hooks/useRequest"

const itemsCountPerPage = 10
const PropertyYourPartners = () => {
  let email = useSelector(state => state.Company)

  const [statu, setStatu] = useState(true)
  const [SendStatu, setSendStatu] = useState(false)
  const [partners, setPartners] = useState([])
  const [selectedRowId, setSelectedRowId] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(10)

  const [showWarnMsg, setShowWarnMsg] = useState(false)
  const [showWarnDeactivateMsg, setShowWarnDeactivateMsg] = useState(false)
  const [showSuccessDeactivateMsg, setShowSuccessDeactivateMsg] =
    useState(false)
  const [showWarnActiveMsg, setShowWarnActiveMsg] = useState(false)
  const [showSuccessActiveMsg, setShowSuccessActiveMsg] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const history = useHistory()

  useEffect(() => {
    const fetchCompanyPartners = async email => {
      const response = await request({
        url: "/property-partners-companies/fetch-by-page-number",
        params: {
          statu,
          SendStatu,
          page: activePage,
          limit: itemsCountPerPage,
          email,
        },
      })

      setPartners(response?.dbResult[0])
      setTotalItemsCount(response?.dbResult[1][0]?.totalItemsCount)
    }
    ;(async () => {
      await Promise.allSettled([fetchCompanyPartners(email)])
    })()
  }, [statu, SendStatu, activePage])

  const navigateToPartnersPage = () => {
    history.push("/property-partners")
  }

  const approve = async () => {
    setShowWarnActiveMsg(false)
    setErrorMessage(false)
    const response = await request({
      url: "/property-partners-companies/activate",
      method: "put",
      data: { id: selectedRowId },
    })
    if (response?.result) {
      setShowSuccessActiveMsg(true)
    }
  }

  const deactivate = async () => {
    setShowWarnDeactivateMsg(false)
    setErrorMessage(false)
    const response = await request({
      url: "/property-partners-companies/deactivate",
      method: "put",
      data: { id: selectedRowId },
    })

    if (response?.result) {
      setShowSuccessDeactivateMsg(true)
    }
  }
  return (
    <main className="page-content">
      <Container fluid className={styles.container}>
        <Row className={styles.firstRow}>
          <h4 style={{ fontSize: "18px" }}>{t("Your Partners")}</h4>
        </Row>
        <Row className={styles.secondRow}>
          <p className={styles.subtitle}>
            {t("Availability pending list for you to view.")}
          </p>
          <Button color="primary" onClick={navigateToPartnersPage}>
            {t("Create New Partners")}
          </Button>
        </Row>
        <Row className={styles.fourthRow}>
          <Col>
            <Row className={styles.buttons}>
              <ButtonGroup>
                <Button
                  color={
                    statu === true && SendStatu === false
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => {
                    setStatu(true)
                    setSendStatu(false)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Pending Approval")}
                </Button>
                <Button
                  color={
                    statu === true && SendStatu === true
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => {
                    setStatu(true)
                    setSendStatu(true)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Actives")}
                </Button>
                <Button
                  color={
                    statu === false && SendStatu === false
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => {
                    setStatu(false)
                    setSendStatu(false)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Passives")}
                </Button>
              </ButtonGroup>
              {statu === true && SendStatu === false && (
                <Button
                  color="success"
                  onClick={() => {
                    if (!selectedRowId) {
                      setShowWarnMsg(true)
                    } else {
                      setShowWarnActiveMsg(true)
                    }
                  }}
                >
                  {t("Approve")}
                </Button>
              )}
              {statu === true && SendStatu === true && (
                <Button
                  color="danger"
                  onClick={() => {
                    if (!selectedRowId) {
                      setShowWarnMsg(true)
                    } else {
                      setShowWarnDeactivateMsg(true)
                    }
                  }}
                >
                  {t("Deactivate")}
                </Button>
              )}
            </Row>
          </Col>
        </Row>

        {errorMessage && (
          <Alert color="danger" className="mt-2">
            {t(errorMessage)}
          </Alert>
        )}
        <Row className={styles.lastRow}>
          <Card className={styles.card}>
            <CardBody>
              {loading ? (
                <CustomSpinner />
              ) : (
                <Table
                  partners={partners}
                  //   opportunityTypes={opportunityTypes}
                  onSelectTableRow={setSelectedRowId}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemsCountPerPage={itemsCountPerPage}
                  onPageChange={setActivePage}
                  hasPagination={true}
                  setShowWarnMsg={setShowWarnMsg}
                  setShowWarnActiveMsg={setShowSuccessActiveMsg}
                />
              )}
            </CardBody>
          </Card>
        </Row>
      </Container>
      {showWarnMsg && (
        <SweetAlert
          warning
          title={t("Please select a row!")}
          onConfirm={() => {
            setShowWarnMsg(false)
          }}
          confirmBtnText={t("OK")}
        />
      )}
      <SweetAlert
        show={showWarnActiveMsg}
        warning
        showCancel
        confirmBtnText={t("Yes, activate it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="success"
        title={t("Are you sure?")}
        onConfirm={approve}
        onCancel={() => {
          setShowWarnActiveMsg(false)
        }}
        focusCancelBtn
      >
        {t("You will not be able to recover it!")}
      </SweetAlert>

      <SweetAlert
        show={showSuccessActiveMsg}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Activeted!")}
        onConfirm={() => {
          // fetchCompanyPartners(email)
          setShowSuccessActiveMsg(false)
        }}
      >
        {t("The partner is activated successfully!")}
      </SweetAlert>
      <SweetAlert
        show={showWarnDeactivateMsg}
        warning
        showCancel
        confirmBtnText={t("Yes, deactivate it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="danger"
        title={t("Are you sure?")}
        onConfirm={deactivate}
        onCancel={() => {
          setShowWarnDeactivateMsg(false)
        }}
        focusCancelBtn
      >
        {t("You will not be able to recover it!")}
      </SweetAlert>

      <SweetAlert
        show={showSuccessDeactivateMsg}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Deactivated!")}
        onConfirm={() => {
          setShowSuccessDeactivateMsg(false)
        }}
      >
        {t("The opportunity is deactivated successfully!")}
      </SweetAlert>
    </main>
  )
}

export default PropertyYourPartners
