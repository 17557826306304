import { createContext, useContext, useEffect, useState } from "react"
import io from "socket.io-client"
import { useSelector } from "react-redux"

const SocketContext = createContext()

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    return new Error("The component must be wrapped by the SocketProvider!")
  }

  return context
}

export const SocketProvider = props => {
  const { company_id, token } = useSelector(state => ({
    company_id: state.Company.company.id,
    token: state.Login.token,
  }))

  const [socket, setSocket] = useState(null)
  const [unreadMessageCount, setUnreadMessageCount] = useState(null)

  useEffect(() => {
    let newSocket
    if (!socket && !!company_id) {
      newSocket = io(process.env.REACT_APP_API_URL, {
        query: { company_id, token: `Bearer ${token}` },
      })

      setSocket(newSocket)
      newSocket.on("unread-message-count", data => {
        setUnreadMessageCount(data.unreadMessageCount)
      })
    }
    return () => {
      setSocket(null)
      newSocket?.close()
    }
  }, [company_id])

  const value = { socket, unreadMessageCount }
  return <SocketContext.Provider value={value} {...props} />
}
