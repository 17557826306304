import React from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { AvForm, AvGroup } from "availity-reactstrap-validation-safe"
import { Label, Input, Row, Col, FormGroup, Alert, Spinner } from "reactstrap"

import CustomDropZone from "./CustomDropZone"
import PhoneInput from "components/Common/PhoneInput/index"

const CustomForm = props => {
  const { t } = useTranslation()

  return (
    <>
      <div className="modal-body">
        <AvForm autoComplete="off">
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="name">* {t("Company Name")}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  autoComplete="off"
                  value={props.name}
                  onChange={event => props.setName(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="title">{t("Title")}</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder=""
                  autoComplete="off"
                  value={props.title}
                  onChange={event => props.setTitle(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">* {t("Email")}</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  autoComplete="off"
                  value={props.email}
                  onChange={event => props.setEmail(event.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <PhoneInput
                phoneNumber={props.telephoneNumber || ""}
                onChange={props.setTelephoneNumber}
                label={`* ${t("Phone Number")}`}
              />
            </Col>
          </Row>
          <Row form>
            {/* COUNTRY */}
            <Col md={6}>
              <AvGroup className="select2-container">
                <Label>* {t("Country")}</Label>
                <Select
                  value={props.selectedCountry}
                  onChange={selectedCountry => {
                    props.fetchProvinces(selectedCountry)
                    props.setSelectedCountry(selectedCountry)
                    props.setSelectedProvince("")
                    props.setSelectedDistrict("")
                  }}
                  options={props.countries}
                  noOptionsMessage={() => t("No Options")}
                  classNamePrefix="select2-selection"
                  placeholder={t("Select...")}
                />
              </AvGroup>
            </Col>

            {/* PROVINCE */}
            <Col md={6}>
              <AvGroup className="select2-container">
                <Label>* {t("Province")}</Label>
                <Select
                  value={props.selectedProvince}
                  onChange={selectedProvince => {
                    props.fetchDistricts(selectedProvince)
                    props.setSelectedProvince(selectedProvince)
                    props.setSelectedDistrict("")
                  }}
                  options={props.provinces}
                  noOptionsMessage={() => t("No Options")}
                  classNamePrefix="select2-selection"
                  placeholder={t("Select...")}
                />
              </AvGroup>
            </Col>
          </Row>

          <Row form>
            {/* DISTRICT */}
            <Col md={6}>
              <AvGroup className="select2-container">
                <Label>* {t("District")}</Label>
                <Select
                  value={props.selectedDistrict}
                  onChange={props.setSelectedDistrict}
                  options={props.districts}
                  noOptionsMessage={() => t("No Options")}
                  classNamePrefix="select2-selection"
                  placeholder={t("Select...")}
                />
              </AvGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address1">{t("Address1")}</Label>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder=""
                  autoComplete="off"
                  value={props.address1}
                  onChange={event => props.setAddress1(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="address2">{t("Address2")}</Label>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder=""
                  autoComplete="off"
                  value={props.address2}
                  onChange={event => props.setAddress2(event.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="taxAdministration">{t("Tax Administration")}</Label>
                <Input
                  type="text"
                  name="taxAdministration"
                  id="taxAdministration"
                  placeholder=""
                  autoComplete="off"
                  value={props.taxAdministration}
                  onChange={event =>
                    props.setTaxAdministration(event.target.value)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="taxNumber">{t("Tax Number")}</Label>
                <Input
                  type="text"
                  name="taxNumber"
                  id="taxNumber"
                  placeholder=""
                  autoComplete="off"
                  value={props.taxNumber}
                  onChange={event => props.setTaxNumber(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <CustomDropZone
            setLogoRef={props.setLogoRef}
            logoRef={props.company.logoRef}
          />

          {props.errorMessage && (
            <Alert color="danger"> {t(props.errorMessage)}</Alert>
          )}
        </AvForm>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={props.saveHandler}
          >
            {t("Save")} {props.saveLoading && <Spinner size="sm" />}
          </button>
        </div>
      </div>
    </>
  )
}

export default CustomForm
