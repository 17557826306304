import React from "react"
import { Card, CardBody } from "reactstrap"
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

export default function DiscountRate(props) {
  const { discountRate, setDiscountRate } = props
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("How much of a discount do you want to give?")}
        </h5>
        <div className={styles.discountRate}>
          <p>{t("Select a discount amount between 1% and 99%")}</p>

          <FormControl className={styles.formControl}>
            <InputLabel htmlFor="outlined-adornment-amount">
              {t("Amount")}
            </InputLabel>
            <OutlinedInput
              className={styles.outlinedInput}
              value={discountRate}
              onChange={e => setDiscountRate(e.target.value)}
              startAdornment={
                <InputAdornment position="start">%</InputAdornment>
              }
              label="Amount"
            />
          </FormControl>
        </div>
      </CardBody>
    </Card>
  )
}
