import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert"
import Checkboxes from "components/Hotel/Marketing/Checkboxes/Checkboxes"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

// custom hooks
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

// fns
import {
  getLabelByLanguage,
  initialApplicationDays_fullName,
  all,
} from "utils/other"
import {
  formatToServerDate,
  addDays,
  getTodayDate,
} from "utils/dateTimeFunctions"

export default function RoomAvailabilityStatus() {
  const [hotel_id, setHotel_id] = useState(null)
  const [fromDate, setFromDate] = useState(getTodayDate())
  const [toDate, setToDate] = useState(addDays(getTodayDate(), 7))
  const [roomNames, setRoomNames] = useState([])
  const [boardTypes, setBoardTypes] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [applicationDays, setApplicationDays] = useState(
    initialApplicationDays_fullName
  )
  const [roomRegError, setRoomRegError] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [availabilityStatus, setAvailabilityStatus] = useState(true)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    // FETCH FUNCTIONS
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )

      if (response?.dbResult) {
        const hotel_id = response?.dbResult?.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }

    const fetchRoomNames = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-room-names",
          params: { hotel_id },
        },
        false
      )
      const roomNames = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
        isChecked: false,
      }))

      if (roomNames.length === 0) {
        setRoomRegError(true)
        return
      }
      roomNames.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setRoomNames(roomNames)
    }

    const fetchBoardTypes = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-board-types/fetch-names-by-hotel-id",
          params: { hotel_id },
        },
        false
      )
      const boardTypes = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
        isChecked: false,
      }))
      boardTypes.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setBoardTypes(boardTypes)
    }

    const fetchCompanyMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        id: i.setMarketingPlace_id,
        name: i.setMarketingPlaceName,
        isChecked: false,
      }))

      marketingPlaces.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })

      setMarketingPlaces(marketingPlaces)
    }
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      await Promise.allSettled([
        fetchRoomNames(hotel_id),
        fetchBoardTypes(hotel_id),
        fetchCompanyMarketingPlaces(),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setRoomNames(
      roomNames.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )
    setBoardTypes(
      boardTypes.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )
    setApplicationDays(applicationDays.map(i => ({ ...i, name: t(i.name_en) })))

    // translate all to hepsi on locale change
    if (marketingPlaces?.length > 2) {
      let newMarketingPlaces = [...marketingPlaces]
      newMarketingPlaces[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newMarketingPlaces)
    }
  }, [locale])

  // CHECK HANDLERS
  const roomNamesCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = roomNames.map(i => ({
        ...i,
        isChecked: !roomNames[0].isChecked,
      }))
    } else {
      updatedList = roomNames.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked

    setRoomNames(updatedList)
  }

  const boardTypesCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = boardTypes.map(i => ({
        ...i,
        isChecked: !boardTypes[0].isChecked,
      }))
    } else {
      updatedList = boardTypes.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked
    setBoardTypes(updatedList)
  }

  const marketingPlacesCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked

    setMarketingPlaces(updatedList)
  }

  const applicationDaysCheckHandler = id => {
    const updatedApplicationDays = applicationDays.map(i => {
      if (id === i.id) {
        return { ...i, isChecked: !i.isChecked }
      } else {
        return i
      }
    })
    setApplicationDays(updatedApplicationDays)
  }

  // it generates dates between two date and exclude unchecked application days
  const generateDateBetween = (startDate, endDate) => {
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    const excludedDays = applicationDays.filter(i => !i.isChecked)
    const excludedDaysIndexes = excludedDays.map(i => i.id)
    let day = 1000 * 60 * 60 * 24
    let diff = (endDate.getTime() - startDate.getTime()) / day
    let dateList = []
    for (let i = 0; i <= diff; i++) {
      let newDate = startDate.getTime() + day * i
      newDate = new Date(newDate)
      let isExcluded = false
      for (let j = 0; j < excludedDaysIndexes.length; j++) {
        if (excludedDaysIndexes[j] === newDate.getDay()) {
          isExcluded = true
          break
        }
      }
      if (!isExcluded) dateList.push(formatToServerDate(newDate))
    }
    return dateList
  }

  const cancelHandler = () => {
    setFromDate(getTodayDate())
    setToDate(addDays(getTodayDate(), 7))
    setRoomNames(roomNames.map(i => ({ ...i, isChecked: false })))
    setBoardTypes(boardTypes.map(i => ({ ...i, isChecked: false })))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setApplicationDays(applicationDays.map(i => ({ ...i, isChecked: true })))
    setError(null)
    setAvailabilityStatus(true)
  }

  const submitHandler = async e => {
    e.preventDefault()
    setError(null)
    // prepare data
    const dates = generateDateBetween(fromDate, toDate)
    // take roomIds
    const roomIds = roomNames.slice(1).reduce((filtered, roomName) => {
      if (roomName.isChecked) {
        filtered.push(roomName.room_id)
      }
      return filtered
    }, [])
    // take boardTypes
    const boardTypeIds = boardTypes.slice(1).reduce((filtered, boardType) => {
      if (boardType.isChecked) {
        filtered.push(boardType.id)
      }
      return filtered
    }, [])

    let marketingPlace_ids = marketingPlaces
      .slice(1)
      .reduce((filtered, item) => {
        if (item.isChecked) {
          filtered.push(item.id)
        }
        return filtered
      }, [])

    if (roomIds.length === 0) {
      return setError(t("Please select a room type!"))
    }
    if (boardTypeIds.length === 0) {
      return setError(t("Please select a board type!"))
    }
    if (marketingPlaces.length === 2) {
      marketingPlace_ids = [1]
    } else if (marketingPlace_ids?.length === 0) {
      return setError(t("Please select a marketing place!"))
    }

    const payload = []
    dates.forEach(date => {
      roomIds.forEach(room_id => {
        boardTypeIds.forEach(setBoardType_id => {
          marketingPlace_ids.forEach(setMarketingPlace_id =>
            payload.push({
              date,
              room_id,
              setBoardType_id,
              setMarketingPlace_id,
              status:
                typeof availabilityStatus === "string"
                  ? availabilityStatus === "true"
                  : availabilityStatus,
              hotel_id,
            })
          )
        })
      })
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-room-availability-status",
        method: "patch",
        data: { payload },
      },
      false
    )

    setSubmitLoading(false)

    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Marketing")}
          breadcrumbItem={t("Availability Status")}
        />
        <form onSubmit={submitHandler}>
          <Card>
            <CardBody className={styles.cardBody}>
              {loading ? (
                <CustomSpinner color="primary" />
              ) : (
                <>
                  <div className={styles.row}>
                    <div className={styles.datePriceContainer}>
                      <div className={styles.datesContainer}>
                        <div className={styles.dateInputContainer}>
                          <Label>{t("From")}</Label>
                          <CustomDayPicker
                            onChange={date => {
                              setFromDate(date)
                              if (date.getTime() > toDate.getTime()) {
                                setToDate(date)
                              }
                            }}
                            value={fromDate}
                            disabledDays={[]}
                          />
                        </div>
                        <div className={styles.dateInputContainer}>
                          <Label>{t("To")}</Label>
                          <CustomDayPicker
                            onChange={setToDate}
                            value={toDate}
                            disabledDays={[{ before: fromDate }]}
                          />
                        </div>
                      </div>
                      {/*  PRICING BOX */}
                      <div className={styles.pricingBox}>
                        <Label className={styles.pricingBoxLabel}>
                          {t("Identify selected rooms as")}:
                        </Label>
                        <RadioGroup
                          row
                          onChange={(e, v) => setAvailabilityStatus(v)}
                          value={availabilityStatus}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography className={styles.radioLabel}>
                                {t("Open")}
                              </Typography>
                            }
                            value={true}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <Typography className={styles.radioLabel}>
                                {t("Closed")}
                              </Typography>
                            }
                            value={false}
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <Checkboxes
                      label="Room Types"
                      items={roomNames}
                      onCheck={roomNamesCheckHandler}
                    />
                    <Checkboxes
                      label="Board Types"
                      items={boardTypes}
                      onCheck={boardTypesCheckHandler}
                    />
                    {marketingPlaces?.length > 2 && (
                      <Checkboxes
                        label="Marketing Places"
                        items={marketingPlaces}
                        onCheck={marketingPlacesCheckHandler}
                      />
                    )}
                    <Checkboxes
                      label="Application Days"
                      items={applicationDays}
                      onCheck={applicationDaysCheckHandler}
                    />
                  </div>
                </>
              )}
              {error && (
                <Alert color="danger" className={styles.alert}>
                  {error}
                </Alert>
              )}
            </CardBody>
          </Card>

          <Row className={styles.buttons}>
            <Button size="md" type="button" onClick={cancelHandler}>
              {t("Cancel")}
            </Button>
            <Button size="md" color="primary">
              {t("Submit")} {submitLoading && <Spinner size="sm" />}
            </Button>
          </Row>
        </form>
      </Container>
      {HotelNotRegisteredComp}
      {roomRegError && (
        <SweetAlert
          warning
          title={t("Warning!")}
          confirmBtnText={t("OK")}
          onConfirm={() => {
            setRoomRegError(false)
            history.push("/hotel-room-registration")
          }}
        >
          {t("Please first register a room!")}
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            cancelHandler()
          }}
        />
      )}
    </main>
  )
}
