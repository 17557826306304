import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Row, Col, Label } from "reactstrap"
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation-safe"

import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

const BottomInputs = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [types, setTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [roomCount, setRoomCount] = useState("")

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const fetchSetCategories = async () => {
    const response = await request(
      {
        url: "/hotel-set-categories",
      },
      false
    )

    const categories = response?.dbResult.map(item => ({
      ...item,
      label: item.name_en,
      value: item.id,
    }))
    setCategories(categories)
  }

  const fetchSetTypes = async () => {
    const response = await request(
      {
        url: "/hotel-set-types",
      },
      false
    )

    const types = response?.dbResult.map(item => ({
      ...item,
      label: item.name_en,
      value: item.id,
    }))
    setTypes(types)
  }

  const fetchData = async () => {
    setLoading(true)
    const responses = await Promise.allSettled([
      fetchSetCategories(),
      fetchSetTypes(),
    ])
    setLoading(false)
    return responses
  }

  useEffect(() => {
    const updatedTypes = types?.map(item => {
      let name = ""
      switch (locale) {
        case "tr":
          name = item.name_tr
          break
        case "fr":
          name = item.name_fr
          break
        case "de":
          name = item.name_de
          break
        case "ar":
          name = item.name_ar
          break
        case "ru":
          name = item.name_ru
          break
        default:
          name = item.name_en
          break
      }
      return {
        ...item,
        label: name,
      }
    })
    const type = updatedTypes.find(i => i?.value === selectedType?.value)
    setSelectedType(type)
    setTypes(updatedTypes)
  }, [locale, types?.length])

  useEffect(() => {
    const updatedCategories = categories?.map(item => {
      let name = ""
      switch (locale) {
        case "tr":
          name = item.name_tr
          break
        case "fr":
          name = item.name_fr
          break
        case "de":
          name = item.name_de
          break
        case "ar":
          name = item.name_ar
          break
        case "ru":
          name = item.name_ru
          break
        default:
          name = item.name_en
          break
      }
      return {
        ...item,
        label: name,
      }
    })
    const category = updatedCategories.find(
      i => i?.value === selectedCategory?.value
    )
    setSelectedCategory(category)
    setCategories(updatedCategories)
  }, [locale, categories?.length])

  const getState = () => {
    return { selectedCategory, selectedType }
  }

  const setState = values => {
    const selectedCategory = categories?.find(
      i => i.value === values.setCategory_id
    )
    const selectedType = types?.find(i => i.value === values.setType_id)
    setSelectedCategory(selectedCategory)
    setSelectedType(selectedType)
    setRoomCount(values.roomCount)
  }

  useImperativeHandle(ref, () => ({ getState, setState, fetchData }))

  return (
    <Card>
      <CardBody>
        <Col xl={8} className="px-0">
          <Row className={styles.bottomInputContainer}>
            <Col xl={4} lg={4} md={6}>
              <Label for="facilityCategory">* {t("Facility Category")}</Label>
              <Select
                value={selectedCategory}
                onChange={setSelectedCategory}
                options={categories}
                placeholder={t("Select...")}
                noOptionsMessage={() => t("No Options")}
                menuPortalTarget={document.querySelector("body")}
              />{" "}
            </Col>
            <Col xl={4} lg={4} md={6}>
              <Label for="facilityType">* {t("Facility Type")}</Label>
              <Select
                value={selectedType}
                onChange={setSelectedType}
                options={types}
                placeholder={t("Select...")}
                noOptionsMessage={() => t("No Options")}
                menuPortalTarget={document.querySelector("body")}
              />
            </Col>

            <Col xl={4} lg={4} md={6}>
              <AvGroup>
                <Label for="roomCount">* {t("Room Count")}</Label>
                <AvInput
                  name="roomCount"
                  id="roomCount"
                  type="number"
                  min={0}
                  step={1}
                  required
                  autoComplete="off"
                  value={roomCount}
                  onChange={(e, value) => setRoomCount(value)}
                />
                <AvFeedback>{t("Please enter a valid number!")}</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
        </Col>
      </CardBody>
    </Card>
  )
})

export default BottomInputs
