import { Row, Col, Card, CardBody, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import { useReservationContext } from "./context"
import { formatDate, formatDateTime } from "utils/dateTimeFunctions"
import { formatMoney, getTranslatedOption, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

const InfoSection = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const { values } = useReservationContext()
  const { resDetails } = values
  const { reservation, children } = resDetails

  const currencySign = getCurrencySignById(2)
  const commission = formatMoney(
    (reservation?.agentTotalPrice * reservation?.commissionRate) / 100,
    2
  )

  const passengerFullName = reservation?.resOnBehalf
    ? `${reservation?.name2} ${reservation?.surname2}`
    : `${reservation?.name} ${reservation?.surname}`

  const email = reservation?.resOnBehalf
    ? reservation?.email2
    : reservation?.email
  const phone = reservation?.resOnBehalf
    ? reservation?.phoneNo2
    : reservation?.phoneNo

  return (
    <section>
      <Card>
        <CardBody className={styles.infoContainer}>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Vehicle Type"}
                value={
                  reservation &&
                  getTranslatedOption(reservation, "vehicleType", locale)
                }
                className={styles.boldValue}
              />
              <InfoItem
                label={"Transfer Date"}
                value={formatDateTime(reservation?.transferDateTime)}
                className={styles.boldValue}
              />
              <InfoItem
                label={"Pickup Location"}
                value={
                  reservation &&
                  getTranslatedOption(reservation, "pickupLocation", locale)
                }
              />
              <InfoItem
                label={"Drop Off Location"}
                value={
                  reservation &&
                  getTranslatedOption(reservation, "dropOffLocation", locale)
                }
              />
              <InfoItem
                label={"Total Guest Count"}
                value={
                  `${reservation?.adultCount} ${t("adult")}` +
                  ` ${
                    reservation?.childCount > 0
                      ? `, ${reservation?.childCount} ${t("child")} (${children
                          ?.map(i => i.childAge)
                          ?.join(", ")} ${t("years old")})`
                      : ""
                  }`
                }
              />

              <InfoItem
                label={"Vehicle Count"}
                value={reservation?.vehicleCount}
              />
              <InfoItem label={"Note"} value={reservation?.note} />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Reservation Number"}
                value={reservation?.resNo}
                className={styles.boldValue}
              />

              <InfoItem
                label={"Passenger Name"}
                value={passengerFullName}
                className={styles.boldValue}
              />
              <div className={styles.link}>
                <a href={`mailto:${email}`}>{email}</a> <br />
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
              {reservation?.resOnBehalf && (
                <>
                  <InfoItem
                    label={"Booker"}
                    value={`${reservation.name} ${reservation.surname}`}
                    className={styles.boldValue}
                  />
                  <div className={styles.link}>
                    <a href={`mailto:${reservation.email}`}>
                      {reservation.email}
                    </a>
                    <br />
                    <a href={`tel:${reservation.phoneNo}`}>
                      {reservation.phoneNo}
                    </a>
                  </div>
                </>
              )}
              <InfoItem
                label={"Marketing Place"}
                value={reservation?.marketingPlaceName}
              />

              <InfoItem
                label={"Reservation Date"}
                value={formatDate(reservation?.resDate)}
              />
              <InfoItem
                label={"Reservation Status"}
                value={t(reservation?.resStatus)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Reservation Total Price"}
                value={`${currencySign} ${formatMoney(
                  reservation?.agentTotalPrice,
                  2
                )}`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Commission"}
                value={`${reservation?.commissionRate}% (${currencySign}${commission})`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Payable amount to the facility"}
                value={`${currencySign} ${formatMoney(
                  reservation?.toCompanyPayableAmount,
                  2
                )}`}
                className={styles.boldValue}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  )
}

export default InfoSection

const InfoItem = ({ label, value, className }) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <Label>{t(label)}</Label>
      <p>{value}</p>
    </div>
  )
}
