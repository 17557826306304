import { useTranslation } from "react-i18next"

import CustomCheckbox from "components/Common/CustomCheckbox/index"
import styles from "./styles.module.scss"

const PaymentCheckboxes = ({ title, items, onChecked }) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <p className={styles.title}>{t(title)}</p>
      <div className={styles.checkboxes}>
        {items.map(item => {
          return (
            <CustomCheckbox
              key={item.id}
              id={item.id}
              title={item.title}
              isChecked={item.isChecked}
              onClick={onChecked.bind(null, item.id)}
              // onClick={ ()=>onChecked(item.id)}
              className={styles.customCheckbox}
            />
          )
        })}
      </div>
    </section>
  )
}

export default PaymentCheckboxes
