import axios from "axios"
import React, { useState, useEffect } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import Add_EditModal from "./Add_EditModal/index"
import PropertiesTable from "components/Villa/PropertiesTable/index"
import useRequest from "hooks/useRequest"

const CancelToken = axios.CancelToken
let cancel
const itemsCountPerPage = 13

const PropertyRegistration = () => {
  // default
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState("")
  const [selectedRowData, setSelectedRowData] = useState({})
  const [showWarningMessage, setShowWarningMessage] = useState(false)
  const [showWarnDeleteMsg, setShowWarnDeleteMsg] = useState(false)
  const [showSuccessDeleteMsg, setShowSuccessDeleteMsg] = useState(false)
  const [isAddOperation, setIsAddOperation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  //
  const [properties, setProperties] = useState([])
  const { t } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    const main = async () => {
      await fetchCompanyProperties(1, "")
    }
    main()
  }, [])

  const fetchCompanyProperties = async (page, searchText) => {
    const response = await request({
      url: "/property/fetch-by-page-number",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c
      }),
      params: {
        page,
        limit: itemsCountPerPage,
        searchText,
      },
    })

    setProperties(response?.dbResult[0])
    setTotalItemsCount(response?.dbResult[1][0]?.totalItemsCount)
  }

  const onSelectTableRow = selectedRowId => {
    const selectedRowData = properties.find(item => item.id == selectedRowId)
    setSelectedRowId(selectedRowId)
    setSelectedRowData(selectedRowData)
  }

  const onPageChange = activePage => {
    setActivePage(activePage)
    fetchCompanyProperties(activePage, "")
  }

  const refreshTable = () => {
    setTotalItemsCount(0)
    setActivePage(1)
    fetchCompanyProperties(1, "")
  }

  const deleteProperty = async () => {
    setShowWarnDeleteMsg(false)
    const response = await request({
      url: "/property",
      method: "delete",
      data: { id: selectedRowId },
    })
    if (response?.result) {
      setShowSuccessDeleteMsg(true)
    }
  }

  let Content = null
  if (loading) {
    Content = <Spinner size="lg" color="primary" className="spinner" />
  } else if (errorMessage) {
    Content = (
      <Alert color="danger"> {t(errorMessage.replace("Error: ", ""))}</Alert>
    )
  } else {
    Content = (
      <PropertiesTable
        properties={properties}
        onSelectTableRow={onSelectTableRow}
        selectedRowId={selectedRowId}
        activePage={activePage}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={itemsCountPerPage}
        onPageChange={onPageChange}
        hasPagination={true}
      />
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Property")}
            breadcrumbItem={t("Property Registration")}
          />
          <ControlButtons
            displayAddBtn={true}
            displayEditBtn={true}
            displayDeleteBtn={true}
            onAddBtn={() => {
              setIsModalOpen(true)
              setIsAddOperation(true)
            }}
            onEditBtn={() => {
              if (selectedRowId) {
                setIsModalOpen(true)
                setIsAddOperation(false)
              } else {
                setShowWarningMessage(true)
              }
            }}
            onDeleteBtn={() => {
              if (selectedRowId) {
                setShowWarnDeleteMsg(true)
              } else {
                setShowWarningMessage(true)
              }
            }}
            onPrintBtn={() => {}}
            onPdfBtn={() => {}}
            onExcelBtn={() => {}}
            isApiSearch={true}
            searchAPI={searchText => fetchCompanyProperties(1, searchText)}
          />
          {Content}
        </Container>
      </div>

      {isModalOpen && (
        <Add_EditModal
          modalTitle={isAddOperation ? "Add Property" : "Edit Property"}
          isOpen={true}
          close={() => {
            setIsModalOpen(false)
            setIsAddOperation(false)
            setSelectedRowData({})
            setSelectedRowId("")
          }}
          property={isAddOperation ? {} : selectedRowData}
          refreshTable={refreshTable}
          isAddOperation={isAddOperation}
        />
      )}

      <SweetAlert
        show={showWarningMessage}
        warning
        title={t("Please select a row!")}
        onConfirm={() => {
          setShowWarningMessage(false)
        }}
        confirmBtnText={t("OK")}
      ></SweetAlert>

      <SweetAlert
        show={showWarnDeleteMsg}
        warning
        showCancel
        confirmBtnText={t("Yes, delete it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="danger"
        title={t("Are you sure?")}
        onConfirm={deleteProperty}
        onCancel={() => {
          setShowWarnDeleteMsg(false)
        }}
        focusCancelBtn
      >
        {t("You will not be able to recover it!")}
      </SweetAlert>

      <SweetAlert
        show={showSuccessDeleteMsg}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Deleted!")}
        onConfirm={() => {
          setShowSuccessDeleteMsg(false)
          refreshTable()
        }}
      >
        {t("The row is deleted successfully!")}
      </SweetAlert>
    </React.Fragment>
  )
}

export default PropertyRegistration
