import { createContext, useContext, useState, useMemo } from "react"

const ChildPolicyContext = createContext({
  hotelRoomTypes: [],
  setHotelRoomTypes: hotelRoomTypes => {},
  currencies: [
    { id: 2, value: 2, label: "EUR", name: "EUR" },
  ],
  setCurrencies: currencies => {},
  selectedRoomType: [],
  setSelectedRoomType: selectedRoomType => {},
  selectedCurrency: [],
  setSelectedCurrency: selectedCurrency => {},
  isPercent: false,
  setIsPercent: isPercent => {},
  childPolicyAge: [],
  setChildPolicyAge: childPolicyAge => {},
  tablesData: [],
  setTablesData: tablesData => {},
  childNames: [],
  setChildNames: childNames => {},
  policyName: "",
  setPolicyName: name => {},
})

export const useChildPolicyContext = () => {
  const context = useContext(ChildPolicyContext)

  if (!context) {
    throw new Error("Component should be wrapped by the provider first!")
  }

  return context
}

export const ChildPolicyProvider = props => {
  const [hotelRoomNames, setHotelRoomNames] = useState([])
  const [selectedRoomName, setSelectedRoomName] = useState("")
  const [hotelBoardTypes, setHotelBoardTypes] = useState([])
  const [selectedBoardType, setSelectedBoardType] = useState("")
  const [currencies, setCurrencies] = useState([
    { id: 2, value: 2, label: "EUR", name: "EUR" },
  ])
  const [selectedCurrency, setSelectedCurrency] = useState("")
  const [isPercent, setIsPercent] = useState(true)
  const [childPolicyAge, setChildPolicyAge] = useState([])
  const [tablesData, setTablesData] = useState([])
  const [childNames, setChildNames] = useState([])
  const [policyName, setPolicyName] = useState("")

  const value = useMemo(() => {
    return {
      hotelRoomNames,
      setHotelRoomNames,
      hotelBoardTypes,
      setHotelBoardTypes,
      currencies,
      setCurrencies,
      selectedRoomName,
      setSelectedRoomName,
      selectedBoardType,
      setSelectedBoardType,
      selectedCurrency,
      setSelectedCurrency,
      isPercent,
      setIsPercent,
      childPolicyAge,
      setChildPolicyAge,
      tablesData,
      setTablesData,
      childNames,
      setChildNames,
      policyName,
      setPolicyName,
    }
  }, [
    hotelRoomNames,
    hotelBoardTypes,
    currencies,
    selectedRoomName,
    selectedBoardType,
    selectedCurrency,
    isPercent,
    childPolicyAge,
    tablesData,
    childNames,
    policyName,
  ])
  return <ChildPolicyContext.Provider value={value} {...props} />
}
