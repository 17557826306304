import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import axios from "axios"

import Inputs from "./Inputs"
import Calendar from "./Calendar"
import { addDays } from "utils/dateTimeFunctions"
import Breadcrumbs from "components/Common/Breadcrumb"

class AvailabilityReport extends Component {
  state = {
    years: [],
    selectedYear: "",
    properties: [],
    selectedProperty: "",
    loading: false,
    errorMessage: "",
    availabilityData: [],
  }

  componentDidMount = () => {
    this.getProperties()
    this.setYears()
  }

  setYears = () => {
    const startYear = 2021
    const currentYear = new Date().getFullYear()
    const loopNumber = currentYear - startYear + 5
    const years = []
    let year = startYear
    for (let i = 0; i < loopNumber; i++) {
      years.push({ label: year, value: year })
      year += 1
    }

    this.setState({ years })
  }

  getProperties = async () => {
    try {
      this.setState({ loading: true })

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = `/property/names`
      const response = await axios.get(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
        const properties = response.data.properties.map(item => {
          return { label: item.name, value: item.id }
        })
        this.setState({ properties })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onYearSelect = selectedYear => {
    this.setState({ selectedYear }, this.onSearchBtn)
  }
  onPropertySelect = selectedProperty => {
    this.setState({ selectedProperty }, this.onSearchBtn)
  }

  onSearchBtn = () => {
    const { selectedYear, selectedProperty } = this.state
    if (selectedYear.value && selectedProperty.value) {
      this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      this.setState({ loading: true })
      let { selectedYear, selectedProperty } = this.state

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          property_id: selectedProperty.value,
          year: selectedYear.value,
          setMarketingPlace_id: 1,
        },
      }
      const url = `property-availability/fetch-by-year`
      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({ availabilityData: response.data.dbResult })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      this.setState({ errorMessage: "Something went wrong!!" })
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const {
      years,
      selectedYear,
      properties,
      selectedProperty,
      loading,
      errorMessage,
      availabilityData,
    } = this.state

    const { t } = this.props
    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (errorMessage) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <>
          <Inputs
            properties={properties}
            onPropertySelect={this.onPropertySelect}
            selectedProperty={selectedProperty}
            years={years}
            onYearSelect={this.onYearSelect}
            selectedYear={selectedYear}
          />
        </>
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Reports")}
              breadcrumbItem={t("Property Availability 2")}
            />
            {Content}
            {!loading && (
              <Calendar selectedYear={selectedYear} data={availabilityData} />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(
  connect(mapStateToProps, null)(AvailabilityReport)
)
