import React, { useState, useEffect } from "react"
import { Container, Card, CardBody, CardText, Alert } from "reactstrap"
import { useTranslation } from "react-i18next"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

import Uploader from "./Uploader"
import styles from "./styles.module.scss"

const HotelPhoto = () => {
  const [hotel_id, setHotel_id] = useState("")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  const fetchHotelData = async () => {
    const response = await request({
      url: "/hotel-registration",
    })
    if (response.dbResult) {
      const hotel_id = response.dbResult.id

      setHotel_id(hotel_id)
      return hotel_id
    }
  }

  useEffect(() => {
    ;(async () => {
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        return
      }
    })()
  }, [])

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Hotels")} breadcrumbItem={t("Hotel Photos")} />
        <Card>
          <CardBody>
            {HotelNotRegisteredComp}
            {hotel_id && (
              <>
                <CardText className={styles.cardText}>
                  {t(
                    "In this section, you need to add photos of your facility. You can add and delete the photos at any time. You should add at least 5 photos."
                  )}
                </CardText>
                <Uploader hotel_id={hotel_id} />
              </>
            )}
            {loading && <CustomSpinner color="primary" />}
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </CardBody>
        </Card>
      </Container>
    </main>
  )
}

export default HotelPhoto
