import React, { Component } from "react"
import { withTranslation } from "react-i18next"
const moment = require("moment")
import { Tooltip } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import { withStyles } from "@material-ui/core/styles"

import { generateDateBetween } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

const DarkTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: "6px 12px",
  },
}))(Tooltip)

class Calendar extends Component {
  getClassName = date => {
    const { data } = this.props
    let className = ""
    let title = ""

    const obj = data.find(item => {
      const date1 = new Date(item.date)
      const date2 = new Date(date)
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      )
    })

    // notAvailable
    // notSellable
    // sellable
    // reserved

    if (obj) {
      if (obj.status) {
        if (obj.res_id) {
          className = styles.reserved
          title = "Reserved_"
        } else {
          className = styles.sellable
          title = "Sellable"
        }
      } else {
        className = styles.notSellable
        title = "Not Sellable"
      }
    } else {
      className = styles.notAvailable
      title = "Not Available_"
    }

    return { className, title }
  }
  render() {
    let { selectedYear, i18n, t } = this.props
    selectedYear = selectedYear.value
    const currentLanguage = i18n.language
    moment.locale(currentLanguage)
    const monthNames = moment.months()

    return (
      <div className="availability-calendar">
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t("Month")}</th>
              {new Array(31).fill().map((num, i) => {
                num = i + 1
                return <th key={num}>{num}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {monthNames.map((mName, i) => {
              const firstDayOfMonth = new Date(selectedYear, i, 1)
              const lastDayOfMonth = new Date(selectedYear, i + 1, 0)
              const monthDays = Object.keys(
                generateDateBetween(firstDayOfMonth, lastDayOfMonth)
              )

              return (
                <tr key={i}>
                  <td>{mName}</td>
                  {monthDays.map(date => {
                    const { className, title } = this.getClassName(date)

                    return (
                      <DarkTooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                        title={t(title)}
                        key={date}
                      >
                        <td className={className}>
                          {new Date(date).getDate()}
                        </td>
                      </DarkTooltip>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>

        <table className={styles.table2}>
          <tbody>
            <tr>
              <td>{t("Not Available_")}</td>
              <td className={styles.notAvailable}></td>
            </tr>
            <tr>
              <td>{t("Not Sellable")}</td>
              <td className={styles.notSellable}></td>
            </tr>
            <tr>
              <td>{t("Sellable")}</td>
              <td className={styles.sellable}></td>
            </tr>
            <tr>
              <td>{t("Reserved_")}</td>
              <td className={styles.reserved}></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default withTranslation()(Calendar)
