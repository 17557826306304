import { Row, Col, Card, CardBody, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { useReservationContext } from "./context"
import { calcDaysBetween, formatDate } from "utils/dateTimeFunctions"
import { formatMoney, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

const InfoSection = () => {
  const { t } = useTranslation()
  const { values } = useReservationContext()
  const { resDetails } = values
  const { reservation, childAges, rooms } = resDetails
  const roomCount = rooms?.reduce(
    (counter, room) => counter + room.roomCount,
    0
  )
  const currencySign = getCurrencySignById(reservation?.agentCurrency_id)
  const commission = formatMoney(
    (reservation?.agentTotalPrices * reservation?.commissionRate) / 100,
    2
  )
  return (
    <section>
      <Card>
        <CardBody className={styles.infoContainer}>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Hotel Name"}
                value={reservation?.hotelName}
                className={styles.boldValue}
              />
              <InfoItem
                label={"Check-in Date"}
                value={formatDate(reservation?.checkIn)}
                className={styles.boldValue}
              />
              <InfoItem
                label={"Check-out Date"}
                value={formatDate(reservation?.checkOut)}
                className={styles.boldValue}
              />
              <InfoItem
                label={"Stay Duration"}
                value={
                  calcDaysBetween(reservation?.checkIn, reservation?.checkOut) +
                  " " +
                  t("night")
                }
              />
              <InfoItem
                label={"Total Guest Count"}
                value={
                  `${reservation?.adultCount} ${t("adult")}` +
                  ` ${
                    reservation?.childCount > 0
                      ? `, ${reservation?.childCount} ${t("child")} (${childAges
                          ?.map(i => i.age)
                          ?.join(", ")} ${t("years old")})`
                      : ""
                  }`
                }
              />

              <InfoItem label={"Room Count"} value={roomCount} />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Reservation Number"}
                value={reservation?.resNo}
                className={styles.boldValue}
              />

              <InfoItem
                label={"Guest Name"}
                value={`${reservation?.name} ${reservation?.surname}`}
                className={styles.boldValue}
              />
              <div className={styles.link}>
                <Link to="#">{reservation?.email}</Link>
              </div>
              <InfoItem label={"Phone Number"} value={reservation?.phoneNo} />
              <InfoItem
                label={"Marketing Place"}
                value={reservation?.setMarketingPlaceName}
              />

              <InfoItem
                label={"Reservation Date"}
                value={formatDate(reservation?.resDate)}
              />
              <InfoItem
                label={"Reservation Status"}
                value={t(reservation?.resStatusName)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Reservation Total Price"}
                value={`${currencySign} ${formatMoney(
                  reservation?.agentTotalPrices,
                  2
                )}`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Commission"}
                value={`${reservation?.commissionRate}% (${currencySign}${commission})`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Payable amount to the facility"}
                value={`${currencySign} ${formatMoney(
                  reservation?.agentTotalPrices - commission,
                  2
                )}`}
                className={styles.boldValue}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  )
}

export default InfoSection

const InfoItem = ({ label, value, className }) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <Label>{t(label)}</Label>
      <p>{value}</p>
    </div>
  )
}
