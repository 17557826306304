import React, { Fragment, Component } from "react"
import { Label } from "reactstrap"
import PhoneInputComp from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import styles from "./styles.module.css"

class PhoneInput extends Component {
  render() {
    const { phoneNumber, onChange, label, isDisabled, errors, className } =
      this.props
    return (
      <Fragment>
        <Label for={label}>{label}</Label>
        <PhoneInputComp
          country={"tr"}
          value={phoneNumber}
          onChange={onChange}
          inputProps={{
            required: true,
          }}
          containerClass={`phoneContainer form-group ${className}`}
          inputClass={`phoneInput ${isDisabled ? styles.disabled : ""}`}
          buttonClass="phoneButton"
          disabled={isDisabled}
        />
      </Fragment>
    )
  }
}

export default PhoneInput
