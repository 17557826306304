import { Row } from "reactstrap"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import { useTranslation } from "react-i18next"
import { AiOutlineSearch } from "react-icons/ai"

import CustomSingleSelect from "./CustomSingleSelect"
import styles from "./styles.module.scss"

export default function SearchInputs(props) {
  const { t } = useTranslation()
  const { roomNames, boardTypes, onSearch, setRoomNames, setBoardTypes } = props

  const singleCheckHandler = (clickedItem, items, setItems) => {
    setItems(
      items.map(i =>
        i.id === clickedItem.id
          ? { ...i, isChecked: true }
          : { ...i, isChecked: false }
      )
    )
  }

  const singleRenderValueHandler = items => {
    const checkedItem = items.find(i => i.isChecked)
    return checkedItem ? checkedItem?.name : t("Select...")
  }

  return (
    <Row className={styles.row}>
      <CustomSingleSelect
        label="Room Name"
        items={roomNames}
        onChange={clickedItem =>
          singleCheckHandler(clickedItem, roomNames, setRoomNames)
        }
        renderValue={() => singleRenderValueHandler(roomNames)}
      />

      <CustomSingleSelect
        label="Board Type"
        items={boardTypes}
        onChange={clickedItem =>
          singleCheckHandler(clickedItem, boardTypes, setBoardTypes)
        }
        renderValue={() => singleRenderValueHandler(boardTypes)}
      />

      {/* SEARCH BTN */}
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          startIcon={<AiOutlineSearch />}
          onClick={onSearch}
          className={styles.button}
        >
          {t("Search")}
        </Button>
      </Stack>
    </Row>
  )
}
