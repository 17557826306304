import React from "react"
import { useTranslation } from "react-i18next"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import RenderDay from "./RenderDay"

// Include the locale utils designed for moment
import MomentLocaleUtils from "react-day-picker/moment"

// Make sure moment.js has the required locale data
import "moment/locale/tr"
import "moment/locale/ar"
import "moment/locale/fr"
import "moment/locale/de"
import "moment/locale/ru"

const Calendar = props => {
  const { selectedYear, modifiers, disabledDays } = props
  const locale = useTranslation().i18n.language
  const t = useTranslation().t
  const currentYear = new Date().getFullYear()

  return (
    <div className="villa-availability">
      <DayPicker
        numberOfMonths={12}
        month={new Date(selectedYear || currentYear, 0)}
        canChangeMonth={false}
        disabledDays={null}
        onDayClick={() => {}}
        selectedDays={null}
        modifiers={modifiers}
        onDayMouseEnter={() => {}}
        localeUtils={MomentLocaleUtils}
        locale={locale}
        renderDay={(day, modifiers) => RenderDay(day, modifiers, t)}
      />
    </div>
  )
}

export default Calendar
