import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody } from "reactstrap"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import RoomPlansCheckboxes from "./Checkboxes"
import styles from "./styles.module.scss"

const WhichRooms = props => {
  const { roomNames, setRoomNames, roomNamesCheckHandler } = props

  const [allRoomNames, setAllRoomNames] = useState(true)
  const [isRoomPlanFormOpen, setIsRoomPlanFormOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{t("Which rooms?")}</h5>
        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={allRoomNames}
            onChange={(e, v) => {
              setAllRoomNames(v)
              setRoomNames(roomNames.map(i => ({ ...i, isChecked: true })))
            }}
          >
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("All rooms within all selected rate plans")}
              className={styles.radioLabel}
              onClick={() => {
                setIsRoomPlanFormOpen(false)
              }}
              value={true}
            />

            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("Select rooms")}
              className={styles.radioLabel}
              onClick={() => {
                setIsRoomPlanFormOpen(true)
              }}
              value={false}
            />
          </RadioGroup>
          {isRoomPlanFormOpen && (
            <RoomPlansCheckboxes
              title="Select at least 1 room type"
              isOpen={true}
              items={roomNames}
              itemCheckHandler={roomNamesCheckHandler}
              close={() => {
                setIsRoomPlanFormOpen(false)
              }}
            />
          )}
        </FormControl>
      </CardBody>
    </Card>
  )
}
export default WhichRooms
