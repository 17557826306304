import {
  Container,
  Card,
  CardBody,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConditionInputs from "./ConditionInputs"
import { PrepaymentProvider, usePrepaymentContext } from "./context"
import PolicySummary from "./PolicySummary"
import styles from "./styles.module.scss"

const TicketPrepaymentPolicy = () => {
  const { t } = useTranslation()

  const { values, setters, functions } = usePrepaymentContext()
  const { loading, submitLoading, errorMessage, showSuccessAlert } = values
  const { setShowSuccessAlert } = setters
  const { submitHandler } = functions
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Ticket")} breadcrumbItem={t("Payment Policy")} />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h4 className="font-size-15" style={{ lineHeight: "20px" }}>
              {t(
                "Reservation fees are collected by Vihobook. Specify the conditions under which you want to receive payment by Vihobook."
              )}{" "}
              {t(
                "You cannot change this information after your Agreement has been approved by Vihobook."
              )}
            </h4>
            <ConditionInputs />
            <PolicySummary />
          </CardBody>
        </Card>

        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

const WithProvider = props => {
  return (
    <PrepaymentProvider>
      <TicketPrepaymentPolicy {...props} />
    </PrepaymentProvider>
  )
}
export default WithProvider
