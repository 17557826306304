import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Label,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import ChildPolicyItem from "./ChildPolicyItem"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning/index"
import CustomSwitch from "components/Common/CustomSwitch/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import styles from "./styles.module.scss"

const initialOptions = []
for (let i = 0; i <= 17; i++) {
  initialOptions.push({ label: i, value: i })
}

const HotelChildPolicy = () => {
  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [hotel_id, setHotel_id] = useState("")

  const [isChildAllowed, setIsChildAllowed] = useState(false)
  const [hasChildBed, setHasChildBed] = useState(false)

  const [childNamesOptions, setChildNamesOptions] = useState([])

  const [childPolicies, setChildPolicies] = useState([])
  const [hotelChild_id, setHotelChild_id] = useState("")
  const [showResetWarn, setShowResetWarn] = useState(false)
  const [resetLoading, setResetLoading] = useState(false)
  const [resetSucceed, setResetSucceed] = useState(false)

  const { request } = useRequest(setLoading, setErrorMessage)
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    setChildNamesOptions(
      childNamesOptions.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
      }))
    )
    setChildPolicies(
      childPolicies.map(i => ({
        ...i,
        selectedChildName: {
          ...i.selectedChildName,
          label: getLabelByLanguage(i.selectedChildName, locale),
        },
      }))
    )
  }, [locale])

  const childGroupCheckHandler = id => {
    const childNamesOptionsCopy = [...childNamesOptions]
    const index = childNamesOptionsCopy.findIndex(i => i.id === id)
    childNamesOptionsCopy[index].isChecked =
      !childNamesOptionsCopy[index].isChecked
    setChildNamesOptions(childNamesOptionsCopy)
  }

  const childGroupChangeHandler = (id, value, startOrEnd) => {
    const childNamesOptionsCopy = [...childNamesOptions]
    const index = childNamesOptionsCopy.findIndex(i => i.id === id)
    childNamesOptionsCopy[index][startOrEnd] = value
    setChildNamesOptions(childNamesOptionsCopy)
  }

  const fetchAll = async () => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )
      if (response?.dbResult) {
        const hotel_id = response.dbResult.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }

    const fetchSetChildName = async () => {
      const response = await request(
        {
          url: "/hotel-set-child-names",
        },
        false
      )

      const childNamesOptions = response?.dbResult?.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
        value: i.id,
        selectedStartAge: "",
        selectedEndAge: "",
        isChecked: false,
      }))

      return childNamesOptions
    }

    const fetchHotel_Child = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-child",
          params: { hotel_id },
        },
        false
      )
      if (response?.dbResult) {
        const {
          id: hotelChild_id,
          hasChildBed,
          isChildAllowed,
        } = response?.dbResult

        setIsChildAllowed(isChildAllowed)
        setHasChildBed(hasChildBed)
        setHotelChild_id(hotelChild_id)
        return response?.dbResult
      }
    }

    const fetchHotel_ChildPolicyAge = async (hotel_id, childNamesOptions) => {
      const response = await request(
        {
          url: "/hotel-child-policy-age",
          params: { hotel_id },
        },
        false
      )

      if (response?.result) {
        const childPolicyAge = response?.dbResult?.sort(
          (a, b) => a.setChildName_id - b.setChildName_id
        )

        const childPolicies = childNamesOptions?.map(i => {
          const childPolicy = childPolicyAge.find(
            j => j.setChildName_id === i.id
          )
          return {
            ...i,
            isChecked: childPolicy?.endAge ? true : false,
            selectedStartAge: childPolicy?.startAge,
            selectedEndAge: childPolicy?.endAge,
          }
        })

        setChildNamesOptions(childPolicies)

        const lastChildPolicyAge = childPolicyAge[childPolicyAge.length - 1]
        if (!lastChildPolicyAge) {
          return
        }

        return response.dbResult
      }
    }

    setLoading(true)
    const hotel_id = await fetchHotelData()
    if (!hotel_id) {
      setLoading(false)
      setShowHotelNotRegWarning(true)
      return
    }

    const childNamesOptions = await fetchSetChildName()
    const hotelChildResponse = await fetchHotel_Child(hotel_id)
    if (hotelChildResponse?.isChildAllowed) {
      await fetchHotel_ChildPolicyAge(hotel_id, childNamesOptions)
    } else {
      setChildNamesOptions(childNamesOptions)
    }

    setLoading(false)
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    const isOneOfThemFilled = childNamesOptions.some(
      i => i.isChecked && i.selectedStartAge && i.selectedEndAge
    )

    if (isChildAllowed && !isOneOfThemFilled) {
      return setShowBlankFieldAlert(true)
    }

    const filteredChildNamesOptions = childNamesOptions.filter(i => i.isChecked)

    const dataList = filteredChildNamesOptions.map(i => ({
      setChildName_id: i.value,
      startAge: i.selectedStartAge,
      endAge: i.selectedEndAge,
      hotel_id,
    }))

    const payload = {
      isChildAllowed,
      hasChildBed,
      hotel_id,
      dataList,
    }
    let method = "post"

    if (hotelChild_id) {
      method = "put"
      payload.id = hotelChild_id
    }

    // send http request
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-child-policy-age",
        method,
        data: payload,
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setHotelChild_id(response?.dbResult?.hotelChild_id)
      setShowSuccessAlert(true)
    }
  }

  const resetHandler = async () => {
    setShowResetWarn(false)
    setResetLoading(true)
    const response = await request(
      {
        url: "/hotel-child-policy-age",
        method: "delete",
        data: { hotel_id },
      },
      false
    )
    setResetLoading(false)
    if (response?.result) {
      setResetSucceed(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Child Policy")}
        />
        {loading && <CustomSpinner color="primary" />}
        <div className={loading ? "d-none" : ""}>
          <Card>
            <CardBody className={styles.cardBody}>
              <h4 className={styles.title}>{t("Child Policy")}</h4>
              <p className={styles.subtitle}>
                {t(
                  "You can define the general rules that you have determined in your facility, such as child guest accommodation, in the system in line with the information requested in this field."
                )}
              </p>
              <CustomSwitch
                label="Do you accept child guests?"
                className={styles.customSwitch}
                onClick={() => setIsChildAllowed(prevState => !prevState)}
                value={isChildAllowed}
              />
              {isChildAllowed && (
                <>
                  <CustomSwitch
                    label="Do you provide extra beds for children?"
                    className={styles.customSwitch}
                    onClick={() => setHasChildBed(prevState => !prevState)}
                    value={hasChildBed}
                  />

                  <Row className={`${styles.row} mt-3 mb-2`}>
                    <div className={styles.col}>
                      <Label>{t("Group Name")}</Label>
                    </div>
                    <div className={styles.col}>
                      <Label>{t("Start Age")}</Label>
                    </div>
                    <div className={styles.col}>
                      <Label>{t("End Age")}</Label>
                    </div>{" "}
                  </Row>

                  <div className={styles.childPolicyList}>
                    {childNamesOptions?.map(i => (
                      <ChildPolicyItem
                        key={i.id}
                        data={i}
                        childGroupChangeHandler={childGroupChangeHandler}
                        childGroupCheckHandler={childGroupCheckHandler}
                      />
                    ))}
                  </div>
                </>
              )}
              {HotelNotRegisteredComp}
            </CardBody>
          </Card>
          {errorMessage && (
            <Alert color="danger">
              {t(errorMessage?.replace("Error:", "")?.trim())}
            </Alert>
          )}
          <Row className={styles.btnContainer}>
            <Button color="danger" onClick={setShowResetWarn.bind(null, true)}>
              {resetLoading ? <Spinner color="white" size="sm" /> : t("Reset")}
            </Button>
            <Button color="primary" onClick={submitHandler}>
              {submitLoading ? (
                <Spinner color="white" size="sm" />
              ) : (
                t("Submit")
              )}
            </Button>
          </Row>
        </div>
      </Container>
      {/* BLANK FIELD ALERT */}
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      {/* SUCCESS ALERT */}
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => {
          setShowSuccessAlert(false)
          fetchAll()
        }}
        message="The data is saved successfully!"
      />
      {/* RESET ALERT */}
      <SweetAlert
        show={showResetWarn}
        warning
        showCancel
        confirmBtnText={t("Yes, delete it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="danger"
        title={t("Warning!")}
        onConfirm={resetHandler}
        onCancel={setShowResetWarn.bind(null, false)}
        focusCancelBtn
      >
        {t(
          "All child pricing policies and room prices will be deleted. Are you sure to continue?"
        )}
      </SweetAlert>
      {/* RESET SUCCESS ALERT */}
      <SweetAlert
        show={resetSucceed}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Deleted!")}
        onConfirm={() => {
          setResetSucceed(false)
          fetchAll()
        }}
      >
        {t("The data is deleted successfully!")}
      </SweetAlert>
    </main>
  )
}

export default HotelChildPolicy
