import moment from "moment"
import "moment/locale/tr"
import "moment/locale/ar"
import "moment/locale/fr"
import "moment/locale/de"
import "moment/locale/ru"

export const momentFormat = (date, locale, format) => {
  moment.locale(locale)
  return moment(date).format(format)
}

export const momentLocale = locale => {
  moment.locale(locale)
  return moment
}

export const formatTwoDigit = num => {
  if (num < 10) {
    num = "0" + num
  }
  return num
}

export const formatDate = date => {
  date = new Date(date)

  let month = date.getMonth() + 1
  month = formatTwoDigit(month)

  let day = date.getDate()
  day = formatTwoDigit(day)

  return day + "." + month + "." + date.getFullYear()
}

export const formatTime = date => {
  date = new Date(date)

  return (
    formatTwoDigit(date.getHours()) +
    ":" +
    formatTwoDigit(date.getMinutes()) +
    ":" +
    formatTwoDigit(date.getSeconds())
  )
}

export const formatDateTime = date => {
  return date ? formatDate(date) + " " + formatTime(date) : ""
}

export const formatToHHMM = time => {
  const date = new Date(time);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const formatToServerDate = date => {
  date = new Date(date)

  let month = date.getMonth() + 1
  month = formatTwoDigit(month)

  let day = date.getDate()
  day = formatTwoDigit(day)

  return date.getFullYear() + "-" + month + "-" + day
}

export const formatToServerDateTime = date => {
  date = new Date(date)

  let month = date.getMonth() + 1
  month = formatTwoDigit(month)

  let day = date.getDate()
  day = formatTwoDigit(day)

  return date.getFullYear() + "-" + month + "-" + day + " " + formatTime(date)
}

export const generateDateBetween = (startDate, endDate) => {
  startDate = new Date(startDate)
  endDate = new Date(endDate)
  let day = 1000 * 60 * 60 * 24
  let diff = (endDate.getTime() - startDate.getTime()) / day
  let dateObjects = {}
  for (let i = 0; i <= diff; i++) {
    let newDate = startDate.getTime() + day * i
    newDate = new Date(newDate)
    dateObjects[formatToServerDate(newDate)] = ""
  }
  return dateObjects
}

export const calcDaysBetween = (startDate, endDate) => {
  startDate = new Date(startDate)
  endDate = new Date(endDate)
  const diffTime = endDate - startDate
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const addDays = (date, days) => {
  let result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const isDateBetween = (dateCheck, dateFrom, dateTo) => {
  dateCheck = new Date(dateCheck).getTime()
  dateFrom = new Date(dateFrom).getTime()
  dateTo = new Date(dateTo).getTime()

  return dateFrom <= dateCheck && dateCheck <= dateTo
}

export const getTodayDate = () => {
  let date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0)
}
