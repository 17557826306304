import { Modal, ModalBody, ModalFooter, Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { CgClose } from "react-icons/cg"
import styles from "./tac.module.scss"

const PrivacyPolicy = ({ toggle }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  let Content = null
  switch (locale) {
    case "en":
      Content = Content_en
      break
    case "tr":
      Content = Content_tr
      break
    default:
      Content = Content_en
      break
  }
  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <header className={styles.header}>
        <h4>{t("Privacy Statement for Business Partners")}</h4>
        <span onClick={toggle}>
          <CgClose />
        </span>
      </header>
      <ModalBody className={styles.modalBody}>
        <Content />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {t("Close")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PrivacyPolicy

const Content_en = () => {
  return (
    <>
      <p>
        This Privacy Statement explains how the Vihobook group of companies
        processes personal data concerning individuals, employees, owners, or
        representatives acting on behalf of our (existing, former, or potential)
        Business Partners.
      </p>
      <p>
        In this document, Vihobook group companies may be referred to as
        &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our,&rdquo; or
        &ldquo;Vihobook.&rdquo; Individuals, employees, owners, or
        representatives acting on behalf of our Business Partners may be
        referred to together or separately as &ldquo;you,&rdquo;
        &ldquo;your,&rdquo; or &ldquo;Business Partners.&rdquo;
      </p>
      <p>
        Within the context of this Privacy Statement, the term &ldquo;Business
        Partners&rdquo; may refer to:
      </p>
      <ul>
        <li>
          <p>Trip providers,</p>
        </li>
        <li>
          <p>Travel agencies,</p>
        </li>
        <li>
          <p>Third party vendors,</p>
        </li>
        <li>
          <p>Ground transportation companies,</p>
        </li>
        <li>
          <p>Payment service providers,</p>
        </li>
        <li>
          <p>
            Other business partners enabling Trip reservations through their
            websites and/or apps (or other means),
          </p>
        </li>
        <li>
          <p>
            And natural or legal persons maintaining a business relationship
            with a Vihobook group company.
          </p>
        </li>
      </ul>
      <p>
        Note that companies using our &ldquo;Vihobook for Business&rdquo;
        services to facilitate Trip reservations for their staff are instead
        subject to our&nbsp; Privacy and Cookie Notice for customers.
      </p>
      <p>
        This Privacy Statement applies to any Vihobook group company responsible
        for the processing of personal data concerning a Business Partner.
        Depending on the nature of the business relationship, different Vihobook
        group companies may be responsible for the processing of that personal
        data.
      </p>
      <p>
        For example, Vihobook B.V., located in Amsterdam, the Netherlands, is
        responsible for the processing of Trip Provider data. This Privacy
        Statement also applies to Booking Brasil Servi&ccedil;os de Reserva de
        Hot&eacute;is Ltda, located in S&atilde;o Paulo, Brazil, when processing
        the personal data of accommodation partners in Brazil.
      </p>
      <p>
        To find out if and to what extent other Vihobook group companies are
        responsible for the processing of your personal data, please review the
        information provided below. Information is also provided in your
        agreement with Vihobook and made available on our online platforms (such
        as sign up pages or procurement management platforms). Alternatively,
        you can contact us at&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>
        .
      </p>
      <p>
        For more information about how Vihobook uses cookies on dedicated
        Business Partner websites and apps, please refer to our&nbsp; Cookie
        Statement .
      </p>
      <h4>Terms we use in this Privacy Statement</h4>
      <p>
        &ldquo;Trip&rdquo; refers to the various travel products and services
        that can be ordered, acquired, purchased, bought, paid for, rented,
        provided to, reserved by, combined by, or consumed by end users, from
        the Trip Provider.
      </p>
      <p>
        &ldquo;Trip Provider&rdquo; means the provider of accommodation (e.g.,
        hotel, motel, apartment, bed &amp; breakfast, landlord, and generically
        referred to as &ldquo;accommodation partner&rdquo;), attractions (e.g.,
        (theme) parks, museums, sightseeing tours), transportation provider
        (e.g., car rentals, cruises, rail, taxi, airport rides, coach tours,
        transfers), tour operators, travel insurances, and any other travel or
        related product or service as from time to time available for Trip
        Reservation on our platforms.
      </p>
      <p>
        &ldquo;Trip Service&rdquo; means the online purchase, order,
        (facilitated) payment or reservation service as offered or enabled by
        Vihobook in respect of various products and services as from time to
        time made available by Trip Providers on the platform.
      </p>
      <p>
        &ldquo;Trip Reservation&rdquo; means the order, purchase, payment,
        booking, or reservation of a Trip.
      </p>
      <h4>Data collection</h4>
      <p>
        The personal data Vihobook collects in regards to Business Partners
        depends on the context of the business relationship and their
        interaction with Vihobook, the choices made by the Business Partner and
        the products, services, and features they use.
      </p>
      <p>
        Please note that the data items in the sections below are only
        considered &ldquo;personal data&rdquo; when concerning a natural person
        (meaning an individual human being). These data items are not considered
        &ldquo;personal data&rdquo; when concerning a legal person or entity.
      </p>
      <p>
        The personal data Vihobook collects about our Business Partners can
        include the following:
      </p>
      <h5>Personal data you give to us</h5>
      <ul>
        <li>
          <h5>Contact details</h5>
          <p>
            We collect contact data from our Business Partners, such as first
            and last name, date of birth (as required), addresses, business
            email addresses, telephone, and fax numbers.
          </p>
        </li>
        <li>
          <h5>Financial data</h5>
          <p>
            We collect data necessary for payment and invoicing purposes
            (including your bank details, bank account number, and VAT number)
            and data otherwise necessary for the processing of credit slips.
          </p>
        </li>
        <li>
          <h5>Verification details</h5>
          <p>
            Vihobook can ask (representatives of) Business Partners to provide a
            copy of company registration documents, their ID card or passport, a
            photo, a video, or other relevant information to verify the
            authenticity of the Business Partner. This may also include
            proof-of-licenses or tax information.
          </p>
        </li>
        <li>
          <h5>Other data</h5>
          <p>
            When a Business Partner communicates with Vihobook, we collect and
            process information about these communications. During calls with
            our support center, live listening may be conducted and calls may be
            recorded for quality control and training purposes. These recordings
            may also be used for the handling of claims and fraud detection
            purposes.
          </p>
          <p>
            Recordings are kept for a limited amount of time before being
            automatically deleted, unless Vihobook has a legitimate interest to
            keep the recording for longer. This only happens in exceptional
            cases, such as for fraud investigation, compliance, and legal
            purposes.
          </p>
        </li>
      </ul>
      <h5>Information we collect automatically</h5>
      <p>
        Depending on the business relationship, Vihobook may also collect
        information automatically&mdash;some of which may be personal data. This
        data is collected when a Business Partner uses online services such as a
        registration form, a user account (e.g., for the Extranet or partner
        center), a vendor management platform, or a Vihobook app, like Pulse.
      </p>
      <p>The data collected may include:</p>
      <ul>
        <li>
          <p>Language settings,</p>
        </li>
        <li>
          <p>IP address,</p>
        </li>
        <li>
          <p>Location,</p>
        </li>
        <li>
          <p>Device settings,</p>
        </li>
        <li>
          <p>Device operating system,</p>
        </li>
        <li>
          <p>Log information,</p>
        </li>
        <li>
          <p>Time of usage,</p>
        </li>
        <li>
          <p>URL requested,</p>
        </li>
        <li>
          <p>Status report,</p>
        </li>
        <li>
          <p>User agent (information about the browser version),</p>
        </li>
        <li>
          <p>Result (viewer or booker),</p>
        </li>
        <li>
          <p>Browsing history,</p>
        </li>
        <li>
          <p>User Booking ID,</p>
        </li>
        <li>
          <p>And the type of data viewed.</p>
        </li>
      </ul>
      <h5>Personal data you give to us about others</h5>
      <p>
        By sharing other individuals&rsquo; personal data for business
        purposes&mdash;such as data belonging to your staff members&mdash;you
        confirm that these individuals have been informed about the use of their
        personal data by Vihobook in accordance with this privacy statement. You
        also confirm that you have obtained all necessary consent, as required
        by the laws and regulations applicable to you.
      </p>
      <h5>Other information we receive from other sources</h5>
      <ul>
        <li>
          <h4>Information on insolvencies</h4>
          <p>
            In insolvency matters, Vihobook can receive information relating to
            Business Partners from insolvency administrators, courts, or other
            public authorities.
          </p>
        </li>
        <li>
          <h4>Data related to law enforcement and tax authority requests</h4>
          <p>
            Law enforcement or tax authorities can contact Vihobook with
            additional information about Business Partners in the event that
            they are affected by an investigation.
          </p>
        </li>
        <li>
          <h4>
            Fraud detection and prevention, risk management, and compliance
          </h4>
          <p>
            In certain instances, and as permitted by applicable law, Vihobook
            may need to collect data through third-party sources for fraud
            detection and prevention, risk management, and compliance purposes.
          </p>
        </li>
      </ul>
      <h4>Processing purposes</h4>
      <p>
        Vihobook uses the previously described Business Partner information,
        some of which may be personal data, as relevant, for the following
        purposes:
      </p>
      <h4>A. Registration and administration</h4>
      <p>
        Vihobook uses account details, contact details, and financial data to
        manage the business relationship with the Business Partner. This
        includes for registration and verification purposes.
      </p>
      <p>
        Certain information, including a Business Partner&rsquo;s name and
        address, can be used for other purposes in accordance with the agreement
        entered into between the Business Partner and Vihobook. An example of
        this would be using an accommodation partner&rsquo;s contact details to
        enable Trip Reservations at their property through a third-party
        website.
      </p>
      <h4>B. Customer service</h4>
      <p>
        Vihobook uses the information provided by Business Partners (which may
        include personal data) to provide support services&mdash;for example, to
        respond to requests, questions, or concerns from Business Partners or
        customers.
      </p>
      <h4>C. Other activities, including marketing</h4>
      <p>
        If a potential Business Partner has not finalized online registration,
        Vihobook may send a reminder to complete the registration process. We
        believe that this additional service is useful to our (future) Business
        Partners because it allows them to complete the registration without
        having to fill out all registration details again.
      </p>
      <p>
        Vihobook may invite Business Partners to attend and host events that we
        think might be relevant or of interest to them. We may also use personal
        data to offer and host online forums that enable Business Partners to
        find answers to frequently asked questions about the offering and usage
        of Vihobook products and services.
      </p>
      <p>
        As relevant to the business relationship, Vihobook uses personal data
        for communications, including providing information about system or
        product updates, sending the Vihobook newsletter, inviting Business
        Partners to participate in referral programs or competitions (such as
        Booking Hero) or for other marketing communications. When we use
        personal data to send direct marketing messages electronically, we will
        offer appropriate opt-out options.
      </p>
      <h4>D. Messaging tools</h4>
      <p>
        Vihobook may offer Business Partners different means to communicate with
        (prospective or existing) guests before or after a reservation.
      </p>
      <p>
        Business Partners can also contact Vihobook to forward reservation
        information or questions to guests, or communicate with guests using
        alias emails, which always include Vihobook as a recipient.
      </p>
      <p>
        Vihobook may access communications between Business Partners and guests.
        We also use automated systems to review, scan, and analyze
        communications for the following purposes:
      </p>
      <ul>
        <li>
          <p>Security,</p>
        </li>
        <li>
          <p>Fraud prevention,</p>
        </li>
        <li>
          <p>Compliance with legal and regulatory requirements,</p>
        </li>
        <li>
          <p>Investigations of potential misconduct,</p>
        </li>
        <li>
          <p>Product development and improvement,</p>
        </li>
        <li>
          <p>Research,</p>
        </li>
        <li>
          <p>
            Customer engagement (including to provide guests with information or
            offers we believe may be of interest to them),
          </p>
        </li>
        <li>
          <p>And customer or technical support.</p>
        </li>
      </ul>
      <p>
        Communications sent or received using Vihobook communication tools will
        be received and stored by Vihobook.
      </p>
      <h4>E. Analytics, improvement, and research:</h4>
      <p>
        Vihobook uses information provided to us, which may include personal
        data, for analytical purposes. This is part of our drive to improve
        Vihobook products and services and enhance user experience.
      </p>
      <p>
        This data can also be used for testing purposes, troubleshooting, and to
        improve the functionality and quality of Vihobook&rsquo;s online
        services. We also invite Business Partners to participate in surveys and
        conduct other market research from time to time.
      </p>
      <p>
        Certain Business Partners may be invited to join a dedicated online
        platform to interact with Vihobook and/or exchange experiences with
        other Business Partners.
      </p>
      <p>
        Please consult the information Vihobook provides when you are invited to
        participate in a survey, market research or to join an online platform,
        to understand how your personal data may be treated differently than
        described in this Privacy Statement.
      </p>
      <h4>F. Security, fraud detection, and prevention</h4>
      <p>
        We process the information provided to us, which may include personal
        data, in order to investigate, prevent, and detect fraud and other
        illegal acts. This may be personal data a Business Partner has provided
        to Vihobook, for example, for verification purposes as part of the
        registration process, personal data collected automatically, or personal
        data obtained from third-party sources (including from guests).
      </p>
      <p>
        Vihobook may also use personal data to facilitate investigation and
        enforcement by competent authorities, where necessary. For these
        purposes, personal data may be shared with law enforcement authorities.
      </p>
      <p>
        Vihobook can also use personal data for risk assessment and security
        purposes, including the authentication of users, and we use third-party
        service providers for third-party risk management. These providers help
        us to assess the business risk profile of our Business Partners. They
        may also provide us with third-party due diligence reports, which, as
        permitted by applicable law, may include possible information about
        criminal convictions and offenses of owners or Business Partners.
      </p>
      <h4>G. Legal and compliance</h4>
      <p>
        In certain cases, Vihobook needs to use the information provided (which
        may include personal data) to handle and resolve legal disputes or for
        regulatory investigations, risk management, and compliance. We may also
        use it to enforce our agreement(s) with Business Partners or solve a
        complaint or claim involving a guest, as reasonably expected, and in
        accordance with internal policies and procedures.
      </p>
      <p>
        In addition, we may need to share information about Business Partners
        (including personal data) where required by law or strictly necessary to
        respond to requests from competent authorities. This includes tax
        authorities, courts, other governmental, and public authorities or local
        municipalities (e.g., in relation to short-term rental laws).
      </p>
      <p>
        If we use automated means to process personal data, which produces legal
        effects or significantly affects you or other natural persons, we will
        implement suitable measures to safeguard yours or the other
        person&rsquo;s rights and freedoms. This includes the right to obtain
        human intervention.
      </p>
      <h5>Legal bases</h5>
      <p>
        As applicable for purposes A and B, Vihobook relies on the legal basis
        that the processing of the personal data is necessary for the
        performance of the agreement between the Business Partner and Vihobook.
        If the required information is not provided, Vihobook cannot register a
        Trip Provider or otherwise work with a Business Partner, nor can we
        provide customer service.
      </p>
      <p>
        In view of purposes C to G, Vihobook relies on its legitimate interest
        to provide its services or obtain services from Business Partners, to
        prevent fraud and to improve its services. When using personal data to
        serve Vihobook&rsquo;s or a third party&apos;s legitimate interest, we
        will always balance the impacted individual&rsquo;s rights and the
        protection of their information against Vihobook&rsquo;s and/or the
        third party&rsquo;s rights and interests.
      </p>
      <p>
        For purposes F and G, Vihobook also relies, where applicable, on
        compliance with legal obligations (such as lawful law-enforcement
        requests). Finally, where needed under applicable law, Vihobook will
        obtain your consent prior to processing personal data, including for
        marketing purposes or as otherwise required by law.
      </p>
      <p>
        If you wish to object to the processing set out under C to E and are
        unable to find a way to opt out directly (for example, in your account
        settings), please contact&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>
        .
      </p>
      <h4>Data sharing</h4>
      <ul>
        <li>
          <h4>Sharing with affiliates</h4>
          <p>
            In order to support the use of the Vihobook services, your
            information (which may include personal data) may be shared with
            members of the Vihobook corporate group. To find out everything you
            need to know about the Vihobook corporate group, visit our&nbsp;
            About Vihobook page.
          </p>
        </li>
        <li>
          <h4>Sharing with third parties</h4>
          <p>
            We share Business Partners&rsquo; information (which may include
            personal data) with third parties, as permitted by law and as
            described below:
          </p>
          <ul>
            <li>
              Service providers (incl. suppliers). We share personal data with
              selected third-party service providers to provide our products and
              services, prevent and detect fraud, store data, and otherwise
              support our business processes, or so they can conduct business on
              our behalf.
            </li>
            <li>
              Payment Providers and other financial institutions. To process
              payments between a Business Partner and Vihobook or a guest and a
              Business Partner, relevant personal data is shared with payment
              providers and other financial institutions.
            </li>
            <li>
              Sanction list screening or risk management as required by
              applicable law.
            </li>
            <li>
              Compelled disclosure. When legally required, strictly necessary
              for the performance of our services, in legal proceedings, or to
              protect our rights or the rights of other members of the Booking
              Holdings Inc. group or users, we disclose personal data to law
              enforcement authorities, investigative organizations, or other
              group members.
            </li>
          </ul>
        </li>
        <li>
          <h4>Sharing and disclosure of aggregated data</h4>
          <p>
            We may share information with third parties in an aggregate form
            and/or another form that does not allow the recipient to identify
            you, for example, for industry analysis or demographic profiling.
          </p>
        </li>
      </ul>
      <h4>Data sharing within the Vihobook Holdings Inc. corporate group</h4>
      <p>
        Vihobook is part of the Vihobook Holdings Inc. corporate group. For more
        information about the group, visit the&nbsp; Vihobook Holdings website .
      </p>
      <p>
        Vihobook may receive personal data about Business Partners from other
        companies in the Vihobook Holdings Inc. corporate group, or we may share
        Business Partners&rsquo; personal data with those companies. This is
        done for the purposes as described below, subject to any contractual
        terms.
      </p>
      <p>
        To provide an example of this inter-group collaboration, Vihobook works
        closely with Rentalcars.com to offer ground transportation services to
        its customers, either directly or through our Business Partners.
      </p>
      <p>
        All companies within the Vihobook Holdings Inc. group may need to
        exchange Business Partners&rsquo; personal data to ensure we protect all
        users from fraudulent activities on its online platforms.
      </p>
      <p>
        The purposes for data sharing within the Vihobook Holdings Inc. group of
        companies are:
      </p>
      <ul>
        <li>
          <p>
            A. To provide services, including reservation services, to manage
            Business Partner accounts (including vendor management), and to
            provide support,
          </p>
        </li>
        <li>
          <p>
            B. To prevent, detect, and investigate fraudulent and other illegal
            activities,
          </p>
        </li>
        <li>
          <p>C. For analytical and product improvement purposes,</p>
        </li>
        <li>
          <p>
            D. To personalize online services or send marketing with the
            recipient&rsquo;s consent (or as otherwise permitted by applicable
            law),
          </p>
        </li>
        <li>
          <p>E. To ensure compliance with policies and applicable laws.</p>
        </li>
      </ul>
      <p>
        Vihobook relies on our legitimate interest and that of the Booking
        Holdings Inc. group companies&rsquo; to receive and share personal data
        as described under A to D. This is in order to provide services or
        obtain services from Business Partners, including to improve these
        services and to prevent fraud.
      </p>
      <p>
        When using personal data to serve Vihobook&rsquo;s or a third
        party&apos;s legitimate interest, Vihobook will always balance the
        impacted individual&rsquo;s rights and interests in the protection of
        their personal data with the rights and interests of Vihobook or the
        third party.
      </p>
      <p>
        For purpose E, Vihobook relies also, where applicable, on compliance
        with legal obligations (such as in matters of lawful law-enforcement
        requests).
      </p>
      <p>
        If you wish to object to the processing set out under A to D, and are
        unable to find a way to opt out directly (for example, in your account
        settings), please contact&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>
        .
      </p>
      <h4>Data sharing with Vihobook Transport Limited</h4>
      <p>
        Vihobook Transport Limited (&ldquo;BTL&rdquo;), also trading as
        Rentalcars.com, is a private limited liability company, incorporated
        under the laws of the United Kingdom with offices at 100 New Bridge
        Street, London, EC4V 6JA.
      </p>
      <p>
        BTL is part of the Vihobook Holdings Inc. corporate group, and the ground
        transportation services offered through Vihobook&rsquo;s websites and
        apps are operated by BTL under the Vihobook brand.
      </p>
      <p>
        Vihobook B.V. operates the BTL&nbsp; and account management process. The
        information collected from BTL Business Partners during this sign-up and
        account management process, which may include personal data, is shared
        with BTL for the purposes set out in this Privacy Statement.
      </p>
      <p>
        If you are a BTL Business Partner and have questions or concerns about
        the processing of your personal data, or you want to exercise the rights
        you have under this Privacy Statement, please contact BTL at&nbsp;
        <a href="mailto:customerr@vihobook.com">
          customerr@vihobook.com
        </a>
        .
      </p>
      <h4>International data transfers</h4>
      <p>
        The transmission of Business Partners&rsquo; personal data, as described
        in this Privacy Statement, may include overseas transfer of this data.
        This may be to countries whose data protection laws are not as
        comprehensive as those of the country or countries in which you
        initially provided the information, including within the European Union.
        In such cases, we will protect your personal data as described in this
        Privacy Statement.
      </p>
      <p>
        Where required by European law, we will put appropriate safeguards in
        place to ensure that such transfers comply with European privacy law. In
        particular, when personal data is transferred to third-party service
        providers, we establish and implement appropriate contractual,
        organizational, and technical measures with such providers.
      </p>
      <p>
        These measures can be established using &ldquo;Standard Contractual
        Clauses,&rdquo; approved by the European Commission, by examining the
        countries data may be transferred to and imposing specific technical and
        organizational security measures. Where applicable, you can ask us for a
        copy of these contractual agreements using the&nbsp; contact details
        provided below.
      </p>
      <h4>Security</h4>
      <p>
        We have procedures in place to prevent unauthorized access to and misuse
        of personal data.
      </p>
      <p>
        We use appropriate business systems and procedures to protect and
        safeguard information, including personal data. We also use security
        procedures and technical and physical restrictions for accessing and
        using the personal data on our servers. Only authorized personnel are
        permitted to access personal data in the course of their work.
      </p>
      <h4>Data retention</h4>
      <p>
        We will retain personal data for as long as deemed necessary to manage
        the business relationship with a Business Partner, to provide Vihobook
        services to a Business Partner and to comply with applicable laws
        (including those regarding document retention), resolve disputes or
        claims with any parties, and as otherwise necessary to allow us to
        conduct our business.
      </p>
      <p>
        All personal data we retain about you as a Business Partner is subject
        to this Privacy Statement and our internal retention guidelines. If you
        have questions about the specific retention periods for the various
        types of personal data we process, please get in touch using the&nbsp;
        contact details below .
      </p>
      <h4>Your choices and rights</h4>
      <p>
        Depending on where you are located or the Vihobook entity processing
        your personal data, different rights may apply to the processing of that
        data, as set out in this privacy statement. As applicable:
      </p>
      <ul>
        <li>
          You can ask us for a copy of the personal data we hold about you,
        </li>
        <li>
          You can inform us of any changes to your personal data, or you can ask
          us to correct any of the personal data we hold about you,
        </li>
        <li>
          In certain situations, you can ask us to erase, block, or restrict the
          personal data we hold about you, or object to particular ways in which
          we are using your personal data,
        </li>
        <li>
          In certain situations, you can also ask us to send the personal data
          you have given us to a third party.
        </li>
      </ul>
      <p>
        Where we are using your personal data on the basis of your consent, you
        are entitled to withdraw that consent at any time, subject to applicable
        law. Where we process your personal data based on legitimate interest or
        public interest, you also have the right to object at any time, subject
        to applicable law.
      </p>
      <p>
        Regardless of your location or the Vihobook entity with which you are
        contracted, we rely on our Business Partners to ensure that the personal
        data we hold is complete, accurate, and current. Please always inform us
        promptly of any changes to or inaccuracies in your personal data.
      </p>
      <p>
        If your business has agreements with a different entity to Vihobook B.V.
        and you would like further information about the relationship between
        that entity and Vihobook B.V., you can&nbsp; contact us at any time. You
        should also do so if you would like to exercise your rights regarding
        the personal data processed about you as part of that business
        relationship.
      </p>
      <h4>Contact us</h4>
      <p>
        If you have questions, requests, or concerns about how we process your
        personal data, or would like to exercise any of the rights you have
        under this Privacy Statement, contact our data protection officer
        at&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>
        . You can also contact your local data protection authority.
      </p>
      <p>
        We address privacy specific questions, requests, and concerns that are
        reported to us using internal policies and procedures based on
        applicable privacy laws, regulations, and guidance. We revisit and
        enhance these policies and procedures regularly, also taking into
        account Business Partner feedback.
      </p>
      <h4>Changes to this Privacy Statement</h4>
      <p>
        Just as our business is always changing, this Privacy Statement also
        changes from time to time. If we plan to make material changes or
        changes that have an impact on you, we will always contact you in
        advance. An example of this kind of change would be if we were to start
        processing your personal data for purposes that aren&rsquo;t detailed
        above.
      </p>
    </>
  )
}


const Content_tr = () => {
  return (
    <>
      <p>
        Bu Gizlilik Bildirimi, Vihobook şirketler grubunun (mevcut, eski veya
        potansiyel) İş Ortaklarımız adına faaliyet g&ouml;steren bireyler,
        &ccedil;alışanlar, mal sahipleri veya temsilcilerle ilgili kişisel
        verileri nasıl işlediğini a&ccedil;ıklar.
      </p>
      <p>
        Bu belgede Vihobook grup şirketlerinden &ldquo;biz&rdquo;,
        &ldquo;bizi/bize&rdquo;, &ldquo;bizim&rdquo; veya &ldquo;Vihobook&rdquo;
        şeklinde bahsedilebilir. İş Ortaklarımız adına faaliyet g&ouml;steren
        bireyler, &ccedil;alışanlar, mal sahipleri veya temsilcilerden topluca
        veya ayrı ayrı &ldquo;siz&rdquo;, &ldquo;sizin&rdquo; ya da &ldquo;İş
        Ortakları&rdquo; şeklinde bahsedilebilir.
      </p>
      <p>
        Bu Gizlilik Bildirimi bağlamında &ldquo;İş Ortakları&rdquo; ifadesi
        şunlardan biri olabilir:
      </p>
      <ul>
        <li>
          <p>Gezi Tedarik&ccedil;ileri,</p>
        </li>
        <li>
          <p>Seyahat acenteleri,</p>
        </li>
        <li>
          <p>&Uuml;&ccedil;&uuml;nc&uuml; taraf satıcılar,</p>
        </li>
        <li>
          <p>Kara yolu taşımacılığı şirketleri,</p>
        </li>
        <li>
          <p>&Ouml;deme hizmeti sağlayıcıları,</p>
        </li>
        <li>
          <p>
            Web siteleri ve/veya uygulamalar (ya da başka y&ouml;ntemler)
            aracılığıyla Gezi rezervasyonları yapılmasını sağlayan diğer iş
            ortakları,
          </p>
        </li>
        <li>
          <p>
            Bir Vihobook grup şirketi ile iş ilişkisi s&uuml;rd&uuml;ren
            ger&ccedil;ek veya t&uuml;zel kişiler.
          </p>
        </li>
      </ul>
      <p>
        Personeli i&ccedil;in Gezi rezervasyonlarına aracılık etmek adına
        &ldquo;Vihobook for Business&rdquo; hizmetlerimizi kullanan şirketlerin
        de m&uuml;şteriler i&ccedil;in&nbsp; Gizlilik ve &Ccedil;erez
        Bildirimimize tabi olduğunu l&uuml;tfen unutmayın.
      </p>
      <p>
        Bu Gizlilik Bildirimi, bir iş ortağını ilgilendiren kişisel verilerin
        işlenmesinden sorumlu t&uuml;m Vihobook grup şirketleri i&ccedil;in
        ge&ccedil;erlidir. İş ilişkisinin yapısına bağlı olarak s&ouml;z konusu
        kişisel verilerin işlenmesinden farklı Vihobook grup şirketleri sorumlu
        olabilir.
      </p>
      <p>
        &Ouml;rneğin Amsterdam, Hollanda&rsquo;da bulunan Vihobook B.V., Gezi
        Tedarik&ccedil;isi verilerinin işlenmesinden sorumludur. Bu Gizlilik
        Bildirimi, Brezilya&rsquo;daki konaklama tesisi ortaklarının kişisel
        verilerinin işlendiği durumlar i&ccedil;in S&atilde;o Paulo,
        Brezilya&rsquo;da bulunan Booking Brasil Servi&ccedil;os de Reserva de
        Hot&eacute;is Ltda i&ccedil;in de ge&ccedil;erlidir.
      </p>
      <p>
        Kişisel verilerinizin işlenmesinden diğer Vihobook grup şirketlerinin
        sorumlu olup olmadığını ve ne &ouml;l&ccedil;&uuml;de sorumlu olduğunu
        &ouml;ğrenmek i&ccedil;in l&uuml;tfen aşağıda verilen bilgileri
        inceleyin. İlgili bilgi, Vihobook&rsquo;la olan s&ouml;zleşmenizde ve
        online platformlarımızda da (kayıt sayfaları veya satın alma
        y&ouml;netimi platformları gibi) sağlanmıştır. Alternatif olarak
        bizimle&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>{" "}
        adresinden de iletişime ge&ccedil;ebilirsiniz.
      </p>
      <p>
        Vihobook&rsquo;un İş Ortağı web siteleri ve uygulamalarında ilgili
        &ccedil;erezleri nasıl kullandığına ilişkin daha fazla bilgi i&ccedil;in
        l&uuml;tfen&nbsp; &Ccedil;erez Bildirimimize bakın.
      </p>
      <h4>Bu Gizlilik Bildiriminde kullandığımız terimler</h4>
      <p>
        &ldquo;Gezi&rdquo;, son kullanıcıların Gezi Tedarik&ccedil;isinden
        sipariş edebileceği, edinebileceği, satın alabileceği, &ouml;demesini
        yapabileceği, kiralayabileceği, rezerve edebileceği, birleştirebileceği,
        kullanabileceği veya Gezi Tedarik&ccedil;isi tarafından kendilerine
        sunulabilecek farklı seyahat &uuml;r&uuml;n ve hizmetleri anlamına
        gelmektedir.
      </p>
      <p>
        &quot;Gezi Tedarik&ccedil;isi&quot;, Platformlarımızda zaman zaman Gezi
        Rezervasyonu i&ccedil;in sunulabilecek konaklama tesisinin (&ouml;r.
        otel, motel, daire, oda ve kahvaltı tesisi, ev sahibi ve genel adıyla
        &ldquo;konaklama tesisi ortağı&rdquo;), turistik noktaların (&ouml;r.
        (tema) parklar, m&uuml;zeler, gezi turları), ulaşımın (&ouml;r. araba
        kiralamaları, gemi gezileri, demir yolu, taksi, havaalanı ulaşımları,
        otob&uuml;s turları, transferler), tur operat&ouml;rlerinin, seyahat
        sigortalarının ve herhangi başka bir seyahatin ya da ilgili
        &uuml;r&uuml;n ve hizmetlerin tedarik&ccedil;isi anlamına gelmektedir.
      </p>
      <p>
        &ldquo;Gezi Hizmeti&rdquo;, Vihobook tarafından sağlanan veya
        m&uuml;mk&uuml;n kılınan şekliyle platformda muhtelif zamanlarda Gezi
        Tedarik&ccedil;ileri tarafından sunulan &ccedil;eşitli &uuml;r&uuml;n ve
        hizmetlere ilişkin online alım satım, sipariş, (aracılı) &ouml;deme veya
        rezervasyon hizmeti anlamına gelmektedir.
      </p>
      <p>
        &ldquo;Gezi Rezervasyonu&rdquo;; bir Gezinin sipariş edilmesi, satın
        alınması, &ouml;demesinin yapılması, ayırtılması veya rezerve edilmesi
        anlamına gelir.
      </p>
      <h4>Verilerin toplanması</h4>
      <p>
        Vihobook&rsquo;un İş Ortaklarına ilişkin olarak topladığı kişisel
        veriler, Vihobook ile olan iş ilişkisi ve etkileşimin bağlamına, İş
        Ortağı tarafından yapılan se&ccedil;imlere ve İş Ortağının kullandığı
        &uuml;r&uuml;n, hizmet ve &ouml;zelliklere dayanır.
      </p>
      <p>
        Aşağıdaki b&ouml;l&uuml;mlerdeki veri unsurlarının yalnızca
        ger&ccedil;ek bir kişi (yani birey) ile ilgili olduğunda &ldquo;kişisel
        veri&rdquo; olarak kabul edildiğini l&uuml;tfen unutmayın. Bu veri
        unsurları, t&uuml;zel bir kişi veya kuruluşla ilgili olduğunda
        &ldquo;kişisel veri&rdquo; olarak kabul edilmez.
      </p>
      <p>
        Vihobook&rsquo;un İş Ortaklarımız hakkında topladığı kişisel veriler
        şunları i&ccedil;erebilir:
      </p>
      <h5>Bize verdiğiniz kişisel veriler</h5>
      <ul>
        <li>
          <h5>İletişim bilgileri</h5>
          <p>
            İş Ortaklarımızdan ad ve soyadı, doğum tarihi (gerekli olduğu
            takdirde), adresler, iş e-posta adresleri, telefon ve faks
            numaraları gibi iletişim verilerini toplarız.
          </p>
        </li>
        <li>
          <h5>Finansal veriler</h5>
          <p>
            &Ouml;deme ve faturalandırma ama&ccedil;ları doğrultusundaki
            verileri (banka bilgileri, banka hesabı numarası ve KDV numarası) ve
            bunlar dışında &ouml;deme fişlerinin işlenmesi i&ccedil;in gerekli
            diğer verileri toplarız.
          </p>
        </li>
        <li>
          <h5>Doğrulama bilgileri</h5>
          <p>
            Vihobook, İş Ortağının kimliğini doğrulamak i&ccedil;in İş
            Ortaklarından (veya bunların temsilcilerinden) şirket tescil
            belgeleri, kimlik kartları veya pasaportlarının bir kopyası,
            fotoğraf, video ya da diğer alakalı bilgileri isteyebilir. Bu aynı
            zamanda ruhsat kanıtlarını veya vergi bilgilerini de
            i&ccedil;erebilir.
          </p>
        </li>
        <li>
          <h5>Diğer veriler</h5>
          <p>
            Bir İş Ortağı Vihobook ile iletişim kurduğunda, bu iletişimlerle
            ilgili bilgileri toplar ve işleriz. Destek merkezimizle yapılan
            g&ouml;r&uuml;şmelerde kalite kontrol ve eğitim amacıyla canlı
            dinleme yapılabilir ve g&ouml;r&uuml;şmeler kayıt altına alınabilir.
            Bu kayıtlar, hak taleplerinin ele alınması ve dolandırıcılık tespiti
            amacıyla da kullanılabilir.
          </p>
          <p>
            Vihobook&apos;un kayıtları daha uzun s&uuml;re saklamak i&ccedil;in
            meşru bir menfaati olmadığı s&uuml;rece kayıtlar, otomatik olarak
            silinmeden &ouml;nce sınırlı bir s&uuml;re boyunca saklanır. Bu
            durum yalnızca dolandırıcılık soruşturması, uyumluluk ve yasal
            ama&ccedil;lar gibi istisnai durumlarda ger&ccedil;ekleşir.
          </p>
        </li>
      </ul>
      <h5>Otomatik olarak topladığımız bilgiler</h5>
      <p>
        İş ilişkisine bağlı olarak Vihobook, aralarında kişisel verilerin de
        olabileceği bilgileri otomatik olarak da toplayabilir. Bu veriler; kayıt
        formu, kullanıcı hesabı (&ouml;r. extranet veya ortak merkezi
        i&ccedil;in), satıcı y&ouml;netim platformu veya Pulse gibi bir Vihobook
        uygulaması vb. online hizmetlerin bir İş Ortağı tarafından kullanılması
        halinde toplanır.
      </p>
      <p>Toplanan veriler şunları i&ccedil;erebilir:</p>
      <ul>
        <li>
          <p>Dil ayarları,</p>
        </li>
        <li>
          <p>IP adresi,</p>
        </li>
        <li>
          <p>Konum,</p>
        </li>
        <li>
          <p>Cihaz ayarları,</p>
        </li>
        <li>
          <p>Cihaz işletim sistemi,</p>
        </li>
        <li>
          <p>G&uuml;nl&uuml;k bilgileri,</p>
        </li>
        <li>
          <p>Kullanım s&uuml;resi,</p>
        </li>
        <li>
          <p>İstenen URL,</p>
        </li>
        <li>
          <p>Durum raporu,</p>
        </li>
        <li>
          <p>
            Kullanıcı aracısı (tarayıcı s&uuml;r&uuml;m&uuml; hakkında bilgi),
          </p>
        </li>
        <li>
          <p>
            Sonu&ccedil; (g&ouml;r&uuml;nt&uuml;leyen veya rezervasyon
            yaptıran),
          </p>
        </li>
        <li>
          <p>G&ouml;z atma ge&ccedil;mişi,</p>
        </li>
        <li>
          <p>Kullanıcı Booking Kimliği,</p>
        </li>
        <li>
          <p>G&ouml;r&uuml;nt&uuml;lenen veri t&uuml;r&uuml;.</p>
        </li>
      </ul>
      <h5>Başkaları hakkında bize verdiğiniz kişisel veriler</h5>
      <p>
        &Ccedil;alışanlarınıza ait veriler gibi diğer şahısların kişisel
        verilerini iş amacıyla paylaşarak bu şahısların, kişisel verilerinin bu
        gizlilik bildirimine uygun olarak Vihobook tarafından kullanımı hakkında
        bilgilendirildiğini onaylamış olursunuz. Ayrıca, sizin i&ccedil;in
        ge&ccedil;erli olan yasa ve y&ouml;netmelikler uyarınca gerekli t&uuml;m
        onayları aldığınızı da onaylamış olursunuz.
      </p>
      <h5>Diğer kaynaklardan aldığımız diğer bilgiler</h5>
      <ul>
        <li>
          <h4>&Ouml;deme aczi hakkında bilgiler</h4>
          <p>
            Vihobook, &ouml;deme aczi konularında &ouml;deme aczi
            idarecilerinden, mahkemelerden veya diğer resmi makamlardan İş
            Ortaklarına ilişkin bilgi alabilir.
          </p>
        </li>
        <li>
          <h4>Emniyet ve vergi makamları talepleriyle ilgili veriler</h4>
          <p>
            Emniyet veya vergi makamları, bir soruşturmadan etkilenmeleri
            halinde İş Ortakları hakkında ek bilgiye sahip olan Vihobook ile
            iletişime ge&ccedil;ebilir.
          </p>
        </li>
        <li>
          <h4>
            Dolandırıcılığın tespiti ve &ouml;nlenmesi, risk y&ouml;netimi ve
            uyumluluk
          </h4>
          <p>
            Belirli durumlarda ve ge&ccedil;erli yasalarca izin verildiği
            &ouml;l&ccedil;&uuml;de dolandırıcılığın tespiti ve &ouml;nlenmesi,
            risk y&ouml;netimi ve uyumluluk ama&ccedil;larına y&ouml;nelik
            olarak Vihobook&rsquo;un &uuml;&ccedil;&uuml;nc&uuml; taraf
            kaynaklar &uuml;zerinden veri toplaması gerekebilir.
          </p>
        </li>
      </ul>
      <h4>İşleme ama&ccedil;ları</h4>
      <p>
        Vihobook, bazıları kişisel veriler olabilecek ve &ouml;nceden
        a&ccedil;ıklanmış İş Ortağı bilgilerini ilgili durumlarda şu
        ama&ccedil;larla kullanır:
      </p>
      <h4>A. Kayıt işlemi ve idaresi</h4>
      <p>
        Vihobook, İş Ortağı ile iş ilişkisini y&ouml;netmek i&ccedil;in hesap
        bilgilerini, iletişim bilgilerini ve finansal verileri kullanır. Buna
        kayıt ve doğrulama ama&ccedil;ları da dahildir.
      </p>
      <p>
        Bir İş Ortağının adı ve adresi dahil olmak &uuml;zere belirli bilgiler,
        İş Ortağı ve Vihobook arasında imzalanan s&ouml;zleşmeye uygun olarak
        başka ama&ccedil;lar i&ccedil;in kullanılabilir. Bir
        &uuml;&ccedil;&uuml;nc&uuml; taraf web sitesi aracılığıyla kendi
        tesislerinde Gezi Rezervasyonu yapılmasını sağlamak i&ccedil;in bir
        konaklama tesisi ortağının iletişim bilgilerini kullanmak, bu duruma
        &ouml;rnek olarak verilebilir.
      </p>
      <h4>B. M&uuml;şteri hizmetleri</h4>
      <p>
        Vihobook, İş Ortakları tarafından sağlanan (ve kişisel veriler
        i&ccedil;erebilen) bilgileri, İş Ortakları ya da m&uuml;şterilerden
        gelen talepler, sorular veya şikayetlere yanıt vermek gibi destek
        hizmetleri sağlamak i&ccedil;in kullanır.
      </p>
      <h4>C. Pazarlama dahil diğer faaliyetler</h4>
      <p>
        Potansiyel bir İş Ortağı online kaydı tamamlamadıysa Vihobook, kayıt
        işlemini tamamlaması i&ccedil;in İş Ortağına hatırlatma
        g&ouml;nderebilir. Bu ek hizmetin (gelecekteki) İş Ortaklarımız
        i&ccedil;in kullanışlı olduğuna inanıyoruz &ccedil;&uuml;nk&uuml;
        s&ouml;z konusu hizmet, t&uuml;m kayıt bilgilerini tekrar doldurmalarına
        gerek kalmadan İş Ortaklarımızın kaydı tamamlamalarına olanak tanıyor.
      </p>
      <p>
        Vihobook, İş Ortaklarını kendileri ile alakalı veya
        ilgilenebileceklerini d&uuml;ş&uuml;nd&uuml;ğ&uuml;m&uuml;z etkinliklere
        katılmaya ve ev sahipliği yapmaya davet edebilir. Kişisel verileri, İş
        Ortaklarının Vihobook &uuml;r&uuml;n ve hizmetlerinin sunumu ve
        kullanımı hakkında sık&ccedil;a sorulan soruların yanıtlarını bulmasını
        sağlayan online forumlar sunmak ve barındırmak i&ccedil;in de
        kullanabiliriz.
      </p>
      <p>
        İş ilişkisi ile ilgili olarak Vihobook, kişisel verileri sistem veya
        &uuml;r&uuml;n g&uuml;ncellemeleri hakkında bilgi vermek, Vihobook haber
        b&uuml;ltenini g&ouml;ndermek, İş Ortaklarını tavsiye programlarına veya
        yarışmalara (Booking Hero gibi) katılmaya davet etmek ya da diğer
        pazarlama iletişimleri gibi iletiler i&ccedil;in kullanır. Kişisel
        verileri elektronik olarak doğrudan pazarlama mesajları g&ouml;ndermek
        i&ccedil;in kullandığımızda, ilgili mesajları almamanız i&ccedil;in
        uygun se&ccedil;enekler sunarız.
      </p>
      <h4>D. Mesajlaşma ara&ccedil;ları</h4>
      <p>
        Vihobook, rezervasyondan &ouml;nce veya sonra konuklarla (olası veya
        mevcut) iletişim kurmaları i&ccedil;in İş Ortaklarına farklı
        y&ouml;ntemler sunabilir.
      </p>
      <p>
        İş Ortakları ayrıca rezervasyon bilgilerini veya soruları konuklara
        iletmek i&ccedil;in Vihobook ile iletişime ge&ccedil;ebilir veya
        alıcının her zaman Vihobook olduğu takma e-postalar aracılığıyla
        konuklarla iletişim kurabilir.
      </p>
      <p>
        Vihobook, İş Ortakları ve konuklar arasındaki iletilere erişebilir.
        Ayrıca şu ama&ccedil;lar doğrultusunda iletileri incelemek, taramak ve
        analiz etmek i&ccedil;in otomatik sistemler kullanırız:
      </p>
      <ul>
        <li>
          <p>G&uuml;venlik,</p>
        </li>
        <li>
          <p>Dolandırıcılık faaliyetlerinin &ouml;nlenmesi,</p>
        </li>
        <li>
          <p>Yasal ve mevzuata tabi gerekliliklerle uyumluluk,</p>
        </li>
        <li>
          <p>Olası k&ouml;t&uuml; davranışlarla ilgili soruşturmalar,</p>
        </li>
        <li>
          <p>&Uuml;r&uuml;n geliştirme ve iyileştirme,</p>
        </li>
        <li>
          <p>Araştırma,</p>
        </li>
        <li>
          <p>
            M&uuml;şteri etkileşimi (ilgilenebileceklerini
            d&uuml;ş&uuml;nd&uuml;ğ&uuml;m&uuml;z bilgi veya teklifleri
            konuklarla paylaşmak gibi),
          </p>
        </li>
        <li>
          <p>M&uuml;şteri desteği ya da teknik destek.</p>
        </li>
      </ul>
      <p>
        Vihobook&apos;un iletişim ara&ccedil;ları kullanılarak g&ouml;nderilen
        ve alınan mesajlar Vihobook tarafından alınır ve saklanır.
      </p>
      <h4>E. Analiz, iyileştirme ve araştırma:</h4>
      <p>
        Vihobook bizimle paylaşılan ve kişisel veri i&ccedil;erebilecek
        bilgileri analiz ama&ccedil;ları i&ccedil;in kullanır. Bu, Vihobook
        &uuml;r&uuml;nlerini ve hizmetlerini geliştirme ve kullanıcı deneyimini
        iyileştirme &ccedil;abamızın bir par&ccedil;asıdır.
      </p>
      <p>
        Ayrıca bu veriler; test etme, sorun giderme ve Vihobook&apos;un online
        hizmetlerinin işlevselliğini ve kalitesini iyileştirmek i&ccedil;in de
        kullanılabilir. Zaman zaman İş Ortaklarını da anketlere katılmaya ve
        başka pazar araştırmaları yapmaya davet ederiz.
      </p>
      <p>
        Belirli İş Ortakları, Vihobook ile etkileşime ge&ccedil;me ve/veya başka
        İş Ortaklarıyla deneyimler paylaşmaları i&ccedil;in &ouml;zel bir online
        platforma katılmaya davet edilebilir.
      </p>
      <p>
        Kişisel verilerinizin bu Gizlilik Bildiriminde a&ccedil;ıklanandan
        farklı şekilde nasıl işlenebileceğini anlamak i&ccedil;in l&uuml;tfen
        bir ankete, pazar araştırmasına veya online platforma katılmaya davet
        edildiğinizde Vihobook&rsquo;un sağladığı bilgilere bakın.
      </p>
      <h4>
        F. G&uuml;venlik, dolandırıcılığın tespit edilmesi ve &ouml;nlenmesi
      </h4>
      <p>
        Dolandırıcılığı ve diğer yasa dışı faaliyetleri soruşturmak,
        &ouml;nlemek ve saptamak i&ccedil;in bize sağlanan ve kişisel verileri
        i&ccedil;erebilecek bilgileri işleriz. Bu, bir İş Ortağının kayıt
        s&uuml;reci kapsamında doğrulama i&ccedil;in Vihobook&rsquo;a sağladığı
        kişisel veriler, otomatik olarak toplanan kişisel veriler veya
        &uuml;&ccedil;&uuml;nc&uuml; taraf kaynaklardan elde edilen kişisel
        veriler (konuklardan alınanlar da dahil) gibi verileri
        i&ccedil;erebilir.
      </p>
      <p>
        Vihobook, gerektiğinde yetkili makamlar tarafından soruşturma ve
        yaptırımı kolaylaştırmak i&ccedil;in kişisel verileri de kullanabilir.
        Kişisel veriler, bu ama&ccedil;lar doğrultusunda emniyet makamları ile
        paylaşılabilir.
      </p>
      <p>
        Vihobook, kullanıcıların kimlik doğrulaması da dahil olmak &uuml;zere
        kişisel verileri risk değerlendirmesi ve g&uuml;venlik ama&ccedil;ları
        i&ccedil;in de kullanabilir. Ayrıca &uuml;&ccedil;&uuml;nc&uuml; taraf
        risk y&ouml;netimi i&ccedil;in &uuml;&ccedil;&uuml;nc&uuml; taraf hizmet
        sağlayıcılarını kullanırız. Bu sağlayıcılar, İş Ortaklarımızın iş riski
        profilini değerlendirmemize yardımcı olur. Ayrıca, ge&ccedil;erli
        yasaların izin verdiği &ouml;l&ccedil;&uuml;de, mal sahiplerinin veya İş
        Ortaklarının cezai h&uuml;k&uuml;mleri ve su&ccedil;ları hakkında olası
        bilgiler i&ccedil;erebilen &uuml;&ccedil;&uuml;nc&uuml; taraf durum
        tespiti raporları sağlayabilirler.
      </p>
      <h4>G. Yasal ve yasalara uyumluluk</h4>
      <p>
        Vihobook&rsquo;un, kendi tarafına sağlanan (ve kişisel veriler
        i&ccedil;erebilen) bilgileri belirli durumlarda yasal uyuşmazlıkları ele
        almak ve &ccedil;&ouml;z&uuml;me kavuşturmak i&ccedil;in veya mevzuata
        tabi soruşturmalar, risk y&ouml;netimi ve uyumluluk doğrultusunda
        kullanması gerekir. Ayrıca, mantıken beklendiği gibi ve kurum i&ccedil;i
        koşul ile prosed&uuml;rlere uygun olarak bu bilgileri, İş Ortakları ile
        aramızdaki s&ouml;zleşmeyi/s&ouml;zleşmeleri uygulamak ya da bir konuğun
        dahil olduğu bir şikayeti veya hak talebini &ccedil;&ouml;z&uuml;me
        kavuşturmak i&ccedil;in de kullanabiliriz.
      </p>
      <p>
        Ayrıca, yasaların gerektirdiği veya yetkili makamlardan gelen taleplere
        yanıt vermek i&ccedil;in kesinlikle gerekli olduğu durumlarda İş
        Ortakları hakkındaki bilgileri (kişisel veriler dahil) paylaşmamız
        gerekebilir. Buna vergi daireleri, mahkemeler, diğer h&uuml;k&uuml;met
        ve kamu makamları veya yerel belediyeler dahildir (&ouml;r. kısa vadeli
        kiralama kanunları ile ilgili olarak).
      </p>
      <p>
        Hukuki sonu&ccedil;lar doğuran veya sizi ya da diğer ger&ccedil;ek
        kişileri &ouml;nemli &ouml;l&ccedil;&uuml;de etkileyen otomatik kişisel
        veri işleme y&ouml;ntemleri kullanırsak sizin ya da diğer kişinin hak ve
        &ouml;zg&uuml;rl&uuml;klerini korumak i&ccedil;in uygun tedbirler
        uygularız. İnsan m&uuml;dahalesi talep etme hakkı da bunlara dahildir.
      </p>
      <h5>Yasal dayanaklar</h5>
      <p>
        A ve B ama&ccedil;larına istinaden Vihobook, kişisel verilerin
        işlenmesinin İş Ortağı ve Vihobook arasındaki s&ouml;zleşmenin yerine
        getirilmesi i&ccedil;in gerekli olduğuna dair yasal dayanağa g&ouml;re
        hareket eder. Gerekli bilgiler sağlanamazsa Vihobook, bir Gezi
        Tedarik&ccedil;isini kaydedemez veya bir İş Ortağıyla başka halde
        &ccedil;alışamaz ya da m&uuml;şteri hizmetleri sağlayamaz.
      </p>
      <p>
        C&apos;den G&apos;ye kadar olan ama&ccedil;lara istinaden Vihobook,
        hizmet sağlama veya İş ortaklarından hizmet alma, dolandırıcılığı
        &ouml;nleme ve hizmetlerini iyileştirme i&ccedil;in meşru menfaatlerine
        itibar eder. Vihobook&apos;un veya bir &uuml;&ccedil;&uuml;nc&uuml;
        tarafın meşru menfaatine hizmet etmek i&ccedil;in kişisel verileri
        kullanırken durumdan etkilenen bireyin hakları ile bilgilerinin
        korunmasını Vihobook&apos;un ve/veya &uuml;&ccedil;&uuml;nc&uuml;
        tarafın hak ve menfaatleriyle dengeleriz.
      </p>
      <p>
        F ve G ama&ccedil;ları bakımından Vihobook, ge&ccedil;erli olduğu
        durumlarda yasal y&uuml;k&uuml;ml&uuml;l&uuml;klere (emniyet
        makamlarının hukuka uygun talepleri gibi) uygunluğa itibar eder. Son
        olarak, ge&ccedil;erli yasalar doğrultusunda gerekli olduğu durumlarda
        Vihobook, kişisel verileri pazarlama veya kanunların gerektirdiği başka
        ama&ccedil;lar doğrultusunda işlemeden &ouml;nce sizin rızanızı alır.
      </p>
      <p>
        C&rsquo;den E&apos;ye kadar olan kısımda belirtilen işleme
        t&uuml;r&uuml;ne itiraz etmek istiyorsanız ve doğrudan hari&ccedil;
        bırakılmanın bir yolunu bulamıyorsanız (&ouml;rneğin hesap
        ayarlarınızda) l&uuml;tfen&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>{" "}
        ile iletişime ge&ccedil;in.
      </p>
      <h4>Verilerin paylaşımı</h4>
      <ul>
        <li>
          <h4>İştiraklerle paylaşım</h4>
          <p>
            Vihobook hizmetlerinin kullanımını desteklemek i&ccedil;in (kişisel
            veriler i&ccedil;erebilen) bilgileriniz Vihobook şirket grubunun
            &uuml;yeleriyle paylaşılabilir. Vihobook şirket grubu hakkında
            bilmeniz gereken her şeyi &ouml;ğrenmek i&ccedil;in&nbsp; Vihobook
            Hakkında sayfamızı ziyaret edin.
          </p>
        </li>
        <li>
          <h4>&Uuml;&ccedil;&uuml;nc&uuml; taraflarla paylaşım</h4>
          <p>
            İş Ortaklarına ait (ve kişisel veriler i&ccedil;erebilen) bilgileri,
            yasaların izin verdiği &ouml;l&ccedil;&uuml;de ve aşağıda
            a&ccedil;ıklandığı şekilde &uuml;&ccedil;&uuml;nc&uuml; taraflarla
            paylaşırız.
          </p>
          <ul>
            <li>
              Hizmet sağlayıcıları (tedarik&ccedil;iler dahil). &Uuml;r&uuml;n
              ve hizmetlerimizi sağlamak, dolandırıcılığı tespit etmek ve
              &ouml;nlemek, verileri saklamak, iş s&uuml;re&ccedil;lerimizi
              başka şekilde desteklemek veya &uuml;&ccedil;&uuml;nc&uuml; taraf
              şirketlerinin bizim adımıza iş yapması amacıyla kişisel verileri
              &uuml;&ccedil;&uuml;nc&uuml; taraf hizmet sağlayıcılarıyla
              paylaşırız.
            </li>
            <li>
              &Ouml;deme Sağlayıcıları ve diğer mali kuruluşlar. İş Ortağı ile
              Vihobook ya da konuk ile İş Ortağı arasındaki &ouml;demeleri
              işlemek i&ccedil;in ilgili kişisel veriler, &ouml;deme
              sağlayıcılarıyla ve diğer mali kuruluşlarla paylaşılır.
            </li>
            <li>
              Y&uuml;r&uuml;rl&uuml;kteki yasaların gerektirdiği şekilde
              yaptırım listesi taraması veya risk y&ouml;netimi.
            </li>
            <li>
              Mecburi a&ccedil;ıklama. Yasaların gerektirdiği durumlarda,
              hizmetlerimizin yerine getirilmesi i&ccedil;in kesinlikle gerekli
              olması halinde, adli kovuşturmalarda veya haklarımızı, diğer
              Vihobook Holdings Inc. grubu &uuml;yelerinin ya da kullanıcıların
              haklarını korumak i&ccedil;in kişisel verileri emniyet makamları,
              soruşturma kurumları veya diğer grup &uuml;yeleriyle
              paylaşabiliriz.
            </li>
          </ul>
        </li>
        <li>
          <h4>Toplu verileri paylaşma ve a&ccedil;ığa &ccedil;ıkarma</h4>
          <p>
            Bilgileri &uuml;&ccedil;&uuml;nc&uuml; taraflarla topluca ve/veya
            alıcının sizi tanımlayamayacağı başka bir bi&ccedil;imde
            paylaşabiliriz (&ouml;rneğin sekt&ouml;r analizi veya demografik
            profil oluşturma i&ccedil;in).
          </p>
        </li>
      </ul>
      <h4>Vihobook Holdings Inc. şirket grubu i&ccedil;inde veri paylaşımı</h4>
      <p>
        Vihobook, Vihobook Holdings Inc. şirket grubunun bir par&ccedil;asıdır.
        Grup hakkında daha fazla bilgi i&ccedil;in l&uuml;tfen&nbsp; Vihobook
        Holdings web sitesini ziyaret edin.
      </p>
      <p>
        Vihobook, Vihobook Holdings Inc. şirket grubundaki diğer şirketlerden İş
        Ortakları hakkında kişisel veriler alabilir veya İş Ortaklarının kişisel
        verilerini bu şirketlerle paylaşabilir. Bu paylaşım, t&uuml;m
        s&ouml;zleşme şartlarına tabi olarak, aşağıda a&ccedil;ıklanan
        ama&ccedil;lar i&ccedil;in yapılır.
      </p>
      <p>
        Vihobook&rsquo;un m&uuml;şterilerine doğrudan veya İş Ortaklarımız
        aracılığıyla kara yolu taşımacılığı hizmetleri sunmak i&ccedil;in
        Rentalcars.com ile yakın bir şekilde &ccedil;alışması, bu gruplar arası
        iş birliğine &ouml;rnek olarak g&ouml;sterilebilir.
      </p>
      <p>
        Vihobook Holdings Inc. grubundaki t&uuml;m şirketlerin, online
        platformlarında t&uuml;m kullanıcıları dolandırıcılık faaliyetlerine
        karşı korumamız i&ccedil;in İş Ortaklarının kişisel verilerini
        karşılıklı olarak alıp vermesi gerekebilir.
      </p>
      <p>
        Vihobook Holdings Inc. şirketler grubu i&ccedil;indeki veri paylaşımının
        ama&ccedil;ları şunlardır:
      </p>
      <ul>
        <li>
          <p>
            A. Rezervasyon hizmetleri de dahil olmak &uuml;zere hizmetler
            sağlamak, İş Ortağı hesaplarını y&ouml;netmek (satıcı y&ouml;netimi
            dahil) ve destek sağlamak,
          </p>
        </li>
        <li>
          <p>
            B. Dolandırıcılık faaliyetlerini ve diğer yasa dışı faaliyetleri
            &ouml;nlemek, tespit etmek ve soruşturmak,
          </p>
        </li>
        <li>
          <p>C. Analiz ve &uuml;r&uuml;n iyileştirme ama&ccedil;ları,</p>
        </li>
        <li>
          <p>
            D. Online hizmetleri kişiselleştirmek veya alıcının rızasıyla (veya
            y&uuml;r&uuml;rl&uuml;kteki yasanın izin verdiği şekilde) pazarlama
            ama&ccedil;lı g&ouml;nderiler iletmek,
          </p>
        </li>
        <li>
          <p>
            E. Koşullarla ve y&uuml;r&uuml;rl&uuml;kteki yasalarla uyumluluk
            sağlamak.
          </p>
        </li>
      </ul>
      <p>
        Vihobook, A&rsquo;dan D&apos;ye kadar olan kısımlarda a&ccedil;ıklandığı
        &uuml;zere kişisel verileri almak ve paylaşmak i&ccedil;in bizim ve
        Vihobook Holdings Inc. grup şirketlerinin meşru menfaatine itibar eder.
        Bu, s&ouml;z konusu hizmetleri iyileştirmek ve dolandırıcılığı
        &ouml;nlemek de dahil olmak &uuml;zere hizmet sağlamak veya İş
        Ortaklarından hizmet almak i&ccedil;indir.
      </p>
      <p>
        Vihobook, Vihobook&apos;un veya bir &uuml;&ccedil;&uuml;nc&uuml; tarafın
        meşru menfaatine hizmet etmek i&ccedil;in kişisel verileri kullanırken
        durumdan etkilenen bireylerin kişisel verilerinin korunması amacıyla
        haklarını ve menfaatlerini Vihobook veya &uuml;&ccedil;&uuml;nc&uuml;
        tarafın hakları ve menfaatleriyle dengeler.
      </p>
      <p>
        E&rsquo;de belirtilen ama&ccedil; doğrultusunda Vihobook, ge&ccedil;erli
        olduğu durumlarda yasal y&uuml;k&uuml;ml&uuml;l&uuml;klerle (emniyet
        makamlarının hukuka uygun talepleri gibi konularda) uyumluluk sağlamaya
        da itibar eder.
      </p>
      <p>
        A&rsquo;dan E&apos;ye kadar olan kısımlarda belirtilen işleme
        t&uuml;r&uuml;ne itiraz etmek istiyorsanız ve doğrudan hari&ccedil;
        bırakılmanın bir yolunu bulamıyorsanız (&ouml;rneğin hesap
        ayarlarınızda) l&uuml;tfen&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>{" "}
        ile iletişime ge&ccedil;in.
      </p>
      <h4>Vihobook Transport Limited ile veri paylaşımı</h4>
      <p>
        Rentalcars.com olarak da ticari faaliyette bulunan Vihobook Transport
        Limited (&ldquo;BTL&rdquo;), Birleşik Krallık yasalarına g&ouml;re
        kurulmuş ve 100 New Bridge Street, London, EC4V 6JA adresinde ofisleri
        bulunan &ouml;zel bir limitet şirkettir.
      </p>
      <p>
        BTL, Vihobook Holdings Inc. şirket grubunun bir par&ccedil;asıdır ve
        Vihobook&apos;un web siteleri ve uygulamaları aracılığıyla sunulan kara
        yolu taşımacılığı hizmetleri, Vihobook markası altında BTL tarafından
        işletilmektedir.
      </p>
      <p>
        Vihobook B.V., BTL&nbsp; bağlı ortak kayıt sayfasını işletir ve hesap
        y&ouml;netimi s&uuml;recini y&uuml;r&uuml;t&uuml;r. Bu kayıt ve hesap
        y&ouml;netimi s&uuml;recinde BTL İş Ortaklarından toplanan ve kişisel
        verileri i&ccedil;erebilecek bilgiler, bu Gizlilik Bildiriminde
        belirtilen ama&ccedil;lar doğrultusunda BTL ile paylaşılır.
      </p>
      <p>
        Bir BTL İş Ortağıysanız ve kişisel verilerinizin işlenmesiyle ilgili
        sorularınız ya da şikayetleriniz varsa veya bu Gizlilik Bildirimi
        kapsamında sahip olduğunuz hakları kullanmak istiyorsanız
        l&uuml;tfen&nbsp;
        <a href="mailto:customerr@vihobook.com">
          customerr@vihobook.com
        </a>{" "}
        adresinden BTL ile iletişime ge&ccedil;in.
      </p>
      <h4>Uluslararası veri aktarımları</h4>
      <p>
        Bu Gizlilik Bildiriminde a&ccedil;ıklandığı &uuml;zere İş Ortaklarının
        kişisel verilerinin aktarımı, s&ouml;z konusu verilerin yurt dışına
        aktarımını i&ccedil;erebilir. Bu aktarımlar, Avrupa
        Birliği&rsquo;ndekiler de dahil olmak &uuml;zere bilgileri ilk
        sağladığınız &uuml;lke veya &uuml;lkelerinki kadar kapsamlı veri koruma
        yasaları olmayan &uuml;lkelere de yapılabilir. Bu gibi durumlarda
        kişisel verilerinizi bu Gizlilik Bildiriminde a&ccedil;ıklandığı şekilde
        koruruz.
      </p>
      <p>
        Avrupa yasalarının gerektirdiği durumlarda, bu t&uuml;r aktarımların
        Avrupa gizlilik yasasıyla uyumlu olmasını sağlamak i&ccedil;in uygun
        tedbirler uygularız. &Ouml;zellikle de kişisel verileriniz
        &uuml;&ccedil;&uuml;nc&uuml; taraf hizmet sağlayıcılarına
        aktarıldığında, bu sağlayıcılarla uygun akdi, kurumsal ve teknik
        tedbirler belirler ve uygularız.
      </p>
      <p>
        Bu tedbirler, Avrupa Komisyonu tarafından onaylanan &ldquo;Standart
        S&ouml;zleşme Maddeleri&rdquo; kullanılarak, verilerin aktarılabileceği
        &uuml;lkeler incelenerek ve belirli teknik ve kurumsal g&uuml;venlik
        tedbirleri uygulanarak belirlenebilir. Uygun olduğunda, aşağıda
        verilen&nbsp; iletişim bilgilerini kullanarak bu s&ouml;zleşmeli
        anlaşmaların bir kopyasını bizden isteyebilirsiniz.
      </p>
      <h4>G&uuml;venlik</h4>
      <p>
        Kişisel verilere yetkisiz erişimi ve bu verilerin k&ouml;t&uuml;ye
        kullanılmasını &ouml;nlemek i&ccedil;in uyguladığımız prosed&uuml;rler
        mevcuttur.
      </p>
      <p>
        Kişisel veriler i&ccedil;eren bilgileri korumak ve muhafaza etmek
        amacıyla uygun iş sistemleri ve prosed&uuml;rleri kullanırız. Aynı
        zamanda, sunucularımızdaki kişisel verilere erişim ve bu verilerin
        kullanımı hususunda g&uuml;venlik prosed&uuml;rleriyle teknik ve fiziki
        kısıtlamalardan yararlanıyoruz. Kişisel verilere sadece yetkili
        kişilerin yaptıkları işin s&uuml;resi boyunca erişmelerine izin verilir.
      </p>
      <h4>Verilerin saklanması</h4>
      <p>
        İş Ortağıyla iş ilişkisini y&ouml;netmek, İş Ortağına Vihobook
        hizmetlerini sunmak, ge&ccedil;erli yasalarla (belge saklamayla ilgili
        olanlar da dahil) uyumluluk sağlamak, taraflardan herhangi birine
        ilişkin uyuşmazlıkları ve hak taleplerini &ccedil;&ouml;z&uuml;me
        kavuşturmak amacıyla ve işimizi s&uuml;rd&uuml;rmemiz i&ccedil;in diğer
        nedenler gerektirdiği s&uuml;rece kişisel verileri saklarız.
      </p>
      <p>
        Bir İş Ortağı olarak hakkınızda sakladığımız t&uuml;m kişisel veriler,
        bu Gizlilik Bildirimine ve kurum i&ccedil;i saklama esaslarımıza
        tabidir. İşlediğimiz &ccedil;eşitli kişisel veri t&uuml;rlerine
        &ouml;zel saklama s&uuml;releri hakkında sorularınız varsa
        l&uuml;tfen&nbsp; aşağıdaki iletişim bilgilerini kullanarak iletişime
        ge&ccedil;in.
      </p>
      <h4>Se&ccedil;enek ve haklarınız</h4>
      <p>
        Bulunduğunuz konuma veya kişisel verilerinizi işleyen Vihobook
        kuruluşuna bağlı olarak bu gizlilik bildiriminde belirtildiği &uuml;zere
        kişisel verilerin işlenmesi i&ccedil;in farklı haklar ge&ccedil;erli
        olabilir. Uygun şekilde:
      </p>
      <ul>
        <li>
          Hakkınızda elimizde bulunan kişisel verilerin bir kopyasını bizden
          isteyebilirsiniz,
        </li>
        <li>
          Kişisel verilerinizdeki herhangi bir değişiklik hakkında bizi
          bilgilendirebilir veya hakkınızda elimizde bulunan herhangi bir
          kişisel veriyi d&uuml;zeltmemizi isteyebilirsiniz,
        </li>
        <li>
          Belirli durumlarda, hakkınızda elimizde bulunan kişisel verileri
          silmemizi veya engellememizi veya bunları kısıtlamamızı isteyebilir ya
          da kişisel verilerinizi kullandığımız belirli yollara itiraz
          edebilirsiniz,
        </li>
        <li>
          Belirli durumlarda, bize vermiş olduğunuz kişisel verileri
          &uuml;&ccedil;&uuml;nc&uuml; bir tarafa g&ouml;ndermemizi
          isteyebilirsiniz.
        </li>
      </ul>
      <p>
        Kişisel verilerinizi rızanıza dayanarak kullandığımız durumlarda,
        ge&ccedil;erli yasalara tabi olmak koşuluyla bu rızayı dilediğiniz zaman
        geri &ccedil;ekmeye hakkınız vardır. Kişisel verilerinizi meşru menfaat
        veya kamu menfaatine dayanarak işlediğimiz durumlarda, ge&ccedil;erli
        yasalara tabi olarak istediğiniz zaman itiraz etme hakkınız da vardır.
      </p>
      <p>
        Konumunuzdan veya s&ouml;zleşme yaptığınız Vihobook kuruluşundan
        bağımsız olarak, sakladığımız verilerin eksiksiz, hatasız ve g&uuml;ncel
        olmasını sağlamak konusunda İş Ortaklarımıza g&uuml;veniriz. L&uuml;tfen
        kişisel verilerinizde yaşanan t&uuml;m değişiklikler veya hatalı
        durumlar konusunda daima bizi gecikmeden bilgilendirin.
      </p>
      <p>
        İşletmenizin Vihobook B.V.&apos;den farklı bir kuruluşla s&ouml;zleşmesi
        varsa ve bu kuruluş ile Vihobook B.V. arasındaki ilişki hakkında daha
        fazla bilgi istiyorsanız bizimle her zaman&nbsp; iletişime
        ge&ccedil;ebilirsiniz . Bu iş ilişkisinin bir par&ccedil;ası olarak
        hakkınızda işlenen kişisel verilere ilişkin haklarınızı kullanmak
        istiyorsanız da bunu yapmalısınız.
      </p>
      <h4>Bizimle iletişime ge&ccedil;in</h4>
      <p>
        Kişisel verilerinizi işlememiz konusunda herhangi bir sorunuz, talebiniz
        veya şikayetiniz varsa ya da bu Gizlilik Bildirimi kapsamındaki
        haklarınızdan herhangi birini kullanmak istiyorsanız veri koruma
        g&ouml;revlimizle&nbsp;
        <a href="mailto:customer@vihobook.com">
          customer@vihobook.com
        </a>{" "}
        adresinden iletişime ge&ccedil;ebilirsiniz. Ayrıca bulunduğunuz konumda
        verilerin korunmasıyla ilgilenen makamla da iletişime
        ge&ccedil;ebilirsiniz.
      </p>
      <p>
        Ge&ccedil;erli gizlilik yasaları, d&uuml;zenlemeleri ve kılavuzlarına
        dayalı olarak kurum i&ccedil;i koşullar ve prosed&uuml;rler aracılığıyla
        bize bildirilen gizliliğe &ouml;zg&uuml; soruları, talepleri ve
        şikayetleri ele alıyoruz. İş Ortağı geri bildirimlerini de dikkate
        alarak bu koşullar ve prosed&uuml;rleri d&uuml;zenli olarak g&ouml;zden
        ge&ccedil;irip iyileştiriyoruz.
      </p>
      <h4>Bu Gizlilik Bildirimindeki Değişiklikler</h4>
      <p>
        İşimizde s&uuml;rekli olarak yaşanan değişiklikler gibi bu Gizlilik
        Bildirimi de zaman zaman değişmektedir. &Ouml;nemli veya sizi etkileyen
        değişiklikler yapmayı planlıyorsak sizinle her zaman &ouml;nceden
        iletişime ge&ccedil;eriz. Kişisel verilerinizi yukarıda detaylı bir
        şekilde a&ccedil;ıklanmayan ama&ccedil;lar doğrultusunda işlemeye
        başlamamız, bu t&uuml;r bir değişikliğe &ouml;rnek g&ouml;sterilebilir.
      </p>
    </>
  )
}
