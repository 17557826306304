import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import CheckBoxes from "./Checkboxes"
import styles from "./styles.module.scss"

const RatePlans = props => {
  const { boardTypes, setBoardTypes, boardTypesCheckHandler } = props
  const { t } = useTranslation()
  const [isRatePlanFormOpen, setIsRatePlanFormOpen] = useState(false)
  const [allBoardTypes, setAllBoardTypes] = useState(true)

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("Which rate plans will this promotion apply to?")}
        </h5>
        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={allBoardTypes}
            onChange={(e, v) => {
              setAllBoardTypes(v)
              setBoardTypes(boardTypes.map(i => ({ ...i, isChecked: true })))
            }}
          >
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("All rate plans")}
              className={styles.radioLabel}
              onClick={() => {
                setIsRatePlanFormOpen(false)
              }}
              value={true}
            />

            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("Select rate plans")}
              className={styles.radioLabel}
              onClick={() => {
                setIsRatePlanFormOpen(true)
              }}
              value={false}
            />
          </RadioGroup>
        </FormControl>

        {isRatePlanFormOpen && (
          <CheckBoxes
            isOpen={true}
            title="Select at least 1 rate type"
            items={boardTypes}
            itemCheckHandler={boardTypesCheckHandler}
            close={() => {
              setIsRatePlanFormOpen(false)
            }}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default RatePlans
