import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import PropertiesTable from "components/Villa/PropertiesTable/index"
import EditModal from "./EditModal"

class Photos extends Component {
  state = {
    isEditModalOpen: false,
    properties: [],
    propertyTypes: [],
    selectedRowId: "",
    selectedRowData: null,
    showWarningAlert: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    loading: false,
    error: false,
    errorMessage: "",
  }

  componentDidMount = () => {
    this.fetchProperties()
    this.fetchPropertyTypes()
  }

  fetchProperties = async () => {
    try {
      this.setState({ loading: true })
      const { activePage, itemsCountPerPage, searchText } = this.state

      const url = `/property/fetch-by-page-number?page=${activePage}&limit=${itemsCountPerPage}&searchText=${searchText}`

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: "",
          properties: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  fetchPropertyTypes = async () => {
    try {
      this.setState({ loading: true })

      const url = `/property-types`

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }

      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({ propertyTypes: response.data.propertyTypes })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedPropertyId => {
    let selectedProperty = this.state.properties.find(property => {
      return property.id == selectedPropertyId
    })
    this.setState({
      selectedRowId: selectedPropertyId,
      selectedRowData: selectedProperty,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchProperties)
  }

  refreshTable = () => {
    this.setState(
      { activePage: 1, searchText: "", totalItemsCount: 0 },
      this.fetchProperties
    )
  }

  render() {
    const { t } = this.props
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      propertyTypes,
      loading,
      error,
      errorMessage,
    } = this.state

    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <>
          <ControlButtons
            displayAddBtn={true}
            addTitle="Add/Remove Photo"
            displayEditBtn={false}
            displayDeleteBtn={false}
            onAddBtn={() => {
              if (this.state.selectedRowId) {
                this.setState({ isEditModalOpen: true })
              } else {
                this.setState({ showWarningAlert: true })
              }
            }}
            onPrintBtn={() => {}}
            onPdfBtn={() => {}}
            onExcelBtn={() => {}}
            isApiSearch={true}
            searchAPI={searchText => {
              this.setState({ searchText, activePage: 1 }, this.fetchProperties)
            }}
          />
          <PropertiesTable
            properties={this.state.properties}
            propertyTypes={propertyTypes}
            onSelectTableRow={this.onSelectTableRow}
            selectedRowId={this.state.selectedRowId}
            activePage={activePage}
            totalItemsCount={totalItemsCount}
            itemsCountPerPage={itemsCountPerPage}
            onPageChange={this.onPageChange}
            hasPagination={true}
          />
        </>
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={t("Property")} breadcrumbItem={t("Photos")} />
            {Content}
          </Container>
        </div>
        {this.state.isEditModalOpen && (
          <EditModal
            isOpen={true}
            close={() => {
              this.setState({ isEditModalOpen: false })
            }}
            property={this.state.selectedRowData}
            refreshTable={this.refreshTable}
          />
        )}

        {this.state.showWarningAlert ? (
          <SweetAlert
            warning
            title={t("Please select a property!")}
            onConfirm={() => {
              this.setState({ showWarningAlert: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        ) : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default withTranslation()(connect(mapStateToProps, null)(Photos))
