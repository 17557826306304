import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"
import { useSelector } from "react-redux"

const PrepaymentContext = createContext()

export const usePrepaymentContext = () => {
  const context = useContext(PrepaymentContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const PrepaymentProvider = props => {
  const { company } = useSelector(state => state.Company)
  const hasSignedContract = company.isAgreementConfirmed || false

  const [hasPrepayment, setHasPrepayment] = useState(true)
  const [hasVirtualCard, setHasVirtualCard] = useState(false)
  const [
    prepaymentBeforeFreeCancellation,
    setPrepaymentBeforeFreeCancellation,
  ] = useState(false)
  const [prepaymentPercent, setPrepaymentPercent] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
  const [returnDayCount, setReturnDayCount] = useState("")

  const [hotel_id, setHotel_id] = useState("")
  const [hotelNotReg, setHotelNotReg] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )
      if (response?.dbResult) {
        setHotel_id(response.dbResult.id)
        return response.dbResult.id
      }
    }

    const fetchSetPaymentMethods = async hotel_id => {
      const response = await request(
        {
          url: "/payment-methods",
        },
        false
      )

      const paymentMethods = response?.paymentMethods?.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
        value: i.id,
      }))

      setPaymentMethods(paymentMethods)
      return paymentMethods
    }

    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        return setHotelNotReg(true)
      }
      const paymentMethods = await fetchSetPaymentMethods(hotel_id)
      await fetchPrepaymentPolicy(hotel_id, paymentMethods)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (paymentMethods) {
      setPaymentMethods(
        paymentMethods.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
        }))
      )
    }
  }, [locale])

  const fetchPrepaymentPolicy = async (hotel_id, paymentMethods) => {
    const response = await request(
      {
        url: "/hotel-prepayment-policy",
        params: { hotel_id },
      },
      false
    )
    if (response?.dbResult?.id) {
      const {
        hasPrepayment,
        hasVirtualCard,
        prepaymentBeforeFreeCancellation,
        prepaymentPercent,
        paymentMethod_id,
        returnDayCount,
      } = response.dbResult

      setHasPrepayment(hasPrepayment)
      setHasVirtualCard(hasVirtualCard !== null ? hasVirtualCard : true)
      setPrepaymentBeforeFreeCancellation(
        prepaymentBeforeFreeCancellation !== null
          ? prepaymentBeforeFreeCancellation
          : true
      )
      setPrepaymentPercent(prepaymentPercent || "")
      setReturnDayCount(returnDayCount || "")
      setSelectedPaymentMethod(
        paymentMethods.find(i => i.value === paymentMethod_id) || ""
      )
    }
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    if (
      hasPrepayment &&
      (!prepaymentPercent || !selectedPaymentMethod || !returnDayCount)
    ) {
      return setErrorMessage("Please fill in the blanks!")
    }

    if (prepaymentPercent > 100) {
      return setErrorMessage(
        "Invalid value. The numbers can't be greater than 100%!"
      )
    }

    if (returnDayCount % 1 !== 0) {
      return setErrorMessage(`Invalid value. The day can't be decimal!`)
    }
    // end of validation

    // submit data
    let payload = {}
    if (hasPrepayment) {
      payload = {
        hasPrepayment: true,
        hasVirtualCard: false,
        prepaymentBeforeFreeCancellation: false,
        prepaymentPercent,
        paymentMethod_id: selectedPaymentMethod.value,
        returnDayCount,
        hotel_id,
      }
    } else {
      payload = {
        hasPrepayment,
        hotel_id,
      }
    }

    setSubmitLoading(true)
    const response = await request({
      url: "/hotel-prepayment-policy",
      method: "patch",
      data: payload,
    })
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
      fetchPrepaymentPolicy(hotel_id, paymentMethods)
    }
  }

  const value = useMemo(() => {
    const setters = {
      setHasPrepayment,
      setHasVirtualCard,
      setPrepaymentBeforeFreeCancellation,
      setPrepaymentPercent,
      setSelectedPaymentMethod,
      setReturnDayCount,
      setHotel_id,
      setShowSuccessAlert,
      setLoading,
      setSubmitLoading,
      setErrorMessage,
      setHotelNotReg,
    }
    const values = {
      hasPrepayment,
      hasVirtualCard,
      prepaymentBeforeFreeCancellation,
      hasSignedContract,
      prepaymentPercent,
      selectedPaymentMethod,
      returnDayCount,
      paymentMethods,
      hotel_id,
      showSuccessAlert,
      submitLoading,
      loading,
      errorMessage,
      hotelNotReg,
    }
    const functions = { submitHandler }
    return { setters, values, functions }
  }, [
    hasPrepayment,
    hasSignedContract,
    hasVirtualCard,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    selectedPaymentMethod,
    returnDayCount,
    paymentMethods,
    hotel_id,
    showSuccessAlert,
    submitLoading,
    loading,
    errorMessage,
    hotelNotReg,
  ])

  return <PrepaymentContext.Provider value={value} {...props} />
}
