import Select from "react-select"
import { IoMdClose } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useCallback, useState, useRef, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import styles from "./customMap.module.scss"
const libraries = ["places"]

const MapModal = props => {
  const { lat, lng, isModalOpen, setIsModalOpen, setCoordinate } = props
  const mapRef = useRef(null)
  const { t } = useTranslation()
  const defaultCoordinate = {
    lat: !!lat ? +lat : 38.9637,
    lng: !!lng ? +lng : 35.2433,
  }
  const [newCenter, setNewCenter] = useState(defaultCoordinate)
  const [marker, setMarker] = useState(defaultCoordinate)

  const isCoordinateProvided = !!lat && !!lng

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  })

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  })

  useEffect(() => {
    if (!isCoordinateProvided) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude
        const lng = position.coords.longitude
        const newCoordinate = { lat, lng }
        setNewCenter(newCoordinate)
        setMarker(newCoordinate)
      })
    }
  }, [])

  const onLoad = useCallback(
    map => {
      if (!isCoordinateProvided) {
        map.fitBounds({
          south: 35.80859185933293,
          west: 25.44081416441787,
          north: 42.36669994962167,
          east: 44.81784494793906,
        })
        mapRef.current = map
      }
    },
    [isCoordinateProvided]
  )

  const selectChangeHandler = useCallback(
    async item => {
      placesService.getDetails({ placeId: item.value }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat = place.geometry.location.lat()
          const lng = place.geometry.location.lng()
          const newCoordinate = { lat, lng }
          setNewCenter(newCoordinate)
          setMarker(newCoordinate)
          mapRef?.current?.fitBounds(place.geometry.viewport)
        }
      })
    },
    [placesService]
  )

  const dragEndHandler = useCallback(e => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const newCoordinate = { lat, lng }
    setMarker(newCoordinate)
  })

  const saveHandler = useCallback(() => {
    setCoordinate(marker)
    setIsModalOpen(false)
  }, [marker])

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={setIsModalOpen.bind(null, false)}
      size="xl"
    >
      <ModalBody className={styles.modalBody}>
        <div className={styles.selectContainer}>
          <Select
            options={placePredictions.map(i => ({
              ...i,
              label: i.description,
              value: i.place_id,
            }))}
            isLoading={isPlacePredictionsLoading}
            onInputChange={v => getPlacePredictions({ input: v })}
            onChange={selectChangeHandler}
            placeholder={t("Search a place...")}
            noOptionsMessage={() => t("No Options")}
          />
        </div>

        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{
              height: "calc(100vh - 140px)",
              width: "100%",
              borderRadius: 4,
            }}
            center={newCenter}
            zoom={10}
            options={{
              disableDefaultUI: true,
              keyboardShortcuts: false,
              draggable: true,
              clickableIcons: true,
              draggableCursor: "pointer",
              mapId: "922ce486e8d5e261",
            }}
            onLoad={onLoad}
          >
            <Marker position={marker} draggable onDragEnd={dragEndHandler} />
          </GoogleMap>
        )}
        <div
          className={styles.closeContainer}
          onClick={setIsModalOpen.bind(null, false)}
        >
          <IoMdClose size={26} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={setIsModalOpen.bind(null, false)}>
          {t("Close")}
        </Button>
        <Button color="primary" onClick={saveHandler}>
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MapModal
