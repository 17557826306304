import { addDays } from "date-fns"
import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage, all } from "utils/other"

const VillaOverallViewContext = createContext()

export const useVillaOverallViewContext = () => {
  const context = useContext(VillaOverallViewContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const VillaOverallViewProvider = props => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(addDays(new Date(), 15))
  const [villas, setVillas] = useState([])
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [tablesData, setTablesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)

  const { i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchCompanyVillas = async () => {
      const response = await request(
        {
          url: "/property/names",
        },
        false
      )

      let villas = response?.properties?.filter(i => i.status)
      villas = villas?.map(i => ({
        ...i,
        isChecked: true,
      }))

      villas.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: true,
      })

      setVillas(villas)
      return villas
    }

    const fetchCompanyMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        name: i.setMarketingPlaceName,
        id: i.setMarketingPlace_id,
        isChecked: false,
      }))
      marketingPlaces[0].isChecked = true
      setMarketingPlaces(marketingPlaces)
      return marketingPlaces
    }

    const main = async () => {
      setLoading(true)
      const response = await Promise.allSettled([
        fetchCompanyVillas(),
        fetchCompanyMarketingPlaces(),
      ])

      await searchHandler(
        response[0].value,
        currencies,
        response[1].value
      )

      setLoading(false)
    }
    main()
  }, [])

  const searchHandler = async (villas, currencies, marketingPlaces) => {
    setError("")
    const villaIds = villas
      ?.filter(i => i.isChecked && i.id !== 0)
      ?.map(i => i.id)
    const currency_id = currencies?.find(i => i.isChecked)?.id
    const setMarketingPlace_id =
      marketingPlaces?.length === 1
        ? 1
        : marketingPlaces?.find(i => i.isChecked)?.id

    // validation
    if (villaIds?.length === 0 || !currency_id || !setMarketingPlace_id) {
      return
    }

    // search on api
    const params = {
      fromDate,
      toDate,
      villa_ids: villaIds,
      currency_id,
      setMarketingPlace_id,
    }

    const response = await request({
      url: "/property-daily-price/overall-view",
      params,
    })

    setTablesData(response?.dbResult)
  }

  const value = useMemo(() => {
    const functions = {
      searchHandler,
    }
    const setters = {
      setLoading,
      setError,
      setFromDate,
      setToDate,
      setVillas,
      setCurrencies,
      setMarketingPlaces,
      setTablesData,
    }
    const values = {
      loading,
      error,
      fromDate,
      toDate,
      villas,
      currencies,
      marketingPlaces,
      tablesData,
    }
    return { functions, setters, values }
  }, [
    loading,
    error,
    fromDate,
    toDate,
    villas,
    currencies,
    marketingPlaces,
    tablesData,
  ])

  return <VillaOverallViewContext.Provider value={value} {...props} />
}
