import React from "react"
import { useTranslation } from "react-i18next"
import DayPickerInput from "react-day-picker/DayPickerInput"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment"
import "moment/locale/tr"
import "./styles.scss"

export default function CustomDayPicker({ onChange, disabledDays, value }) {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  return (
    <div className="customDayPicker">
      <DayPickerInput
        onDayChange={onChange}
        value={value}
        inputProps={{ readOnly: true }}
        placeholder={t("Select Date")}
        dayPickerProps={{
          disabledDays,
          localeUtils: MomentLocaleUtils,
          locale,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        format="L"
      />
    </div>
  )
}
