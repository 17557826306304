import React from "react"
import { Card, CardBody, FormGroup, Input, Label, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

export default function Prices(props) {
  const { price, setPrice, returnPrice, setReturnPrice, currency } = props

  const { t } = useTranslation()
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Prices")}</h4>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Label for="price">{t("Departure Price")}</Label>
              <Input
                type="number"
                min={0}
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="returnPrice">{t("Return Price")}</Label>
              <Input
                type="number"
                min={0}
                value={returnPrice}
                onChange={e => setReturnPrice(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <Label for="returnPrice">{t("Currency")}</Label>
            <h5 className="ml-2 mt-2">{currency}</h5>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
