import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { connect } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import { getCurrentCompanyUsers, deleteUser } from "store/actions"
import Add_EditModel from "./CustomModal"
import UsersTable from "./UsersTable"

class UserSettings extends Component {
  state = {
    isAddOperation: false,
    showModel: false,
    users: [],
    selectedRowUserId: "",
    selectedUserData: {},
    showWarningMessage: false,
    showWarnDeleteMsg: false,
    showSuccessDeleteMsg: false,
  }

  componentDidMount = () => {
    this.refreshUsersTable()
  }

  getUsersCallback = obj => {
    if (obj.result) {
      this.setState({ users: obj.dbResult })
    }
  }

  refreshUsersTable = () => {
    this.props.getCurrentCompanyUsers(this.getUsersCallback)
    this.setState({ selectedRowUserId: "", selectedUserData: "" })
  }

  onSelectTableRow = selectedUserId => {
    let selectedUser = this.state.users.find(user => {
      return user.id == selectedUserId
    })
    this.setState({
      selectedRowUserId: selectedUserId,
      selectedUserData: selectedUser,
    })
  }

  deleteUser = () => {
    this.props.deleteUser(this.state.selectedRowUserId, this.deleteCallback)
    this.setState({ showWarnDeleteMsg: false })
  }

  deleteCallback = obj => {
    if (obj.result) {
      this.refreshUsersTable()
      this.setState({ showSuccessDeleteMsg: true })
    }
  }

  render() {
    const { loading, errorMessage, error, t } = this.props
    const {
      selectedRowUserId,
      users,
      isAddOperation,
      showModel,
      showWarningMessage,
      showWarnDeleteMsg,
      showSuccessDeleteMsg,
      selectedUserData,
    } = this.state
    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <>
          <ControlButtons
            displayAddBtn={true}
            displayEditBtn={true}
            displayDeleteBtn={true}
            onAddBtn={() => {
              this.setState({ isAddOperation: true, showModel: true })
            }}
            onEditBtn={() => {
              if (selectedRowUserId) {
                this.setState({ isAddOperation: false, showModel: true })
              } else {
                this.setState({ showWarningMessage: true })
              }
            }}
            onDeleteBtn={() => {
              if (selectedRowUserId) {
                this.setState({ showWarnDeleteMsg: true })
              } else {
                this.setState({ showWarningMessage: true })
              }
            }}
            onPrintBtn={() => {}}
            onPdfBtn={() => {}}
            onExcelBtn={() => {}}
          />
          <UsersTable users={users} onSelectTableRow={this.onSelectTableRow} />
        </>
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={t("Setup")} breadcrumbItem={t("User")} />
            {Content}
          </Container>
        </div>

        {showModel && (
          <Add_EditModel
            isOpen={true}
            close={() => {
              this.setState({ showModel: false })
            }}
            user={selectedUserData}
            refreshUsersTable={this.refreshUsersTable}
            isAddOperation={isAddOperation}
          />
        )}
        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a user!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}

        <SweetAlert
          show={showWarnDeleteMsg}
          warning
          showCancel
          confirmBtnText={t("Yes, delete it!")}
          cancelBtnText={t("Cancel")}
          confirmBtnBsStyle="danger"
          title={t("Are you sure?")}
          onConfirm={this.deleteUser}
          onCancel={() => {
            this.setState({ showWarnDeleteMsg: false })
          }}
          focusCancelBtn
        >
          {t("You will not be able to recover it!")}
        </SweetAlert>

        <SweetAlert
          show={showSuccessDeleteMsg}
          success
          confirmBtnText={t("OK")}
          confirmBtnBsStyle="primary"
          title={t("Deleted!")}
          onConfirm={() => {
            this.setState({ showSuccessDeleteMsg: false })
          }}
        >
          {t("The user is deleted successfully!")}
        </SweetAlert>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { error, errorMessage, loading } = state.User
  return {
    error,
    errorMessage,
    loading,
  }
}

export default withTranslation()(
  connect(mapStateToProps, { getCurrentCompanyUsers, deleteUser })(UserSettings)
)
