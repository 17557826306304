import Select from "react-select"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from "reactstrap"

import CustomSwitch from "components/Common/CustomSwitch/index"
import { getLabelByLanguage, selectError } from "utils/other"
import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

const initialOptions = []
for (let i = 0; i <= 17; i++) {
  initialOptions.push({ label: i, value: i })
}

const TicketChildPolicy = ({ useFormResult, isAddOperation, ticket }) => {
  const { register, errors, setValue, control, watch } = useFormResult

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [childNamesOptions, setChildNamesOptions] = useState([])
  const isChildAllowed = watch("isChildAllowed")

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchSetChildName = async () => {
      const response = await request(
        {
          url: "/ticket-set-child-names",
        },
        false
      )

      const childNamesOptions = response?.dbResult?.map((i, idx) => ({
        ...i,
        label: getLabelByLanguage(i, locale),
        value: i.id,
        selectedStartAge: idx === 0 ? initialOptions[0] : "",
        selectedEndAge: "",
      }))

      setChildNamesOptions(childNamesOptions)
      return childNamesOptions
    }

    ;(async () => {
      setLoading(true)
      const childNamesOptions = await fetchSetChildName()

      if (!isAddOperation) {
        const { ageMin, ageMax, isChildAllowed, chd1, chd2 } = ticket
        setValue("ageMin", ageMin)
        setValue("ageMax", ageMax)
        setValue("isChildAllowed", isChildAllowed)
        setValue("chd1", chd1)
        setValue("chd2", chd2)

        if (isChildAllowed && childNamesOptions.length === 2) {
          childNamesOptions[0].selectedEndAge = initialOptions.find(
            i => i.value === chd1
          )
          childNamesOptions[1].selectedStartAge = initialOptions.find(
            i => i.value === chd1 + 1
          )
          childNamesOptions[1].selectedEndAge = initialOptions.find(
            i => i.value === chd2
          )
        }
      }
      setLoading(false)
    })()
  }, [])

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Age conditions")}</CardTitle>
        <hr />
        <Label className="mb-2">
          {t("Select the age range of your guests")}
        </Label>
        <Row className={styles.row}>
          <Col md={3}>
            <Label>* {t("Min. Age")}</Label>
            <Input
              name="ageMin"
              innerRef={register}
              invalid={!!errors?.ageMin}
              type="number"
              step={1}
              min={1}
              max={99}
            />
            {errors?.ageMin && <p>{t(errors?.ageMin?.message)}</p>}
          </Col>
          <Col md={3}>
            <Label>* {t("Max. Age")}</Label>
            <Input
              name="ageMax"
              innerRef={register}
              invalid={!!errors?.ageMax}
              type="number"
              step={1}
              min={1}
              max={99}
            />
            {errors?.ageMax && <p>{t(errors?.ageMax?.message)}</p>}
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md={6}>
            <Controller
              control={control}
              name="isChildAllowed"
              defaultValue={true}
              render={() => (
                <CustomSwitch
                  label={t("Do you accept child guests?")}
                  onClick={() => {
                    setValue("isChildAllowed", !isChildAllowed)
                  }}
                  value={isChildAllowed}
                />
              )}
            />
          </Col>
        </Row>
        {isChildAllowed && (
          <>
            {childNamesOptions?.map((item, index) => (
              <Row className={`${styles.row}`} key={item.value}>
                <Col md={2} className={`${styles.col}`}>
                  <Label>{t(item.label)}</Label>
                </Col>
                <Col md={3}>
                  <Label>{t("Start Age")}</Label>
                  <Select
                    placeholder=""
                    value={item.selectedStartAge}
                    noOptionsMessage={() => t("No Options")}
                    options={initialOptions}
                    onChange={option => {}}
                    components={{
                      Menu: () => null,
                      MenuList: () => null,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                    className={styles.disabled}
                  />
                </Col>
                <Col md={3}>
                  <Label>{t("End Age")}</Label>
                  <Controller
                    control={control}
                    name={`chd${index + 1}`}
                    defaultValue={""}
                    render={({ onChange, value, ref }) => {
                      return (
                        <Select
                          inputRef={ref}
                          placeholder=""
                          value={
                            initialOptions?.find(i => i?.value === value) ||
                            item.selectedEndAge
                          }
                          noOptionsMessage={() => t("No Options")}
                          options={initialOptions.filter(op =>
                            item.selectedStartAge?.value !== "" &&
                            op.value > item.selectedStartAge?.value
                              ? true
                              : false
                          )}
                          onChange={option => {
                            const chd = option.value
                            onChange(chd)
                            setChildNamesOptions(prev => {
                              const newOptions = [...prev]
                              newOptions[index].selectedEndAge = option

                              if (index === 0) {
                                newOptions[1].selectedStartAge =
                                  initialOptions.find(i => i.value === chd + 1)
                                newOptions[1].selectedEndAge = ""
                                setValue("chd2", "")
                              }
                              return newOptions
                            })
                          }}
                          styles={
                            (errors?.chd1 && index === 0) ||
                            (errors.chd2 && index === 1)
                              ? selectError
                              : ""
                          }
                        />
                      )
                    }}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default TicketChildPolicy
