import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ImEnter, ImExit } from "react-icons/im"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"
import moment from "moment"

import CustomTable from "./CustomTable"
import { useReservationContext } from "../context"
import { formatMoney, getTranslatedOption, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

const DailyDetailSection = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language
  const { values, setters } = useReservationContext()
  const { resDetails, isDailySectionOpen } = values
  const { setIsDailySectionOpen } = setters
  const { reservation, rooms } = resDetails
  moment.locale(locale)

  const el = document.getElementById("dailyPrice")
  const currencySign = getCurrencySignById(reservation?.agentCurrency_id)
  return (
    <section>
      <Card>
        <CardBody>
          <header className={styles.header}>
            <div>
              <h5>
                {rooms?.map(i => (
                  <p key={i.room_id} className="mb-0">{`${
                    i.roomCount
                  } x ${getTranslatedOption(
                    i,
                    "setRoomType",
                    locale
                  )} - ${getTranslatedOption(
                    i,
                    "setRoomName",
                    locale
                  )} - ${getTranslatedOption(i, "setBoardType", locale)}`}</p>
                ))}
              </h5>
              <h5>
                {currencySign} {formatMoney(reservation?.agentTotalPrices, 2)}
              </h5>
            </div>
            <div>
              <div className={styles.briefInfo}>
                <span>
                  <ImEnter size={18} className="mr-1" />{" "}
                  {moment(reservation?.checkIn).format("Do MMMM YYYY, ddd")}
                </span>
                <span>
                  {" "}
                  <ImExit size={18} className="mr-1" />{" "}
                  {moment(reservation?.checkOut).format("Do MMMM YYYY, ddd")}
                </span>
              </div>
              <div
                className={styles.control}
                onClick={() => setIsDailySectionOpen(prev => !prev)}
              >
                {isDailySectionOpen ? (
                  <RiArrowUpSLine size={23} />
                ) : (
                  <RiArrowDownSLine size={23} />
                )}
              </div>
            </div>
          </header>
          <article
            className={styles.article}
            style={
              isDailySectionOpen && el
                ? { maxHeight: el.clientHeight + 200 }
                : {}
            }
          >
            <CustomTable />
          </article>
        </CardBody>
      </Card>
    </section>
  )
}

export default DailyDetailSection
