import React, { useEffect, useState } from "react"
import Select from "react-select"
import { BsTable } from "react-icons/bs"
import { HiPlus } from "react-icons/hi"
import {
  Card,
  CardBody,
  Container,
  Label,
  Row,
  Button,
  Spinner,
  Alert,
  Input,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import DamageDepositItem from "./DamageDepositItem"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning/index"
import CustomSwitch from "components/Common/CustomSwitch/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

const PropertyDamageDeposit = () => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  const [showWarning, setShowWarning] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [hasDamageDeposit, setHasDamageDeposit] = useState(false)

  const [properties, setProperties] = useState([])
  const [selectedProperty, setSelectedProperty] = useState("")

  const [currencies, setCurrencies] = useState([
    { id: 2, value: 2, label: "EUR", name: "EUR" },
  ])

  const [selectedCurrency, setSelectedCurrency] = useState("")

  const [amount, setAmount] = useState([])
  const [damageDeposits, setDamageDeposits] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchCompanyPropertyNames = async () => {
      const response = await request(
        {
          url: "/property/names",
        },
        false
      )

      setProperties(
        response?.properties?.map(i => ({ label: i.name, value: i.id }))
      )
    }

    ;(async () => {
      if (country_id === 107 && !currencies.find(i => i.id === 3)) {
        setCurrencies(prevCurrencies => [
          ...prevCurrencies,
          { id: 3, value: 3, label: "TRY", name: "TRY" },
        ])
      }
      setLoading(true)
      await Promise.allSettled([
        fetchCompanyPropertyNames(),
        fetchCompanyDamageDeposits(),
      ])

      setLoading(false)
    })()
  }, [])

  const fetchCompanyDamageDeposits = async () => {
    const response = await request(
      {
        url: "/property-damage-deposits",
      },
      false
    )

    const damageDeposits = response?.dbResult?.map(i => ({
      selectedProperty: { label: i.propertyName, value: i.property_id },
      selectedCurrency: { label: i.currencyName, value: i.currency_id },
      amount: i.amount,
    }))

    setDamageDeposits(damageDeposits)
    if (damageDeposits?.length > 0) {
      setHasDamageDeposit(true)
    }
  }

  const addDamageDepositHandler = () => {
    if (!selectedProperty || !selectedCurrency || !amount) {
      return setShowWarning("Please fill in the blanks!")
    }

    // check if same property and currency already exists
    const isAlreadyAdded = damageDeposits.find(
      i =>
        i.selectedProperty.value === selectedProperty.value &&
        i.selectedCurrency.value === selectedCurrency.value
    )

    if (isAlreadyAdded) {
      return setShowWarning(
        "Selected property and currency already added. To edit please delete and add it again!"
      )
    }

    // add to damageDeposits
    setDamageDeposits([
      ...damageDeposits,
      {
        selectedProperty,
        selectedCurrency,
        amount,
      },
    ])

    // reset inputs
    setSelectedProperty("")
    setSelectedCurrency("")
    setAmount("")
  }

  const deleteDamageDepositHandler = (property_id, currency_id) => {
    setDamageDeposits(
      damageDeposits.filter(
        i =>
          !(
            i?.selectedProperty?.value === property_id &&
            i?.selectedCurrency?.value === currency_id
          )
      )
    )
  }

  const submitHandler = async () => {
    const submitData = async (method, payload) => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/property-damage-deposits",
          method,
          data: { payload },
        },
        false
      )
      setSubmitLoading(false)

      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setErrorMessage("")
    if (!hasDamageDeposit) {
      // request delete all damage deposits by company_id
      return submitData("delete", {})
    }

    if (hasDamageDeposit && damageDeposits.length === 0) {
      return setShowWarning("Please add some damage deposits!")
    }

    const payload = damageDeposits.map(i => ({
      property_id: i.selectedProperty.value,
      currency_id: i.selectedCurrency.value,
      amount: i.amount,
    }))

    submitData("patch", payload)
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Property")}
          breadcrumbItem={t("Damage Deposit")}
        />
        {loading && <CustomSpinner color="primary" />}
        <div className={loading ? "d-none" : ""}>
          <Card>
            <CardBody className={styles.cardBody}>
              <h4 className={styles.title}>{t("Damage Deposit")}</h4>
              <CustomSwitch
                label="Do you take a damage deposit at the entrance of the accommodation and return it at the exit?"
                className={styles.customSwitch}
                onClick={() => setHasDamageDeposit(prevState => !prevState)}
                value={hasDamageDeposit}
              />
              {hasDamageDeposit && (
                <>
                  <Row className={`${styles.row} mt-3 mb-2`}>
                    <div className={styles.col}>
                      <Label>{t("Property")}</Label>
                      <Select
                        placeholder={t("Select...")}
                        noOptionsMessage={() => t("No Options")}
                        value={selectedProperty}
                        options={properties}
                        onChange={setSelectedProperty}
                      />
                    </div>
                    <div className={styles.col}>
                      <Label>{t("Currency")}</Label>
                      <Select
                        placeholder={t("Select...")}
                        noOptionsMessage={() => t("No Options")}
                        value={selectedCurrency}
                        options={currencies}
                        onChange={setSelectedCurrency}
                      />
                    </div>
                    <div className={styles.col}>
                      <Label>{t("Amount")}</Label>
                      <Input
                        type="number"
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                      />
                    </div>{" "}
                    <div
                      className={styles.addIcon}
                      onClick={addDamageDepositHandler}
                      title={t("Add")}
                    >
                      <HiPlus size={22} />
                      <BsTable size={25} />
                    </div>
                  </Row>

                  <div>
                    {damageDeposits?.map((i, idx) => (
                      <DamageDepositItem
                        key={i?.selectedProperty?.label + idx}
                        data={i}
                        deleteHandler={deleteDamageDepositHandler}
                      />
                    ))}
                  </div>
                </>
              )}
            </CardBody>
          </Card>
          {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}
          <Row className={styles.btnContainer}>
            <Button color="primary" onClick={submitHandler}>
              {t("Submit")}{" "}
              {submitLoading && <Spinner color="white" size="sm" />}
            </Button>
          </Row>
        </div>
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={!!showWarning}
        onConfirm={() => setShowWarning("")}
        message={showWarning}
      />
      {/* SUCCESS ALERT */}
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => {
          setShowSuccessAlert(false)
          fetchCompanyDamageDeposits()
        }}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default PropertyDamageDeposit
