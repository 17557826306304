import React, { useState, useRef, useEffect } from "react"
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import MessageList from "./MessageList"
import { useMessagesContext } from "./context"
import styles from "./styles.module.scss"

const ChatBox = () => {
  let messagesEl = useRef()
  let searchInputRef = useRef()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const { t } = useTranslation()

  const { values, setters, functions } = useMessagesContext()
  const {
    currentConversation_id,
    conversations,
    messages,
    currentMessage,
    scroll,
  } = values
  const { setCurrentMessage, setScroll } = setters
  const { addMessage, setMessagesToRead, deleteMessage } = functions
  const currentConversation = conversations.find(
    i => i.id == currentConversation_id
  )

  useEffect(() => {
    if (scroll) {
      messagesEl?.scroll(0, messagesEl?.scrollHeight)

      setScroll(false)
    }
  }, [scroll])

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  const onKeyPress = e => {
    const { key } = e
    if (key === "Enter") {
      sendHandler()
    }
  }

  const sendHandler = () => {
    if (currentMessage) {
      addMessage(currentConversation_id, currentMessage)
    }
  }

  const userInteractHandler = () => {
    setMessagesToRead(currentConversation)
  }

  const searchHandler = e => {
    e.preventDefault()
    const value = searchInputRef?.current?.value
    console.log(window?.find(value))
  }

  return (
    <div className="w-100 user-chat">
      <Card onClick={userInteractHandler}>
        <div className="p-4 border-bottom ">
          <Row>
            <Col md="4" xs="9">
              <h5 className="font-size-15 mb-1">{currentConversation?.name}</h5>

              <p className="text-muted mb-0">
                <i
                  className={
                    currentConversation?.customerStatus
                      ? "mdi mdi-circle text-success align-middle mr-1"
                      : "mdi mdi-circle align-middle mr-1"
                  }
                />
                {currentConversation?.customerStatus
                  ? t("Online")
                  : t("Offline")}
              </p>
            </Col>
            <Col md="8" xs="3">
              {/* SEARCH */}
              <ul className="list-inline user-chat-nav text-right mb-0">
                <li className="list-inline-item d-none d-sm-inline-block">
                  <Dropdown isOpen={isSearchOpen} toggle={toggleSearch}>
                    <DropdownToggle className="btn nav-btn" tag="i">
                      <i className="bx bx-search-alt-2" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md" right>
                      <Form className="p-3">
                        <FormGroup className="m-0">
                          <InputGroup>
                            <Input
                              type="search"
                              className="form-control"
                              placeholder={t("Search...")}
                              aria-label="Recipient's username"
                              innerRef={searchInputRef}
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                color="primary"
                                type="submit"
                                onClick={searchHandler}
                              >
                                <i className="mdi mdi-magnify" />
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <div>
          <div className="chat-conversation p-3">
            <PerfectScrollbar
              className={styles.chatBoxHeight}
              ref={x => (messagesEl = x?._ps?.element)}
            >
              <MessageList
                messages={messages}
                deleteMessage={deleteMessage}
                customerName={currentConversation?.customerName}
              />
            </PerfectScrollbar>
          </div>
          <div className="p-3 chat-input-section">
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="text"
                    value={currentMessage}
                    onKeyPress={onKeyPress}
                    onChange={e => setCurrentMessage(e.target.value)}
                    className="form-control chat-input"
                    placeholder={t("Enter Message...")}
                  />
                </div>
              </Col>
              <Col className="col-auto">
                <Button
                  type="button"
                  color="primary"
                  onClick={sendHandler}
                  className="btn-rounded chat-send w-md waves-effect waves-light"
                >
                  <span className="d-none d-sm-inline-block mr-2">
                    {t("Send")}
                  </span>{" "}
                  <i className="mdi mdi-send" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ChatBox
