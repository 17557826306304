import React from "react"
import { Redirect } from "react-router-dom"

/*************  COMMON PAGES *****************************************************************/

// Authentication related pages
import Login from "../pages/Common/Authentication/Login"
import Logout from "../pages/Common/Authentication/Logout"
import Register from "../pages/Common/Authentication/Register"
import RegisterForm from "../pages/Common/Authentication/RegisterForm"
import ForgetPwd from "../pages/Common/Authentication/ForgetPassword"
import ResetPassword from "../pages/Common/Authentication/ResetPassword"
import Auth from "pages/Common/Authentication"
//Utility
import Pages404 from "../pages/Common/Utility/pages-404"
import Pages500 from "../pages/Common/Utility/pages-500"

// Setup
import HomePage from "../pages/Common/Home/index"
import ReservationMessages from "../pages/Common/Messages/reservationMessages"
import CustomerServiceMessages from "../pages/Common/Messages/masterMessages"
import CompanySettings from "../pages/Setup/CompanySettings/index"
import CountryPolicy from "../pages/Setup/CompanyCountryPolicy/index"
import User from "../pages/Setup/User/index"
import CompanyMarketingPlace from "../pages/Setup/CompanyMarketingPlace/index"
import CompanyDocuments from "pages/Setup/CompanyDocuments"
import CompanyContract from "pages/Setup/CompanyContract"

/*************  VILLA PAGES *****************************************************************/

// Property
import PropertyRegistration from "../pages/Villa/Property/PropertyRegistration/index"
import PropertyFeatures from "../pages/Villa/Property/PropertyFeatures/index"
import PropertyPhotos from "../pages/Villa/Property/Photos/index"
import PropertyLanguages from "../pages/Villa/Property/PropertyLanguage/index"
import PropertyCategories from "pages/Villa/Property/PropertyCategories"
import PropertyPlaceDistances from "pages/Villa/Property/PropertyPlaceDistances"

// Marketing
import PropertyPricing from "../pages/Villa/Marketing/Pricing/index"
import PropertyOverallView from "../pages/Villa/Marketing/OverallView/index"
import PropertyPromotions from "../pages/Villa/Marketing/Promotions/index"
import PropertyYourPromotions from "../pages/Villa/Marketing/YourPromotions/index"
import PropertyAvailability from "../pages/Villa/Marketing/PropertyAvailability/index"
import PropertyDamageDeposit from "../pages/Villa/Marketing/DamageDeposit/index"
import PropertyExtraServices from "../pages/Villa/Marketing/ExtraServices/index"
import PropertyYourExtraServices from "../pages/Villa/Marketing/YourExtraServices/index"

// POLICIES
import PropertyCancellationPolicy from "../pages/Villa/Policies/CancellationPolicy"
import PropertyPrepaymentPolicy from "../pages/Villa/Policies/PrepaymentPolicy/index"
import PropertyGuestInfoPolicy from "../pages/Villa/Policies/GuestInfoPolicy/index"
import PropertyTaxFeesKdvPolicy from "../pages/Villa/Policies/KdvTaxFeesPolicy/index"

// Opportunities
import PropertyInfluencerMarketing from "../pages/Villa/Opportunities/InfluencerMarketing"
import PropertySearchMarketing from "../pages/Villa/Opportunities/SearchEngine"
import PropertySocialMarketing from "../pages/Villa/Opportunities/SocialNetwork"
import PropertyYourOpportunities from "../pages/Villa/YourOpportunities/index"

// Reservations
import PropertyReservations from "../pages/Villa/Reservations/ReservationsOverview/index"
import PropertyReservationDetails from "../pages/Villa/Reservations/ReservationDetails/index"

// Reservations
import PropertyPartners from "../pages/Villa/Partners/Partners/index"
import PropertyPartnersList from "../pages/Villa/Partners/PartnersList/index"

// Reports
import AvailabilityReport from "../pages/Villa/Reports/AvailabilityReport/index"
import AvailabilityReport2 from "../pages/Villa/Reports/AvailabilityReport2/index"

/*************  TRANSFER PAGES *****************************************************************/
// Vehicles || Transfer
import VehicleRegistration from "../pages/Transfer/Vehicles/VehicleRegistration/index"
import VehiclePhotos from "../pages/Transfer/Vehicles/VehiclePhotos/index"

// Marketing
import TransferPricing from "../pages/Transfer/Marketing/Pricing/index"
import ExtraServicesPricing from "../pages/Transfer/Marketing/ExtraServicesPricing"
import TransferPromotions from "../pages/Transfer/Marketing/Promotions/index"
import TransYourPromotions from "../pages/Transfer/Marketing/YourPromotions/index"
import TransferPricingReport from "../pages/Transfer/Marketing/PricingReport/index"

// Reservations
import TransferReservations from "../pages/Transfer/Reservations/ReservationsOverview/index"
import TransferReservationsDetails from "../pages/Transfer/Reservations/ReservationDetails/index"

// Policies
import TransferCancellationPolicy from "../pages/Transfer/Policies/CancellationPolicy/index"
import TransferPrepaymentPolicy from "../pages/Transfer/Policies/PrepaymentPolicy/index"
import TransferTaxPolicy from "../pages/Transfer/Policies/KdvTaxFeesPolicy"
import TransferLanguage from "../pages/Transfer/TransferLanguage"

/*************  HOTELS PAGES *****************************************************************/
import HotelInformation from "../pages/Hotels/Hotel/HotelInformation/index"
import HotelBoardType from "../pages/Hotels/Hotel/HotelBoardType/index"
import HotelLanguages from "../pages/Hotels/Hotel/HotelLanguage/index"
import HotelFeatures from "../pages/Hotels/Hotel/HotelFeatures/index"
import AboutHotel from "../pages/Hotels/Hotel/AboutHotel/index"
import HotelPhotos from "../pages/Hotels/Hotel/HotelPhotos/index"
import HotelPaymentMethods from "../pages/Hotels/Hotel/HotelPaymentMethods/index"
import HotelPlaceDistances from "../pages/Hotels/Hotel/HotelPlaceDistance/index"
import HotelCategories from "pages/Hotels/Hotel/HotelCategories"

// POLICIES
import HotelCancellationPolicy from "../pages/Hotels/Policies/CancellationPolicy"
import HotelPrepaymentPolicy from "../pages/Hotels/Policies/PrepaymentPolicy/index"
import HotelGuestInfoPolicy from "../pages/Hotels/Policies/GuestInfoPolicy/index"
import HotelTaxFeesKdvPolicy from "../pages/Hotels/Policies/KdvTaxFeesPolicy/index"
import HotelInOutConditions from "../pages/Hotels/Policies/HotelInOutConditions/index"
import HotelChildPolicy from "../pages/Hotels/ChildPolicies/HotelChildPolicy/index"
import HotelPetPolicy from "../pages/Hotels/Policies/HotelPetPolicy/index"

// ROOMS
import HotelRoomRegistration from "../pages/Hotels/Rooms/RoomRegistration/index"
import HotelRoomFeatures from "../pages/Hotels/Rooms/RoomFeatures/index"
import HotelRoomPhotos from "../pages/Hotels/Rooms/RoomPhotos/index"

// MARKETING
import HotelRoomOverallView from "../pages/Hotels/Marketing/OverallView/index"
import HotelRoomPricing from "../pages/Hotels/Marketing/Pricing/index"
import HotelRoomAvailability from "../pages/Hotels/Marketing/AvailabilityStatus/index"
import HotelRoomQuota from "../pages/Hotels/Marketing/Quota/index"
import HotelRoomRateConditions from "../pages/Hotels/Marketing/RateConditions/index"
import HotelChildPricingPolicy from "../pages/Hotels/ChildPolicies/ChildPricingPolicy/index"

// PROMOTIONS
import HotelPromotions from "../pages/Hotels/Promotions/index"
import HotelYourPromotions from "../pages/Hotels/YourPromotions/index"

// OPPORTUNITIES
import HotelInfluencerMarketing from "../pages/Hotels/Opportunities/InfluencerMarketing"
import HotelSearchMarketing from "../pages/Hotels/Opportunities/SearchEngine"
import HotelSocialMarketing from "../pages/Hotels/Opportunities/SocialNetwork"
import HotelYourOpportunities from "../pages/Hotels/YourOpportunities/index"

// RESERVATIONS
import HotelRoomReservations from "../pages/Hotels/Reservations/ReservationsOverview/index"
import HotelRoomReservationDetails from "../pages/Hotels/Reservations/ReservationDetails/index"

/*************  TICKET PAGES *****************************************************************/
import TicketRegistration from "../pages/Ticket/Ticket/TicketRegistration/index"
import TicketFeatures from "../pages/Ticket/Ticket/TicketFeatures/index"
import TicketLanguages from "../pages/Ticket/TicketLanguage"
import TicketPhotos from "../pages/Ticket/Ticket/TicketPhotos/index"

// Marketing
import TicketPricing from "../pages/Ticket/Marketing/Pricing/index"
import TicketAvailability from "../pages/Ticket/Marketing/AvailabilityStatus/index"
import TicketPromotions from "../pages/Ticket/Marketing/Promotions/index"
import TicketYourPromotions from "../pages/Ticket/Marketing/YourPromotions/index"

// POLICIES
import TicketCancellationPolicy from "../pages/Ticket/Policies/CancellationPolicy"
import TicketPrepaymentPolicy from "../pages/Ticket/Policies/PrepaymentPolicy"
import TicketTaxFeesKdvPolicy from "../pages/Ticket/Policies/KdvTaxFeesPolicy"

// RESERVATIONS
import TicketReservations from "../pages/Ticket/Reservations/ReservationsOverview/index"
import TicketReservationDetails from "../pages/Ticket/Reservations/ReservationDetails/index"

const authProtectedRoutes = {
  commonPages: [
    /*************  COMMON PAGES *****************************************************************/
    // Setup
    { path: "/reservation-messages", component: ReservationMessages },
    { path: "/company-documents", component: CompanyDocuments },
    { path: "/customer-service-messages", component: CustomerServiceMessages },
    { path: "/setup-company-settings", component: CompanySettings },
    { path: "/setup-user", component: User },
    { path: "/country-policy", component: CountryPolicy },
    { path: "/company-marketing-place", component: CompanyMarketingPlace },
    { path: "/", component: HomePage, exact: true },
    { path: "/", exact: true, component: () => <Redirect to="/" /> },
  ],
  villaPages: [
    /*************  VILLA PAGES *****************************************************************/
    //Property
    { path: "/property-registration", component: PropertyRegistration },
    { path: "/property-features", component: PropertyFeatures },
    { path: "/property-photos", component: PropertyPhotos },
    { path: "/property-languages", component: PropertyLanguages },
    { path: "/property-categories", component: PropertyCategories },
    { path: "/property-place-distances", component: PropertyPlaceDistances },

    // Marketing
    { path: "/property-pricing", component: PropertyPricing },
    { path: "/property-overall-view", component: PropertyOverallView },
    { path: "/property-promotions", component: PropertyPromotions },
    { path: "/property-your-promotions", component: PropertyYourPromotions },
    { path: "/property-availability", component: PropertyAvailability },
    { path: "/property-damage-deposit", component: PropertyDamageDeposit },
    { path: "/property-extra-services", component: PropertyExtraServices },
    {
      path: "/property-your-extra-services",
      component: PropertyYourExtraServices,
    },

    // POLICIES
    {
      path: "/property-cancellation-policy",
      component: PropertyCancellationPolicy,
    },
    {
      path: "/property-prepayment-policy",
      component: PropertyPrepaymentPolicy,
    },
    {
      path: "/property-guest-info-policy",
      component: PropertyGuestInfoPolicy,
    },
    {
      path: "/property-kdv-tax-fees",
      component: PropertyTaxFeesKdvPolicy,
    },

    //Opportunities
    {
      path: "/property-influencer-marketing",
      component: PropertyInfluencerMarketing,
    },
    {
      path: "/property-search-engine-marketing",
      component: PropertySearchMarketing,
    },
    {
      path: "/property-social-network-marketing",
      component: PropertySocialMarketing,
    },
    {
      path: "/property-your-opportunities",
      component: PropertyYourOpportunities,
    },

    //Reservation
    { path: "/property-reservations", component: PropertyReservations },
    {
      path: "/property-reservation-details/:id",
      component: PropertyReservationDetails,
    },
    //Partners
    { path: "/property-partners", component: PropertyPartners },
    {
      path: "/property-partners-list",
      component: PropertyPartnersList,
    },

    // Reports
    { path: "/reports-availability", component: AvailabilityReport },
    { path: "/reports-availability2", component: AvailabilityReport2 },
  ],
  transferPages: [
    /*************  TRANSFER PAGES *****************************************************************/
    //Vehicles/ Transfer
    { path: "/vehicle-registration", component: VehicleRegistration },
    { path: "/vehicle-photos", component: VehiclePhotos },
    { path: "/transfer-pricing", component: TransferPricing },
    { path: "/extra-services-pricing", component: ExtraServicesPricing },
    { path: "/transfer-promotions", component: TransferPromotions },
    { path: "/transfer-your-promotions", component: TransYourPromotions },
    { path: "/transfer-pricing-report", component: TransferPricingReport },

    //Reservations
    {
      path: "/transfer-reservations",
      component: TransferReservations,
    },
    {
      path: "/transfer-reservation-details/:id",
      component: TransferReservationsDetails,
    },

    // POLICIES
    {
      path: "/transfer-cancellation-policy",
      component: TransferCancellationPolicy,
    },
    {
      path: "/transfer-prepayment-policy",
      component: TransferPrepaymentPolicy,
    },
    {
      path: "/transfer-kdv-tax-fees",
      component: TransferTaxPolicy,
    },
    {
      path: "/transfer-languages",
      component: TransferLanguage,
    },
  ],
  hotelPages: [
    /*************  HOTELS PAGES *****************************************************************/
    { path: "/company-contract", component: CompanyContract },
    { path: "/hotel-information", component: HotelInformation },
    { path: "/hotel-board-type", component: HotelBoardType },
    { path: "/hotel-languages", component: HotelLanguages },
    { path: "/hotel-features", component: HotelFeatures },
    { path: "/hotel-categories", component: HotelCategories },
    { path: "/about-hotel", component: AboutHotel },
    { path: "/hotel-photos", component: HotelPhotos },
    { path: "/hotel-payment-methods", component: HotelPaymentMethods },
    { path: "/hotel-place-distances", component: HotelPlaceDistances },

    // POLICIES
    { path: "/hotel-cancellation-policy", component: HotelCancellationPolicy },
    { path: "/hotel-prepayment-policy", component: HotelPrepaymentPolicy },
    {
      path: "/hotel-guest-info-policy",
      component: HotelGuestInfoPolicy,
    },
    { path: "/hotel-in-out-conditions", component: HotelInOutConditions },
    { path: "/hotel-child-policy", component: HotelChildPolicy },
    { path: "/hotel-pet-policy", component: HotelPetPolicy },
    // ROOMS
    { path: "/hotel-room-registration", component: HotelRoomRegistration },
    { path: "/hotel-room-features", component: HotelRoomFeatures },
    { path: "/hotel-room-photos", component: HotelRoomPhotos },
    // MARKETING
    { path: "/hotel-room-overall-view", component: HotelRoomOverallView },
    { path: "/hotel-room-pricing", component: HotelRoomPricing },
    { path: "/hotel-room-availability", component: HotelRoomAvailability },
    { path: "/hotel-room-quota", component: HotelRoomQuota },
    { path: "/hotel-room-rate-conditions", component: HotelRoomRateConditions },
    { path: "/hotel-child-pricing-policy", component: HotelChildPricingPolicy },
    {
      path: "/hotel-kdv-tax-fees",
      component: HotelTaxFeesKdvPolicy,
    },
    // PROMOTIONS
    { path: "/hotel-promotions", component: HotelPromotions },
    { path: "/hotel-your-promotions", component: HotelYourPromotions },

    // OPPORTUNITIES
    {
      path: "/hotel-influencer-marketing",
      component: HotelInfluencerMarketing,
    },
    {
      path: "/hotel-search-engine-marketing",
      component: HotelSearchMarketing,
    },
    {
      path: "/hotel-social-network-marketing",
      component: HotelSocialMarketing,
    },
    { path: "/hotel-your-opportunities", component: HotelYourOpportunities },

    // RESERVATIONS
    { path: "/hotel-room-reservations", component: HotelRoomReservations },
    {
      path: "/hotel-room-reservation-details/:id",
      component: HotelRoomReservationDetails,
    },
  ],
  ticketPages: [
    /*************  TICKET PAGES *****************************************************************/
    { path: "/ticket", component: TicketRegistration },
    { path: "/ticket-features", component: TicketFeatures },
    { path: "/ticket-languages", component: TicketLanguages },
    { path: "/ticket-photos", component: TicketPhotos },

    // POLICIES
    {
      path: "/ticket-cancellation-policy",
      component: TicketCancellationPolicy,
    },
    {
      path: "/ticket-prepayment-policy",
      component: TicketPrepaymentPolicy,
    },
    {
      path: "/ticket-kdv-tax-fees",
      component: TicketTaxFeesKdvPolicy,
    },

    // MARKETING
    { path: "/ticket-pricing", component: TicketPricing },
    { path: "/ticket-availability", component: TicketAvailability },
    { path: "/ticket-promotions", component: TicketPromotions },
    { path: "/ticket-your-promotions", component: TicketYourPromotions },

    //Reservation
    { path: "/ticket-reservations", component: TicketReservations },
    {
      path: "/ticket-reservation-details/:id",
      component: TicketReservationDetails,
    },
  ],
}

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/login", component: Auth },
  { path: "/register-form", component: Auth },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
