import { useState, useCallback, useEffect } from "react"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { NavLink, useHistory } from "react-router-dom"
import { ImCross } from "react-icons/im"

import SidebarItem from "./SidebarItem"
import styles from "./sidebar.module.scss"

const Sidebar = ({ content, open: openSidebar, setOpen }) => {
  const history = useHistory()
  const pathname = history.location.pathname
  const [openedL1ItemId, setOpenL1ItemId] = useState(null)

  const level1ItemClickHandler = useCallback(id => {
    setOpenL1ItemId(prevId => (prevId === id ? -1 : id))
  }, [])

  useEffect(() => {
    const activeItem = content.filter(item => {
      let passed = item.link === pathname
      if (passed) {
        return passed
      } else if (item?.items?.length > 0) {
        return item.items.some(i => i.link === pathname)
      } else {
        return passed
      }
    })

    const activeId = activeItem[0]?.id
    if (activeId > -1) {
      setOpenL1ItemId(activeId)
    }
  }, [])

  return (
    <>
      <div
        className={classnames({ [styles.backdrop]: openSidebar })}
        onClick={() => setOpen(false)}
      ></div>
      <section
        className={classnames({
          [styles.sidebar]: true,
          [styles.openSidebar]: openSidebar,
        })}
      >
        <header>
          <span className={styles.closeBtn} onClick={setOpen.bind(null, false)}>
            <ImCross />
          </span>
          <NavLink to={"/"}>Vihobook</NavLink>
        </header>
        <PerfectScrollbar className={styles.scrollbar}>
          <nav className={styles.nav}>
            <div className={styles.menuTitle}>Menu</div>
            <ul className={styles.menu}>
              {content?.map(item => (
                <SidebarItem
                  key={item.id}
                  id={item.id}
                  Icon={item.icon}
                  link={item.link}
                  label={item.label}
                  unreadMessageCount={item?.unreadMessageCount}
                  items={item.items}
                  level1ItemClickHandler={level1ItemClickHandler}
                  level1ClickedItemId={openedL1ItemId}
                />
              ))}
            </ul>
          </nav>
        </PerfectScrollbar>
      </section>
    </>
  )
}

export default Sidebar
