import React from "react"
import { Card, CardBody, Label } from "reactstrap"

import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

import { useTranslation } from "react-i18next"

const StayDates = props => {
  const {
    setBooking_startDate,
    setBooking_endDate,
    booking_startDate,
    booking_endDate,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Card className={styles.card}>
        <CardBody className={styles.cardBody}>
          <h4 className={styles.cardTitle}>{t("Stay dates")}</h4>
          <h5 className={styles.cardSubtitle}>
            {t("When can guests stay using this promotion?")}
          </h5>

          <div className={styles.datesContainer}>
            <div className={styles.dateInputContainer}>
              <Label>{t("From")}</Label>
              <CustomDayPicker
                onChange={date => {
                  setBooking_startDate(date)
                  if (date.getTime() > booking_endDate.getTime()) {
                    setBooking_endDate(date)
                  }
                }}
                value={booking_startDate}
                disabledDays={[]}
              />
            </div>
            <div className={styles.dateInputContainer}>
              <Label>{t("To")}</Label>
              <CustomDayPicker
                onChange={setBooking_endDate}
                value={booking_endDate}
                disabledDays={[{ before: booking_startDate }]}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default StayDates
