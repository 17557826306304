import React, { useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"

import WidgetPagination from "components/Common/WidgetPagination/index"
import { formatDate } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

const OpportunitiesTable = props => {
  const [selectedRowId, setSelectedRowId] = useState("")
  const { t } = useTranslation()
  const {
    opportunities,
    onSelectTableRow,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const OpportunityRows = opportunities?.map(item => {
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
          setSelectedRowId(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{item.opportunityName}</Td>

        <Td className={styles.dataCell}>
          {formatDate(item?.startDate)} - {formatDate(item?.endDate)}
        </Td>

        <Td className={styles.dataCell}>{item?.setMarketingPlaceName}</Td>
        <Td className={styles.dataCell}>{item?.commission}</Td>
        <Td className={styles.dataCell}>{item.userName}</Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className={`table-responsive ${styles.tContainer}`}>
        <Table className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.th}>{t("Opportunity Name")}</Th>
              <Th className={styles.th}>{t("Bookable period")}</Th>
              <Th className={styles.th}>{t("Marketing Place")}</Th>
              <Th className={styles.th}>{t("Commission")} %</Th>
              <Th className={styles.th}>{t("Created By")}</Th>
            </Tr>
          </Thead>

          <Tbody>{OpportunityRows}</Tbody>
        </Table>
      </div>
      {hasPagination && (
        <WidgetPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default OpportunitiesTable
