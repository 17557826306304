import { Row, Col, Card, CardBody, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { useReservationContext } from "./context"
import { formatDate } from "utils/dateTimeFunctions"
import { getCurrencySignById, formatMoney } from "utils/other"
import styles from "./styles.module.scss"

const InfoSection = () => {
  const { values } = useReservationContext()
  const { resDetails } = values
  const d = resDetails.reservation

  const currencySign = getCurrencySignById(d?.agentCurrency_id)
  const commission = formatMoney(
    (d?.agentTotalPrice * d?.commissionRate) / 100,
    2
  )

  const pairs = d?.childAges?.split(",")

  const reversedPairs = pairs?.map(pair => {
    const [num1, num2] = pair?.split("_")
    return `${num2}`
  })

  const childAges = reversedPairs?.join(", ")

  return (
    <section>
      <Card>
        <CardBody className={styles.infoContainer}>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Event Date"}
                value={formatDate(d?.ticketDate)}
                className={styles.boldValue}
              />
              {d?.childCount > 0 && (
                <>
                  <InfoItem label={"Adult Count"} value={d?.adultCount} />
                  <InfoItem label={"Children Count"} value={d?.childCount} />
                  <InfoItem label={"Child Ages"} value={childAges} />
                </>
              )}
              <InfoItem
                label={"Total Guest Count"}
                value={d?.adultCount + d?.childCount}
              />
              <InfoItem
                label={"Reservation Date"}
                value={formatDate(d?.resDate)}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Guest Name"}
                value={`${d?.name} ${d?.surname}`}
                className={styles.boldValue}
              />

              <div className={styles.link}>
                <Link to="#">{d?.email}</Link>
              </div>
              <InfoItem label={"Phone Number"} value={d?.phoneNo} />
              <InfoItem
                label={"Marketing Place"}
                value={d?.setMarketingPlaceName}
              />
              <InfoItem label={"Reservation Number"} value={d?.resNo} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InfoItem
                label={"Reservation Total Price"}
                value={`${currencySign} ${formatMoney(d?.agentTotalPrice, 2)}`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Commission"}
                value={`${d?.commissionRate}% (${currencySign}${commission})`}
                className={styles.boldValue}
              />
            </Col>
            <Col md={6}>
              <InfoItem
                label={"Payable amount to the facility"}
                value={`${currencySign} ${formatMoney(
                  d?.agentTotalPrice - commission,
                  2
                )}`}
                className={styles.boldValue}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  )
}

export default InfoSection

const InfoItem = ({ label, value, className }) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <Label>{t(label)}</Label>
      <p>{value}</p>
    </div>
  )
}
