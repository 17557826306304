import { takeEvery, put, select } from "redux-saga/effects"
import axios from "axios"

import {
  GET_CURRENT_USER,
  ADD_NEW_USER,
  GET_CURRENT_COMPANY_USERS,
  UPDATE_USER,
  DELETE_USER,
} from "./actionTypes"
import {
  userApiError,
  getCurrentUserStart,
  getCurrentUserSucceed,
  addNewUserStart,
  addNewUserSucceed,
  getCurrentCompanyUsersStart,
  getCurrentCompanyUsersSucceed,
  updateUserStart,
  updateUserSucceed,
  deleteUserStart,
  deleteUserSucceed,
} from "./actions"

function* getCurrentUserSaga({ token }) {
  try {
    yield put(getCurrentUserStart())
    let url = "/user/current-user"

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
    const response = yield axios.get(url, config)

    if (response.data.result == true) {
      yield put(getCurrentUserSucceed(response.data.user))
    } else {
      yield put(userApiError(response.data.message))
    }
  } catch (error) {
    yield put(userApiError("Something went wrong!!"))
  }
}

function* addNewUserSaga({ newUser, callback }) {
  try {
    yield put(addNewUserStart())
    const state = yield select()
    const token = state.Login.token

    let url = "/user/add-user"
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
    const response = yield axios.post(url, newUser, config)
    if (response.data.result == true) {
      yield put(addNewUserSucceed())
      callback(response.data)
    } else {
      yield put(userApiError(response.data.message))
    }
  } catch (error) {
    yield put(userApiError("Something went wrong!!"))
  }
}

function* getCurrentCompanyUsersSaga({ callback }) {
  try {
    yield put(getCurrentCompanyUsersStart())
    const state = yield select()
    const token = state.Login.token

    let url = "/user/company-users"
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
    const response = yield axios.get(url, config)
    if (response.data.result == true) {
      yield put(getCurrentCompanyUsersSucceed())
      callback(response.data)
    } else {
      yield put(userApiError(response.data.message))
    }
  } catch (error) {
    yield put(userApiError("Something went wrong!!"))
  }
}

function* updateUserSaga({ user, callback }) {
  try {
    yield put(updateUserStart())
    const state = yield select()
    const token = state.Login.token

    let url = "/user/update-user"
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
    const response = yield axios.put(url, user, config)
    if (response.data.result == true) {
      yield put(updateUserSucceed())
      callback(response.data)
    } else {
      yield put(userApiError(response.data.message))
    }
  } catch (error) {
    yield put(userApiError("Something went wrong!!"))
  }
}

function* deleteUserSaga({ id, callback }) {
  try {
    yield put(deleteUserStart())
    const state = yield select()
    const token = state.Login.token

    let url = "/user/delete-user"
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      data: { id },
    }
    const response = yield axios.delete(url, config)
    if (response.data.result == true) {
      yield put(deleteUserSucceed())
      callback(response.data)
    } else {
      yield put(userApiError(response.data.message))
    }
  } catch (error) {
    yield put(userApiError("Something went wrong!!"))
  }
}

function* userSaga() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUserSaga)
  yield takeEvery(ADD_NEW_USER, addNewUserSaga)
  yield takeEvery(GET_CURRENT_COMPANY_USERS, getCurrentCompanyUsersSaga)
  yield takeEvery(UPDATE_USER, updateUserSaga)
  yield takeEvery(DELETE_USER, deleteUserSaga)
}

export default userSaga
