import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"

class SuccessAlert extends React.Component {
  render() {
    const { hideSuccessAlert, t } = this.props
    return (
      <SweetAlert
        success
        title={t("Congratulations!")}
        confirmBtnBsStyle="success"
        onConfirm={hideSuccessAlert}
        confirmBtnText={t("OK")}
      >
        <h4>{t("Successfully Saved!")}</h4>
      </SweetAlert>
    )
  }
}

export default withTranslation()(SuccessAlert)
