import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import { getLabelByLanguage, getTranslatedOption } from "utils/other"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"

function CountryPolicy() {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [countries, setCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchCountries = async unpermittedCountries => {
      const response = await request(
        {
          url: "/countries",
        },
        false
      )

      if (response?.countries) {
        const countries = response?.countries.map(i => {
          if (unpermittedCountries.some(item => item.country_id === i.id)) {
            return {
              ...i,
              label: getLabelByLanguage(i, locale),
              continentName: getTranslatedOption(i, "continentName", locale),
              value: i.id,
              isChecked: false,
            }
          } else {
            return {
              ...i,
              label: getLabelByLanguage(i, locale),
              continentName: getTranslatedOption(i, "continentName", locale),
              value: i.id,
              isChecked: true,
            }
          }
        })
        setCountries(countries)
      }
    }

    const fetchCompanyUnpermittedCountries = async () => {
      const response = await request(
        {
          url: "/company-unpermitted-countries",
        },
        false
      )

      if (response?.dbResult) {
        return response.dbResult
      }
      setErrorMessage("Something went wrong!")
    }

    ;(async () => {
      setLoading(true)
      const unpermittedCountries = await fetchCompanyUnpermittedCountries()
      await fetchCountries(unpermittedCountries)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setCountries(
      countries.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
        continentName: getTranslatedOption(i, "continentName", locale),
      }))
    )
  }, [locale])

  const countriesByContinent = countries.reduce((acc, country) => {
    const continentName = country.continentName
    if (!acc[continentName]) {
      acc[continentName] = []
    }
    acc[continentName].push(country)
    return acc
  }, {})

  const isContinentChecked = continentName => {
    const countriesInContinent = countriesByContinent[continentName]
    const allCountriesChecked = countriesInContinent.every(
      country => country.isChecked
    )
    return allCountriesChecked
  }

  const handleContinentCheck = continentName => {
    setCountries(
      countries.map(i => {
        if (i.continentName === continentName) {
          return {
            ...i,
            isChecked: !isContinentChecked(continentName),
          }
        }
        return i
      })
    )
  }

  const handleCountryCheck = id => {
    setCountries(
      countries.map(i => {
        if (i.id === id) {
          return {
            ...i,
            isChecked: !i.isChecked,
          }
        }
        return i
      })
    )
  }

  const submitHandler = async () => {
    const dataList = []
    countries.forEach(i => {
      if (!i.isChecked) {
        dataList.push({
          country_id: i.id,
        })
      }
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/company-unpermitted-countries",
        method: "put",
        data: { dataList },
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage("Something went wrong!")
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Policies")} breadcrumbItem={t("Country Policy")} />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h4 className={styles.title}>
              {t("Which countries do you accept guests from in your facility?")}
            </h4>
            <div className={styles["country-list-container"]}>
              {Object.keys(countriesByContinent).map(continentName => (
                <div
                  key={continentName}
                  className={styles["continent-section"]}
                >
                  <h3
                    className={styles.continentLabel}
                    onClick={() => handleContinentCheck(continentName)}
                  >
                    <input
                      type="checkbox"
                      className={styles["continent-checkbox"]}
                      checked={isContinentChecked(continentName)}
                    />
                    {continentName}
                  </h3>
                  <ul className={styles["country-checkbox-list"]}>
                    {countriesByContinent[continentName].map(country => (
                      <li key={country.value}>
                        <div
                          onClick={() => handleCountryCheck(country.value)}
                          className={styles["country-checkbox-container"]}
                        >
                          <input
                            type="checkbox"
                            className={styles["country-checkbox"]}
                            checked={country.isChecked}
                            onChange={() => handleCountryCheck(country.value)}
                          />
                          {country.label}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>

        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

export default CountryPolicy
