import { Container, Card, CardBody, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ReservationProvider, useReservationContext } from "./context"
import Breadcrumbs from "components/Common/Breadcrumb"
import SearchInputs from "./SearchInputs/index"
import CustomTable from "./CustomTable"

const TransferReservations = () => {
  const { t } = useTranslation()
  const {
    values: { error, loading },
  } = useReservationContext()
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Property")} breadcrumbItem={t("Reservations")} />
        <SearchInputs />
        {error && <Alert color="danger">{t(error)}</Alert>}
        {loading ? (
          <Spinner color="primary" className="absolute-spinner" />
        ) : (
          <Card>
            <CardBody>
              <CustomTable />
            </CardBody>
          </Card>
        )}
      </Container>
    </main>
  )
}

const WithContext = props => {
  return (
    <ReservationProvider>
      <TransferReservations {...props} />
    </ReservationProvider>
  )
}

export default WithContext
