import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal, Button, Alert } from "reactstrap"

import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert/index"
import NavButtons from "./NavButtons/index.js"
import NavContents from "./NavContents/index"
import styles from "./styles.module.scss"

import { getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"

const TicketFeatures = props => {
  const [activeLink, setActiveLink] = useState(0)
  const [features, setFeatures] = useState([])
  const [loading, setLoading] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const isPrevBtnDisabled = activeLink === 0
  const isNextBtnDisabled = activeLink === features.length - 1

  const fetchSetTicketFeatureTypes = async () => {
    const setTypeName_id = props.data.ticketTypeName_id
    const response = await request(
      {
        url: "/ticket-set-feature-types/get-by-set-type-name-id",
        params: { setTypeName_id },
      },
      false
    )
    return response.dbResult
  }

  const fetchSetTicketFeatures = async () => {
    const response = await request({ url: "/ticket-set-features" }, false)
    return response.dbResult
  }

  const fetchTicketFeatures = async () => {
    const response = await request(
      { url: "/ticket-features", params: { ticket_id: props.data.id } },
      false
    )
    return response.dbResult
  }

  const fetchCurrencies = async () => {
    const response = await request({ url: "/currencies" }, false)
    setCurrencies(
      response.currencies.map(i => ({ label: i.name, value: i.id }))
    )
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const response = await Promise.allSettled([
        fetchSetTicketFeatureTypes(),
        fetchSetTicketFeatures(),
        fetchTicketFeatures(),
        fetchCurrencies(),
      ])
      setLoading(false)
      prepareData({
        featureTypes: response[0].value,
        features: response[1].value,
        ticketFeatures: response[2].value,
      })
    })()
  }, [])

  const prepareData = data => {
    const { featureTypes, features, ticketFeatures } = data
    const initialFeatures = []

    featureTypes.forEach(type => {
      const initialFeature = {
        id: type.id,
        featureTitle: getLabelByLanguage(type, locale),
        featureItems: [],
      }
      for (const feature of features) {
        if (type.id === feature.setFeatureType_id) {
          let isChecked = false
          let price = ""
          let currency_id = ""
          for (const ticketFeature of ticketFeatures) {
            if (ticketFeature.featureId === feature.id) {
              isChecked = true
              price = ticketFeature.price
              currency_id = ticketFeature.currency_id
              break
            }
          }
          initialFeature.featureItems.push({
            id: feature.id,
            itemTitle: getLabelByLanguage(feature, locale),
            isChecked,
            isPaid: feature.isPaid,
            price: price,
            currency_id: currency_id,
          })
        }
      }
      initialFeatures.push(initialFeature)
    })

    setFeatures(initialFeatures)
  }

  const linkClickHandler = idx => {
    setActiveLink(idx)
  }

  const checkBoxClickHandler = itemId => {
    const updatedFeatures = [...features]
    const featureItemIndex = updatedFeatures[activeLink].featureItems.findIndex(
      item => item.id === itemId
    )
    const featureItem =
      updatedFeatures[activeLink].featureItems[featureItemIndex]

    updatedFeatures[activeLink].featureItems[featureItemIndex] = {
      ...featureItem,
      isChecked: !featureItem.isChecked,
    }

    setFeatures(updatedFeatures)
  }

  const priceChangeHandler = (price, itemId) => {
    const updatedFeatures = [...features]
    const featureItemIndex = updatedFeatures[activeLink].featureItems.findIndex(
      item => item.id === itemId
    )
    const featureItem =
      updatedFeatures[activeLink].featureItems[featureItemIndex]

    updatedFeatures[activeLink].featureItems[featureItemIndex] = {
      ...featureItem,
      price,
    }

    setFeatures(updatedFeatures)
  }

  const currencyChangeHandler = (currency_id, itemId) => {
    const updatedFeatures = [...features]
    const featureItemIndex = updatedFeatures[activeLink].featureItems.findIndex(
      item => item.id === itemId
    )
    const featureItem =
      updatedFeatures[activeLink].featureItems[featureItemIndex]

    updatedFeatures[activeLink].featureItems[featureItemIndex] = {
      ...featureItem,
      currency_id,
    }

    setFeatures(updatedFeatures)
  }

  const submitHandler = () => {
    const data = []
    const ticket_id = props.data.id

    features.forEach(type => {
      type?.featureItems?.forEach(f => {
        if (f.isChecked) {
          data.push({
            feature_id: f.id,
            ticket_id,
            ...(f?.isPaid && { price: f.price, currency_id: f.currency_id }),
          })
        }
      })
    })

    submitData(data)
  }

  const submitData = async data => {
    const response = await request({
      url: "/ticket-features",
      method: "patch",
      data: { ticketFeatures: data, ticket_id: props.data.id },
    })

    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      scrollable={true}
      size="xl"
      toggle={props.close}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Add/Remove Feature")}</h5>
        <button
          type="button"
          onClick={props.close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {loading && <CustomSpinner color="primary" />}
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <div className={loading ? "invisible" : ""}>
          <NavButtons
            features={features}
            activeLink={activeLink}
            onLinkClick={linkClickHandler}
          />
          <NavContents
            features={features}
            activeLink={activeLink}
            onCheckboxClicked={checkBoxClickHandler}
            currencies={currencies}
            onPriceChange={priceChangeHandler}
            onCurrencyChange={currencyChangeHandler}
          />
          <div className={styles.navButtons}>
            <Button
              disabled={isPrevBtnDisabled}
              className={isPrevBtnDisabled ? styles.disabled : ""}
              onClick={() => setActiveLink(activeLink => activeLink - 1)}
            >
              {t("Previous")}
            </Button>

            <Button
              disabled={isNextBtnDisabled}
              className={isNextBtnDisabled ? styles.disabled : ""}
              onClick={() => setActiveLink(activeLink => activeLink + 1)}
            >
              {t("Next")}
            </Button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className={styles.submitButton}>
          <Button color="primary" onClick={submitHandler}>
            {t("Submit")}
          </Button>
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            props.close()
          }}
        />
      )}
    </Modal>
  )
}

export default TicketFeatures
