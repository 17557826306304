import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import Add_EditModal from "./Add_EditModal/index"
import RoomTable from "components/Hotel/RoomTable/index"

const CancelToken = axios.CancelToken
let cancel

class RoomRegistration extends Component {
  state = {
    isModalOpen: false,
    hotel_id: "",
    rooms: [],
    roomTypes: [],
    roomNames: [],
    selectedRowId: "",
    selectedRowData: {},
    showWarningMessage: false,
    showWarnDeleteMsg: false,
    showSuccessDeleteMsg: false,
    isAddOperation: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    roomsLoading: false,
    errorMessage: "",
    error: false,
    showHotelNotRegAlert: false,
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const hotel_id = await this.fetchHotelData()
    if (!hotel_id) {
      this.setState({ loading: false, showHotelNotRegAlert: true })
      return
    }
    Promise.allSettled([
      this.fetchRooms(hotel_id),
      this.fetchRoomTypes(),
      this.fetchRoomNames(),
    ])
    this.setState({ loading: false })
  }

  fetchHotelData = async () => {
    try {
      const url = `/hotel-registration`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {},
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        const hotel_id = response.data.dbResult.id
        this.setState({ hotel_id })
        return hotel_id
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRoomTypes = async () => {
    try {
      const url = `/hotel-set-room-types`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)
      if (response.data.result) {
        this.setState({ roomTypes: response.data.dbResult })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRoomNames = async () => {
    try {
      const url = `/hotel-set-room-names`
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        this.setState({ roomNames: response.data.dbResult })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ errorMessage: "Something went wrong!!" })
    }
  }

  fetchRooms = async param_hotel_id => {
    try {
      this.setState({ roomsLoading: true })
      const { activePage, itemsCountPerPage, searchText, hotel_id } = this.state
      const url = `/hotel-rooms/fetch-by-page-number`

      if (cancel !== undefined) {
        cancel()
      }

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        params: {
          page: activePage,
          limit: itemsCountPerPage,
          searchText,
          hotel_id: hotel_id || param_hotel_id,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }
      const response = await axios.get(url, config)

      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: {},
          rooms: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ errorMessage: "Something went wrong!!" })
    }
    this.setState({ roomsLoading: false })
  }

  deleteRoom = async () => {
    this.setState({ showWarnDeleteMsg: false })
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        data: {
          room_id: this.state.selectedRowId,
          hotel_id: this.state.hotel_id,
        },
      }
      const url = "/hotel-rooms"
      const response = await axios.delete(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
        this.setState({ showSuccessDeleteMsg: true })
        this.refreshTable()
      } else {
        this.setState({
          error: true,
          errorMessage: response.data.message,
        })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedRowId => {
    let selectedRowData = this.state.rooms.find(item => {
      return item.id == selectedRowId
    })
    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchRooms)
  }

  refreshTable = () => {
    this.setState(
      {
        activePage: 1,
        searchText: "",
        totalItemsCount: 0,
      },
      this.fetchRooms
    )
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      loading,
      roomsLoading,
      error,
      errorMessage,
      selectedRowId,
      rooms,
      isModalOpen,
      isAddOperation,
      selectedRowData,
      showWarnDeleteMsg,
      showSuccessDeleteMsg,
      showWarningMessage,
      roomTypes,
      roomNames,
      hotel_id,
      showHotelNotRegAlert,
    } = this.state
    const { t } = this.props

    let Content = null
    if (loading || roomsLoading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <RoomTable
          rooms={rooms}
          roomTypes={roomTypes}
          roomNames={roomNames}
          onSelectTableRow={this.onSelectTableRow}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Rooms")}
              breadcrumbItem={t("Room Registration")}
            />
            <ControlButtons
              displayAddBtn={true}
              displayEditBtn={true}
              displayDeleteBtn={true}
              onAddBtn={() => {
                this.setState({ isModalOpen: true, isAddOperation: true })
              }}
              onEditBtn={() => {
                if (selectedRowId) {
                  this.setState({ isModalOpen: true, isAddOperation: false })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onDeleteBtn={() => {
                if (selectedRowId) {
                  this.setState({ showWarnDeleteMsg: true })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState({ searchText, activePage: 1 }, this.fetchRooms)
              }}
            />
            {Content}
          </Container>
        </div>
        {isModalOpen && (
          <Add_EditModal
            modalTitle={isAddOperation ? "Add Room" : "Edit Room"}
            isOpen={true}
            close={() => {
              this.setState({ isModalOpen: false, isAddOperation: false })
            }}
            room={isAddOperation ? {} : selectedRowData}
            rooms={rooms}
            hotel_id={hotel_id}
            roomTypes={roomTypes}
            roomNames={roomNames}
            refreshTable={this.refreshTable}
            isAddOperation={isAddOperation}
          />
        )}

        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}

        {showWarnDeleteMsg && (
          <SweetAlert
            show={true}
            warning
            showCancel
            confirmBtnText={t("Yes, delete it!")}
            cancelBtnText={t("Cancel")}
            confirmBtnBsStyle="danger"
            title={t("Are you sure?")}
            onConfirm={this.deleteRoom}
            onCancel={() => {
              this.setState({ showWarnDeleteMsg: false })
            }}
            focusCancelBtn
          >
            {t("You will not be able to recover it!")}
          </SweetAlert>
        )}

        {showSuccessDeleteMsg && (
          <SweetAlert
            show={true}
            success
            confirmBtnText={t("OK")}
            confirmBtnBsStyle="primary"
            title={t("Deleted!")}
            onConfirm={() => {
              this.setState({ showSuccessDeleteMsg: false })
            }}
          >
            {t("The row is deleted successfully!")}
          </SweetAlert>
        )}
        {showHotelNotRegAlert && (
          <SweetAlert
            show={true}
            warning
            confirmBtnText={t("OK")}
            confirmBtnBsStyle="primary"
            title={t("Warning!")}
            onConfirm={() => {
              this.setState({ showHotelNotRegAlert: false }, () => {
                this.props.history.push("/hotel-information")
              })
            }}
          >
            {t("Please Register Your Hotel Information First!")}
          </SweetAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(connect(mapStateToProps, {})(RoomRegistration))
