import React from "react"
import { Modal, Row, Col, Alert, Spinner, Label } from "reactstrap"
import {
  AvForm,
  AvRadio,
  AvGroup,
  AvInput,
  AvRadioGroup,
} from "availity-reactstrap-validation-safe"
import axios from "axios"
import Select from "react-select"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import SuccessAlert from "components/Common/SuccessAlert/index"
import { getTranslatedOption } from "utils/other"

class CustomModal extends React.Component {
  state = {
    loading: false,
    errorMessage: "",
    showSuccessAlert: false,
    //
    vehicles: [],
    selectedVehicle: "",
    services: [],
    selectedService: "",
    price: "",
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    await Promise.allSettled([
      this.fetchCompanyVehicles(),
      this.fetchExtraServices(),
    ])

    if (!this.props.isAddOperation) {
      await this.populateData()
    }

    this.setState({ loading: false })
  }

  fetchCompanyVehicles = async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/trans-vehicles/names"
      const response = await axios.get(url, config)

      if (response.data.result) {
        const vehicles = response.data.dbResult.map(item => {
          return {
            ...item,
            value: item.id,
            label: getTranslatedOption(item, "type", this.props.i18n.language),
          }
        })
        this.setState({ vehicles })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  fetchExtraServices = async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/transfer-extra-services"
      const response = await axios.get(url, config)

      if (response.data.result) {
        const services = response.data.dbResult.map(item => {
          return {
            value: item.id,
            label: getTranslatedOption(item, "label", this.props.i18n.language),
          }
        })
        this.setState({ services })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  populateData = async () => {
    const { vehicles, services } = this.state
    const { vehicle_id, extraService_id, price, isFree } =
      this.props.extraServicePrice

    const selectedVehicle = vehicles.find(item => item.value == vehicle_id)
    const selectedService = services.find(item => item.value == extraService_id)
    this.setState({
      selectedVehicle,
      selectedService,
      isFree,
      price,
    })
  }

  onValidSubmit = (event, data) => {
    const { selectedVehicle, selectedService } = this.state
    if (
      !selectedVehicle.value ||
      !selectedService.value
    ) {
      this.setState({ errorMessage: "Please fill in the blank!" })
      return
    }

    const payload = {
      isFree: data.isFree === "1",
      price: +data.price,
      vehicle_id: selectedVehicle.value,
      extraService_id: selectedService.value,
      currency_id: 2,
    }

    if (!this.props.isAddOperation) {
      // if update
      payload.id = this.props.extraServicePrice.id
    }

    this.savePrice(payload)
  }

  savePrice = async data => {
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const url = "/transfer-extra-service-prices"
      let response
      if (this.props.isAddOperation) {
        response = await axios.post(url, data, config)
      } else {
        response = await axios.put(url, data, config)
      }

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({ showSuccessAlert: true })
        this.props.refreshTable()
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  render() {
    const { isOpen, close, modalTitle, t, isAddOperation, extraServicePrice } =
      this.props
    const {
      loading,
      errorMessage,
      showSuccessAlert,
      vehicles,
      selectedVehicle,
      services,
      selectedService,
      price,
    } = this.state

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} scrollable={true} size="lg" toggle={close}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
            <button
              type="button"
              onClick={close}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {loading && (
              <div className="spinner-background">
                <Spinner size="lg" color="primary" className="spinner" />
              </div>
            )}
            <AvForm
              onValidSubmit={this.onValidSubmit}
              model={
                isAddOperation
                  ? { isFree: "0" }
                  : { isFree: extraServicePrice.isFree ? "1" : "0" }
              }
            >
              <div className="rows-container min-height-240">
                <Row form>
                  <Col md={6}>
                    <AvGroup className="select2-container">
                      <Label>{t("Vehicle Type")}</Label>
                      <Select
                        value={selectedVehicle}
                        onChange={selectedVehicle =>
                          this.setState({ selectedVehicle })
                        }
                        options={vehicles}
                        noOptionsMessage={() => t("No Options")}
                        classNamePrefix="select2-selection"
                        placeholder={t("Select...")}
                      />
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup className="select2-container">
                      <Label>{t("Extra Service")}</Label>
                      <Select
                        value={selectedService}
                        onChange={selectedService => {
                          this.setState({ selectedService })
                        }}
                        options={services}
                        noOptionsMessage={() => t("No Options")}
                        classNamePrefix="select2-selection"
                        placeholder={t("Select...")}
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="price">{t("Price")}</Label>
                      <AvInput
                        name="price"
                        id="price"
                        autoComplete="off"
                        type="number"
                        min={0}
                        value={price}
                      />
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvRadioGroup name="isFree" required>
                      <Label for="isFree" className="d-block">
                        {t("Is Free")}
                      </Label>
                      <div
                        className="AvRadioGroup__buttons d-flex flex-row"
                        style={{ gap: 16 }}
                      >
                        <AvRadio label={t("Yes")} value="1" />
                        <AvRadio label={t("No")} value="0" />
                      </div>
                    </AvRadioGroup>
                  </Col>
                </Row>
              </div>
              {errorMessage && <Alert color="danger"> {t(errorMessage)}</Alert>}
              {showSuccessAlert && (
                <SuccessAlert
                  hideSuccessAlert={() => {
                    this.setState({ showSuccessAlert: false })
                    this.props.close()
                  }}
                />
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={close}
                >
                  {t("Close")}
                </button>

                <button type="submit" className="btn btn-primary">
                  {this.props.loading ? (
                    <>
                      <Spinner size="sm" /> {t("Loading...")}{" "}
                    </>
                  ) : (
                    t("Save")
                  )}
                </button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  const { country_id } = state.Company.company; 

  return { token, country_id }
}
export default withTranslation()(connect(mapStateToProps, null)(CustomModal))
