import { createContext, useContext, useMemo, useState, useEffect } from "react"
import useRequest from "hooks/useRequest"

const GuestInfoContext = createContext()
export const useGuestInfoContext = () => {
  const context = useContext(GuestInfoContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const GuestInfoProvider = props => {
  const [allowResWithoutAddress, setAllowResWithoutAddress] = useState(false)
  const [allowResWithoutPhone, setAllowResWithoutPhone] = useState(false)

  const [hasAgeLimit, setHasAgeLimit] = useState(false)
  const [ages, setAges] = useState(initialAges)
  const [selectedMinAge, setSelectedMinAge] = useState(initialAges[0])
  const [selectedMaxAge, setSelectedMaxAge] = useState(
    initialAges[initialAges.length - 1]
  )

  const [hasCurfew, setHasCurfew] = useState(false)
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await fetchGuestInfoPolicy()
      setLoading(false)
    })()
  }, [])

  const fetchGuestInfoPolicy = async () => {
    const response = await request(
      {
        url: "/property-guest-info-policy",
      },
      false
    )
    if (response?.dbResult?.id) {
      const {
        allowResWithoutAddress,
        allowResWithoutPhone,
        hasAgeLimit,
        minAge,
        maxAge,
        hasCurfew,
        startTime,
        endTime,
      } = response.dbResult

      setAllowResWithoutAddress(allowResWithoutAddress)
      setAllowResWithoutPhone(allowResWithoutPhone)
      setHasAgeLimit(hasAgeLimit)
      setSelectedMinAge(minAge ? ages.find(i => i.value == minAge) : ages[0])
      setSelectedMaxAge(
        maxAge ? ages.find(i => i.value == maxAge) : ages[ages.length - 1]
      )
      setHasCurfew(hasCurfew)
      setSelectedStartTime(times.find(i => i.value == startTime) || "")
      setSelectedEndTime(times.find(i => i.value == endTime) || "")
    }
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    if (hasCurfew && (!selectedStartTime || !selectedEndTime)) {
      return setErrorMessage("Please fill in the blanks!")
    }

    const payload = {
      allowResWithoutAddress,
      allowResWithoutPhone,
      hasAgeLimit,
      minAge: hasAgeLimit ? selectedMinAge.value : null,
      maxAge: hasAgeLimit ? selectedMaxAge.value : null,
      hasCurfew,
      startTime: hasCurfew ? selectedStartTime.value : null,
      endTime: hasCurfew ? selectedEndTime.value : null,
    }

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/property-guest-info-policy",
        method: "patch",
        data: payload,
      },
      false
    )
    setSubmitLoading(false)

    if (response?.result) {
      setShowSuccessAlert(true)
      fetchGuestInfoPolicy()
    }
  }

  const value = useMemo(() => {
    const setters = {
      setAllowResWithoutAddress,
      setAllowResWithoutPhone,
      setHasAgeLimit,
      setAges,
      setSelectedMinAge,
      setSelectedMaxAge,
      setHasCurfew,
      setSelectedStartTime,
      setSelectedEndTime,
      setShowSuccessAlert,
      setLoading,
      setSubmitLoading,
      setErrorMessage,
    }
    const values = {
      allowResWithoutAddress,
      allowResWithoutPhone,
      hasAgeLimit,
      ages,
      selectedMinAge,
      selectedMaxAge,
      hasCurfew,
      selectedStartTime,
      selectedEndTime,
      showSuccessAlert,
      submitLoading,
      loading,
      errorMessage,
      times,
    }
    const functions = { submitHandler }
    return { setters, values, functions }
  }, [
    allowResWithoutAddress,
    allowResWithoutPhone,
    hasAgeLimit,
    ages,
    selectedMinAge,
    selectedMaxAge,
    hasCurfew,
    selectedStartTime,
    selectedEndTime,
    showSuccessAlert,
    submitLoading,
    loading,
    errorMessage,
  ])

  return <GuestInfoContext.Provider value={value} {...props} />
}

const initialAges = Array(82)
  .fill(1)
  .map((_, i) => ({
    label: 18 + i,
    value: 18 + i,
  }))

const times = []
Array(24)
  .fill(1)
  .forEach((_, i) => {
    Array(2)
      .fill(1)
      .forEach((_, j) => {
        let t = ""
        if (j === 0) {
          t = `${i}:00`
        } else {
          t = `${i}:30`
        }
        times.push({ label: t, value: t })
      })
  })
