import { TiLocation } from "react-icons/ti"
import { HiMinus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"

import styles from "./styles.module.scss"
import { getLabelByLanguage, getFormattedDistance } from "utils/other"

export default ({ data, places, deleteHandler, showDeleteBtn }) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const place = places.find(i => i.id === data.setPlace_id)
  const placeName = getLabelByLanguage(place, locale)
  return (
    <Row className={styles.row}>
      <div className={`${styles.col} pl-2`}>{data?.name}</div>
      <div className={styles.col}>{placeName}</div>
      <div className={styles.col}>{getFormattedDistance(data?.distance)}</div>
      {showDeleteBtn && (
        <div
          className={styles.deleteIcons}
          onClick={deleteHandler.bind("", data.id)}
          title={t("Delete")}
        >
          <HiMinus size={22} className="mr-n2" />
          <TiLocation size={25} />
        </div>
      )}
    </Row>
  )
}
