import React, { useEffect, useState } from "react"
import Select from "react-select"
import { TiLocation } from "react-icons/ti"
import { HiPlus } from "react-icons/hi"
import {
  Card,
  CardBody,
  Container,
  Label,
  Row,
  Button,
  Spinner,
  Alert,
  Input,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"

import ListItem from "./ListItem"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import styles from "./styles.module.scss"

const HotelChildPolicy = () => {
  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [hotel_id, setHotel_id] = useState("")

  const [placeName, setPlaceName] = useState("")
  const [places, setPlaces] = useState([])
  const [selectedPlace, setSelectedPlace] = useState("")
  const [distance, setDistance] = useState("")
  const [hotelPlaces, setHotelPlaces] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    if (places.length > 0) {
      setPlaces(places =>
        places.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
        }))
      )
    }

    if (selectedPlace && selectedPlace.value) {
      setSelectedPlace(place => ({
        ...place,
        label: getLabelByLanguage(place, locale),
      }))
    }
  }, [locale])

  const fetchAll = async () => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )
      if (response?.dbResult) {
        const hotel_id = response.dbResult.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }

    const fetchPlaces = async () => {
      const response = await request(
        {
          url: "/hotel-set-places",
        },
        false
      )
      if (response?.dbResult) {
        const places = response.dbResult?.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
        }))
        setPlaces(places)
      }
    }

    const fetchHotelPlaceDistances = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-place-distances",
          params: { hotel_id },
        },
        false
      )
      if (response?.dbResult) {
        setHotelPlaces(response.dbResult)
      }
    }

    setLoading(true)
    const hotel_id = await fetchHotelData()
    if (!hotel_id) {
      setLoading(false)
      setShowHotelNotRegWarning(true)
      return
    }

    await Promise.allSettled([
      fetchPlaces(),
      fetchHotelPlaceDistances(hotel_id),
    ])
    setLoading(false)
  }

  const addItemHandler = () => {
    if (placeName.trim().length === 0 || !selectedPlace || !distance) {
      return setShowBlankFieldAlert(true)
    }

    // add to hotel places
    setHotelPlaces([
      ...hotelPlaces,
      {
        id: uuid(),
        name: placeName,
        setPlace_id: selectedPlace.value,
        distance,
        hotel_id,
      },
    ])

    // reset
    setPlaceName("")
    setSelectedPlace("")
    setDistance("")
  }

  const deleteItemHandler = id => {
    setHotelPlaces(places => places.filter(i => i.id !== id))
  }

  const submitHandler = async () => {
    setErrorMessage("")
    if (hotelPlaces.length === 0) {
      return setShowBlankFieldAlert(true)
    }
    // send http request
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-place-distances",
        method: "put",
        data: {
          dataList: hotelPlaces,
        },
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Hotels")} breadcrumbItem={t("What's nearby?")} />
        {loading && <CustomSpinner color="primary" />}
        <div className={loading ? "d-none" : ""}>
          <Card>
            <CardBody className={styles.cardBody}>
              <h4 className={styles.title}>{t("Tell us what's nearby")}</h4>
              <p className={`${styles.subtitle} mb-0`}>
                {t(
                  "When deciding where to stay, one of the most essential considerations for visitors is location; they want to know if there are restaurants or grocery stores nearby."
                )}
              </p>
              <p className={styles.subtitle}>
                {t(
                  "What are your top three suggestions for restaurants, cafes, bars, marketplaces and grocery stores?"
                )}
              </p>

              <Row className={`${styles.row} mt-3 mb-2`}>
                <div className={styles.col}>
                  <Label>{t("Place name")}</Label>
                  <Input
                    type="text"
                    value={placeName}
                    onChange={e => setPlaceName(e.target.value)}
                  />
                </div>
                <div className={styles.col}>
                  <Label>{t("Place type")}</Label>
                  <Select
                    placeholder=""
                    value={selectedPlace}
                    noOptionsMessage={() => t("No Options")}
                    options={places}
                    onChange={setSelectedPlace}
                  />
                </div>

                <div className={styles.col}>
                  <Label>{t("Distance in meter")}</Label>
                  <Input
                    type="number"
                    value={distance}
                    onChange={e =>
                      setDistance(e.target.value.replace(/[^0-9\.]+/g, ""))
                    }
                  />
                </div>
                <div
                  className={styles.addIcon}
                  onClick={addItemHandler}
                  title={t("Add")}
                >
                  <HiPlus size={22} className="mr-n2" />
                  <TiLocation size={25} />
                </div>
              </Row>

              <div className={styles.childPolicyList}>
                {hotelPlaces?.map((i, idx) => (
                  <ListItem
                    key={i.id}
                    data={i}
                    places={places}
                    deleteHandler={deleteItemHandler}
                    showDeleteBtn={hotelPlaces.length === idx + 1}
                  />
                ))}
              </div>

              {HotelNotRegisteredComp}
            </CardBody>
          </Card>
          {errorMessage && (
            <Alert color="danger">
              {t(errorMessage?.replace("Error:", "")?.trim())}
            </Alert>
          )}
          <Row className={styles.btnContainer}>
            <Button color="primary" onClick={submitHandler}>
              {submitLoading ? (
                <Spinner color="white" size="sm" />
              ) : (
                t("Submit")
              )}
            </Button>
          </Row>
        </div>
      </Container>
      {/* BLANK FIELD ALERT */}
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      {/* SUCCESS ALERT */}
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => {
          setShowSuccessAlert(false)
          fetchAll()
        }}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default HotelChildPolicy
