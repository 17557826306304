import { Fragment } from "react"
import moment from "moment"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { IoCheckmarkDoneOutline } from "react-icons/io5"
import styles from "./styles.module.scss"

const MessageItem = ({ messages, deleteMessage, customerName }) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  let formattedDate = ""
  let showDate = false
  let unreadShown = false

  return (
    <ul className={styles.messageList}>
      {messages?.map(message => {
        if (!message.showStatus) {
          return null
        }
        showDate = false
        const newFormattedDate = moment(message.createdAt)
          .locale(locale)
          .format("LL")
        if (formattedDate !== newFormattedDate) {
          formattedDate = newFormattedDate
          showDate = true
        }

        let showUnread =
          !unreadShown && !message.readStatus && !!!message.companyUser_id
        if (showUnread) {
          unreadShown = true
        }

        const isMyMessage = !!message.companyUser_id
        return (
          <Fragment key={message.id}>
            {showDate && (
              <li>
                <div className="chat-day-title">
                  <span className="title">{formattedDate}</span>
                </div>
              </li>
            )}
            {showUnread && (
              <li>
                <div className="chat-day-title">
                  <span className="title">{t("Unread Messages")}</span>
                </div>
              </li>
            )}
            <li className={isMyMessage ? "right" : ""}>
              <div className="conversation-list">
                {isMyMessage && (
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn nav-btn" tag="i">
                      <i className="bx bx-dots-vertical-rounded " />
                    </DropdownToggle>
                    <DropdownMenu right={false} flip={false}>
                      <DropdownItem
                        onClick={deleteMessage.bind(null, message.id)}
                      >
                        {t("Delete")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                <div className="ctext-wrap">
                  <div className="conversation-name">
                    {isMyMessage ? message?.companyUserName : customerName}
                  </div>
                  <p>{message.message}</p>
                  <p className="chat-time mb-0">
                    <i className="bx bx-time-five align-middle mr-1" />
                    {moment(message.createdAt).format("hh:mm")}
                    {isMyMessage && (
                      <IoCheckmarkDoneOutline
                        size={19}
                        className="ml-2"
                        color={message.readStatus ? "#264bfb" : ""}
                      />
                    )}
                  </p>
                </div>
              </div>
            </li>
          </Fragment>
        )
      })}
    </ul>
  )
}

export default MessageItem
