import { useTranslation } from "react-i18next"
import { useGuestInfoContext } from "./context"

import styles from "./styles.module.scss"
const GuestInfoSection = () => {
  const { t } = useTranslation()
  const { values, setters } = useGuestInfoContext()
  const { allowResWithoutAddress, allowResWithoutPhone } = values
  const { setAllowResWithoutAddress, setAllowResWithoutPhone } = setters

  return (
    <section className={styles.section}>
      <h4>{t("Guest Info")}</h4>
      {/* FIRST Q */}
      <div className={styles.questionContainer}>
        <p>{t("Can guests book without providing address details?")}</p>
        <div className={styles.buttons}>
          <label htmlFor="addressYes">
            <input
              id="addressYes"
              type="radio"
              name="allowResWithoutAddress"
              checked={allowResWithoutAddress === true}
              value={allowResWithoutAddress}
              onChange={() => setAllowResWithoutAddress(true)}
            />
            {t("Yes")}
          </label>
          <label htmlFor="addressNo">
            <input
              id="addressNo"
              type="radio"
              name="allowResWithoutAddress"
              value={allowResWithoutAddress}
              checked={allowResWithoutAddress === false}
              onChange={() => setAllowResWithoutAddress(false)}
            />
            {t("No")}
          </label>
        </div>
      </div>
      {/* SECOND Q */}
      <div className={styles.questionContainer}>
        <p>{t("Can guests book without providing a phone number?")}</p>
        <div className={styles.buttons}>
          <label htmlFor="phoneYes">
            <input
              id="phoneYes"
              type="radio"
              name="allowResWithoutPhone"
              checked={allowResWithoutPhone === true}
              value={allowResWithoutPhone}
              onChange={() => setAllowResWithoutPhone(true)}
            />
            {t("Yes")}
          </label>
          <label htmlFor="phoneNo">
            <input
              id="phoneNo"
              type="radio"
              name="allowResWithoutPhone"
              value={allowResWithoutPhone}
              checked={allowResWithoutPhone === false}
              onChange={() => setAllowResWithoutPhone(false)}
            />
            {t("No")}
          </label>
        </div>
      </div>
    </section>
  )
}

export default GuestInfoSection
