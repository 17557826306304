import React from "react"
import { useTranslation } from "react-i18next"
import { Modal, Alert, Spinner } from "reactstrap"

import TicketInfo from "./TicketInfo"
import styles from "./styles.module.scss"
import TicketAddress from "./TicketAddress"
import TicketChildPolicy from "./TicketChildPolicy"
import TicketDescription from "./TicketDescription"
import SuccessAlert from "components/Common/SuccessAlert"
import { useTicketContext, TicketContextProvider } from "./context"

const AddEditTicket = props => {
  const { values, setters, functions, useFormResult } = useTicketContext()
  const { submitTicket } = functions
  const { setErrorMessage, setShowSuccessAlert } = setters
  const {
    loading,
    errorMessage,
    showSuccessAlert,
    submitLoading,
    selectedTimes,
  } = values

  const { handleSubmit } = useFormResult
  const { isOpen, close, modalTitle } = props
  const { t } = useTranslation()

  const submitData = data => {
    if (selectedTimes.length === 0) {
      return setErrorMessage("Add a time!")
    }
    setErrorMessage("")

    const payload = {
      ...data,
      ...(!props?.isAddOperation && { id: props?.ticket?.id }),
      times: selectedTimes,
      chd1: data.isChildAllowed ? data.chd1 : null,
      chd2: data.isChildAllowed ? data.chd2 : null,
    }

    const method = props?.isAddOperation ? "post" : "put"
    submitTicket(method, payload)
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {loading && (
          <div className="spinner-background">
            <Spinner size="lg" color="primary" className="spinner" />
          </div>
        )}
        <form onSubmit={handleSubmit(submitData)}>
          <div className={styles.rowsContainer}>
            <TicketInfo {...props} useFormResult={useFormResult} />
            <TicketChildPolicy {...props} useFormResult={useFormResult} />
            <TicketAddress {...props} useFormResult={useFormResult} />
            <TicketDescription {...props} useFormResult={useFormResult} />
          </div>
          {errorMessage && <Alert color="danger"> {t(errorMessage)}</Alert>}

          {showSuccessAlert && (
            <SuccessAlert
              hideSuccessAlert={() => {
                setShowSuccessAlert(false)
                props.refreshTable()
                props.close()
              }}
            />
          )}

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={close}>
              {t("Close")}
            </button>

            <button type="submit" className="btn btn-primary">
              {t("Save")} {submitLoading && <Spinner size="sm" />}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const WithContext = props => {
  return (
    <TicketContextProvider>
      <AddEditTicket {...props} />
    </TicketContextProvider>
  )
}

export default WithContext
