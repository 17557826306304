import { useState, useEffect, useImperativeHandle, forwardRef } from "react"
import Select from "react-select"
import { IoMdBed } from "react-icons/io"
import { HiPlus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import {
  AvGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation-safe"
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Spinner,
  Alert,
} from "reactstrap"

import BedTypeItem from "./BedTypeItem"
import styles from "../styles.module.scss"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

const bedTypeCountOptions = Array(5)
  .fill(null)
  .map((_, i) => ({ label: i + 1, value: i + 1 }))

export default forwardRef((props, ref) => {
  const [bedTypes, setBedTypes] = useState([])
  const [selectedBedTypes, setSelectedBedTypes] = useState([])
  const [selectedBedType, setSelectedBedType] = useState("")
  const [selectedBedTypeCount, setSelectedBedTypeCount] = useState(
    bedTypeCountOptions[0]
  )

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const fetchSetRoomBedTypes = async () => {
    const response = await request({
      url: "/hotel-set-room-bed-types",
    })
    const bedTypes = response.dbResult.map(i => ({
      ...i,
      label: getLabelByLanguage(i, locale),
      value: i.id,
    }))
    setBedTypes(bedTypes)
    return bedTypes
  }

  const fetchRoomBedTypes = async bedTypes => {
    const room_id = props.room.id
    const response = await request({
      url: "/hotel-room-bed-types/fetch-by-room-id",
      params: { room_id },
    })

    let selectedBedTypes = []
    let updatedBedTypes = [...bedTypes]
    response?.dbResult?.forEach(item => {
      for (let i = 0; i < bedTypes.length; i++) {
        const element = bedTypes[i]
        if (element.id === item.setRoomBedType_id) {
          // add item to selectedBedTypes
          selectedBedTypes.push({
            ...element,
            count: item.count,
          })
          // delete item from updatedBedTypes
          const index = updatedBedTypes.findIndex(
            x => x.id === item.setRoomBedType_id
          )
          updatedBedTypes.splice(index, 1)
          break
        }
      }
    })
    setSelectedBedTypes(selectedBedTypes)
    setBedTypes(updatedBedTypes)
  }

  useEffect(() => {
    ;(async () => {
      const bedTypes = await fetchSetRoomBedTypes()
      if (!props.isAddOperation) {
        fetchRoomBedTypes(bedTypes)
      }
    })()
  }, [])

  const addBedType = () => {
    if (!selectedBedType?.value || !selectedBedTypeCount?.value) return
    // add selected bed type to state
    const updatedSelectedBedTypes = [...selectedBedTypes]
    updatedSelectedBedTypes.push({
      ...selectedBedType,
      count: selectedBedTypeCount.value,
    })
    setSelectedBedTypes(updatedSelectedBedTypes)
    // remove added bed type from bedTypes state
    let updatedBedTypes = [...bedTypes]
    updatedBedTypes = updatedBedTypes.filter(
      i => i.value !== selectedBedType.value
    )
    setBedTypes(updatedBedTypes)
    // reset select option
    setSelectedBedType("")
    setSelectedBedTypeCount(bedTypeCountOptions[0])
  }

  const deleteHandler = id => {
    // delete item from selectedBedTypes state
    const updatedSelectedBedTypes = [...selectedBedTypes]
    const bedTypeIndex = updatedSelectedBedTypes.findIndex(i => i.value === id)
    const bedType = updatedSelectedBedTypes[bedTypeIndex]
    updatedSelectedBedTypes.splice(bedTypeIndex, 1)
    setSelectedBedTypes(updatedSelectedBedTypes)
    // add the item to bedTypes state
    const updatedBedTypes = [...bedTypes]
    updatedBedTypes.push(bedType)
    setBedTypes(updatedBedTypes)
  }

  const getState = () => ({ selectedBedTypes })
  useImperativeHandle(ref, () => ({ getState }))

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <CardTitle>{t("Number of beds and accommodation")}</CardTitle>
        <hr />
        {loading && <Spinner color="primary" className="absolute-spinner" />}
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <div className={loading ? "invisible" : ""}>
          <Row form>
            <Col sm={6}>
              <AvGroup>
                <Label>
                  * {t("Bed type")}{" "}
                  <span className={styles.info}>
                    (
                    {t(
                      "Do not forget to press the Add button after selecting the Bed Type."
                    )}
                    )
                  </span>
                </Label>
                <Select
                  value={selectedBedType}
                  onChange={setSelectedBedType}
                  options={bedTypes}
                  noOptionsMessage={() => t("No Options")}
                  placeholder={t("Select...")}
                />
              </AvGroup>
            </Col>
            <Col>
              <div className={styles.bedTypeCountContainer}>
                <Select
                  value={selectedBedTypeCount}
                  onChange={setSelectedBedTypeCount}
                  options={bedTypeCountOptions}
                  className={styles.bedTypeCountSelect}
                />
                <span
                  className={styles.icons}
                  onClick={addBedType}
                  title={t("Add")}
                >
                  <HiPlus size={22} />
                  <IoMdBed size={35} className={styles.lastIcon} />
                </span>
              </div>
            </Col>
          </Row>
          {selectedBedTypes.map(item => (
            <BedTypeItem
              key={item.value}
              selectedBedType={item}
              onDelete={deleteHandler}
            />
          ))}
          <hr className={styles.hr} />
          <div className={styles.extraBedGiveableContainer}>
            <p>* {t("Can an extra bed be added to this room?")}</p>
            <AvRadioGroup name="extraBedGiveable" inline>
              <AvRadio label={t("Yes")} value={true} />
              <AvRadio label={t("No")} value={false} />
            </AvRadioGroup>
          </div>
          <div className={styles.extraBedGiveableContainer}>
            <p>
              * {t("Can an extra child bed or crib be added to this room?")}
            </p>
            <AvRadioGroup name="extraChildBedGiveable" inline>
              <AvRadio label={t("Yes")} value={true} />
              <AvRadio label={t("No")} value={false} />
            </AvRadioGroup>
          </div>
        </div>
      </CardBody>
    </Card>
  )
})
