import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationEN from "./locales/en/translation.json"
import translationFR from "./locales/fr/translation.json"
import translationGr from "./locales/de/translation.json"
import translationRS from "./locales/ru/translation.json"
import translationTR from "./locales/tr/translation.json"
import translationAR from "./locales/ar/translation.json"

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationGr,
  },
  ru: {
    translation: translationRS,
  },
  tr: {
    translation: translationTR,
  },
  ar: {
    translation: translationAR,
  },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "tr")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "tr",
    fallbackLng: "tr", // use tr if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
