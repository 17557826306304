import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import React, { useState, useEffect } from "react"
import { Col, Row, Container, Card, Alert } from "reactstrap"

import TopItem from "./TopItem"
import BottomItem from "./BottomItem"
import useRequest from "hooks/useRequest"
import { useSocket } from "contexts/socket-context"
// images/icons
import img1 from "assets/images/hp-icons/1.png"
import img2 from "assets/images/hp-icons/2.png"
import img3 from "assets/images/hp-icons/3.png"
import img4 from "assets/images/hp-icons/4.png"
import img5 from "assets/images/hp-icons/5.png"

import styles from "./dashboard.module.scss"
import { useMasterSocket } from "contexts/master-socket-context"
import { getTranslatedOption } from "utils/other"

const TransferHomePage = () => {
  const [, setErrorMessage] = useState("")
  const [, setLoading] = useState(false)
  const [reservationCount, setReservationCount] = useState(0)
  const [cStatuses, setCStatuses] = useState({})
  const [showWarning, setShowWarning] = useState(false)

  const userName = useSelector(
    state => state.User.currentUser.name + " " + state.User.currentUser.surname
  )
  const { request } = useRequest(setLoading, setErrorMessage)
  const { unreadMessageCount: resUnreadMessageCount } = useSocket()
  const { unreadMessageCount: masterUnreadMessageCount } = useMasterSocket()
  const totalUnreadMessageCount =
    (resUnreadMessageCount || 0) + (masterUnreadMessageCount || 0)

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    const fetchPendingReservationCount = async () => {
      const response = await request(
        {
          url: "/transfer-reservations/get-count-by-company-id",
        },
        false
      )
      if (response?.dbResult) {
        setReservationCount(response?.dbResult?.pendingReservationsCount)
      }
    }

    const fetchCompulsoryStatuses = async () => {
      const response = await request(
        {
          url: "/transfer/compulsory-tables-statuses",
        },
        false
      )
      if (response?.dbResult) {
        const statuses = response.dbResult
        setCStatuses(statuses)
        for (const [_, value] of Object.entries(statuses)) {
          if (value === 0) {
            setShowWarning(true)
            break
          }
        }
      }
    }

    ;(async () => {
      await Promise.allSettled([
        fetchPendingReservationCount(),
        fetchCompulsoryStatuses(),
      ])
    })()
  }, [])

  return (
    <Container className={styles.container}>
      <Alert
        color="danger"
        className={styles.warning}
        isOpen={showWarning}
        toggle={setShowWarning.bind(null, false)}
      >
        <p>{t("Warning!")}</p>
        <p>{t("Please fill out the following pages carefully.")}</p>
        <ul>
          {cStatuses.totalVehicleCount === 0 && (
            <li>
              <Link to="/vehicle-registration" className="alert-link">
                {t("Vehicle Registration")} -{" "}
                {t("Register at least one active vehicle!")}
              </Link>
            </li>
          )}

          {!cStatuses.hasCancellationPolicy && (
            <li>
              <Link to="/transfer-cancellation-policy" className="alert-link">
                {t("Cancellation Policy")}
              </Link>
            </li>
          )}
          {!cStatuses.hasPrepaymentPolicy && (
            <li>
              <Link to="/transfer-prepayment-policy" className="alert-link">
                {t("Payment Policy")}
              </Link>
            </li>
          )}

          {!cStatuses.hasTax && (
            <li>
              <Link to="/transfer-kdv-tax-fees" className="alert-link">
                {t("Tax Policy")}
              </Link>
            </li>
          )}

          {cStatuses?.photos?.length > 0 && (
            <>
              <li>
                <Link to="/vehicle-photos" className="alert-link">
                  {t("Room Photos")}
                </Link>
                <ul className="mt-1 mb-2">
                  {cStatuses.photos.map((item, index) => (
                    <li key={index}>
                      <Link to="/vehicle-photos" className="alert-link">
                        {getTranslatedOption(item, "name", locale)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </>
          )}
        </ul>
      </Alert>
      {/* TOP ITEMS */}
      <Row>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/setup-user"}
            bgColor={"#40189d"}
            imgSrc={img1}
            label={t("View Profile")}
            content={userName}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/transfer-reservations"}
            bgColor={"#72ace5"}
            imgSrc={img1}
            label={t("Reservations")}
            content={reservationCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/reservation-messages"}
            bgColor={"#9ac46d"}
            imgSrc={img1}
            label={t("Messages")}
            content={totalUnreadMessageCount}
          />
        </Col>
        <Col xs={6} xl={3}>
          <TopItem
            link={"/#"}
            bgColor={"#3b8965"}
            imgSrc={img1}
            label={t("Comments")}
            content={0}
          />
        </Col>
      </Row>
      <Card className={styles.middleCard}>{t("Operations")}</Card>
      {/* BOTTOM ITEMS */}
      <Row>
        {bottomItems.map(item => (
          <Col xs={6} xl={3} key={item.label}>
            <BottomItem
              link={item.link}
              imgSrc={item.imgSrc}
              label={t(item.label)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default TransferHomePage

const bottomItems = [
  { label: "Pricing", imgSrc: img1, link: "/transfer-pricing" },
  {
    label: "Transfer Promotions",
    imgSrc: img2,
    link: "/transfer-promotions",
  },
  {
    label: "Vehicle Registration",
    imgSrc: img4,
    link: "/vehicle-registration",
  },
  { label: "Reservation", imgSrc: img2, link: "/transfer-reservations" },
  {
    label: "Pricing Report",
    imgSrc: img3,
    link: "/transfer-pricing-report",
  },
  { label: "User", imgSrc: img4, link: "/setup-user" },
  { label: "Settings", imgSrc: img5, link: "/setup-company-settings" },
]
