import axios from "axios"
import * as yup from "yup"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory, Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { yupResolver } from "@hookform/resolvers/yup"
import { Card, CardBody, Container, Spinner, Button, Alert } from "reactstrap"

import Layout from "./Layout"
import styles from "./reset-password.module.scss"

const schema = yup.object({
  password: yup.string().required("Password is required!").min(6, "Too short!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
})

function ResetPasswordBox() {
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const history = useHistory()
  const paramToken = history.location.search.split("=")[1]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = async data => {
    setErrorMessage("")
    try {
      setLoading(true)
      const config = {
        url: "/auth/reset-password",
        method: "put",
        headers: {
          Authorization: "Bearer " + paramToken,
        },
        data,
      }

      const response = await axios.request(config)
      setLoading(false)
      if (response.data.result) {
        setSuccessModalVisible(true)
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  return (
    <Layout>
      <Container>
        <main className={styles.wrapper}>
          <Card className={styles.card}>
            <CardBody>
              <h4>{t("Reset Your Password")}</h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className={`input-box ${errors.password && styles.error}`}
                    >
                      <label className="label-text">{t("Password")}</label>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          placeholder={t("Password")}
                          ref={register}
                        />
                        <p>{t(errors?.password?.message)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className={`input-box ${
                        errors.confirmPassword && styles.error
                      }`}
                    >
                      <label className="label-text">
                        {t("Confirm Password")}
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control"
                          autoComplete="new-password"
                          type="password"
                          placeholder={t("Password")}
                          name="confirmPassword"
                          ref={register}
                        />
                        <p>{t(errors?.confirmPassword?.message)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="btn-box margin-bottom-20px">
                      {errorMessage && (
                        <Alert color="danger">{t(errorMessage)}</Alert>
                      )}
                      <Button color="primary" type="submit">
                        {t("Submit")}{" "}
                        {loading && (
                          <Spinner
                            size="sm"
                            className="align-self-center ml-1"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
          <div className="mt-5 text-center">
            {locale !== "tr" && (
              <p>
                Go back to{" "}
                <Link to="login" className="font-weight-medium text-primary">
                  Login
                </Link>{" "}
              </p>
            )}
            {locale === "tr" && (
              <p>
                <Link to="login" className="font-weight-medium text-primary">
                  Girişe
                </Link>{" "}
                geri dön
              </p>
            )}
            <p>
              © {new Date().getFullYear()} Vihobook.{" "}
              {locale !== "tr" && (
                <>
                  Crafted with <i className="mdi mdi-heart text-danger" />
                </>
              )}
              {locale === "tr" && (
                <>
                  <i className="mdi mdi-heart text-danger" />
                  {"  "} ile hazırlanmış
                </>
              )}
            </p>
          </div>
        </main>
      </Container>
      {successModalVisible && (
        <SweetAlert
          title={t("Congratulations!")}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setSuccessModalVisible(false)
            history.push("/login")
          }}
          confirmBtnText={t("OK")}
        >
          <h4>
            {t("Your password has been reset successfully. Please login again")}
          </h4>
        </SweetAlert>
      )}
    </Layout>
  )
}
export default ResetPasswordBox
