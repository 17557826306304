import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@mui/styles"
import { Label } from "reactstrap"

const useStyles = makeStyles(() => ({
  formControl: {
    width: 140,
    height: 36.41,
    background: "white",
  },
  col: {
    display: "flex",
    flexDirection: "Column",
  },
  input: {
    fontSize: 13.5,
    color: "#495057",
  },
}))

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
}

const CustomSelect = ({ label, onChange, items, renderValue }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const id = `custom-select-${label}`
  return (
    <div className={classes.col}>
      <Label>{t(label)}</Label>
      <FormControl className={classes.formControl} size="small">
        <Select
          id={id}
          multiple
          displayEmpty
          value={[]}
          input={<OutlinedInput label="" className={classes.input} />}
          MenuProps={MenuProps}
          renderValue={renderValue}
        >
          {items?.map(item => (
            <MenuItem key={item.id} onClick={() => onChange(item)}>
              <Checkbox checked={item?.isChecked} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelect
