import {
  USER_API_ERROR,
  GET_CURRENT_USER,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCEED,
  ADD_NEW_USER,
  ADD_NEW_USER_START,
  ADD_NEW_USER_SUCCEED,
  GET_CURRENT_COMPANY_USERS,
  GET_CURRENT_COMPANY_USERS_START,
  GET_CURRENT_COMPANY_USERS_SUCCEED,
  UPDATE_USER,
  UPDATE_USER_START,
  UPDATE_USER_SUCCEED,
  DELETE_USER,
  DELETE_USER_START,
  DELETE_USER_SUCCEED,
} from "./actionTypes"

export const userApiError = errorMessage => {
  return {
    type: USER_API_ERROR,
    errorMessage: errorMessage,
  }
}

export const getCurrentUser = token => {
  return {
    type: GET_CURRENT_USER,
    token,
  }
}

export const getCurrentUserStart = () => {
  return {
    type: GET_CURRENT_USER_START,
  }
}

export const getCurrentUserSucceed = user => {
  return {
    type: GET_CURRENT_USER_SUCCEED,
    user,
  }
}

export const addNewUser = (newUser, callback) => {
  return {
    type: ADD_NEW_USER,
    newUser,
    callback,
  }
}

export const addNewUserStart = () => {
  return {
    type: ADD_NEW_USER_START,
  }
}

export const addNewUserSucceed = () => {
  return {
    type: ADD_NEW_USER_SUCCEED,
  }
}

export const getCurrentCompanyUsers = callback => {
  return {
    type: GET_CURRENT_COMPANY_USERS,
    callback,
  }
}

export const getCurrentCompanyUsersStart = () => {
  return {
    type: GET_CURRENT_COMPANY_USERS_START,
  }
}

export const getCurrentCompanyUsersSucceed = () => {
  return {
    type: GET_CURRENT_COMPANY_USERS_SUCCEED,
  }
}

export const updateUser = (user, callback) => {
  return {
    type: UPDATE_USER,
    user,
    callback,
  }
}

export const updateUserStart = () => {
  return {
    type: UPDATE_USER_START,
  }
}

export const updateUserSucceed = () => {
  return {
    type: UPDATE_USER_SUCCEED,
  }
}

export const deleteUser = (id, callback) => {
  return {
    type: DELETE_USER,
    id,
    callback,
  }
}

export const deleteUserStart = () => {
  return {
    type: DELETE_USER_START,
  }
}

export const deleteUserSucceed = () => {
  return {
    type: DELETE_USER_SUCCEED,
  }
}
