import React from "react"
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import WidgetPagination from "components/Common/WidgetPagination/index"

class ChildPolicyTable extends React.Component {
  state = {
    selectedRowId: "",
  }

  render() {
    const {
      t,
      onSelectTableRow,
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      onPageChange,
      hasPagination,
      //
      policies,
      hotelRoomNames,
      hotelBoardTypes,
    } = this.props

    const RowsComponent = policies?.map(item => {
      // find room types
      const roomName = hotelRoomNames?.find(i => i.room_id === item.room_id)
      const boardType = hotelBoardTypes?.find(
        i => i.id === item.setBoardType_id
      )

      return (
        <Tr
          key={item.id}
          onClick={() => {
            onSelectTableRow(item.id)
            this.setState({ selectedRowId: item.id })
          }}
          className={this.state.selectedRowId === item.id ? "selectedRow" : ""}
        >
          <Td className="align-middle text-nowrap">{item.name}</Td>
          <Td className="align-middle text-nowrap">{roomName?.name}</Td>
          <Td className="align-middle text-nowrap">{boardType?.name}</Td>
        </Tr>
      )
    })

    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div className="table-responsive mb-0 data-table-container">
                  <Table
                    id="main-table"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th className="table-data">{t("Policy Name")}</Th>
                        <Th className="table-data">{t("Room Name")}</Th>
                        <Th className="table-data">{t("Board Type")}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>{RowsComponent}</Tbody>
                  </Table>
                </div>
                {hasPagination && (
                  <WidgetPagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(ChildPolicyTable)
