import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"
import { getLabelByLanguage } from "utils/other"


import WidgetPagination from "components/Common/WidgetPagination/index"
import styles from "./styles.module.scss"

const TicketsTable = props => {
  const { t } = useTranslation()
  const {
    onSelectTableRow,
    selectedRowId,
    tickets,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    onPageChange,
    hasPagination,
  } = props

  const locale = useTranslation().i18n.language


  let TicketsComp = tickets.map((item) => {
    const ticketTypeName = getLabelByLanguage(item, locale)
    return (
      <Tr
        key={item.id}
        onClick={() => {
          onSelectTableRow(item.id)
        }}
        className={selectedRowId === item.id ? styles.selectedRow : ""}
      >
        <Td className={styles.dataCell}>{ticketTypeName}</Td>
        <Td className={styles.dataCell}>{item.name}</Td>
        <Td className={styles.dataCell}>{item.maxCapacity}</Td>
        <Td className={styles.dataCell}>
          {item.status ? t("Active") : t("Inactive")}
        </Td>
        <Td className={styles.dataCell}>{item.ageMin}</Td>
        <Td className={styles.dataCell}>{item.ageMax}</Td>
        <Td className={styles.dataCell}>{item.isChildAllowed ? t("Yes") : t("No")}</Td>
        <Td className={styles.dataCell}>{item.address}</Td>
      </Tr>
    )
  })

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0 data-table-container">
                <Table
                  id="main-table"
                  className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th className={styles.tableData}>{t("Ticket Name")}</Th>
                      <Th className={styles.tableData}>{t("Special Name")}</Th>
                      <Th className={styles.tableData}>{t("Max. Capacity")}</Th>
                      <Th className={styles.tableData}>{t("Status")}</Th>                  
                      <Th className={styles.tableData}>{t("Min. Age")}</Th>
                      <Th className={styles.tableData}>{t("Max. Age")}</Th>
                      <Th className={styles.tableData}>{t("Child Guest")}</Th>
                      <Th className={styles.tableData}>{t("Address")}</Th>
                      
                    </Tr>
                  </Thead>
                  <Tbody>{TicketsComp}</Tbody>
                </Table>
              </div>
              {hasPagination && (
                <WidgetPagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}



export default TicketsTable
