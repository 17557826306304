import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useMasterSocket } from "contexts/master-socket-context"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

const ChatContext = createContext()
export const useChatContext = () => {
  const context = useContext(ChatContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const ChatProvider = props => {
  const { masterSocket } = useMasterSocket()
  const currentUser = useSelector(state => state.User.currentUser)

  const [contacts, setContacts] = useState([])
  const [messages, setMessages] = useState([])
  const [currentContact, setCurrentContact] = useState(null)
  const [currentMessage, setCurrentMessage] = useState("")
  const [subjectTypes, setSubjectTypes] = useState([])
  const [showNewCModal, setShowNewCModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [scroll, setScroll] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const {
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    if (!masterSocket) return
    // SET LISTENERS
    masterSocket.on("new-conversation-created", newCon => {
      setContacts(prev => [
        {
          ...newCon,
          unreadMessageCount: 0,
        },
        ...prev,
      ])

      if (!currentContact) {
        setCurrentContact(newCon)
      }
    })

    // listening for new messages to come
    masterSocket.on("receive-message", newMsg => {
      // update contacts lastMessage and time
      setContacts(prev => {
        let newContacts = prev.map(i => {
          if (i.id === newMsg.conversation_id) {
            return {
              ...i,
              lastMessage: newMsg.message,
              time: new Date(newMsg.createdAt) - 2000,
              unreadMessageCount: i.unreadMessageCount + 1,
            }
          } else {
            return i
          }
        })
        newContacts.sort(
          (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
        )

        return newContacts
      })

      // update messages if currentConversation_id is same as newMessageConversation_id
      setCurrentContact(con => {
        if (con.id === newMsg.conversation_id) {
          setMessages(prev => [...prev, newMsg])
        }
        return con
      })

      setScroll(true)
    })

    masterSocket.on("messages-read", conversation_id => {
      setMessages(messages =>
        messages.map(i =>
          i.conversation_id === conversation_id ? { ...i, readStatus: true } : i
        )
      )
    })

    // REMOVE LISTENER
    return () => {
      if (masterSocket) {
        masterSocket.off("receive-message")
        masterSocket.off("new-conversation-created")
      }
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    // FETCH SUBJECTS
    ;(async () => {
      const response2 = await request(
        {
          url: "/set-conversation-subject-types",
        },
        false
      )

      if (response2?.result) {
        const types = response2.dbResult.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
        }))
        setSubjectTypes(types)
      }
    })()

    const onCustomerConversationsReceive = response => {
      if (response?.result) {
        let contacts = response?.dbResult || []
        contacts = contacts.map(i => ({
          ...i,
        }))
        setContacts(contacts)

        if (contacts.length > 0) {
          setCurrentContact(contacts[0])
        }
      } else {
        setErrorMessage(response.message)
      }

      setLoading(false)
    }

    masterSocket.emit("fetch-conversations", onCustomerConversationsReceive)
  }, [masterSocket])

  useEffect(() => {
    if (currentContact) {
      const payload = { conversation_id: currentContact.id }
      masterSocket.emit("fetch-messages", payload, response => {
        if (response?.result) {
          setMessages(response?.dbResult)
          setScroll(true)
        } else {
          setErrorMessage(response?.message)
        }
      })
    }
  }, [currentContact])

  useEffect(() => {
    if (subjectTypes.length > 0) {
      setSubjectTypes(prev =>
        prev.map(i => ({ ...i, label: getLabelByLanguage(i, locale) }))
      )
    }
  }, [locale])

  const newConversationHandler = data => {
    const payload = {
      title: data.title,
      setSubjectType_id: data.subjectType.value,
    }
    masterSocket.emit("create-new-conversation", payload, response => {
      if (response?.result) {
        const newCon = response.newConversation
        addMessage(newCon, data.message)
      }

      setShowNewCModal(false)
    })
  }

  const addMessage = useCallback(
    (contact, newMessage) => {
      const payload = {
        conversation_id: contact.id,
        message: newMessage,
      }

      const cb = response => {
        if (response?.result) {
          const newMsg = response.dbResult
          setCurrentMessage("")
          setContacts(prev => {
            let newCons = prev.map(con =>
              con.id === newMsg.conversation_id
                ? {
                    ...con,
                    lastMessage: newMsg.message,
                    time: new Date(newMsg.createdAt).getTime() - 5000,
                  }
                : con
            )
            newCons.sort(
              (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
            )
            return newCons
          })
          setMessages(prev => [...prev, newMsg])
          setScroll(true)
        }
      }

      masterSocket.emit("send-message", payload, cb)
    },
    [masterSocket, contacts]
  )

  const setMessagesToRead = useCallback(
    conversation => {
      if (conversation?.unreadMessageCount > 0) {
        const cb = response => {
          if (response?.result) {
            setContacts(prev => {
              return prev.map(i =>
                i.id === conversation.id ? { ...i, unreadMessageCount: 0 } : i
              )
            })
            setMessages(prevMsg =>
              prevMsg.map(i => ({ ...i, readStatus: true }))
            )
          }
        }
        const customer_id = contacts.find(
          i => i.id === conversation.id
        ).customer_id
        masterSocket.emit(
          "set-master-messages-to-read",
          { conversation_id: conversation.id, customer_id },
          cb
        )
      }
    },
    [masterSocket, contacts]
  )

  const deleteMessage = useCallback(
    message_id => {
      masterSocket.emit("delete-message", { message_id }, response => {
        if (response?.result) {
          setMessages(prev => prev.filter(i => i.id !== message_id))
        }
      })
    },
    [masterSocket]
  )

  const value = useMemo(() => {
    const setters = {
      setLoading,
      setSubmitLoading,
      setErrorMessage,
      //
      setContacts,
      setMessages,
      setCurrentContact,
      setCurrentMessage,
      setScroll,
      setShowNewCModal,
    }
    const values = {
      submitLoading,
      loading,
      errorMessage,
      //
      currentUser,
      contacts,
      messages,
      currentContact,
      currentMessage,
      scroll,
      subjectTypes,
      showNewCModal,
    }
    const functions = {
      addMessage,
      setMessagesToRead,
      deleteMessage,
      newConversationHandler,
    }
    return { setters, values, functions }
  }, [
    // functions
    addMessage,
    setMessagesToRead,
    deleteMessage,
    newConversationHandler,
    submitLoading,
    loading,
    errorMessage,
    //
    currentUser,
    contacts,
    messages,
    currentContact,
    currentMessage,
    scroll,
    subjectTypes,
    showNewCModal,
  ])

  return <ChatContext.Provider value={value} {...props} />
}
