import { useContext, useMemo, createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import {
  generateDateBetween,
  addDays,
  getTodayDate,
} from "utils/dateTimeFunctions"
import { getLabelByLanguage, all } from "utils/other"

const PropertyPricingContext = createContext()

export const usePropertyPricingContext = () => {
  const context = useContext(PropertyPricingContext)
  if (!context) {
    throw new Error("Error: The component must be wrapped by the provider!")
  }
  return context
}

export const PropertyPricingProvider = props => {
  const [fromDate, setFromDate] = useState(getTodayDate())
  const [toDate, setToDate] = useState(addDays(getTodayDate(), 7))
  const [villas, setVillas] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])

  const [villaRegError, setVillaRegError] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchCompanyVillas = async () => {
      const response = await request(
        {
          url: "/property/names",
        },
        false
      )

      const villas = response?.properties?.map(i => ({
        ...i,
        isChecked: false,
      }))
      setVillas(villas)

      if (villas.length === 0) {
        setVillaRegError(true)
      }
    }
    const fetchCompanyMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )
      const marketingPlaces = response?.dbResult?.map(i => ({
        id: i.setMarketingPlace_id,
        name: i.setMarketingPlaceName,
        isChecked: false,
      }))
      marketingPlaces.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setMarketingPlaces(marketingPlaces)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchCompanyVillas(),
        fetchCompanyMarketingPlaces(),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (marketingPlaces?.length > 0) {
      const updatedMarketingPlaces = [...marketingPlaces]
      updatedMarketingPlaces[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(updatedMarketingPlaces)
    }
  }, [locale])

  // CHECK HANDLERS

  const villaCheckHandler = id => {
    setVillas(
      villas.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        } else {
          return { ...i, isChecked: false }
        }
      })
    )
  }

  const marketingPlaceCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked

    setMarketingPlaces(updatedList)
  }

  const submitHandler = (e, data) => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/property-daily-price",
          method: "patch",
          data: { payload },
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")
    const currency_id = +currencies.find(i => i.isChecked)?.id

    if (!currency_id) {
      return setError(t("Please select a currency!"))
    }

    const property_id = villas.find(i => i.isChecked)?.id
    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (!property_id) {
      return setError(t("Please select a property!"))
    }
    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    const payload = []

    // generate dates
    const dates = generateDateBetween(fromDate, toDate)

    Object.keys(dates).forEach(date => {
      setMarketingPlace_ids.forEach(setMarketingPlace_id => {
        payload.push({
          date,
          property_id,
          currency_id,
          price: data.price,
          setMarketingPlace_id,
        })
      })
    })

    submit(payload)
  }

  const currencyCheckHandler = id => {
    setCurrencies(prev =>
      prev.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        } else {
          return { ...i, isChecked: false }
        }
      })
    )
  }

  const cancelHandler = () => {
    setFromDate(getTodayDate())
    setToDate(addDays(getTodayDate(), 7))
    setCurrencies([
      { id: 2, name: "EUR", isChecked: true },
      { id: 3, name: "TRY", isChecked: false },
    ])
    setVillas(villas.map(i => ({ ...i, isChecked: false })))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setError(null)
  }

  const value = useMemo(() => {
    const values = {
      fromDate,
      toDate,
      villas,
      marketingPlaces,
      villaRegError,
      showSuccessAlert,
      submitLoading,
      currencies,
      loading,
      error,
    }
    const setters = {
      setFromDate,
      setToDate,
      setVillaRegError,
      setShowSuccessAlert,
      setSubmitLoading,
      setLoading,
      setError,
    }
    const functions = {
      submitHandler,
      cancelHandler,
      villaCheckHandler,
      marketingPlaceCheckHandler,
      currencyCheckHandler,
    }
    return { values, setters, functions }
  }, [
    fromDate,
    toDate,
    villas,
    marketingPlaces,
    villaRegError,
    showSuccessAlert,
    submitLoading,
    currencies,
    loading,
    error,
  ])

  return <PropertyPricingContext.Provider value={value} {...props} />
}
