import { IoMdBed } from "react-icons/io"
import { HiMinus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"

import styles from "../styles.module.scss"

export default ({ selectedBedType, onDelete }) => {
  const { t } = useTranslation()

  return (
    <Row form>
      <Col sm={6} className={styles.bedTypeItem_Label}>
        {selectedBedType.label}
      </Col>
      <Col>
        <div className="row m-0">
          <span className={styles.count}>{selectedBedType.count}</span>
          <span
            className={styles.iconsDel}
            onClick={onDelete.bind("", selectedBedType.value)}
            title={t("Delete")}
          >
            <HiMinus size={22} />
            <IoMdBed size={35} className={styles.lastIcon} />
          </span>
        </div>
      </Col>
    </Row>
  )
}
