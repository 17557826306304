import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  Spinner,
  Row,
  Input,
  Tooltip,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert"
import Checkboxes from "components/Hotel/Marketing/Checkboxes/Checkboxes"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import ChildDetailsModal from "./ChildDetailsModal"
import styles from "./styles.module.scss"
import CustomSwitch from "components/Common/CustomSwitch"
// custom hooks
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

// fns
import {
  getLabelByLanguage,
  initialApplicationDays_shortName,
  all,
} from "utils/other"
import {
  formatToServerDate,
  addDays,
  getTodayDate,
} from "utils/dateTimeFunctions"
import { HiOutlineInformationCircle } from "react-icons/hi"

export default function Pricing() {
  const [hotel_id, setHotel_id] = useState(null)
  const [room_id, setRoom_id] = useState(null)
  const [fromDate, setFromDate] = useState(getTodayDate())
  const [toDate, setToDate] = useState(addDays(getTodayDate(), 7))
  const [roomNames, setRoomNames] = useState([])
  const [childPolicies, setChildPolicies] = useState([])
  const [selectedChildPolicy, setSelectedChildPolicy] = useState("")
  const [checkedRoomName, setCheckedRoomName] = useState(null)
  const [boardTypes, setBoardTypes] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [applicationDays, setApplicationDays] = useState(
    initialApplicationDays_shortName
  )
  const [roomRegError, setRoomRegError] = useState(false)
  const [isRoomAllowChildren, setIsRoomAllowChildren] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [isChildAllowed, setIsChildAllowed] = useState(false)
  const [prices, setPrices] = useState([])
  const [childDetailsEnabled, setChildDetailsEnabled] = useState(false)
  const [showChildModal, setShowChildModal] = useState(false)
  const [showPriceTooltip, setShowPriceTooltip] = useState(false)
  const [useRatio, setUseRatio] = useState(false)
  const [useTRY, setUseTRY] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()
  const history = useHistory()
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchHotelData = async () => {
      const response = await request(
        {
          url: "/hotel-registration",
        },
        false
      )

      if (response?.dbResult) {
        const hotel_id = response?.dbResult?.id
        setHotel_id(hotel_id)
        return hotel_id
      }
    }

    const fetchRoomNames = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-room-names",
          params: { hotel_id },
        },
        false
      )
      const roomNames = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
        isChecked: false,
      }))
      setRoomNames(roomNames)
      if (roomNames.length === 0) {
        setRoomRegError(true)
      }
    }

    const fetchBoardTypes = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-board-types/fetch-names-by-hotel-id",
          params: { hotel_id },
        },
        false
      )
      const boardTypes = response?.dbResult?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
        isChecked: false,
      }))
      setBoardTypes(boardTypes)
    }

    const fetchCompanyMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        id: i.setMarketingPlace_id,
        name: i.setMarketingPlaceName,
        isChecked: false,
      }))

      marketingPlaces.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })

      setMarketingPlaces(marketingPlaces)
    }

    const fetchHotelChild = async hotel_id => {
      const response = await request(
        {
          url: "/hotel-child",
          params: { hotel_id },
        },
        false
      )

      setIsChildAllowed(response?.dbResult?.isChildAllowed || false)
    }
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      await Promise.allSettled([
        fetchRoomNames(hotel_id),
        fetchBoardTypes(hotel_id),
        fetchCompanyMarketingPlaces(),
        fetchHotelChild(hotel_id),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setRoomNames(
      roomNames.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )
    setBoardTypes(
      boardTypes.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
    )
    setApplicationDays(applicationDays.map(i => ({ ...i, name: t(i.name_en) })))

    // translate all to hepsi on locale change
    if (marketingPlaces?.length > 2) {
      let newMarketingPlaces = [...marketingPlaces]
      newMarketingPlaces[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newMarketingPlaces)
    }
  }, [locale])

  useEffect(() => {
    const selectedRoomName = roomNames.find(i => i.isChecked)
    const room_id = selectedRoomName?.room_id
    const boardType = boardTypes.find(i => i.isChecked)
    const setBoardType_id = boardType?.id
    const currency_id = useTRY ? 3 : 2

    if (room_id && setBoardType_id && isChildAllowed) {
      const fetchChildPolicies = async () => {
        const response = await request({
          url: "/hotel-child-policies",
          params: {
            room_id,
            setBoardType_id,
            currency_id,
            hotel_id,
          },
        })

        const childPolicies = response?.dbResult?.map(i => ({
          ...i,
          label: i.name,
          value: i.id,
        }))

        setChildPolicies(childPolicies)
        setSelectedChildPolicy("")
      }
      fetchChildPolicies()
    }
  }, [roomNames, boardTypes, useTRY])

  useEffect(() => {
    const isValid = fieldsValid().length === 0
    if (isValid && !childDetailsEnabled) {
      setChildDetailsEnabled(true)
    } else if (!isValid && childDetailsEnabled) {
      setChildDetailsEnabled(false)
    }
  }, [prices, selectedChildPolicy, childDetailsEnabled])

  // CHECK HANDLERS
  const roomNamesCheckHandler = id => {
    const updatedRoomNames = roomNames.map(i => {
      if (id === i.id) {
        return { ...i, isChecked: !i.isChecked }
      } else {
        return { ...i, isChecked: false }
      }
    })
    const isRoomAllows = updatedRoomNames?.find(i => i.isChecked)?.maxChild > 0
    setRoomNames(updatedRoomNames)
    setIsRoomAllowChildren(isRoomAllows)
    // find checkedRoomName
    const checkedRoomName = updatedRoomNames.find(i => i.isChecked)
    setCheckedRoomName(checkedRoomName)
    setPrices(
      Array(checkedRoomName?.maxAdult)
        .fill(0)
        .map((_, idx) => ({
          id: idx + 1,
          ratio: "",
          coefficient: "",
          price: "",
        }))
    )

    setRoom_id(checkedRoomName?.room_id)
  }

  const boardTypesCheckHandler = id => {
    const updatedBoardTypes = boardTypes.map(i => {
      if (id === i.id) {
        return { ...i, isChecked: !i.isChecked }
      } else {
        return { ...i, isChecked: false }
      }
    })
    setBoardTypes(updatedBoardTypes)
  }

  const marketingPlacesCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedList = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked

    setMarketingPlaces(updatedList)
  }

  const applicationDaysCheckHandler = id => {
    const updatedApplicationDays = applicationDays.map(i => {
      if (id === i.id) {
        return { ...i, isChecked: !i.isChecked }
      } else {
        return i
      }
    })
    setApplicationDays(updatedApplicationDays)
  }

  const fieldsValid = () => {
    let errorMessage = ""

    const setBoardType_id = boardTypes.find(i => i.isChecked)?.id
    let marketingPlace_ids = marketingPlaces
      .slice(1)
      .reduce((filtered, item) => {
        if (item.isChecked) {
          filtered.push(item.id)
        }
        return filtered
      }, [])

    if (!room_id) {
      return (errorMessage = t("Please select a room type!"))
    }
    if (!setBoardType_id) {
      return (errorMessage = t("Please select a board type!"))
    }
    if (isChildAllowed && isRoomAllowChildren && !selectedChildPolicy) {
      return (errorMessage = t("Please select a child policy!"))
    }

    if (marketingPlaces.length === 2) {
      marketingPlace_ids = [1]
    } else if (marketingPlace_ids?.length === 0) {
      return (errorMessage = t("Please select a marketing place!"))
    }

    const filteredPrices = prices.filter(
      i => !i.coefficient > 0 || !i.price > 0
    )
    if (filteredPrices.length > 0) {
      return (errorMessage = t("Please fill in all prices!"))
    }
    return errorMessage
  }

  const submitHandler = e => {
    e.preventDefault()
    // it generates dates between two date and exclude unchecked application days
    const generateDateBetween = (startDate, endDate) => {
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      const excludedDays = applicationDays.filter(i => !i.isChecked)
      const excludedDaysIndexes = excludedDays.map(i => i.id)
      let day = 1000 * 60 * 60 * 24
      let diff = (endDate.getTime() - startDate.getTime()) / day
      let dateList = []
      for (let i = 0; i <= diff; i++) {
        let newDate = startDate.getTime() + day * i
        newDate = new Date(newDate)
        let isExcluded = false
        for (let j = 0; j < excludedDaysIndexes.length; j++) {
          if (excludedDaysIndexes[j] === newDate.getDay()) {
            isExcluded = true
            break
          }
        }
        if (!isExcluded) dateList.push(formatToServerDate(newDate))
      }
      return dateList
    }

    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/hotel-room-prices",
          method: "put",
          data: { payload },
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")
    const errorMessage = fieldsValid()
    if (errorMessage) {
      setError(errorMessage)
      return
    }

    const setBoardType_id = boardTypes.find(i => i.isChecked)?.id
    let marketingPlace_ids = marketingPlaces
      .slice(1)
      .reduce((filtered, item) => {
        if (item.isChecked) {
          filtered.push(item.id)
        }
        return filtered
      }, [])
    if (marketingPlaces.length === 2) {
      marketingPlace_ids = [1]
    }

    const pricesByPersonCount = prices.map(p => ({
      personCount: p.id,
      coefficient: p.coefficient,
      price: p.price,
    }))

    const currency_id = useTRY ? 3 : 2

    const payload = []

    // generate dates
    const dates = generateDateBetween(fromDate, toDate)
    dates.forEach(date => {
      marketingPlace_ids?.forEach(setMarketingPlace_id =>
        payload.push({
          room_id,
          date,
          currency_id,
          setBoardType_id,
          hotel_id,
          pricesByPersonCount,
          setMarketingPlace_id,
          childPolicy_id: selectedChildPolicy?.value,
        })
      )
    })
    submit(payload)
  }

  const cancelHandler = () => {
    setFromDate(getTodayDate())
    setToDate(addDays(getTodayDate(), 7))
    setRoomNames(roomNames.map(i => ({ ...i, isChecked: false })))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setCheckedRoomName(null)
    setUseTRY(false)
    setRoom_id(null)
    setBoardTypes(boardTypes.map(i => ({ ...i, isChecked: false })))
    setApplicationDays(applicationDays.map(i => ({ ...i, isChecked: true })))
    setError(null)
    setSelectedChildPolicy("")
  }

  const toNumber = value => value.replace(/[^\d\.]/g, "")

  const maxAdult = checkedRoomName?.maxAdult
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Marketing")} breadcrumbItem={t("Pricing")} />
        <form onSubmit={submitHandler}>
          <Card>
            <CardBody className={styles.cardBody}>
              {loading ? (
                <CustomSpinner color="primary" />
              ) : (
                <>
                  <div className={styles.row}>
                    <div>
                      {/* DATE INPUTS */}
                      <div className={styles.datesContainer}>
                        <div className={styles.dateInputContainer}>
                          <Label>{t("From")}</Label>
                          <CustomDayPicker
                            onChange={date => {
                              setFromDate(date)
                              if (date.getTime() > toDate.getTime()) {
                                setToDate(date)
                              }
                            }}
                            value={fromDate}
                            disabledDays={[]}
                          />
                        </div>
                        <div className={styles.dateInputContainer}>
                          <Label>{t("To")}</Label>
                          <CustomDayPicker
                            onChange={setToDate}
                            value={toDate}
                            disabledDays={[{ before: fromDate }]}
                          />
                        </div>
                      </div>
                      {/* APPLICATION DAYS */}
                      <Checkboxes
                        label="Application Days"
                        items={applicationDays}
                        onCheck={applicationDaysCheckHandler}
                        className={styles.applicationDays}
                      />
                    </div>

                    <Checkboxes
                      label="Room Types"
                      items={roomNames}
                      onCheck={roomNamesCheckHandler}
                    />
                    <Checkboxes
                      label="Board Types"
                      items={boardTypes}
                      onCheck={boardTypesCheckHandler}
                    />

                    {marketingPlaces?.length > 2 && (
                      <Checkboxes
                        label="Marketing Places"
                        items={marketingPlaces}
                        onCheck={marketingPlacesCheckHandler}
                      />
                    )}
                  </div>

                  {checkedRoomName && country_id === 107 && (
                    <div className={styles.row}>
                      <CustomSwitch
                        label="Do you want to use TRY as your currency?"
                        className={styles.currencies}
                        onClick={() => setUseTRY(prevState => !prevState)}
                        value={useTRY}
                      />
                    </div>
                  )}
                  {checkedRoomName && (
                    <div className={styles.roomData}>
                      <span>{getLabelByLanguage(checkedRoomName, locale)}</span>
                      <span>
                        {checkedRoomName?.maxAdult} {t("Person")}
                      </span>
                      <span>
                        {checkedRoomName?.maxChild} {t("Child")}
                      </span>
                      <span>
                        {t("Capacity")}: {checkedRoomName?.maxCapacity}
                      </span>
                    </div>
                  )}
                  {checkedRoomName && (
                    <div
                      className={`${styles.switchWrapper} ${
                        prices.length > 0 && prices[0].coefficient > 0
                          ? styles.switchDisabled
                          : ""
                      }`}
                    >
                      <CustomSwitch
                        label="Do you want to use ratio in price definition?"
                        className={styles.customSwitch}
                        onClick={() => setUseRatio(prevState => !prevState)}
                        value={useRatio}
                      />
                    </div>
                  )}
                  {/* PRICING AND CHILD POLICY */}
                  <div className={styles.row}>
                    {/*  PRICING BOX */}
                    <div className={styles.pricingBox}>
                      <Label>{t("Prices")}</Label>
                      {maxAdult && (
                        <>
                          <table>
                            <thead>
                              <tr>
                                <th>{t("Person")}</th>
                                {useRatio && (
                                  <th className="text-center">{t("Ratio")}</th>
                                )}
                                <th className="text-center">{t("Price")}</th>
                                <th>{t("Currency")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={useRatio ? 2 : 1}>
                                  {t("Price Per Person Per Night")}
                                </td>
                                <td>
                                  <Input
                                    value={prices[0].coefficient}
                                    onChange={e => {
                                      const coefficient = toNumber(
                                        e.target.value
                                      )
                                      setPrices(prices =>
                                        prices.map(i => ({
                                          ...i,
                                          coefficient,
                                          price: useRatio
                                            ? coefficient * i.ratio
                                            : i.price,
                                        }))
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  {useTRY ? "TRY" : "EUR"}
                                </td>
                              </tr>
                              {prices.map(i => (
                                <tr key={i.id}>
                                  <td>
                                    {i.id}. {t("Person")}
                                  </td>
                                  {useRatio && (
                                    <td
                                      className={styles.ratioWrapper}
                                      id={`Tooltip-input`}
                                    >
                                      <Input
                                        onChange={e => {
                                          const ratio = toNumber(e.target.value)
                                          setPrices(
                                            prices.map(p =>
                                              p.id === i.id
                                                ? {
                                                    ...p,
                                                    ratio,
                                                    price: (
                                                      ratio * i.coefficient
                                                    ).toFixed(2),
                                                  }
                                                : p
                                            )
                                          )
                                        }}
                                        value={i.ratio}
                                        disabled={!(i.coefficient > 0)}
                                      />
                                    </td>
                                  )}
                                  <td id={`Tooltip-input`}>
                                    <Input
                                      onChange={e => {
                                        const price = toNumber(e.target.value)
                                        setPrices(
                                          prices.map(p =>
                                            p.id === i.id
                                              ? {
                                                  ...p,
                                                  price,
                                                  ratio: (
                                                    price / i.coefficient
                                                  ).toFixed(2),
                                                }
                                              : p
                                          )
                                        )
                                      }}
                                      value={i.price}
                                      disabled={!(i.coefficient > 0)}
                                    />
                                  </td>
                                  <td>
                                    {useTRY ? "TRY" : "EUR"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <Tooltip
                            placement="auto"
                            isOpen={
                              prices[0].coefficient > 0
                                ? false
                                : showPriceTooltip
                            }
                            target={"Tooltip-input"}
                            toggle={() => {
                              !(prices[0].coefficient > 0) &&
                                setShowPriceTooltip(!showPriceTooltip)
                            }}
                          >
                            {t(
                              "Please first fill in the price per person per night field"
                            )}
                          </Tooltip>
                        </>
                      )}
                    </div>
                    {/* CHILD POLICY */}
                    {isChildAllowed && isRoomAllowChildren && (
                      <div className={styles.childBox}>
                        <Label className={styles.childBoxLabel}>
                          {t("Choose Child Price Policy")}
                        </Label>
                        {maxAdult && (
                          <>
                            <Select
                              placeholder={t("Select...")}
                              className={styles.childPolicy}
                              value={selectedChildPolicy}
                              noOptionsMessage={() => t("No Options")}
                              options={childPolicies}
                              onChange={setSelectedChildPolicy}
                              styles={customStyles}
                            />
                            <button
                              type="button"
                              disabled={!childDetailsEnabled}
                              onClick={setShowChildModal.bind(null, true)}
                              className={styles.childDetailsButton}
                            >
                              {t("Show Child Details")}
                            </button>

                            <div className={styles.ppppn}>
                              <HiOutlineInformationCircle size={18} />
                              <p>
                                {t(
                                  "Child Prices are calculated over the price per person per night."
                                )}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
              {error && (
                <Alert color="danger" className={styles.alert}>
                  {error}
                </Alert>
              )}
            </CardBody>
          </Card>

          <Row className={styles.buttons}>
            <Button size="md" type="button" onClick={cancelHandler}>
              {t("Cancel")}
            </Button>
            <Button size="md" color="primary">
              {t("Submit")} {submitLoading && <Spinner size="sm" />}
            </Button>
          </Row>
        </form>
      </Container>
      {HotelNotRegisteredComp}
      {roomRegError && (
        <SweetAlert
          warning
          title={t("Warning!")}
          confirmBtnText={t("OK")}
          onConfirm={() => {
            setRoomRegError(false)
            history.push("/hotel-room-registration")
          }}
        >
          {t("Please first register a room!")}
        </SweetAlert>
      )}
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            cancelHandler()
            setFromDate(toDate)
            setToDate(addDays(toDate, 7))
          }}
        />
      )}
      {showChildModal && (
        <ChildDetailsModal
          isOpen={true}
          close={setShowChildModal.bind(null, false)}
          hotel_id={hotel_id}
          adultPrices={prices}
          currency_id={useTRY ? 3 : 2}
          childPolicy={selectedChildPolicy}
          roomData={checkedRoomName}
        />
      )}
    </main>
  )
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "30px",
    boxShadow: state.isFocused ? null : null,
    borderColor: "#ced4da",

    "&:hover": {
      borderColor: "#ced4da",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30.5px",
    padding: "0px 8px",
    lineHeight: "none",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32.5px",

    "& > div": {
      padding: "6px 8px",
    },
  }),
}
