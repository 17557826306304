import { useContext, useMemo, createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import {
  addDays,
  getTodayDate,
  formatToServerDate,
} from "utils/dateTimeFunctions"
import { getLabelByLanguage, all } from "utils/other"

const ExtraServicesContext = createContext()

export const useExtraServicesContext = () => {
  const context = useContext(ExtraServicesContext)
  if (!context) {
    throw new Error("Error: The component must be wrapped by the provider!")
  }
  return context
}

export const ExtraServicesProvider = props => {
  const [fromDate, setFromDate] = useState(getTodayDate())
  const [toDate, setToDate] = useState(addDays(getTodayDate(), 7))
  const [villas, setVillas] = useState([])
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])
  const [extraServices, setExtraServices] = useState([])

  const [villaRegError, setVillaRegError] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchCompanyVillas = async () => {
      const response = await request(
        {
          url: "/property/names",
        },
        false
      )

      const villas = response?.properties?.map(i => ({
        ...i,
        isChecked: false,
      }))
      setVillas(villas)

      if (villas.length === 0) {
        setVillaRegError(true)
      }
    }
    const fetchExtraServices = async () => {
      const response = await request(
        {
          url: "/property-set-extra-services",
        },
        false
      )
      const extraServices = response?.dbResult?.map(i => ({
        id: i.id,
        name: i.name_en,
        isChecked: false,
      }))
      extraServices.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setExtraServices(extraServices)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchCompanyVillas(), fetchExtraServices()])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (extraServices?.length > 0) {
      const updatedExtraServices = [...extraServices]
      updatedExtraServices[0].name = getLabelByLanguage(all, locale)
      setExtraServices(updatedExtraServices)
    }
  }, [locale])

  // CHECK HANDLERS

  const villaCheckHandler = id => {
    setVillas(
      villas.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        } else {
          return { ...i, isChecked: false }
        }
      })
    )
  }

  const currencyCheckHandler = id => {
    setCurrencies(prev =>
      prev.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        } else {
          return { ...i, isChecked: false }
        }
      })
    )
  }

  const extraServicesCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = extraServices.map(i => ({
        ...i,
        isChecked: !extraServices[0].isChecked,
      }))
    } else {
      updatedList = extraServices.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    updatedList[0].isChecked = isAllChecked

    setExtraServices(updatedList)
  }

  const submitHandler = (e, data) => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/property-extra-services",
          method: "patch",
          data: { payload },
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")
    const currency_id = currencies.find(i => i.isChecked)?.id
    if (!currency_id) {
      return setError(t("Please select a currency!"))
    }
    const property_id = villas.find(i => i.isChecked)?.id
    const setExtraServices_ids = extraServices
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (!property_id) {
      return setError(t("Please select a property!"))
    }
    if (setExtraServices_ids.length === 0) {
      return setError(t("Please select a extra service!"))
    }
    const payload = []
    setExtraServices_ids.forEach(setExtraService_id => {
      payload.push({
        setExtraService_id,
        currency_id,
        price: data.price,
        startDate: formatToServerDate(fromDate),
        endDate: formatToServerDate(toDate),
        minAccommodation: data.minAccommodation,
        property_id,
        isCompulsory: data.isCompulsory,
        status: 1,
      })
    })
    submit(payload)
  }

  const cancelHandler = () => {
    setFromDate(getTodayDate())
    setToDate(addDays(getTodayDate(), 7))
    setVillas(villas.map(i => ({ ...i, isChecked: false })))
    setCurrencies([
      { id: 2, name: "EUR", isChecked: true },
      { id: 3, name: "TRY", isChecked: false },
    ])
    setExtraServices(extraServices.map(i => ({ ...i, isChecked: false })))
    setError(null)
  }

  const value = useMemo(() => {
    const values = {
      fromDate,
      currencies,
      toDate,
      villas,
      extraServices,
      villaRegError,
      showSuccessAlert,
      submitLoading,
      loading,
      error,
    }
    const setters = {
      setFromDate,
      setToDate,
      setVillaRegError,
      setShowSuccessAlert,
      setSubmitLoading,
      setLoading,
      setError,
    }
    const functions = {
      submitHandler,
      cancelHandler,
      villaCheckHandler,
      currencyCheckHandler,
      extraServicesCheckHandler,
    }
    return { values, setters, functions }
  }, [
    fromDate,
    toDate,
    villas,
    currencies,
    extraServices,
    villaRegError,
    showSuccessAlert,
    submitLoading,
    loading,
    error,
  ])

  return <ExtraServicesContext.Provider value={value} {...props} />
}
