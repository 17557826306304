import MainComponent from "./Component/Component"

const InfluencerMarketing = () => {
  return (
    <MainComponent
      title={"Increase your sales with social media influencers"}
      opportunityType_id={1}
    />
  )
}

export default InfluencerMarketing
