import React from "react"
import { Card, CardBody, Label } from "reactstrap"

import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

import { useTranslation } from "react-i18next"

const StayDates = props => {
  const { subtitle, startDate, endDate, setStartDate, setEndDate } =
    props
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{t(subtitle)}</h5>
        {/* DATE INPUTS */}
        <div className={styles.datesContainer}>
          <div className={styles.dateInputContainer}>
            <Label>{t("From")}</Label>
            <CustomDayPicker
              onChange={date => {
                setStartDate(date)
                if (date.getTime() > endDate.getTime()) {
                  setEndDate(date)
                }
              }}
              value={startDate}
              disabledDays={[{ before: new Date() }]}
            />
          </div>
          <div className={styles.dateInputContainer}>
            <Label>{t("To")}</Label>
            <CustomDayPicker
              onChange={setEndDate}
              value={endDate}
              disabledDays={[{ before: startDate }]}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default StayDates
