import React, { Component } from "react"
import Pagination from "react-js-pagination"
//import "bootstrap/dist/css/bootstrap.min.css"

class WidgetPagination extends Component {
  render() {
    const { activePage, itemsCountPerPage, totalItemsCount, onPageChange } =
      this.props
    return (
      <div className="pagination__container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={onPageChange}
          itemClass="page-item pagination-inactive"
          linkClass="page-link"
          activeClass="pagination-active"
        />
      </div>
    )
  }
}

export default WidgetPagination
