import { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Button,
  Spinner,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import Breadcrumb from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import PaymentCheckboxes from "./PaymentCheckboxes/index"
import styles from "./styles.module.scss"
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

const HotelPaymentMethods = () => {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [hotel_id, setHotel_id] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }
      const paymentMethods = await fetchPaymentMethods()
      await fetchHotel_PaymentMethod(paymentMethods, hotel_id)
      setLoading(false)
    })()
  }, [])

  const fetchPaymentMethods = async () => {
    const response = await request(
      {
        url: "/hotel-set-payment-method",
      },
      false
    )
    const HotelPaymentMethods = response?.dbResult?.map(i => ({
      ...i,
      title: i.name_en,
      isChecked: false,
    }))
    setPaymentMethods(HotelPaymentMethods)
    return HotelPaymentMethods
  }

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      setHotel_id(response.dbResult.id)
      return response.dbResult.id
    }
  }

  const fetchHotel_PaymentMethod = async (paymentMethods, hotel_id) => {
    const response = await request(
      {
        url: "/hotel-payment-method",
        params: { hotel_id },
      },
      false
    )

    const hotel_paymentMethods = response?.dbResult
    const updatedPaymentMethods = [...paymentMethods]

    hotel_paymentMethods?.forEach(i => {
      for (let j = 0; j < updatedPaymentMethods.length; j++) {
        const paymentMethod = updatedPaymentMethods[j]
        if (i.setPaymentMethod_id === paymentMethod.id) {
          updatedPaymentMethods[j] = { ...paymentMethod, isChecked: true }
          break
        }
      }
    })

    setPaymentMethods(updatedPaymentMethods)
  }

  useEffect(() => {
    const updatedPaymentMethods = paymentMethods?.map(item => {
      let title = ""
      switch (locale) {
        case "tr":
          title = item.name_tr
          break
        case "fr":
          title = item.name_fr
          break
        case "de":
          title = item.name_de
          break
        case "ar":
          title = item.name_ar
          break
        case "ru":
          title = item.name_ru
          break
        default:
          title = item.name_en
          break
      }
      return {
        ...item,
        title,
      }
    })

    setPaymentMethods(updatedPaymentMethods)
  }, [locale, paymentMethods?.length])

  const paymentMethodCheckHandler = id => {
    const updatedPaymentMethods = [...paymentMethods]
    const index = updatedPaymentMethods.findIndex(item => item.id === id)
    const paymentMethod = updatedPaymentMethods[index]
    updatedPaymentMethods[index] = {
      ...paymentMethod,
      isChecked: !paymentMethod.isChecked,
    }
    setPaymentMethods(updatedPaymentMethods)
  }

  const submitHandler = async () => {
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    const dataList = []
    paymentMethods.forEach(i => {
      if (i.isChecked) {
        dataList.push({
          setPaymentMethod_id: i.id,
          hotel_id,
        })
      }
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-payment-method",
        method: "put",
        data: { dataList, hotel_id },
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response?.message)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumb
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Payment Methods")}
        />
        {loading && <CustomSpinner color="primary" />}
        {!loading && (
          <Card className={styles.card}>
            <CardTitle className={styles.cardTitle}>
              {t("Payment Methods")}
            </CardTitle>
            <CardSubtitle className={styles.cardSubtitle}>
              {t("Specify the payment terms applicable to your facility")}
            </CardSubtitle>
            <CardBody className={styles.cardBody}>
              <PaymentCheckboxes
                title="Please select accepted payment methods in the facility."
                items={paymentMethods}
                onChecked={paymentMethodCheckHandler}
              />
              {HotelNotRegisteredComp}
            </CardBody>
          </Card>
        )}
        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={styles.btnContainer}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

export default HotelPaymentMethods
