import { takeEvery, put, call, delay } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { AUTO_LOGOUT, LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  loginSuccess,
  logoutUser,
  autoLogout,
  logoutUserSuccess,
  apiError,
} from "./actions"
import { getCompany, getCompanySucceed } from "../../company/actions"
import * as cookie from "utils/cookie"

const saveUserToCookie = (user, isRememberMeChecked) => {
  if (isRememberMeChecked) {
    cookie.setCookie("companyCode", user.companyCode)
    cookie.setCookie("email", user.email)
    cookie.setCookie("password", user.password)
  } else {
    cookie.eraseCookie("companyCode")
    cookie.eraseCookie("email")
    cookie.eraseCookie("password")
  }
}

function* loginUserSaga({ payload: { user, history, isRememberMeChecked } }) {
  try {
    let url = "/auth/login"
    const response = yield axios.post(url, user)

    if (response.data.result == true) {
      const { userId, token, expiresIn } = response.data
      yield put(loginSuccess(userId, token))
      yield put(getCompany(token))

      // save data to local storage for auto login
      const expirationDate = yield new Date(
        new Date().getTime() + expiresIn * 1000
      )
      yield localStorage.setItem("token", token)
      yield localStorage.setItem("expirationDate", expirationDate)
      yield localStorage.setItem("userId", userId)
      //

      yield saveUserToCookie(user, isRememberMeChecked)
      yield put(autoLogout(expiresIn, history))
      yield history.push("/")
    } else {
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    yield put(apiError("Something went wrong!!"))
  }
}

function* logoutUserSaga(action) {
  try {
    yield call([localStorage, "removeItem"], "token")
    yield call([localStorage, "removeItem"], "expirationDate")
    yield call([localStorage, "removeItem"], "userId")
    yield put(logoutUserSuccess())
    yield put(getCompanySucceed({}))
    if (action.history) {
      yield action.history.push("/login")
    }
  } catch (error) {
    yield put(apiError("Something went wrong!!"))
  }
}

export function* autoLogoutSaga(action) {
  yield delay(action.expiresIn * 1000)
  yield put(logoutUser(action.history))
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUserSaga)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
  yield takeEvery(AUTO_LOGOUT, autoLogoutSaga)
}

export default authSaga
