import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Spinner, Button, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import PromotionName from "./components/PromotionName"
import RadioButtons from "./components/RadioButtons"
import Dates from "./components/Dates"
import Members from "./components/Members"
import Checkboxes from "./components/Checkboxes"
import DiscountRate from "./components/DiscountRate"
import Currencies from "./components/Currencies"

import SuccessAlert from "components/Common/SuccessAlert"
import { getLabelByLanguage, all, getTranslatedOption } from "utils/other"
import { addDays, getTodayDate, formatDate } from "utils/dateTimeFunctions"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner"

const TicketPromotion = () => {
  const { country_id } = useSelector(state => ({
    country_id: state.Company.company.country_id,
  }))

  const [onlyMembers, setOnlyMembers] = useState(false)
  const [promotionTypes, setPromotionTypes] = useState([])
  const [tickets, setTickets] = useState([])
  const [currencies, setCurrencies] = useState([
    { id: 2, name: "EUR", isChecked: true },
    { id: 3, name: "TRY", isChecked: false },
  ])

  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [discountRate, setDiscountRate] = useState(10)

  const [resStartDate, setResStartDate] = useState(getTodayDate())
  const [resEndDate, setResEndDate] = useState(addDays(getTodayDate(), 7))

  const [bookingStartDate, setBookingStartDate] = useState(getTodayDate())
  const [bookingEndDate, setBookingEndDate] = useState(
    addDays(getTodayDate(), 7)
  )

  const [promotionName, setPromotionName] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")

  const { request } = useRequest(setLoading, setError)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      if (response?.result && Array.isArray(response?.dbResult)) {
        const marketingPlaces = response?.dbResult?.map(i => ({
          ...i,
          id: i.id[1],
          isChecked: false,
        }))

        marketingPlaces.unshift({
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })
        setMarketingPlaces(marketingPlaces)
      }
    }

    const fetchPromotionTypes = async () => {
      const response = await request({
        url: "/ticket-promotion-types",
      })

      if (response?.result && Array.isArray(response?.dbResult)) {
        const types = response?.dbResult?.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
          isChecked: false,
        }))
        if (types.length > 0) {
          types[0].isChecked = true
        }

        setPromotionTypes(types)
      }
    }

    const fetchTickets = async () => {
      const response = await request(
        {
          url: "/ticket/names",
        },
        false
      )

      const activeTickets = response?.tickets
        ?.filter(ticket => ticket.status === true)
        .map(i => ({
          ...i,
          name: `${getTranslatedOption(i, "name", locale)} (${i.specialName})`,
          isChecked: false,
        }))

      activeTickets.unshift({
        ...all,
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })

      setTickets(activeTickets)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchPromotionTypes(),
        fetchSetMarketingPlaces(),
        fetchTickets(),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setPromotionTypes(list =>
      list.map(item => ({ ...item, label: getLabelByLanguage(item, locale) }))
    )

    setMarketingPlaces(list => [
      { ...list[0], label: getLabelByLanguage(all, locale) },
      ...list.slice(1),
    ])

    setTickets(list =>
      list.map(i => ({
        ...i,
        name: `${getTranslatedOption(i, "name", locale)} (${i.specialName})`,
      }))
    )
  }, [locale])

  useEffect(() => {
    const promotionTypeLabel = promotionTypes.find(i => i.isChecked)?.label
    setPromotionName(
      `%${discountRate}  ${promotionTypeLabel}  ${formatDate(getTodayDate())}`
    )
  }, [discountRate, promotionTypes])

  // CHECK HANDLERS
  const currencyCheckHandler = id => {
    setCurrencies(
      currencies.map(i => {
        if (id === i.id) {
          return { ...i, isChecked: !i.isChecked }
        }
        return i
      })
    )
  }

  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const ticketCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = tickets.map(i => ({
        ...i,
        isChecked: !tickets[0].isChecked,
      }))
    } else {
      updatedList = tickets.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedList[0].isChecked = true
    } else {
      updatedList[0].isChecked = false
    }

    setTickets(updatedList)
  }

  const reset = () => {
    const nonCheckedTickets = tickets.map(i => ({
      ...i,
      isChecked: false,
    }))

    setTickets(nonCheckedTickets)

    const updatedMarketingPlaces = marketingPlaces.map(i => ({
      ...i,
      isChecked: false,
    }))

    const resetTypes = promotionTypes.map(i => ({
      ...i,
      label: getLabelByLanguage(i, locale),
      value: i.id,
      isChecked: false,
    }))
    if (resetTypes.length > 0) {
      resetTypes[0].isChecked = true
    }
    setPromotionTypes(resetTypes)

    setMarketingPlaces(updatedMarketingPlaces)
    setOnlyMembers(false)
    setPromotionName("")
    setDiscountRate(10)
    setCurrencies([
      { id: 2, name: "EUR", isChecked: true },
      { id: 3, name: "TRY", isChecked: false },
    ])
    setResStartDate(getTodayDate())
    setResEndDate(addDays(getTodayDate(), 7))
    setBookingStartDate(getTodayDate())
    setBookingEndDate(addDays(getTodayDate(), 7))
  }

  const submitHandler = () => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/ticket-promotions",
          method: "post",
          data: payload,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")

    const ticket_ids = tickets
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (ticket_ids.length === 0) {
      return setError("Please select a ticket!")
    }

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    const currency_ids = currencies.filter(i => i.isChecked).map(i => i.id)
    if (currency_ids.length < 1) {
      return setError(t("Please select a currency!"))
    }
    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    if (discountRate < 0 || discountRate >= 100) {
      return setError("Please enter a valid discount rate!")
    }

    if (promotionName.trim().length === 0) {
      return setError("Please enter a valid promotion name!")
    }

    const newData = {
      ticket_ids,
      promotionType_id: promotionTypes.find(i => i.isChecked).value,
      onlyMembers,
      resStartDate,
      resEndDate,
      currency_ids,
      bookingStartDate,
      bookingEndDate,
      discountRate,
      promotionName,
      setMarketingPlace_ids,
    }
    submit(newData)
    reset()
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Ticket")}
          breadcrumbItem={t("Ticket Promotions")}
        />
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <p className="font-size-15 mt-n3">
              {t(
                "Customize this promotion to suit your needs. Select your own dates, discount amount, audience, and more."
              )}
            </p>

            <Members
              onlyMembers={onlyMembers}
              setOnlyMembers={setOnlyMembers}
            />

            {/* PROMOTION TYPES */}
            <RadioButtons
              title={t("What is the type of promotion?")}
              name="promotionTypes"
              dataList={promotionTypes}
              dataCheckHandler={(e, v) =>
                setPromotionTypes(list =>
                  list.map(i => ({
                    ...i,
                    isChecked: +v === i.value ? true : false,
                  }))
                )
              }
            />

            <Checkboxes
              dataList={tickets}
              checkHandler={ticketCheckHandler}
              label="Which Tickets?"
            />

            {marketingPlaces.length > 2 && (
              <Checkboxes
                dataList={marketingPlaces}
                checkHandler={marketingPlaceCheckHandler}
                label="Marketing Places"
              />
            )}

            {country_id === 107 && (
              <Currencies
                currencies={currencies}
                currencyCheckHandler={currencyCheckHandler}
              />
            )}

            <DiscountRate
              discountRate={discountRate}
              setDiscountRate={setDiscountRate}
            />

            {/* BOOKING DATES */}
            <Dates
              title="Tour Dates"
              subtitle="When can guests use this promotion?"
              startDate={bookingStartDate}
              endDate={bookingEndDate}
              setStartDate={setBookingStartDate}
              setEndDate={setBookingEndDate}
            />

            {/* RESERVATION DATES */}
            <Dates
              title="Bookable Period"
              subtitle="When can customers book this promotion?"
              startDate={resStartDate}
              endDate={resEndDate}
              setStartDate={setResStartDate}
              setEndDate={setResEndDate}
            />

            <PromotionName
              promotionsName={promotionName}
              setPromotionsName={setPromotionName}
            />

            {error && <Alert color="danger"> {t(error)}</Alert>}
            {showSuccessAlert && (
              <SuccessAlert
                hideSuccessAlert={() => {
                  setShowSuccessAlert(false)
                }}
              />
            )}

            <div className="row justify-content-end">
              <Button
                className="align-self-end"
                size="md"
                color="primary"
                onClick={submitHandler}
              >
                {submitLoading ? (
                  <>
                    <Spinner size="sm" /> {t("Loading...")}{" "}
                  </>
                ) : (
                  t("Save")
                )}
              </Button>
            </div>
          </>
        )}
      </Container>
    </main>
  )
}
export default TicketPromotion
