import { useEffect, useState } from "react"
import { AiOutlineClockCircle } from "react-icons/ai"
import { HiPlus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row, Col, Label, Input, Alert } from "reactstrap"

import TimeItem from "./TimeItem"
import styles from "../styles.module.scss"
import useRequest from "hooks/useRequest"
import { formatToHHMM } from "utils/dateTimeFunctions"

export default ({ selectedTimes, setSelectedTimes, isAddOperation, ticket }) => {
  const [selectedTime, setSelectedTime] = useState("10:00")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  const fetchTicketTimes = async () => {
    const ticket_id = ticket.id
    const response = await request({
      url: "/ticket-times/fetch-by-ticket-id",
      params: { ticket_id },
    })

    if (response?.result) {
      const updatedSelectedTimes = []
      response?.dbResult?.forEach(i => {
        const formattedTime = formatToHHMM(i?.time)
        updatedSelectedTimes.push(formattedTime)
      })

      setSelectedTimes(updatedSelectedTimes)

    } else {
      setErrorMessage("Something went wrong!")
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!isAddOperation) {
        await fetchTicketTimes()
      }
    })()
  }, [])
  
  const deleteHandler = id => {
    // delete item from selectedTimes state
    const updatedSelectedTimes = [...selectedTimes]
    const timeIndex = updatedSelectedTimes.findIndex(i => i.value === id)
    updatedSelectedTimes.splice(timeIndex, 1)
    setSelectedTimes(updatedSelectedTimes)
  }

  const addTime = () => {
    // add selected time to state
    const updatedSelectedTimes = [...selectedTimes]
    if(!updatedSelectedTimes.find(i => i === selectedTime)){
      updatedSelectedTimes.push(selectedTime)
      setSelectedTimes(updatedSelectedTimes)
      setSelectedTime("10:00")
    }
  }

  return (
    <>
      <Row form>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <Col sm={6}>
          <Label>
            * {t("Time")}{" "}
            <span className={styles.info}>
              (
              {t(
                "Do not forget to press the Add button after selecting the time."
              )}
              )
            </span>
          </Label>
          <Input
            name="time"
            type="time"
            value={selectedTime}
            onChange={e => setSelectedTime(e.target.value)}
          />
        </Col>
        <Col>
          <div className={styles.timeContainer}>
            <span className={styles.icons} onClick={addTime} title={t("Add")}>
              <HiPlus size={22} />
              <AiOutlineClockCircle size={35} className={styles.lastIcon} />
            </span>
          </div>
        </Col>
      </Row>
      {selectedTimes.map(item => (
        <TimeItem
          key={item.value}
          selectedTime={item}
          onDelete={deleteHandler}
        />
      ))}
    </>
  )
}
