import React from "react"
import { Card, CardBody, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import styles from "./styles.module.scss"

const BookablePeriodDate = props => {
  const { setRes_startDate, setRes_endDate, res_startDate, res_endDate } = props
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Bookable Period")}</h4>
        <h5 className={styles.cardSubtitle}>
          {t("When can customers book this promotion?")}
        </h5>
        <div className={styles.datesContainer}>
          <div className={styles.dateInputContainer}>
            <Label>{t("From")}</Label>
            <CustomDayPicker
              onChange={date => {
                setRes_startDate(date)
                if (date.getTime() > res_endDate.getTime()) {
                  setRes_endDate(date)
                }
              }}
              value={res_startDate}
              disabledDays={[]}
            />
          </div>
          <div className={styles.dateInputContainer}>
            <Label>{t("To")}</Label>
            <CustomDayPicker
              onChange={setRes_endDate}
              value={res_endDate}
              disabledDays={[{ before: res_startDate }]}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default BookablePeriodDate
