import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { useReservationContext } from "../context"
import { formatMoney, getTranslatedOption, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

export default function CustomTable() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  moment.locale(locale)
  const {
    values: { resDetails, promotionNames },
  } = useReservationContext()
  const { reservation } = resDetails

  const currencySign = getCurrencySignById(reservation?.agentCurrency_id)

  return (
    <TableContainer component={Paper} id="dailyPrice">
      <Table>
        <TableHead>
          <TableRow className={styles.headRow}>
            <TableCell>{t("Price")}</TableCell>
            <TableCell>{t("Discount Description")}</TableCell>
            <TableCell>{t("Discount Rate")}</TableCell>
            <TableCell>{t("Discounted Price")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
              <TableRow className={styles.bodyRow}>
                <TableCell>
                  {currencySign}
                  {formatMoney(reservation?.agentPrice, 2)}
                </TableCell>

                <TableCell>
                  {/* {pNames.map(i => (
                    <p key={i.id} className="mb-0">
                      {getTranslatedOption(i, "name", locale)}
                    </p>
                  ))} */}
                </TableCell>
                <TableCell>{reservation?.discountRate || 0}%</TableCell>
                <TableCell>{currencySign}{reservation?.discountedPrice || formatMoney(reservation?.agentPrice, 2)}</TableCell>
              </TableRow>
          <TableRow
            className={styles.lightColor}
            sx={{ "& > td": { paddingTop: "15px !important" } }}
          >
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Subtotal")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentPrice, 2)}
            </TableCell>
          </TableRow>
          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Taxes")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentTaxPrice, 2)}
            </TableCell>
          </TableRow>
          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Total")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentTotalPrice, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
