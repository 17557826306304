import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Alert,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomTable from "./Table/index"
import styles from "./styles.module.scss"

import { getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"

const itemsCountPerPage = 10
const YourPromotions = () => {
  const [isActive, setIsActive] = useState(true)
  const [promotions, setPromotions] = useState([])
  const [selectedRowId, setSelectedRowId] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(10)

  const [showWarnMsg, setShowWarnMsg] = useState(false)
  const [showWarnDeactivateMsg, setShowWarnDeactivateMsg] = useState(false)
  const [showSuccessDeactivateMsg, setShowSuccessDeactivateMsg] =
    useState(false)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      await Promise.allSettled([fetchCompanyPromotions()])
    })()
  }, [])

  useEffect(() => {
    fetchCompanyPromotions()
  }, [isActive, activePage])

  useEffect(() => {
    // TODO:
  }, [locale])

  const fetchCompanyPromotions = async () => {
    const response = await request({
      url: "/transfer-promotions/fetch-by-page-number",
      params: {
        isActive,
        page: activePage,
        limit: itemsCountPerPage,
      },
    })

    setPromotions(response?.dbResult[0])
    setTotalItemsCount(response?.dbResult[1][0]?.totalItemsCount)
  }

  const navigateToPromotionPage = () => {
    history.push("/transfer-promotions")
  }

  const deactivate = async () => {
    setShowWarnDeactivateMsg(false)
    setErrorMessage(false)
    const response = await request({
      url: "/transfer-promotions/deactivate",
      method: "put",
      data: { id: selectedRowId },
    })

    if (response?.result) {
      setShowSuccessDeactivateMsg(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid className={styles.container}>
        <Row className={styles.firstRow}>
          <h4>{t("Your Promotions")}</h4>
          <Button color="primary" onClick={navigateToPromotionPage}>
            {t("Create New Promotion")}
          </Button>
        </Row>
        <Row className={styles.secondRow}>
          <p className={styles.subtitle}>
            {t("Review, manage and add new promotions.")}
          </p>
        </Row>
        <Row className={styles.fourthRow}>
          <Col>
            <Row className={styles.buttons}>
              <ButtonGroup>
                <Button
                  color={isActive ? "primary" : "secondary"}
                  onClick={() => {
                    setIsActive(true)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Actives")}
                </Button>
                <Button
                  color={isActive ? "secondary" : "primary"}
                  onClick={() => {
                    setIsActive(false)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Passives")}
                </Button>
              </ButtonGroup>
              {isActive && (
                <Button
                  color="danger"
                  onClick={() => {
                    if (!selectedRowId) {
                      setShowWarnMsg(true)
                    } else {
                      setShowWarnDeactivateMsg(true)
                    }
                  }}
                >
                  {t("Deactivate")}
                </Button>
              )}
            </Row>
          </Col>
        </Row>

        {errorMessage && (
          <Alert color="danger" className="mt-2">
            {t(errorMessage)}
          </Alert>
        )}
        <Row className={styles.lastRow}>
          <Card className={styles.card}>
            <CardBody>
              {loading ? (
                <CustomSpinner />
              ) : (
                <CustomTable
                  promotions={promotions}
                  onSelectTableRow={setSelectedRowId}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemsCountPerPage={itemsCountPerPage}
                  onPageChange={setActivePage}
                  hasPagination={true}
                />
              )}
            </CardBody>
          </Card>
        </Row>
      </Container>
      {showWarnMsg && (
        <SweetAlert
          warning
          title={t("Please select a row!")}
          onConfirm={() => {
            setShowWarnMsg(false)
          }}
          confirmBtnText={t("OK")}
        />
      )}

      <SweetAlert
        show={showWarnDeactivateMsg}
        warning
        showCancel
        confirmBtnText={t("Yes, deactivate it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="danger"
        title={t("Are you sure?")}
        onConfirm={deactivate}
        onCancel={() => {
          setShowWarnDeactivateMsg(false)
        }}
        focusCancelBtn
      >
        {t("You will not be able to recover it!")}
      </SweetAlert>

      <SweetAlert
        show={showSuccessDeactivateMsg}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Deactivated!")}
        onConfirm={() => {
          fetchCompanyPromotions()
          setShowSuccessDeactivateMsg(false)
        }}
      >
        {t("The promotion is deactivated successfully!")}
      </SweetAlert>
    </main>
  )
}

export default YourPromotions
