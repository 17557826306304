import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal, Button, Alert } from "reactstrap"

import CustomSpinner from "components/Common/CustomSpinner/index"
import SuccessAlert from "components/Common/SuccessAlert/index"
import NavButtons from "./NavButtons/index.js"
import NavContents from "./NavContents/index"
import styles from "./styles.module.scss"

import { getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"

const HotelFeatures = props => {
  const [activeLink, setActiveLink] = useState(0)
  const [features, setFeatures] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const isPrevBtnDisabled = activeLink === 0
  const isNextBtnDisabled = activeLink === features.length - 1

  const fetchSetRoomFeatureTypes = async () => {
    const response = await request(
      { url: "/hotel-set-room-feature-types" },
      false
    )
    return response.dbResult
  }

  const fetchSetRoomFeatures = async () => {
    const response = await request({ url: "/hotel-set-room-features" }, false)
    return response.dbResult
  }

  const fetchRoomFeatures = async () => {
    const response = await request(
      { url: "/hotel-room-features", params: { room_id: props.room.id } },
      false
    )
    return response.dbResult
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await Promise.allSettled([
        fetchSetRoomFeatureTypes(),
        fetchSetRoomFeatures(),
        fetchRoomFeatures(),
      ])
      setLoading(false)
      prepareData({
        featureTypes: response[0].value,
        features: response[1].value,
        roomFeatures: response[2].value,
      })
    })()
  }, [])

  const prepareData = data => {
    const { featureTypes, features, roomFeatures } = data
    const initialFeatures = []

    featureTypes.forEach(type => {
      const initialFeature = {
        id: type.id,
        featureTitle: getLabelByLanguage(type, locale),
        featureItems: [],
      }
      for (let i = 0; i < features.length; i++) {
        const feature = features[i]
        if (type.id === feature.setRoomFeatureType_id) {
          let isChecked = false
          for (let i = 0; i < roomFeatures.length; i++) {
            const roomFeature = roomFeatures[i]
            if (roomFeature.setRoomFeature_id === feature.id) {
              isChecked = true
              break
            }
          }
          initialFeature.featureItems.push({
            id: feature.id,
            itemTitle: getLabelByLanguage(feature, locale),
            isChecked,
          })
        }
      }
      initialFeatures.push(initialFeature)
    })

    setFeatures(initialFeatures)
  }

  const linkClickHandler = idx => {
    setActiveLink(idx)
  }

  const checkBoxClickHandler = itemId => {
    const updatedFeatures = [...features]
    const featureItemIndex = updatedFeatures[activeLink].featureItems.findIndex(
      item => item.id === itemId
    )
    const featureItem =
      updatedFeatures[activeLink].featureItems[featureItemIndex]

    updatedFeatures[activeLink].featureItems[featureItemIndex] = {
      ...featureItem,
      isChecked: !featureItem.isChecked,
    }

    setFeatures(updatedFeatures)
  }

  const submitHandler = () => {
    const data = []
    const room_id = props.room.id

    features.forEach(type => {
      type?.featureItems?.forEach(f => {
        if (f.isChecked) {
          data.push({
            setRoomFeature_id: f.id,
            room_id,
          })
        }
      })
    })

    submitData(data)
  }

  const submitData = async data => {
    const response = await request({
      url: "/hotel-room-features",
      method: "put",
      data: { dataList: data, room_id: props.room.id },
    })

    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      scrollable={true}
      size="xl"
      toggle={props.close}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t("Add/Remove Feature")}</h5>
        <button
          type="button"
          onClick={props.close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {loading && <CustomSpinner color="primary" />}
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <div className={loading ? "invisible" : ""}>
          <NavButtons
            features={features}
            activeLink={activeLink}
            onLinkClick={linkClickHandler}
          />
          <NavContents
            features={features}
            activeLink={activeLink}
            onCheckboxClicked={checkBoxClickHandler}
          />
          <div className={styles.navButtons}>
            <Button
              disabled={isPrevBtnDisabled}
              className={isPrevBtnDisabled ? styles.disabled : ""}
              onClick={() => setActiveLink(activeLink => activeLink - 1)}
            >
              {t("Previous")}
            </Button>

            <Button
              disabled={isNextBtnDisabled}
              className={isNextBtnDisabled ? styles.disabled : ""}
              onClick={() => setActiveLink(activeLink => activeLink + 1)}
            >
              {t("Next")}
            </Button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className={styles.submitButton}>
          <Button color="primary" onClick={submitHandler}>
            {t("Submit")}
          </Button>
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            props.refreshTable()
            props.close()
          }}
        />
      )}
    </Modal>
  )
}

export default HotelFeatures
