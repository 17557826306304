import React, { Component } from "react"
import { Label } from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withTranslation } from "react-i18next"
import Select from "react-select"

import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import styles from "./styles.module.scss"
class Inputs extends Component {
  render() {
    const {
      properties,
      onPropertySelect,
      selectedProperty,
      years,
      onYearSelect,
      selectedYear,
      t,
    } = this.props

    return (
      <div className="mb-3 row mx-0">
        <div className={styles.selectContainer}>
          <Label className="text-nowrap mr-2">{t("Property")}</Label>

          <div>
            <Select
              value={selectedProperty}
              onChange={onPropertySelect}
              options={properties}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
        </div>

        <div className={styles.selectContainer}>
          <Label className="text-nowrap mr-2">{t("Year")}</Label>
          <div>
            <Select
              value={selectedYear}
              onChange={onYearSelect}
              options={years}
              noOptionsMessage={() => t("No Options")}
              classNamePrefix="select2-selection"
              placeholder={t("Select...")}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
        </div>

        <ControlButtons className="col px-0" />
      </div>
    )
  }
}

export default withTranslation()(Inputs)
