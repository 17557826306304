import React, { useState, useEffect } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import useRequest from "hooks/useRequest"
import SuccessAlert from "components/Common/SuccessAlert/index"
import axios from "axios"
import Breadcrumbs from "components/Common/Breadcrumb"
import styles from "./styles.module.scss"
import { useSelector } from "react-redux"
import { formatDate } from "utils/dateTimeFunctions"

const CompanyContract = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isContractOpen, setIsContractOpen] = useState(true)
  const [signedContractDate, setSignedContractDate] = useState("")
  const [cancellationPolicyText, setCancellationPolicyText] = useState("")
  const [prepaymentPolicyText, setPrepaymentPolicyText] = useState("")
  const [defaultCommission, setDefaultCommission] = useState()
  const [fileRef, setFileRef] = useState("")
  const [taxPolicyText, setTaxPolicyText] = useState("")

  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  const { request } = useRequest(setLoading, setErrorMessage)
  const token = useSelector(state => state.Login.token)
  const company = useSelector(state => state.Company.company)

  const address = `${company.address1} ${company.address2} ${company.districtName_tr}/${company.provinceName_tr}`
  const companyName = company?.name + " - " + company?.title

  let Contract = null
  switch (locale) {
    case "en":
      Contract = Contract_tr
      break
    case "tr":
      Contract = Contract_tr
      break
    default:
      Contract = Contract_tr
      break
  }

  const cancellationPolicyPreparer = policy => {
    const {
      freeCancellable,
      freeCancelDayCountBeforeArrival,
      cancelChargePercent,
      unusedChargePercent,
    } = policy
    let summary30
    let summary31
    let summary32
    if (freeCancellable) {
      summary30 = `1.30. Misafir, Tesise (otel) varış (check-in) tarihinin ${freeCancelDayCountBeforeArrival} gün öncesine kadar ücretsiz olarak iptal edebilir.`
      summary31 = `1.31. Ücretsiz iptal tarihi geçen rezervasyonların toplam ücretin %${cancelChargePercent}'i tahsil edilecektir.`
    } else {
      summary30 = `1.30. Misafirlerin ücretsiz iptal edebilecekleri bir gün yoktur.`
      summary31 = `1.31. Rezervasyondan sonra iptal edilirse rezervasyonun toplam ücretin %${cancelChargePercent}'u otel tarafından Vihobook’tan tahsil edilecektir.`
    }

    if (!!unusedChargePercent) {
      summary32 = `1.32. Konuğun (Misafir) rezervasyon yaptırdığı halde otele gelmemesi (No-Show) durumunda toplam rezervasyon ücretinin %${unusedChargePercent}'si kadar tahsil edilecektir.`
    }

    return { summary30, summary31, summary32 }
  }

  const prepaymentPolicyPreparer = policy => {
    const { prepaymentPercent, returnDayCount } = policy

    const summary33 = `1.33. Toplam ücretin %${prepaymentPercent}'si ücretsiz iptal sona erdikten sonra ödenir, Kalanı %${
      100 - prepaymentPercent
    }'si misafirin otelden ayrılış (cout) tarihi sonunda Vihobook tarafından 5 iş gününde ödenecektir.`

    const summary34 = `1.34. Rezervasyonun iptal edilmesi (olması) durumunda ödemesi yapılmış olan rezervasyonların tesis tarafından maksimum ${returnDayCount} iş günü içerisinde Vihobook'a geri iade ödemesini gerçekleştirecektir.`

    const summary35 = `1.35. Tesisin Vihobook'tan alacağı olması durumunda tesis iade ödemesini gerçekleştirmek zorunda değildir. Vihobook alacak bakiyesinden düşülecektir.`

    const summary36 = `1.36. Tesis Vihobook'tan ödeme alabilmesi için rezervasyonların numarası (voucher), geliş ayrılış tarihleri, rezervasyon misafir adını gösteren konaklama faturalarını vihobook muhasebe departmanı ulaştıktan sonra belirtilmiş olan koşullar doğrultusunda ödemesini tahsil edebilecektir.`

    const summary37 = `1.37. Vihobook ile Tesis arasında imzalanan ek sözleşme var ise ana sözleşmenin bir parçasıdır ayrı kullanılamaz.`

    return { summary33, summary34, summary35, summary36, summary37 }
  }

  const taxPolicyPreparer = policy => {
    const {
      hasOrderTax,
      hasStateTax,
      hasVatTax,
      orderTaxCommission,
      stateTaxCommission,
      vatTaxCommission,
    } = policy
    let summary444
    let summary445
    let summary446
    if (!hasVatTax) {
      summary444 =
        "4.4.4. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza Katma Değer Vergisi dahildir."
    } else {
      summary444 = `4.4.4. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza %${vatTaxCommission} Katma Değer Vergisi ilave edilecektir.`
    }

    if (!hasStateTax) {
      summary445 =
        "4.4.5. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza Turizm Vergisi dahildir."
    } else {
      summary445 = `4.4.5. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza %${stateTaxCommission} Turizm Vergisi ilave edilecektir.`
    }

    if (!hasOrderTax) {
      summary446 =
        "4.4.6. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza diğer vergi ücretleri dahildir."
    } else {
      summary446 = `4.4.6. Vihobook platformuna girmiş olduğumuz fiyatlarımıza ya da ek sözleşmede belirtmiş olduğumuz fiyatlarımıza %${orderTaxCommission} Diğer vergi ücretleri ilave edilecektir.`
    }

    return { summary444, summary445, summary446 }
  }

  useEffect(() => {
    const main = async () => {
      if (!company.title || !company.address1) {
        return setErrorMessage("Company info is missing!")
      }
      await fetchContractInfo()
    }
    main()
  }, [])

  const fetchContractInfo = async () => {
    setLoading(true)
    const response = await request({
      url: "/company-contracts/fetch-by-company-id",
    })

    if (response?.result) {
      const cText = cancellationPolicyPreparer(
        response.dbResult?.policies?.cancellationPolicy
      )
      setCancellationPolicyText(cText)

      const pText = prepaymentPolicyPreparer(
        response.dbResult?.policies?.prepaymentPolicy
      )
      setPrepaymentPolicyText(pText)

      const tText = taxPolicyPreparer(response.dbResult?.policies?.taxPolicy)

      setTaxPolicyText(tText)

      setDefaultCommission(response.dbResult?.defaultCommission)
      if (response?.dbResult?.contractInfo) {
        setSignedContractDate(formatDate(response.dbResult?.contractInfo?.date))
        setFileRef(response.dbResult?.contractInfo?.fileRef)
        setIsContractOpen(true)
      } else {
        setIsContractOpen(false)
      }
    } else {
      setErrorMessage("Something went wrong!")
    }
    setLoading(false)
  }

  const submitContract = async () => {
    setSubmitLoading(true)

    const companyType = company.types[0]?.name?.toLowerCase()
    const payload = {
      companyType,
      companyName,
      address,
      cancellationPolicyText,
      prepaymentPolicyText,
      taxPolicyText,
      defaultCommission,
    }

    const response = await axios.request({
      url: "/company-contracts",
      method: "post",
      data: payload,

      headers: {
        Authorization: "Bearer " + token,
      },
    })

    if (response?.data?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage("Something went wrong!")
    }

    setSubmitLoading(false)
  }

  let Content = null
  if (loading) {
    Content = <Spinner size="lg" color="primary" className="spinner" />
  } else if (errorMessage) {
    Content = (
      <Alert color="danger"> {t(errorMessage.replace("Error: ", ""))}</Alert>
    )
  } else if (isContractOpen) {
    Content = (
      <div className="pt-3 d-flex flex-column align-items-center">
        <p>Sözleşmeyi {signedContractDate} tarihinde imzaladınız.</p>
        <a target="_blank" href={`${process.env.REACT_APP_API_URL}${fileRef}`}>
          Sözleşmeyi İndir
        </a>
      </div>
    )
  } else {
    Content = (
      <>
        <div className={styles.container}>
          <Contract
            cPolicy={cancellationPolicyText}
            pPolicy={prepaymentPolicyText}
            tPolicy={taxPolicyText}
            defaultCommission={defaultCommission}
          />
        </div>
        <hr />
        <div className="p-5 d-flex justify-content-center">
          <button className="btn btn-primary" onClick={submitContract}>
            {submitLoading
              ? t("Submitting...")
              : t("I've read the contract and I approve it.")}{" "}
            {submitLoading && <Spinner size="sm" />}
          </button>
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={t("Setup")} breadcrumbItem={t("Contract")} />
          {Content}
        </Container>
        {showSuccessAlert && (
          <SuccessAlert
            hideSuccessAlert={async () => {
              await fetchContractInfo()
              setShowSuccessAlert(false)
            }}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default CompanyContract

const Contract_tr = ({ cPolicy, pPolicy, tPolicy, defaultCommission }) => {
  const company = useSelector(state => state.Company.company)
  const address = `${company.address1} ${company.address2} ${company.districtName_tr}/${company.provinceName_tr}`
  const companyName = company?.name + " - " + company?.title
  const date = formatDate(new Date())

  return (
    <div className={styles.contract}>
      <h3 className="text-center">Otel Sözleşmesi</h3>
      <br />
      <p>
        İşbu Vihobook Hüküm ve Koşulları (“Hüküm ve Koşullar”), Vihobook Otel
        Sözleşmesi’ne (“Sözleşme”) dairdir .
      </p>
      <p>
        İşbu Hüküm ve Koşullar’da büyük harfle kullanılan ifadeler, Sözleşme’de
        kendilerine atfedilen anlamları haiz olacaktır. İşbu Hüküm ve Koşullar
        ile Sözleşme hükümleri arasında herhangi bir uyumsuzluk veya çelişki
        olması halinde, Sözleşme hükümleri üstün tutulacaktır. Vihobook’un işbu
        Hüküm ve Koşulları’da tek taraflı olarak değişiklik yapma hakkı
        saklıdır, ancak Vihobook böyle durumlarda ilgili değişikliklere dair
        Otel’e bildirimde bulunacak ve Hüküm ve Koşullar’ın değiştirilen halini
        Otel’in Sözleşme’nin giriş kısmında yer alan e-posta adresine
        iletecektir.
      </p>
      <p>
        Bir tarafta, <strong>{address}</strong> adresinde mukim{" "}
        <strong>{companyName}</strong> (Bundan sonra kısaca{" "}
        <strong>Tesis</strong> veya <strong>Otel</strong> olarak anılacaktır)
        ile diğer tarafta{" "}
        <strong>
          Kötekli Mahallesi Denizli Yolu Bulvarı No:4B Kat :2 D:37 Muğla
          Teknokent Menteşe/Muğla
        </strong>{" "}
        adresinde mukim{" "}
        <strong>VİHOBOOK SOFTWARE TOURISM TİC.İTH.İHR. VE SAN.LTD.ŞTİ,</strong>{" "}
        (Vergi Dairesi Marmaris Vergi No 6120697703 ) Turizm Bakanlığı TURSAB
        Belge Numarası : 15962 (Bundan sonra kısaca <strong>Vihobook</strong>{" "}
        olarak anılacaktır) arasında aşağıdaki kayıtlı şartlarda anlaşmışlar ve
        iş bu sözleşme hükümlerine uymayı karşılıklı olarak kabul ve taahhüt
        etmişlerdir.
      </p>
      <br />
      <h4>1. TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ</h4>
      <ul>
        <li>
          1.1. Vihobook, Platform üzerinden veya kanal yöneticisiyle doğrudan
          bağlanarak, kanal yöneticisinden gelen fiyat ve kontenjanları
          kullanarak Ürün’ü satabilecektir.
        </li>
        <br />
        <li>
          1.2. Vihobook Platform’un Otel tarafından kullanılmasını sağlamak
          üzere, gerekli teknik açıklamaları Otel’e yapmayı, Platform’un
          sisteminin Otel tarafından kullanılması için gerekli kullanıcı adı ve
          şifre detaylarını Otel’e sağlamayı ve Platform’un olağan akışı içinde
          düzenli olarak çalıştırılacağını beyan etmektedir.
        </li>
        <br />
        <li>
          1.3. Vihobook otellere sağlayacağı bu Platform’da, Platform üzerinden
          veya bir kanal yöneticisi aracılığıyla doğrudan bağlanarak, Ürün’ü
          doğrudan veya paket satışı şeklinde tüketicilere ve kurumsal
          müşterilere sunabileceği gibi, Ürün’ü üçüncü kişi sağlayıcılar
          vasıtasıyla da satabilecektir.
        </li>
        <br />
        <li>
          1.4. Vihobook Otel’in veya üçüncü kişilerin kusurlarından kaynaklı
          iletişim hatalarından veya Otel’in Platform’daki kayıtlarının hatalı
          olmasından sorumlu olmayacaktır.
        </li>
        <br />
        <li>
          1.5. Müşteriden Ürün’e ilişkin bedel Otel adına Vihobook tarafından
          tahsil edilecektir. Vihobook sonrasında ilgili tutarı Otel’e
          aktaracaktır. Müşteri Otel’e ayrıca bir bedel ödemeyecektir. Otel
          ayrıca, müşteri tarafından tamamen kendi takdirinde yapılabilecek
          ilave harcamalar dışında herhangi bir bedel talep etmeyecektir.
          Otel’in bu hükme aykırı olarak, müşteriden Ürün bedelini veya müşteri
          tarafından tamamen kendi takdirinde yapılabilecek ilave harcamalar
          dışında herhangi bir bedeli talep veya tahsil etmesi sonucunda
          herhangi bir müşteri şikayeti ile karşılaşılması halinde, ortaya
          çıkabilecek tüm tutarların ödenmesinden Otel tek başına sorumlu
          olacaktır. Vihobook’un herhangi bir şekilde herhangi bir bedel ödemek
          durumunda kalması halinde Otel, bu bedelleri, Vihobook’un ilk talebini
          müteakip Vihobook’a nakden ve defaten ödeyecektir.
        </li>
        <br />
        <li>
          1.6. Otel’in Platform’daki hesabına ait bir kullanıcı adı ve şifresi
          bulunacaktır. Otel’in şifreyi haiz personelinin işten ayrılması
          durumunda Otel bu şifreyi değiştirmekle yükümlüdür. Otel’in kullanıcı
          adı ve şifresinin güvenliğini ve gizliliğini sağlamaktan Otel tek
          başına sorumludur.
        </li>
        <br />
        <li>
          1.7. Otel, Platform’da yer alacak içeriği kendisi belirleyecek ve
          Platform’un sistemine yükleyecektir. Otel Platform’da yer alan
          konaklama bilgileri, fiyatlar, oda bilgileri, rezervasyon değiştirme
          koşulları,fırsatlar, indirim tanımlamaları gibi tüm bilgilerin,
          görsellerin ve açıklamaların gerçek ve güncel olduğundan, tüketicileri
          yanıltıcı bilgileri içermemesinden ve tüketici ve internet yayınlarına
          ilişkin mevzuat dahil olmak, ancak bunlarla sınırlı olmamak üzere, tüm
          içeriğin ilgili mevzuata uygun olmasından yalnız başına sorumlu
          olacaktır. Ancak, Otel, Vihobook’un, Otel’in bu hükmü ihlal ederek,
          ilgili mevzuata aykırı davranışları ve içeriği nedeniyle ödeme zorunda
          kalacağı idari para cezaları ve müşteri şikayetleri dahil olmak üzere
          tüm bedelleri, Vihobook’un ilk talebi üzerinde derhal, nakden ve
          defaten ödemeyi kabul, beyan ve taahhüt etmektedir. Otel’in
          Platform’daki bilgileri, görselleri veya açıklamaları değiştirmek
          istemesi halinde, öncelikle bununla ilgili olarak Vihobook’a
          bildirimde bulunacaktır. Ardından, Vihobook’un teknik olarak sistemi
          mümkün kılması ile bu değişiklikleri yapabilecektir.
        </li>
        <br />
        <li>
          1.8. Otel, Ürün’ün Platform üzerinden müşterilere sunulduğu tüm süre
          boyunca, ilgili tesislerin her yönüyle müşteri kabul etmeye hazır
          olduğunu, personel, ekipman, teknik donanım dahil, ancak bunlarla
          sınırlı olmamak üzere, hiçbir eksiği bulunmadığını ve müşterilere bu
          süre boyunca eksiksiz ve yeterli hizmet verileceğini kabul, beyan ve
          taahhüt eder.
        </li>
        <br />
        <li>
          1.9. İlgili tesiste Otel’den kaynaklanan eksikliklerin bulunması,
          klimaların çalışmaması, yeterli personel ve ekipmanın olmaması,
          elektrik, su ve benzeri kesintiler gibi müşterileri mağdur edecek sair
          durumların ortaya çıkması halinde, Otel bu durumu derhal gidermekle
          yükümlüdür. Bu ve benzeri sorunların, ortaya çıkmasından en geç 24
          saat içinde giderilmemesi durumunda Vihobook, tamamen kendi takdirinde
          olmak üzere, Otel’in Platform’da satışını durdurabileceği gibi
          Sözleşme’yi derhal tek taraflı olarak feshedebilir. Otel, işbu maddede
          yer verilen sorun ve eksiklikler nedeniyle bu eksikliklerin
          giderilmesi veya sair surette Vihobook’un müşteriler ile arasındaki
          sözleşmeler tahtındaki yükümlülüklerini gereği gibi yerine
          getirebilmek üzere müşterilere veya sair üçüncü kişilere yapmış olduğu
          tüm ödemeleri sözleşmesel ceza ödemesi kabilinden olmak üzere % 200
          fazlası ile Vihobook’a ödemekle yükümlüdür. Ayrıca Otel’in Sözleşme’ye
          aykırı davranması nedeniyle Otel, Vihobook’un ve müşterilerinin bu
          nedenle uğradıkları tüm zararları, kâr mahrumiyeti de dahil olmak
          üzere, Vihobook’un ilk talebi üzerinde derhal, nakden ve defaten
          ödemeyi kabul, beyan ve taahhüt etmektedir.
        </li>
        <br />
        <li>
          1.10. Otel ayrıca, sağlık, hijyen, yangın, güvenlik gibi konularda
          kanuni standartları sağladığından emin olacak, misafirlerin sağlık ve
          güvenliğine ilişkin tüm mevzuata uygun hareket edecektir.
        </li>
        <br />
        <li>
          1.11. Sözleşme kapsamında Otel tarafından Ürün’ün müşteriye herhangi
          bir nedenle kullandırılamaması ve/veya hazır olmaması ve Otel’in
          vermeyi taahhüt ettiği hizmetleri yerine getirememesinden kaynaklanan
          sebeplerle, Vihobook’un maruz kaldığı adli ve/veya idari yaptırım ve
          para cezaları, kar mahrumiyetini, üstlenmek zorunda kalacağı
          müşterilere ait konaklama masrafları, müşteri şikayetlerinden doğacak
          tazminatlar gibi müşteri memnuniyeti sağlamaya yönelik olarak
          yapılacak tüm harcamalar ve masraflar dahil ancak bunlarla sınırlı
          olmamak üzere, her türlü zararı Otel, Vihobook’un ilk talebi üzerinde
          derhal, nakden ve defaten ödemeyi kabul, beyan ve taahhüt etmektedir.
        </li>
        <br />
        <li>
          1.12. Müşterinin rezervasyonunu iptal etmesi veya tesise hiç gelmemesi
          veya çifte rezervasyon yapması durumunda Otel, bu durumu Vihobook’a
          bildirecektir. Vihobook, Otel’in iptal veya iade politikasına göre
          Otel ile karşılıklı mutabık kalarak, ilgili koşulu misafire
          iletecektir. Otel tarafından platforma girilen iptal koşulları ve
          rezervasyon kabul aşamasında Vihobook bildirmiş olduğu iptal koşulları
          geçerli olacaktır . Platforma Otel tarafından herhangi bir iptal
          koşulu girilmediyse Vihobook’un misafir sözleşmesinde yer alan iptal
          koşulları geçerli olacak.
        </li>
        <br />
        <li>
          1.13. Sözleşme’nin, Ote’lin Sözleşme’ye aykırılığı nedeniyle Vihobook
          tarafından feshedilmesi halinde Otel, Vihobook’un o güne kadar hak
          kazandığı Komisyon’u %50 fazlası ile sözleşmesel ceza ödemesi
          kabilinden olmak üzere derhal Vihobook’a ödemeyi kabul, beyan ve
          taahhüt eder. Otel’in işbu hükmü ihlal etmesi halinde, Vihobook
          Sözleşme’yi feshedebilecektir. Vihobook’un, ayrıca, uğranılan
          zararlarının tazmin edilmesi hakkı saklıdır.
        </li>
        <br />
        <li>
          1.14. Otel kendi bağımsız ticari kararları çerçevesinde kendi
          fiyatlarını belirleyecektir. Otel’in telefon, çağrı merkezi, internet
          sayfası dahil, ancak bunlarla sınırlı olmaksızın kendisine ait yahut
          Vihobook’un kanalları dışındaki sair satış kanallarında ve/veya
          Otel’in satış ve pazarlamasını yapan online satış sitelerinde (kamuya
          açık yahut ilgili sitenin üyelerine özgü kampanyalar veya sair surette
          üye girişi yapmak suretiyle üyelerin erişebileceği şekilde) sunduğu
          fiyatlar, Otel’in Vihobook’a sunmuş olduğu fiyatlardan daha avantajlı
          olmamalıdır. Bu kapsamda, Otel tarafından Vihobook’un herhangi bir
          satış kanalı dışında sunulan fiyatların yabancı para üzerinden olması
          durumunda, Vihobook’a uygulanan fiyatın karşılığından daha avantajlı
          olmaması gerekmektedir.
        </li>
        <br />
        <li>
          1.15. Vihobook, bu madde kapsamındaki satış ve fiyatlandırmaları
          düzenli olarak takip ederek yukarıdaki hüküm ile ters düşen
          fiyatlandırmaları tespit etmesi halinde bunları kanıtları ile beraber
          derhal Otel’e bildirir ve söz konusu avantajlı fiyatların Vihobook’a
          sunulan fiyatlara yansıtılmasını talep eder. Otel, söz konusu
          avantajlı fiyatları Vihobook’a sunulan fiyatlara yansıtacağını taahhüt
          eder.
        </li>
        <br />
        <li>
          1.16. Vihobook tarafından yukarıdaki hüküm ile ters düşen
          fiyatlandırmalar üzerinden müşteriye satışın gerçekleştirilmesi
          halinde, Vihobook oluşan ödeme farkının derhal müşteriye yansıtılmak
          üzere tarafına ödenmesini talep eder. Otel, söz konusu ödeme farkını
          derhal müşteriye yansıtılmak üzere Vihobook’a ödemeyi taahhüt eder.
        </li>
        <br />
        <li>
          1.17. Vihobook’un Otel tarafından onaylanan konaklamalarda, müşterinin
          tesise giriş yaptığı ve/veya tesiste konakladığı sırada, (Sözleşme’de
          belirtilenlerle sınırlı olmamak üzere, mücbir sebepler dışında), her
          ne sebeple olursa olsun, müşteriyi başka bir tesiste konaklamak üzere
          yönlendirmesi halinde, Otel tarafından sunulan ve Otel ile aynı veya
          daha iyi niteliklere ve yıldız sayısına sahip başka bir tesiste
          konaklamanın ancak müşteri tarafından yazılı olarak kabul edilmesi
          halinde yapılması mümkün olacaktır. Bu şartlara uymaksızın, Otel’in
          müşteriyi başka bir tesiste konaklamaya yönlendirmesinin müşteri
          tarafından Vihobook’a bildirilmesi veya müşteri tarafından bu sebeple
          Vihobook’dan herhangi bir talepte bulunulması halinde Otel, müşteri ve
          Vihobook’un uğrayacağı tüm masraf ve zararlardan sorumlu olacaktır.
          Böyle bir durumda Otel, Vihobook’un talebi üzerine derhal (Vihobook’un
          müşteriye ödemek durumunda kaldığı bedeller dahil olmak üzere)
          Vihobook’un her türlü zararını aynen Vihobook’a ödemeyi kabul, beyan
          ve taahhüt eder.
        </li>
        <br />
        <li>
          1.18. Otel kendisine yazılı ve/veya sözlü olarak kendisine iletilen
          müşteri şikayetleri ile ilgili konularda öncelikle şikayetin anında
          giderilmesine çaba sarf edecek, şikayet konusu olan sorunun tesiste
          çözülmesi sağlanacaktır. Eğer şikayet tesiste çözüme kavuşturulamazsa,
          Otel ilgili şikayete konu olan durumu Vihobook’un müşteri hizmetleri
          departmanına 24 saat içinde yazılı olarak bildirmekle yükümlüdür. Aksi
          halde müşterinin beyanı doğru kabul edilerek müşterinin mağduriyeti
          Vihobook tarafından giderilecektir. Ayrıca müşteri tarafından Otel
          hakkında usulüne uygun ve haklı olduğu Vihobook tarafından tespit
          edilen müşteri şikayetlerinde de müşterinin mağduriyeti Vihobook
          tarafından hemen giderilir. Her iki durumda da Vihobook tarafından
          yapılan tüm ödemeler ve masraflar Otel’e fatura edilerek cari
          hesaplardan düşülür. Müşteri şikayetleri halinde Vihobook Sözleşme’yi
          tek taraflı olarak feshedebilecektir. Sözleşme’nin feshi halinde
          doğacak Vihobook’un tüm hak ve alacaklarını talep ve tahsil etme hakkı
          kendiliğinden ve herhangi bir ihbar ve ihtara gerek kalmaksızın
          muaccel olur.
        </li>
        <br />
        <li>
          1.19. Mahkeme nezdinde dava konusu olmuş ya da hakem heyetine taşınmış
          şikayetlerde gerekli savunmalar taraflarca birlikte yapılacak ve
          tüketici mahkemesi ve/veya tüketici hakem heyetlerinin veya sair idari
          mercilerin hükmedeceği tüm bedeller Otel tarafından müşteriye
          ödenecektir. Otel, tüketici hakem heyetinin, mahkemelerin, sair
          mercilerin hükmedeceği tüm bedeller, dava icra giderleri, vekalet
          ücretleri gibi tüm masrafları ilk talep halinde ödemeyi peşinen kabul
          ve taahhüt eder.
        </li>
        <br />
        <li>
          1.20. Otel, konseptini ve konaklama kurallarını kendisi
          belirlemektedir. Bu kapsamda Otel, oluşturacağı kural ve konseptin,
          ilgili mevzuata ve bakanlıklar ve ilgili sair resmi birimlerin
          talimatlarına uygun olduğunu; bunlara aykırı hususlar içermediğini
          kabul ve taahhüt eder. Herhangi bir sebeple, yanında kadın olmadan
          erkek rezervasyonlarını kabul etmemek ayrımcılık yasağı kapsamına
          girdiğinden, böyle bir durumun varlığı halinde tüm cezai ve hukuki
          sorumluluk Otel’e aittir ve Vihobook’un bu konuda hiç bir sorumluluğu
          bulunmamaktadır. Vihobook’un, Otel’in kural, konsept ve uygulamaları
          neticesinde uğrayacağı her türlü zararı, idari cezaları, üçüncü
          şahısların uğradığı zararları Otel karşılamakla yükümlüdür. Otel’in,
          konseptinde ve tesis adında değişiklik yapması halinde, bu değişiklik
          ancak Vihobook’a yapılacak bildirimden sonra ve yeni rezervasyonlar
          için geçerli olabilir. Aksi durumda Vihobook’un müşterilerinin
          uğradığı her türlü zararı, idari cezaları, Vihobook’un bu nedenle
          müşterilerine ödediği tutarları ve Vihobook’un uğradığı her türlü
          zararı Otel tazmin etmekle yükümlüdür.
        </li>
        <br />
        <li>
          1.21. Otel’in herhangi bir sebeple üçüncü şahıslara ve/veya
          kuruluşlara satılması, kiralanması halinde, ayrıca geçici veya sürekli
          olarak devredilmesi durumlarında, Sözleşme hükümleri, yeni Taraflar
          arasında aynı koşullarda devam eder; böyle bir durumda Otel,
          Sözleşme’nin aynı koşullar ile devam etmesi için gerekli her türlü
          önlemi almakla yükümlüdür. Aksi halde hiçbir ihtar, ihbar, mahkeme
          kararına gerek kalmaksızın Otel Vihobook’un bu nedenle uğrayacağı tüm
          zararları Otel nakden ve defaten karşılayacaktır.
        </li>
        <br />
        <li>
          1.22. Otel mevzuat gereği müşteri kabulü için gerekli çalışma ruhsatı,
          işletme belgesi gibi belgelere sahip olduğunu kabul ve beyan eder.
          Otel, bu belgelerdeki herhangi bir eksikten dolayı Vihobook’un,
          müşterilerin ve üçüncü kişilerin uğrayacağı tüm zararları ödemeyi
          kabul ve taahhüt eder. Vihobook’un, müşterilerine veya üçüncü
          şahıslara herhangi bir ödeme yapmak zorunda kalması halinde bu bedel
          hiçbir ihbar ve ihtara gerek kalmaksızın Otel tarafından Vihobook’a
          hemen ödenecektir. Ödemede gecikilen her ay için aylık % 25 gecikme
          cezası uygulanır.
        </li>
        <br />
        <li>
          1.23. Vihoook Otel’e Platform üstünden ve e-mail yolu ile rezervasyon
          iletecektir. Rezervasyon formunda ve platformda rezervasyon ait
          detaylı fiyat, tesise ödenecek tutar, oda tipi, pansiyon tipi,
          konaklama tarihleri, rezervasyon numarası, iptal koşulları otel’e
          bildirmek zorundadır. Otel rezervasyon onayını platform üstünden
          verdikten sonra geçerli olacaktır. 24 saat içinde platform üstünden
          onay verilmeyen rezervasyonlar otel tarafından kabul edildi
          sayılacaktır.
        </li>
        <br />
        <li>
          1.24. Vihobook Otel’lin Platform girmiş olduğu fiyatlar, indirimler ,
          promosyonlar, kampanyalar , kuponlar ,fırsatlar kullanarak
          müşterilerine satış gerçekleştirir . Gerçekleştirmiş olduğu
          satışlarında vihobook’un komisyon ücretlerini platformda ayrı ayrı
          gösterir ve rezervasyon formunda vihobook’un komisyon düşererek otel’e
          ödemesini gerçekleştirir.
        </li>
        <br />
        <li>
          1.25. Vihobook Otel’e ödeme koşullarında belirtilen koşularda otele
          ödemesini gerçekleştir. Koşullar doğrultusunda ödemesi yapılmayan
          rezervasyonların misafirlerini otel almama hakkı otele aittir.
        </li>
        <br />
        <li>
          1.26. İptal koşulları platform üstünden otelin belirlemiş olduğu
          koşullar doğrultusunda gerçekleştirilir. Bunların dışında uluslar
          arası mücbir sebepler oluşması durumunda savaş , sağlık, covid,
          uluslar arası sağlık sorunları gibi uluslar arası mücbir sebepler
          iptal koşullarına bağlı değildir. Mücbir sebeplerden iptal olan
          rezervasyonların ücretini vihobook’tan talep etmeyeceğini otel kabul
          eder. Otel’e ödemesi yaptığı rezervasyonların ücretini otel tarafından
          10 gün için iade ödemesi yapacağını otel kabul eder. Gününde
          yapılmayan iade ödemesi faiz ve masraflar gibi doğabilecek her türlü
          giderleri ödeyeceğini kabul eder.
        </li>
        <br />
        <li>
          1.27. Türkiye’de faaliyet gösteren Otel’ler Platforma Türkiye Pazarı
          müşterileri için Türk lirası fiyat ve indirimlerini veri girişlerini
          yapar. Vihobook satış kanallarındaTürkiyeden giriş yapan müşterilerini
          Türkiye pazarı fiyatları ile satışlarını gerçekleştirir. Türk lirası
          ile gerçekleşen rezervasyonların ücretini otele ödemesini Türk lirası
          olarak ödemesini yapar. Türkiye’de faaliyet gösteren Otel’ler Global
          pazarda fiyatlarını ve indirimlerini EURO para birimi kullanarak
          platforma giriş yapabilirler. Vihobook Euro para birimi ile
          gerçekleşen rezervasyonların ücretini otele ödemesini Euro para
          biriminden yapar.{" "}
        </li>
        <br />
        <li>
          1.28. Vihobook platformu üstünde otel ülke bazlı kısıtlamalar
          yapabilirler. Bu kısıtlamalara göre vihobook satış kanallarında otelin
          satışları gerçekleşir.
        </li>
        <br />
        <li>
          1.29. Vihobook platformu üstünden girilen bilgiler ve verileri
          vihobook satış kanalları online rezervasyon web sitesi , call center ,
          dijital marketing ve sosyal medya pazarlama tüm bilgilerini pazarlama
          faaliyetlerinde kullanabilecektir.
        </li>
        <br />
        <li>{cPolicy.summary30}</li>
        <br />
        <li>{cPolicy.summary31}</li>
        <br />
        <li>{cPolicy.summary32}</li>
        <br />
        <li>{pPolicy.summary33}</li>
        <br />
        <li>{pPolicy.summary34}</li>
        <br />
        <li>{pPolicy.summary35}</li>
        <br />
        <li>{pPolicy.summary36}</li>
        <br />
        <li>{pPolicy.summary37}</li>
        <br />
        <li>
          1.38. Platform üstündeki oteller satışları durdurmak ve açma
          (stop-sales) sorumluğu otele aittir. Otel platform üstünden kullanıcı
          adı şifresi ile giriş yaparak satışlarını kapatıp açabileceklerdir
          yada vihobook partner departmanını e-mail yolu ile odaların
          satışlarını kapattırıp açtırabilirler. Partner departmanından otel’in
          satışlarını kapatıldığı yada açıldığı teyid akmak sorumluğundadır.
        </li>
        <br />
        <li>
          1.39. Otel platforma stop sales girmeyi unuttuğu ya da diğer olaylardan
          kaynaklı otelin dolu olmasına rağmen gerçekleşen satışları 24 saat
          içinde rezervasyon iptal etmediğinden yada kabul edip daha sonra
          otelin dolu olduğu sorunu çıkmasından kaynaklı çıkabilecek tüm
          sorunları maddi ve manevi masrafları otel kabul eder.
        </li>
        <br />
        <li>
          1.40. Otel’in Short'a düşmesi sonucu vihobook’un misafirlerini mağdur
          etmesi dolayı kendinden daha yüksek kalite yada kendisine eşit bir
          segmente otel’de konaklatacağını yada çıkabilecek masrafları kabul
          edeceğini kabul eder.
        </li>
        <br />
        <li>
          1.41. Vihobook komisyon ücreti fiyatlardan indirim promosyon
          düşüldükten sonra %{defaultCommission} Vihobook komisyonu hesaplanır. Tesise Vihobook
          komisyonu düşüldükten sonra ödemesi gerçekleştirilir.{" "}
        </li>
      </ul>
      <br />
      <h4>2. REKLAM ÇALIŞMALARI</h4>
      <ul>
        <li>
          2.1. Vihobook, basılı materyal, kendi internet sitesi, gazete
          ilanları, dijital medya, sosyal medya, internet, eposta, elektronik
          bildirim, mobil uygulamalar dahil olmak üzere, ancak bunlarla sınırlı
          olmaksızın her türlü medya araçlarını kullanarak Otel’in Platform’da
          yer alan tüm bilgilerini pazarlama faaliyetlerinde kullanabilecektir.
        </li>
      </ul>
      <br />
      <h4>3. MÜŞTERİ DEĞERLENDİRMELERİ</h4>
      <ul>
        <li>
          3.1. Vihobook, konaklama yapan misafirlerden konaklamaları ile ilgili
          yorum ve konaklama deneyimlerinin belli bir yönüne dair puanlama
          yapmalarını talep edebilir.
        </li>
        <li>
          3.2. Vihobook’un misafirlerinin yorum ve puanlarını kendi internet
          sitesine veya Platform’a koyma hakkı saklıdır. Otel, Vihobook’un
          misafirler yorumlarının doğruluğunu ispatlama yükümlülüğü olmadığını
          ve bu yorumların içeriğinden sorumlu olmayacağını kabul eder.
        </li>
        <li>
          3.3. Vihobook misafir değerlendirmelerini, hakaret, ırkçılık veya bir
          bireyin adının anılması ihtimaline karşın izlemek ve değerlendirmek
          için azami çabayı göstermeyi taahhüt eder. Ancak Vihobook’un bu çabası
          hiçbir şekilde Vihobook’un misafir değerlendirmelerinin içeriğinden
          sorumlu olduğu anlamına gelmeyecektir.
        </li>
        <li>
          3.4. Vihobook, değerlendirmelerin küfür içermesi, bir bireyin adına
          atıfta bulunması ihtimaline karşın istenmeyen değerlendirmeleri
          reddetme, düzeltme veya yayından kaldırma haklarını muhafaza eder.
          Ancak Vihobook’un bu hakları hiçbir şekilde Vihobook’un misafir
          değerlendirmelerinin içeriğinden sorumlu olduğu anlamına
          gelmeyecektir.
        </li>
        <li>
          3.5. Vihobook misafir değerlendirmeleri veya bunların içeriği veya
          yayınlanmasının veya dağıtımının sonuçları hakkında Otel ile herhangi
          bir tartışma, müzakere veya muhaberatta bulunmayacaktır.
        </li>
        <li>
          3.6. Vihobook’un yorum ve değerlendirmelerinin içeriği, yayınlanması
          ve dağıtımının sonuçları ile ilgili olarak herhangi bir yüküm ve
          sorumluluğu bulunmamaktadır.
        </li>
      </ul>
      <br />
      <h4>4. DİĞER HÜKÜMLER</h4>
      <ul>
        <h5>4.1. Gizlilik</h5>
        <li>
          4.1.1. Taraflar bu Sözleşme’nin ifası sırasında elde edilmiş olan
          bilgileri, Sözleşme’den doğan hizmetlerin yerine getirilmesi için bu
          bilgilerin açıklanmasının gerekli olduğu haller ve resmi makam veya
          mercilere kanunlar uyarınca bilgi verilmesinin zorunlu olduğu haller
          dışında kesinlikle gizli tutmayı ve bu tür bilgileri üçüncü
          firmalara/şahıslara açıklamayacaklarını veya diğer Taraf’ın önceden
          yazılı izni olmadan kullanmayacaklarını taahhüt ederler.
        </li>
        <br />
        <h5>4.2. Kişisel Verilerin Korunması</h5>
        <li>
          4.2.1. Otel, Sözleşme tahtında Vihobook’un Otel ile paylaştığı tüm
          kişisel verileri (müşteri verileri dahil olmak üzere) Kişisel
          Verilerin Korunması Hakkında Kanun’u (“Kanun”) ve bu Kanun ile ilgili
          mevcut olan ve ileride yürürlüğe girecek her türlü mevzuatın
          hükümlerine uygun olarak işleyeceğini, saklayacağını ve muhafaza
          edeceğini kabul, beyan ve taahhüt eder.
        </li>
        <li>
          4.2.2. Müşteri verileri dahil olmak ve fakat bununla sınırlı olmamak
          üzere Vihobook tarafından Otel ile paylaşılan ve Kanun kapsamında
          “kişisel veri” olarak nitelendirilen bütün verilerin Kanun hükümlerine
          uygun işlenmesi, saklanması, ifşa edilmesi, aktarılması ve güvende
          tutulmasından münhasıran Otel sorumlu olacaktır.
        </li>
        <li>
          4.2.3. Otel, Vihobook’un Otel ile paylaştığı verileri sadece paylaşım
          amacı için ve bu amaç doğrultusunda kullanacak; bu verilerin kullanım
          amacı sona erdiğinde hem elektronik ortamdan hem de fiziksel ortamdan
          bütün verileri derhal silecek, imha edecek veya anonim hale
          getirecektir.
        </li>
        <li>
          4.2.4. Otel, Vihobook’un Otel ile paylaştığı kişisel verileri yetkisiz
          veya yasadışı işlememeyi, sehven kayıp, imha veya yok edilmesine karşı
          korunmasını sağlamayı ve verilerin saklanması için uygun güvenlik
          seviyesini oluşturmak amacıyla tüm gerekli teknik ve diğer önlemleri
          almayı kabul, beyan ve taahhüt eder.
        </li>
        <li>
          4.2.5. Otel, Vihobook’un Otel ile paylaştığı kişisel verilere erişimi
          sadece bu verilere kati şekilde erişime ihtiyacı olan ve kati
          sözleşmesel gizlilik yükümlülüğüne sahip çalışanlar, sözleşme
          tarafları, aracılar ve diğer ilgili taraflar ile kısıtlı tutmayı
          kabul, beyan ve taahhüt eder.
        </li>
        <li>
          4.2.6. Vihobook’un Otel ile paylaştığı kişisel verilerin Otel
          tarafından mevzuat hükümlerine ve işbu madde hükümlerine aykırı
          kullanılmasından meydana gelebilecek her türlü doğrudan veya dolaylı
          zarar ve ziyanını, mahrum kalacağı idari ve adli cezaları, üçüncü
          kişilerin (müşteriler dahil olmak üzere) taleplerini Vihobook’un ilk
          talebi üzerine derhal nakden ve defaten karşılamayı kabul, beyan ve
          taahhüt eder.
        </li>
        <br />
        <h5>4.3. Mücbir Sebep</h5>
        <li>
          4.3.1. Doğal afetler, yangın, hükümetin faaliyetleri, ulusal
          seferberlik halleri, ayaklanmalar, savaş ya da savaş girişimleri,
          grev, lokavt gibi burada yazılı olanlarla sınırlı olmamak kaydıyla,
          Sözleşme’nin imzalandığı tarihte var olmayan ve öngörülmeyen,
          Taraflar’ın kontrolleri dışında gelişen, ortaya çıkmasıyla
          Taraflar’dan birinin ya da her ikisinin de Sözleşme ile yüklendikleri
          borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya
          da bunları zamanında yerine getirmelerini imkânsızlaştıran haller,
          mücbir sebep olarak kabul edilecektir. Bu nedenlerden birisi meydana
          gelirse tarafların bu Sözleşme’den kaynaklanan yükümlülükleri askıya
          alınır. Bu sebep eğer Sözleşme’nin ifasını bir Taraf için imkansız
          kılıyorsa, Sözleşme tek taraflı olarak feshedilebilecektir. Bu durumda
          hizmet alınan dönem için ödenmesi gereken Komisyon derhal Vihobook’a
          nakden ve defaten ödenir. Taraflar’ın fesihten önce tahakkuk eden hak
          ve alacakları saklı kalacaktır.
        </li>
        <br />
        <h5>4.4. Masraflar ve Vergiler</h5>
        <li>
          4.4.1. Her bir Taraf Sözleşme’nin hazırlanması, imzası ve ikmali ile
          ilgili olarak, profesyonel danışmanlarının ücretleri de dâhil olmak
          üzere, kendi gider ve masraflarını kendisi üstlenecektir.
        </li>
        <li>
          4.4.2. Her bir Taraf Sözleşme tarafından öngörülen işlemlerin usulüne
          uygun olarak ikmali için kendisi tarafından ödenmesi gerekecek satış,
          devir veya diğer resim, harç, gider ve masraflara kendisi
          katlanacaktır.
        </li>
        <li>
          4.4.3. Otel satışlarda vergi politikasını platform üstünden
          belirlemekle sorumludur.
        </li>
        <li>{tPolicy.summary444}</li>
        <li>{tPolicy.summary445}</li>
        <li>{tPolicy.summary446}</li>
        <br />
        <h5>4.5. Ekler</h5>
        <li>
          4.5.1. Sözleşme’nin tüm Ekleri, Sözleşme’nin ayrılmaz parçasını
          oluşturur. Herhangi bir Ek ile Sözleşme’nin hükümleri arasında bir
          uyuşmazlık olması halinde, Sözleşme’nin hükümleri üstün tutulacaktır.
        </li>
        <br />
        <h5>4.6. Sözleşme’nin Bütünlüğü</h5>
        <li>
          4.6.1. Sözleşme ve Ekleri, Taraflar arasında konularına ilişkin
          varılan anlaşmanın tamamını teşkil edecektir ve bu konuda Taraflarca
          yapılan önceki tüm sözlü ve yazılı irade beyanlarını geçersiz kılacak
          ve onların yerini alacaktır.
        </li>
        <br />
        <h5>4.7. Değişiklikler</h5>
        <li>
          4.7.1. Sözleşme’de (işbu hükümde yapılacak olanlar da dâhil olmak
          üzere) yapılacak her türlü değişiklik yazılı olması şartıyla geçerli
          olur, meğerki kanun tarafından başka bir şekil gerekli görülmüş olsun.
        </li>
        <li>
          4.7.2. Otel, ticari unvan ve diğer ticari bilgileri ile banka hesap
          bilgilerini Vihobook’a vermek ve güncel tutmakla yükümlüdür. Bu
          bilgilerin Otel tarafından Vihobook’a herhangi bir bildirimde
          bulunulmaksızın değişmesi durumunda ortaya çıkabilecek yanlış
          işlemlerden Vihobook sorumlu olmayacaktır.
        </li>
        <br />
        <h5>4.8. Halefler ve Temlik</h5>
        <li>
          4.8.1. Sözleşme Taraflar’ın halefleri üzerinde bağlayıcı olacak ve
          onların lehine de hüküm ifade edecektir. Hiçbir Taraf, diğer Taraf’ın
          önceden yazılı onayını almaksızın Sözleşme tahtındaki hiçbir hak veya
          talebi temlik etme hakkını haiz değildir. Şu kadar ki Otel,
          Vihobook’un Sözleşme’yi, tamamen veya kısmen, bağlı ortaklıklarından
          herhangi birine temlik etme hakkını haiz olduğunu kabul ve ikrar eder.
        </li>
        <li>
          4.8.2. Otel’in hissedarlık yapısında veya sahibi olduğu tesisin üçünü
          bir kişiye devredilmesi veya tesisin unvanının değiştirilmesi halinde
          Sözleşme kendiliğinden sona erecek ve Otel’in Platform’da yer almak
          için yeniden sözleşme yapma yükümlülüğü doğacaktır.
        </li>
        <br />
        <h5>4.9. Geçersizlik ve Bölünebilirlik</h5>
        <li>
          4.9.1. Sözleşme’nin uygulanacak hukuk tahtında hukuka aykırı, yok
          hükmünde, kesin hükümsüz veya icra edilemez olduğuna hükmedilirse,
          Sözleşme’nin geri kalanının uygulanacak hukuk uyarınca hukuka
          uygunluğu, geçerliliği ve icra edilebilirliği etkilenmeyecektir.
          Sözleşme’deki herhangi bir hüküm veya madde kısmen veya tamamen
          hükümsüz veya icra edilemez addolunursa; taraflar, ilgili hüküm veya
          maddelerin geçerli ve icra edilebilir ve Sözleşme’nin amacı ve
          hedefleri bakımından temel olarak aynı etkiye sahip olan
          düzenlemelerle değiştirilmesi hususunda iyi niyetli olarak müzakere
          etme ve anlaşmaya varmayı burada kabul etmişlerdir. Her halükarda,
          Sözleşmedeki münferit hükümlerin ve maddelerin iptal edilmesi veya
          geçersizliği, Sözleşme’nin bir bütün olarak iptal edilmesi veya
          geçersiz hale gelmesini gerektirmeyecektir.
        </li>
        <br />
        <h5>4.10. Bildirimler</h5>
        <li>
          4.10.1. Sözleşme tahtında öngörülen tüm ihbarlar, muvafakatler,
          talepler, talimatlar, onaylar ve (dava tebligatları hariç olmak üzere)
          sair iletişim, yazılı olarak yapılmak ve elden teslim yoluyla veya
          (teyit ya da iletim onayı ile ispatlanacak) faks yoluyla veya (iadeli
          taahhütlü olmak üzere) acele postayla, Taraflar’ın Sözleşme’nin giriş
          kısmında yer verdikleri adreslerine veya herhangi bir Taraf’ın zaman
          zaman bu maddede öngörülen usul ve şekilde yazılı bildirimde bulunarak
          belirleyebileceği başka bir adrese teslim edilmek kaydıyla geçerli
          sayılacaktır.
        </li>
        <li>
          4.10.2. İhbarlar ve bu gibi başka bildirimler fiilen alındıklarında
          teslim edilmiş sayılırlar; şu kadar ki, Türk Ticaret Kanunu’nun
          18/III. maddesinde tanımlanan (esasen temerrüt veya fesih ihbarlarına
          ilişkin olan) ihbarlar ve bildirimler, noter veya iadeli taahhütlü
          posta vasıtasıyla gönderilmesi kaydıyla geçerli olacak ve Türk
          Kanunları uyarınca, usulüne uygun olarak tebliğ edildikleri tarih
          itibariyle yapılmış sayılacaklardır.
        </li>
        <br />
        <h5>4.11. Uyuşmazlıkların Çözümü</h5>
        <li>
          4.11.1. Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir ve
          Sözleşme’nin yorumlanması, ifası, ihlâli, mevcudiyeti, geçerliliği
          veya sona ermesinden doğan veya Sözleşme ile ilgili tüm
          uyuşmazlıkların çözümünde münhasıran Muğla (Merkez) mahkemeleri ve
          icra daireleri yetkili olacaktır.
        </li>
      </ul>
      <p>
        İşbu sözleşme 2 orijinal nüsha olarak {date} tarihinde düzenlenmiş ve
        imzaya haiz kişilerce okunup imzalanmıştır.
      </p>

      <br />
      <br />
      <br />
      <div className="d-flex justify-content-around">
        <div>
          <p>
            <strong>Otel Yetkili</strong>
          </p>
          <p>
            <strong>Adı Soyadı</strong>
          </p>
          <p>
            <strong>İmza / Kaşe</strong>
          </p>
        </div>
        <div>
          <p>
            <strong>Vihobook Yetkili</strong>
          </p>
          <p>
            <strong>Adı Soyadı</strong>
          </p>
          <p>
            <strong>İmza / Kaşe</strong>
          </p>
        </div>
      </div>
    </div>
  )
}
