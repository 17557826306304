import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Container, Card, CardBody, Button, Alert } from "reactstrap"
import NavButtons from "./NavButtons/index.js"
import NavContents from "./NavContents/index"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning/index"
import BreadCrumb from "components/Common/Breadcrumb"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

import styles from "./styles.module.scss"

import { getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"

const HotelFeatures = () => {
  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [activeLink, setActiveLink] = useState(0)
  const [features, setFeatures] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [hotel_id, setHotel_id] = useState("")

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const isPrevBtnDisabled = activeLink === 0
  const isNextBtnDisabled = activeLink === features.length - 1

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      setHotel_id(response.dbResult.id)
      return response.dbResult.id
    }
  }
  const fetchSetFeatureTypes = async () => {
    const response = await request({ url: "/hotel-set-feature-types" }, false)
    return response.dbResult
  }

  const fetchSetFeatures = async () => {
    const response = await request({ url: "/hotel-set-features" }, false)
    return response.dbResult
  }

  const fetchHotelFeatures = async hotel_id => {
    const response = await request(
      { url: "/hotel-features", params: { hotel_id } },
      false
    )
    return response.dbResult
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        return
      }
      const response = await Promise.allSettled([
        fetchSetFeatureTypes(),
        fetchSetFeatures(),
        fetchHotelFeatures(hotel_id),
      ])
      setLoading(false)

      const prepareData = data => {
        const { featureTypes, features, hotelFeatures } = data

        const initialFeatures = []

        featureTypes?.forEach(type => {
          const initialFeature = {
            id: type.id,
            featureTitle: getLabelByLanguage(type, locale),
            featureItems: [],
          }
          for (let i = 0; i < features.length; i++) {
            const feature = features[i]
            if (type.id === feature.setFeatureType_id) {
              let isChecked = false
              for (let i = 0; i < hotelFeatures.length; i++) {
                const hotelFeature = hotelFeatures[i]
                if (hotelFeature.setFeature_id === feature.id) {
                  isChecked = true
                  break
                }
              }

              initialFeature.featureItems?.push({
                id: feature.id,
                itemTitle: getLabelByLanguage(feature, locale),
                isChecked,
              })
            }
          }
          initialFeatures.push(initialFeature)
        })
        setFeatures(initialFeatures)
      }
      prepareData({
        featureTypes: response[0].value,
        features: response[1].value,
        hotelFeatures: response[2].value,
      })
    })()
  }, [locale, features?.length])

  const linkClickHandler = idx => {
    setActiveLink(idx)
  }

  const checkBoxClickHandler = itemId => {
    const updatedFeatures = [...features]
    const featureItemIndex = updatedFeatures[activeLink].featureItems.findIndex(
      item => item.id === itemId
    )
    const featureItem =
      updatedFeatures[activeLink].featureItems[featureItemIndex]

    updatedFeatures[activeLink].featureItems[featureItemIndex] = {
      ...featureItem,
      isChecked: !featureItem.isChecked,
    }
    setFeatures(updatedFeatures)
  }

  const submitHandler = () => {
    const data = []

    features.forEach(type => {
      type?.featureItems?.forEach(f => {
        if (f.isChecked) {
          data.push({
            setFeature_id: f.id,
            hotel_id,
          })
        }
      })
    })

    submitData(data)
  }

  const submitData = async data => {
    const response = await request({
      url: "/hotel-features",
      method: "put",
      data: { dataList: data, hotel_id: hotel_id },
    })

    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Hotels")} breadcrumbItem={t("Hotel Features")} />
        <Card>
          <CardBody>
            {loading && <CustomSpinner color="primary" />}
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            {HotelNotRegisteredComp}
            <div className={loading ? "invisible" : ""}>
              <NavButtons
                features={features}
                activeLink={activeLink}
                onLinkClick={linkClickHandler}
              />
              <NavContents
                features={features}
                activeLink={activeLink}
                onCheckboxClicked={checkBoxClickHandler}
              />
              <div className={styles.navButtons}>
                <Button
                  disabled={isPrevBtnDisabled}
                  className={isPrevBtnDisabled ? styles.disabled : ""}
                  onClick={() => setActiveLink(activeLink => activeLink - 1)}
                >
                  {t("Previous")}
                </Button>

                <Button
                  disabled={isNextBtnDisabled}
                  className={isNextBtnDisabled ? styles.disabled : ""}
                  onClick={() => setActiveLink(activeLink => activeLink + 1)}
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className={styles.submitButton}>
          <Button color="primary" onClick={submitHandler}>
            {t("Submit")}
          </Button>
        </div>
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default HotelFeatures
