import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Alert,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import CustomSpinner from "components/Common/CustomSpinner/index"
import { getLabelByLanguage } from "utils/other"
import useRequest from "hooks/useRequest"
import Table from "./Table/index"
import styles from "./styles.module.scss"

const itemsCountPerPage = 10
const PropertyYourOpportunities = () => {
  const [status, setStatus] = useState(true)
  const [opportunities, setOpportunities] = useState([])
  const [opportunityTypes, setOpportunityTypes] = useState([])
  const [selectedRowId, setSelectedRowId] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(10)

  const [showWarnMsg, setShowWarnMsg] = useState(false)
  const [showWarnDeactivateMsg, setShowWarnDeactivateMsg] = useState(false)
  const [showSuccessDeactivateMsg, setShowSuccessDeactivateMsg] =
    useState(false)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchSetOpportunityTypes = async () => {
      const response = await request({
        url: "property-set-opportunity-type",
      })
      setOpportunityTypes(
        response?.dbResult?.map(i => ({
          ...i,
          name: getLabelByLanguage(i, locale),
        }))
      )
    }
    ;(async () => {
      await Promise.allSettled([
        fetchCompanyOpportunities(),
        fetchSetOpportunityTypes(),
      ])
    })()
  }, [])

  useEffect(() => {
    fetchCompanyOpportunities()
  }, [status, activePage])

  useEffect(() => {
    setOpportunityTypes(
      opportunityTypes?.map(i => ({
        ...i,
        name: getLabelByLanguage(i, locale),
      }))
    )
  }, [locale])

  const fetchCompanyOpportunities = async () => {
    const response = await request({
      url: "/property-opportunities/fetch-by-page-number",
      params: {
        status,
        page: activePage,
        limit: itemsCountPerPage,
      },
    })

    setOpportunities(response?.dbResult[0])
    setTotalItemsCount(response?.dbResult[1][0]?.totalItemsCount)
  }

  const deactivate = async () => {
    setShowWarnDeactivateMsg(false)
    setErrorMessage(false)
    const response = await request({
      url: "/property-opportunities/deactivate",
      method: "put",
      data: { id: selectedRowId },
    })

    if (response?.result) {
      setShowSuccessDeactivateMsg(true)
    }
  }
  return (
    <main className="page-content">
      <Container fluid className={styles.container}>
        <Row className={styles.firstRow}>
          <h4>{t("Your Opportunities")}</h4>
        </Row>
        <Row className={styles.secondRow}>
          <p className={styles.subtitle}>
            {t("Review, manage and add new opportunity.")}
          </p>
        </Row>
        <Row className={styles.fourthRow}>
          <Col>
            <Row className={styles.buttons}>
              <ButtonGroup>
                <Button
                  color={status ? "primary" : "secondary"}
                  onClick={() => {
                    setStatus(true)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Actives")}
                </Button>
                <Button
                  color={status ? "secondary" : "primary"}
                  onClick={() => {
                    setStatus(false)
                    setActivePage(1)
                    setSelectedRowId("")
                  }}
                >
                  {t("Passives")}
                </Button>
              </ButtonGroup>
              {status && (
                <Button
                  color="danger"
                  onClick={() => {
                    if (!selectedRowId) {
                      setShowWarnMsg(true)
                    } else {
                      setShowWarnDeactivateMsg(true)
                    }
                  }}
                >
                  {t("Deactivate")}
                </Button>
              )}
            </Row>
          </Col>
        </Row>

        {errorMessage && (
          <Alert color="danger" className="mt-2">
            {t(errorMessage)}
          </Alert>
        )}
        <Row className={styles.lastRow}>
          <Card className={styles.card}>
            <CardBody>
              {loading ? (
                <CustomSpinner />
              ) : (
                <Table
                  opportunities={opportunities}
                  opportunityTypes={opportunityTypes}
                  onSelectTableRow={setSelectedRowId}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemsCountPerPage={itemsCountPerPage}
                  onPageChange={setActivePage}
                  hasPagination={true}
                />
              )}
            </CardBody>
          </Card>
        </Row>
      </Container>
      {showWarnMsg && (
        <SweetAlert
          warning
          title={t("Please select a row!")}
          onConfirm={() => {
            setShowWarnMsg(false)
          }}
          confirmBtnText={t("OK")}
        />
      )}

      <SweetAlert
        show={showWarnDeactivateMsg}
        warning
        showCancel
        confirmBtnText={t("Yes, deactivate it!")}
        cancelBtnText={t("Cancel")}
        confirmBtnBsStyle="danger"
        title={t("Are you sure?")}
        onConfirm={deactivate}
        onCancel={() => {
          setShowWarnDeactivateMsg(false)
        }}
        focusCancelBtn
      >
        {t("You will not be able to recover it!")}
      </SweetAlert>

      <SweetAlert
        show={showSuccessDeactivateMsg}
        success
        confirmBtnText={t("OK")}
        confirmBtnBsStyle="primary"
        title={t("Deactivated!")}
        onConfirm={() => {
          fetchCompanyOpportunities()
          setShowSuccessDeactivateMsg(false)
        }}
      >
        {t("The opportunity is deactivated successfully!")}
      </SweetAlert>
    </main>
  )
}

export default PropertyYourOpportunities
