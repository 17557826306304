import Sidebar from "./sidebar/Sidebar"
import ChatBox from "./chatBox/ChatBox"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ChatProvider, useChatContext } from "./context"
import { useMasterSocket } from "contexts/master-socket-context"
import styles from "./styles.module.scss"

const Chats = () => {
  const { t } = useTranslation()
  const { values } = useChatContext()
  const { loading } = values
  return (
    <div className="page-content">
      <Breadcrumbs
        breadcrumbItem={t("Customer Service Messages")}
        title={t("Messages")}
      />
      <div className={styles.container}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <Spinner />
          </div>
        ) : (
          <>
            <Sidebar />
            <ChatBox />
          </>
        )}
      </div>
    </div>
  )
}

const WithProvider = props => {
  const { masterSocket } = useMasterSocket()
  if (!masterSocket) return null

  return (
    <ChatProvider>
      <Chats {...props} />
    </ChatProvider>
  )
}

export default WithProvider
