import MainComponent from "./Component/Component"

const SearchEngine = () => {
  return (
    <MainComponent
      title={"Increase your sales with search engine"}
      opportunityType_id={2}
    />
  )
}

export default SearchEngine
