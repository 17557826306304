import * as yup from "yup"

export const schema = yup.object({
  type_id: yup.string().required("This field is required!"),
  propertyName: yup
    .string()
    .required("This field is required!")
    .min(3, "Too short!")
    .max(50, "Too long!"),
  checkInTime: yup.string().required("This field is required!"),
  checkOutTime: yup.string().required("This field is required!"),
  bedroomCount: yup
    .number()
    .typeError("Must be a number!")
    .required("This field is required!")
    .positive()
    .min(0, "Invalid input!")
    .max(15, "Invalid input!"),
  livingRoomCount: yup
    .number()
    .typeError("Must be a number!")
    .required("This field is required!")
    .positive()
    .min(0, "Invalid input!")
    .max(15, "Invalid input!"),
  bathroomCount: yup
    .number()
    .typeError("Must be a number!")
    .required("This field is required!")
    .positive()
    .min(0, "Invalid input!")
    .max(15, "Invalid input!"),
  country_id: yup.string().required("This field is required!"),
  province_id: yup.string().required("This field is required!"),
  district_id: yup.string().required("This field is required!"),
  address: yup
    .string()
    .required("This field is required!")
    .min(5, "Too short!")
    .max(50, "Too long!"),
  latitude: yup.string().required("Please add coordinate!"),
  longitude: yup.string().required("Please add coordinate!"),
  maxAdult: yup
    .number()
    .typeError("Must be a number!")
    .required("This field is required!")
    .positive()
    .min(0, "Invalid input!")
    .max(15, "Invalid input!"),
  maxChild: yup
    .number()
    .typeError("Must be a number!")
    .required("This field is required!")
    .positive()
    .min(0, "Invalid input!")
    .max(15, "Invalid input!"),
})
