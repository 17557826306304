import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Label,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
} from "availity-reactstrap-validation-safe"
import CustomWarning from "components/Common/CustomWarning/index"
import Breadcrumbs from "components/Common/Breadcrumb"

import useRequest from "hooks/useRequest"
import styles from "./styles.module.scss"
import { AvatarGroup } from "@mui/material"

const PropertyPartners = () => {
  const company_id = useSelector(state => state.Company.company.id)

  const [showWarning, setShowWarning] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [companyName, setCompanyName] = useState("")
  const [partners, setPartners] = useState([])
  const [selectedCompany, setSelectedCompany] = useState("")
  const [email, setEmail] = useState([])

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await Promise.allSettled([fetchCompanyPartners(company_id)])

      setLoading(false)
    })()
  }, [])

  const fetchCompanyPartners = async company_id => {
    const response = await request(
      {
        url: "/property-partners-companies",
        params: { company_id },
      },
      false
    )

    const partners = response?.dbResult?.map(i => ({
      selectedCompany: { label: i.companyName, value: i.id },
      email: i.email,
    }))

    setPartners(partners)
  }

  const submitData = async (e, inputs) => {
    let data = {
      ...inputs,
      statu: 1,
    }

    setErrorMessage("")
    if (data.companyName.length === 0 || data.email.length === 0) {
      return setShowWarning("Please add some partners!")
    }
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/property-partners-companies",
        method: "post",
        data,
      },
      false
    )
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Property")} breadcrumbItem={t("Partners")} />
        <div className={loading ? "d-none" : ""}>
          <Row>
            <p style={{ marginLeft: "14px" }}>
              {t(
                "Other companies selling your villa can view your availability by giving permission ."
              )}
            </p>
          </Row>
          <AvForm onValidSubmit={submitData}>
            <Card>
              <CardBody className={styles.cardBody}>
                <Row className={`${styles.row} mt-3 mb-2`}>
                  <div className={styles.col}>
                    <AvGroup>
                      <Label>{t("Company Name")}</Label>
                      <AvInput name="companyName" />
                      <AvFeedback>{t("Please fill in the blanks!")}</AvFeedback>
                    </AvGroup>
                  </div>
                  <div className={styles.col}>
                    <AvGroup>
                      <Label>{t("Email")}</Label>
                      <AvInput name="email" />
                      <AvFeedback>{t("Please fill in the blanks!")}</AvFeedback>
                    </AvGroup>
                  </div>{" "}
                </Row>
              </CardBody>
            </Card>
            {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}
            <Row className={styles.btnContainer}>
              <Button color="primary">
                {t("Submit")}{" "}
                {submitLoading && <Spinner color="white" size="sm" />}
              </Button>
            </Row>
          </AvForm>
        </div>
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={!!showWarning}
        onConfirm={() => setShowWarning("")}
        message={showWarning}
      />
      {/* SUCCESS ALERT */}
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => {
          setShowSuccessAlert(false)
          fetchCompanyPartners()
        }}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default PropertyPartners
