import { useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import { Divider } from "@mui/material"

import ResStatusModal from "./ResStatusModal"
import { useReservationContext } from "../context"
import { momentFormat } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

const ButtonsSection = () => {
  const [showResStatusModal, setShowResStatusModal] = useState(false)
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { values, functions } = useReservationContext()
  const {
    cancelResHandler,
    printHandler,
    notUsedResHandler,
    resNotUsedLoading,
  } = functions
  const { resStatuses, resDetails, resCancelLoading } = values
  if (!resDetails.reservation) {
    return null
  }

  const status_id = resDetails.reservation.resStatus_id
  const statusName = resStatuses.find(i => i.id === status_id)?.name
  const resStatusUpdatedBy = resDetails.reservation?.resStatusUpdatedByName
  const date = resDetails.reservation.resStatusUpdatedAt
  const resStatusUpdatedAt = date ? momentFormat(date, locale, "LLL") : ""

  return (
    <section>
      <Card>
        <CardBody className={styles.bsCardBody}>
          <Stack spacing={1} className={styles.stack}>
            <h5>{t("Update The Reservation")}</h5>
            <Button variant="outlined" onClick={printHandler}>
              {t("Print this page")}
            </Button>
            <Divider />
            <Button
              variant="outlined"
              onClick={setShowResStatusModal.bind(null, true)}
              disabled={status_id !== 1}
            >
              {t("Update Reservation Status")}
            </Button>
            <Button
              variant="outlined"
              onClick={notUsedResHandler}
              disabled={status_id !== 2}
            >
              {t("Not Used Reservation")}
              {resNotUsedLoading && <Spinner size="sm" className="ml-1" />}
            </Button>

            <Button
              variant="outlined"
              onClick={cancelResHandler}
              disabled={status_id !== 5}
            >
              {t("Confirm Cancellation Request")}
              {resCancelLoading && <Spinner size="sm" className="ml-1" />}
            </Button>

            <div className={styles.status}>
              <p
                style={{
                  backgroundColor:
                    status_id === 3 || status_id === 6 ? "#d30b0b" : "#44ac49",
                }}
              >
                {t(statusName)}
              </p>
            </div>

            <div>
              {status_id !== 1 && (
                <p className="mb-0 text-center">
                  {resStatusUpdatedBy} {resStatusUpdatedAt}
                </p>
              )}
              {status_id === 3 && (
                <p className="mb-0 text-center">
                  {resDetails.reservation.resStatusDescription}
                </p>
              )}
              {status_id === 6 && (
                <p className="mb-0 text-center text-danger">
                  {resDetails?.reservation?.resCancelledByName}{" "}
                  {momentFormat(
                    resDetails?.reservation?.resCancelledAt,
                    locale,
                    "LLL"
                  )}
                </p>
              )}
            </div>
          </Stack>
        </CardBody>
      </Card>

      {showResStatusModal && (
        <ResStatusModal close={setShowResStatusModal.bind(null, false)} />
      )}
    </section>
  )
}

export default ButtonsSection
