import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import { getLabelByLanguage } from "utils/other"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import styles from "./styles.module.scss"

const HotelBoardType = () => {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [types, setTypes] = useState([])
  const [hotel_id, setHotel_id] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setLoading(false)
        setShowHotelNotRegWarning(true)
        return
      }
      const types = await fetchTypes()
      await fetchHotel_BoardTypes(types, hotel_id)
      setLoading(false)
    })()
  }, [])

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      setHotel_id(response.dbResult.id)
      return response.dbResult.id
    }
  }

  const fetchTypes = async () => {
    const response = await request(
      {
        url: "/hotel-set-board-types",
      },
      false
    )
    const boardTypes = response?.dbResult?.map(i => ({
      ...i,
      title: getLabelByLanguage(i, locale),
      isChecked: false,
    }))

    setTypes(boardTypes)
    return boardTypes
  }

  const fetchHotel_BoardTypes = async (types, hotel_id) => {
    const response = await request(
      {
        url: "/hotel-board-types",
        params: { hotel_id },
      },
      false
    )

    const hotel_boardTypes = response?.dbResult
    const updatedTypes = [...types]

    hotel_boardTypes?.forEach(i => {
      for (let j = 0; j < updatedTypes.length; j++) {
        const type = updatedTypes[j]
        if (i.setBoardType_id === type.id) {
          updatedTypes[j] = { ...type, isChecked: true }
          break
        }
      }
    })
    setTypes(updatedTypes)
  }

  useEffect(() => {
    const updatedTypes = types?.map(item => {
      return {
        ...item,
        title: getLabelByLanguage(item, locale),
      }
    })

    setTypes(updatedTypes)
  }, [locale, types?.length])

  const checkHandler = id => {
    const updatedTypes = [...types]
    const index = updatedTypes.findIndex(item => item.id === id)
    const type = updatedTypes[index]
    updatedTypes[index] = { ...type, isChecked: !type.isChecked }
    setTypes(updatedTypes)
  }

  const submitHandler = async () => {
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    const dataList = []
    types.forEach(i => {
      if (i.isChecked) {
        dataList.push({
          setBoardType_id: i.id,
          hotel_id,
        })
      }
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-board-types",
        method: "put",
        data: { dataList, hotel_id },
      },
      false
    )
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response.message)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Board Type")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <p className={styles.subtitle}>
              {t(
                "Please indicate which hotel types you offer at the facility."
              )}
            </p>
            <Row className="mx-0 mt-4">
              {types.map(item => (
                <FormGroup key={item.id} className={styles.checkboxContainer}>
                  <div
                    onClick={() => checkHandler(item.id)}
                    className={styles.checkboxWrapper}
                  >
                    <Input
                      type="checkbox"
                      className={styles.checkbox}
                      name={item.title}
                      id={item.id}
                      onChange={() => {}}
                      checked={item.isChecked || false}
                    />
                    <span className="ml-2">{item.title}</span>
                  </div>
                </FormGroup>
              ))}
              {HotelNotRegisteredComp}
            </Row>
          </CardBody>
        </Card>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />
        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

export default HotelBoardType
