import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import CustomModal from "./CustomModal"
import CustomTable from "./CustomTable"

const CancelToken = axios.CancelToken
let cancel

class ExtraServicesPricing extends Component {
  state = {
    isModalOpen: false,
    extraServicesPrices: [],
    selectedRowId: "",
    selectedRowData: {},
    showWarningMessage: false,
    showWarnDeleteMsg: false,
    showSuccessDeleteMsg: false,
    isAddOperation: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    errorMessage: "",
    error: false,
  }

  componentDidMount = () => {
    this.fetchExtraServicesPrices()
  }

  fetchExtraServicesPrices = async () => {
    try {
      this.setState({ loading: true })
      const { activePage, itemsCountPerPage, searchText } = this.state
      const url = `/transfer-extra-service-prices/fetch-by-page-number?page=${activePage}&limit=${itemsCountPerPage}&searchText=${searchText}`

      if (cancel !== undefined) {
        cancel()
      }

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      }
      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: {},
          extraServicesPrices: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ error: true, errorMessage: response.data.message })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  delete = async () => {
    this.setState({ showWarnDeleteMsg: false })
    try {
      this.setState({ loading: true })
      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
        data: {
          id: this.state.selectedRowId,
        },
      }
      const url = "/transfer-extra-service-prices"
      const response = await axios.delete(url, config)

      this.setState({ loading: false })

      if (response.data.result) {
        this.refreshTable()
      } else {
        this.setState({
          error: true,
          errorMessage: response.data.message,
        })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedRowId => {
    let selectedRowData = this.state.extraServicesPrices.find(item => {
      return item.id == selectedRowId
    })
    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchExtraServicesPrices)
  }

  refreshTable = () => {
    this.setState(
      { activePage: 1, searchText: "", totalItemsCount: 0 },
      this.fetchExtraServicesPrices
    )
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      loading,
      error,
      errorMessage,
      selectedRowId,
      extraServicesPrices,
      isModalOpen,
      isAddOperation,
      selectedRowData,
      showWarnDeleteMsg,
      showSuccessDeleteMsg,
      showWarningMessage,
    } = this.state
    const { t } = this.props

    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <CustomTable
          extraServicesPrices={extraServicesPrices}
          onSelectTableRow={this.onSelectTableRow}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Transfer")}
              breadcrumbItem={t("Extra Services Pricing")}
            />
            <ControlButtons
              displayAddBtn={true}
              displayEditBtn={true}
              displayDeleteBtn={true}
              onAddBtn={() => {
                this.setState({ isModalOpen: true, isAddOperation: true })
              }}
              onEditBtn={() => {
                if (selectedRowId) {
                  this.setState({ isModalOpen: true, isAddOperation: false })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onDeleteBtn={() => {
                if (selectedRowId) {
                  this.setState({ showWarnDeleteMsg: true })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState(
                  { searchText, activePage: 1 },
                  this.fetchExtraServicesPrices
                )
              }}
            />
            {Content}
          </Container>
        </div>
        {isModalOpen && (
          <CustomModal
            modalTitle={
              isAddOperation ? "Add Extra Service" : "Edit Extra Service"
            }
            isOpen={true}
            close={() => {
              this.setState({ isModalOpen: false, isAddOperation: false })
            }}
            extraServicePrice={isAddOperation ? {} : selectedRowData}
            refreshTable={this.refreshTable}
            isAddOperation={isAddOperation}
          />
        )}

        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}

        {showWarnDeleteMsg && (
          <SweetAlert
            show={true}
            warning
            showCancel
            confirmBtnText={t("Yes, delete it!")}
            cancelBtnText={t("Cancel")}
            confirmBtnBsStyle="danger"
            title={t("Are you sure?")}
            onConfirm={this.delete}
            onCancel={() => {
              this.setState({ showWarnDeleteMsg: false })
            }}
            focusCancelBtn
          >
            {t("You will not be able to recover it!")}
          </SweetAlert>
        )}

        {showSuccessDeleteMsg && (
          <SweetAlert
            show={true}
            success
            confirmBtnText={t("OK")}
            confirmBtnBsStyle="primary"
            title={t("Deleted!")}
            onConfirm={() => {
              this.setState({ showSuccessDeleteMsg: false })
            }}
          >
            {t("The row is deleted successfully!")}
          </SweetAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(
  connect(mapStateToProps, {})(ExtraServicesPricing)
)
