import { IoMdBed } from "react-icons/io"
import { HiMinus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"

import styles from "./styles.module.scss"

export default ({ data, onDelete }) => {
  const { t } = useTranslation()

  const deleteHandler = () => {
    onDelete(data.selectedRoomNumber.value, data.selectedBedType.value)
  }
  return (
    <Row className={styles.bedTypeItem}>
      <span className={styles.bedroomLabel}>
        {data.selectedRoomNumber.label}
      </span>
      <span className={styles.bedTypeLabel}>{data.selectedBedType.label}</span>
      <div className={styles.row}>
        <span>{data.selectedBedTypeCount.value}</span>
        <span
          className={styles.iconsDel}
          onClick={deleteHandler}
          title={t("Delete")}
        >
          <HiMinus size={22} />
          <IoMdBed size={35} className={styles.lastIcon} />
        </span>
      </div>
    </Row>
  )
}
