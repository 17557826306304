import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@mui/styles"
import { Label } from "reactstrap"

const useStyles = makeStyles(() => ({
  formControl: {
    width: 160,
    background: "white",
    ["@media (max-width: 500px)"]: {
      width: 320,
    },
  },
  col: {
    display: "flex",
    flexDirection: "Column",
  },
  input: {
    height: 36.41,
    color: "#495057",
  },
}))

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
}

const CustomSingleSelect = ({ label, onChange, items, renderValue }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const id = `custom-select-${label}`
  return (
    <div className={classes.col}>
      <Label>{t(label)}</Label>
      <FormControl className={classes.formControl} size="small">
        <Select
          id={id}
          displayEmpty
          value={""}
          input={
            <OutlinedInput
              label=""
              className={classes.input}
              sx={{ fontSize: 13.5 }}
            />
          }
          MenuProps={MenuProps}
          renderValue={renderValue}
        >
          {items?.map(item => (
            <MenuItem key={item.id} onClick={() => onChange(item)}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSingleSelect
