import React from "react"
import { Card, CardBody, Input } from "reactstrap"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

export default function MinNightsInput(props) {
  const { minNightDay, setMinNightDay } = props
  const { t } = useTranslation()

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>
          {t("How long do guests need to stay to use this promotion?")}
        </h5>
        <div className={styles.minNightWrapper}>
          <Input
            value={minNightDay}
            onChange={(e, v) => {
              setMinNightDay(e.target.value.replace(/[^0-9]/g, ""))
            }}
            min={1}
            step={1}
            type="number"
          />
          <span>{t("night or more")}</span>
        </div>
      </CardBody>
    </Card>
  )
}
