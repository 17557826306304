import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Button,
  Spinner,
  Label,
  Alert,
} from "reactstrap"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { MdOutlineHomeWork } from "react-icons/md"
import { FaRegCalendarCheck } from "react-icons/fa"
import { MdShoppingCart } from "react-icons/md"
import { BiTrendingUp } from "react-icons/bi"
import { Box, Slider } from "@mui/material"

import useRequest from "hooks/useRequest"
import Breadcrumbs from "components/Common/Breadcrumb"
import SuccessAlert from "components/Common/SuccessAlert"
import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomDayPicker from "components/Common/CustomDayPickerInput/index"
import { addDays, getTodayDate } from "utils/dateTimeFunctions"
import { getLabelByLanguage, all } from "utils/other"
import styles from "./styles.module.scss"

export default function index({ title, opportunityType_id }) {
  const company_id = useSelector(state => state.Company.company.id)

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")
  const [propertyNames, setPropertyNames] = useState([])
  const [startDate, setStartDate] = useState(getTodayDate())
  const [endDate, setEndDate] = useState(addDays(getTodayDate(), 7))
  const [marketingPlaces, setMarketingPlaces] = useState([])
  const [commission, setCommission] = useState(10)
  const [defaultCommission, setDefaultCommission] = useState("")

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const { request } = useRequest(setLoading, setErrorMessage)

  useEffect(() => {
    const fetchPropertyNames = async company_id => {
      const response = await request(
        {
          url: "/property/names",
          params: { company_id },
        },
        false
      )

      if (response?.properties) {
        const propertyNames = response?.properties?.map(i => ({
          ...i,
          isChecked: false,
        }))

        propertyNames.unshift({
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })

        setPropertyNames(propertyNames)
        return propertyNames
      }
    }

    const fetchDefaultCommission = async company_id => {
      const response = await request(
        {
          url: "/company-types/fetch-by-companyId",
          params: { company_id },
        },
        false
      )

      if (response?.dbResult) {
        const defaultCommission = response?.dbResult[0]?.defaultCommission
        setDefaultCommission(defaultCommission)
        return defaultCommission
      }
    }

    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      const marketingPlaces = response?.dbResult?.map(i => ({
        ...i,
        id: i.id[1],
        isChecked: false,
      }))
      marketingPlaces.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })
      setMarketingPlaces(marketingPlaces)
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchPropertyNames(company_id),
        fetchSetMarketingPlaces(),
        fetchDefaultCommission(company_id),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (marketingPlaces.length > 0) {
      const newMP = [...marketingPlaces]
      newMP[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newMP)
    }

    if (propertyNames.length > 0) {
      const newPN = [...propertyNames]
      newPN[0].name = getLabelByLanguage(all, locale)
      setMarketingPlaces(newPN)
    }
  }, [locale])

  // CHECK HANDLERS
  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const propertyCheckHandler = id => {
    let updatedProperties = []
    if (id === 0) {
      updatedProperties = propertyNames.map(i => ({
        ...i,
        isChecked: !propertyNames[0].isChecked,
      }))
    } else {
      updatedProperties = propertyNames.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedProperties.length; i++) {
      if (!updatedProperties[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedProperties[0].isChecked = true
    } else {
      updatedProperties[0].isChecked = false
    }

    setPropertyNames(updatedProperties)
  }

  const validSubmitHandler = () => {
    const submit = async newData => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "/property-opportunities",
          method: "post",
          data: newData,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }
    setError("")

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    const property_ids = propertyNames
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (property_ids.length === 0) {
      return setError(t("Please select a property!"))
    }

    let newData = {
      startDate,
      endDate,
      setMarketingPlace_ids,
      setOpportunityType_id: opportunityType_id,
      defaultCommission,
      commission,
      status: 1,
      property_ids,
      company_id,
    }
    submit(newData)
  }
  const resetStates = () => {
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setMarketingPlaces(marketingPlaces.map(i => ({ ...i, isChecked: false })))
    setPropertyNames(propertyNames.map(i => ({ ...i, isChecked: false })))
    setCommission(10)
    setError(null)
  }
  const resetDatesHandler = () => {
    setStartDate(getTodayDate())
    setEndDate(addDays(getTodayDate(), 7))
    setError(null)
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Property")}
          breadcrumbItem={t("Influencer Marketing")}
        />
        {loading && <CustomSpinner color="primary" />}
        {!loading && (
          <>
            <Card className="mb-3">
              <CardBody>
                <header className={styles.header}>
                  <h4>{t(title)}</h4>
                  <p>
                    {t(
                      "You can fill your empty villas and attract the guests you want to target."
                    )}
                  </p>
                </header>

                <Label className={styles.label}>
                  <MdOutlineHomeWork size={20} />
                  {t("Select the properties on which it will be active.")}
                </Label>
                <Row className={styles.checkboxRow}>
                  {propertyNames.map(item => (
                    <div
                      key={item.id}
                      className={styles.checkboxWrapper}
                      onClick={() => propertyCheckHandler(item.id)}
                    >
                      <Input
                        type="checkbox"
                        className={styles.checkboxInput}
                        name={item.name}
                        id={item.id}
                        onChange={() => {}}
                        checked={item.isChecked || false}
                      />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </Row>
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <form>
                  <FormGroup className="mb-1">
                    <Label className={styles.label}>
                      <FaRegCalendarCheck size={20} />
                      {t("Select the dates on which it will be active.")}
                    </Label>
                    <Row className={styles.row1}>
                      {/* DATE INPUTS */}
                      <FormGroup className={styles.singleDateContainer}>
                        <Label>{t("From")}</Label>
                        <CustomDayPicker
                          onChange={date => {
                            setStartDate(date)
                            if (date.getTime() > endDate.getTime()) {
                              setEndDate(date)
                            }
                          }}
                          value={startDate}
                          disabledDays={[]}
                        />
                      </FormGroup>
                      <FormGroup className={styles.singleDateContainer}>
                        <Label>{t("To")}</Label>
                        <CustomDayPicker
                          onChange={setEndDate}
                          value={endDate}
                          disabledDays={[{ before: startDate }]}
                        />
                      </FormGroup>

                      <Button
                        color="primary"
                        onClick={resetDatesHandler}
                        className={styles.resetBtn}
                      >
                        {t("Reset Dates")}
                      </Button>
                    </Row>
                  </FormGroup>
                </form>
              </CardBody>
            </Card>
            {marketingPlaces.length > 2 && (
              <Card className="mb-3">
                <CardBody>
                  <Label className={styles.label}>
                    <MdShoppingCart size={20} />
                    {t("Sales Channels")}
                  </Label>
                  <Row className={styles.checkboxRow}>
                    {marketingPlaces.map(item => (
                      <div
                        key={item.id}
                        className={styles.checkboxWrapper}
                        onClick={() => marketingPlaceCheckHandler(item.id)}
                      >
                        <Input
                          type="checkbox"
                          className={styles.checkboxInput}
                          name={item.name}
                          id={item.id}
                          onChange={() => {}}
                          checked={item.isChecked || false}
                        />
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            )}
            <Card className="mb-3">
              <CardBody>
                <Label className={styles.label}>
                  <BiTrendingUp size={20} />
                  {t("What would you like your new commission rate to be?")}
                </Label>
                <p>
                  {t(
                    "The final step! Move the blue dot to increase your commission. The higher you raise, the more visible you will be on selected dates."
                  )}
                </p>
                <p className={styles.commission}>
                  {t("Current Commission")} {defaultCommission}%
                </p>
                <p>
                  {t(
                    "Move the circle icon along the line to increase your new commission rate."
                  )}
                </p>
                <Box className={styles.sliderWrapper}>
                  <Slider
                    defaultValue={+defaultCommission}
                    valueLabelDisplay="auto"
                    marks
                    min={10}
                    max={50}
                    step={5}
                    value={commission}
                    onChange={e => setCommission(e.target.value)}
                  />
                </Box>
                <p className={styles.commission}>
                  {t("Total Commission")} {defaultCommission + commission}%
                </p>
              </CardBody>
            </Card>
            {error && <Alert color="danger"> {t(error)}</Alert>}
            <Button
              color="primary"
              onClick={validSubmitHandler}
              className={styles.submit}
            >
              {t("Apply new commission rate")}
              {"  "}
              {submitLoading && <Spinner size="sm" color="white" />}
            </Button>
          </>
        )}
      </Container>

      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            resetStates()
          }}
        />
      )}
    </main>
  )
}
