import axios from "axios"
import { useTranslation } from "react-i18next"
import { Container, Spinner } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SuccessAlert from "components/Common/SuccessAlert/index"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import { getTranslatedOption, getLabelByLanguage } from "utils/other"
import Breadcrumbs from "components/Common/Breadcrumb"
import { updateCompany } from "store/actions"
import CustomForm from "./CustomForm"

const CompanySettings = () => {
  const dispatch = useDispatch()
  const { company } = useSelector(state => state.Company)

  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedProvince, setSelectedProvince] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [taxAdministration, setTaxAdministration] = useState("")
  const [taxNumber, setTaxNumber] = useState("")
  const [logoRef, setLogoRef] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const url = "/countries"
        const response = await axios.get(url)
        if (response.data.result) {
          let countries = response.data.countries?.map(c => {
            return {
              ...c,
              label: getTranslatedOption(c, "name", locale),
              value: c.id,
            }
          })
          setCountries(countries)
          return countries
        } else {
          setErrorMessage(response.data.message)
        }
      } catch (error) {
        setErrorMessage("Something went wrong!!")
      }
    }

    const fetchData = async () => {
      setLoading(true)

      const countries = await fetchCountries()
      const { country_id, province_id, district_id } = company

      const selectedCountry = countries.find(c => {
        return c.value == country_id
      })

      if (selectedCountry) {
        setSelectedCountry(selectedCountry)
        const provinces = await fetchProvinces(selectedCountry)

        if (province_id && district_id) {
          // prepare selected Province
          const selectedP = await provinces.find(p => {
            return p.value == province_id
          })
          // preparing selected district
          const districts = await fetchDistricts(selectedP)
          const selectedDist = districts.find(d => {
            return d.value == district_id
          })

          setSelectedProvince(selectedP)
          setSelectedDistrict(selectedDist)
        }
      }

      setName(company.name || "")
      setTitle(company.title || "")
      setTelephoneNumber(company.telephoneNumber || "")
      setEmail(company.email || "")
      setAddress1(company.address1 || "")
      setAddress2(company.address2 || "")
      setTaxAdministration(company.taxAdministration || "")
      setTaxNumber(company.taxNumber || "")
      setLogoRef(company.logoRef || "")
      setLoading(false)
    }

    if (!email && !!company?.email) {
      fetchData()
    }
  }, [company, email])

  useEffect(() => {
    if (countries.length > 0) {
      setCountries(
        countries.map(c => {
          return {
            ...c,
            label: getTranslatedOption(c, "name", locale),
          }
        })
      )
    }
    if (provinces.length > 0) {
      setProvinces(
        provinces.map(p => {
          return {
            ...p,
            label: getTranslatedOption(p, "name", locale),
          }
        })
      )
    }

    if (districts.length > 0) {
      setDistricts(
        districts.map(d => {
          return {
            ...d,
            label: getTranslatedOption(d, "name", locale),
          }
        })
      )
    }

    if (selectedCountry) {
      setSelectedCountry({
        ...selectedCountry,
        label: getTranslatedOption(selectedCountry, "name", locale),
      })
    }

    if (selectedProvince) {
      setSelectedProvince({
        ...selectedProvince,
        label: getTranslatedOption(selectedProvince, "name", locale),
      })
    }

    if (selectedDistrict) {
      setSelectedDistrict({
        ...selectedDistrict,
        label: getTranslatedOption(selectedDistrict, "name", locale),
      })
    }
  }, [locale])

  const fetchProvinces = async selectedCountry => {
    try {
      let country_id = selectedCountry.value
      const url = "/provinces?country_id=" + country_id

      const response = await axios.get(url)

      if (response.data.result) {
        let provinces = response.data.provinces.map(p => {
          return {
            ...p,
            label: getTranslatedOption(p, "name", locale),
            value: p.id,
          }
        })
        setProvinces(provinces)
        return provinces
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage("Something went wrong!!")
    }
  }

  const fetchDistricts = async selectedProvince => {
    try {
      let province_id = selectedProvince.value
      const url = "/districts?province_id=" + province_id
      const response = await axios.get(url)
      if (response.data.result) {
        let districts = response.data.districts.map(d => {
          return {
            ...d,
            label: getLabelByLanguage(d, locale),
            value: d.id,
          }
        })
        setDistricts(districts)
        return districts
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage("Something went wrong!!")
    }
  }

  const saveHandler = async () => {
    setSaveLoading(true)
    if (!name || !email || !telephoneNumber || !selectedCountry || !selectedProvince || !selectedDistrict) {
      setErrorMessage("Please fill in the blanks!")
      setSaveLoading(false)
    } else {
      const newCompany = {
        name,
        title,
        telephoneNumber,
        email,
        country_id: selectedCountry.value,
        province_id: selectedProvince.value,
        district_id: selectedDistrict.value,
        address1,
        address2,
        taxAdministration,
        taxNumber,
        logoRef,
      }
      dispatch(updateCompany(newCompany, callback))
    }
  }

  const callback = obj => {
    if (obj.result) {
      setSaveLoading(false)
      setShowSuccessMsg(true)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Setup")}
          breadcrumbItem={t("Company Settings")}
        />
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <CustomForm
            name={name}
            title={title}
            telephoneNumber={telephoneNumber}
            email={email}
            countries={countries}
            provinces={provinces}
            districts={districts}
            selectedCountry={selectedCountry}
            selectedProvince={selectedProvince}
            selectedDistrict={selectedDistrict}
            address1={address1}
            address2={address2}
            taxAdministration={taxAdministration}
            taxNumber={taxNumber}
            logoRef={logoRef}
            errorMessage={errorMessage}
            saveLoading={saveLoading}
            showSuccessMsg={showSuccessMsg}
            setName={setName}
            setTitle={setTitle}
            setTelephoneNumber={setTelephoneNumber}
            setEmail={setEmail}
            setCountries={setCountries}
            setProvinces={setProvinces}
            setDistricts={setDistricts}
            setSelectedCountry={setSelectedCountry}
            setSelectedProvince={setSelectedProvince}
            setSelectedDistrict={setSelectedDistrict}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setTaxAdministration={setTaxAdministration}
            setTaxNumber={setTaxNumber}
            setLogoRef={setLogoRef}
            setErrorMessage={setErrorMessage}
            setShowSuccessMsg={setShowSuccessMsg}
            saveHandler={saveHandler}
            setSaveLoading={setSaveLoading}
            fetchProvinces={fetchProvinces}
            fetchDistricts={fetchDistricts}
            company={company}
          />
        )}

        {showSuccessMsg && (
          <SuccessAlert
            hideSuccessAlert={setShowSuccessMsg.bind(null, false)}
          />
        )}
      </Container>
    </div>
  )
}

export default CompanySettings
