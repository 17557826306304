import React from "react"
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import WidgetPagination from "components/Common/WidgetPagination/index"

class RoomTable extends React.Component {
  state = {
    selectedRowId: "",
  }

  render() {
    const {
      t,
      i18n,
      onSelectTableRow,
      rooms,
      roomTypes,
      roomNames,
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      onPageChange,
      hasPagination,
    } = this.props

    const RoomComp = rooms?.map(item => {
      // find room types and room names
      const roomType = roomTypes?.find(i => i.id === item.setRoomType_id)
      const roomTypeName = getNameByLanguage(i18n.language, roomType)
      const roomNameObj = roomNames?.find(i => i.id === item.setRoomName_id)
      const roomName = getNameByLanguage(i18n.language, roomNameObj)
      return (
        <Tr
          key={item.id}
          onClick={() => {
            onSelectTableRow(item.id)
            this.setState({ selectedRowId: item.id })
          }}
          className={this.state.selectedRowId === item.id ? "selectedRow" : ""}
        >
          <Td className="align-middle text-nowrap">{roomTypeName}</Td>
          <Td className="align-middle text-nowrap">{roomName}</Td>
          <Td className="align-middle text-nowrap">{item.specialName}</Td>
          <Td className="align-middle text-nowrap">{item.roomCount}</Td>
          <Td className="align-middle text-nowrap">{item.roomArea}</Td>
          <Td className="align-middle text-nowrap">{item.maxAdult}</Td>
          <Td className="align-middle text-nowrap">{item.maxChild}</Td>
          <Td className="align-middle text-nowrap">{item.maxCapacity}</Td>
          <Td className="align-middle text-nowrap">
            {item.extraBed ? t("Yes") : t("No")}
          </Td>
          <Td className="align-middle text-nowrap">
            {item.status ? t("Active") : t("Inactive")}
          </Td>
        </Tr>
      )
    })

    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div className="table-responsive mb-0 data-table-container">
                  <Table
                    id="main-table"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th className="table-data">{t("Room Type")}</Th>
                        <Th className="table-data">{t("Room Name")}</Th>
                        <Th className="table-data">{t("Special Name")}</Th>
                        <Th className="table-data">{t("Room Count")}</Th>
                        <Th className="table-data">{t("Area")}</Th>
                        <Th className="table-data">{t("Adult")}</Th>
                        <Th className="table-data">{t("Child")}</Th>
                        <Th className="table-data">{t("Max. Person")}</Th>
                        <Th className="table-data">{t("Extra Bed")}</Th>
                        <Th className="table-data">{t("Status")}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>{RoomComp}</Tbody>
                  </Table>
                </div>
                {hasPagination && (
                  <WidgetPagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(RoomTable)

const getNameByLanguage = (locale, item) => {
  let name = item?.name_en
  switch (locale) {
    case "tr":
      name = item?.name_tr
      break
    case "fr":
      name = item?.name_fr
      break
    case "de":
      name = item?.name_de
      break
    case "ru":
      name = item?.name_ru
      break
    case "ar":
      name = item?.name_ar
      break
    default:
      name = item?.name_en
      break
  }

  return name
}
