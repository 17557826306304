import styles from "./styles.module.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className={styles.footer}>
          {new Date().getFullYear()} © Vihobook.
        </div>
      </div>
    </footer>
  )
}

export default Footer
