import usFlag from "../assets/images/flags/us.jpg"
import turkey from "../assets/images/flags/turkey.jpg"
// import french from "../assets/images/flags/french.jpg"
// import germany from "../assets/images/flags/germany.jpg"
// import russia from "../assets/images/flags/russia.jpg"
// import arabic from "../assets/images/flags/arabic.jpg"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  tr: {
    label: "Turkish",
    flag: turkey,
  },
  // fr: {
  //   label: "French",
  //   flag: french,
  // },
  // de: {
  //   label: "German",
  //   flag: germany,
  // },
  // ru: {
  //   label: "Russian",
  //   flag: russia,
  // },
  // ar: {
  //   label: "العربية",
  //   flag: arabic,
  // },
}

export default languages
