import React from "react"
import {
  Container,
  Card,
  CardBody,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { Divider } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import { CancellationProvider, useCancellationContext } from "./context"
import ConditionInputs from "./ConditionInputs"
import PolicySummary from "./PolicySummary"
import styles from "./styles.module.scss"

const HotelCancellationPolicy = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { values, setters, functions } = useCancellationContext()
  const {
    loading,
    submitLoading,
    errorMessage,
    freeCancellable,
    showSuccessAlert,
    hotelNotReg,
    hasSignedContract,
  } = values
  const { setFreeCancellable, setShowSuccessAlert, setHotelNotReg } = setters
  const { submitHandler } = functions
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Cancellation Policy")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h4 className={styles.title}>
              {t("Set Reservation Cancellation Conditions")}.{" "}
              {t(
                "You cannot change this information after your Agreement has been approved by Vihobook."
              )}
            </h4>
            <div className={styles.isFreeCancellableContainer}>
              <p>
                {t(
                  "Is there a period when the guest can cancel free of charge?"
                )}
              </p>
              <p>
                {t(
                  "It is recommended to set a free cancellation period to increase your sales."
                )}
              </p>
              <div className={styles.buttons}>
                <label htmlFor="yes">
                  <input
                    type="radio"
                    id="yes"
                    name="freeCancellable"
                    disabled={hasSignedContract}
                    checked={freeCancellable === true}
                    value={freeCancellable}
                    onChange={() => setFreeCancellable(true)}
                  />
                  {t("Yes")}
                </label>
                <label htmlFor="no">
                  <input
                    type="radio"
                    id="no"
                    name="freeCancellable"
                    disabled={hasSignedContract}
                    value={freeCancellable}
                    checked={freeCancellable === false}
                    onChange={() => setFreeCancellable(false)}
                  />
                  {t("No")}
                </label>
              </div>
            </div>
            <Divider className={styles.divider} />
            <ConditionInputs hasSignedContract={hasSignedContract} />
            <PolicySummary />
          </CardBody>
        </Card>

        {errorMessage && <Alert color="danger">{t(errorMessage)}</Alert>}

        <CustomWarning
          title="Warning!"
          warning={true}
          show={hotelNotReg}
          onConfirm={() => {
            setHotelNotReg(false)
            history.push("/hotel-information")
          }}
          message="Please Register Your Hotel Information First!"
        />

        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />

        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button
            style={{ cursor: hasSignedContract ? "not-allowed" : "hasSignedContract" }}
            color="primary"
            onClick={submitHandler}
            disabled={hasSignedContract}
          >
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

const WithProvider = props => {
  return (
    <CancellationProvider>
      <HotelCancellationPolicy {...props} />
    </CancellationProvider>
  )
}
export default WithProvider
