import React from "react"
import { useTranslation } from "react-i18next"
import { Container, Card, CardBody, Alert } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSpinner from "components/Common/CustomSpinner/index"
import SearchInputs from "./SearchInputs"
import CustomTable from "./Table/index"
import styles from "./styles.module.scss"

// custom hooks
import { useVillaOverallViewContext, VillaOverallViewProvider } from "./context"

const VillaOverView = () => {
  const { t } = useTranslation()
  const { values } = useVillaOverallViewContext()
  const { loading, error, tablesData } = values

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Marketing")}
          breadcrumbItem={t("Overall View")}
        />

        {loading ? (
          <CustomSpinner color="primary" />
        ) : (
          <>
            {/*SEARCH INPUTS*/}
            <SearchInputs />
            {tablesData?.length > 0 && (
              <Card>
                <CardBody className={styles.cardBody}>
                  {/* TABLE*/}
                  {tablesData?.map((tData, idx) => (
                    <CustomTable key={idx} data={tData} />
                  ))}
                </CardBody>
              </Card>
            )}
          </>
        )}
        {error && (
          <Alert color="danger" className={styles.alert}>
            {error}
          </Alert>
        )}
      </Container>
    </main>
  )
}

const WrappedComponent = () => (
  <VillaOverallViewProvider>
    <VillaOverView />
  </VillaOverallViewProvider>
)

export default WrappedComponent
