import { Row } from "reactstrap"
import CustomCheckbox from "components/Common/CustomCheckbox"
import styles from "./styles.module.scss"

export default ({ data, childGroupCheckHandler, childGroupChangeHandler }) => {
  const isDisabled = !data.isChecked

  return (
    <Row className={styles.row}>
      <div className={`${styles.col}`}>
        <CustomCheckbox
          id={data.id}
          title={data?.label}
          isChecked={data?.isChecked}
          onClick={childGroupCheckHandler}
        />
      </div>
      <div className={`${styles.col}`}>
        <input
          className={isDisabled && styles.disabled}
          type="number"
          disabled={isDisabled}
          value={data?.selectedStartAge}
          onChange={e => {
            childGroupChangeHandler(data.id, e.target.value, "selectedStartAge")
          }}
        />{" "}
      </div>
      <div className={`${styles.col}`}>
        <input
          className={isDisabled && styles.disabled}
          disabled={isDisabled}
          type="number"
          value={data?.selectedEndAge || ""}
          onChange={e => {
            childGroupChangeHandler(data.id, e.target.value, "selectedEndAge")
          }}
        />{" "}
      </div>
    </Row>
  )
}
