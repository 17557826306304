import { useState } from "react"
import { Col, Container, Input, Media, Row, Card } from "reactstrap"
import { useTranslation } from "react-i18next"

import Breadcrumbs from "components/Common/Breadcrumb"
import avatar from "assets/images/users/profile.png"
import Conversations from "./Conversations"
import ChatBox from "./ChatBox"
import { useMessagesContext, MessagesProvider } from "./context"
import { useSocket } from "contexts/socket-context"
import styles from "./styles.module.scss"

const Messages = () => {
  const { t } = useTranslation()
  const { values } = useMessagesContext()
  const { currentUser, conversations } = values
  const [searchInput, setSearchInput] = useState("")

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Messages")}
          breadcrumbItem={t("Reservation Messages")}
        />
        <Row>
          <Col lg="12">
            <div className="d-lg-flex">
              <Card className="chat-leftsidebar mr-lg-4 p-3">
                <div className="">
                  <div className="py-3 border-bottom">
                    <Media>
                      <div className="align-self-center mr-3">
                        <img
                          src={avatar}
                          className="avatar-xs rounded-circle"
                          alt="user image placeholder"
                        />
                      </div>
                      <Media body>
                        <h5 className="font-size-15 mt-0 mb-1">
                          {`${currentUser.name} ${currentUser.surname}`}
                        </h5>
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-circle text-success align-middle mr-1" />
                          {t("Online")}
                        </p>
                      </Media>
                    </Media>
                  </div>
                  <div className="search-box chat-search-box py-4">
                    <div className="position-relative">
                      <Input
                        type="search"
                        className={`form-control ${styles.searchBg}`}
                        placeholder={t("Search...")}
                        onChange={e => setSearchInput(e.target.value)}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                  <Conversations
                    filteredConversations={conversations.filter(i =>
                      i.name.toLowerCase().includes(searchInput.toLowerCase())
                    )}
                  />
                </div>
              </Card>
              {conversations?.length > 0 && <ChatBox />}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const WithProvider = props => {
  const { socket } = useSocket()
  if (!socket) return null
  return (
    <MessagesProvider>
      <Messages {...props} />
    </MessagesProvider>
  )
}

export default WithProvider
