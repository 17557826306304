import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { useReservationContext } from "../context"
import { getTranslatedOption, formatMoney, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

export default function CustomTable() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  moment.locale(locale)
  const {
    values: { resDetails, promotionNames },
  } = useReservationContext()
  const { reservation, rooms, resDays } = resDetails

  const currencySign = getCurrencySignById(reservation?.agentCurrency_id)

  return (
    <TableContainer component={Paper} id="dailyPrice">
      <Table>
        <TableHead>
          <TableRow className={styles.headRow}>
            <TableCell>{t("Date")}</TableCell>
            <TableCell>{t("Room Count")}</TableCell>
            <TableCell>{t("Price")}</TableCell>
            <TableCell>{t("Discount Description")}</TableCell>
            <TableCell>{t("Discount Rate")}</TableCell>
            <TableCell>{t("Nightly Price")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resDays?.map(item => {
            const room = rooms.find(r => r.room_id === item.room_id)
            let promotionIds = item.promotion_ids?.split(", ")?.map(id => +id)

            let pNames = []
            if (promotionNames.length > 0 && promotionIds) {
              promotionIds = [...new Set(promotionIds)]
              pNames = promotionIds?.map(id =>
                promotionNames.find(nameObj => nameObj.id === id)
              )
            }

            return (
              <TableRow className={styles.bodyRow} key={item.id}>
                <TableCell>{moment(item.date).format("L")}</TableCell>
                <TableCell>
                  {`${item.roomCount} x ${getTranslatedOption(
                    room,
                    "setRoomType",
                    locale
                  )}`}
                </TableCell>

                <TableCell>
                  {currencySign}
                  {formatMoney(item.price, 2)}
                </TableCell>

                <TableCell>
                  {pNames.map(i => (
                    <p key={i.id} className="mb-0">
                      {i.name}
                    </p>
                  ))}
                </TableCell>
                <TableCell>{item.discountRate}%</TableCell>
                <TableCell>
                  {currencySign}
                  {formatMoney(
                    item.price * item.roomCount * (1 - item.discountRate / 100),
                    2
                  )}
                </TableCell>
              </TableRow>
            )
          })}

          <TableRow
            className={styles.lightColor}
            sx={{ "& > td": { paddingTop: "15px !important" } }}
          >
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Subtotal")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentRoomPrices, 2)}
            </TableCell>
          </TableRow>
          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Taxes")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentTaxPrices, 2)}
            </TableCell>
          </TableRow>
          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Total")}</TableCell>
            <TableCell align="center">
              {currencySign}
              {formatMoney(reservation?.agentTotalPrices, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
