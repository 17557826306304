import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

const PrepaymentContext = createContext()
export const usePrepaymentContext = () => {
  const context = useContext(PrepaymentContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const PrepaymentProvider = props => {
  const [hasPrepayment, setHasPrepayment] = useState(true)
  const [hasVirtualCard, setHasVirtualCard] = useState(true)
  const [
    prepaymentBeforeFreeCancellation,
    setPrepaymentBeforeFreeCancellation,
  ] = useState(false)
  const [prepaymentPercent, setPrepaymentPercent] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
  const [returnDayCount, setReturnDayCount] = useState("")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const response = await request(
        {
          url: "/payment-methods",
        },
        false
      )

      const paymentMethods = response?.paymentMethods?.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
        value: i.id,
      }))

      setPaymentMethods(paymentMethods)
      return paymentMethods
    }

    ;(async () => {
      setLoading(true)
      const paymentMethods = await fetchPaymentMethods()
      await fetchPrepaymentPolicy(paymentMethods)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (paymentMethods) {
      const newPaymentMethods = paymentMethods.map(i => ({
        ...i,
        label: getLabelByLanguage(i, locale),
      }))
      setPaymentMethods(newPaymentMethods)

      if (selectedPaymentMethod) {
        setSelectedPaymentMethod(
          newPaymentMethods.find(i => i.value === selectedPaymentMethod.value)
        )
      }
    }
  }, [locale])

  const fetchPrepaymentPolicy = async paymentMethods => {
    const response = await request(
      {
        url: "/transfer-prepayment-policy",
      },
      false
    )
    if (response?.dbResult?.id) {
      const {
        hasPrepayment,
        hasVirtualCard,
        prepaymentBeforeFreeCancellation,
        prepaymentPercent,
        paymentMethod_id,
        returnDayCount,
      } = response.dbResult

      setHasPrepayment(hasPrepayment)
      setHasVirtualCard(hasVirtualCard !== null ? hasVirtualCard : true)
      setPrepaymentBeforeFreeCancellation(
        prepaymentBeforeFreeCancellation !== null
          ? prepaymentBeforeFreeCancellation
          : true
      )
      setPrepaymentPercent(prepaymentPercent || "")
      setReturnDayCount(returnDayCount || "")
      setSelectedPaymentMethod(
        paymentMethods.find(i => i.value === paymentMethod_id) || ""
      )
    }
  }

  const submitHandler = async () => {
    setErrorMessage("")
    // validation
    if (
      hasPrepayment &&
      (!prepaymentPercent || !selectedPaymentMethod || !returnDayCount)
    ) {
      return setErrorMessage("Please fill in the blanks!")
    }

    if (prepaymentPercent > 100) {
      return setErrorMessage(
        "Invalid value. The numbers can't be greater than 100%!"
      )
    }

    if (returnDayCount % 1 !== 0) {
      return setErrorMessage(`Invalid value. The day can't be decimal!`)
    }
    // end of validation

    // submit data
    let payload = {}
    if (hasPrepayment) {
      payload = {
        hasPrepayment,
        hasVirtualCard,
        prepaymentBeforeFreeCancellation,
        prepaymentPercent,
        paymentMethod_id: selectedPaymentMethod.value,
        returnDayCount,
      }
    } else {
      payload = {
        hasPrepayment,
      }
    }

    setSubmitLoading(true)
    const response = await request({
      url: "/transfer-prepayment-policy",
      method: "patch",
      data: payload,
    })
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
      fetchPrepaymentPolicy(paymentMethods)
    }
  }

  const value = useMemo(() => {
    const setters = {
      setHasPrepayment,
      setHasVirtualCard,
      setPrepaymentBeforeFreeCancellation,
      setPrepaymentPercent,
      setSelectedPaymentMethod,
      setReturnDayCount,
      setShowSuccessAlert,
      setLoading,
      setSubmitLoading,
      setErrorMessage,
    }
    const values = {
      hasPrepayment,
      hasVirtualCard,
      prepaymentBeforeFreeCancellation,
      prepaymentPercent,
      selectedPaymentMethod,
      returnDayCount,
      paymentMethods,
      showSuccessAlert,
      submitLoading,
      loading,
      errorMessage,
    }
    const functions = { submitHandler }
    return { setters, values, functions }
  }, [
    hasPrepayment,
    hasVirtualCard,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    selectedPaymentMethod,
    returnDayCount,
    paymentMethods,
    showSuccessAlert,
    submitLoading,
    loading,
    errorMessage,
  ])

  return <PrepaymentContext.Provider value={value} {...props} />
}
