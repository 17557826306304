import { BsTable } from "react-icons/bs"
import { HiMinus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"

import styles from "./styles.module.scss"

export default ({ data, deleteHandler }) => {
  const { t } = useTranslation()

  return (
    <Row className={styles.row}>
      <div className={`${styles.col} pl-2`}>
        {data?.selectedProperty?.label}
      </div>
      <div className={`${styles.col} text-center`}>
        {data?.selectedCurrency?.label}
      </div>
      <div className={`${styles.col} text-center`}>{data?.amount}</div>

      <div
        className={styles.deleteIcons}
        onClick={deleteHandler.bind(
          "",
          data?.selectedProperty?.value,
          data?.selectedCurrency?.value
        )}
        title={t("Delete")}
      >
        <HiMinus size={22} />
        <BsTable size={25} />
      </div>
    </Row>
  )
}
