import { BiBuildingHouse } from "react-icons/bi"
import { IoIosListBox } from "react-icons/io"
import { MdRoomPreferences } from "react-icons/md"
import { FaDollarSign } from "react-icons/fa"
import { FiPercent } from "react-icons/fi"
import { BsGraphUp, BsCalendar2CheckFill } from "react-icons/bs"

const hotelContent = [
  {
    id: 1001,
    label: "Hotel",
    icon: BiBuildingHouse,
    link: "/#",
    items: [
      {
        label: "Hotel Information",
        link: "/hotel-information",
        isMandatory: true,
      },
      {
        label: "Hotel Board Type",
        link: "/hotel-board-type",
        isMandatory: true,
      },
      {
        label: "Hotel Languages",
        link: "/hotel-languages",
      },
      {
        label: "Hotel Features",
        link: "/hotel-features",
        isMandatory: true,
      },
      {
        label: "Hotel Categories",
        link: "/hotel-categories",
      },
      {
        label: "Hotel Photos",
        link: "/hotel-photos",
        isMandatory: true,
      },
      {
        label: "About Hotel",
        link: "/about-hotel",
        isMandatory: true,
      },
      {
        label: "Hotel Payment Methods",
        link: "/hotel-payment-methods",
      },

      {
        label: "What's nearby?",
        link: "/hotel-place-distances",
      },
    ],
  },
  {
    id: 1002,
    label: "Rooms",
    icon: MdRoomPreferences,
    link: "/#",
    items: [
      {
        label: "Room Registration",
        link: "/hotel-room-registration",
        isMandatory: true,
      },
      {
        label: "Room Features",
        link: "/hotel-room-features",
        isMandatory: true,
      },
      {
        label: "Room Photos",
        link: "/hotel-room-photos",
        isMandatory: true,
      },
    ],
  },
  {
    id: 1003,
    label: "Policies",
    icon: IoIosListBox,
    link: "/#",
    items: [
      {
        label: "Cancellation Policy",
        link: "/hotel-cancellation-policy",
        isMandatory: true,
      },
      {
        label: "Payment Policy",
        link: "/hotel-prepayment-policy",
        isMandatory: true,
      },
      {
        label: "Country Policy",
        link: "/country-policy",
      },
      {
        label: "Guest Info Policy",
        link: "/hotel-guest-info-policy",
      },
      {
        label: "Tax Policy",
        link: "/hotel-kdv-tax-fees",
        isMandatory: true,
      },
      {
        label: "Check-in/Check-out Conditions",
        link: "/hotel-in-out-conditions",
        isMandatory: true,
      },
      {
        label: "Pet Policy",
        link: "/hotel-pet-policy",
      },
    ],
  },
  {
    id: 1004,
    label: "Child Policies",
    icon: IoIosListBox,
    link: "/#",
    items: [
      {
        label: "Child Policy",
        link: "/hotel-child-policy",
        isMandatory: true,
      },
      {
        label: "Child Pricing Policy",
        link: "/hotel-child-pricing-policy",
        isMandatory: true,
      },
    ],
  },

  {
    id: 1005,
    label: "Marketing",
    icon: FaDollarSign,
    link: "/#",
    items: [
      {
        label: "Overall View",
        link: "/hotel-room-overall-view",
      },
      {
        label: "Pricing",
        link: "/hotel-room-pricing",
        isMandatory: true,
      },
      {
        label: "Availability Status",
        link: "/hotel-room-availability",
        isMandatory: true,
      },
      {
        label: "Room Quota",
        link: "/hotel-room-quota",
        isMandatory: true,
      },
      {
        label: "Rate Conditions",
        link: "/hotel-room-rate-conditions",
        isMandatory: true,
      },
    ],
  },
  {
    id: 1006,
    label: "Promotions",
    icon: FiPercent,
    link: "/#",
    items: [
      { label: "Choose New Promotion", link: "/hotel-promotions" },
      { label: "Your Promotions", link: "/hotel-your-promotions" },
    ],
  },
  {
    id: 1007,
    label: "Opportunities",
    icon: BsGraphUp,
    link: "/#",
    items: [
      { label: "Influencer Marketing", link: "/hotel-influencer-marketing" },
      {
        label: "Search Engine Marketing",
        link: "/hotel-search-engine-marketing",
      },
      {
        label: "Social Network Marketing",
        link: "/hotel-social-network-marketing",
      },
      {
        label: "Your Opportunities",
        link: "/hotel-your-opportunities",
      },
    ],
  },
  {
    id: 1008,
    label: "Reservations",
    icon: BsCalendar2CheckFill,
    link: "/#",
    items: [{ label: "Reservations", link: "/hotel-room-reservations" }],
  },
]

export default hotelContent
