import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import { getLabelByLanguage } from "utils/other"

import styles from "./styles.module.scss"

const HotelCategories = () => {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [holidayThemes, setHolidayThemes] = useState([])
  const [hotel_id, setHotel_id] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setShowHotelNotRegWarning(true)
        setLoading(false)
        return
      }

      const holidayThemes = await fetchHolidayThemes()
      await fetchHotel_HolidayThemes(holidayThemes, hotel_id)
      setLoading(false)
    })()
  }, [])

  const fetchHolidayThemes = async () => {
    const response = await request(
      {
        url: "/hotel-holiday-themes/set-holiday-theme",
      },
      false
    )

    console.log(response?.dbResult)
    const holidayThemes = response?.dbResult?.map(i => ({
      ...i,
      title: getLabelByLanguage(i, locale),
      isChecked: false,
    }))
    setHolidayThemes(holidayThemes)
    return holidayThemes
  }

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      setHotel_id(response.dbResult.id)
      return response.dbResult.id
    }
  }

  const fetchHotel_HolidayThemes = async (holidayThemes, hotel_id) => {
    const response = await request(
      {
        url: "/hotel-holiday-themes",
        params: { hotel_id },
      },
      false
    )

    const hotel_holidayThemes = response?.dbResult
    const updatedHolidayThemes = [...holidayThemes]

    hotel_holidayThemes?.forEach(i => {
      for (let j = 0; j < updatedHolidayThemes.length; j++) {
        const holidayTheme = updatedHolidayThemes[j]
        if (i.holidayTheme_id === holidayTheme.id) {
          updatedHolidayThemes[j] = { ...holidayTheme, isChecked: true }
          break
        }
      }
    })

    setHolidayThemes(updatedHolidayThemes)
  }

  useEffect(() => {
    const updatedHolidayThemes = holidayThemes?.map(item => {
      return {
        ...item,
        title: getLabelByLanguage(item, locale),
      }
    })

    setHolidayThemes(updatedHolidayThemes)
  }, [locale, holidayThemes?.length])

  const checkHandler = id => {
    const updatedHolidayThemes = [...holidayThemes]
    const index = updatedHolidayThemes.findIndex(item => item.id === id)
    const holidayTheme = updatedHolidayThemes[index]
    updatedHolidayThemes[index] = {
      ...holidayTheme,
      isChecked: !holidayTheme.isChecked,
    }
    setHolidayThemes(updatedHolidayThemes)
  }
  const submitHandler = async () => {
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }

    const dataList = []
    holidayThemes.forEach(i => {
      if (i.isChecked) {
        dataList.push({
          holidayTheme_id: i.id,
          hotel_id,
        })
      }
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-holiday-themes",
        method: "put",
        data: { dataList, hotel_id },
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response?.message)
    }
  }
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Hotel Categories")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <p className={styles.subtitle}>
              {t(
                "Please specify your facility theme types. You can select multiple themes."
              )}
            </p>
            <Row className="mx-0 mt-4">
              {holidayThemes.map(item => (
                <FormGroup key={item.id} className={styles.checkboxContainer}>
                  <div
                    onClick={() => checkHandler(item.id)}
                    className={styles.checkboxWrapper}
                  >
                    <Input
                      type="checkbox"
                      className={styles.checkbox}
                      name={item.title}
                      id={item.id}
                      onChange={() => {}}
                      checked={item.isChecked || false}
                    />
                    <span className="ml-2">{item.title}</span>
                  </div>
                </FormGroup>
              ))}
            </Row>
            {HotelNotRegisteredComp}
          </CardBody>
        </Card>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />
        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

export default HotelCategories
