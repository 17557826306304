import { AiOutlineClockCircle } from "react-icons/ai"
import { HiMinus } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"

import styles from "../styles.module.scss"

export default ({ selectedTime, onDelete }) => {
  const { t } = useTranslation()

  return (
    <Row form>
      <Col sm={6} className={styles.timeItem_Label}>
        {selectedTime}
      </Col>
      <Col>
        <div className="row m-0">
          <span
            className={styles.iconsDel}
            onClick={onDelete.bind("", selectedTime.value)}
            title={t("Delete")}
          >
            <HiMinus size={22} />
            <AiOutlineClockCircle size={35} className={styles.lastIcon} />
          </span>
        </div>
      </Col>
    </Row>
  )
}
