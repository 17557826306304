import { Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"

import { usePrepaymentContext } from "./context"
import styles from "./styles.module.scss"

const ConditionInputs = () => {
  const { t } = useTranslation()
  const { values, setters } = usePrepaymentContext()
  const {
    hasPrepayment,
    hasVirtualCard,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    paymentMethods,
    selectedPaymentMethod,
    returnDayCount,
  } = values
  const {
    setHasPrepayment,
    setHasVirtualCard,
    setPrepaymentBeforeFreeCancellation,
    setPrepaymentPercent,
    setSelectedPaymentMethod,
    setReturnDayCount,
  } = setters
  return (
    <div className={styles.conditionsContainer}>
      {/* FIRST Q */}
      <div className={styles.questionContainer}>
        <p>{t("Do you want to get paid before the guest arrives?")}</p>
        <div className={styles.buttons}>
          <label htmlFor="hasPrepaymentNo">
            <input
              type="radio"
              id="hasPrepaymentNo"
              name="hasPrepayment"
              value={hasPrepayment}
              checked={hasPrepayment === false}
              onChange={() => setHasPrepayment(false)}
            />
            {t("No")}
          </label>
          <label htmlFor="hasPrepaymentYes">
            <input
              id="hasPrepaymentYes"
              type="radio"
              name="hasPrepayment"
              checked={hasPrepayment === true}
              value={hasPrepayment}
              onChange={() => setHasPrepayment(true)}
            />
            {t("Yes")}
          </label>
        </div>
      </div>
      {hasPrepayment && (
        <>
          {" "}
          {/* SECOND Q */}
          <div className={styles.questionContainer}>
            <p>
              {t("Can you receive payment by virtual card at the facility?")}
            </p>
            <div className={styles.buttons}>
              <label htmlFor="hasVirtualCardNo">
                <input
                  type="radio"
                  id="hasVirtualCardNo"
                  name="hasVirtualCard"
                  value={hasVirtualCard}
                  checked={hasVirtualCard === false}
                  onChange={() => setHasVirtualCard(false)}
                />
                {t("No")}
              </label>
              <label htmlFor="hasVirtualCardYes">
                <input
                  id="hasVirtualCardYes"
                  type="radio"
                  name="hasVirtualCard"
                  checked={hasVirtualCard === true}
                  value={hasVirtualCard}
                  onChange={() => setHasVirtualCard(true)}
                />
                {t("Yes")}
              </label>
            </div>
          </div>
          <div className={styles.questionContainer}>
            <p>{t("When do you want to receive the payment?")}</p>
            <div className={styles.buttons}>
              <label htmlFor="afterFreeCancellationEnds">
                <input
                  id="afterFreeCancellationEnds"
                  type="radio"
                  name="prepaymentBeforeFreeCancellation"
                  value={prepaymentBeforeFreeCancellation}
                  checked={prepaymentBeforeFreeCancellation === false}
                  onChange={() => setPrepaymentBeforeFreeCancellation(false)}
                />
                {t("After Free Cancellation Ends")}
              </label>
            </div>
          </div>
          <div className={styles.thirdQuestion}>
            <Label>
              {t(
                "What percentage of the total fee would you like to receive as a payment?"
              )}
            </Label>
            <Input
              type="number"
              value={prepaymentPercent}
              onChange={e => {
                const inputValue = e.target.value;
                if (inputValue >= 0 && inputValue <= 100) {
                  setPrepaymentPercent(inputValue);
                }            
              }}
              max={100}
              min={0}
            />
            %
          </div>
          <div className={styles.lastRow}>
            <div className={styles.col1}>
              <Label>{t("How will you receive the payment?")}</Label>
              <Select
                placeholder=""
                value={selectedPaymentMethod}
                noOptionsMessage={() => t("No Options")}
                options={paymentMethods}
                onChange={setSelectedPaymentMethod}
              />
            </div>
          </div>
          <div className={styles.lastRow}>
            <div className={styles.col2}>
              <Label>
                {t(
                  "In case of cancellation of paid reservations, how long will it take for the payment to be refunded?"
                )}
              </Label>
              <div className={styles.row}>
                {" "}
                <Input
                  type="number"
                  value={returnDayCount}
                  onChange={e =>
                    setReturnDayCount(e.target.value?.replace(/[^0-9]/g, ""))
                  }
                  min={0}
                  max={100}
                  step={1}
                />
                {t("days_")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ConditionInputs
