import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import WidgetPagination from "components/Common/WidgetPagination/index"
import { useReservationContext } from "../context"
import { formatDate, formatDateTime } from "utils/dateTimeFunctions"
import { formatMoney, getCurrencySignById } from "utils/other"
import styles from "./style.module.scss"

const CustomTable = () => {
  const { t } = useTranslation()
  const { values, setters } = useReservationContext()
  let {
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    reservations,
    roomTypes,
    roomNames,
  } = values
  const { setActivePage } = setters

  const ReservationRows = reservations?.map(item => {
    const path = `/hotel-room-reservation-details/${item.id}`
    const rooms = item.rooms?.map(room => {
      const roomType = roomTypes?.find(i => i.id === room.setRoomType_id)?.name
      const roomName = roomNames?.find(i => i.id === room.setRoomName_id)?.name
      return { ...room, name: `${roomType} - ${roomName}` }
    })

    return (
      <Tr key={item.id}>
        <Td className={styles.dataCell}>
          <Link to={path}>{`${item.name} ${item.surname}`}</Link>
        </Td>
        <Td className={styles.dataCell}>
          {`${item.adultCount} ${t("adult")}`}
          {item.childCount > 0 && `, ${item.childCount} ${t("child")}`}
        </Td>
        <Td className={styles.dataCell}>{formatDate(item.checkIn)}</Td>
        <Td className={styles.dataCell}>{formatDate(item.checkOut)}</Td>
        <Td className={styles.dataCell}>
          {rooms?.map(room => (
            <p
              key={room.room_id}
              className="mb-0"
            >{`${room.roomCount} x ${room.name}`}</p>
          ))}
        </Td>
        <Td className={styles.dataCell}>{formatDateTime(item.resDate)}</Td>
        <Td className={styles.dataCell}>{t(item.resStatusName)}</Td>
        <Td className={styles.dataCell}>{`${getCurrencySignById(item.agentCurrency_id)} ${formatMoney(
          item.agentTotalPrices,
          2
        )}`}</Td>
        <Td className={styles.dataCell}>
          <Link to={path}>{item.resNo}</Link>
        </Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0 data-table-container">
        <Table id="main-table" className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.tableData}>{t("Guest Name")}</Th>
              <Th className={styles.tableData}>{t("Guest Count")}</Th>
              <Th className={styles.tableData}>{t("Check-in Date")}</Th>
              <Th className={styles.tableData}>{t("Check-out Date")}</Th>
              <Th className={styles.tableData}>{t("Room Name")}</Th>
              <Th className={styles.tableData}>{t("Reservation Date")}</Th>
              <Th className={styles.tableData}>{t("Status")}</Th>
              <Th className={styles.tableData}>{t("Total Price")}</Th>
              <Th className={styles.tableData}>{t("Reservation Number")}</Th>
            </Tr>
          </Thead>

          <Tbody>{ReservationRows}</Tbody>
        </Table>
      </div>

      <WidgetPagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={setActivePage}
      />
    </div>
  )
}

export default CustomTable
