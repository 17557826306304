import { Fragment } from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { IoCheckmarkDoneOutline } from "react-icons/io5"
import { AiOutlineClockCircle } from "react-icons/ai"
import { HiOutlineDotsVertical } from "react-icons/hi"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { momentLocale } from "utils/dateTimeFunctions"
import styles from "./messageList.module.scss"

const MessageItem = ({ messages, deleteMessage }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const user = useSelector(state => state.User.currentUser)
  let formattedDate = ""
  let showDate = false
  let unreadShown = false

  return (
    <ul className={styles.container}>
      {messages?.map(message => {
        if (!message.showStatus) {
          return null
        }
        showDate = false
        const newFormattedDate = momentLocale(locale)(message.createdAt).format(
          "LL"
        )
        if (formattedDate !== newFormattedDate) {
          formattedDate = newFormattedDate
          showDate = true
        }

        const isMyMessage = !message.masterUser_id
        let showUnread = !unreadShown && !message.readStatus && !isMyMessage
        if (showUnread) {
          unreadShown = true
        }
        return (
          <Fragment key={message.id}>
            {showDate && (
              <li>
                <div className={styles.chatDayTitle}>
                  <span>{formattedDate}</span>
                </div>
              </li>
            )}
            {showUnread && (
              <li>
                <div className={styles.chatDayTitle}>
                  <span>{t("Unread Messages")}</span>
                </div>
              </li>
            )}
            <li className={isMyMessage ? styles.right : ""}>
              <div className={styles.messageItem}>
                {isMyMessage && (
                  <UncontrolledDropdown>
                    <DropdownToggle className="btn nav-btn" tag="i">
                      <HiOutlineDotsVertical />
                    </DropdownToggle>
                    <DropdownMenu
                      className={styles.dropdownMenu}
                      right={false}
                      flip={false}
                    >
                      <DropdownItem
                        onClick={deleteMessage.bind(null, message.id)}
                      >
                        {t("Delete")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                <div className={styles.messageContent}>
                  <h3>{isMyMessage ? message.companyUserName : "Admin"}</h3>
                  <p>{message.message}</p>
                  <p className={styles.time}>
                    <span>
                      <AiOutlineClockCircle />
                      {momentLocale(locale)(message.createdAt).format("hh:mm")}
                    </span>
                    {isMyMessage && (
                      <IoCheckmarkDoneOutline
                        size={19}
                        className="ml-2"
                        color={message.readStatus ? "#264bfb" : ""}
                      />
                    )}
                  </p>
                </div>
              </div>
            </li>
          </Fragment>
        )
      })}
    </ul>
  )
}

export default MessageItem
