import React from "react"
import { useTranslation } from "react-i18next"

import { usePrepaymentContext } from "./context"
import styles from "./styles.module.scss"

const PolicySummary = () => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const { values } = usePrepaymentContext()
  const {
    hasPrepayment,
    prepaymentBeforeFreeCancellation,
    prepaymentPercent,
    returnDayCount,
  } = values

  if (hasPrepayment && (!prepaymentPercent || !returnDayCount)) {
    return (
      <div className={styles.policySummary}>
        <p className={styles.validationalert}>
          {t("Please enter the conditions in the fields mentioned above.")}
        </p>
      </div>
    )
  }

  let summary = ""
  if (locale === "tr") {
    if (!hasPrepayment) {
      summary +=
        "Rezervasyonun cout tarihi sonunda faturası vihobook tarafına iletildiği zaman 5 iş gününde ödenecektir."
    } else {
      summary += `Toplam ücretin %${prepaymentPercent}'si ücretsiz iptal  ${
        prepaymentBeforeFreeCancellation
          ? "sona ermeden önce"
          : "sona erdikten sonra"
      } ödenir`
      summary +=
        prepaymentPercent < 100
          ? `, Kalanı %${
              100 - prepaymentPercent
            } si rezervasyonun cout tarihi sonunda faturası vihobook tarafına iletildiği zaman 5 iş gününde ödenecektir. `
          : ". "
      summary += `Rezervasyon İptal edilmesi durumunda yapılmış olan ödeme tesis tarafından ${returnDayCount} iş günü içerisinde vihobook iade edilecektir.`
    }
  } else {
    if (!hasPrepayment) {
      summary +=
        "The payment will be made within 5 working days after the invoice is sent to Vihobook at the end of the reservation's cout date."
    } else {
      summary += `${prepaymentPercent}% of the total fee is paid ${
        prepaymentBeforeFreeCancellation ? "before" : "after"
      }  the free cancellation ends`
      summary +=
        prepaymentPercent < 100
          ? `, the remaining ${
              100 - prepaymentPercent
            }% will be paid in 5 working days when the invoice is sent to Vihobook at the end of the cout date of the reservation. `
          : ". "
      summary += `In case of Cancellation of the reservation, the payment will be refunded by the facility within ${returnDayCount} working days.`
    }
  }

  return (
    <div className={styles.policySummary}>
      <h6>{t("Policy Summary")}</h6>
      <p>{summary}</p>
    </div>
  )
}

export default PolicySummary
