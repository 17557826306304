import React, { useState } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { useTranslation } from "react-i18next"

const PasswordInput = ({ value, onChange, className }) => {
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  function togglePasswordVisibility(e) {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  return (
    <div
      className={`passwordInput position-relative w-100 ${className}`}
    >
      <input
        className="w-100 py-3 px-3 border border-secondary rounded"
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={t("Password")}
        required
      />
      <button
        className="position-absolute h-100 focus:outline-none"
        onClick={e => togglePasswordVisibility(e)}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  )
}

export default PasswordInput
