import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Modal, Alert, Input } from "reactstrap"
import useRequest from "hooks/useRequest"
import SuccessAlert from "components/Common/SuccessAlert/index"
import CustomDropZone from "./CustomDropZone"
import styles from "./styles.module.scss"
import { useSelector } from "react-redux"
import axios from "axios"

const AddEditDocuments = props => {
  const companyId = useSelector(state => state.Company.company.id)
  const [fileRef, setFileRef] = useState(null)
  const [fileTitle, setFileTitle] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState("")

  const { isOpen, close, modalTitle, refreshTable, document, isAddOperation, fileId } =
    props
  const { t } = useTranslation()
  const token = useSelector(state => state.Login.token)
  const { request } = useRequest(setLoading, setErrorMessage)

  const fetchDocument = async id => {
    const response = await request(
      {
        url: "/company-documents/fetch-by-id",
        params: { id },
      },
      false
    )
    setFileTitle(response.dbResult.fileTitle)
    setFileRef(response.dbResult.fileRef)
  }

  useEffect(() => {
    if (!isAddOperation) {
      fetchDocument(document.id)
    }
  }, [])

  const submitDocument = async (method, payload) => {
    setSubmitLoading(true)

    const response = await axios.request({
      url: "/company-documents",
      method,
      data: payload,

      headers: {
        Authorization: "Bearer " + token,
      },
    })

    setSubmitLoading(false)
    if (response?.data?.result) {
      setShowSuccessAlert(true)
    }
  }

  const submitData = () => {
    if (fileTitle === "" || fileRef === null) {
      setErrorMessage(t("Please fill in the blanks!"))
      return
    }
    const formData = new FormData()
    formData.append("fileTitle", fileTitle)
    formData.append("companyDocument", fileRef)
    formData.append("companyId", companyId)
    
    if(!isAddOperation){
      formData.append("id", fileId)
    }
    
    const method = isAddOperation ? "post" : "put"
    submitDocument(method, formData)
  }

  return (
    <Modal isOpen={isOpen} scrollable={true} size="xl" toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body min-height-240">
        <form className={styles.form}>
          <div className={styles.titleContainer}>
            <label>{t("Document Title")}</label>
            <Input
              type="text"
              name="fileTitle"
              value={fileTitle}
              onChange={e => setFileTitle(e.target.value)}
              placeholder={t("Enter Title")}
            />
          </div>
          <CustomDropZone fileRef={fileRef} setFileRef={setFileRef} />
          {errorMessage && <Alert color="danger"> {t(errorMessage)}</Alert>}
          <div className={styles.buttons}>
            <button type="button" className="btn btn-secondary" onClick={close}>
              {t("Close")}
            </button>
            <button
              type="button"
              className={`btn btn-primary`}
              onClick={submitData}
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </div>

      {showSuccessAlert && (
        <SuccessAlert
          hideSuccessAlert={() => {
            setShowSuccessAlert(false)
            refreshTable()
            close()
          }}
        />
      )}
    </Modal>
  )
}

export default AddEditDocuments
