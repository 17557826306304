import { Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import { useCancellationContext } from "./context"
import styles from "./styles.module.scss"

const ConditionInputs = () => {
  const { t } = useTranslation()
  const { values, setters } = useCancellationContext()
  const {
    freeCancelDayCountBeforeArrival,
    cancelChargePercent,
    unusedChargePercent,
    freeCancellable,
    hasSignedContract,
  } = values
  const {
    setFreeCancelDayCountBeforeArrival,
    setCancelChargePercent,
    setUnusedChargePercent,
  } = setters

  return (
    <div className={styles.conditionsContainer}>
      <h6>{t("Specify Conditions")}</h6>
      {freeCancellable && (
        <>
          <div className={`mb-3 ${hasSignedContract && styles.disabled}`}>
            <Label>
              {t(
                "How long before arrival can the guest cancel free of charge?"
              )}
            </Label>
            <Input
              type="number"
              onChange={e =>
                setFreeCancelDayCountBeforeArrival(
                  e.target.value?.replace(/[^0-9]/g, "")
                )
              }
              disabled={hasSignedContract}
              value={freeCancelDayCountBeforeArrival}
              max={30}
              min={0}
              step={1}
            />
            {t("days_")}
          </div>
          <div className={`mb-3 ${hasSignedContract && styles.disabled}`}>
            <Label>
              {t(
                "What percentage of the total price will be charged to the guest whose cancellation date has passed?"
              )}
            </Label>
            <Input
              type="number"
              disabled={hasSignedContract}
              onChange={e => {
                const inputValue = e.target.value
                if (inputValue >= 0 && inputValue <= 100) {
                  setCancelChargePercent(inputValue)
                }
              }}
              value={cancelChargePercent}
              max={100}
              min={0}
            />
            %
          </div>
        </>
      )}
      {!freeCancellable && (
        <div className={`mb-3 ${hasSignedContract && styles.disabled}`}>
          <Label>{t("How much is the guest charged for cancellation?")}</Label>
          <Input
            type="number"
            disabled={hasSignedContract}
            onChange={e => {
              const inputValue = e.target.value
              if (inputValue >= 0 && inputValue <= 100) {
                setCancelChargePercent(inputValue)
              }
            }}
            value={cancelChargePercent}
            max={100}
            min={0}
          />
          %
        </div>
      )}
      <div className={`mb-3 ${hasSignedContract && styles.disabled}`}>
        <Label>
          {t(
            "What percentage of the total price will be charged to guests when the reservation is not used?"
          )}
        </Label>
        <Input
          type="number"
          disabled={hasSignedContract}
          onChange={e => {
            const inputValue = e.target.value
            if (inputValue >= 0 && inputValue <= 100) {
              setUnusedChargePercent(inputValue)
            }
          }}
          value={unusedChargePercent}
          max={100}
          min={0}
        />
        %
      </div>
    </div>
  )
}

export default ConditionInputs
