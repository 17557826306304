import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { useReservationContext } from "../context"
import WidgetPagination from "components/Common/WidgetPagination/index"
import { formatDate, formatDateTime } from "utils/dateTimeFunctions"
import {
  formatMoney,
  getCurrencySignById,
  getTranslatedOption,
} from "utils/other"
import styles from "./style.module.scss"

const CustomTable = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation()
  const { values, setters } = useReservationContext()
  const { activePage, totalItemsCount, itemsCountPerPage, reservations } =
    values
  const { setActivePage } = setters

  const ReservationRows = reservations?.map(item => {
    const path = `/transfer-reservation-details/${item.id}`
    return (
      <Tr key={item.id}>
        <Td className={styles.dataCell}>
          <Link
            to={path}
          >{`${item.passengerName} ${item.passengerSurname}`}</Link>
        </Td>
        <Td className={styles.dataCell}>{formatDate(item.resDate)}</Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "vehicleType", locale)}
        </Td>
        <Td className={styles.dataCell}>{item.vehicleCount}</Td>
        <Td className={styles.dataCell}>
          {formatDateTime(item.transferDateTime)}
        </Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "pickupLocation", locale)}
        </Td>
        <Td className={styles.dataCell}>
          {getTranslatedOption(item, "dropOffLocation", locale)}
        </Td>
        <Td className={styles.dataCell}>{t(item.resStatus)}</Td>
        <Td className={styles.dataCell}>
          {`${getCurrencySignById(2)} ${formatMoney(item.agentTotalPrice, 2)}`}
        </Td>
        <Td className={styles.dataCell}>
          {`${getCurrencySignById(2)} ${formatMoney(
            item.agentTotalPrice * (item.commissionRate / 100),
            2
          )}`}
        </Td>
        <Td className={styles.dataCell}>
          <Link to={path}>{item.resNo}</Link>
        </Td>
      </Tr>
    )
  })

  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0 data-table-container">
        <Table id="main-table" className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className={styles.tableData}>{t("Passenger Name")}</Th>
              <Th className={styles.tableData}>{t("Reservation Date")}</Th>
              <Th className={styles.tableData}>{t("Vehicle Type")}</Th>
              <Th className={styles.tableData}>{t("Vehicle Count")}</Th>
              <Th className={styles.tableData}>{t("Transfer Date")}</Th>
              <Th className={styles.tableData}>{t("Pickup Location")}</Th>
              <Th className={styles.tableData}>{t("Drop Off Location")}</Th>
              <Th className={styles.tableData}>{t("Status")}</Th>
              <Th className={styles.tableData}>{t("Price")}</Th>
              <Th className={styles.tableData}>{t("Commission")}</Th>
              <Th className={styles.tableData}>{t("Reservation Number")}</Th>
            </Tr>
          </Thead>

          <Tbody>{ReservationRows}</Tbody>
        </Table>
      </div>

      <WidgetPagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={setActivePage}
      />
    </div>
  )
}

export default CustomTable
