import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  AUTO_LOGIN,
  AUTO_LOGOUT,
} from "./actionTypes"

export const loginUser = (user, history, isRememberMeChecked) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, isRememberMeChecked },
  }
}

export const loginSuccess = (userId, token) => {
  return {
    type: LOGIN_SUCCESS,
    userId: userId,
    token: token,
  }
}
export const autoLogin = history => {
  return {
    type: AUTO_LOGIN,
    history: history,
  }
}
export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    history: history,
  }
}

export const autoLogout = (expiresIn, history) => {
  return {
    type: AUTO_LOGOUT,
    history: history,
    expiresIn: expiresIn,
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  }
}

export const apiError = errorMessage => {
  return {
    type: API_ERROR,
    errorMessage: errorMessage,
  }
}
