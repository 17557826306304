import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"
import Select from "react-select"

import { useGuestInfoContext } from "./context"
import styles from "./styles.module.scss"

const Curfew = () => {
  const { t } = useTranslation()
  const { values, setters } = useGuestInfoContext()
  const { hasCurfew, times, selectedStartTime, selectedEndTime } = values
  const { setHasCurfew, setSelectedStartTime, setSelectedEndTime } = setters
  return (
    <section className={styles.section}>
      <h4>{t("Curfew")}</h4>
      <p>
        {t(
          "A curfew is a policy that limits the hours you guests can leave or enter your property."
        )}
      </p>
      {/* FIRST Q */}
      <div className={styles.questionContainer}>
        <p>{t("Is there a curfew for guests?")}</p>
        <div className={styles.buttons}>
          <label htmlFor="curfewYes">
            <input
              id="curfewYes"
              type="radio"
              name="hasCurfew"
              checked={hasCurfew === true}
              value={hasCurfew}
              onChange={() => setHasCurfew(true)}
            />
            {t("Yes")}
          </label>
          <label htmlFor="curfewNo">
            <input
              id="curfewNo"
              type="radio"
              name="hasCurfew"
              value={hasCurfew}
              checked={hasCurfew === false}
              onChange={() => setHasCurfew(false)}
            />
            {t("No")}
          </label>
        </div>
      </div>
      {hasCurfew && (
        <div className={styles.row}>
          <div className={styles.col}>
            <Label className="mb-1">{t("Start time")}</Label>
            <Select
              placeholder={t("Select...")}
              noOptionsMessage={() => t("No Options")}
              value={selectedStartTime}
              options={times}
              onChange={setSelectedStartTime}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
          <div className={styles.col}>
            <Label className="mb-1">{t("End time")}</Label>
            <Select
              placeholder={t("Select...")}
              noOptionsMessage={() => t("No Options")}
              value={selectedEndTime}
              options={times}
              onChange={setSelectedEndTime}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
        </div>
      )}
    </section>
  )
}

export default Curfew
