import { Spinner } from "reactstrap"
import styles from "./styles.module.scss"

export default props => {
  return (
    <div className={styles.container}>
      <Spinner color={props.color ? props.color : "secondary"} />
    </div>
  )
}
