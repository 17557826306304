import React from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material"

import styles from "./styles.module.scss"

const PromotionDetails = props => {
  const { members, setMembers } = props
  const { t } = useTranslation()
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Promotion Details")}</h4>
        <h5 className={styles.cardSubtitle}>
          {t("Who will be able to see this promotion?")}
        </h5>
        <FormControl component="fieldset" className={styles.formControl}>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={members}
            onChange={(e, v) => setMembers(v)}
          >
            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("Everyone")}
              className={styles.radioLabel}
              value={false}
            />

            <FormControlLabel
              control={<Radio className={styles.radio} />}
              label={t("Members and newsletter subscribers only")}
              className={styles.radioLabel}
              value={true}
            />
          </RadioGroup>
        </FormControl>
      </CardBody>
    </Card>
  )
}
export default PromotionDetails
