import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { loginUser } from "store/actions"

import LanguageDropdown from "components/Common/Layouts/TopbarDropdown/LanguageDropdown"
import TACModal from "./TAC"
import PPModal from "./PrivacyPolicy"
import * as cookie from "utils/cookie"
import faq from "assets/images/faq.png"
import vihobookLogo from "assets/images/vBlueLogo.png"

import FAQ from "./FAQ"
import PasswordInput from "./PasswordInput"
import AuthButton from "./AuthButton"
import styles from "./auth-section.module.scss"

const Login = ({ handlePageChange }) => {
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true)
  const [showFAQ, setShowFAQ] = useState(false)
  const [companyCode, setCompanyCode] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showTAC, setShowTAC] = useState(false)
  const [showPP, setShowPP] = useState(false)

  const {
    t,
    i18n: { language: locale },
  } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, errorMessage } = useSelector(state => state.Login)

  const handleValidSubmit = (event) => {
    event.preventDefault()
    const values = { companyCode, email, password, isRememberMeChecked }
    dispatch(loginUser(values, history, isRememberMeChecked))
  }

  const checkHandler = () => {
    setIsRememberMeChecked(prev => !prev)
  }

  const propagateUserCookieData = () => {
    const companyCode = cookie.getCookie("companyCode")
    const email = cookie.getCookie("email")
    const password = cookie.getCookie("password")

    if (companyCode && email && password) {
      setCompanyCode(companyCode)
      setEmail(email)
      setPassword(password)
    }
  }

  useEffect(() => {
    propagateUserCookieData()
  }, [])

  const tacHandler = e => {
    e.preventDefault()
    setShowTAC(true)
  }

  const ppHandler = e => {
    e.preventDefault()
    setShowPP(true)
  }

  const faqClickHandler = e => {
    e.preventDefault()
    setShowFAQ(true)
  }

  let TAC = null
  switch (locale) {
    case "en":
      TAC = (
        <p className={styles.tac}>
          By signing in or creating an account, you agree with our
          <Link to="/#" onClick={tacHandler}>
            Terms & Conditions
          </Link>
          and
          <Link to="/#" onClick={ppHandler}>
            Privacy Statement
          </Link>
        </p>
      )
      break
    case "tr":
      TAC = (
        <p className={styles.tac}>
          Bir hesaba giriş yaparak ya da hesap oluşturarak
          <Link to="/#" onClick={tacHandler}>
            Şartlar ve Koşullarımız
          </Link>
          ile
          <Link to="/#" onClick={ppHandler}>
            Gizlilik Bildirimimizi
          </Link>
          kabul etmiş olursunuz
        </p>
      )
      break
    default:
      TAC = (
        <p className={styles.tac}>
          By signing in or creating an account, you agree with our
          <Link to="/#" onClick={tacHandler}>
            Terms & Conditions
          </Link>
          and
          <Link to="/#" onClick={ppHandler}>
            Privacy Statement
          </Link>
        </p>
      )
      break
  }

  return (
    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
      <div className="language-dropdown">
        <LanguageDropdown color="black" />
      </div>
    
      {showTAC && <TACModal toggle={() => setShowTAC(prev => !prev)} />}
      {showPP && <PPModal toggle={() => setShowPP(prev => !prev)} />}
      <form
        onSubmit={handleValidSubmit}
        className={`loginForm d-flex flex-column justify-content-center align-items-center`}
      >
        <img
          src={vihobookLogo}
          alt="Vihobook Logo"
          className={`mb-4 logo`}
        />
        <div>
          <input
            className="w-100 mb-3 py-3 px-3 border border-secondary rounded"
            type="text"
            id="companyCode"
            name="companyCode"
            value={companyCode}
            onChange={e => setCompanyCode(e.target.value)}
            placeholder={t("Company Code")}
            required
          />
          <input
            className="w-100 mb-3 py-3 px-3 border border-secondary rounded"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={t("Email")}
            required
          />
          <PasswordInput
            value={password}
            onChange={setPassword}
            className={"mb-4"}
          />
          <div className="d-flex w-100 justify-content-between mb-3">
            <label
              htmlFor="checkbox"
              className={`checkbox d-flex align-items-center text-base cursor-pointer text-gray`}
            >
              <input
                id="checkbox"
                type="checkbox"
                name="remember"
                checked={isRememberMeChecked}
                onChange={checkHandler}
                className={`checkbox mr-1`}
              />
              {t("Remember me")}
            </label>

            <Link to="/forgot-password">{t("Forgot your password?")}</Link>
          </div>
          <AuthButton
            className={`signBtn w-100 rounded mt-12`}
            onClick={() => {}}
            submitLoading={loading}
            submitText={t("Loading...")}
          >
            {t("Login")}
          </AuthButton>
        </div>
      </form>
      <div className={`already mt-2`}>
        <p className="text-center" onClick={handlePageChange}>
          {t("Don't have an account?")}
        </p>
      </div>
      <div className={styles.w60}>
        <div className={`mt-3 text-center errorTxt`}>
          {errorMessage && <p className="text-center">{t(errorMessage)}</p>}
        </div>
        {TAC}
        {/* <div className={styles.faq}>
          <img src={faq} alt="faq" />
          <a href="/#" onClick={faqClickHandler}>
            {t("Frequently asked questions")}
          </a>
          {showFAQ && <FAQ toggle={setShowFAQ.bind(null, false)} />}
        </div> */}
      </div>
    </div>
  )
}

export default Login
