import React from "react"
import { useSelector } from "react-redux"

// Pages Components
import Hotel from "./Hotel"
import Villa from "./Villa"
import Transfer from "./Transfer"
import Ticket from "./Ticket"

const Home = () => {
  const company = useSelector(state => state?.Company?.company)
  if (!company?.types || company?.types?.length === 0) {
    return null
  }
  const companyType_id = company?.types[0]?.companyType_id

  return (
    <div className="page-content">
      {companyType_id === 1 && <Hotel />}
      {companyType_id === 2 && <Villa />}
      {companyType_id === 3 && <Transfer />}
      {companyType_id === 4 && <Ticket />}
    </div>
  )
}

export default Home
