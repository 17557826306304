import { useState, forwardRef, useImperativeHandle, useEffect } from "react"
import { Card, CardBody, Row, Col, Label, Alert, Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation-safe"
import Select from "react-select"

import CustomSpinner from "components/Common/CustomSpinner/index"
import PhoneInput from "components/Common/PhoneInput/index"
import { getLabelByLanguage } from "utils/other"
import styles from "./styles.module.scss"
import useRequest from "hooks/useRequest"
import CustomMap from "./CustomMap"

const TopInputs = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [facilityName, setFacilityName] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [postalCode, setPostalCode] = useState("")

  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [neighborhoods, setNeighborhoods] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedProvince, setSelectedProvince] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [googleName, setGoogleName] = useState("")
  const [googleAddress, setGoogleAddress] = useState("")
  const [googlePhoneNumber, setGooglePhoneNumber] = useState("")
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [mapModalOpen, setMapModalOpen] = useState(false)

  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const FeedBack = <AvFeedback>{t("Please fill in the blanks!")}</AvFeedback>
  const FeedBack2 = <AvFeedback>{t("Please add coordinate!")}</AvFeedback>

  const locale = i18n.language

  const fetchCountries = async () => {
    const response = await request(
      {
        url: "/countries",
      },
      false
    )

    const countries = response?.countries.map(item => ({
      ...item,
      label: getLabelByLanguage(item, locale),
      value: item.id,
    }))
    setCountries(countries)
  }

  const fetchProvinces = async country_id => {
    const response = await request(
      {
        url: "/provinces",
        params: { country_id },
      },
      false
    )
    const provinces = response?.provinces.map(item => ({
      ...item,
      label: getLabelByLanguage(item, locale),
      value: item.id,
    }))
    setProvinces(provinces)
  }

  const fetchDistricts = async province_id => {
    const response = await request(
      {
        url: "/districts",
        params: { province_id },
      },
      false
    )
    const districts = response?.districts.map(item => ({
      ...item,
      label: getLabelByLanguage(item, locale),
      value: item.id,
    }))
    setDistricts(districts)
  }

  const fetchNeighborhoods = async district_id => {
    const response = await request(
      {
        url: "/neighborhoods",
        params: { district_id },
      },
      false
    )
    const neighborhoods = response?.dbResult.map(item => ({
      ...item,
      label: getLabelByLanguage(item, locale),
      value: item.id,
    }))
    setNeighborhoods(neighborhoods)
  }

  useEffect(() => {
    if (countries.length > 0) {
      setCountries(prev =>
        prev.map(i => ({ ...i, label: getLabelByLanguage(i, locale) }))
      )
    }
    if (provinces.length > 0) {
      setProvinces(prev =>
        prev.map(i => ({ ...i, label: getLabelByLanguage(i, locale) }))
      )
    }
    if (districts.length > 0) {
      setDistricts(prev =>
        prev.map(i => ({ ...i, label: getLabelByLanguage(i, locale) }))
      )
    }

    if (selectedCountry?.value) {
      const country = countries.find(i => i.id === selectedCountry.value)
      setSelectedCountry({
        ...country,
        label: getLabelByLanguage(country, locale),
        value: country.id,
      })
    }
    if (selectedProvince?.value) {
      const province = provinces.find(i => i.id === selectedProvince.value)
      setSelectedProvince({
        ...province,
        label: getLabelByLanguage(province, locale),
        value: province.id,
      })
    }
    if (selectedDistrict?.value) {
      const district = districts.find(i => i.id === selectedDistrict.value)
      setSelectedDistrict({
        ...district,
        label: getLabelByLanguage(district, locale),
        value: district.id,
      })
    }
  }, [locale])

  const fetchData = async () => {
    const responses = await Promise.allSettled([fetchCountries()])
    return responses
  }

  const getState = () => {
    return {
      selectedCountry,
      selectedProvince,
      selectedDistrict,
      selectedNeighborhood,
      phoneNumber,
      googleName,
      googleAddress,
      googlePhoneNumber,
    }
  }

  const setState = async values => {
    setFacilityName(values.name)
    setAddress1(values.address1)
    setAddress2(values.address2)
    setPostalCode(values.postalCode)
    setPhoneNumber(values.phoneNumber)

    setSelectedCountry({ label: values.countryName, value: values.country_id })
    await fetchProvinces(values.country_id)
    setSelectedProvince({
      label: values.provinceName,
      value: values.province_id,
    })
    await fetchDistricts(values.province_id)
    setSelectedDistrict({
      label: values.districtName,
      value: values.district_id,
    })
    await fetchNeighborhoods(values.district_id)
    if (values.neighborhood_id) {
      setSelectedNeighborhood({
        label: values.neighborhoodName,
        value: values.neighborhood_id,
      })
    }

    if (values.latitude && values.longitude) {
      setLatitude(values.latitude)
      setLongitude(values.longitude)
      setGoogleName(values.googleName)
      setGoogleAddress(values.googleAddress)
      setGooglePhoneNumber(values.googlePhoneNumber)

    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLatitude(position.coords.latitude)
        setLongitude(position.coords.longitude)
      })
    }
  }

  useImperativeHandle(ref, () => ({
    getState,
    setState,
    fetchData,
  }))

  const setCoordinate = ({ lat, lng }) => {
    setLatitude(lat)
    setLongitude(lng)
  }

  if (errorMessage) {
    return <Alert color="danger">{t(errorMessage)}</Alert>
  }

  return (
    <Card>
      <CardBody>
        {!loading ? (
          <Row className={styles.topInputContainer}>
            <Col xl={8}>
              <Row>
                <AvGroup className={styles.facilityContainer}>
                  <Label for="facilityName">* {t("Facility Name")}</Label>
                  <AvInput
                    name="facilityName"
                    id="facilityName"
                    type="text"
                    required
                    autoComplete="off"
                    value={facilityName}
                    onChange={(e, value) => setFacilityName(value)}
                  />
                  {FeedBack}
                </AvGroup>
              </Row>
              <Row className={styles.row2}>
                <Col md={4}>
                  <PhoneInput
                    phoneNumber={phoneNumber}
                    onChange={setPhoneNumber}
                    label={"* " + t("Phone Number")}
                    className={styles.phoneInput}
                  />
                </Col>
                <Col md={4}>
                  <Label for="selectedCountry">* {t("Country")}</Label>
                  <Select
                    value={selectedCountry}
                    onChange={selectedCountry => {
                      setSelectedCountry(selectedCountry)
                      setSelectedProvince("")
                      setSelectedDistrict("")
                      fetchProvinces(selectedCountry.value)
                    }}
                    options={countries}
                    placeholder={t("Select...")}
                    noOptionsMessage={() => t("No Options")}
                  />
                </Col>
                <Col md={4}>
                  <Label for="selectedProvince">* {t("Province")}</Label>
                  <Select
                    value={selectedProvince}
                    onChange={selectedProvince => {
                      setSelectedProvince(selectedProvince)
                      setSelectedDistrict("")
                      fetchDistricts(selectedProvince.value)
                    }}
                    options={provinces}
                    placeholder={t("Select...")}
                    noOptionsMessage={() => t("No Options")}
                  />
                </Col>
              </Row>
              <Row className={styles.row2}>
                <Col md={4}>
                  <Label for="selectedDistrict">* {t("District")}</Label>
                  <Select
                    value={selectedDistrict}
                    onChange={selectedDistrict => {
                      setSelectedDistrict(selectedDistrict)
                      fetchNeighborhoods(selectedDistrict.value)
                      setSelectedNeighborhood("")
                    }}
                    options={districts}
                    placeholder={t("Select...")}
                    noOptionsMessage={() => t("No Options")}
                  />
                </Col>
                <Col md={4}>
                  <Label for="selectedNeighborhood">{t("Neighborhood")}</Label>
                  <Select
                    value={selectedNeighborhood}
                    onChange={setSelectedNeighborhood}
                    options={neighborhoods}
                    placeholder={t("Select...")}
                    noOptionsMessage={() => t("No Options")}
                  />
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Label for="postalCode">{t("Postal Code")}</Label>
                    <AvInput
                      name="postalCode"
                      id="postalCode"
                      type="text"
                      autoComplete="off"
                      value={postalCode}
                      onChange={(e, value) => setPostalCode(value)}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row className={styles.row2}>
                <Col>
                  <AvGroup>
                    <Label for="address1">* {t("Address 1")}</Label>
                    <AvInput
                      name="address1"
                      id="address1"
                      type="textarea"
                      required
                      autoComplete="off"
                      value={address1}
                      onChange={(e, value) => setAddress1(value)}
                    />
                    {FeedBack}
                  </AvGroup>
                </Col>
              </Row>
              <Row className={styles.row2}>
                <Col md={4}>
                  <AvGroup>
                    <Label for="latitude">{"* " + t("Latitude")}</Label>
                    <AvInput
                      name="latitude"
                      id="latitude"
                      type="text"
                      required
                      autoComplete="off"
                      value={latitude}
                      onChange={(e, value) => setLatitude(value)}
                      disabled
                    />
                    {FeedBack2}
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Label for="longitude">{"* " + t("Longitude")}</Label>
                    <AvInput
                      name="longitude"
                      id="longitude"
                      type="text"
                      autoComplete="off"
                      required
                      value={longitude}
                      onChange={(e, value) => setLongitude(value)}
                      disabled
                    />
                    {FeedBack2}
                  </AvGroup>
                </Col>
              </Row>
            </Col>
            <Col xl={4} className={styles.mapContainer}>
              <div className={styles.map}>
                <CustomMap
                  lat={!!latitude ? +latitude : null}
                  lng={!!longitude ? +longitude : null}
                  isModalOpen={mapModalOpen}
                  setIsModalOpen={setMapModalOpen}
                  setCoordinate={setCoordinate}
                  setGoogleName={setGoogleName}
                  setGoogleAddress={setGoogleAddress}
                  setGooglePhoneNumber={setGooglePhoneNumber}
                />
              </div>

              <Button onClick={setMapModalOpen.bind(null, true)}>
                {t(latitude ? "Update Coordinate" : "Add Coordinate")}
              </Button>
            </Col>
          </Row>
        ) : (
          <CustomSpinner color="primary" />
        )}
      </CardBody>
    </Card>
  )
})

export default TopInputs
