import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Button,
  Card,
  CardBody,
  Label,
  Spinner,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import CustomWarning from "components/Common/CustomWarning/index"

import Breadcrumbs from "components/Common/Breadcrumb"
import CustomSwitch from "components/Common/CustomSwitch/index"
import CustomTimePicker from "components/Common/CustomTimePicker/index"
import CustomSpinner from "components/Common/CustomSpinner"
import useRequest from "hooks/useRequest"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"
import { formatTime } from "utils/dateTimeFunctions"

import styles from "./styles.module.scss"

const HotelInOutConditions = () => {
  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [hotel_id, setHotel_id] = useState("")
  const [condition_id, setCondition_id] = useState("")
  const [checkIn, setCheckIn] = useState(getDateByHourAndMinute(14))
  const [checkOut, setCheckOut] = useState(getDateByHourAndMinute(12))
  const [hasEarlyCheckInFee, setHasEarlyCheckInFee] = useState(false)
  const [hasLateCheckOutFee, setHasLateCheckOutFee] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setLoading(false)
        setShowHotelNotRegWarning(true)
        return
      }
      await fetchHotel_CheckInOutConditions(hotel_id)
      setLoading(false)
    })()
  }, [])

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )
    if (response?.dbResult) {
      setHotel_id(response.dbResult.id)
      return response.dbResult.id
    }
  }

  const fetchHotel_CheckInOutConditions = async hotel_id => {
    const response = await request(
      {
        url: "./hotel-in-out-conditions",
        params: { hotel_id },
      },

      false
    )

    if (response.dbResult) {
      const { checkInHour, checkOutHour, id, earlyCheckIn, lateCheckOut } =
        response.dbResult
      setCondition_id(id)
      setHasEarlyCheckInFee(earlyCheckIn)
      setHasLateCheckOutFee(lateCheckOut)
      const splittedCheckIn = checkInHour.split(":")
      const splittedCheckOut = checkOutHour.split(":")
      if (splittedCheckIn.length === 3 && splittedCheckOut.length === 3) {
        setCheckIn(
          getDateByHourAndMinute(splittedCheckIn[0], splittedCheckIn[1])
        )
        setCheckOut(
          getDateByHourAndMinute(splittedCheckOut[0], splittedCheckOut[1])
        )
      }
    }
  }

  const submitHandler = async () => {
    let data = {
      checkInHour: formatTime(checkIn),
      checkOutHour: formatTime(checkOut),
      earlyCheckIn: hasEarlyCheckInFee,
      lateCheckOut: hasLateCheckOutFee,
      hotel_id,
    }

    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    let method = ""
    if (condition_id) {
      data = { ...data, id: condition_id }
      method = "put"
    } else {
      method = "post"
    }
    setSubmitLoading(true)
    const response = await request(
      {
        url: "/hotel-in-out-conditions",
        method,
        data,
      },
      false
    )
    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      console.log(errorMessage)
    }
  }
  const { t } = useTranslation()

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Hotels")}
          breadcrumbItem={t("Check-in/Check-out Conditions")}
        />
        {loading && <CustomSpinner color="primary" />}
        <div className={loading ? "d-none" : ""}>
          <Card className={styles.card}>
            <h4 className={styles.subtitle}>
              {t(
                "Please define the valid entry / exit times of your facility in the system. If you have an early check-in and late check-out, specify the applicable conditions."
              )}
            </h4>

            <CardBody className={styles.cardBody}>
              <div className={styles.checkInContainer}>
                <div className={styles.timePickerContainer}>
                  <Label>{t("Check-in Hour")} </Label>

                  <CustomTimePicker
                    value={new Date(checkIn)}
                    onChange={setCheckIn}
                    placeholder={t(" ")}
                  />
                </div>

                <CustomSwitch
                  label="Early check-in is accepted for an additional fee"
                  className={styles.customSwitch}
                  onClick={() => setHasEarlyCheckInFee(prevState => !prevState)}
                  value={hasEarlyCheckInFee}
                />
              </div>

              <div className={styles.checkOutContainer}>
                <div className={styles.timePickerContainer}>
                  <Label>{t("Check-out Hour")} </Label>
                  <CustomTimePicker
                    value={new Date(checkOut)}
                    onChange={setCheckOut}
                    placeholder={t(" ")}
                  />
                </div>

                <CustomSwitch
                  label="Late check-out is accepted for an additional fee"
                  className={styles.customSwitch}
                  onClick={() => setHasLateCheckOutFee(prevState => !prevState)}
                  value={hasLateCheckOutFee}
                />
              </div>
              {HotelNotRegisteredComp}
            </CardBody>
          </Card>

          <Row className={styles.btnContainer}>
            <Button color="primary" onClick={submitHandler}>
              {submitLoading ? (
                <Spinner color="white" size="sm" />
              ) : (
                t("Submit")
              )}
            </Button>
          </Row>
        </div>
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default HotelInOutConditions

const getDateByHourAndMinute = (hour, minute = "00") => {
  let today = new Date()
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    hour,
    minute
  )
}
