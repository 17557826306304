import { FormGroup, Input } from "reactstrap"
import styles from "./styles.module.scss"

const CustomCheckbox = ({ id, title, isChecked, onClick, className }) => {
  return (
    <FormGroup key={id} className={className}>
      <div onClick={() => onClick(id)} className={styles.checkboxWrapper}>
        <Input
          type="checkbox"
          className={styles.checkbox}
          name={title}
          id={id}
          onChange={() => {}}
          checked={isChecked || false}
        />
        <span className={styles.title}>{title}</span>
      </div>
    </FormGroup>
  )
}

export default CustomCheckbox
