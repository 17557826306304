import MainComponent from "./Component/Component"

const SocialNetwork = () => {
  return (
    <MainComponent
      title={"Increase your sales with social network"}
      opportunityType_id={3}
    />
  )
}

export default SocialNetwork
