import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import { useSocket } from "contexts/socket-context"
import { useMasterSocket } from "contexts/master-socket-context"
import commonContent from "./SidebarContent/commonContent"
import hotelContent from "./SidebarContent/hotelContent"
import villaContent from "./SidebarContent/villaContent"
import transferContent from "./SidebarContent/transferContent"
import ticketContent from "./SidebarContent/ticketContent"
import CustomSidebar from "../CustomSidebar/Sidebar"

const Sidebar = ({ type, setType }) => {
  const companyTypes = useSelector(state => state.Company.company.types)
  const { unreadMessageCount: resUnreadMessageCount } = useSocket()
  const { unreadMessageCount: masterUnreadMessageCount } = useMasterSocket()

  const unreadMessageCount = {
    resUnreadMessageCount: resUnreadMessageCount || 0,
    masterUnreadMessageCount: masterUnreadMessageCount || 0,
  }
  commonContent[2].unreadMessageCount = unreadMessageCount

  const pathname = useHistory().location.pathname
  const setOpenSidebarHandler = isOpen => {
    if (isOpen) {
      setType("default")
    } else {
      setType("condensed")
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setOpenSidebarHandler(false)
    }
  }, [pathname])

  const companyType_id = companyTypes ? companyTypes[0]?.companyType_id : null
  
  // temporary solution until contracts for other company types are added
  let updatedCommonContent = commonContent
  if (companyType_id !== 1 && companyType_id !== null) {
    updatedCommonContent = commonContent.map((item) => {
      if (item.items) {
        item.items = item.items.filter(i => i.label !== "Contract");
      }
      return item;
    });
  } 

  if (!companyTypes || !companyType_id) return null

  let content = []
  if (companyType_id === 1) {
    content = hotelContent
  } else if (companyType_id === 2) {
    content = villaContent
  } else if (companyType_id === 3) {
    content = transferContent
  } else if (companyType_id === 4) {
    content = ticketContent
  }

  content = [...updatedCommonContent, ...content]
  return (
    <CustomSidebar
      open={type === "default"}
      setOpen={setOpenSidebarHandler}
      content={content}
    />
  )
}

export default Sidebar
