import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"

import CustomTable from "./CustomTable"
import { useReservationContext } from "../context"
import styles from "./styles.module.scss"

const FeaturesAndConditions = () => {
  const { t } = useTranslation()
  const { values, setters } = useReservationContext()
  const { isPolicySectionOpen } = values
  const { setIsPolicySectionOpen } = setters

  const el = document.getElementById("hotelConditions")
  return (
    <section>
      <Card>
        <CardBody>
          <header className={styles.header}>
            <div>
              <h5>{t("Features & Conditions")}</h5>
              <div
                className={styles.control}
                onClick={() => setIsPolicySectionOpen(prev => !prev)}
              >
                {isPolicySectionOpen ? (
                  <RiArrowUpSLine size={23} />
                ) : (
                  <RiArrowDownSLine size={23} />
                )}
              </div>
            </div>
          </header>
          <article
            className={styles.article}
            style={
              isPolicySectionOpen && el ? { maxHeight: el.clientHeight } : {}
            }
          >
            <CustomTable />
          </article>
        </CardBody>
      </Card>
    </section>
  )
}

export default FeaturesAndConditions
