import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Button,
  Spinner,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import CustomSpinner from "components/Common/CustomSpinner/index"
import CustomWarning from "components/Common/CustomWarning"
import Breadcrumbs from "components/Common/Breadcrumb"
import useRequest from "hooks/useRequest"
import { getLabelByLanguage } from "utils/other"

import styles from "./styles.module.scss"

const PropertyLanguages = () => {
  const company_id = useSelector(state => state.Company.company.id)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [languages, setLanguages] = useState([])
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const languages = await fetchLanguages()
      await fetchCompany_Languages(languages, company_id)
      setLoading(false)
    })()
  }, [])

  const fetchLanguages = async () => {
    const response = await request(
      {
        url: "/property-set-languages",
      },
      false
    )
    const PropertyLanguages = response?.dbResult?.map(i => ({
      ...i,
      title: getLabelByLanguage(i, locale),
      isChecked: false,
    }))
    setLanguages(PropertyLanguages)
    return PropertyLanguages
  }

  const fetchCompany_Languages = async (languages, company_id) => {
    const response = await request(
      {
        url: "/property-languages",
        params: { company_id },
      },
      false
    )

    const property_languages = response?.dbResult
    const updatedLanguages = [...languages]

    property_languages?.forEach(i => {
      for (let j = 0; j < updatedLanguages.length; j++) {
        const language = updatedLanguages[j]
        if (i.setLanguage_id === language.id) {
          updatedLanguages[j] = { ...language, isChecked: true }
          break
        }
      }
    })

    setLanguages(updatedLanguages)
  }

  useEffect(() => {
    const updatedLanguages = languages?.map(item => {
      return {
        ...item,
        title: getLabelByLanguage(item, locale),
      }
    })

    setLanguages(updatedLanguages)
  }, [locale, languages?.length])

  const checkHandler = id => {
    const updatedLanguages = [...languages]
    const index = updatedLanguages.findIndex(item => item.id === id)
    const language = updatedLanguages[index]
    updatedLanguages[index] = { ...language, isChecked: !language.isChecked }
    setLanguages(updatedLanguages)
  }
  const submitHandler = async () => {
    const dataList = []
    languages.forEach(i => {
      if (i.isChecked) {
        dataList.push({
          setLanguage_id: i.id,
          company_id,
        })
      }
    })

    setSubmitLoading(true)
    const response = await request(
      {
        url: "/property-languages",
        method: "put",
        data: { dataList, company_id },
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      setErrorMessage(response?.message)
    }
  }
  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Villas")}
          breadcrumbItem={t("Property Languages")}
        />
        {loading && <CustomSpinner color="primary" />}
        <Card className={loading ? "d-none" : ""}>
          <CardBody>
            <h3>{t("Languages ​​Used on the Facility")}</h3>
            <p className={styles.subtitle}>
              {t(
                "In this field, indicate which languages you provide service in your facility."
              )}
            </p>
            <Row className="mx-0 mt-4">
              {languages.map(item => (
                <FormGroup key={item.id} className={styles.checkboxContainer}>
                  <div
                    onClick={() => checkHandler(item.id)}
                    className={styles.checkboxWrapper}
                  >
                    <Input
                      type="checkbox"
                      className={styles.checkbox}
                      name={item.title}
                      id={item.id}
                      onChange={() => {}}
                      checked={item.isChecked || false}
                    />
                    <span className="ml-2">{item.title}</span>
                  </div>
                </FormGroup>
              ))}
            </Row>
          </CardBody>
        </Card>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <CustomWarning
          title="Congratulations!"
          success={true}
          show={showSuccessAlert}
          onConfirm={() => setShowSuccessAlert(false)}
          message="The data is saved successfully!"
        />
        <Row className={`${styles.btnContainer} ${loading ? "d-none" : ""}`}>
          <Button color="primary" onClick={submitHandler}>
            {submitLoading && <Spinner size="sm" color="white" />} {t("Submit")}
          </Button>
        </Row>
      </Container>
    </main>
  )
}

export default PropertyLanguages
