import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import axios from "axios"
import htmlToDraft from "html-to-draftjs"

import CustomModal from "./CustomModal"

class Add_EditVehicle extends React.Component {
  state = {
    showSuccessAlert: false,
    selectedVehicleType: null,
    blankFieldError: false,
    //
    editorState: EditorState.createEmpty(),
    englishEditorState: EditorState.createEmpty(),
    turkishEditorState: EditorState.createEmpty(),
    germanEditorState: EditorState.createEmpty(),
    frenchEditorState: EditorState.createEmpty(),
    russianEditorState: EditorState.createEmpty(),
    arabicEditorState: EditorState.createEmpty(),
    //
    loading: false,
    errorMessage: "",
  }

  componentDidMount = async () => {
    this.setState({ loading: true })

    // the following code will run only in edit mode
    if (!this.props.isAddOperation) {
      const {
        vehicleType_id,
        description_en,
        description_tr,
        description_de,
        description_fr,
        description_ru,
        description_ar,
      } = this.props.vehicle

      // preparing selectedVehicleType
      const selectedVehicleType = this.props.vehicleTypes.find(type => {
        return type.value == vehicleType_id
      })

      // preparing description
      this.populateDescription(description_en, "en")
      this.populateDescription(description_tr, "tr")
      this.populateDescription(description_de, "de")
      this.populateDescription(description_fr, "fr")
      this.populateDescription(description_ru, "ru")
      this.populateDescription(description_ar, "ar")

      this.setState({ selectedVehicleType })
    }

    this.setState({ loading: false })
  }

  convertDraftToHtml = editorState => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }

  onSaveVehicle = (event, values) => {
    const {
      selectedVehicleType,
      englishEditorState,
      frenchEditorState,
      germanEditorState,
      russianEditorState,
      turkishEditorState,
      arabicEditorState,
    } = this.state
    if (!selectedVehicleType) {
      this.setState({ blankFieldError: true })
      return
    }

    const englishDescInHTMLFormat = this.convertDraftToHtml(englishEditorState)
    const turkishDescInHTMLFormat = this.convertDraftToHtml(turkishEditorState)
    const germanDescInHTMLFormat = this.convertDraftToHtml(germanEditorState)
    const frenchDescInHTMLFormat = this.convertDraftToHtml(frenchEditorState)
    const russianDescInHTMLFormat = this.convertDraftToHtml(russianEditorState)
    const arabicDescInHTMLFormat = this.convertDraftToHtml(arabicEditorState)

    let data = {
      ...values,
      vehicleType_id: selectedVehicleType.value,
      description_en: englishDescInHTMLFormat,
      description_tr: turkishDescInHTMLFormat,
      description_de: germanDescInHTMLFormat,
      description_fr: frenchDescInHTMLFormat,
      description_ru: russianDescInHTMLFormat,
      description_ar: arabicDescInHTMLFormat,
      id: this.props.isAddOperation ? "" : this.props.vehicle.id,
    }

    this.saveVehicleHandler(data)
  }

  saveVehicleHandler = async data => {
    try {
      this.setState({ loading: true })

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }

      const url = `/trans-vehicles`
      let response
      if (this.props.isAddOperation) {
        response = await axios.post(url, data, config)
      } else {
        response = await axios.put(url, data, config)
      }

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({ showSuccessAlert: true })
      } else {
        console.log(response.data.message)
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      console.log(error)
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onEditorStateChange = (editorState, locale) => {
    let property = ""
    switch (locale) {
      case "en":
        property = "englishEditorState"
        break
      case "tr":
        property = "turkishEditorState"
        break
      case "de":
        property = "germanEditorState"
        break
      case "fr":
        property = "frenchEditorState"
        break
      case "ru":
        property = "russianEditorState"
        break
      case "ar":
        property = "arabicEditorState"
        break
    }

    this.setState({ [property]: editorState })
  }

  hideSuccessAlert = () => {
    this.props.refreshTable()
    this.props.close()
    this.setState({ showSuccessAlert: false })
  }

  selectedVehicleTypeHandler = selectedVehicleType => {
    this.setState({
      selectedVehicleType,
      blankFieldError: false,
    })
  }

  populateDescription = (description, lang) => {
    const contentBlock = htmlToDraft(description || "<p></p>")
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      switch (lang) {
        case "en":
          this.setState({ englishEditorState: editorState })
          break
        case "tr":
          this.setState({ turkishEditorState: editorState })
          break
        case "de":
          this.setState({ germanEditorState: editorState })
          break
        case "fr":
          this.setState({ frenchEditorState: editorState })
          break
        case "ru":
          this.setState({ russianEditorState: editorState })
          break
        case "ar":
          this.setState({ arabicEditorState: editorState })
          break
      }
    }
  }

  render() {
    const {
      selectedVehicleType,
      blankFieldError,
      showSuccessAlert,
      englishEditorState,
      frenchEditorState,
      germanEditorState,
      russianEditorState,
      turkishEditorState,
      arabicEditorState,
      errorMessage,
      loading,
    } = this.state

    return (
      <React.Fragment>
        <CustomModal
          modalTitle={
            this.props.isAddOperation ? "Add Vehicle" : "Edit Vehicle"
          }
          {...this.props}
          selectedVehicleType={selectedVehicleType}
          blankFieldError={blankFieldError}
          englishEditorState={englishEditorState}
          frenchEditorState={frenchEditorState}
          germanEditorState={germanEditorState}
          russianEditorState={russianEditorState}
          turkishEditorState={turkishEditorState}
          arabicEditorState={arabicEditorState}
          errorMessage={errorMessage || this.props.errorMessage}
          componentDidMountLoading={loading}
          showSuccessAlert={showSuccessAlert}
          hideSuccessAlert={this.hideSuccessAlert}
          selectedVehicleTypeHandler={this.selectedVehicleTypeHandler}
          onEditorStateChange={this.onEditorStateChange}
          onSubmit={this.onSaveVehicle}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}
export default connect(mapStateToProps, {})(withTranslation()(Add_EditVehicle))
