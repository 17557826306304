import { useState } from "react"
import { useHistory } from "react-router-dom"

import CustomWarning from "../components/Common/CustomWarning"

export default () => {
  const [showVillaNotRegWarning, setShowVillaNotRegWarning] = useState(false)
  const history = useHistory()

  const VillaNotRegisteredComp = showVillaNotRegWarning ? (
    <CustomWarning
      title="Warning!"
      warning={true}
      show={showVillaNotRegWarning}
      onConfirm={() => {
        setShowVillaNotRegWarning(false)
        history.push("/property-registration")
      }}
      message="Please first register a villa!"
    />
  ) : null

  return {
    setShowVillaNotRegWarning,
    VillaNotRegisteredComp,
  }
}
