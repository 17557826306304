import React, { useState, useEffect } from "react"
import { Container, Row, Button, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import { AvForm } from "availity-reactstrap-validation-safe"

import Breadcrumbs from "components/Common/Breadcrumb"
import InformationInputs from "./InformationInputs"
import styles from "./styles.module.scss"
import CustomWarning from "components/Common/CustomWarning/index"
import useHotelNotRegisteredWarn from "hooks/useHotelNotRegisteredWarn"

import useRequest from "hooks/useRequest"

const AboutHotel = () => {
  const [showBlankFieldAlert, setShowBlankFieldAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [info, setInfo] = useState({})
  const [info_id, setInfo_id] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { request } = useRequest(setLoading, setErrorMessage)
  const { t } = useTranslation()
  const { setShowHotelNotRegWarning, HotelNotRegisteredComp } =
    useHotelNotRegisteredWarn()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const hotel_id = await fetchHotelData()
      if (!hotel_id) {
        setLoading(false)
        setShowHotelNotRegWarning(true)
        return
      }
      const dbResult = await fetchHotel_About(hotel_id)

      if (dbResult) {
        setInfo({
          hotel_id,
          facilityInfo_tr: dbResult.facilityInfo_tr || "",
          facilityInfo_en: dbResult.facilityInfo_en || "",
          facilityInfo_fr: dbResult.facilityInfo_fr || "",
          facilityInfo_de: dbResult.facilityInfo_de || "",
          facilityInfo_ru: dbResult.facilityInfo_ru || "",
          facilityInfo_ar: dbResult.facilityInfo_ar || "",

          generalInfo_tr: dbResult.generalInfo_tr || "",
          generalInfo_en: dbResult.generalInfo_en || "",
          generalInfo_fr: dbResult.generalInfo_fr || "",
          generalInfo_de: dbResult.generalInfo_de || "",
          generalInfo_ru: dbResult.generalInfo_ru || "",
          generalInfo_ar: dbResult.generalInfo_ar || "",
        })
      }
      setLoading(false)
    })()
  }, [])

  const fetchHotelData = async () => {
    const response = await request(
      {
        url: "/hotel-registration",
      },
      false
    )

    if (response?.dbResult) {
      const hotel_id = response.dbResult.id
      return hotel_id
    }
  }

  const fetchHotel_About = async hotel_id => {
    const response = await request(
      {
        url: "/hotel-about",
        params: { hotel_id },
      },
      false
    )
    if (response?.dbResult) {
      setInfo_id(response?.dbResult.id)
      return response.dbResult
    }
  }

  const submitHandler = async (e, data) => {
    const hotel_id = await fetchHotelData()

    data = {
      ...data,
      hotel_id: hotel_id,
    }
    if (!hotel_id) {
      setShowHotelNotRegWarning(true)
      return
    }
    setSubmitLoading(true)
    let method = ""
    if (info_id) {
      data = { ...data, hotel_id, id: info_id }
      method = "put"
    } else {
      method = "post"
    }

    const response = await request(
      {
        url: "/hotel-about",
        method,
        data,
      },
      false
    )

    setSubmitLoading(false)
    if (response?.result) {
      setShowSuccessAlert(true)
    } else {
      console.log(errorMessage)
    }
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Hotels")} breadcrumbItem={t("About Hotel")} />
        {!loading && (
          <AvForm model={info} onValidSubmit={submitHandler}>
            <InformationInputs
              title="Facility Brief Info"
              name="facilityInfo"
            />
            <InformationInputs title="General Info" name="generalInfo" />
            <Row className={styles.btnContainer}>
              {HotelNotRegisteredComp}
              <Button color="primary">
                {submitLoading && <Spinner size="sm" color="white" />}{" "}
                {t("Submit")}
              </Button>
            </Row>
          </AvForm>
        )}
      </Container>
      <CustomWarning
        title={"Warning!"}
        warning={true}
        show={showBlankFieldAlert}
        onConfirm={() => setShowBlankFieldAlert(false)}
        message="Please fill in the blanks!"
      />
      <CustomWarning
        title="Congratulations!"
        success={true}
        show={showSuccessAlert}
        onConfirm={() => setShowSuccessAlert(false)}
        message="The data is saved successfully!"
      />
    </main>
  )
}

export default AboutHotel
