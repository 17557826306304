import React from "react"
import { Card, CardBody, FormGroup, Input, Label, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { formatToHHMM } from "utils/dateTimeFunctions"
import styles from "./styles.module.scss"

export default function Capacity({
  selectedTicket,
  capacities,
  setCapacities,
}) {
  const { t } = useTranslation()

  const timesArr = selectedTicket.ticket_times.split(",").map((time, index) => {
    const id = parseInt(selectedTicket.ticketTime_ids.split(",")[index])
    return { id, time }
  })

  const handleCapacityChange = (ticketTime_id, capacity) => {
    const capacityIndex = capacities.findIndex(
      item => item.ticketTime_id === ticketTime_id
    )

    if (capacityIndex !== -1) {
      const updatedCapacities = [...capacities]
      updatedCapacities[capacityIndex] = {
        ticketTime_id,
        capacity: capacity,
      }
      setCapacities(updatedCapacities)
    } else {
      setCapacities([...capacities, { ticketTime_id, capacity: capacity }])
    }
  }

  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h5 className={styles.cardSubtitle}>{t("Number of Tickets to be Sold")}</h5>
        {timesArr.map(timeObj => (
          <Row form key={timeObj.id}>
            <Col md={6}>
              <FormGroup row>
                <Label for="price" md={3}>
                  {formatToHHMM(`2000-01-01T${timeObj?.time}Z`)}
                </Label>
                <Col md={4}>
                  <Input
                    type="number"
                    min={0}
                    value={
                      capacities.find(item => item.ticketTime_id === timeObj.id)
                        ?.capacity || ""
                    }
                    onChange={e => {
                      e.target.value <= selectedTicket.maxCapacity
                        ? handleCapacityChange(timeObj.id, e.target.value)
                        : {}
                    }}
                  />
                </Col>
                <Col md={2} className="ml-4 d-flex align-items-center">
                  <Input
                    type="checkbox"
                    name={"max"}
                    onChange={() => {
                      handleCapacityChange(
                        timeObj.id,
                        +selectedTicket.maxCapacity
                      )
                    }}
                    checked={
                      capacities.find(item => item.ticketTime_id === timeObj.id)
                        ?.capacity == selectedTicket.maxCapacity
                    }
                  />
                  <span className="mt-1">{t("Max")}</span>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        ))}
      </CardBody>
    </Card>
  )
}
