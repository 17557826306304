import React from "react"
import { useTranslation } from "react-i18next"

import { useChildPolicyContext } from "../../context"

export default function TableRow({ data, groupNumber }) {
  const { t } = useTranslation()
  const {
    childNames,
    tablesData,
    setTablesData,
    isPercent,
    childPolicyAge,
  } = useChildPolicyContext()

  const valueChangeHandler = (e, childCount) => {
    const value = e.target.value
    // validation
    if (value < 0 || (isPercent && value > 100)) {
      return
    }
    // copy tables data
    const newTbsData = [...tablesData]
    // find current table, find current row, find current side(first/second child)
    const adultCount = data[0].adultCount
    newTbsData[adultCount - 1][groupNumber - 1][
      childCount
    ].percentOrPrice = value
    setTablesData(newTbsData)
  }

  const childPolicyAge_id = data[0].childPolicyAge_id
  const cpAge = childPolicyAge.find(i => i.id === childPolicyAge_id)
  const childName = childNames.find(i => i.id === cpAge?.setChildName_id)?.name
  const label = `${t("Group")} ${groupNumber} (${childName} ${
    cpAge?.startAge
  }-${cpAge?.endAge})`

  return (
    <tr>
      <td>{label}</td>
      <td>
        <input
          type="number"
          value={data[0].percentOrPrice}
          onChange={e => valueChangeHandler(e, 0)}
        />
      </td>
      <td>{label}</td>
      <td>
        <input
          type="number"
          value={data[1].percentOrPrice}
          onChange={e => valueChangeHandler(e, 1)}
        />
      </td>
    </tr>
  )
}
