import React from "react"
import { Modal, Row, Col, Alert, Spinner, Label } from "reactstrap"
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation-safe"
import Select from "react-select"
import { withTranslation } from "react-i18next"

import SuccessAlert from "components/Common/SuccessAlert/index"
import TextEditor from "./TextEditor"

class CustomModal extends React.Component {
  render() {
    const {
      vehicleTypes,
      isOpen,
      close,
      modalTitle,
      componentDidMountLoading,
      onSubmit,
      vehicle,
      selectedVehicleType,
      selectedVehicleTypeHandler,
      error,
      errorMessage,
      blankFieldError,
      showSuccessAlert,
      hideSuccessAlert,
      loading,
      isAddOperation,
      t,
    } = this.props

    const Feedback = <AvFeedback>{t("Please fill in the blank!")}</AvFeedback>

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} scrollable={true} size="lg" toggle={close}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">{t(modalTitle)}</h5>
            <button
              type="button"
              onClick={close}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body min-height-240">
            {componentDidMountLoading ? (
              <Spinner size="lg" color="primary" className="spinner" />
            ) : (
              <AvForm
                onValidSubmit={onSubmit}
                model={
                  isAddOperation
                    ? { status: "1" }
                    : vehicle
                    ? {
                        status: vehicle.status ? "1" : "0",
                      }
                    : { status: "1" }
                }
              >
                <Row form>
                  <Col md={6}>
                    <AvGroup className="select2-container">
                      <Label>{t("Vehicle Type")}</Label>
                      <Select
                        value={selectedVehicleType}
                        onChange={selectedVehicleTypeHandler}
                        options={vehicleTypes}
                        noOptionsMessage={() => t("No Options")}
                        classNamePrefix="select2-selection"
                        placeholder={t("Select...")}
                      />
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="count">{t("Vehicle Count")}</Label>
                      <AvInput
                        name="count"
                        id="count"
                        type="number"
                        min="0"
                        required
                        autoComplete="off"
                        value={vehicle.count}
                      />
                    </AvGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="capacity">{t("Capacity")}</Label>
                      <AvInput
                        name="capacity"
                        id="capacity"
                        type="number"
                        min="0"
                        required
                        autoComplete="off"
                        value={vehicle.capacity}
                      />
                      {Feedback}
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="suitcaseCount">{t("Suitcase Count")}</Label>
                      <AvInput
                        name="suitcaseCount"
                        id="suitcaseCount"
                        type="number"
                        min="0"
                        required
                        autoComplete="off"
                        value={vehicle.suitcaseCount}
                      />
                      {Feedback}
                    </AvGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="note">{t("Note")}</Label>
                      <AvInput
                        name="note"
                        id="note"
                        type="text"
                        autoComplete="off"
                        value={vehicle.note}
                      />
                    </AvGroup>
                  </Col>
                  <Col md={3}>
                    <AvRadioGroup name="status" required>
                      <Label for="status" className="d-block">
                        {t("Status")}
                      </Label>
                      <div className="AvRadioGroup__buttons">
                        <AvRadio label={t("Active")} value="1" />
                        <AvRadio label={t("Inactive")} value="0" />
                      </div>
                    </AvRadioGroup>
                  </Col>
                </Row>

                {/* DESCRIPTION */}
                <Label for="description" className="d-block">
                  {t("Description")}
                </Label>
                <TextEditor {...this.props} />

                {error && <Alert color="danger"> {t(errorMessage)}</Alert>}

                {blankFieldError && (
                  <Alert color="danger">{t("Please fill in the blank!")}</Alert>
                )}

                {showSuccessAlert && (
                  <SuccessAlert hideSuccessAlert={hideSuccessAlert} />
                )}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={close}
                  >
                    {t("Close")}
                  </button>

                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Spinner size="sm" /> {t("Loading...")}{" "}
                      </>
                    ) : (
                      t("Save")
                    )}
                  </button>
                </div>
              </AvForm>
            )}
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CustomModal)
