import { addDays } from "date-fns"
import { createContext, useContext, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import useRequest from "hooks/useRequest"
import { getLabelByLanguage, all } from "utils/other"

const ReservationContext = createContext()

export const useReservationContext = () => {
  const context = useContext(ReservationContext)

  if (!context) {
    throw new Error("The component must be wrapped by the provider!")
  }

  return context
}

export const ReservationProvider = props => {
  const [dateTypes, setDateTypes] = useState(initialDateTypes)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addDays(new Date(), 15))
  const [statuses, setStatuses] = useState([])

  const [roomTypes, setRoomTypes] = useState([])
  const [roomNames, setRoomNames] = useState([])

  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)

  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const { request } = useRequest(setLoading, setError)

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchResStatuses = async () => {
      const response = await request(
        {
          url: "reservation-statuses",
        },
        false
      )

      const statuses = response?.resStatuses?.map(i => ({
        ...i,
        name: t(i.name),
        isChecked: [1, 5].includes(i.id),
      }))

      statuses.unshift({
        id: 0,
        name: getLabelByLanguage(all, locale),
        isChecked: false,
      })

      setStatuses(statuses)
      return statuses
    }

    const fetchSetRoomTypes = async () => {
      const response = await request(
        {
          url: "hotel-set-room-types",
        },
        false
      )

      setRoomTypes(
        response?.dbResult?.map(i => ({
          ...i,
          name: getLabelByLanguage(i, locale),
        }))
      )
    }

    const fetchSetRoomNames = async () => {
      const response = await request(
        {
          url: "hotel-set-room-names",
        },
        false
      )

      setRoomNames(
        response?.dbResult?.map(i => ({
          ...i,
          name: getLabelByLanguage(i, locale),
        }))
      )
    }

    const fetchPendingReservations = async () => {
      const response = await request({
        url: "/hotel-room-reservations/fetch-pending-reservations",
        params: {
          skip: 0,
          limit: itemsCountPerPage,
        },
      })

      if (response?.dbResult?.totalItemsCount > 0) {
        setReservations(response?.dbResult?.reservations)
        setTotalItemsCount(response?.dbResult?.totalItemsCount)
        setStartDate(new Date(response?.dbResult?.firstPendingResDate))
      }
    }

    ;(async () => {
      setLoading(true)
      await fetchResStatuses()
      await Promise.allSettled([fetchSetRoomTypes(), fetchSetRoomNames()])
      fetchPendingReservations()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    searchHandler(statuses)
  }, [activePage])

  useEffect(() => {
    if (statuses.length > 0) {
      let newStatuses = [...statuses]
      newStatuses[0].name = getLabelByLanguage(all, locale)
      newStatuses = newStatuses.map(i => ({ ...i, name: t(i.name) }))
      setStatuses(newStatuses)
    }

    setDateTypes(initialDateTypes?.map(i => ({ ...i, name: t(i.name) })))

    if (roomTypes.length > 0) {
      setRoomTypes(
        roomTypes.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
      )
    }

    if (roomNames.length > 0) {
      setRoomNames(
        roomNames.map(i => ({ ...i, name: getLabelByLanguage(i, locale) }))
      )
    }
  }, [locale])

  const searchHandler = async statuses => {
    setError("")

    // extract data
    const dateType_id = dateTypes?.find(i => i.isChecked)?.id
    const status_ids = statuses
      ?.filter(i => i.isChecked && i.id !== 0)
      ?.map(i => i.id)
    if (status_ids?.length === 0) {
      return
    }

    const response = await request({
      url: "hotel-room-reservations",
      params: {
        dateType_id,
        startDate,
        endDate,
        status_ids,
        page: activePage,
        limit: itemsCountPerPage,
      },
    })

    setReservations(response?.dbResult?.reservations)
    setTotalItemsCount(response?.dbResult?.totalItemsCount)
  }

  const value = useMemo(() => {
    const functions = {
      searchHandler,
    }
    const setters = {
      setLoading,
      setError,
      setStartDate,
      setEndDate,
      setReservations,
      setStatuses,
      setDateTypes,
      setActivePage,
      setTotalItemsCount,
    }
    const values = {
      loading,
      error,
      startDate,
      endDate,
      reservations,
      statuses,
      dateTypes,
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      roomTypes,
      roomNames,
    }
    return { functions, setters, values }
  }, [
    loading,
    error,
    startDate,
    endDate,
    reservations,
    statuses,
    dateTypes,
    activePage,
    totalItemsCount,
    roomTypes,
    roomNames,
  ])

  return <ReservationContext.Provider value={value} {...props} />
}

const itemsCountPerPage = 10
const initialDateTypes = [
  { id: 1, name: "Reservation", isChecked: true },
  { id: 2, name: "Check-in_", isChecked: false },
  { id: 3, name: "Check-out", isChecked: false },
]
