import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

const CustomSwitch = ({
  label,
  labelPosition,
  className,
  onClick,
  value,
  isRequired,
}) => {
  const { t } = useTranslation()

  const container = [styles.switchContainer]
  if (className) {
    container.push(className)
  }
  if (labelPosition === "right") {
    container.push(styles.right)
  }
  return (
    <div className={container.join(" ")} onClick={onClick}>
      <label>
        {isRequired ? "* " : ""} {t(label)}
      </label>
      <span className={`${styles.switch} ${value ? styles.active : ""}`}>
        <span className={styles.ball}></span>
      </span>
    </div>
  )
}

export default CustomSwitch
