import React, { Component } from "react"
import { Container, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

import Breadcrumbs from "components/Common/Breadcrumb"
import ControlButtons from "components/Common/ControlButtons/ControlButtons"
import FeaturesModal from "./FeaturesModal/index"
import PropertiesTable from "components/Villa/PropertiesTable/index"

class PropertyFeatures extends Component {
  state = {
    isModalOpen: false,
    hotel_id: "",
    properties: [],
    propertyTypes: [],
    selectedRowId: "",
    selectedRowData: {},
    showWarningMessage: false,
    //
    activePage: 1,
    itemsCountPerPage: 13,
    totalItemsCount: 0,
    searchText: "",
    //
    loading: false,
    errorMessage: "",
    error: false,
  }

  componentDidMount = () => {
    this.fetchProperties()
    this.fetchPropertyTypes()
  }

  fetchProperties = async () => {
    try {
      this.setState({ loading: true })
      const { activePage, itemsCountPerPage, searchText } = this.state

      const url = `/property/fetch-by-page-number?page=${activePage}&limit=${itemsCountPerPage}&searchText=${searchText}`

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({
          selectedRowId: "",
          selectedRowData: "",
          properties: response.data.dbResult[0],
          totalItemsCount: response.data.dbResult[1][0].totalItemsCount,
        })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("search Request canceled")
      }
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  fetchPropertyTypes = async () => {
    try {
      this.setState({ loading: true })

      const url = `/property-types`

      const config = {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }

      const response = await axios.get(url, config)

      this.setState({ loading: false })
      if (response.data.result) {
        this.setState({ propertyTypes: response.data.propertyTypes })
      } else {
        this.setState({ errorMessage: response.data.message })
      }
    } catch (error) {
      this.setState({ loading: false, errorMessage: "Something went wrong!!" })
    }
  }

  onSelectTableRow = selectedRowId => {
    let selectedRowData = this.state.properties.find(item => {
      return item.id == selectedRowId
    })
    this.setState({
      selectedRowId,
      selectedRowData,
    })
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.fetchProperties)
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      propertyTypes,
      properties,
      loading,
      error,
      errorMessage,
      isModalOpen,
      showWarningMessage,
      selectedRowId,
      selectedRowData,
    } = this.state
    const { t } = this.props

    let Content = null
    if (loading) {
      Content = <Spinner size="lg" color="primary" className="spinner" />
    } else if (error) {
      Content = <Alert color="danger"> {t(errorMessage)}</Alert>
    } else {
      Content = (
        <PropertiesTable
          properties={properties}
          propertyTypes={propertyTypes}
          onSelectTableRow={this.onSelectTableRow}
          selectedRowId={this.state.selectedRowId}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          onPageChange={this.onPageChange}
          hasPagination={true}
        />
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Property")}
              breadcrumbItem={t("Property Features")}
            />
            <ControlButtons
              displayAddBtn={true}
              addTitle="Add/Remove Feature"
              displayEditBtn={false}
              displayDeleteBtn={false}
              onAddBtn={() => {
                if (selectedRowId) {
                  this.setState({ isModalOpen: true })
                } else {
                  this.setState({ showWarningMessage: true })
                }
              }}
              onPrintBtn={() => {}}
              onPdfBtn={() => {}}
              onExcelBtn={() => {}}
              isApiSearch={true}
              searchAPI={searchText => {
                this.setState(
                  { searchText, activePage: 1 },
                  this.fetchProperties
                )
              }}
            />
            {Content}
          </Container>
        </div>
        {isModalOpen && (
          <FeaturesModal
            isOpen={true}
            close={() => {
              this.setState({ isModalOpen: false })
            }}
            data={selectedRowData}
          />
        )}

        {showWarningMessage && (
          <SweetAlert
            warning
            title={t("Please select a row!")}
            onConfirm={() => {
              this.setState({ showWarningMessage: false })
            }}
            confirmBtnText={t("OK")}
          ></SweetAlert>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { token } = state.Login
  return {
    token,
  }
}

export default withTranslation()(connect(mapStateToProps, {})(PropertyFeatures))
