import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Spinner, Button, Alert, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import PromotionName from "./components/PromotionName"
import RadioButtons from "./components/RadioButtons"
import Dates from "./components/Dates"
import Members from "./components/Members"
import Checkboxes from "./components/Checkboxes"
import DiscountRate from "./components/DiscountRate"
import Locations from "./components/Locations"

import SuccessAlert from "components/Common/SuccessAlert"
import { getLabelByLanguage, all, getTranslatedOption } from "utils/other"
import { addDays, getTodayDate, formatDate } from "utils/dateTimeFunctions"

import useRequest from "hooks/useRequest"
import CustomSpinner from "components/Common/CustomSpinner"

const TransferPromotion = () => {
  const [onlyMembers, setOnlyMembers] = useState(false)
  const [promotionTypes, setPromotionTypes] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [marketingPlaces, setMarketingPlaces] = useState([])

  const [discountRate, setDiscountRate] = useState(10)
  const [selectedStartLocation, setSelectedStartLocation] = useState("")
  const [selectedEndLocation, setSelectedEndLocation] = useState("")
  const [isBothSide, setIsBothSide] = useState(false)

  const [resStartDate, setResStartDate] = useState(getTodayDate())
  const [resEndDate, setResEndDate] = useState(addDays(getTodayDate(), 7))

  const [bookingStartDate, setBookingStartDate] = useState(getTodayDate())
  const [bookingEndDate, setBookingEndDate] = useState(
    addDays(getTodayDate(), 7)
  )

  const [promotionName, setPromotionName] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState("")
  const [error, setError] = useState("")

  const { request } = useRequest(setLoading, setError)
  const { t, i18n } = useTranslation()
  const locale = i18n.language

  useEffect(() => {
    const fetchSetMarketingPlaces = async () => {
      const response = await request(
        {
          url: "/company-marketing-place",
        },
        false
      )

      if (response?.result && Array.isArray(response?.dbResult)) {
        const marketingPlaces = response?.dbResult?.map(i => ({
          ...i,
          id: i.id[1],
          isChecked: false,
        }))

        marketingPlaces.unshift({
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })
        setMarketingPlaces(marketingPlaces)
      }
    }

    const fetchPromotionTypes = async () => {
      const response = await request({
        url: "/transfer-promotion-types",
      })

      if (response?.result && Array.isArray(response?.dbResult)) {
        const types = response?.dbResult?.map(i => ({
          ...i,
          label: getLabelByLanguage(i, locale),
          value: i.id,
          isChecked: false,
        }))
        if (types.length > 0) {
          types[0].isChecked = true
        }

        setPromotionTypes(types)
      }
    }

    const fetchVehicles = async () => {
      const response = await request({
        url: "/trans-vehicles/names",
      })

      if (response?.result && Array.isArray(response?.dbResult)) {
        const vehicles = response.dbResult?.map(i => ({
          ...i,
          name: getTranslatedOption(i, "type", locale),
          isChecked: false,
        }))
        if (vehicles.length > 0) {
          vehicles[0].isChecked = true
        }

        vehicles.unshift({
          ...all,
          id: 0,
          name: getLabelByLanguage(all, locale),
          isChecked: false,
        })

        setVehicles(vehicles)
      }
    }

    ;(async () => {
      setLoading(true)
      await Promise.allSettled([
        fetchPromotionTypes(),
        fetchSetMarketingPlaces(),
        fetchVehicles(),
      ])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    setPromotionTypes(list =>
      list.map(item => ({ ...item, label: getLabelByLanguage(item, locale) }))
    )

    setMarketingPlaces(list => [
      { ...list[0], label: getLabelByLanguage(all, locale) },
      ...list.slice(1),
    ])

    setVehicles(list =>
      list.map(item =>
        item.id == 0
          ? { ...item, name: getTranslatedOption(item, "name", locale) }
          : {
              ...item,
              name: getTranslatedOption(item, "type", locale),
            }
      )
    )
  }, [locale])

  useEffect(() => {
    const promotionTypeLabel = promotionTypes.find(i => i.isChecked)?.label
    setPromotionName(
      `%${discountRate}  ${promotionTypeLabel}  ${formatDate(getTodayDate())}`
    )
  }, [discountRate, promotionTypes])

  // CHECK HANDLERS
  const marketingPlaceCheckHandler = id => {
    let updatedMarketingPlaces = []
    if (id === 0) {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: !marketingPlaces[0].isChecked,
      }))
    } else {
      updatedMarketingPlaces = marketingPlaces.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedMarketingPlaces.length; i++) {
      if (!updatedMarketingPlaces[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedMarketingPlaces[0].isChecked = true
    } else {
      updatedMarketingPlaces[0].isChecked = false
    }

    setMarketingPlaces(updatedMarketingPlaces)
  }

  const vehicleCheckHandler = id => {
    let updatedList = []
    if (id === 0) {
      updatedList = vehicles.map(i => ({
        ...i,
        isChecked: !vehicles[0].isChecked,
      }))
    } else {
      updatedList = vehicles.map(i => ({
        ...i,
        isChecked: i.id === id ? !i.isChecked : i.isChecked,
      }))
    }

    let isAllChecked = true
    for (let i = 1; i < updatedList.length; i++) {
      if (!updatedList[i].isChecked) {
        isAllChecked = false
        break
      }
    }
    if (isAllChecked) {
      updatedList[0].isChecked = true
    } else {
      updatedList[0].isChecked = false
    }

    setVehicles(updatedList)
  }

  const reset = () => {
    const nonCheckedVehicles = vehicles.map(i => ({
      ...i,
      isChecked: false,
    }))

    setVehicles(nonCheckedVehicles)

    const updatedMarketingPlaces = marketingPlaces.map(i => ({
      ...i,
      isChecked: false,
    }))

    const resetTypes = promotionTypes.map(i => ({
      ...i,
      label: getLabelByLanguage(i, locale),
      value: i.id,
      isChecked: false,
    }))
    if (resetTypes.length > 0) {
      resetTypes[0].isChecked = true
    }
    setPromotionTypes(resetTypes)

    setMarketingPlaces(updatedMarketingPlaces)
    setOnlyMembers(false)
    setPromotionName("")
    setDiscountRate(10)
    setResStartDate(getTodayDate())
    setResEndDate(addDays(getTodayDate(), 7))
    setBookingStartDate(getTodayDate())
    setBookingEndDate(addDays(getTodayDate(), 7))
    setIsBothSide(false)
    setSelectedEndLocation("")
    setSelectedStartLocation("")
  }

  const submitHandler = () => {
    const submit = async payload => {
      setSubmitLoading(true)
      const response = await request(
        {
          url: "transfer-promotions",
          method: "post",
          data: payload,
        },
        false
      )
      setSubmitLoading(false)
      if (response?.result) {
        setShowSuccessAlert(true)
      }
    }

    setError("")

    const vehicle_ids = vehicles
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (vehicle_ids.length === 0) {
      return setError("Please enter a vehicle!")
    }

    if (!selectedStartLocation || !selectedEndLocation) {
      return setError("Please select location!")
    }

    const currency_ids = [2]

    const setMarketingPlace_ids = marketingPlaces
      .filter(i => i.isChecked && i.id !== 0)
      .map(i => i.id)

    if (marketingPlaces.length > 2 && setMarketingPlace_ids.length === 0) {
      return setError(t("Please select a marketing place!"))
    } else if (marketingPlaces.length === 2) {
      setMarketingPlace_ids.push(1)
    }

    if (discountRate < 0 || discountRate >= 100) {
      return setError("Please enter a valid discount rate!")
    }

    if (promotionName.trim().length === 0) {
      return setError("Please enter a valid promotion name!")
    }

    const newData = {
      vehicle_ids,
      promotionType_id: promotionTypes.find(i => i.isChecked).value,
      onlyMembers,
      location_id_start: selectedStartLocation.id,
      searchTableStart_id: selectedStartLocation.searchTable_id,
      location_id_end: selectedEndLocation.id,
      searchTableEnd_id: selectedEndLocation.searchTable_id,
      resStartDate,
      resEndDate,
      bookingStartDate,
      bookingEndDate,
      currency_ids,
      discountRate,
      promotionName,
      setMarketingPlace_ids,
      isBothSide,
    }
    submit(newData)
    reset()
  }

  return (
    <main className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Transfer")}
          breadcrumbItem={t("Transfer Promotions")}
        />
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <p className="font-size-15 mt-n3">
              {t(
                "Customize this promotion to suit your needs. Select your own dates, discount amount, audience, and more."
              )}
            </p>

            <Members
              onlyMembers={onlyMembers}
              setOnlyMembers={setOnlyMembers}
            />

            {/* PROMOTION TYPES */}
            <RadioButtons
              title={t("What is the type of promotion?")}
              name="promotionTypes"
              dataList={promotionTypes}
              dataCheckHandler={(e, v) =>
                setPromotionTypes(list =>
                  list.map(i => ({
                    ...i,
                    isChecked: +v === i.value ? true : false,
                  }))
                )
              }
            />

            <Checkboxes
              dataList={vehicles}
              checkHandler={vehicleCheckHandler}
              label="Which vehicles?"
            />

            {marketingPlaces.length > 2 && (
              <Checkboxes
                dataList={marketingPlaces}
                checkHandler={marketingPlaceCheckHandler}
                label="Marketing Places"
              />
            )}

            <Locations
              selectedStartLocation={selectedStartLocation}
              setSelectedStartLocation={setSelectedStartLocation}
              selectedEndLocation={selectedEndLocation}
              setSelectedEndLocation={setSelectedEndLocation}
              isBothSide={isBothSide}
              setIsBothSide={() => setIsBothSide(prev => !prev)}
            />

            <DiscountRate
              discountRate={discountRate}
              setDiscountRate={setDiscountRate}
            />

            {/* BOOKING DATES */}
            <Dates
              title="Transfer Dates"
              subtitle="When can guests transfer using this promotion?"
              startDate={bookingStartDate}
              endDate={bookingEndDate}
              setStartDate={setBookingStartDate}
              setEndDate={setBookingEndDate}
            />

            {/* RESERVATION DATES */}
            <Dates
              title="Bookable Period"
              subtitle="When can customers book this promotion?"
              startDate={resStartDate}
              endDate={resEndDate}
              setStartDate={setResStartDate}
              setEndDate={setResEndDate}
            />

            <PromotionName
              promotionsName={promotionName}
              setPromotionsName={setPromotionName}
            />

            {error && <Alert color="danger"> {t(error)}</Alert>}
            {showSuccessAlert && (
              <SuccessAlert
                hideSuccessAlert={() => {
                  setShowSuccessAlert(false)
                }}
              />
            )}

            <div className="row justify-content-end">
              <Button
                className="align-self-end"
                size="md"
                color="primary"
                onClick={submitHandler}
              >
                {submitLoading ? (
                  <>
                    <Spinner size="sm" /> {t("Loading...")}{" "}
                  </>
                ) : (
                  t("Save")
                )}
              </Button>
            </div>
          </>
        )}
      </Container>
    </main>
  )
}
export default TransferPromotion
