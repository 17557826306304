import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import axios from "axios"

import App from "./App"
import "./i18n"

import store from "./store"

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const index = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(index, document.getElementById("root"))
