import { useTranslation } from "react-i18next"
import SweetAlert from "react-bootstrap-sweetalert"

export default ({ show, title, onConfirm, message, success, warning }) => {
  const { t } = useTranslation()
  return (
    <>
      {show && (
        <SweetAlert
          success={success}
          warning={warning}
          title={t(title)}
          onConfirm={onConfirm}
          confirmBtnText={t("OK")}
        >
          {t(message)}
        </SweetAlert>
      )}
    </>
  )
}
