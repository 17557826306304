import React from "react"
import { Card, CardBody, Input } from "reactstrap"
import { useTranslation } from "react-i18next"

import styles from "./styles.module.scss"

export default function PromotionName(props) {
  const { promotionsName, setPromotionsName } = props

  const { t } = useTranslation()
  return (
    <Card className={styles.card}>
      <CardBody className={styles.cardBody}>
        <h4 className={styles.cardTitle}>{t("Promotion name")}</h4>
        <h5 className={styles.cardSubtitle}>
          {t("What do you want to name this promotion?")}
        </h5>
        <p>
          {t(
            "This name is just for you. We won’t show it to customers on Vihobook.com."
          )}
        </p>

        <Input
          type="text"
          className={styles.promotionNameInput}
          value={promotionsName}
          onChange={e => setPromotionsName(e.target.value)}
        />
      </CardBody>
    </Card>
  )
}
