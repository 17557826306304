import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { useReservationContext } from "../context"
import { getTranslatedOption, formatMoney, getCurrencySignById } from "utils/other"
import styles from "./styles.module.scss"

export default function CustomTable() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  moment.locale(locale)
  const {
    values: { resDetails, promotionNames },
  } = useReservationContext()
  const { reservation } = resDetails

  const currencySign = getCurrencySignById(2)

  if (!reservation) {
    return null
  }

  const promotionRate =
    (reservation.agentPromotionsAmount * 100) / reservation.agentTransferPrice

  const subtotal =
    reservation.agentTransferPrice - reservation.agentPromotionsAmount

  return (
    <TableContainer component={Paper} id="dailyPrice">
      <Table>
        <TableHead>
          <TableRow className={styles.headRow}>
            <TableCell>{t("Date")}</TableCell>
            <TableCell>{t("Vehicle Count")}</TableCell>
            <TableCell>{t("Price")}</TableCell>
            <TableCell>{t("Discount Description")}</TableCell>
            <TableCell>{t("Discount Rate")}</TableCell>
            <TableCell>{t("Subtotal")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={styles.bodyRow}>
            <TableCell>
              {moment(reservation.transferDate).format("L")}
            </TableCell>
            <TableCell>
              {`${reservation.vehicleCount} x ${
                reservation &&
                getTranslatedOption(reservation, "vehicleType", locale)
              }`}
            </TableCell>

            <TableCell>
              {currencySign}
              {formatMoney(reservation.agentTransferPrice, 2)}
            </TableCell>

            <TableCell>
              {promotionNames.map(i => (
                <p key={i.id} className="mb-0">
                  {i?.promotionName}
                </p>
              ))}
            </TableCell>
            <TableCell>{promotionRate.toFixed(0)}%</TableCell>
            <TableCell>
              {currencySign}
              {formatMoney(subtotal, 2)}
            </TableCell>
          </TableRow>

          <TableRow
            className={styles.lightColor}
            sx={{ "& > td": { paddingTop: "15px !important" } }}
          >
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Extra Services")}</TableCell>
            <TableCell align="right" colSpan={2}>
              {currencySign}
              {formatMoney(reservation?.agentExtraServicePrice, 2)}
            </TableCell>
          </TableRow>

          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Taxes")}</TableCell>
            <TableCell align="right" colSpan={2}>
              {currencySign}
              {formatMoney(reservation?.agentTaxAmount, 2)}
            </TableCell>
          </TableRow>
          <TableRow className={styles.lightColor}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2}>{t("Total")}</TableCell>
            <TableCell align="right" colSpan={2}>
              {currencySign}
              {formatMoney(reservation.agentTotalPrice, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
