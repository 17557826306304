import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Input } from "reactstrap"
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"

const TourDescription = ({ useFormResult, isAddOperation, ticket }) => {
  const [activeTab, setActiveTab] = useState("Turkish")
  const { register, setValue } = useFormResult
  const { t } = useTranslation()

  useEffect(() => {
    if (!isAddOperation) {
      const {
        description_en,
        description_tr,
        description_fr,
        description_de,
        description_ru,
        description_ar,
      } = ticket
      setValue("description_en", description_en)
      setValue("description_tr", description_tr)
      setValue("description_fr", description_fr)
      setValue("description_de", description_de)
      setValue("description_ru", description_ru)
      setValue("description_ar", description_ar)
    }
  }, [])

  const handleLinkClick = value => {
    if (value === activeTab) {
      return
    }
    setActiveTab(value)
  }
  const TabLink = title => (
    <MDBTabsItem>
      <MDBTabsLink
        onClick={() => handleLinkClick(title)}
        active={activeTab === title}
      >
        {t(title)}
      </MDBTabsLink>
    </MDBTabsItem>
  )

  const TabsPane = (input, name) => (
    <MDBTabsPane show={activeTab === input}>
      <Input
        name={name}
        innerRef={register}
        type="textarea"
        className={styles.tabInput}
      />
    </MDBTabsPane>
  )
  return (
    <Card className={styles.card}>
      <CardBody>
        <CardTitle>{t("Description")}</CardTitle>
        <hr />
        <MDBTabs pills className="mb-3">
          {TabLink("Turkish")}
          {TabLink("English")}
          {TabLink("French")}
          {TabLink("German")}
          {TabLink("Russian")}
          {TabLink("Arabic")}
        </MDBTabs>

        <MDBTabsContent>
          {TabsPane("Turkish", "description_tr")}
          {TabsPane("English", "description_en")}
          {TabsPane("French", "description_fr")}
          {TabsPane("German", "description_de")}
          {TabsPane("Russian", "description_ru")}
          {TabsPane("Arabic", "description_ar")}
        </MDBTabsContent>
      </CardBody>
    </Card>
  )
}

export default TourDescription
