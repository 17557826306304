import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.scss"
import { getCurrencySignById } from 'utils/other'

import { useOverallViewContext } from "../context"

const CustomTable = props => {
  const { values } = useOverallViewContext()
  const { t, i18n } = useTranslation()
  const { roomNames, currencies } = values
  const { summaryData, dailyData } = props.data

  const roomName = roomNames?.find(i => i.room_id === summaryData.room_id)?.name

  const currencyName = getCurrencySignById(currencies?.find(i => i.isChecked)?.id || 2)

  return (
    <div className={styles.container}>
      <legend className={styles.legend}>
        <span>{roomName} </span>
      </legend>
      <div className={styles.row}>
        {/* TITLES*/}
        <div className={`${styles.col} ${styles.titlesCol}`}>
          <span className={styles.center}>{t("Month")}</span>
          <span className={styles.center}>{t("Name")}</span>
          <span className={styles.center}>{t("Day")}</span>
          <br />
          <span>{t("Status")}</span>
          <span>{t("Quota")}</span>
          <span>{t("Sold out")}</span>
          <span>{t("Remaining")}</span>
          <br />
          <span className={styles.rowsHeader}>{t("Price")}</span>
          <span>PPPN ({currencyName})</span>
          {/* DYNAMIC */}
          {Array(summaryData?.maxAdult)
            .fill(0)
            .map((_, idx) => (
              <span key={idx}>
                {idx + 1}. {t("Person")} ({currencyName})
              </span>
            ))}
        </div>
        {/* SHOULD BE SCROLLABLE */}
        <div className={`${styles.col} ${styles.scrollable}`}>
          <div className={styles.row}>
            {dailyData?.map(item => {
              let { date, roomDayStatus, roomCount, reservedCount } = item
              date = new Date(date)
              return (
                <div key={date} className={styles.col}>
                  <span className={styles.bold}>
                    {date?.toLocaleString(i18n.language, {
                      month: "short",
                    })}
                  </span>
                  <span className={styles.bold}>
                    {date?.toLocaleDateString(i18n.language, {
                      weekday: "short",
                    })}
                  </span>
                  <span className={styles.bold}>{date.getDate()}</span>
                  <br />
                  <span className={roomDayStatus ? styles.open : styles.closed}>
                    {roomDayStatus ? t("Open") : t("Closed")}
                  </span>
                  <span>{roomCount || 0}</span>
                  <span>{reservedCount || 0}</span>
                  <span>{roomCount - reservedCount || 0}</span>
                  <br />
                  <span className={styles.empty}></span>
                  <span>{item.coefficient || 0}</span>
                  {Array(summaryData?.maxAdult)
                    .fill(0)
                    .map((_, idx) => (
                      <span key={idx}>{item[idx + 1] || 0} </span>
                    ))}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomTable
