import { TabContent, TabPane } from "reactstrap"

import CustomCheckbox from "components/Common/CustomCheckbox/index"
import styles from "./styles.module.scss"

const NavContents = props => {
  return (
    <TabContent activeTab={props.activeLink} className={styles.tabContent}>
      <TabPane tabId={0} className={styles.tabPane}>
        {props?.features[props.activeLink]?.featureItems?.map(item => (
          <CustomCheckbox
            key={item.id}
            className={styles.checkboxContainer}
            id={item.id}
            title={item.itemTitle}
            isChecked={item.isChecked}
            onClick={props.onCheckboxClicked}
          />
        ))}
      </TabPane>
    </TabContent>
  )
}

export default NavContents
