import { BiHome, BiMessage } from "react-icons/bi"
import { FiSettings } from "react-icons/fi"

const commonContent = [
  {
    id: 1,
    label: "Home Page",
    link: "/",
    icon: BiHome,
  },
  {
    id: 2,
    label: "Setup",
    icon: FiSettings,
    link: "/#",
    items: [
      {
        label: "Company Settings",
        link: "/setup-company-settings",
        isMandatory: true,
      },
      {
        label: "Company Documents",
        link: "/company-documents",
      },
      {
        label: "User",
        link: "/setup-user",
      },
      {
        label: "Marketing Place",
        link: "/company-marketing-place",
      },
      {
        label: "Contract",
        link: "/company-contract",
        isMandatory: true,
      },
    ],
  },
  {
    id: 3,
    label: "Messages",
    icon: BiMessage,
    link: "/#",
    items: [
      {
        label: "Reservation Messages",
        link: "/reservation-messages",
      },
      {
        label: "Customer Service Messages",
        link: "/customer-service-messages",
      },
    ],
  },
]

export default commonContent
